import { useFormikContext } from "formik";
import React from "react";
import { Button, Col, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import ActionItemDelete from "../../ActionItemDelete";
import ActionItemCommentCreate from "../../ActionItemComment/ActionItemCommentCreate";

const ActionItemUpdateFooter = ({
  handleClose,
  isLoading,
  setActionItemComment,
  onActionItemDeleted,
}) => {
  const {
    handleSubmit,
    isValid,
    values: { step, actionItem },
  } = useFormikContext();

  const handleActionItemCommentCreated = (comment) => {
    setActionItemComment((prevComments) => {
      const comments = [...prevComments, comment];
      return comments;
    });
  };

  return step === 0 ? (
    <div className="sticky-bottom p-3 bg-light d-flex flex-row justify-content-between align-items-center">
      <div>
        <ActionItemDelete
          actionItem={actionItem}
          onActionItemDeleted={onActionItemDeleted}
          handleClose={handleClose}
        />
      </div>
      <div>
        <Button
          size="sm"
          variant="outline-secondary"
          className="me-1"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          size="sm"
          disabled={isLoading || !isValid}
        >
          {isLoading && (
            <Spinner
              className="me-2"
              animation="border"
              size="sm"
              variant="light"
            />
          )}{" "}
          <span>Update</span>
        </Button>
      </div>
    </div>
  ) : (
    step === 2 && (
      <Col className="p-3 pb-5 bg-light border-top sticky-bottom">
        <ActionItemCommentCreate
          actionItemId={actionItem?.actionItemId}
          onActionItemCommentCreated={handleActionItemCommentCreated}
        />
      </Col>
    )
  );
};

ActionItemUpdateFooter.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ActionItemUpdateFooter;
