import { OrganizationContext } from "contexts/OrganizationProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchOrgAudits = ({ page, perPage, params }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [error, setError] = useState("");
  const [assessments, setAssessments] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState();

  const fetchAssessments = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const assessmentsParams = {
      params: {
        organization_id: organization?.id,
        include: `protocol,facility,labels`,
        page: page ?? 1,
        perPage: perPage ?? 10,
        ...params(),
      },
    };

    const activitiesPromise = get(`/audits`, assessmentsParams);

    activitiesPromise.promise
      .then(({ data, meta }) => {
        setAssessments(data);
        setMeta(meta);
        setError(null);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(activitiesPromise);
  }, [organization?.id, page, perPage, params]);

  useEffect(() => {
    fetchAssessments();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchAssessments]);

  return {
    meta,
    error,
    assessments,
    isLoading,
    setError,
    fetchAssessments,
  };
};

export default useFetchOrgAudits;
