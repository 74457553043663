import React from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

export default function DisplayPowerBiReport({ config }) {
  const setResponsiveness = (bodyNode) => {
    if (bodyNode !== null) {
      let aTags = bodyNode.getElementsByTagName("a");
      for (let aTag of aTags) {
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("rel", "noopener noreferrer");
      }

      let imgTags = bodyNode.getElementsByTagName("img");
      for (let img of imgTags) {
        img.setAttribute("class", "img-fluid");
      }

      let iframeTags = bodyNode.getElementsByTagName("iframe");
      for (let iframe of iframeTags) {
        iframe.removeAttribute("height");
        iframe.removeAttribute("width");
        let wrapper = document.createElement("div");
        wrapper.className = "ratio ratio-16x9";
        // insert wrapper before el in the DOM tree
        iframe.parentNode.insertBefore(wrapper, iframe);
        wrapper.appendChild(iframe);
      }

      let figureTags = bodyNode.getElementsByTagName("figureTags");
      for (let figure of figureTags) {
        figure.setAttribute("class", "img-fluid");
      }
    }
  };
  if (config && JSON.stringify(config) !== "{}") {
    return (
      <div ref={setResponsiveness}>
        <PowerBIEmbed
          embedConfig={{
            ...config,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            permissions: models.Permissions.All,
            settings: {
              panes: {
                filters: {
                  expanded: true,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  // Report Loaded successfully
                },
              ],
              [
                "rendered",
                function () {
                  //Report rendered event
                },
              ],
              [
                "error",
                function (event) {
                  //TODO: handle events here
                  //console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.warn("visual clicked")],
              ["pageChanged", (event) => console.warn(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </div>
    );
  }
}
