import MultipleObservationExportWrapper from "./MultipleObservationExportWrapper";
import SingleObservationExportWrapper from "./SingleObservationExportWrapper";

const ExportWrapper = ({ isMultipleObservations, ...props }) => {
  if (isMultipleObservations) {
    return <MultipleObservationExportWrapper {...props} />;
  } else {
    return <SingleObservationExportWrapper {...props} />;
  }
};

export default ExportWrapper;
