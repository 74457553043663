import React from "react";
import * as Sentry from "@sentry/react";
import { Login } from "features/authentication";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const LoginPage = () => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("location", "LoginPage");
      }}
      fallback={({ error, resetError }) => (
        <ErrorBoundaryFallback error={error} resetError={resetError} />
      )}
    >
      <Login />{" "}
    </Sentry.ErrorBoundary>
  );
};

export default LoginPage;
