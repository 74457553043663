import React, { useContext, useRef, useState, useEffect } from "react";
import "./AuditExecutiveSummary.scss";

import { Formik } from "formik";
import { useRoles, useToast } from "hooks";
import PropTypes from "prop-types";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import * as yup from "yup";

import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import SlateEditor from "components/ui/SlateEditor";
import { OrganizationContext } from "contexts/OrganizationProvider";
import ProtocolTypeahead from "features/assessment/components/common/ProtocolTypeahead";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { put } from "utils/DeApi";
import { get } from "utils/BeeApi";
import Loader from "components/ui/Loader";

const AuditExecutiveSummary = ({ onAuditUpdated }) => {
  const { isCertifier } = useRoles();
  const subscribedPromises = useRef([]);
  const { audit } = useContext(AuditContext);
  const organization = useContext(OrganizationContext);
  const { protocol: { summaryContentId: contentId = "" } = {} } = audit;
  const toast = useToast();

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasTemplate, setHasTemplate] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const updateAudit = (formData) => {
    setIsLoading(true);
    const auditPromise = put(`audits/${audit.auditId}`, {
      ...formData,
      facilityId: audit?.facilityId,
      organizationId: organization?.id,
      executiveSummary: formData.executiveSummary,
    });
    auditPromise.promise
      .then((response) => {
        let audit = response.data;
        setError(null);
        setIsLoading(false);
        handleClose();
        onAuditUpdated(audit);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(auditPromise);
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Assessment name is too Short!")
      .max(100, "Assessment name is too Long!")
      .required("Assessment name is required"),
    description: yup
      .string()
      .min(2, "Description is too Short!")
      .max(255, "Description is too Long!")
      .notRequired(),
    executiveSummary: yup.string().nullable(),
    protocol: yup.array().length(1),
    startAt: yup.date(),
    completedAt: yup.date(),
  });

  if (isCertifier) return <></>;

  const handleGetExecutiveSummary = (executiveSummary, setFieldValue) => {
    setError("");
    setIsLoading(true);

    const executiveSummaryContentsPromise = get(`contents/${contentId}`);

    executiveSummaryContentsPromise.promise
      .then((response) => {
        const contents = response.data;
        setFieldValue(
          "executiveSummary",
          executiveSummary + contents?.contentBody
        );
        toast.success("Success", "Template added successfully");
        setHasTemplate(true);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(executiveSummaryContentsPromise);
  };

  return (
    <>
      <Button size="sm" variant="outline-primary" onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          edit
        </span>{" "}
        Executive Summary
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            updateAudit({
              name: values.name,
              description: values.description,
              protocolId: values.protocol.pop().protocolId,
              auditGroupId:
                Array.isArray(values.group) && values.group.length
                  ? values.group.pop().id
                  : "",
              executiveSummary:
                values.executiveSummary === "<p></p>"
                  ? ""
                  : values.executiveSummary,

              startedAt: values.startedAt,
              completedAt: values.completedAt,
            });
          }}
          initialValues={{
            name: audit.name,
            description: audit.description,
            executiveSummary: audit.executiveSummary,
            group: audit?.group ? [audit.group] : [],
            protocol: audit.protocol ? [audit.protocol] : [],
            startedAt: "2022-01-19 03:14:07",
            completedAt: "2022-01-19 03:14:07",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => {
            return (
              <>
                <Modal.Header
                  className="border-0 position-relative"
                  closeButton
                >
                  <Modal.Title>Executive Summary</Modal.Title>
                </Modal.Header>
                {contentId && (
                  <Alert
                    variant="info"
                    className="py-2 ms-3 me-4 d-flex align-items-center"
                  >
                    <span className="material-symbols-outlined md-16 text-info">
                      info
                    </span>
                    <span className="ms-2">
                      A template is available for this assessment.
                    </span>
                    <Button
                      variant="link"
                      className="text-info ms-n2 py-0 summary-template-btn"
                      onClick={() =>
                        handleGetExecutiveSummary(
                          values.executiveSummary,
                          setFieldValue
                        )
                      }
                      disabled={isLoading || hasTemplate}
                    >
                      Use template
                    </Button>
                  </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Modal.Body className="py-0">
                    <Form.Group
                      controlId="name"
                      className="mb-3 visually-hidden"
                    >
                      <Form.Label>
                        Assessment Name <RequiredAsterisk />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          !(values.name && !errors.name) && touched.name
                        }
                        isValid={values.name && !errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name && touched.name ? (
                          <small>{errors.name}</small>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="protocol"
                      className="mb-3 visually-hidden"
                    >
                      <Form.Label>Assessment Protocol</Form.Label>
                      <ProtocolTypeahead
                        selected={values.protocol}
                        isValid={values.protocol && !errors.protocol}
                        onChange={(protocol) =>
                          setFieldValue("protocol", protocol)
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="description"
                      className="mb-3 visually-hidden"
                    >
                      <Form.Label>Assessment Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.description && touched.description}
                        isValid={values.description && !errors.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description && touched.description ? (
                          <small>{errors.description}</small>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {isLoading && <Loader />}
                    {!isLoading && (
                      <Form.Group controlId="executiveSummary">
                        <div className="rounded border">
                          <SlateEditor
                            name="executiveSummary"
                            setFieldValue={setFieldValue}
                            initialValue={values.executiveSummary}
                            oldValue={values.executiveSummary}
                            placeholder="Enter Executive Summary"
                            minHeight={500}
                            customClassNames={["executive-summary"]}
                          />
                        </div>
                      </Form.Group>
                    )}

                    {error && <ErrorHandler error={error} />}
                  </Modal.Body>
                  <Modal.Footer className="border-0">
                    <Button size="sm" variant="link" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      disabled={!values.name || isLoading}
                    >
                      <span translate="no">
                        {isLoading && (
                          <Spinner
                            className="me-2"
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        )}
                      </span>
                      <span>Update</span>
                    </Button>
                  </Modal.Footer>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

AuditExecutiveSummary.propTypes = {
  onAuditUpdated: PropTypes.func.isRequired,
};

export default AuditExecutiveSummary;
