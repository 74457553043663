import React from "react";
import { Form } from "react-bootstrap";
import FieldRadio from "../FieldRadio";
import FieldTextarea from "../FieldTextarea";
import FieldNumeric from "../FieldNumeric";
import FieldDatetime from "../FieldDatetime";
import FieldCheckbox from "../FieldCheckbox";

const NestedFields = ({ values, questions }) => {
  return (
    <div
      className="border-start border-end border-primary py-2 border-1 ps-4 shadow-sm p-3"
      style={{
        "--bs-border-style": "dashed",
      }}
    >
      {questions.map((q) => {
        const value = values?.metadata?.[q?.id] || {};
        return (
          <div key={q.id} className="">
            <Form.Group controlId={q.id} className="mt-3 mb-1">
              <div className="d-flex justify-content-between align-items-center">
                <Form.Label className={`flex-fill`}>
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18 position-absolute text-primary"
                    style={{ left: 20 }}
                  >
                    subdirectory_arrow_right
                  </span>
                  <strong>{q.prompt}</strong>
                </Form.Label>
              </div>
              <FieldRadio question={q} value={value} />
              <FieldTextarea question={q} value={value} />
              <FieldNumeric question={q} value={value} />
              <FieldDatetime question={q} value={value} />
              <FieldCheckbox question={q} value={value} />
            </Form.Group>
          </div>
        );
      })}
    </div>
  );
};

export default NestedFields;
