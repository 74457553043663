import ListObservationExport from "../../ListObservationExport";
import ObservationExport from "../../ObservationExport/ObservationExport";

const SingleObservationExportWrapper = ({ isGrid, observations, ...props }) => {
  return (
    <>
      {isGrid ? (
        <ObservationExport
          observation={observations?.length > 0 ? observations[0] : null}
          responses={
            observations?.length > 0 ? observations[0].responses : null
          }
          {...props}
        />
      ) : (
        <ListObservationExport
          observation={observations?.length > 0 ? observations[0] : null}
          responses={
            observations?.length > 0 ? observations[0].responses : null
          }
          {...props}
        />
      )}
    </>
  );
};

export default SingleObservationExportWrapper;
