import { useContext } from "react";

import moment from "moment";
import { useRoles } from "hooks";
import { Badge } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";

const AuditInfo = () => {
  const { isCertifier } = useRoles();
  const { audit } = useContext(AuditContext);

  const inProgress = audit?.status === "Unfinished";

  return (
    <>
      <h1 className="text-capitalize">{audit.name}</h1>
      <Badge
        className={` ${
          inProgress ? "bg-white  text-dark" : "bg-primary"
        }   bg-opacity-75 border border-secondary border-opacity-25 me-2 mb-1`}
      >
        <small>
          <span translate="no" className="material-symbols-outlined md-13">
            {inProgress ? "autorenew" : "check"}
          </span>{" "}
          {inProgress ? "In Progress" : audit.status}
        </small>
      </Badge>
      {audit?.archived && (
        <Badge className="bg-white bg-opacity-75 border border-secondary border-opacity-25 text-dark me-2 mb-1">
          <small className="text-warning">
            <span translate="no" className="material-symbols-outlined md-13">
              archive
            </span>{" "}
            Archived
          </small>
        </Badge>
      )}
      {audit?.confidential && (
        <Badge className="bg-white bg-opacity-75 border border-secondary border-opacity-25 text-dark me-2 mb-1">
          <small className="text-danger">
            <span translate="no" className="material-symbols-outlined md-13">
              block
            </span>{" "}
            Confidential
          </small>
        </Badge>
      )}
      {(audit.locked || isCertifier) && (
        <Badge className="bg-white bg-opacity-75 border border-secondary border-opacity-25 text-dark me-2 mb-1">
          <small className="text-dark">
            <span translate="no" className="material-symbols-outlined md-13">
              lock
            </span>{" "}
            View only
          </small>
        </Badge>
      )}
      <p>
        {audit?.labels?.map((label) => (
          <span
            key={label.auditLabelId}
            className="me-2 rounded-2 badge text-dark opacity-75"
            style={{
              borderWidth: 1,
              borderColor: label?.color,
              backgroundColor: `${label?.color}30`,
              borderStyle: "solid",
            }}
          >
            <small>{label?.name}</small>
          </span>
        ))}
      </p>
      <p className="text-capitalize my-2">
        <span
          translate="no"
          className="material-symbols-outlined md-16 text-muted"
        >
          policy
        </span>{" "}
        {audit.protocol.name}
      </p>
      <p className="text-capitalize text-muted mb-3">
        Last updated {moment(audit.updatedAt).fromNow()}
      </p>
    </>
  );
};

export default AuditInfo;
