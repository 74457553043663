import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compact } from "lodash";

const InherentRiskColor = ({ children, values, label }) => {
  const [inherentRiskMap, setInherentRiskMap] = useState({});
  useEffect(() => {
    if (Array.isArray(children))
      children.forEach((child, index) => {
        const choices = child?.choices || [];

        const selectedIndex = choices.findIndex(({ id }) => {
          if (!values?.dynamic) return false;
          if (!values?.dynamic[child.id]) return false;
          return values?.dynamic[child.id]?.response === id;
        });

        setInherentRiskMap((prevState) => {
          return {
            ...prevState,
            [index]: choices.map((choice, index) => {
              return {
                ...choice,
                seleted: selectedIndex === index,
              };
            }),
          };
        });
      });
  }, [children, values]);

  if (!Array.isArray(inherentRiskMap[0])) return <></>;
  if (!Array.isArray(inherentRiskMap[1])) return <></>;

  const yAxisItems = inherentRiskMap[0] || [];
  const xAxisItems = inherentRiskMap[1] || [];

  const selected = compact(
    yAxisItems
      .map((yAxis) => {
        return xAxisItems.map((xAxis) => {
          const colorMix = yAxis.color.mix(xAxis.color.toString(), 0.5, {
            space: "lch",
            outputSpace: "srgb",
          });

          return xAxis?.seleted && yAxis?.seleted ? colorMix : null;
        });
      })
      .flat()
  );

  if (!selected[0]) return <></>;

  return (
    <span
      className="float-end rounded-0 badge px-2 py-1 text-white"
      style={{
        backgroundColor: selected[0].toString(),
      }}
    >
      <strong>{label}</strong>
    </span>
  );
};

InherentRiskColor.propTypes = {
  values: PropTypes.any,
  children: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default InherentRiskColor;
