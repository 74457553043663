import { levels } from "features/actionItems/constants";
import {
  ActionItemsDispatchContext,
  ActionItemsContext,
} from "features/actionItems/context/ActionItemProvider";
import isFilterActive from "features/actionItems/utils/isFilterActive";
import React, { useContext } from "react";
import { Col, Dropdown } from "react-bootstrap";

const LevelFilter = () => {
  const {
    filters: { level },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);

  return (
    <Col lg={2} className="mb-3">
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-level"
          className={`w-100 text-truncate border border-gray-900 ${isFilterActive(
            level?.name
          )}`}
        >
          {level?.name || "Levels"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {levels.map((l) => (
            <Dropdown.Item
              key={l.index}
              active={l.index === level?.index}
              onClick={() => {
                dispatch({ type: "SET_FILTERS", payload: { level: l } });
              }}
            >
              {l.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  );
};

export default LevelFilter;
