import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { post } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { useFormikContext } from "formik";

const ManuscriptCommentCreate = ({ onCommentCreated, observationId }) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext() || {};
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const postManuscriptCommentCreate = (body) => {
    setError("");
    setIsLoading(true);
    const payload = {
      body,
    };
    const commentPromise = post(
      `/observations-report-comments/${observationId}/comments`,
      payload
    );

    commentPromise.promise
      .then((response) => {
        setIsLoading(false);
        onCommentCreated(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      })
      .finally(() => {
        setFieldValue("newComment", "");
        setFieldTouched("newComment", false);
      });

    subscribedPromises.current.push(commentPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return (
    <>
      <Form.Group className="mb-3" controlId="newComment">
        <Form.Label>Add Comment</Form.Label>
        <Form.Control
          onChange={handleChange}
          onBlur={handleBlur}
          name="newComment"
          type="text"
          placeholder="Write a comment"
          as="textarea"
          rows={3}
          value={values?.newComment}
          isValid={values?.newComment && !error?.newComment}
          isInvalid={
            !(values?.newComment && !errors.newComment) && touched?.newComment
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors?.newComment && touched?.newComment ? (
            <small>{errors.newComment}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>

      {error && <ErrorHandler error={error} />}
      <Col xs={12}>
        <Button
          onClick={() => postManuscriptCommentCreate(values?.newComment)}
          className="float-end"
          size="sm"
          disabled={isLoading || !observationId}
        >
          {isLoading && (
            <Spinner
              className="me-2"
              animation="border"
              size="sm"
              variant="light"
            />
          )}
          <span>Post Comment</span>
          <span translate="no" className="material-symbols-outlined md-18 ms-2">
            send
          </span>
        </Button>
      </Col>
    </>
  );
};

ManuscriptCommentCreate.propTypes = {
  onCommentCreated: PropTypes.func.isRequired,
  observationId: PropTypes.string,
};

export default ManuscriptCommentCreate;
