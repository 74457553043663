import React from "react";
import { Col, Row } from "react-bootstrap";
import ActionItemReport from "../../ActionItems/ActionItemReport";

const ActionItemStats = ({ tableView }) => {
  if (tableView) return <span />;
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
        <ActionItemReport />
      </Col>
    </Row>
  );
};

export default ActionItemStats;
