import React from "react";

import { Col, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";

import AuditMemos from "./AuditMemos";
import AuditFiles from "./AuditFiles";
import AuditMembers from "./AuditMembers";
import Question from "./Question/Question";
import AuditMetadata from "./AuditMetadata";
import LeftSidePanel from "./LeftSidePanel";
import AuditSignatures from "./AuditSignatures";
import AuditReport from "./AuditReport/AuditReport";
import AuditPreQualification from "./AuditPreQualification";

import "./AuditDetails.scss";

const AuditDetails = () => {
  return (
    <Row>
      <Col xs={12} sm={12} md={4} lg={3} className="sidebar-container mb-3">
        <LeftSidePanel />
      </Col>
      <Col xs={12} sm={12} md={8} lg={9} className="mb-3">
        <Row>
          <Routes>
            <Route index element={<AuditReport />} />
            <Route path="files" element={<AuditFiles />} />
            <Route path="members" element={<AuditMembers />} />
            <Route path="metadata" element={<AuditMetadata />} />
            <Route
              path="pre-qualification"
              element={<AuditPreQualification />}
            />
            <Route path="signatures" element={<AuditSignatures />} />
            <Route
              path="sections/:sectionId/questions/:questionId"
              element={<Question />}
            />
          </Routes>
        </Row>
        <AuditMemos />
      </Col>
    </Row>
  );
};

export default AuditDetails;
