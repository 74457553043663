import { Badge } from "react-bootstrap";

const QuestionTags = ({ tags = [] }) => {
  if (!tags.length) return <></>;
  return tags.map(({ id, name }) => (
    <Badge key={id} className="me-2 mb-2 bg-opacity-10 text-primary fw-normal">
      <p className="mb-0 pe-2">
        <span
          translate="no"
          className="material-symbols-outlined md-16 me-2 mt-n2"
        >
          tag
        </span>
        <strong>{name}</strong>
      </p>
    </Badge>
  ));
};

export default QuestionTags;
