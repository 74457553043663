import React, { useEffect, useRef } from "react";
import "./ReportScore.scss";
import * as echarts from "echarts";

const ReportScore = ({ score, size, color, label }) => {
  const el = useRef(null);
  useEffect(() => {
    const option = {
      color: color || ["#74b72e"],

      series: [
        {
          type: "gauge",
          startAngle: 90,
          endAngle: -270,
          center: [50, 45],
          progress: {
            show: true,
            width: 10,
          },
          min: 0,
          max: 100,
          axisLine: {
            lineStyle: {
              width: 10,
            },
          },
          pointer: { show: false },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          anchor: {
            show: false,
          },
          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            fontSize: 24,
          },
          data: [
            {
              value: score ? Math.round(score) : 0,
              detail: {
                fontWeight: "normal",
                offsetCenter: [0, 0],
                formatter: function (value) {
                  return `{a|${
                    label
                      ? label.length > 10
                        ? label.substring(0, 8) + "..."
                        : label
                      : value.toFixed(0)
                  }}{b|${label ? "" : "%"}}`;
                },
                rich: {
                  a: {
                    fontSize: label ? 12 : 20,
                    color: "#000",
                    lineHeight: 1,
                  },
                  b: {
                    fontSize: label ? 12 : 18,
                    color: "#000",
                  },
                },
              },
            },
          ],
        },
      ],
    };
    const chart = echarts.init(el.current);
    chart.setOption(option);
  }, [el, score, size]);

  return (
    <div className="ReportScore">
      <div className={`report-score-container mx-auto ${size}`} ref={el} />
    </div>
  );
};

export default ReportScore;
