import moment from "moment";
import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useCallback, useEffect, useRef, useContext, useState } from "react";

export function useFetchAuditTrails({
  startDate,
  endDate,
  selectedAction,
  sortState,
  page,
  perPage,
  member = [],
  subject = [],
}) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [meta, setMeta] = useState("");
  const [auditTrails, setAuditTrails] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const fetchAuditTrails = useCallback(() => {
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");

    let params = {
      sort: sortState === "asc" ? "-created_at" : "created_at",
      page: page ?? 1,
      perPage: perPage ?? 10,
    };

    const isClearedFiltered =
      !endDate && !member.length > 0 && !subject.length > 0 && !selectedAction;

    if (isClearedFiltered) {
      params = {
        ...params,
        "filter[subject_type]": [
          "subscriberFacility",
          "subscriberOrganization",
          "observation",
          "audit",
          "response",
          "facility",
          "subscriberPortfolio",
          "media",
          "file",
        ],
      };
    } else {
      params = {
        ...params,
        "filter[created_at]": startDate && endDate ? `${start},${end}` : "",
        "filter[email]": member?.length ? `${member[0].email}` : "",
        "filter[subject_type]": subject?.length ? `${subject[0].key}` : "",
        "filter[type]": selectedAction,
      };
    }

    setError(null);
    setIsLoading(true);
    let getAuditTrails = get(
      `organizations/${organization?.id}/activity-logs`,
      { params }
    );

    getAuditTrails.promise
      .then(({ data, meta }) => {
        setMeta(meta);
        setAuditTrails(data);
      })
      .catch((error) => {
        if (!error.isCanceled) setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(getAuditTrails);
  }, [
    organization?.id,
    startDate,
    endDate,
    member,
    subject,
    selectedAction,
    sortState,
    page,
    perPage,
    setError,
    setIsLoading,
    setMeta,
    setAuditTrails,
    subscribedPromises,
  ]);

  useEffect(() => {
    fetchAuditTrails();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchAuditTrails]);

  return { error, isLoading, meta, auditTrails };
}
