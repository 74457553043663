import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { Table, Tab, Nav, Button, Offcanvas } from "react-bootstrap";
import "./ManuscriptEditHistory.scss";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useFetchEditHistory } from "features/assessment/services/useFetchEditHistory.js";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import Loader from "components/ui/Loader";
import { useShowEditHistory } from "features/assessment/hooks/useShowEditHistory";
import ManuscriptEditHistoryFilters from "./ManuscriptEditHistoryFilters";
import ManuscriptEditHistoryColumns from "./ManuscriptEditHistoryColumns";
import ErrorHandler from "components/ui/ErrorHandler";

const ManuscriptEditHistory = ({ question, labels }) => {
  const [show, setShow] = useState(false);
  const [sortField, setSortField] = useState("timestamp");
  const [sortDirection, setSortDirection] = useState("desc");
  const [filters, setFilters] = useState({
    userId: "",
    fieldType: "",
    startDate: "",
    endDate: "",
  });
  const [activeKey, setActiveKey] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [displayError, setDisplayError] = useState(null);
  const { audit } = useContext(AuditContext);

  const { filterAndSortHistory, renderHistoryEntry } = useShowEditHistory(
    filters,
    sortField,
    sortDirection,
    labels
  );

  const handleShow = () => {
    setShow(true);
    resetFilters();
  };
  const handleClose = () => setShow(false);

  const { historyData, isLoading, error, refetch } = useFetchEditHistory(
    activeKey,
    show
  );

  const resetFilters = useCallback(() => {
    setFilters({
      userId: "",
      fieldType: "",
      startDate: "",
      endDate: "",
    });
  }, []);

  const getFieldLabel = (field) => {
    switch (field.toLowerCase()) {
      case "risk":
        return labels.risk;
      case "conformity":
      case "conformitylevel":
        return labels.conformity;
      case "compliance":
        return labels.compliance;
      default:
        return field.charAt(0).toUpperCase() + field.slice(1);
    }
  };

  const existingObservations = question.observations.map((obs, index) => ({
    observationId: obs.observationId,
    label: obs?.label || `Observation ${++index}`,
  }));

  const combinedHistoryByObservation = useMemo(() => {
    return {
      [activeKey]: historyData,
    };
  }, [historyData, activeKey]);

  useEffect(() => {
    const users = new Set();
    const questions = new Set();

    Object.values(combinedHistoryByObservation)
      .flat()
      .forEach((entry) => {
        users.add(`${entry.userFirstName} ${entry.userLastName}`);
        questions.add(getFieldLabel(entry.field));
      });

    setUserOptions(Array.from(users).map((user) => ({ label: user })));
    setQuestionOptions(
      Array.from(questions).map((question) => ({ label: question }))
    );
  }, [combinedHistoryByObservation]);

  useEffect(() => {
    if (existingObservations.length > 0) {
      if (
        !activeKey ||
        !existingObservations.some((obs) => obs.observationId === activeKey)
      ) {
        setActiveKey(existingObservations[0].observationId);
      }
    } else {
      setActiveKey(null);
    }
  }, [existingObservations, activeKey]);

  useEffect(() => {
    if (show) {
      setDisplayError(null);
      if (activeKey) {
        refetch();
      }
      resetFilters();
    }
  }, [show, activeKey, refetch, resetFilters]);

  const handleSort = useCallback((field) => {
    setSortField(field);
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  }, []);

  const handleFilterChange = useCallback((type, selected) => {
    const value = selected.length > 0 ? selected[0].label : "";
    setFilters((prev) => ({ ...prev, [type]: value }));
  }, []);

  return (
    <>
      <Button
        variant="outline-secondary"
        className="position-relative ms-2 d-print-none py-0 px-2"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          history
        </span>
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        scroll
        className="w-fixed-640"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit History</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3">
          <div className="question-prompt">
            <div
              className="prompt-content"
              dangerouslySetInnerHTML={{
                __html: question.prompt,
              }}
            />
          </div>
          {existingObservations.length === 0 ? (
            <EmptyStateHandler
              title="No Observations"
              description="There are currently no observations with edit history for this question."
            />
          ) : (
            <Tab.Container
              activeKey={activeKey}
              onSelect={(k) => {
                setActiveKey(k);
              }}
            >
              {audit?.protocol?.hasObservationSets && (
                <Nav variant="tabs" className="mb-3">
                  {existingObservations.map((observation) => (
                    <Nav.Item key={observation.observationId}>
                      <Nav.Link eventKey={observation.observationId}>
                        {observation.label}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              )}
              <Tab.Content>
                <Tab.Pane eventKey={activeKey}>
                  {isLoading && <Loader />}
                  {displayError && <ErrorHandler error={error} />}
                  {!displayError && !isLoading && historyData.length === 0 && (
                    <EmptyStateHandler
                      title="No Edit History"
                      description="There is currently no edit history to display for this observation."
                    />
                  )}
                  {!(isLoading || displayError || historyData.length === 0) && (
                    <>
                      <ManuscriptEditHistoryFilters
                        userOptions={userOptions}
                        handleFilterChange={handleFilterChange}
                        filters={filters}
                        questionOptions={questionOptions}
                      />
                      <div className="table-responsive">
                        {combinedHistoryByObservation[activeKey]?.length > 0 ? (
                          <Table hover className="mb-0">
                            <ManuscriptEditHistoryColumns
                              handleSort={handleSort}
                              sortField={sortField}
                              sortDirection={sortDirection}
                            />
                            <tbody>
                              {filterAndSortHistory(
                                combinedHistoryByObservation[activeKey] || []
                              ).map((entry, index) =>
                                renderHistoryEntry(entry, index)
                              )}
                            </tbody>
                          </Table>
                        ) : (
                          <EmptyStateHandler
                            title="No Edit History"
                            description="There is currently no edit history to display for this observation."
                          />
                        )}
                      </div>
                    </>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManuscriptEditHistory.propTypes = {
  question: PropTypes.string.isRequired,
  labels: PropTypes.shape({
    risk: PropTypes.string,
    conformity: PropTypes.string,
    compliance: PropTypes.string,
  }).isRequired,
};

export default ManuscriptEditHistory;
