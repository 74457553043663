import React, { Fragment } from "react";
import { Modal, Button, Table, Badge } from "react-bootstrap";
import { validationMapping } from "features/assessment/components/Audit/helper";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const AuditTrailResponse = ({ activityLog, handleClose, auditTrail }) => {
  const auditId =
    auditTrail?.observation?.audit?.id || auditTrail?.response?.audit?.id;

  const includedKeys = ["notes", "answerIdValue"];

  const getPropertyValue = (value) => {
    if (typeof value === "boolean") return value.toString();
    return `${value}`;
  };

  return (
    <>
      <Modal.Body>
        <div className="d-flex flox-row">
          <div className="flex-fill">
            <h4>{activityLog.logLabel}</h4>
          </div>
          <div className="text-end">
            {activityLog?.description === "created" && (
              <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
                CREATED
              </Badge>
            )}
            {activityLog?.description === "updated" && (
              <Badge className="bg-warning rounded-0 py-2 px-3 bg-opacity-10 text-warning">
                UPDATED
              </Badge>
            )}
            {activityLog?.description === "deleted" && (
              <Badge className="bg-danger rounded-0 py-2 px-3 bg-opacity-10 text-danger">
                DELETED
              </Badge>
            )}
            <div className="mt-1">
              <small>
                {new Date(activityLog?.createdAt).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </small>
            </div>
          </div>
        </div>
        <div>
          <span>
            {activityLog?.causer?.firstName ?? "-"}{" "}
            {activityLog?.causer?.lastName ?? "-"}
          </span>
          <br />
          <small className="text-muted">
            {activityLog?.causer?.email ?? "-"}
          </small>
        </div>
        <>
          <hr />
          <Table striped borderless>
            <thead>
              <tr>
                <th>Attribute</th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              {activityLog &&
                activityLog.subjectType === "observation" &&
                Object.keys(activityLog?.newProperties).map((key) => {
                  const questionOptions =
                    activityLog?.observation?.audit?.protocol
                      ?.questionOptions ?? [];
                  const riskLevels =
                    activityLog?.observation?.audit?.protocol?.riskLevels ?? [];
                  const conformity =
                    activityLog?.observation?.audit?.protocol?.conformity ?? [];

                  const valueMap = validationMapping.find(
                    ({ property }) => property === key
                  );

                  if (key === "extras") return <Fragment key={key} />;
                  if (key === "parentId") return <Fragment key={key} />;

                  if (valueMap && questionOptions.length > 0) {
                    valueMap.riskLevels = riskLevels;
                    valueMap.conformity = conformity;

                    return (
                      <tr key={key}>
                        <td>{valueMap.label(questionOptions)}</td>
                        <td>
                          {activityLog?.oldProperties[key] ||
                          activityLog?.oldProperties[key] === 0 ||
                          (typeof activityLog?.oldProperties[key] ===
                            "boolean" &&
                            activityLog?.oldProperties[key] !== null) ? (
                            valueMap.answerMapping(
                              activityLog?.oldProperties[key]
                            )
                          ) : (
                            <span className="text-muted">Unanswered</span>
                          )}
                        </td>
                        <td>
                          {activityLog?.newProperties[key] ||
                          activityLog?.newProperties[key] === 0 ||
                          (typeof activityLog?.newProperties[key] ===
                            "boolean" &&
                            activityLog?.newProperties[key] !== null) ? (
                            valueMap.answerMapping(
                              activityLog?.newProperties[key]
                            )
                          ) : (
                            <span className="text-muted">Unanswered</span>
                          )}
                        </td>
                      </tr>
                    );
                  }

                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>
                        {activityLog?.oldProperties[key] ?? (
                          <span className="text-muted">Unanswered</span>
                        )}
                      </td>
                      <td>
                        {activityLog?.newProperties[key] ?? (
                          <span className="text-muted">Unanswered</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              {activityLog &&
                activityLog.subjectType === "response" &&
                Object.keys(activityLog?.newProperties)
                  .filter((key) => includedKeys.includes(key))
                  .filter((key) => activityLog?.newProperties[key] !== null)
                  .map((key) => {
                    //Datetime
                    if (activityLog?.response?.flexibleQuestion?.type === 6)
                      return (
                        <tr key={key}>
                          <td>
                            {activityLog?.response?.flexibleQuestion?.prompt}
                          </td>
                          <td>
                            {activityLog?.oldProperties[key] ? (
                              new Date(
                                activityLog?.oldProperties[key]
                              ).toLocaleString([], {
                                dateStyle: "short",
                                timeStyle: "short",
                              })
                            ) : (
                              <span className="text-muted">Unanswered</span>
                            )}
                          </td>
                          <td>
                            {activityLog?.newProperties[key] ? (
                              new Date(
                                activityLog?.newProperties[key]
                              ).toLocaleString([], {
                                dateStyle: "short",
                                timeStyle: "short",
                              })
                            ) : (
                              <span className="text-muted">Unanswered</span>
                            )}
                          </td>
                        </tr>
                      );

                    //Text and Numeric values
                    return (
                      <tr key={key}>
                        <td>
                          {activityLog?.response?.flexibleQuestion?.prompt}
                        </td>
                        <td>
                          {activityLog?.oldProperties[key] != null ? (
                            getPropertyValue(activityLog.oldProperties[key])
                          ) : (
                            <span className="text-muted">Unanswered</span>
                          )}
                        </td>
                        <td>
                          {activityLog?.newProperties[key] != null ? (
                            getPropertyValue(activityLog.newProperties[key])
                          ) : (
                            <span className="text-muted">Unanswered</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
        {activityLog?.description !== "deleted" && (
          <Button
            size="sm"
            className="px-3"
            as={Link}
            to={`/audits/${auditId}`}
          >
            View assessment
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

AuditTrailResponse.propTypes = {
  auditTrail: PropTypes.object.isRequired,
  activityLog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AuditTrailResponse;
