import React, { useContext, useRef, useState } from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

const PortfolioDelete = ({ portfolio, onPortfolioDeleted }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const { isAdmin } = useRoles();
  const [isChecked, setIsChecked] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setIsChecked(false);
  };

  const deleteFacility = (values) => {
    setError(null);
    setIsLoading(true);

    const portfolioPromise = destroy(
      `organizations/${organization?.id}/portfolios/${portfolio?.id}`
    );

    portfolioPromise.promise
      .then((res) => {
        handleClose();
        onPortfolioDeleted(portfolio);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(portfolioPromise);
  };

  if (!isAdmin) return <></>;

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={handleShow}
        disabled={!portfolio}
        className={`ms-3`}
      >
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          delete
        </span>
        Delete
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">
            Delete Portfolio -{" "}
            <small translate="no" className="text-muted">
              {portfolio?.name}
            </small>
          </h2>
          <p className="mx-4 mt-2">
            <Form.Group>
              <Form.Check
                label={
                  <small>
                    By checking the box, I confirm that I want to delete this
                    Portfolio. I understand that this cannot be undone.
                  </small>
                }
                onClick={() => setIsChecked(!isChecked)}
              />
            </Form.Group>
          </p>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            className="px-3"
            variant="outline-secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="danger"
            className="px-3"
            onClick={(values) => {
              deleteFacility(values);
            }}
            size="sm"
            disabled={!isChecked || isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

PortfolioDelete.propTypes = {
  portfolio: PropTypes.object.isRequired,
  onPortfolioDeleted: PropTypes.func.isRequired,
};

export default PortfolioDelete;
