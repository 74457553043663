import React, { useContext, useEffect, useState } from "react";

import { Alert, Button, Card } from "react-bootstrap";

import Loader from "components/ui/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { login as loginDE } from "utils/DeApi";
import { auth0Login } from "utils/BeeApi";
import MainFixedOffset from "layouts/MainFixedOffset";
import { UserContext, UserDispatchContext } from "contexts/UserProvider";

import RedirectIfAuthenticated from "../common/RedirectIfAuthenticated";

import "./Login.scss";
import LoginForm from "./LoginForm";
import TwoFactorForm from "./TwoFactorForm";

const Login = () => {
  const user = useContext(UserContext);
  const fetchUser = useContext(UserDispatchContext);
  const [error, setError] = useState("");
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [requireTwoFactor, setRequireTwoFactor] = useState(false);

  const [email, setEmail] = useState("");

  const { isAuthenticated, getIdTokenClaims, logout, isLoading } = useAuth0();

  useEffect(() => {
    const handleAuth0Submit = () => {
      getIdTokenClaims().then((idTokenClaims) => {
        auth0Login(idTokenClaims.__raw)
          .then((data) => {
            return loginDE({ token: data.data.token });
          })
          .then(() => {
            fetchUser();
          })
          .catch((error) => {
            setError(error);
          });
      });
    };

    if (isAuthenticated && !user && !error) {
      handleAuth0Submit();
    }
  }, [isAuthenticated, user, error]);

  if ((isAuthenticated && !user) || isLoading)
    return (
      <div>
        {error ? (
          <Alert variant="info" className={`my-3 d-flex flex-row mx-5`}>
            <div className="me-3">
              <span translate="no" className="material-symbols-outlined md-18">
                lock
              </span>
            </div>
            <div className="flex-fill">
              <h5 className="mb-1">
                <small>Login Error</small>
              </h5>
              <p>
                <small>
                  Sorry, an error has occured while logging in with ERM!
                </small>
              </p>
              <p>
                <small>
                  Try logging out and logging back in, if this doesn't work
                  contact the admin
                </small>
              </p>
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() =>
                  logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  })
                }
              >
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 me-2"
                >
                  west
                </span>
                Logout
              </Button>
            </div>
          </Alert>
        ) : (
          <Loader />
        )}
      </div>
    );

  if (user) return <RedirectIfAuthenticated isAuthenticated={user} />;

  return (
    <MainFixedOffset>
      <Card className="border-0 shadow-sm">
        <Card.Body>
          {requireTwoFactor ? (
            <TwoFactorForm
              isLoginLoading={isLoginLoading}
              setIsLoginLoading={setIsLoginLoading}
              email={email}
              setRequireTwoFactor={setRequireTwoFactor}
            />
          ) : (
            <LoginForm
              isLoginLoading={isLoginLoading}
              setIsLoginLoading={setIsLoginLoading}
              setEmail={setEmail}
              setRequireTwoFactor={setRequireTwoFactor}
            />
          )}
        </Card.Body>
      </Card>
    </MainFixedOffset>
  );
};

export default Login;
