import { useContext, useEffect, useRef, useState } from "react";

import { Collapse, Nav } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";

import { get } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import "./GroupNavigation.scss";

const GroupNavigation = () => {
  const location = useLocation();
  const { audit } = useContext(AuditContext);
  const subscribedPromises = useRef([]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(true);
  const [audits, setAudits] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { auditId } = useParams();

  useEffect(() => {
    function fetchAudit() {
      setError(null);
      setIsLoading(true);
      const auditsPromise = get(`facilities/${audit?.facilityId}/audits`, {
        params: {
          include: "protocol",
          "filter[audit_group]": audit?.group?.id,
        },
      });

      auditsPromise.promise
        .then(({ data: audits }) => {
          setAudits(audits);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(auditsPromise);
    }

    if (Boolean(audit?.group)) {
      setOpen(true);
      fetchAudit();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [audit?.facilityId, audit?.group]);

  useEffect(() => {
    setOpen(location.pathname === `/audits/${auditId}`);
  }, [auditId, location]);

  if (!Boolean(audit?.group)) return <></>;

  return (
    <>
      <hr />
      <div className="d-flex" onClick={() => setOpen(!open)}>
        <span className="align-self-baseline">
          <span
            translate="no"
            className="material-symbols-outlined md-20 me-2 pb-1 text-muted"
          >
            folder
          </span>
        </span>

        <h5
          className={`align-self-baseline flex-grow-1 text-capitalize`}
          role="button"
        >
          {audit?.group?.title || "Group"}
        </h5>
        <span className="align-content-end align-self-baseline">
          {open ? (
            <span translate="no" className="material-symbols-outlined ">
              expand_more
            </span>
          ) : (
            <span translate="no" className="material-symbols-outlined ">
              chevron_right
            </span>
          )}
        </span>
      </div>
      <Collapse in={open}>
        <Nav defaultActiveKey={location?.pathname} variant="pills">
          {isLoading && (
            <Nav.Link className="placeholder-glow w-100">
              <span className="placeholder col-9 bg-primary placeholder-xs"></span>
              <span className="placeholder col-12 bg-primary placeholder-xs"></span>
              <span className="placeholder col-4 bg-primary placeholder-xs"></span>
            </Nav.Link>
          )}
          {error && <ErrorHandler error={error} />}
          {Array.isArray(audits) &&
            audits.map((item) => {
              const href = `/audits/${item.auditId}`;
              const isActive = item.auditId === auditId;

              return (
                <Nav.Link
                  as={Link}
                  to={href}
                  active={isActive}
                  key={item.auditId}
                  className={`w-100`}
                >
                  <h5 className="mb-1">{item.name}</h5>
                  <small>
                    <span
                      translate="no"
                      className="material-symbols-outlined md-16"
                    >
                      policy
                    </span>{" "}
                    {item?.protocol?.name}
                  </small>
                </Nav.Link>
              );
            })}
        </Nav>
      </Collapse>
      <hr />
    </>
  );
};
GroupNavigation.propTypes = {};
export default GroupNavigation;
