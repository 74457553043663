import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import { OrganizationContext } from "contexts/OrganizationProvider";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Alert, Table } from "react-bootstrap";
import { get } from "utils/DeApi";
import { setAccentColor } from "utils/EntityTagUtils";
import ActionItemTagCreate from "./ActionItemTagCreate";
import ActionItemTagUpdate from "./ActionItemTagUpdate";
import ActionItemTagDelete from "./ActionItemTagDelete";

const ActionItemTags = () => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const isAdminOrOwner =
    organization?.invitation?.role === "Owner" ||
    organization?.invitation?.role === "Admin";

  const handleTagCreated = (tag) => {
    fetchTags();
  };

  const handleTagUpdated = (tag) => {
    fetchTags();
  };

  const fetchTags = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const tagsPromise = get(`organizations/${organization?.id}/tags`);
    tagsPromise.promise
      .then(({ data: tags }) => {
        tags.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setTags(tags);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(tagsPromise);
  }, [organization]);

  useEffect(() => {
    fetchTags();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchTags, organization?.id]);

  if (!isAdminOrOwner) return <></>;

  return (
    <>
      <div className="d-flex flex-row mt-5">
        <div className="flex-fill">
          <h4>Action Item tags</h4>
        </div>
        <div>
          <ActionItemTagCreate onTagCreated={(tag) => handleTagCreated(tag)} />
        </div>
      </div>
      <hr />
      <Table hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Color</th>
            <th>Description</th>
            <th>Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {isLoading ? (
            <tr>
              <td colSpan={5}>
                <Loader />
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan={5}>
                <ErrorHandler error={error} />
              </td>
            </tr>
          ) : Array.isArray(tags) && !tags.length ? (
            <tr>
              <td colSpan={5}>
                <Alert variant="info" className={`my-3 d-flex flex-row`}>
                  <div className="me-3">
                    <span
                      translate="no"
                      className="material-symbols-outlined md-18 text-primary text-opacity-75"
                    >
                      info
                    </span>
                  </div>
                  <div>
                    <h5 className="mb-1">
                      <small>No tags </small>
                    </h5>
                    <p className="mb-1">
                      <small>Tags will appear here once added</small>
                    </p>
                  </div>
                </Alert>
              </td>
            </tr>
          ) : (
            <></>
          )}
          {tags.map((tag) => {
            return (
              <tr key={tag.labelId}>
                <td>{tag.name}</td>
                <td>
                  <small
                    className="px-2 py-1"
                    style={{
                      backgroundColor: tag?.color || "#C1C1C1",
                      color: setAccentColor(tag?.color),
                    }}
                  >
                    {tag?.color || "#C1C1C1"}
                  </small>
                </td>
                <td>{tag.description}</td>
                <td className="text-nowrap">
                  <small>
                    {new Date(tag.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                </td>
                <td className="text-nowrap">
                  <ActionItemTagUpdate
                    tag={tag}
                    onTagUpdated={handleTagUpdated}
                  />
                  <ActionItemTagDelete
                    tag={tag}
                    onTagDeleted={(tag) =>
                      setTags((prevStatus) =>
                        prevStatus.filter(
                          ({ labelId }) => labelId !== tag.labelId
                        )
                      )
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ActionItemTags;
