import { colorMap } from "features/assessment/constants";
import PropTypes from "prop-types";
const FormatResponseStatic = ({ question = {}, value = {} }) => {
  if (question?.properties?.fieldType !== "radio") return <></>;
  if (!question?.prompt.toLowerCase().includes("conformity")) return <></>;

  const index = question.choices.findIndex(({ id }) => id === value?.response);
  return (
    <span
      className="float-end rounded-2 px-3"
      style={{ backgroundColor: colorMap[index]?.color }}
    >
      <p
        className={`my-0 py-1 ${
          index === 2 || index === 3 ? "text-dark" : "text-white"
        }`}
      >
        <small className="d-flex">{question.choices[index]?.label}</small>
      </p>
    </span>
  );
};
FormatResponseStatic.propTypes = {
  question: PropTypes.object.isRequired,
  value: PropTypes.object,
};

export default FormatResponseStatic;
