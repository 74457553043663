import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Form, Offcanvas, Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";
import PropTypes from "prop-types";

import { upload } from "utils/DeApi";
import GeoTagUpdateFields from "./GeoTagUpdateFields";
import GeoTagUpdateImage from "./GeoTagUpdateImage";
import GeoTagFooter from "../GeoTagFooter";
import { OrganizationContext } from "contexts/OrganizationProvider";

const GeoTagUpdate = ({ location, onGeoTagUpdate, handleError }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [geoLocation, setGeoLocation] = useState(location);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { title, longitude, latitude, id } = geoLocation;
  const removePac = () => {
    document.body.querySelector(".pac-container")?.remove();
  };
  const handleClose = () => {
    setStep(0);
    setShow(false);
    removePac();
    setGeoLocation(location);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    title: yup.string().required("This field is required"),
    longitude: yup
      .number()
      .test(
        "only-digits",
        "Coordinate is invalid",
        (value) => typeof value === "number" && !/[eE]/.test(value.toString()) //checking for e and E
      )
      .min(-180, "Coordinate is invalid")
      .max(180, "Coordinate is invalid")
      .required("This field is required"),
    latitude: yup
      .number()
      .test(
        "only-digits",
        "Coordinate is invalid",
        (value) => typeof value === "number" && !/[eE]/.test(value.toString()) //checking for e and E
      )
      .min(-90, "Coordinate is invalid")
      .max(90, "Coordinate is invalid")
      .required("This field is required"),
    images: yup.array(),
  });

  const updateGeoTag = (values, actions) => {
    handleError("");
    setIsLoading(true);
    const { title, longitude, latitude } = geoLocation;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("longitude", longitude);
    formData.append("latitude", latitude);

    const geoTagPromise = upload(`/geotags/${id}`, formData);

    geoTagPromise.promise
      .then(({ data }) => onGeoTagUpdate(data))
      .catch((error) => {
        !error.isCanceled && handleError(error);
        setGeoLocation(location);
      })
      .finally(() => {
        setIsLoading(false);
        handleClose();
        actions.resetForm();
      });

    subscribedPromises.current.push(geoTagPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (organization?.invitation?.role === "Certifier") return <></>;

  return (
    <>
      <Button
        className="border-dark border-opacity-25"
        variant="outline-primary"
        size="sm"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-18">
          edit
        </span>
      </Button>

      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Formik
          validationSchema={schema}
          initialValues={{ title, longitude, latitude, images: [] }}
          onSubmit={updateGeoTag}
        >
          {() => (
            <Form>
              <Offcanvas.Header className="border-bottom" closeButton>
                <Offcanvas.Title>Update Geotag</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="vh-100">
                <Tabs
                  id="geotag-update-tabs"
                  activeKey={step}
                  onSelect={(k) => setStep(+k)}
                  className="mb-2"
                >
                  <Tab eventKey={0} title={"General Information"}>
                    <GeoTagUpdateFields
                      geoLocation={geoLocation}
                      setGeoLocation={setGeoLocation}
                    />
                  </Tab>
                  <Tab eventKey={1} title={"Location Photos"}>
                    <GeoTagUpdateImage
                      location={location}
                      geoLocation={geoLocation}
                      setGeoLocation={setGeoLocation}
                      onGeoTagUpdate={onGeoTagUpdate}
                    />
                  </Tab>
                </Tabs>
              </Offcanvas.Body>
              <GeoTagFooter
                step={step}
                setStep={setStep}
                isLoading={isLoading}
                handleClose={handleClose}
                submitButton={"Update"}
              />
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

GeoTagUpdate.propTypes = {
  onGeoTagUpdate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default GeoTagUpdate;
