const LabelWeight = ({ question, auditQuestion, value }) => {
  if (!question?.scoreable || !auditQuestion?.weight) return null;

  const choice = question?.choices
    ? question?.choices.find(({ id }) => id === value?.response)
    : {};

  const score =
    choice?.score && auditQuestion?.weight
      ? parseFloat(choice?.score) * parseFloat(auditQuestion?.weight)
      : "";

  return (
    <div className="text-muted me-2">
      <small>
        Weight: {auditQuestion?.weight} {!!score ? `| Score: ${score}` : ""}
      </small>
    </div>
  );
};

export default LabelWeight;
