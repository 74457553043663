import React, { useRef, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { truncate } from "lodash";
import PropTypes from "prop-types";

import DragAndDrop from "components/common/DragAndDrop";
import { upload } from "utils/DeApi";
import { getURLFileExtension } from "utils/StringUtils";
import GeoTagFileDelete from "../../GeoTagFileDelete";
import { useFormikContext } from "formik";
import { validateImageFormat, compressImage } from "utils/UploadUtils";

const GeoTagUpdateImage = ({
  location,
  geoLocation,
  setGeoLocation,
  onGeoTagUpdate,
}) => {
  const { errors, touched, setFieldValue, setFieldTouched, setErrors } =
    useFormikContext();
  const subscribedPromises = useRef([]);

  const { id, images } = geoLocation;
  const [isLoading, setIsLoading] = useState(false);

  const validateImageSize = (value) => {
    if (value && value?.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].size > 2048000) {
          return false;
        }
      }
    }
    return true;
  };

  const compressAndUpdateImage = async (img) => {
    const compressedImage = await compressImage(img[0]);
    setFieldValue("images", [compressedImage]);
    const validSize = validateImageSize([compressedImage]);
    if (validSize) onImageDrop([compressedImage], location, setFieldTouched);
    if (!validSize) setErrors({ images: "File Size is greater than 2 MB" });
  };

  const onImageDrop = (images, location, setFieldTouched) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", location?.title);
    formData.append("longitude", location?.longitude);
    formData.append("latitude", location?.latitude);
    if (images?.length) {
      for (let i = 0; i < images?.length; i++) {
        formData.append("images[]", images[i]);
      }
    }

    const geoTagPromise = upload(`/geotags/${id}`, formData);

    geoTagPromise.promise
      .then(({ data }) => {
        setGeoLocation(data);
        onGeoTagUpdate(data);
      })
      .catch((error) => {
        setGeoLocation(location);
      })
      .finally(() => {
        setFieldTouched("images", false, false);
        setIsLoading(false);
      });

    subscribedPromises.current.push(geoTagPromise);
  };
  return (
    <>
      <Form.Group controlId="images" className="mb-3">
        <Form.Label>Upload Files</Form.Label>
        <DragAndDrop
          replaceFile={true}
          preview={false}
          onDrop={(files) => {
            setFieldTouched("images", true, false);
            const validFormat = validateImageFormat(files);
            if (validFormat) compressAndUpdateImage(files);
            if (!validFormat)
              setErrors({
                images:
                  "Wrong File Type. Only images (.jpeg, .jpg, .png) are accepted.",
              });
          }}
        />
        {errors.images && touched?.images && (
          <small className="text-danger">{errors.images}</small>
        )}
      </Form.Group>
      {!!images?.length && (
        <Form.Group
          controlId="images"
          className="mb-3 border border-2 rounded p-2"
        >
          <Form.Label>
            Uploaded Files{" "}
            {isLoading && (
              <Spinner animation="border" size="sm" variant="dark" />
            )}
          </Form.Label>
          {images?.map((image) => {
            const imageURL = image.url || "";
            const imageExt = getURLFileExtension(imageURL);
            return (
              <Row
                key={image.id}
                className="m-0 py-2 d-flex align-items-center border bg-secondary bg-opacity-10 rounded mb-2"
              >
                <Col xs={3} md={5} lg={6}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {image?.name || `${image?.id}.${imageExt}`}
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <a
                        ref={ref}
                        href={imageURL}
                        target="_blank"
                        rel="noreferrer"
                        {...triggerHandler}
                      >
                        <div className="d-flex flex-row align-items-center">
                          <div>
                            <span
                              className={`fiv-sqo fiv-size-md lh-base fiv-icon-jpeg me-2 rounded`}
                            />
                          </div>

                          <span className="text-wrap">
                            {truncate(
                              image?.name || `${image?.id}.${imageExt}`,
                              {
                                length: 25,
                              }
                            )}
                          </span>
                        </div>
                      </a>
                    )}
                  </OverlayTrigger>
                </Col>
                <Col xs={9} md={7} lg={6} className="text-end">
                  <small className="ms-2">{image?.humanReadableSize}</small>
                  <small className="ms-2">
                    {new Date(image?.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                  <GeoTagFileDelete
                    setGeoLocation={setGeoLocation}
                    onGeoTagUpdate={onGeoTagUpdate}
                    id={image?.id}
                  />
                </Col>
              </Row>
            );
          })}
        </Form.Group>
      )}
    </>
  );
};

GeoTagUpdateImage.propTypes = {
  geoLocation: PropTypes.object.isRequired,
  setGeoLocation: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onGeoTagUpdate: PropTypes.func.isRequired,
};

export default GeoTagUpdateImage;
