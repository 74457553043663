import { useContext } from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { showCompliance } from "features/assessment/components/Audit/helper";

import FieldClearButton from "../FieldClearButton";

const FieldComplianceOption = ({ isDisabled }) => {
  const { audit } = useContext(AuditContext);
  const { values, setFieldValue } = useFormikContext();

  const { conformity, questionOptions } = audit?.protocol;
  const [, complianceLabel, , , , , ,] = questionOptions || [];
  const _showCompliance = showCompliance(conformity, questionOptions);

  if (!_showCompliance) return <></>;

  const clearSelection = () => {
    setFieldValue("compliance", null);
  };

  return (
    <Form.Group controlId="compliance" className="my-3">
      <Form.Label className="w-100">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <strong>{complianceLabel?.name || "Are you compliant?"}</strong>
            <RequiredAsterisk />
          </div>
          <FieldClearButton
            handleOnClick={clearSelection}
            isDisabled={isDisabled}
          />
        </div>
      </Form.Label>
      <br />
      <Form.Check
        inline
        label="Yes"
        name="compliance"
        value={1}
        checked={parseInt(values.compliance) === 1}
        disabled={values.isInapplicable || isDisabled}
        onChange={() => setFieldValue("compliance", 1)}
        type="radio"
      />
      <Form.Check
        inline
        label="No"
        name="compliance"
        value={0}
        checked={parseInt(values.compliance) === 0}
        disabled={values.isInapplicable || isDisabled}
        onChange={() => setFieldValue("compliance", 0)}
        type="radio"
      />
    </Form.Group>
  );
};

FieldComplianceOption.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldComplianceOption;
