import React from "react";
import PropTypes from "prop-types";

import ActionItemActionType from "../ActionItemActionType";
import ActionItemStatusHistory from "../ActionItemStatusHistory";
import ActionItemCommentsHistory from "../ActionItemCommentsHistory";

const ActionItemHistoryCard = ({
  causer: { firstName, lastName, email } = {},
  createdAt,
  type,
  subjectType,
  oldProperties: { done: previousStatus, body: previousComment } = {},
  newProperties: { done: currentStatus, body: currentComment } = {},
}) => {
  return (
    <tbody>
      <tr>
        <td className="border-0">
          <span className="fw-semibold">
            {firstName ?? "-"} {lastName ?? "-"}
          </span>
          <br />
          <small className="text-secondary fw-semibold">{email ?? "-"}</small>
        </td>
      </tr>
      <tr>
        <td className="border-0">
          <span className="text-secondary fw-semibold">Action:</span>
          <span className="ms-2 fw-semibold">
            <ActionItemActionType type={type} subjectType={subjectType} />
          </span>
        </td>
        <td className="border-0">
          <span className="text-secondary fw-semibold">Time Stamp:</span>
          <span className="ms-2 fw-semibold">
            {new Date(createdAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </span>
        </td>
      </tr>
      {subjectType === "comment" ? (
        <ActionItemCommentsHistory
          previousComment={previousComment ?? ""}
          currentComment={currentComment}
          type={type}
        />
      ) : (
        <ActionItemStatusHistory
          previousStatus={previousStatus}
          currentStatus={currentStatus}
        />
      )}
    </tbody>
  );
};

ActionItemHistoryCard.propTypes = {
  causer: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
};

export default ActionItemHistoryCard;
