import { useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { useRoles } from "hooks";
import { Button, Dropdown, Form, Modal, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { useAuditMapping } from "features/assessment/hooks";

const ObservationLabelUpdate = ({ observation, updateObservations }) => {
  const { isCertifier } = useRoles();
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { observationCreateOrUpdateMapping } = useAuditMapping();

  const schema = yup.object().shape({
    observationLabel: yup
      .string()
      .min(2, "Observation label is too Short!")
      .max(100, "Observation label is too Long!")
      .required("Observation label is required"),
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleOnSubmit = (values) => {
    setError("");
    setIsLoading(true);

    const observationPromise = put(
      `observations/${observation.observationId}`,
      {
        auditId: observation?.auditId,
        questionId: observation?.questionId,
        parentId: observation?.parentId,
        label: values?.observationLabel || "Observation 1",
      }
    );
    observationPromise.promise
      .then(({ data }) => {
        handleClose();
        observationCreateOrUpdateMapping(data || {});
      })

      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    subscribedPromises.current.push(observationPromise);
  };

  useState(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  if (!observation?.observationId || isCertifier) return;

  return (
    <div
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onFocus={(e) => e.stopPropagation()}
      onMouseOver={(e) => e.stopPropagation()}
    >
      <Dropdown.Item onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-16">
          edit
        </span>{" "}
        Update
      </Dropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Observation Name</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnSubmit}
          initialValues={{
            observationLabel: observation?.label || "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="observationLabel" className="mb-3">
                  <Form.Label>Observation Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="observationLabel"
                    value={values.observationLabel}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isValid={
                      values.observationLabel && !errors.observationLabel
                    }
                    isInvalid={
                      !(values.observationLabel && !errors.observationLabel) &&
                      touched.observationLabel
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.observationLabel && touched.observationLabel ? (
                      <small>{errors.observationLabel}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  variant="primary"
                  className="px-3"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  {isLoading && (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      className="me-2"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}{" "}
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ObservationLabelUpdate;
