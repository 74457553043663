import { useState } from "react";
import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";

import "./EntityTypeahead.scss";
import { useFetchEntities } from "services";

const EntityTypeahead = ({
  onChange,
  isValid,
  selected,
  placeholder = "Select by Entities",
}) => {
  const { isLoading, error, entities } = useFetchEntities({ perPage: 1000 });
  const [entity, setEntity] = useState(Array.isArray(selected) ? selected : []);

  error && console.error(error);

  const handleOnChange = (data) => {
    onChange(data);
    setEntity(data);
  };

  return (
    <Typeahead
      multiple
      clearButton
      labelKey="name"
      selected={entity}
      options={entities}
      id="entityTypeahead"
      isLoading={isLoading}
      onChange={handleOnChange}
      placeholder={placeholder}
      isValid={Boolean(isValid)}
    />
  );
};

EntityTypeahead.propTypes = {
  isValid: PropTypes.any,
  selected: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default EntityTypeahead;
