import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import { useFetchSearchContents } from "features/search/hooks/useFetchSearchContents";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Card, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Search.scss";
import SearchPagination from "../SearchPagination";

const SearchResult = ({
  searchTerm,
  setIsLoading,
  isLoading,
  setPage,
  page,
}) => {
  const { error, totalPages, searchResult } = useFetchSearchContents(
    searchTerm,
    page,
    setIsLoading
  );
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  if (!searchResult) return;

  const extractedData = searchResult.map(
    ({
      question,
      assessmentId,
      highlight,
      entityName,
      assessmentName,
      createdAt,
      sectionId,
      questionId,
    }) => ({
      question,
      assessmentId,
      highlight,
      entityName,
      assessmentName,
      createdAt,
      sectionId,
      questionId,
    })
  );

  const generateRandomKey = () => crypto.randomUUID();

  return (
    <Card className="search-result">
      <Card.Body>
        {searchResult.length ? (
          <>
            {extractedData.map((tmpData) => {
              const tmpPathname = `/audits/${tmpData.assessmentId}/sections/${tmpData.sectionId}/questions/${tmpData.questionId}`;

              return (
                <React.Fragment key={generateRandomKey()}>
                  <p>
                    <Link to={tmpPathname}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            tmpData.question.length > 72
                              ? tmpData.question.substring(0, 72) + "..."
                              : tmpData.question,
                        }}
                      />
                    </Link>
                  </p>

                  <p>
                    {tmpData.entityName} / {tmpData.assessmentName}
                  </p>

                  {Object.keys(tmpData.highlight).map((key) => (
                    <p key={generateRandomKey()}>
                      {tmpData.highlight[key].map(
                        (highlightData, index, array) => (
                          <React.Fragment key={generateRandomKey()}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: highlightData,
                              }}
                            />
                            {index !== array.length - 1 && <>, ... </>}
                          </React.Fragment>
                        )
                      )}
                    </p>
                  ))}

                  <p className="small">
                    {new Date(tmpData.createdAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </p>

                  <hr />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <Alert variant="info">No results found</Alert>
        )}

        <div className="d-flex justify-content-end">
          <Pagination>
            <Pagination.First
              onClick={() => setPage(1)}
              disabled={page === 1}
            />
            <Pagination.Prev
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            />

            <SearchPagination
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />

            <Pagination.Next
              onClick={() => setPage(page + 1)}
              disabled={page === totalPages}
            />
            <Pagination.Last
              onClick={() => setPage(totalPages)}
              disabled={page === totalPages}
            />
          </Pagination>
        </div>
      </Card.Body>
    </Card>
  );
};

SearchResult.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

export default SearchResult;
