import React, { useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import { COLS } from "utils/EntityTagUtils";
import ErrorHandler from "components/ui/ErrorHandler";
import ColorPicker from "components/ui/ColorPicker";
import RequiredAsterisk from "components/ui/RequiredAsterisk";

const ActionItemTagUpdate = ({ tag, onTagUpdated }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const handleClose = () => {
    setError(null);
    setShow(false);
    setColorPickerOpen(false);
  };
  const handleShow = () => setShow(true);

  const updateTag = (values) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = put(`/labels/${tag?.labelId}`, {
      name: values.name,
      color: values.color,
      description: values.description,
    });
    auditPromise.promise
      .then(({ data: tag }) => {
        handleClose();
        onTagUpdated(tag);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  const schema = yup.object().shape({
    name: yup.string().min(2).max(255).required("Name is required"),
    color: yup.string().min(2).max(255).required("Color is required"),
    description: yup.string().nullable().notRequired(),
  });

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const handleToggleColorPicker = (e) => {
    e.preventDefault();
    setColorPickerOpen(!colorPickerOpen);
  };

  return (
    <>
      <Button size="sm" onClick={handleShow} variant="outline-primary">
        <span translate="no" className="material-symbols-outlined md-18">
          edit
        </span>
      </Button>

      <Offcanvas
        show={show}
        placement="end"
        onHide={handleClose}
        className="w-fixed-640"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Tag</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          <Formik
            validationSchema={schema}
            onSubmit={(values) => updateTag(values)}
            initialValues={{
              name: tag?.name || "",
              color: tag?.color || "",
              description: tag?.description || "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="d-flex flex-column justify-content-between h-100">
                <div onClick={colorPickerOpen ? handleToggleColorPicker : null}>
                  <Form.Group controlId="name" className="mb-3">
                    <Form.Label className="mb-1">
                      Name <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isValid={values.name && !errors.name}
                      isInvalid={!(values.name && !errors.name) && touched.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name && touched.name ? (
                        <small>{errors.name}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="color" className="mb-3">
                    <Form.Label className="mb-1">
                      Tag color <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="color"
                      name="color"
                      style={{ width: COLS * 40 }}
                      onBlur={handleBlur}
                      value={values.color}
                      onChange={handleChange}
                      onClick={handleToggleColorPicker}
                      isValid={values.color && !errors.color}
                      isInvalid={
                        !(values.color && !errors.color) && touched.color
                      }
                    />
                    <ColorPicker
                      name="color"
                      value={values.color}
                      handleChange={handleChange}
                      visible={colorPickerOpen}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.color && touched.color ? (
                        <small>{errors.color}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mb-1">Description</Form.Label>
                    <Form.Control
                      as={"textarea"}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      placeholder="Enter your description here"
                      isValid={values.description && !errors.description}
                      isInvalid={!!errors.description && touched.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description && touched.description ? (
                        <small>{errors.description}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {error && <ErrorHandler error={error} />}
                </div>

                <div className="text-end mt-auto">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    className="float-end px-3 ms-3"
                    onClick={handleSubmit}
                    disabled={
                      Object.keys(errors).length > 0 || isLoading || !isValid
                    }
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Update Tag
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ActionItemTagUpdate.propTypes = {
  onTagUpdated: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
};

export default ActionItemTagUpdate;
