import React, { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Button, Col, Dropdown, Row } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import ActionItemCommentDelete from "../../ActionItemComment/ActionItemCommentDelete";
import ActionItemCommentUpdate from "../../ActionItemComment/ActionItemCommentUpdate";

const sortBy = [
  {
    index: 0,
    name: "Oldest To Newest",
    value: "oldestToNewest",
  },
  {
    index: 1,
    name: "Newest To Oldest",
    value: "newestToOldest",
  },
];

const ActionItemUpdateComment = ({
  actionItemComment,
  setActionItemComment,
}) => {
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const {
    values: { step, actionItem },
  } = useFormikContext();

  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [showUpdateForm, setShowUpdateForm] = useState("");
  const [sortValue, setSortValue] = useState(sortBy[0]);

  const isAdminOrOwner =
    organization?.invitation?.role === "Admin" ||
    organization?.invitation?.role === "Owner";

  const commentCreator = (user, subscriber) => {
    return user?.subscriberId === subscriber?.subscriberId;
  };

  const handleCommentUpdated = (updatedComment) => {
    setActionItemComment((prevComments) => {
      return prevComments.map((comment) => {
        if (updatedComment?.id === comment?.id) return updatedComment;
        return comment;
      });
    });

    setShowUpdateForm("");
  };

  const handleActionItemCommentDelete = (deletedComment) => {
    setActionItemComment((prevActionItemComments) => {
      const comments = prevActionItemComments.filter((comment) => {
        return deletedComment?.id !== comment?.id;
      });
      return comments;
    });
  };

  useEffect(() => {
    const fetchActionItemComment = () => {
      setError("");
      setIsLoading(true);
      const actionItemCommentsPromise = get(
        `/action-items/${actionItem?.actionItemId}/comments`
      );
      actionItemCommentsPromise.promise
        .then(({ data: comments }) => {
          setIsLoading(false);
          setActionItemComment(
            comments?.sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt);
            })
          );
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(actionItemCommentsPromise);
    };

    if (step === 2) fetchActionItemComment();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [step, actionItem?.actionItemId, setActionItemComment]);
  return (
    <div className="vh-100">
      {!isLoading &&
        !error &&
        Array.isArray(actionItemComment) &&
        !actionItemComment.length && (
          <EmptyStateHandler
            title="No Action Item Comments"
            description="There are currently no comments to display."
          />
        )}
      {isLoading && <Loader />}
      {error && <ErrorHandler error={error} />}

      {!!actionItemComment?.length && (
        <Row className="mb-3 text-secondary fs-5">
          <Col className="d-flex align-items-center justify-content-end">
            Sort By:
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-done"
                className="ms-2 text-start border border-gray-900 bg-white text-secondary"
              >
                {sortValue?.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortBy.map((s) => (
                  <Dropdown.Item
                    key={s.index}
                    active={s.index === sortValue.index}
                    onClick={() => {
                      setActionItemComment((prevActionItemComments) => {
                        setSortValue(s);
                        return prevActionItemComments?.sort((a, b) => {
                          return s?.value === "oldestToNewest"
                            ? new Date(a.createdAt) - new Date(b.createdAt)
                            : new Date(b.createdAt) - new Date(a.createdAt);
                        });
                      });
                    }}
                  >
                    {s.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      )}
      <Row className="comments-overflow">
        {Array.isArray(actionItemComment) &&
          actionItemComment.map((comment) => {
            const { id, subscriber, body, createdAt, updatedAt } = comment;
            return (
              <Row className="mb-3" key={id}>
                <Col xs={1} className="me-2">
                  <div className="avatar d-flex align-items-center justify-content-center rounded-circle bg-primary bg-opacity-10 text-primary text-uppercase">
                    <strong>
                      {subscriber?.firstName?.[0]}
                      {subscriber?.lastName?.[0]}
                    </strong>
                  </div>
                </Col>
                <Col>
                  <Col className="d-flex justify-content-between align-items-end">
                    <h5 className="text-secondary text-capitalize">
                      {subscriber?.firstName} {subscriber?.lastName}
                    </h5>
                    <div>
                      {commentCreator(user, subscriber) && (
                        <>
                          {showUpdateForm !== id ? (
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => {
                                setShowUpdateForm(id);
                              }}
                            >
                              <span
                                translate="no"
                                className="material-symbols-outlined md-18"
                              >
                                edit
                              </span>
                            </Button>
                          ) : (
                            <Button
                              size="sm"
                              variant="outline-secondary"
                              onClick={() => {
                                setShowUpdateForm("");
                              }}
                            >
                              <span
                                translate="no"
                                className="material-symbols-outlined md-18"
                              >
                                close
                              </span>
                            </Button>
                          )}
                        </>
                      )}
                      {(isAdminOrOwner || commentCreator(user, subscriber)) && (
                        <ActionItemCommentDelete
                          handleActionItemCommentDelete={
                            handleActionItemCommentDelete
                          }
                          comment={comment}
                          actionItemId={actionItem?.actionItemId}
                        />
                      )}
                    </div>
                  </Col>
                  {showUpdateForm !== id ? (
                    <>
                      <p className="mb-2 text-break text-wrap">{body}</p>
                      <p className="text-muted">
                        <small className="me-1">
                          {new Date(updatedAt).toLocaleString([], {
                            dateStyle: "short",
                            timeStyle: "short",
                          })}
                        </small>
                        {createdAt !== updatedAt && <span> · edited</span>}
                      </p>
                    </>
                  ) : (
                    <ActionItemCommentUpdate
                      comment={comment}
                      onActionItemCommentUpdated={handleCommentUpdated}
                    />
                  )}
                  <hr />
                </Col>
              </Row>
            );
          })}
      </Row>
    </div>
  );
};

ActionItemUpdateComment.propTypes = {
  actionItemComment: PropTypes.array,
  setActionItemComment: PropTypes.func,
};

export default ActionItemUpdateComment;
