import ErrorHandler from "components/ui/ErrorHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import useDownloadAuditCSV from "features/assessment/services/useDownloadAuditCSV";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { useContext } from "react";
import { Button, Spinner } from "react-bootstrap";

function AuditCSVExport() {
  const { audit } = useContext(AuditContext);
  const { isCertifier, isContributor } = useRoles();
  const { error, isLoading, downloadObservation } = useDownloadAuditCSV(audit);

  if (isCertifier || isContributor) return <></>;

  return (
    <>
      {error && <ErrorHandler error={error} />}
      <Button
        disabled={isLoading}
        className="btn-sm bg-primary bg-opacity-10 text-dark border-0 px-3"
        onClick={downloadObservation}
      >
        <span translate="no">
          {isLoading && (
            <Spinner
              className="me-2"
              animation="border"
              size="sm"
              variant="primary"
            />
          )}
        </span>
        <span>Raw Excel (CSV)</span>
      </Button>
    </>
  );
}
AuditCSVExport.propTypes = {
  audit: PropTypes.object.isRequired,
};

export default AuditCSVExport;
