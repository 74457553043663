import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Button,
  Form,
  Modal,
  Offcanvas,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import PropTypes from "prop-types";
import * as yup from "yup";

import { Formik } from "formik";
import { upload } from "utils/DeApi";
import GeoTagCreateFields from "./GeoTagCreateFields";
import GeoTagCreateImage from "./GeoTagCreateImage";
import GeoTagFooter from "../GeoTagFooter";
import { OrganizationContext } from "contexts/OrganizationProvider";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

const GeoTagCreate = ({
  onGeoTagCreate,
  observationId,
  handleError,
  createObservation,
  variant = "light",
  copy = "Create",
  disabled,
  isExpanding: isLoading,
  isExpandingError,
}) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [geoLocation, setGeoLocation] = useState({
    id: 0,
    title: "",
    longitude: 0,
    latitude: 0,
    images: [],
  });
  const [show, setShow] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(0);

  const removePac = () => {
    document.body.querySelector(".pac-container")?.remove();
  };
  const handleClose = () => {
    setShow(false);
    removePac();
  };
  const handleShow = () => setShow(true);

  const handleCloseWarningModal = () => setShowWarningModal(false);
  const handleShowWarningModal = () => setShowWarningModal(true);

  const schema = yup.object().shape({
    title: yup.string().required("This field is required"),
    longitude: yup
      .number()
      .test(
        "only-digits",
        "Coordinate is invalid",
        (value) => typeof value === "number" && !/[eE]/.test(value.toString()) //checking for e and E
      )
      .min(-180, "Coordinate is invalid")
      .max(180, "Coordinate is invalid")
      .required("This field is required"),
    latitude: yup
      .number()
      .test(
        "only-digits",
        "Coordinate is invalid",
        (value) => typeof value === "number" && !/[eE]/.test(value.toString()) //checking for e and E
      )
      .min(-90, "Coordinate is invalid")
      .max(90, "Coordinate is invalid")
      .required("This field is required"),
    images: yup.array(),
  });

  const createGeoLocation = () => {
    const { title, longitude, latitude, images } = geoLocation;
    handleError("");
    setIsExpanding(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("longitude", longitude);
    formData.append("latitude", latitude);
    if (images?.length) {
      for (let i = 0; i < images?.length; i++) {
        formData.append("images[]", images[i]);
      }
    }
    const geoTagPromise = upload(
      `/observations/${observationId}/geotags`,
      formData
    );

    geoTagPromise.promise
      .then(({ data }) => onGeoTagCreate(data))
      .catch((error) => !error.isCanceled && handleError(error))
      .finally(() => {
        resetForm();
        handleClose();
        setIsExpanding(false);
      });

    subscribedPromises.current.push(geoTagPromise);
  };

  const resetForm = () => {
    setGeoLocation({
      title: "",
      longitude: "",
      latitude: "",
      images: [],
    });
    setError(false);
    setStep(0);
  };

  useEffect(() => {
    const fetchLatitudeAndLongitude = () => {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = String(position.coords.latitude.toFixed(6));
          const longitude = String(position.coords.longitude.toFixed(6));
          setGeoLocation((prev) => ({ ...prev, longitude, latitude }));
        },
        (error) => error.code === 1 && setError(true)
      );
    };
    fetchLatitudeAndLongitude();
    if (!show) resetForm();

    const permissionQuery = navigator.permissions.query({
      name: "geolocation",
    });
    permissionQuery.then((result) => {
      result.onchange = () => {
        if (show) fetchLatitudeAndLongitude();
      };
    });
    return () => {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        result.onchange = null;
      });
    };
  }, [show]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (organization?.invitation?.role === "Certifier") return <></>;

  return (
    <>
      <Button
        size="sm"
        variant={variant}
        disabled={disabled}
        className="text-start bg-primary bg-opacity-25 rounded-3 text-dark me-2"
        onClick={
          observationId !== undefined ? handleShow : handleShowWarningModal
        }
      >
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          add
        </span>
        {copy}
      </Button>

      <Modal show={showWarningModal} onHide={handleCloseWarningModal}>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmptyStateHandler
            className={` d-flex flex-row mx-1 my-3`}
            title="Observation not Saved"
            description="You can geotags once you have saved the observation"
          />
          {isExpandingError && <ErrorHandler error={isExpandingError} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={handleCloseWarningModal}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="sm"
            disabled={isLoading}
            onClick={createObservation}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Formik
          validationSchema={schema}
          initialValues={{
            title: geoLocation?.title,
            longitude: geoLocation?.longitude,
            latitude: geoLocation?.latitude,
            images: geoLocation?.images || [],
          }}
          onSubmit={createGeoLocation}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Header className="border-bottom" closeButton>
                <Offcanvas.Title>Create Geotag</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="vh-100">
                <Tabs
                  id="geotag-create-tabs"
                  activeKey={step}
                  onSelect={(k) => setStep(+k)}
                  className="mb-2"
                >
                  <Tab eventKey={0} title={"General Information"}>
                    <GeoTagCreateFields
                      geoLocation={geoLocation}
                      setGeoLocation={setGeoLocation}
                      error={error}
                    />
                  </Tab>
                  <Tab eventKey={1} title={"Location Photos"}>
                    <GeoTagCreateImage setGeoLocation={setGeoLocation} />
                  </Tab>
                </Tabs>
              </Offcanvas.Body>
              <GeoTagFooter
                step={step}
                setStep={setStep}
                isLoading={isExpanding}
                handleClose={handleClose}
                submitButton={"Create"}
              />
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

GeoTagCreate.propTypes = {
  onGeoTagCreate: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  createObservation: PropTypes.func.isRequired,
  observationId: PropTypes.string.isRequired,
};

export default GeoTagCreate;
