import Color from "colorjs.io";
import PropTypes from "prop-types";

const FormatResponseCompute = ({ question, value }) => {
  if (question?.properties?.fieldType !== "radio") return <></>;

  const isFindingOrBestPractice = question.choices.find(
    ({ typeLabel }) => typeLabel === "Finding" || typeLabel === "Best Practise"
  );

  if (!isFindingOrBestPractice) return <></>;

  const color = new Color("green");

  const colorSteps = color
    .steps("red", {
      steps: 0,
      maxDeltaE: 3,
      space: "lch",
      outputSpace: "srgb",
      maxSteps: question?.choices?.length,
    })
    .reverse();

  const selectedIndex = question.choices
    .sort((a, b) => {
      if (a.score < b.score) return 1;
      if (a.score > b.score) return -1;
      return 0;
    })
    .findIndex(({ id }) => {
      if (!value) return false;
      if (!value[question.id]) return false;
      return id === value[question.id];
    });

  if (selectedIndex === -1) return <></>;

  return (
    <span
      className="float-end rounded-2 px-3"
      style={{
        backgroundColor: colorSteps[selectedIndex].toString(),
      }}
    >
      <strong>{question.choices[selectedIndex].label}</strong>
      <p className={`my-0 py-1 text-white`}>
        <small className="d-flex">
          {question.choices[selectedIndex].label}
        </small>
      </p>
    </span>
  );
};
FormatResponseCompute.propTypes = {
  question: PropTypes.object.isRequired,
  value: PropTypes.any,
};

export default FormatResponseCompute;
