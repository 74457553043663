import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Row, Col, Card, Table, Form, Badge } from "react-bootstrap";
import moment from "moment";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import { UserContext } from "contexts/UserProvider";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import ActionItemBulkOperation from "features/actionItems/components/ActionItems/ActionItemsBulkOperation/ActionItemBulkOperation";
import ActionItemUpdate from "features/actionItems/components/common/ActionItemUpdate";
import MetaPagination from "components/ui/MetaPagination";

import { useRoles } from "hooks";

const CorrectiveActionItems = ({ tabKey }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [meta, setMeta] = useState({});

  const { isCertifier, isAdmin } = useRoles();

  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const { audit } = useContext(AuditContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(
    function resetSelectedItems() {
      // reset selected items to those still in the list

      setSelectedItems((prevSelectedItems) => {
        const selectedActionItems = actionItems.filter((item) =>
          prevSelectedItems.includes(item.actionItemId)
        );
        return selectedActionItems.map((item) => item.actionItemId);
      });
    },
    [actionItems]
  );

  const fetchActionItems = useCallback(() => {
    setIsLoading(true);
    setError(null);
    const subscribersPromise = get(
      `subscribers/${user?.de?.subscriberId}/action-items`,
      {
        params: {
          page,
          perPage: perPage,
          organizationId: organization?.id,
          "filter[type]": "question",
          "filter[audit_id]": audit?.auditId,
          "filter[status]": "0, 1, 2",
        },
      }
    );

    subscribersPromise.promise
      .then(({ data, meta }) => {
        setActionItems(
          data.filter(
            (item) => item.taskableType === "question" && item.taskableId
          )
        );
        setMeta(meta);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(subscribersPromise);
  }, [user, organization, audit?.auditId, page, perPage]);

  useEffect(() => {
    if (!isCertifier && tabKey === "actionItems") fetchActionItems();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [isCertifier, tabKey, fetchActionItems]);

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="px-1 mb-3">
        <Card className="border-0">
          <Card.Body>
            {isAdmin ? (
              <div className="d-flex flex-row mb-2 justify-content-between align-items-center position-relative">
                <div></div>
                <div className="d-flex">
                  <ActionItemBulkOperation
                    items={actionItems}
                    selectedItems={selectedItems}
                    modalBtnVariant={"primary"}
                    facilityId={audit?.facilityId}
                    onActionItemsUpdated={(updatedActionItems) => {
                      setActionItems((prevActionItems) => {
                        return prevActionItems.map((item) => {
                          const updatedItem = updatedActionItems.find(
                            (actionItem) =>
                              actionItem.actionItemId === item.actionItemId
                          );
                          if (updatedItem) {
                            return updatedItem;
                          }
                          return item;
                        });
                      });
                      setSelectedItems([]);
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className="table-responsive">
              <Table className="cursor-default" hover responsive>
                <ActionItemsHeader
                  actionItems={actionItems}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
                <ActionItemsBody
                  actionItems={actionItems}
                  setActionItems={setActionItems}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  isLoading={isLoading}
                  error={error}
                />
              </Table>
            </div>
            <div>
              <MetaPagination
                meta={meta}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CorrectiveActionItems;

const ActionItemsHeader = ({
  actionItems,
  selectedItems,
  setSelectedItems,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { isAdmin } = useRoles();

  useEffect(
    function updateIsCheckedStatus() {
      setIsChecked(
        selectedItems.length > 0 &&
          selectedItems.length === actionItems.length &&
          selectedItems.every((selectedItem) => {
            // exists in actionItems
            return actionItems.find(
              (actionItem) => actionItem.actionItemId === selectedItem
            );
          })
      );
    },
    [selectedItems, actionItems]
  );

  const handleSelectAllChange = (e) => {
    setSelectedItems(
      e.target.checked
        ? actionItems.map((actionItem) => actionItem.actionItemId)
        : []
    );
    setIsChecked(e.target.checked);
  };

  return (
    <thead>
      <tr className="align-baseline">
        {isAdmin ? (
          <th>
            <Form.Check
              type="checkbox"
              title="Select all items in this page"
              checked={isChecked}
              onChange={handleSelectAllChange}
            />
          </th>
        ) : null}
        <th className="text-wrap">Title</th>
        <th className="text-nowrap">Assigned To</th>
        <th className="text-nowrap">Due On</th>
        <th>Action</th>
      </tr>
    </thead>
  );
};

const ActionItemsBody = ({
  actionItems,
  setActionItems,
  selectedItems,
  setSelectedItems,
  isLoading,
  error,
}) => {
  const { isAdmin } = useRoles();

  if (isLoading)
    return (
      <tbody className="table-group-divider">
        <tr>
          <td colSpan={12}>
            <Loader />
          </td>
        </tr>
      </tbody>
    );
  if (error)
    return (
      <tbody className="table-group-divider">
        <tr>
          <td colSpan={12}>
            <ErrorHandler error={error} />
          </td>
        </tr>
      </tbody>
    );
  if (!Array.isArray(actionItems) || !actionItems?.length)
    return (
      <tbody className="table-group-divider">
        <tr>
          <td colSpan={12}>
            <EmptyStateHandler
              title="No action items"
              description="Try checking another assessment or create new action items from the question tab."
            />
          </td>
        </tr>
      </tbody>
    );

  return (
    <tbody className="table-group-divider">
      {actionItems.map((actionItem) => (
        <tr key={actionItem.actionItemId}>
          {isAdmin ? (
            <td>
              <Form.Check
                type="checkbox"
                title="Select for bulk update"
                checked={
                  selectedItems?.find((it) => it === actionItem.actionItemId) ||
                  false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedItems([
                      ...selectedItems,
                      actionItem.actionItemId,
                    ]);
                  } else {
                    setSelectedItems(
                      selectedItems.filter(
                        (it) => it !== actionItem.actionItemId
                      )
                    );
                  }
                }}
              />
            </td>
          ) : null}
          <td>
            {actionItem.item} <br />{" "}
            <small className="text-secondary">{actionItem.actionItemId}</small>
          </td>
          <td className="text-nowrap">
            {actionItem.assignedTo?.map(({ firstName, lastName }) => (
              <span>
                {firstName} {lastName} <br />
              </span>
            ))}
          </td>
          <td className="text-nowrap">
            <small className="text-nowrap">
              {actionItem.dueDate ? (
                <>
                  {new Date(actionItem.dueDate).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}{" "}
                  {moment(actionItem.dueDate).isBefore(
                    moment(new Date()).utc().format("YYYY-MM-DD")
                  ) &&
                    actionItem.statusLabel !== "Accepted" && (
                      <>
                        <Badge className="bg-danger bg-opacity-10 text-danger">
                          Overdue
                        </Badge>
                      </>
                    )}
                </>
              ) : (
                "N/A"
              )}
            </small>
          </td>
          <td>
            <div className="text-end">
              <ActionItemUpdate
                actionItem={actionItem}
                onActionItemUpdated={(actionItem) => {
                  setActionItems((prevActionItems) => {
                    return prevActionItems.map((item) =>
                      actionItem.actionItemId === item.actionItemId
                        ? actionItem
                        : item
                    );
                  });
                }}
                onActionItemDeleted={(deletedActionItem) => {
                  setActionItems((prevActionItems) => {
                    const filterActionItem = prevActionItems.filter(
                      (actionItem) => {
                        return (
                          deletedActionItem?.actionItemId !==
                          actionItem?.actionItemId
                        );
                      }
                    );
                    return filterActionItem;
                  });
                }}
              />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};
