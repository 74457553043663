import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import ErrorHandler from "components/ui/ErrorHandler";
import { useContext, useState } from "react";

import { login as loginDE } from "utils/DeApi";
import { UserDispatchContext } from "contexts/UserProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { twoFactorLogin } from "utils/BeeApi";

const TwoFactorForm = ({
  setIsLoginLoading,
  setRequireTwoFactor,
  email,
  isLoginLoading,
}) => {
  const [error, setError] = useState("");
  const fetchUser = useContext(UserDispatchContext);
  const twoFactorschema = yup.object().shape({
    code: yup.string().required("Required").min(6),
  });

  function submitTwoFactorCode(values) {
    setError("");
    setIsLoginLoading(true);
    twoFactorLogin({ code: `${values?.code}`, email: email })
      .then((response) => {
        return loginDE(response.data);
      })
      .then(
        (response) =>
          new Promise((resolve) => setTimeout(() => resolve(response), 1000))
      )
      .then((response) => {
        fetchUser();
        setError(null);
        setIsLoginLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoginLoading(false);
      });
  }
  return (
    <Formik
      validationSchema={twoFactorschema}
      onSubmit={(values) => submitTwoFactorCode(values)}
      initialValues={{
        code: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="text-center">
            <h2 className="pre-title">Two-step authentication.</h2>
          </div>
          <hr />
          <p>
            To continue, please enter the 6-digit code sent to your email. The
            code is valid for 10 minutes only.
          </p>
          <Form.Group className="mb-3">
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                name="code"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={values.code && !errors.code}
                placeholder="Enter code"
                autoFocus
              />
              <Button type="submit" color="primary" disabled={isLoginLoading}>
                {isLoginLoading && (
                  <Spinner
                    className="me-2"
                    animation="border"
                    size="sm"
                    variant="light"
                  />
                )}
                <span>Continue</span>
              </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              This is required
            </Form.Control.Feedback>
          </Form.Group>

          {error && <ErrorHandler error={error} />}

          <p className="text-center">
            <Button
              variant="link"
              onClick={() => {
                setRequireTwoFactor(false);
                setError(null);
              }}
            >
              Login.
            </Button>
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default TwoFactorForm;
