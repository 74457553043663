import { Col, Row } from "react-bootstrap";

import RadioFields from "./RadioFields/RadioFields";

function OrganizationRadioField({ data }) {
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="mb-4">
          <p className="text-center w-100 text-truncate">
            Distribution of Findings by {data.fieldName}
          </p>
          <hr />

          <RadioFields data={data.stats} />
        </Col>
      </Row>
    </>
  );
}

export default OrganizationRadioField;
