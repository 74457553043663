import PropTypes from "prop-types";
import { useState } from "react";
import ListObservationExport from "../../ListObservationExport";
import ObservationExport from "../../ObservationExport/ObservationExport";

const MultipleObservationExportWrapper = ({
  isGrid,
  observations,
  ...props
}) => {
  const [answeredArray, setAnsweredArray] = useState(
    !!observations ? Array(observations.length).fill(false) : []
  );

  const allUnanswered = answeredArray.every(
    (unanswered) => unanswered === true
  );

  const getWrapperClass = () => (allUnanswered ? "py-2" : "py-2 ps-3 p-3");
  const getWarningClass = () =>
    allUnanswered ? "bg-warning bg-light p-3 w-100 d-flex" : "d-none";
  const getObservationClass = (index) =>
    `${allUnanswered ? "d-none" : "mb-3"}
    }`;

  const renderExportComponent = (mappedObservation, index) => {
    if (isGrid) {
      return (
        <ObservationExport
          observation={mappedObservation}
          responses={mappedObservation.responses}
          index={index}
          setAnsweredArray={setAnsweredArray}
          {...props}
        />
      );
    }
    return (
      <ListObservationExport
        observation={mappedObservation}
        responses={mappedObservation.responses}
        index={index}
        setAnsweredArray={setAnsweredArray}
        {...props}
      />
    );
  };

  return (
    <div className={getWrapperClass()}>
      <div className={getWarningClass()}>UNANSWERED</div>
      {observations &&
        observations.map((mappedObservation, index) => (
          <div
            className={getObservationClass(index)}
            key={mappedObservation.observationId}
          >
            <span
              className={"position-relative text-decoration-underline"}
              style={{ left: -8 }}
            >
              {mappedObservation.label
                ? mappedObservation.label
                : "Observation 1"}
            </span>
            {renderExportComponent(mappedObservation, index)}
          </div>
        ))}
    </div>
  );
};

MultipleObservationExportWrapper.propTypes = {
  isGrid: PropTypes.bool.isRequired,
};

export default MultipleObservationExportWrapper;
