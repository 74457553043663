import React from "react";
import {
  Modal,
  Button,
  Table,
  Badge,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ErrorHandler from "components/ui/ErrorHandler";
import useRestoreAudit from "features/activityLogs/services/useRestoreAudit";

const AuditTrailAudit = ({ activityLog, handleClose }) => {
  const subscriberAction = activityLog?.description;
  const { audit = {} } = activityLog;
  const { isDeleted = false } = audit;
  const { error, isLoading, restoreAudit } = useRestoreAudit();

  return (
    <>
      <Modal.Body>
        <div className="d-flex flox-row mb-3">
          <div className="flex-fill mt-1">
            <h4>{activityLog.logLabel}</h4>
            <small>{audit?.name}</small>
          </div>
          <div className="text-end">
            {subscriberAction === "created" && (
              <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
                CREATED
              </Badge>
            )}
            {subscriberAction === "updated" && (
              <Badge className="bg-warning rounded-0 py-2 px-3 bg-opacity-10 text-warning">
                UPDATED
              </Badge>
            )}
            {subscriberAction === "deleted" && (
              <Badge className="bg-danger rounded-0 py-2 px-3 bg-opacity-10 text-danger">
                DELETED
              </Badge>
            )}
            {subscriberAction === "restored" && (
              <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
                RESTORED
              </Badge>
            )}
            <div className="mt-1">
              <small>
                {new Date(activityLog?.createdAt).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </small>
            </div>
          </div>
        </div>
        <div>
          <span>
            {activityLog?.causer?.firstName ?? "-"}{" "}
            {activityLog?.causer?.lastName ?? "-"}
          </span>
          <br />
          <small className="text-muted">
            {activityLog?.causer?.email ?? "-"}
          </small>
        </div>

        {subscriberAction === "updated" && (
          <>
            <hr />
            <Table striped borderless>
              <thead>
                <tr>
                  <th>Attribute</th>
                  <th>From</th>
                  <th>To</th>
                </tr>
              </thead>
              <tbody>
                {activityLog && (
                  <tr>
                    <td>Title</td>
                    <td>{activityLog?.oldProperties?.name}</td>
                    <td>{activityLog?.newProperties?.name}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        )}
        {error && <ErrorHandler error={error} />}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
        {subscriberAction !== "deleted" && (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {isDeleted ? (
                  <small>Assessment is Deleted</small>
                ) : (
                  <small>View</small>
                )}
              </Tooltip>
            }
          >
            <span className="d-inline-block">
              <Button
                size="sm"
                className="px-3"
                as={!isDeleted && Link}
                to={`/audits/${activityLog?.audit?.id}`}
                disabled={isDeleted}
              >
                View Assessment
              </Button>
            </span>
          </OverlayTrigger>
        )}
        {subscriberAction === "deleted" && !activityLog.isRestored && (
          <Button
            size="sm"
            className="px-3"
            onClick={() =>
              restoreAudit(activityLog?.audit?.id, activityLog?.id)
            }
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Restore
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

AuditTrailAudit.propTypes = {
  activityLog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AuditTrailAudit;
