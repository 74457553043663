import React, { useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { destroy } from "utils/DeApi";
import { useRoles, useToast } from "hooks";

const GeoTagDelete = ({ location, onGeoTagDelete, handleError }) => {
  const subscribedPromises = useRef([]);
  const toast = useToast();
  const { isCertifier } = useRoles();

  const [isExpanding, setIsExpanding] = useState(false);

  const handleDeleteLocation = (location) => {
    handleError(null);
    setIsExpanding(true);

    const geoTagPromise = destroy(`/geotags/${location.id}`);

    geoTagPromise.promise
      .then(() => {
        setIsExpanding(false);
        onGeoTagDelete(location);
        toast.success("Success", "The Geotag has been deleted successfully");
      })
      .catch((error) => {
        !error.isCanceled && handleError(error);
        setIsExpanding(false);
      });

    subscribedPromises.current.push(geoTagPromise);
  };

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        className="ms-2 border-danger border-opacity-25"
        onClick={() => handleDeleteLocation(location)}
      >
        {isExpanding ? (
          <Spinner animation="border" size="sm" variant="light" />
        ) : (
          <span translate="no" className="material-symbols-outlined md-18 ">
            delete
          </span>
        )}
      </Button>
    </>
  );
};

GeoTagDelete.propTypes = {
  location: PropTypes.object.isRequired,
  onGeoTagDelete: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default GeoTagDelete;
