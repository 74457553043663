import { useContext } from "react";

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useRoles } from "hooks";

const CorrectiveActionLink = () => {
  const { isContributor } = useRoles();
  const { audit } = useContext(AuditContext);

  if (isContributor) return <></>;
  if (!audit?.protocol?.hasCorrectiveAction) return <></>;

  return (
    <Button
      variant="primary"
      size="sm"
      as={Link}
      to={`/audits/${audit.auditId}/corrective-actions`}
      className="d-flex justify-content-center align-items-center mb-2"
    >
      <span>Corrective Actions</span>
      <span translate="no" className="material-symbols-outlined md-18 ms-1">
        east
      </span>
    </Button>
  );
};

export default CorrectiveActionLink;
