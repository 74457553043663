import React from "react";
import { Card } from "react-bootstrap";

const OrganizationNumericFields = ({ numericFields }) => {
  return (
    <div>
      <Card.Body>
        <p className="w-100 text-truncate text-capitalize">
          Summary of {numericFields?.fieldName}
        </p>
        <hr />

        <div className="d-flex align-items-center justify-content-center">
          {numericFields.stats.map((item, index) => {
            const count = Number(Number(item?.count)?.toFixed(2));
            let formatter = Intl.NumberFormat("en", {
              maximumFractionDigits: 2,
              notation: "compact",
              compactDisplay: "short",
            });
            return (
              <span key={index++} className="w-25">
                <h3>
                  {count >= 1000000
                    ? formatter.format(count)
                    : count?.toLocaleString()}
                </h3>
                <p>{item.option}</p>
              </span>
            );
          })}
        </div>
      </Card.Body>
    </div>
  );
};

export default OrganizationNumericFields;
