import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { post } from "utils/DeApi";
import { useToast } from "hooks";

const NotePadCreate = ({ observationId, onMemoCreate }) => {
  const subscribedPromises = useRef([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState();

  const createMemo = (values, actions) => {
    setIsLoading(true);
    const { name, description } = values;
    const memoPromise = post(`/observations/${observationId}/memos`, {
      key: name,
      value: description,
    });
    memoPromise.promise
      .then(({ data }) => {
        onMemoCreate(data);
        toast.success("Success", "Notepad has been created successfully");
        actions.resetForm();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          toast.error(
            "Error",
            "An Error occurred while trying to save the Notepad"
          );
        }
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(memoPromise);
  };

  return (
    <>
      <Formik
        onSubmit={createMemo}
        initialValues={{
          name: "",
          description: "",
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                name="name"
                type="text"
                placeholder="Title"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                name="description"
                type="text"
                as="textarea"
                row={2}
                placeholder="Take a note..."
              />
            </Form.Group>

            <div className="text-end">
              {isLoading ? (
                <Spinner
                  className="mt-2"
                  animation="border"
                  size="sm"
                  variant="dark"
                />
              ) : (
                <Button
                  type="submit"
                  className="px-3"
                  size="sm"
                  variant="outline-primary"
                  disabled={!values.name || !values.description}
                >
                  Add
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
NotePadCreate.propTypes = {
  observationId: PropTypes.string,
  onMemoCreate: PropTypes.func,
  handleError: PropTypes.func,
};
export default NotePadCreate;
