const VERTICALS = {
  //CSRD
  "653ffd70aed8a": {
    labels: {
      "Helpful Information & Resources": "Application Requirements",
    },
    hideAssessTitlesInReport: false,
    defaultViewInReport: "list",
  },
  //Stormwater
  "65425f02031a6": {
    labels: null,
    hideAssessTitlesInReport: true,
    defaultViewInReport: "grid",
    hideAttachmentDescAndName: true,
    hideAssessmentLevelAttachment: true,
    generatedFileNameShouldOnlyHaveAssessmentName: true,
    domain: "https://stormwater.ermassess.com",
  },
};

export default VERTICALS;
