import * as echarts from "echarts";
import { colorMap } from "features/assessment/constants";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { compact, groupBy } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import "./ReportRadioField.scss";

const CHART_HEIGHT = 270;

const ReportRadioField = ({
  field,
  isExportable = false,
  tooltipTitle = "",
  setChartImage,
  module = "",
  hasAnyOfTheChartsBeenHidden = false,
}) => {
  const el = useRef(null);
  const { audit } = useContext(AuditContext);

  const fieldResponses = useMemo(() => {
    const applicableObservations = audit?.observations
      .filter(({ isInapplicable }) => !isInapplicable)
      .map(({ observationId }) => observationId);

    return audit.responses.filter(
      (item) =>
        item.choice &&
        item.flexibleQuestionId === field.id &&
        applicableObservations.includes(item.observationId)
    );
  }, [audit.responses, audit?.observations, field.id]);

  const handleSetChartImage = React.useCallback(
    (chart) => {
      if (!hasAnyOfTheChartsBeenHidden)
        setChartImage((prevState) => {
          const found = prevState.find(({ id }) => id === field.id);
          if (found)
            return prevState.map((data, index) => {
              if (data.id === found.id)
                return {
                  id: field.id,
                  title: `Distribution of Findings by ${
                    field.prompt || "Risk Rating"
                  }`,
                  chart: chart,
                  order: index,
                };

              return data;
            });
          return [
            ...prevState,
            {
              id: field.id,
              title: `Distribution of Findings by ${
                field.prompt || "Risk Rating"
              }`,
              chart: chart,
              order: prevState.length,
            },
          ];
        });
    },
    [field.id, field.prompt, hasAnyOfTheChartsBeenHidden, setChartImage]
  );

  useEffect(() => {
    if (!fieldResponses.length) {
      return;
    }
    const fieldObservations = groupBy(fieldResponses, (item) => {
      return item.choice.label;
    });

    const data = Object.keys(fieldObservations).map((key) => {
      const color = Object.values(colorMap).find(({ label }) => {
        return key.toLowerCase().includes(label?.toLowerCase());
      });
      return {
        name: key,
        color: color?.color || "",
        value: fieldObservations[key].length,
      };
    });

    const sum = data.reduce((prev, current) => prev + current.value, 0);

    const chartColors = compact(data.map(({ color }) => color));

    const dimension = {
      width: isExportable ? 800 : el.current.offsetWidth,
      height: isExportable ? 500 : CHART_HEIGHT,
    };

    const option = {
      title: {
        show: !isExportable,
        textStyle: {
          color: "grey",
          fontSize: 15,
          fontWeight: "lighter",
        },
        text: `Total: ${sum}`,
        left: "center",
        top: "bottom",
      },
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />{d0}%",
        confine: true,
      },
      ...(!isExportable
        ? {
            legend: {
              type: "scroll",
              orient: "horizontal",
              icon: "circle",
              textStyle: {
                fontSize: 14,
              },
            },
          }
        : {}),
      series: [
        {
          name: tooltipTitle,
          type: "pie",
          radius: "50%",
          label: {
            show: true,
            formatter: "{b},{c} ({d}%)",
            ...(isExportable
              ? {
                  alignTo: "none",
                  fontSize: 14,
                  color: "#000",
                  lineHeight: 2,
                  padding: 20,
                  textStyle: {
                    overflow: "truncate",
                    width: 500,
                    confine: true,
                  },
                }
              : {}),
            edgeDistance: "1%",
          },
          labelLine: {
            length: 10,
            length2: 10,
          },

          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          percentPrecision: 1,
          data: data,
          color: data?.length === chartColors?.length ? chartColors : [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    if (!fieldResponses.length) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
    }

    const chart = echarts.init(el.current, null, dimension);

    if (setChartImage) {
      chart.on("finished", () => {
        var img = new Image();
        img.src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        handleSetChartImage(img);
        chart.dispose();
      });
    }

    chart.setOption(option);

    function handleResize() {
      chart.resize(dimension);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [
    el,
    audit,
    field,
    isExportable,
    tooltipTitle,
    fieldResponses,
    handleSetChartImage,
    setChartImage,
  ]);

  if (!fieldResponses.length && module === "Reports") return <></>;
  if (!fieldResponses.length)
    return (
      <p className="text-center text-muted">
        <small>There is currently no data to show.</small>
      </p>
    );

  return (
    <div className="ReportRadioField">
      <div className="chart-container">
        <div className="w-100" ref={el} />
      </div>
    </div>
  );
};

ReportRadioField.propTypes = {
  audit: PropTypes.object.isRequired,
  setChartImage: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  isExportable: PropTypes.bool.isRequired,
};

export default ReportRadioField;
