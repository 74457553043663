import React, { useContext, useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Dropdown,
  Nav,
  Offcanvas,
  Row,
  Tab,
} from "react-bootstrap";
import * as yup from "yup";

import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import { UserContext } from "contexts/UserProvider";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { get, post } from "utils/DeApi";

import ManuscriptCommentCreate from "./ManuscriptCommentCreate";
import ManuscriptCommentDelete from "./ManuscriptCommentDelete";
import ManuscriptCommentUpdate from "./ManuscriptCommentUpdate";

import useAuditMapping from "../../../../../hooks/useAuditMapping";
import "./ManuscriptComments.scss";
import { sortArrayByDate } from "utils/ArrayUtils";

const sortBy = [
  {
    index: 0,
    name: "Oldest To Newest",
    value: "oldestToNewest",
  },
  {
    index: 1,
    name: "Newest To Oldest",
    value: "newestToOldest",
  },
];

const ManuscriptComments = ({ payload, question }) => {
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [showUpdateForm, setShowUpdateForm] = useState("");
  const [sortValue, setSortValue] = useState(sortBy[0]);
  const [comments, setComments] = useState([]);
  const [activeObservation, setActiveObservation] = useState("default");
  const { audit, setAudit } = useContext(AuditContext);
  const { observationCreateOrUpdateMapping } = useAuditMapping();

  const validationSchema = yup.object({
    updateComment: yup.string().required("Comment is required"),
    newComment: yup.string().required("Comment is required"),
  });

  const buildEmptyObservation = () => {
    if (question.observations.length === 0) {
      setIsLoading(true);
      const observationPromise = post(`observations`, payload);
      observationPromise.promise
        .then((response) => {
          observationCreateOrUpdateMapping(response.data);
          setActiveObservation(response.data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else return;
  };

  const handleShow = () => {
    if (question.observations.length === 0) {
      buildEmptyObservation();
    }
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setShowUpdateForm("");
  };

  const commentCreator = (user, subscriber) => {
    return user?.subscriberId === subscriber?.subscriberId;
  };

  const observationsMappped = !!question?.observations?.length
    ? sortArrayByDate(question?.observations, "createdAt")
    : [{}];

  const sortComments = (comments, sortByValue) => {
    return comments.sort((a, b) => {
      if (sortByValue === "oldestToNewest") {
        return new Date(a.updatedAt) - new Date(b.updatedAt);
      } else if (sortByValue === "newestToOldest") {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      }
      return 0;
    });
  };

  const updateSort = (sortByValue) => {
    const sortedComments = sortComments([...comments], sortByValue);
    setComments(sortedComments);
  };

  const handleCommentUpdated = (updatedComment) => {
    setComments((prevState) => {
      const updatedComments = prevState.filter(
        (comment) => comment.commentId !== updatedComment.commentId
      );
      updatedComments.push(updatedComment);
      return sortComments(updatedComments, sortValue.value);
    });
    setShowUpdateForm("");
    setAudit((prevState) => {
      const { latestComments = [] } = prevState || {};
      const filteredComments = latestComments.filter(
        (comment) => comment.commentId !== updatedComment.commentId
      );
      const updatedLatestComments = [updatedComment, ...filteredComments];
      return {
        ...prevState,
        latestComments: updatedLatestComments,
      };
    });
  };

  const handleCommentCreated = (newComment) => {
    setComments((prevState) => {
      const updatedComments = [...prevState, newComment];
      return sortComments(updatedComments, sortValue.value);
    });

    const updatedObservation = {
      ...activeObservation,
      commentsCount: !!activeObservation?.commentsCount
        ? activeObservation.commentsCount + 1
        : 1,
    };
    observationCreateOrUpdateMapping(updatedObservation);
    setAudit((prevState) => {
      const { latestComments = [] } = prevState || {};
      return {
        ...prevState,
        latestComments: [
          { ...newComment, observation: activeObservation },
          ...latestComments,
        ],
      };
    });
    setActiveObservation(updatedObservation);
  };

  function handleCommentDeleted(commentId) {
    setComments((prevState) => {
      return prevState.filter((comment) => comment.commentId !== commentId);
    });
    const updatedObservation = {
      ...activeObservation,
      commentsCount: activeObservation.commentsCount - 1,
    };

    observationCreateOrUpdateMapping(updatedObservation);
    setAudit((prevState) => {
      const { latestComments = [] } = prevState || {};
      const filteredComments = latestComments.filter(
        (comment) => comment.commentId !== commentId
      );
      const updatedLatestComments = [...filteredComments];
      return {
        ...prevState,
        latestComments: updatedLatestComments,
      };
    });
    setActiveObservation(updatedObservation);
  }

  useEffect(() => {
    function fetchObservation() {
      const found = question?.observations?.length
        ? question?.observations[0]
        : {};

      setActiveObservation(found || {});
    }
    fetchObservation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question?.observations?.length]);

  useEffect(() => {
    const fetchManuscriptComments = () => {
      setError("");
      setIsLoading(true);
      const manuscriptCommentsPromise = get(
        `/observations-report-comments/${activeObservation.observationId}/comments`
      );

      manuscriptCommentsPromise.promise
        .then(({ data: comments }) => {
          setIsLoading(false);
          setComments(
            comments.sort((a, b) => {
              return new Date(a.updatedAt) - new Date(b.updatedAt);
            })
          );
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(manuscriptCommentsPromise);
    };

    if (show && activeObservation?.observationId) fetchManuscriptComments();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [show, activeObservation.observationId]);

  return (
    <>
      <Button
        variant="outline-secondary"
        className="position-relative ms-2 d-print-none py-0 px-2"
        onClick={handleShow}
      >
        <>
          <span translate="no" className="material-symbols-outlined md-16">
            comment
          </span>
          {!audit?.protocol?.hasObservationSets &&
            question.observations?.[0]?.commentsCount > 0 && (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {question.observations[0].commentsCount}
                <span className="visually-hidden">unread messages</span>
              </span>
            )}
        </>
      </Button>
      <Formik
        initialValues={{
          updateComment: "initialCommentBody",
          newComment: "",
        }}
        validationSchema={validationSchema}
      >
        <Offcanvas
          className="w-fixed-640"
          show={show}
          onHide={handleClose}
          placement="end"
          scroll
        >
          <div className="h-100 comments-overflow">
            <div className="d-flex justify-content-between p-3 manuscript-header">
              <div
                className="question-title"
                dangerouslySetInnerHTML={{
                  __html: question.prompt,
                }}
              />
              <Button
                type="button"
                className="btn-close bg-white p-2 ms-2"
                onClick={handleClose}
              />
            </div>
            <hr className="m-1 mb-3" />
            <Tab.Container activeKey={activeObservation.observationId}>
              {audit?.protocol?.hasObservationSets && (
                <Nav variant="tabs" className="pt-3 d-flex flex-row mx-3 mb-3">
                  {observationsMappped.map((observation, index) => {
                    const { observationId = "default" } = observation || {};

                    return (
                      <Nav.Item
                        key={observationId}
                        onClick={() => {
                          setActiveObservation(observation);
                        }}
                      >
                        <Nav.Link
                          eventKey={observationId}
                          className={`d-flex flex-row ${
                            observation?.isInapplicable &&
                            "text-muted opacity-75"
                          }`}
                        >
                          <span className="flex-fill">
                            {observation?.label
                              ? observation?.label
                              : `Observation ${++index}`}
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              )}
              <div className="px-3">
                {!isLoading &&
                  !error &&
                  Array.isArray(comments) &&
                  !comments.length && (
                    <EmptyStateHandler
                      title="No Comments"
                      description="There are currently no comments to display."
                    />
                  )}
                {(isLoading || !activeObservation) && <Loader />}
                {error && <ErrorHandler error={error} />}
                {!!comments?.length && (
                  <Row className="mb-3 text-secondary fs-5">
                    <Col className="d-flex align-items-center justify-content-end">
                      Sort By:
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          id="dropdown-done"
                          className="ms-2 text-start border border-gray-900 bg-white text-secondary"
                        >
                          {sortValue?.name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {sortBy.map((s) => (
                            <Dropdown.Item
                              key={s.index}
                              active={s.index === sortValue.index}
                              onClick={() => {
                                setSortValue(s);
                                updateSort(s.value);
                              }}
                            >
                              {s.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                )}
                <Row className="comments-overflow pt-1">
                  {Array.isArray(comments) &&
                    comments.map((comment) => {
                      const {
                        commentId,
                        subscriber,
                        body,
                        createdAt,
                        updatedAt,
                      } = comment;
                      return (
                        <Row className="mb-3" key={commentId}>
                          <Col xs={1} className="me-2">
                            <div className="avatar d-flex align-items-center justify-content-center rounded-circle bg-primary bg-opacity-10 text-primary text-uppercase">
                              <strong>
                                {subscriber?.firstName?.[0]}
                                {subscriber?.lastName?.[0]}
                              </strong>
                            </div>
                          </Col>
                          <Col>
                            <Col className="d-flex justify-content-between align-items-end">
                              <h5 className="text-secondary text-capitalize">
                                {subscriber?.firstName} {subscriber?.lastName}
                              </h5>
                              <div>
                                {commentCreator(user, subscriber) && (
                                  <>
                                    {showUpdateForm !== commentId && (
                                      <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        onClick={() => {
                                          setShowUpdateForm(commentId);
                                        }}
                                      >
                                        <span
                                          translate="no"
                                          className="material-symbols-outlined md-18"
                                        >
                                          edit
                                        </span>
                                      </Button>
                                    )}
                                    {showUpdateForm === commentId && (
                                      <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        onClick={() => {
                                          setShowUpdateForm("");
                                        }}
                                      >
                                        <span
                                          translate="no"
                                          className="material-symbols-outlined md-18"
                                        >
                                          close
                                        </span>
                                      </Button>
                                    )}
                                  </>
                                )}
                                <ManuscriptCommentDelete
                                  onCommentDeleted={handleCommentDeleted}
                                  commentId={comment.commentId}
                                />
                              </div>
                            </Col>
                            {showUpdateForm !== commentId && (
                              <>
                                <p className="mb-2 text-break text-wrap">
                                  {body}
                                </p>
                                <p className="text-muted">
                                  <small className="me-1">
                                    {new Date(updatedAt).toLocaleString([], {
                                      dateStyle: "short",
                                      timeStyle: "short",
                                    })}
                                  </small>
                                  {createdAt !== updatedAt && (
                                    <span> · edited</span>
                                  )}
                                </p>
                              </>
                            )}{" "}
                            {showUpdateForm === commentId && (
                              <ManuscriptCommentUpdate
                                comment={comment}
                                onManuscriptCommentUpdated={
                                  handleCommentUpdated
                                }
                              />
                            )}
                            <hr />
                          </Col>
                        </Row>
                      );
                    })}
                </Row>
              </div>
            </Tab.Container>
          </div>
          <Col className="p-3 bg-light border-top">
            <ManuscriptCommentCreate
              onCommentCreated={handleCommentCreated}
              observationId={activeObservation.observationId}
            />
          </Col>
        </Offcanvas>
      </Formik>
    </>
  );
};

ManuscriptComments.propTypes = {
  payload: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
};

export default ManuscriptComments;
