import { useContext, useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import { groupBy } from "lodash";
import { useToast } from "hooks";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";

import { destroy } from "utils/DeApi";
import Loader from "components/ui/Loader";
import { post, put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useFetchScreeningResponses } from "features/assessment/services";
import { useRoles } from "hooks";
import { useAuditMapping } from "features/assessment/hooks";

export default function AuditPreQualification() {
  const { isCertifier } = useRoles();
  const toast = useToast();
  const subscribedPromises = useRef([]);

  const { audit, setAudit } = useContext(AuditContext);
  const { error, isLoading, responses, setResponses } =
    useFetchScreeningResponses({
      auditId: audit?.auditId,
    });

  const [isExpanding, setIsExpanding] = useState();
  const [isExpandingError, setIsExpandingError] = useState(null);

  const { observationsInapplicabilityMapping } = useAuditMapping();

  const responseMapping = () => {
    if (!Array.isArray(responses) || !responses?.length) return {};

    const groupByQuestionId = groupBy(responses, "screeningQuestion.id");
    return Object.keys(groupByQuestionId).reduce((accumulator, key) => {
      if (
        !Array.isArray(groupByQuestionId[key]) &&
        !groupByQuestionId[key].length
      )
        return accumulator;

      const response = groupByQuestionId[key][0];
      const responseValue = response?.response ? "Yes" : "No";

      return {
        ...accumulator,
        [key]: {
          questionId: key,
          responseId: response?.responseId,
          response: responseValue,
        },
      };
    }, {});
  };

  let auditApplicabilityScreening = audit?.applicabilityScreening || [];

  const handleOnSubmit = (formData = {}) => {
    setIsExpanding(true);
    setIsExpandingError("");

    const q = formData?.screening || {};
    const promiseOne = Object.keys(q).flatMap((questionId) => {
      const { responseId, response } = q[questionId] || {};

      if (!questionId) return [];

      const _response = response === "No" ? false : true;
      auditApplicabilityScreening = auditApplicabilityScreening.map((q) => {
        if (q.id === questionId) {
          return {
            ...q,
            response: _response,
          };
        }
        return q;
      });
      const responseStatus = response === "Yes";

      if (responseId) {
        if (!response) {
          const responsePromise = destroy(`/screening-responses/${responseId}`);
          subscribedPromises.current.push(responsePromise);

          return responsePromise.promise;
        }
        const responsePromise = put(`/screening-responses/${responseId}`, {
          response: _response,
        });
        subscribedPromises.current.push(responsePromise);

        return responsePromise.promise;
      }

      if (!response) return [];
      const responsePromise = post(`/screening-questions/${questionId}`, {
        audit_id: audit?.auditId,
        response: responseStatus,
      });
      subscribedPromises.current.push(responsePromise);
      return responsePromise.promise;
    });

    Promise.all(promiseOne)
      .then((resp) => {
        const response = resp.flatMap(({ data }) => data).filter(Boolean);
        setResponses(response);
      })
      .catch((error) => {
        !error.isCanceled && setIsExpandingError(error);
      })
      .finally(() => {
        // setIsExpanding(false);
      });

    handleSectionInapplicable(auditApplicabilityScreening);
  };

  const handleSectionInapplicable = (responseCollection = []) => {
    setIsExpanding(true);
    const auditSections =
      audit?.protocol?.sections.filter((section) => {
        return section.principles.length && section.questions.length;
      }) || [];

    const sectionPromises = responseCollection
      .flatMap(({ response, inapplicableSections }) => {
        const sections = inapplicableSections.map((section) => ({
          ...section,
          isInapplicable:
            response === "No" || response !== undefined ? !response : false,
        }));

        return sections.filter(({ sectionid }) => {
          return !!auditSections.find(({ sectionId: id }) => id === sectionid);
        });
      })
      .map(({ sectionid, isInapplicable }) => {
        const sectionPromise = put(
          `/audits/${audit?.auditId}/sections/${sectionid}/is-inapplicable`,
          {
            isInapplicable: isInapplicable,
          }
        );
        subscribedPromises.current.push(sectionPromise);
        return sectionPromise.promise;
      });

    Promise.all(sectionPromises)
      .then((response) => {
        let observations = response.flatMap(({ data }) => data);
        observationsInapplicabilityMapping(observations);
        toast.success("Success", "Applicability screening has been saved");
      })
      .catch((error) => {
        !error.isCanceled && setIsExpandingError(error);
      })
      .finally(() => {
        setIsExpanding(false);
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((p) => {
        p.cancel();
      });
    };
  }, []);

  return (
    <Col xs={12} lg={8} md={8} className="mb-3">
      <Formik
        enableReinitialize
        initialValues={{
          screening: responseMapping(),
        }}
        onSubmit={handleOnSubmit}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <fieldset disabled={isCertifier}>
              <Row>
                <Col xs={12} lg={12} md={12} className="mb-3 mt-2">
                  <Card>
                    <Card.Header>
                      <Card.Title className="my-2">
                        Applicability Screening
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {isLoading ? <Loader /> : null}
                      {Array.isArray(audit?.applicabilityScreening) &&
                        audit?.applicabilityScreening.map((q) => {
                          const value = values.screening[q.id];

                          return (
                            <Form.Group
                              key={q.id}
                              controlId={q.id}
                              className="my-3"
                            >
                              <Form.Label className="w-100">
                                <div className="d-flex justify-content-between ps-1">
                                  <span className="pe-2">{q.prompt}</span>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={(props) => (
                                      <Tooltip
                                        id={`tooltip-${q.id}`}
                                        {...props}
                                      >
                                        Clear
                                      </Tooltip>
                                    )}
                                  >
                                    <Button
                                      variant="light"
                                      size="sm"
                                      className="py-0 custom-clear-button border-secondary border-opacity-25 rounded-circle text-center"
                                      onClick={() => {
                                        setFieldValue(`screening.${q.id}`, {
                                          response: "",
                                          questionId: q.id,
                                          responseId: value?.responseId,
                                        });
                                      }}
                                    >
                                      <span
                                        translate="no"
                                        className="material-symbols-outlined md-14"
                                      >
                                        close
                                      </span>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </Form.Label>
                              <Form.Check
                                value={"Yes"}
                                id={`${q.id}-yes`}
                                type="radio"
                                name={q.id}
                                label={"Yes"}
                                inline={true}
                                checked={value?.response === "Yes"}
                                onChange={(ev) => {
                                  setFieldValue(`screening.${q.id}`, {
                                    response: "Yes",
                                    responseId: value?.responseId,
                                  });
                                }}
                              />
                              <Form.Check
                                value={"No"}
                                id={`${q.id}-no`}
                                type="radio"
                                name={q.id}
                                label={"No"}
                                inline={true}
                                checked={value?.response === "No"}
                                onChange={(ev) => {
                                  setFieldValue(`screening.${q.id}`, {
                                    response: "No",
                                    questionId: q.id,
                                    responseId: value?.responseId,
                                  });
                                }}
                              />
                            </Form.Group>
                          );
                        })}

                      {!isLoading && error ? (
                        <ErrorHandler error={error} />
                      ) : null}

                      {!isExpanding && isExpandingError ? (
                        <ErrorHandler error={isExpandingError} />
                      ) : null}
                    </Card.Body>
                    <Card.Footer className="py-3">
                      <Button
                        size="sm"
                        type="submit"
                        className="float-end  px-3"
                        disabled={isExpanding}
                      >
                        {isExpanding && (
                          <Spinner
                            className="me-2"
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        )}{" "}
                        Save
                      </Button>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="float-end me-2 px-3"
                        onClick={() => {
                          let screening = values.screening || {};
                          Object.keys(screening).forEach(function (key) {
                            const response = screening[key];
                            screening[key] = {
                              response: "",
                              questionId: response?.questionId,
                              responseId: response?.responseId,
                            };
                          });

                          setFieldValue("screening", screening);
                        }}
                      >
                        Clear
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </fieldset>
          </Form>
        )}
      </Formik>
    </Col>
  );
}
