const creatingData = (actionItem, tableData) => {
  const values = tableData.map((d) => {
    const { taskableType, audit } = actionItem || {};
    const val = actionItem[d?.dataIndex];
    if (taskableType === "question" && d?.dataIndex === "protocol")
      return { ...d, val: audit?.protocol?.name };
    if (taskableType === "question" && d?.dataIndex === "facility")
      return { ...d, val: audit?.facility?.name };
    if (typeof val === "object" && !!Object.keys(val)?.length)
      return { ...d, val };
    if (!!val?.length) return { ...d, val };
    if (!!val) return { ...d, val };
    return { ...d, val: "-" };
  });
  return values;
};

export default creatingData;
