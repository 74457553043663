export const colorOptions = [
  //Black shades
  "#000000",
  "#292929",
  "#545454",
  "#858585",
  "#C1C1C1",
  // Dark shades
  "#AB1524",
  "#DC3545",
  "#FD7E14",
  "#FFC107",
  "#019A20",
  // Light shades
  "#ECACB3",
  "#FEDDE1",
  "#FDD0AA",
  "#FFE69C",
  "#B9F3C3",
  // Medium shades
  "#CD5A65",
  "#ED838D",
  "#FFAE6A",
  "#FFDA6D",
  "#7EC68B",
  // Darker shades
  "#732E35",
  "#A22424",
  "#C57742",
  "#D5B050",
  "#014E20",
];

export const whiteAccent = [
  "#000000",
  "#292929",
  "#545454",
  "#AB1524",
  "#DC3545",
  "#732E35",
  "#A22424",
  "#014E20",
];

export const setAccentColor = (color) => {
  if (whiteAccent.includes(color)) {
    return "white";
  }
  return "black";
};

export const COLS = 5;
