import React, { Fragment, useEffect } from "react";
import { truncate } from "lodash";
import PropTypes from "prop-types";

import GeoTagDelete from "../GeoTagDelete";
import GeoTagUpdate from "../GeoTagUpdate";

const GeoTagCard = ({ geolocations, setGeolocations, handleError }) => {
  const onGeoTagDelete = (currLocation) => {
    setGeolocations((prevLocation) => {
      return prevLocation.filter((location) => location.id !== currLocation.id);
    });
  };

  const onGeoTagUpdate = (updatedLocation) => {
    setGeolocations((prevLocation) => {
      return prevLocation.map((location) => {
        if (updatedLocation?.id === location?.id) return updatedLocation;
        return location;
      });
    });
  };

  const geoTagSort = () => {
    return geolocations.sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
  };

  return (
    <>
      {!!geolocations.length &&
        geoTagSort(geolocations).map((location, index, arr) => (
          <Fragment key={location?.id}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {location?.title[0]?.toUpperCase()}
                {truncate(location?.title.slice(1), {
                  length: 40,
                })}
              </div>
              <div className="w-50 text-end">
                <GeoTagUpdate
                  location={location}
                  onGeoTagUpdate={onGeoTagUpdate}
                  handleError={handleError}
                />
                <GeoTagDelete
                  location={location}
                  onGeoTagDelete={onGeoTagDelete}
                  handleError={handleError}
                />
              </div>
            </div>
            <div className="mb-1">
              <small>
                Latitude: {location?.latitude}
                {" | "}
                Longitude: {location?.longitude}
              </small>
            </div>
            <div>
              <small className="text-muted">
                {new Date(location?.updatedAt).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </small>
            </div>
            {arr.length > ++index && <hr className="mt-3" />}
          </Fragment>
        ))}
    </>
  );
};

GeoTagCard.propTypes = {
  handleError: PropTypes.func.isRequired,
  setGeolocations: PropTypes.func.isRequired,
  geolocations: PropTypes.array.isRequired,
};

export default GeoTagCard;
