import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useRoles, useToast } from "hooks";
import { Button, Card, Col } from "react-bootstrap";

import { get } from "utils/DeApi";
import BreadCrumbs from "components/ui/BreadCrumbs";
import { OrganizationContext } from "contexts/OrganizationProvider";

import ReportTable from "./ReportTable";
import AddReportModal from "./AddReportModal";
import EditReportModal from "./EditReportModal";
import DeleteReportModal from "./DeleteReportModal";
import NoReportsAvailable from "./NoReportsAvailable";
import MetaPagination from "components/ui/MetaPagination";

const OrganizationReports = () => {
  const toast = useToast();
  const { isAdmin } = useRoles();
  const organization = useContext(OrganizationContext);
  const [loading, setLoading] = useState(false);
  const [organizationReports, setOrganizationReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [reportTypes] = useState([
    { value: "", label: "Select Report Type" },
    { value: "1", label: "Assess PowerBi" },
    { value: "2", label: "ArcGIS" },
    { value: "3", label: "QuickSight" },
    { value: "4", label: "Client PowerBi" },
  ]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState();

  const generateReportTypeMapping = (types) => {
    return types.reduce((mapping, type, index) => {
      mapping[type.label] = `${index}`;
      return mapping;
    }, {});
  };
  const subscribedPromises = useRef([]);

  const fetchEmbedReport = useCallback(async () => {
    setLoading(true);
    const findingsPromise = get(
      `/organizations/${organization?.id}/embedded-reports`,
      {
        params: {
          page: page ?? 1,
          perPage: perPage ?? 10,
        },
      }
    );
    findingsPromise.promise
      .then(async ({ data, meta }) => {
        if (data && data.length < 1) {
          setOrganizationReports([]);
          setLoading(false);
          return;
        }
        setMeta(meta);
        setOrganizationReports([...data]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    subscribedPromises.current.push(findingsPromise);
  }, [organization?.id, page, perPage]);

  useEffect(() => {
    fetchEmbedReport();
  }, [fetchEmbedReport]);

  const handleUpdateData = (item, action) => {
    if (action === "add") {
      toast.success("Success", "The Report has been created successfully");
      setOrganizationReports((prevReports) => [item, ...prevReports]);
    } else if (action === "edit") {
      setOrganizationReports((prevReports) => {
        const filteredReports = prevReports.filter(
          (report) => report.id !== item.id
        );
        return [item, ...filteredReports];
      });
      toast.success("Success", "The Report has been updated successfully");
    } else {
      toast.success("Success", "The Report has been deleted successfully");
      setOrganizationReports((prevReports) =>
        prevReports.filter((report) => report.id !== item)
      );
    }
  };

  const dataNotAvailable = organizationReports.length < 1;

  const modalProps = {
    selectedReport,
    fetchEmbedReport,
    handleUpdateData,
    subscribedPromises,
    reportTypes,
  };

  return (
    <>
      <EditReportModal
        {...modalProps}
        organizationId={organization?.id}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        generateReportTypeMapping={generateReportTypeMapping}
      />
      <DeleteReportModal
        {...modalProps}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
      <AddReportModal
        {...modalProps}
        organizationId={organization?.id}
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
      />
      <BreadCrumbs
        breadcrumbs={[
          { name: "Dashboard", link: "/" },
          { name: "Reports", active: false },
        ]}
      />
      <div className="d-flex flex-row mb-3 align-items-center">
        <div className="flex-grow-1">
          <h1 className="mb-0">Reports</h1>
        </div>
        {isAdmin && (
          <Button
            size="sm"
            variant="primary"
            onClick={() => setShowAddModal(true)}
            className="px-3"
          >
            Add New
          </Button>
        )}
      </div>
      <Col xs={12} className="mb-3">
        <Card>
          <Card.Body className="table-responsive">
            <ReportTable
              organizationReports={organizationReports}
              setSelectedReport={setSelectedReport}
              setShowEditModal={setShowEditModal}
              setShowDeleteModal={setShowDeleteModal}
              isUserAdmin={isAdmin}
              loading={loading}
            />
            {dataNotAvailable && !loading && <NoReportsAvailable />}
          </Card.Body>
          <Card.Footer>
            <MetaPagination
              meta={meta}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Card.Footer>
        </Card>
      </Col>
    </>
  );
};

export default OrganizationReports;
