import { Container } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import { PrivacyPolicy } from "features/contents";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const PrivacyPolicyPage = () => {
  return (
    <Container className="my-5 MainContent">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "PrivacyPolicyPage");
        }}
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      >
        <PrivacyPolicy />
      </Sentry.ErrorBoundary>
    </Container>
  );
};

export default PrivacyPolicyPage;
