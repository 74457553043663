import { useContext, useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { put } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";

const useUpdateAudit = ({
  auditId,
  onAuditUpdated,
  successMessage = "Assessment was updated successfully",
}) => {
  const toast = useToast();
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const updateAudit = (formData) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = put(`/audits/${auditId}`, {
      ...formData,
      organizationId: organization?.id,
    });

    auditPromise.promise
      .then(({ data }) => {
        if (typeof onAuditUpdated === "function") onAuditUpdated(data);
        toast.success("Success", successMessage);
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, updateAudit };
};

export default useUpdateAudit;
