import React, { useContext, useState } from "react";

import { useRoles } from "hooks";
import { useParams } from "react-router-dom";
import { Loader } from "react-bootstrap-typeahead";
import { Card, Collapse, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { useAuditMapping } from "features/assessment/hooks";
import { useFetchObservationMemos } from "features/assessment/services";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useCreateOrUpdateObservation } from "features/assessment/services";

import NotePadCard from "./NotePadCard";
import NotePadCreate from "./NotePadCreate";
import { prepSubmitObservationValues } from "../QuestionUtils";

import "./NotePad.scss";

const NotePad = ({ observation, formRef }) => {
  const { questionId, auditId } = useParams();
  const { isCertifier } = useRoles();
  const [show, setShow] = useState(false);
  const { audit } = useContext(AuditContext);
  const { responseUpdateMapping, observationCreateOrUpdateMapping } =
    useAuditMapping();

  const { error, isLoading, memos, setMemos } = useFetchObservationMemos({
    observationId: observation?.observationId,
    fetchByDefault: show && audit?.protocol?.hasNotepad && !isCertifier,
  });

  const {
    error: isExpandError,
    isLoading: isExpanding,
    handleOnSubmit,
  } = useCreateOrUpdateObservation({
    onObservationSuccess: (observation) => {
      observationCreateOrUpdateMapping(observation);
    },
    onResponsesSuccess: (responses, observation) => {
      responseUpdateMapping(responses, observation);
    },
  });

  const onMemoCreate = (memo) => {
    setMemos((prev) => [...prev, memo]);
  };

  const handleShow = () => setShow((prev) => !prev);

  const createObservation = () => {
    const formData = prepSubmitObservationValues(formRef.current.values, {
      auditId,
      parentId: null,
      questionId,
      observationId: observation?.observationId,
    });

    handleOnSubmit(formData);
  };

  if (!audit?.protocol?.hasNotepad && !isCertifier) return <></>;

  return (
    <>
      <h5
        onClick={
          observation?.observationId !== undefined
            ? handleShow
            : createObservation
        }
        role="button"
        className="mb-0"
      >
        {show ? (
          <span translate="no" className="material-symbols-outlined me-2">
            expand_more
          </span>
        ) : (
          <span translate="no" className="material-symbols-outlined me-2">
            expand_less
          </span>
        )}
        Notepad ·{" "}
        {isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <span className="text-muted">
            {memos?.length ?? 0}
            <span className="visually-hidden">Memos count</span>
          </span>
        )}
      </h5>
      <Collapse in={show}>
        <div className="mt-2">
          <Card>
            <Card.Body>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {!!memos?.length && (
                    <NotePadCard memos={memos} setMemos={setMemos} />
                  )}
                  <NotePadCreate
                    onMemoCreate={onMemoCreate}
                    observationId={observation?.observationId}
                  />
                </>
              )}
              {error && <ErrorHandler error={error} />}
            </Card.Body>
          </Card>
        </div>
      </Collapse>
      <hr />
    </>
  );
};
export default NotePad;
