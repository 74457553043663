import { useContext } from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { showConformityLevel } from "features/assessment/components/Audit/helper";

import FormatResponse from "../FormatResponse";
import FieldClearButton from "../FieldClearButton";

const FieldConformityLevel = ({ isDisabled }) => {
  const { audit } = useContext(AuditContext);
  const { values, setFieldValue } = useFormikContext();

  const { conformity, questionOptions } = audit?.protocol;
  const [, , conformityLabel, , , , ,] = questionOptions || [];
  const _showConformityLevel = showConformityLevel(conformity, questionOptions);

  if (!_showConformityLevel) return <></>;

  const _conformity = conformity.filter(
    ({ value = "" }) => value.trim().toLowerCase() !== "n/a"
  );

  const clearSelection = () => {
    setFieldValue("conformityLevel", "");
  };

  return (
    <Form.Group controlId="conformityLevel" className="my-3">
      <Form.Label className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <strong>{conformityLabel?.name || "Conformity Level"}</strong>
            <RequiredAsterisk />
          </div>
          <FieldClearButton
            handleOnClick={clearSelection}
            isDisabled={isDisabled}
          />
        </div>
        <FormatResponse
          options={_conformity || []}
          label={conformityLabel?.name || "Conformity Level"}
          index={
            Array.isArray(_conformity)
              ? _conformity.findIndex(({ id }) => id === values.conformityLevel)
              : -1
          }
        />
      </Form.Label>
      <br />
      {_conformity.map(({ value, points, id }) => (
        <Form.Check
          id={`radio-${id}`}
          key={`radio-${id}`}
          label={
            <>
              {value}{" "}
              {!isNaN(Number(points)) && points > 0 ? (
                <small className="text-muted float-end ps-2">
                  {Number(points)} points
                </small>
              ) : (
                <></>
              )}
            </>
          }
          type="radio"
          inline
          checked={values.conformityLevel === id}
          disabled={values.isInapplicable || isDisabled}
          onChange={() => setFieldValue(`conformityLevel`, id)}
        />
      ))}
    </Form.Group>
  );
};

FieldConformityLevel.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldConformityLevel;
