import { useContext, useEffect, useRef, useState } from "react";

import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

export default function FileCollapseView({ file, expandableId }) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [isLoading, setIsLoading] = useState("");
  const [error, setError] = useState("");
  const [audit, setAudit] = useState("");
  const [facility, setFacility] = useState("");
  const [question, setQuestion] = useState("");
  const [open, setOpen] = useState(expandableId === file?.id);

  useEffect(() => {
    const fetchAudit = (auditId) => {
      setIsLoading(true);
      setError("");
      const auditsPromise = get(`audits/${auditId}`);
      const observationPromise = get(`audits/${auditId}/observations`);

      subscribedPromises.current.push(auditsPromise);
      subscribedPromises.current.push(observationPromise);

      Promise.all([auditsPromise.promise, observationPromise.promise])
        .then(([{ data: audit }, { data: observations }]) => {
          setFacility(audit?.facility);
          setAudit({
            ...audit,
          });

          if (file?.observationId) {
            const { questionId } =
              observations.find(({ observationId }) => {
                return observationId === file?.observationId;
              }) || {};

            const questions =
              audit?.protocol?.sections
                .map((section) => ({
                  ...section,
                  questions: section.questions.map((questions) => ({
                    ...questions,
                    href: `/audits/${audit.auditId}/sections/${section.sectionId}/questions/${questions.questionId}`,
                  })),
                }))
                .filter(({ principles }) => principles.length > 0)
                .reduce((prev, { questions: curr }) => {
                  return [...prev, ...curr];
                }, []) || [];

            const question = questions.find(({ questionId: id }) => {
              return id === questionId;
            });

            setQuestion(question);
          }
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
    };

    if (file?.modelType === "facility" && open) {
      setFacility({
        facilityId: file?.model?.id,
        ...file?.model,
      });
    }

    if (file?.modelType === "assessment" && open) {
      fetchAudit(file?.auditId);
    }
    if (file?.modelType === "question" && open) {
      fetchAudit(file?.auditId);
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [file, organization, open]);

  useEffect(() => {
    setOpen(expandableId === file?.id);
  }, [expandableId, file]);

  if (isLoading)
    return (
      <tr className="bg-light">
        <td colSpan={9} className="border-0 m-0 p-0">
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr className="bg-light">
        <td colSpan={9} className="border-0 m-0 p-0">
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  return (
    <tr className="bg-light">
      <td colSpan={9} className="border-0 m-0 p-0">
        <Collapse in={open} className="p-3 ps-5 border-bottom">
          <div>
            <div className="d-flex flex-column">
              <div className="w-100 d-flex flex-row py-2 border-0 border-bottom border-dashed">
                <div className="w-fixed-150">Organization</div>
                <div className="d-flex">
                  <Link to="/">
                    <span
                      translate="no"
                      className="material-symbols-outlined md-18 me-3"
                    >
                      open_in_new
                    </span>
                    {organization?.name}
                  </Link>
                </div>
              </div>
              {facility ? (
                <div className="w-100 d-flex flex-row  py-2 border-0 border-bottom border-dashed">
                  <div className="w-fixed-150">Entity</div>
                  <div>
                    <Link to={`/facilities/${facility?.facilityId}`}>
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18 me-3"
                      >
                        open_in_new
                      </span>
                      {facility?.name}
                    </Link>
                  </div>
                </div>
              ) : null}

              {audit ? (
                <div className="d-flex flex-row py-2 border-0 border-bottom border-dashed">
                  <div className="w-fixed-150">Assessment</div>
                  <div>
                    <Link to={`/audits/${audit?.auditId}`}>
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18 me-3"
                      >
                        open_in_new
                      </span>
                      {audit?.name}
                    </Link>
                  </div>
                </div>
              ) : null}

              {question ? (
                <div className="d-flex flex-row py-2">
                  <div className="w-fixed-150">Question</div>
                  <div className="w-75">
                    <Link to={question?.href} className="d-flex flex-row">
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18 me-3 float-start"
                      >
                        open_in_new
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(question?.prompt),
                        }}
                      />
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Collapse>
      </td>
    </tr>
  );
}
