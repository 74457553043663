import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import SlateEditor from "components/ui/SlateEditor";
import useCreateOrUpdateSectionSummary from "features/assessment/services/useCreateOrUpdateSectionSummary";
import { useRoles } from "hooks";
import sectionSummaryValidationSchema from "features/assessment/schemas/sectionSummaryValidationSchema";

const ManuscriptSectionSummary = ({ summary, sectionId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  function formatAndCleanExecutiveSummaryContent(content = "") {
    return content
      ?.replace(/<p>(\s|&nbsp;)*?<\/p>/g, "<p>&#xFEFF;</p>") // Replace empty <p> with <p>&#xFEFF;</p>
      ?.replace(/\n/g, "<br />") // Replace newlines with <br />
      ?.replace(/<p>(.*?)<\/p>/g, (match, p1) => {
        // Replace empty tags inside <p></p> with &#xFEFF;
        return p1.trim() === ""
          ? "<p>&#xFEFF;</p>"
          : match.replace(/<(\w+)><\/\1>/g, "<$1>&#xFEFF;</$1>");
      });
  }
  const handleShow = () => {
    setShow(true);
  };
  const { isCertifier } = useRoles();
  const { isLoading, error, createOrUpdateSectionSummary } =
    useCreateOrUpdateSectionSummary({
      summary,
      sectionId,
      handleClose,
    });

  return (
    <>
      {!summary?.body || summary?.body === "<p></p>" ? (
        <>
          {!isCertifier && (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={handleShow}
              className="d-print-none py-0 w-100"
            >
              <span translate="no" className="material-symbols-outlined md-16">
                edit
              </span>{" "}
              Add Section Summary
            </Button>
          )}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between border-top pt-3 mb-1">
            <h5 className="fw-bold title me-2">Section Summary:</h5>{" "}
            {!isCertifier && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={handleShow}
                className="d-print-none py-0"
              >
                <span
                  translate="no"
                  className="material-symbols-outlined md-16"
                >
                  edit
                </span>
              </Button>
            )}
          </div>
          <div className="mx-2 mb-3 position-relative">
            <div
              className="content"
              style={{
                wordBreak: "break-word",
              }}
              dangerouslySetInnerHTML={{
                __html: formatAndCleanExecutiveSummaryContent(summary?.body),
              }}
            />
          </div>
        </>
      )}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Section Summary</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={sectionSummaryValidationSchema}
          onSubmit={(values) => {
            createOrUpdateSectionSummary({
              summary: values.summary,
            });
          }}
          initialValues={{
            summary: summary ? summary.body : "",
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body className="py-0">
                <Form.Group controlId="summary">
                  <div className="rounded border">
                    <SlateEditor
                      name="summary"
                      setFieldValue={setFieldValue}
                      oldValue={values.summary}
                      placeholder="Enter Summary"
                      minHeight={500}
                      customClasses={["executive-summary"]}
                    />
                  </div>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer className="border-0">
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={isLoading}>
                  <span translate="no">
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}
                  </span>
                  <span>Update</span>
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ManuscriptSectionSummary.propTypes = {
  summary: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
};

export default ManuscriptSectionSummary;
