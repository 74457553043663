import { truncate } from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";

const ActionItemCommentsHistory = ({
  previousComment,
  currentComment,
  type,
}) => {
  const [seeMore, setSeeMore] = useState(false);

  return (
    <>
      <tr>
        <td colSpan={2} className="border-0 ">
          <p className="mb-0">
            {type !== "created" && (
              <span className="text-secondary fw-semibold">
                Current Comment:{" "}
              </span>
            )}
            {!seeMore
              ? truncate(currentComment, {
                  length: 200,
                })
              : currentComment}{" "}
            {currentComment?.length > 70 && (
              <span
                className="text-decoration-underline cursor-pointer text-primary"
                onClick={() => setSeeMore(!seeMore)}
              >
                {!seeMore ? "See more" : "See less"}
              </span>
            )}
          </p>
        </td>
      </tr>
      {previousComment && (
        <tr>
          <td colSpan={2} className="border-0">
            <p className="mb-0 ">
              <span className="text-secondary fw-semibold">
                Previous Comment:{" "}
              </span>
              {!seeMore
                ? truncate(previousComment, {
                    length: 200,
                  })
                : previousComment}
            </p>
            {previousComment?.length > 70 && (
              <p
                className="text-decoration-underline cursor-pointer"
                onClick={() => setSeeMore(!seeMore)}
              >
                {!seeMore ? "See more" : "See less"}
              </p>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

ActionItemCommentsHistory.propTypes = {
  previousComment: PropTypes.string.isRequired,
  currentComment: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ActionItemCommentsHistory;
