import { useState, useEffect, useRef } from "react";
import { put } from "utils/DeApi";
import { useToast } from "hooks";
const useUpdateAuditFile = ({ file, onFileUpdate }) => {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const updateAuditFile = (formData) => {
    setError(null);
    setIsLoading(true);

    const auditFileUpdatePromise = put(`files/${file?.fileId}`, formData);

    auditFileUpdatePromise.promise
      .then(({ data }) => {
        onFileUpdate(data);
        toast.success("Success", "File has been updated successfully");
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          toast.error("Error", "Failed to update the file");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(auditFileUpdatePromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return { isLoading, error, updateAuditFile };
};

export default useUpdateAuditFile;
