import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import "./Map.scss";
import Marker from "./Marker";
import Autocomplete from "./Autocomplete";
import { drawPolygons } from "utils/MapUtils";
import { colorfy } from "utils/MapUtils";

const Map = ({
  values,
  draggable,
  geolocations,
  handlePinDrop,
  onMarkerInteraction,
  onMarkerInteractionUp,
  search,
  onPlacesChanged,
}) => {
  const [mapsApiLoaded, setMapsApiLoaded] = useState({
    loaded: false,
    maps: null,
    map: null,
  });
  const [polygons, setPolygons] = useState({});
  const DEFAULT_ZOOM = 17;

  const apiIsLoaded = (map, maps) => {
    setMapsApiLoaded({ loaded: true, maps: maps, map: map });

    // function to draw polygons
    setPolygons(drawPolygons(map, maps, geolocations, search, polygons));

    const bounds = new maps.LatLngBounds();
    geolocations
      .map(({ latitude, longitude }) => ({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      }))
      .forEach((place) => {
        bounds.extend(new maps.LatLng(place.lat, place.lng));
      });

    map.fitBounds(bounds);
    if (search) {
      map.setZoom(map.getZoom() - 3);
    }
    window.addEventListener("resize", () => {
      map.fitBounds(bounds);
      if (search) {
        map.setZoom(map.getZoom() - 3);
      }
    });
  };

  useEffect(() => {
    if (mapsApiLoaded.loaded) {
      apiIsLoaded(mapsApiLoaded.map, mapsApiLoaded.maps);
    }
  }, [geolocations]);

  [
    "fullscreenchange",
    "webkitfullscreenchange",
    "mozfullscreenchange",
    "MSFullscreenChange",
  ].forEach((event) => {
    document.addEventListener(event, () => {
      const pacContainer = document.querySelector(".pac-container");
      if (!pacContainer) return;
      const fullscreenElement =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      if (fullscreenElement) {
        fullscreenElement.appendChild(pacContainer);
        pacContainer.className += " fullscreen-pac-container";
      } else {
        document.body.appendChild(pacContainer);
        pacContainer.className = pacContainer.className.replaceAll(
          " fullscreen-pac-container",
          ""
        );
      }
    });
  });

  return (
    <>
      <div className="w-100" style={{ height: "400px" }}>
        {mapsApiLoaded.loaded && search && (
          <Autocomplete
            maps={mapsApiLoaded.maps}
            map={mapsApiLoaded.map}
            onPlacesChanged={onPlacesChanged}
          />
        )}
        <GoogleMapReact
          onChildClick={handlePinDrop}
          draggable={draggable}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_KEY,
            libraries: ["places", "geometry"],
          }}
          onChildMouseDown={onMarkerInteraction}
          onChildMouseUp={onMarkerInteractionUp}
          onChildMouseMove={onMarkerInteraction}
          center={{ lat: +values?.latitude || 0, lng: +values?.longitude || 0 }}
          defaultZoom={DEFAULT_ZOOM}
          options={{
            minZoom: 2,
            maxZoom: 20,
            fullscreenControl: search,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        >
          {Array.isArray(geolocations)
            ? geolocations.map(
                (
                  { latitude, longitude, title, id, showAsPolygon, questionId },
                  ind
                ) => (
                  <Marker
                    key={id || ind}
                    id={id || ind}
                    visible={!showAsPolygon} //show pin if not set to polygon view in summary
                    summary={!search} //summary views do not have search
                    title={title}
                    lat={latitude}
                    lng={longitude}
                    draggable={draggable}
                    color={questionId ? colorfy(questionId) : colorfy(title)}
                  />
                )
              )
            : null}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default Map;
