import React, { useContext, useRef, useState } from "react";
import { Button, Dropdown, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import DownloadArcGisCsv from "./DownloadArcGisCsv";

const ArcGis = ({ audit }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState();

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard(link).catch((err) => setError(err));
  };

  const generatingLink = () => {
    setIsLoading(true);
    const { auditId, protocolId } = audit;

    const subscribersPromise = get(
      `/organizations/${organization?.id}/geotags-data-link`,
      {
        params: {
          protocol_id: protocolId,
          assessments: [auditId],
        },
      }
    );

    subscribersPromise.promise
      .then(({ data }) => {
        setLink(data);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(subscribersPromise);
  };

  error && console.log(error);

  return (
    <>
      <div className="d-flex me-2">
        <DownloadArcGisCsv audit={audit} />
        <Dropdown autoClose={false} onClick={() => generatingLink()}>
          <Dropdown.Toggle className="btn-sm bg-primary bg-opacity-10 text-dark border-0 text-start">
            Data Link
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-fixed-320">
            <Dropdown.Item className="bg-white">
              {isLoading && (
                <Spinner
                  className="me-2"
                  animation="border"
                  size="sm"
                  variant="dark"
                />
              )}
              {link && (
                <div className="d-flex flex-row mt-1">
                  <div className="flex-fill">
                    <Form.Control
                      type="text"
                      readOnly
                      disabled
                      className="p-1"
                      value={link}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={handleCopyClick}
                      size="sm"
                      className="ms-2"
                      variant="outline-secondary"
                    >
                      <span
                        translate="no"
                        className="material-symbols-outlined md-16"
                      >
                        content_copy
                      </span>
                    </Button>
                  </div>
                </div>
              )}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

ArcGis.propTypes = {
  audit: PropTypes.object.isRequired,
};

export default ArcGis;
