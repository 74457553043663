import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import DynamicQuestionSummaryTable from "../DynamicQuestionSummaryTable";

const SummaryTables = ({ showInapplicable, riskLevels }) => {
  const { audit } = useContext(AuditContext);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [summaryTable, setSummaryTable] = useState();
  const [isSummaryTableEMpty, setIsSummaryTableEMpty] = useState();
  const subscribedPromises = useRef([]);
  useEffect(() => {
    const fetchSummaryTable = () => {
      const summaryTablePromise = get(
        `audits/${audit.auditId}/summary-tables`,
        {
          params: { showInapplicable: showInapplicable ? 1 : 0 },
        }
      );
      summaryTablePromise.promise
        .then(({ data }) => {
          const { dynamicQuestions = [] } = data || {};

          setIsSummaryTableEMpty(!Array.isArray(dynamicQuestions));
          setSummaryTable(data);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });
      subscribedPromises.current.push(summaryTablePromise);
    };
    fetchSummaryTable();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [audit?.auditId, showInapplicable, audit?.responses, audit?.observations]);
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler />;
  if (!isSummaryTableEMpty)
    return (
      <h2 className="text-primary border-bottom border-primary text-uppercase">
        Summary Table
      </h2>
    );
  return (
    <>
      <DynamicQuestionSummaryTable summaryTable={summaryTable} />
    </>
  );
};
SummaryTables.propTypes = {
  audit: PropTypes.object.isRequired,
  showInapplicable: PropTypes.bool.isRequired,
};
export default SummaryTables;
