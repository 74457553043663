import Loader from "components/ui/Loader";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DisplayLoader = () => {
  return (
    <tr>
      <td colSpan={6} className="text-center">
        <Loader />
      </td>
    </tr>
  );
};

const ReportTable = ({
  organizationReports,
  setSelectedReport,
  setShowEditModal,
  setShowDeleteModal,
  isUserAdmin,
  loading,
}) => {
  const navigate = useNavigate();
  return (
    <Table className="cursor-default">
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Updated</th>
          <th>Type</th>
          {isUserAdmin && <th>Actions</th>}
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {loading && DisplayLoader()}
        {organizationReports &&
          organizationReports.map((element, index) => (
            <tr key={index}>
              <td
                className="text-primary cursor-pointer"
                onClick={() => {
                  setSelectedReport(element);
                  navigate(`/reports/${element.id}`);
                }}
              >
                {element.title} <br />
                <small className="text-secondary">{element.id}</small>
              </td>
              <td className="align-middle max-width-200 text-truncate">
                {element.description || "-"}
              </td>
              <td className="align-middle">
                <small>
                  {new Date(element.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </td>
              <td className="align-middle">{element.reportType}</td>
              <td className="align-middle">
                {isUserAdmin && (
                  <div className="d-flex">
                    <Button
                      className="ms-2"
                      variant="outline-primary"
                      size="sm"
                      onClick={() => {
                        setShowEditModal(true);
                        setSelectedReport(element);
                      }}
                    >
                      <span
                        translate="no"
                        className="material-symbols-outlined md-16"
                      >
                        edit
                      </span>
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      className="ms-2"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedReport(element);
                      }}
                    >
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18"
                      >
                        delete
                      </span>
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ReportTable;
