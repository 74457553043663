import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useFetchMembers } from "features/activityLogs/services/useFetchMembers";

const MembersTypeahead = ({ onChange, selected }) => {
  const { isLoading, error, members } = useFetchMembers({});
  const [member, setMember] = useState([]);

  error && console.error(error);

  useEffect(() => {
    setMember(selected);
  }, [selected]);

  return (
    <Typeahead
      id="member-typeahead"
      name="member"
      clearButton
      isLoading={isLoading}
      labelKey={(option) => `${option.firstName} ${option.lastName}`}
      placeholder="Select an actor..."
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps}>
          {results.map((result, index) => (
            <MenuItem
              key={result.subscriberId}
              option={result}
              position={index}
            >
              {result.firstName} {result.lastName} <br />
              <small className="text-muted">{result.email}</small>
            </MenuItem>
          ))}
        </Menu>
      )}
      onChange={(data) => {
        onChange(data);
        setMember(data);
      }}
      options={members}
      selected={member}
    />
  );
};

MembersTypeahead.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default MembersTypeahead;
