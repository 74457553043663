import React from "react";

import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";

import { getURLFileExtension } from "utils/StringUtils";

const AuditFileDownload = ({ file }) => {
  const { fileId, name } = file;
  const fileURL = file.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  return (
    <Button
      title="download"
      variant="outline-secondary"
      size="sm"
      onClick={() => {
        saveAs(fileURL, name || `${fileId}.${fileEXT}`);
      }}
    >
      <span translate="no" className="material-symbols-outlined md-16">
        download
      </span>
    </Button>
  );
};
AuditFileDownload.propTypes = {
  file: PropTypes.object.isRequired,
};
export default AuditFileDownload;
