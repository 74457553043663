import React, { useEffect, useRef, useState } from "react";
import ActionItemLink from "./ActionItemLink";
import { get } from "utils/DeApi";

const ActionItemLevelRedirect = (props) => {
  const {
    taskableType,
    path,
    facility,
    audit,
    organizationId,
    taskableId,
    show,
  } = props || {};
  const subscribedPromises = useRef([]);
  const [questionData, setQuestionData] = useState();

  const removeHtmlTags = (input) => {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.body.textContent || "";
  };

  const facilityData = [
    {
      taskableType: "Entity",
      path,
      name: facility || "N/A",
    },
  ];

  useEffect(() => {
    const fetchQuestions = () => {
      const questionPromise = get(`questions/${taskableId}`);
      questionPromise.promise
        .then(({ data: { prompt } }) =>
          setQuestionData([
            {
              taskableType: "Entity",
              path: `/facilities/${audit?.facility?.facilityId}`,
              name: audit?.facility?.name,
            },
            {
              taskableType: "Assessment",
              path: `/audits/${audit?.auditId}`,
              name: audit?.name,
            },
            {
              taskableType: "Question",
              path,
              name: removeHtmlTags(prompt) || "N/A",
            },
          ])
        )
        .catch((error) => !error.isCanceled && console.log(error));

      subscribedPromises.current.push(questionPromise);
    };

    if (taskableType === "question" && show) fetchQuestions();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [audit, path, show, taskableId, taskableType]);

  if (taskableType === "facility")
    return (
      <ActionItemLink data={facilityData} organizationId={organizationId} />
    );
  if (taskableType === "question")
    return (
      <ActionItemLink data={questionData} organizationId={organizationId} />
    );
};

export default ActionItemLevelRedirect;
