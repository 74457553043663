import React, { useContext, useEffect, useRef, useState } from "react";

import moment from "moment";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { isEmpty, uniqBy } from "lodash";
import DatePicker from "react-datepicker";
import { Button, Form, Offcanvas, Spinner, Dropdown } from "react-bootstrap";

import { get } from "utils/DeApi";
import SlateEditor from "components/ui/SlateEditor";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useUpdateAudit } from "features/assessment/services";
import { auditValidationSchema } from "features/assessment/schemas";

import { useRoles } from "hooks";
import { Typeahead } from "react-bootstrap-typeahead";
import GroupTypeahead from "../../common/GroupTypeahead";
import ProtocolTypeahead from "../../common/ProtocolTypeahead";
import { OrganizationContext } from "contexts/OrganizationProvider";

const AuditUpdate = ({ audit, children, onAuditUpdated }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const { isCertifier, isContributor } = useRoles();

  const [show, setShow] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [tags, setTags] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { error, isLoading, updateAudit } = useUpdateAudit({
    auditId: audit.auditId,
    onAuditUpdated: (data) => {
      handleClose();
      onAuditUpdated(data);
    },
  });

  useEffect(() => {
    const fetchTags = () => {
      setIsSyncing(true);
      const subscribersPromise = get(
        `/organizations/${organization?.id}/audit-labels`
      );
      subscribersPromise.promise
        .then((response) => {
          setTags(response.data ?? []);
          setIsSyncing(false);
        })
        .catch((error) => {
          !error.isCanceled && setTagError(error);
          setIsSyncing(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    fetchTags();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization?.id]);

  if (isCertifier || isContributor) return <></>;

  return (
    <>
      {React.cloneElement(
        children || (
          <Dropdown.Item className="text-primary" onClick={handleShow}>
            <span
              translate="no"
              className="material-symbols-outlined md-18 me-2"
            >
              edit
            </span>
            Update
          </Dropdown.Item>
        ),
        { onClickHandler: handleShow }
      )}

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="w-fixed-640"
        placement="end"
        scroll
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Update Assessment</Offcanvas.Title>
        </Offcanvas.Header>
        <Formik
          validationSchema={auditValidationSchema}
          onSubmit={(values) => {
            updateAudit({
              name: values.name,
              description: values.description,
              labels: values?.tags?.length
                ? values?.tags.map((tag) => tag.auditLabelId)
                : [],
              auditGroupId:
                Array.isArray(values.group) && values.group.length
                  ? values.group?.[0]?.id
                  : "",
              protocolId:
                Array.isArray(values.protocol) && values.protocol.length
                  ? values.protocol?.[0]?.protocolId
                  : "",
              executiveSummary: values.executiveSummary,
              startedAt: values.startedAt,
              completedAt: values.completedAt,
              dueDate: values.dueDate
                ? moment(values.dueDate).format("YYYY-MM-DD hh:mm:ss")
                : "",
              facilityId: audit?.facilityId,
            });
          }}
          initialValues={{
            name: audit.name,
            description: audit.description,
            tags: audit.labels ?? [],
            executiveSummary: audit.executiveSummary,
            group: audit?.group ? [audit.group] : [],
            protocol: audit.protocol ? [audit.protocol] : [],
            startedAt: "2022-01-19 03:14:07",
            completedAt: "2022-01-19 03:14:07",
            dueDate: audit.dueDate ? new Date(audit.dueDate) : null,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Body className="h-75vh pb-5 overflow-auto">
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label>
                    Assessment Name <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Assessment Name"
                    type="text"
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isValid={values.name && !errors.name}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="protocol" className="mb-3">
                  <Form.Label>
                    Assessment Protocol <RequiredAsterisk />
                  </Form.Label>
                  <ProtocolTypeahead
                    isInvalid={errors.protocol && touched.protocol}
                    selected={values.protocol}
                    isValid={!isEmpty(values?.protocol) && !errors.protocol}
                    onChange={(protocol) => {
                      if (!protocol.length) setFieldTouched("protocol", true);
                      if (protocol.length) setFieldTouched("protocol", false);
                      setFieldValue("protocol", protocol);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.protocol && touched.protocol ? (
                      <small>{errors.protocol}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="tags" className="mb-3">
                  <Form.Label className="mb-1">Tags</Form.Label>
                  <Typeahead
                    id="tags-typeahead"
                    clearButton
                    placeholder="Select tags ..."
                    newSelectionPrefix="Add: "
                    isLoading={isSyncing}
                    labelKey={`name`}
                    isValid={
                      !!values.tags.length && !errors.tags && touched.tags
                    }
                    isInvalid={!(values.tags && !errors.tags) && touched.tags}
                    onChange={(tags) => {
                      setFieldValue(
                        "tags",
                        uniqBy(
                          tags.map((tag) => ({
                            ...tag,
                            name: tag?.name.toLowerCase(),
                          })),
                          "name"
                        )
                      );
                    }}
                    options={tags}
                    selected={values.tags}
                    multiple
                  />
                </Form.Group>

                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Assessment Description (Optional)</Form.Label>
                  <Form.Control
                    rows={3}
                    placeholder="Enter Description"
                    as="textarea"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    isValid={values.description && !errors.description}
                    isInvalid={!!errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="group" className="mb-3">
                  <Form.Label>
                    Group Assignment (Optional) <br />
                    <small>
                      An assessment can only be assigned to <strong>1</strong>{" "}
                      group
                    </small>
                  </Form.Label>
                  <GroupTypeahead
                    allowNew={true}
                    selected={values?.group}
                    facilityId={audit?.facilityId}
                    placeholder="Select or create a group"
                    isValid={!isEmpty(values?.group) && !errors?.group}
                    onChange={(group) => setFieldValue("group", group)}
                  />
                </Form.Group>
                <Form.Group
                  controlId="executiveSummary"
                  className="my-3 visually-hidden"
                >
                  <Form.Label>Executive Summary</Form.Label>
                  <div className="bg-light rounded border">
                    <SlateEditor
                      name="executiveSummary"
                      setFieldValue={setFieldValue}
                      oldValue={values.executiveSummary}
                      placeholder="Enter Executive Summary"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">Due Date</Form.Label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    type="date"
                    name="dueDate"
                    selected={values.dueDate}
                    popperPlacement="top-start"
                    className="audit-datepicker form-control"
                    onChange={(date) => setFieldValue("dueDate", date)}
                    onBlur={handleBlur}
                    isValid={values.dueDate && !errors.dueDate}
                    isInvalid={
                      !(values.dueDate && !errors.dueDate) && touched.dueDate
                    }
                    isClearable
                    placeholderText="Enter Due Date"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dueDate && touched.dueDate ? (
                      <small>{errors.dueDate}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {tagError && <ErrorHandler error={tagError} />}
              </Offcanvas.Body>
              <div className="d-flex flex-row p-3 bg-light position-absolute bottom-0  w-100">
                <div className="flex-fill"></div>
                <div>
                  <Button
                    size="sm"
                    className="me-2"
                    onClick={handleClose}
                    variant="outline-primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    disabled={!isValid || isLoading}
                  >
                    {isLoading && (
                      <Spinner
                        size="sm"
                        variant="light"
                        className="me-2"
                        animation="border"
                      />
                    )}{" "}
                    Update Assessment
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

AuditUpdate.propTypes = {
  audit: PropTypes.object.isRequired,
  onAuditUpdated: PropTypes.func.isRequired,
};

export default AuditUpdate;
