import { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useRoles } from "hooks";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";
import { OrganizationContext } from "contexts/OrganizationProvider";

import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ActionItemCard from "features/actionItems/components/common/ActionItemCard";

function CorrectiveQuestionActionItems({
  auditId,
  facilityId,
  questionId,
  actionItems,
  disableViewAll,
  updateActionItemCount,
  ondeletedActionItem,
  setActionItems,
}) {
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { isCertifier } = useRoles();

  useEffect(() => {
    const fetchActionItems = () => {
      setIsLoading(true);
      setError(null);
      const subscribersPromise = get(
        `subscribers/${user?.de?.subscriberId}/action-items`,
        {
          params: {
            perPage: 5,
            organizationId: organization?.id,
            "filter[type]": "question",
            "filter[question_id]": questionId,
            "filter[audit_id]": auditId,
            "filter[status]": "0, 1, 2",
          },
        }
      );

      subscribersPromise.promise
        .then(({ data, meta }) => {
          setActionItems(data);
          updateActionItemCount(questionId, meta.total);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (!isCertifier) fetchActionItems();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization, user, isCertifier, auditId, questionId, setActionItems]);

  if (isCertifier) return <></>;
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!actionItems) return <></>;
  if (!actionItems?.length)
    return (
      <EmptyStateHandler
        title="No action items found"
        description="There are currently no action items to show for the  selected Question."
      />
    );
  return (
    <>
      <div className="mt-2">
        {actionItems &&
          actionItems.length > 0 &&
          actionItems.map((element) => (
            <div className="mb-1" key={element.actionItemId}>
              <ActionItemCard
                facilityId={facilityId}
                taskableId={questionId}
                taskableType={"question"}
                className="w-50 mb-3"
                actionItems={[element]}
                setActionItems={setActionItems}
                ondeletedActionItem={ondeletedActionItem}
                disableViewAll={disableViewAll}
                to={`/action-items?taskableType=question&taskableId=${questionId}&facilityId=${facilityId}&auditId=${auditId}`}
              />
            </div>
          ))}
      </div>
    </>
  );
}

CorrectiveQuestionActionItems.propTypes = {
  facilityId: PropTypes.string.isRequired,
};

export default CorrectiveQuestionActionItems;
