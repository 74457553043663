import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { useDownloadAuditRawWord } from "features/assessment/services";
function AuditWordExportRaw({
  auditId,
  name,
  charts,
  organization,
  showAttachments,
  showCitation,
  showExecutiveSummary,
  showInapplicable,
  showSummary,
  showCharts,
}) {
  const { error, isLoading, downloadAudit } = useDownloadAuditRawWord(
    auditId,
    name,
    charts,
    organization,
    showAttachments,
    showCitation,
    showExecutiveSummary,
    showInapplicable,
    showSummary,
    showCharts
  );

  error && console.error(error);

  return (
    <Button
      className="btn-sm bg-primary bg-opacity-10 text-dark border-0 px-3"
      onClick={downloadAudit}
      disabled={isLoading}
    >
      <span translate="no">
        {isLoading && (
          <Spinner
            className="ms-2"
            animation="border"
            size="sm"
            variant="primary"
          />
        )}
      </span>
      <span>Raw Word (DOCX)</span>
      <span className="text-danger mx-1">
        <strong>
          <i>Deprecating</i>
        </strong>
      </span>
    </Button>
  );
}
AuditWordExportRaw.propTypes = {
  auditId: PropTypes.string.isRequired,
  charts: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
};
export default AuditWordExportRaw;
