import { useContext } from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { showRisk } from "features/assessment/components/Audit/helper";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { getRiskLevel } from "features/assessment/components/Audit/helper";

import FormatResponse from "../FormatResponse";
import FieldClearButton from "../FieldClearButton";

const FieldRiskOption = ({ isDisabled }) => {
  const { audit } = useContext(AuditContext);
  const { values, setFieldValue } = useFormikContext();

  const { conformity, questionOptions, riskLevels } = audit?.protocol;
  const [riskLabel, , , , , , ,] = questionOptions || [];
  const riskLevel = getRiskLevel(riskLevels) || [];
  const _showRisk = showRisk(conformity, questionOptions);

  if (!_showRisk) return <></>;

  const clearSelection = () => {
    setFieldValue("risk", "");
  };

  return (
    <Form.Group controlId="risk" className="my-3">
      <Form.Label className="d-flex justify-content-between align-items-center">
        <div>
          <strong>{riskLabel?.name || "Risk Rating"}</strong>
          <RequiredAsterisk />
          <FormatResponse
            options={riskLevel || []}
            label={riskLabel?.name || "Risk Rating"}
            index={
              Array.isArray(riskLevel)
                ? riskLevel.findIndex(({ id }) => id === values.risk)
                : -1
            }
          />
        </div>
        <FieldClearButton
          handleOnClick={clearSelection}
          isDisabled={isDisabled}
        />
      </Form.Label>

      {riskLevel.map(({ id, value }) => (
        <Form.Check
          inline
          label={value}
          name="risk"
          value={id}
          type="radio"
          key={`radio-${id}`}
          checked={values.risk === id}
          onChange={() => setFieldValue("risk", id)}
          disabled={values.isInapplicable || isDisabled}
        />
      ))}
    </Form.Group>
  );
};

FieldRiskOption.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldRiskOption;
