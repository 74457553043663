import { useEffect, useState } from "react";

import { find } from "lodash";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { FlexibleFields } from "features/assessment/constants";

import LabelScore from "../LabelScore";
import InfoTooltip from "../InfoTooltip";
import InherentRiskColor from "./InherentRiskColor";
import ResidualRiskColor from "./ResidualRiskColor";
import FieldClearButton from "../../FieldClearButton";
import InherentRiskRanking from "./InherentRiskRanking";
import { choiceColorMap, createEquation, evaluateExpression } from "./helper";

const { COMPOUND_RADIO } = FlexibleFields;

const FieldCompound = ({ question, questions, isDisabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const [qChildren, setQChildren] = useState([]);

  const renderEquation = (collection) => {
    if (!Array.isArray(collection)) return "";
    return `${createEquation(collection, "label")} = ${evaluateExpression(
      collection
    )}`;
  };

  const evaluateChildrenEquations = (q) => {
    const expressions = q?.properties?.mathFormula?.elements.map((operand) => {
      const flexibleQuestionId = operand?.flexibleQuestionId;
      if (flexibleQuestionId) {
        const _children = choiceColorMap(q?.children);
        const child = find(_children, ["id", flexibleQuestionId]);
        if (child) {
          const choice =
            find(child?.choices, [
              "id",
              values.dynamic[flexibleQuestionId]?.response,
            ]) || {};
          return {
            ...child,
            label: choice.label,
            score: choice.score,
            color: choice.color,
          };
        }
      }

      return operand;
    });

    const score = evaluateExpression(expressions);

    return {
      label: q?.prompt ? `${q?.prompt}(${score})` : "",
      score: score,
      expressions: expressions,
    };
  };

  const createMappedExpression = (question) => {
    const { children, properties } = question || {};

    return properties?.mathFormula?.elements.map((operand) => {
      const flexibleQuestionId = operand?.flexibleQuestionId;
      if (flexibleQuestionId) {
        const parentLevelQuestion = questions.find(
          ({ id }) => id === flexibleQuestionId
        );
        if (parentLevelQuestion)
          return evaluateChildrenEquations(parentLevelQuestion);

        const _children = choiceColorMap(children);
        const childLevelQuestion = find(_children, ["id", flexibleQuestionId]);

        if (childLevelQuestion) {
          const choice = find(childLevelQuestion?.choices, [
            "id",
            values.dynamic[flexibleQuestionId]?.response,
          ]);

          return {
            label: choice
              ? `${choice?.label} (${choice.score})`
              : childLevelQuestion?.prompt,
            score: choice?.score,
            color: choice?.color,
          };
        }

        return {
          label: operand?.symbol,
          score: NaN,
        };
      }
      return operand;
    });
  };

  useEffect(() => {
    if (Array.isArray(question?.children)) {
      const _children = choiceColorMap(question?.children);
      setQChildren(_children);
    }
  }, [question?.children]);

  if (question?.properties?.fieldType !== "compound") return <></>;

  return (
    <>
      {qChildren.map((child) => {
        const choices = child?.choices || [];

        const selected = choices.find(({ id }) => {
          if (!values.dynamic) return false;
          if (!values.dynamic[child.id]) return false;
          return values.dynamic[child.id]?.response === id;
        });

        return (
          <div className="d-flex flex-row w-100" key={child.id}>
            <div className="pe-2 pt-1">
              <span
                translate="no"
                className="material-symbols-outlined md-18 text-muted"
              >
                remove
              </span>
            </div>
            <div className="flex-fill">
              <Form.Group controlId={child.id} className="mb-3">
                <Form.Label className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <span>{child.prompt}</span>
                    {child.properties.required && <RequiredAsterisk />}
                    <InfoTooltip id={child.id} text={child.description} />
                  </div>
                  <div className="d-flex align-items-center">
                    {selected && (
                      <span
                        className="float-end rounded-sm badge px-2 py-1 text-white me-2"
                        style={{
                          backgroundColor: selected?.color.toString(),
                        }}
                      >
                        <strong>{selected.label}</strong>
                      </span>
                    )}
                    <FieldClearButton
                      handleOnClick={() =>
                        setFieldValue(`dynamic.${child.id}`, {
                          type: COMPOUND_RADIO,
                          response: "",
                        })
                      }
                      isDisabled={isDisabled}
                    />
                  </div>
                </Form.Label>
                {choices.map(({ label, score, id }) => {
                  return (
                    <Form.Check
                      inline
                      id={id}
                      key={id}
                      value={id}
                      type="radio"
                      name={child.id}
                      label={
                        <>
                          {label} <LabelScore score={score} />
                        </>
                      }
                      disabled={values.isInapplicable || isDisabled}
                      checked={values.dynamic[child.id]?.response === id}
                      onChange={(ev) => {
                        setFieldValue(`dynamic.${child.id}`, {
                          type: COMPOUND_RADIO,
                          response: id,
                        });
                      }}
                    />
                  );
                })}
              </Form.Group>
            </div>
          </div>
        );
      })}
      {question.properties.hasFormula && (
        <>
          <div className="d-flex flex-row">
            <div className="pe-2">
              <span
                translate="no"
                className="material-symbols-outlined md-18 text-muted"
              >
                remove
              </span>
            </div>
            <Form.Label className="w-100">
              {question.prompt}
              <InherentRiskColor
                values={values}
                children={qChildren}
                label={question.prompt || "Inherent Risk"}
              />
              <ResidualRiskColor
                label={question.prompt || "Residual Risk"}
                expression={createMappedExpression(question)}
              />
            </Form.Label>
          </div>
          <div className="w-100 ps-5">
            {renderEquation(createMappedExpression(question))}
          </div>
        </>
      )}

      <InherentRiskRanking children={qChildren} values={values} />
    </>
  );
};

FieldCompound.propTypes = {
  question: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldCompound;
