import React, { useContext } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { types } from "features/actionItems/constants";

import isFilterActive from "features/actionItems/utils/isFilterActive";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context/ActionItemProvider";

const AdvanceFilters = () => {
  const {
    filters: { updatedDate, createdAt, type, dueDate },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);

  const [updatedStartDate, updatedEndDate] = updatedDate;
  const [createdAtStartDate, createdAtEndDate] = createdAt;
  const [dueStartDate, dueEndDate] = dueDate;

  return (
    <Col lg={2} className="mb-3">
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-done"
          className={`w-100 text-truncate border border-gray-900  ${isFilterActive(
            type?.name || createdAtEndDate || updatedEndDate
          )}`}
        >
          More Filters
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-fixed-560 overflow-visible p-3">
          <Row>
            <Col lg={6} className="mb-3">
              <small className="text-muted my-2">Type</small>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="dropdown-done"
                  className={`w-100 mt-2 text-truncate border border-gray-900  ${isFilterActive(
                    type?.name
                  )}`}
                >
                  {type?.name || "Types"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {types.map((t) => (
                    <Dropdown.Item
                      key={t.index}
                      active={t.index === type.index}
                      onClick={() => {
                        dispatch({ type: "SET_FILTERS", payload: { type: t } });
                      }}
                    >
                      {t.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col lg={6}>
              <Row>
                <Col xs={12} className="mb-3 date-filter">
                  <small className="text-muted my-2">
                    <span
                      translate="no"
                      className="material-symbols-outlined md-18"
                    >
                      calendar_today
                    </span>{" "}
                    Date Created
                  </small>
                  <DatePicker
                    isClearable
                    dateFormat="MM/dd/yyyy"
                    selected={createdAtStartDate}
                    onChange={(date) => {
                      dispatch({
                        type: "SET_FILTERS",
                        payload: { createdAt: date },
                      });
                    }}
                    startDate={createdAtStartDate}
                    endDate={createdAtEndDate}
                    selectsRange
                    placeholderText="Date Created"
                    monthsShown={2}
                    className={`form-control mt-2 w-100 ${isFilterActive(
                      createdAtEndDate
                    )}`}
                  >
                    <Button
                      size="sm"
                      className="mb-1 position-absolute bottom-0 end-0 me-2"
                      onClick={() => {
                        dispatch({
                          type: "SET_FILTERS",
                          payload: { createdAt: [] },
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </DatePicker>
                </Col>
                <Col xs={12} className="date-filter mb-3">
                  <small className="text-muted my-2">
                    <span
                      translate="no"
                      className="material-symbols-outlined md-18"
                    >
                      calendar_today
                    </span>{" "}
                    Date Updated
                  </small>
                  <DatePicker
                    isClearable
                    dateFormat="MM/dd/yyyy"
                    selected={updatedStartDate}
                    onChange={(date) => {
                      dispatch({
                        type: "SET_FILTERS",
                        payload: { updatedDate: date },
                      });
                    }}
                    startDate={updatedStartDate}
                    endDate={updatedEndDate}
                    selectsRange
                    placeholderText="Date Updated"
                    monthsShown={2}
                    className={`form-control mt-2 w-100 ${isFilterActive(
                      updatedEndDate
                    )}`}
                  >
                    <Button
                      size="sm"
                      className="mb-1 position-absolute bottom-0 end-0 me-2"
                      onClick={() => {
                        dispatch({
                          type: "SET_FILTERS",
                          payload: { updatedDate: [] },
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </DatePicker>
                </Col>
                <Col xs={12} className="date-filter">
                  <small className="text-muted my-2">
                    <span
                      translate="no"
                      className="material-symbols-outlined md-18"
                    >
                      calendar_today
                    </span>{" "}
                    Due Date
                  </small>
                  <DatePicker
                    isClearable
                    dateFormat="MM/dd/yyyy"
                    selected={dueStartDate}
                    onChange={(date) => {
                      dispatch({
                        type: "SET_FILTERS",
                        payload: { dueDate: date },
                      });
                    }}
                    startDate={dueStartDate}
                    endDate={dueEndDate}
                    selectsRange
                    placeholderText="Due Date"
                    monthsShown={2}
                    className={`form-control mt-2 w-100 ${isFilterActive(
                      dueEndDate
                    )}`}
                  >
                    <Button
                      size="sm"
                      className="mb-1 position-absolute bottom-0 end-0 me-2"
                      onClick={() => {
                        dispatch({
                          type: "SET_FILTERS",
                          payload: { dueDate: [] },
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </DatePicker>
                </Col>
              </Row>
            </Col>
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  );
};

export default AdvanceFilters;
