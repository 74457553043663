import * as echarts from "echarts";
import { colorMap } from "features/assessment/constants";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { compact, groupBy } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef } from "react";
import "./ReportAggregatedConformityLevel.scss";

const ReportAggregatedConformityLevel = ({
  setChartImage,
  isExportable = true,
}) => {
  const el = useRef(null);
  const { audit } = useContext(AuditContext);

  useEffect(() => {
    const flexibleQuestions = audit?.protocol?.flexibleQuestions.filter(
      ({ questionType, prompt }) =>
        questionType === "Radio Field" &&
        prompt.toLowerCase().includes("conformity")
    );

    const observations = audit?.questions.flatMap(
      (question) => question.observations || []
    );

    const responses = observations.flatMap(
      (observation) => observation.responses
    );

    const flexibleQuestionIds = flexibleQuestions.map(({ id }) => id);

    const filteredFieldResponses = Array.isArray(responses)
      ? responses?.filter(
          // Filter available responses for this field
          (item) =>
            item.choice && flexibleQuestionIds.includes(item.flexibleQuestionId)
        )
      : [];

    const fieldObservations = groupBy(filteredFieldResponses, (item) => {
      return item.choice.label;
    });

    const data = Object.keys(fieldObservations).map((key) => {
      const color = Object.values(colorMap).find(({ label }) => {
        return key.toLowerCase().includes(label?.toLowerCase());
      });

      return {
        name: key,
        color: color?.color || "",
        value: fieldObservations[key].length,
      };
    });

    const chartColors = compact(data.map(({ color }) => color));
    const dimension = {
      width: isExportable ? 800 : el.current.offsetWidth,
      height: isExportable ? 500 : el.current.offsetHeight,
    };

    const option = {
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />{d0}%",
      },
      legend: false,
      series: [
        {
          name: "Conformity Level",
          type: "pie",
          radius: "50%",
          label: {
            show: true,
            formatter: "{b}, {c}\n({d}%)",
            ...(isExportable
              ? {
                  alignTo: "labelLine",
                  overflow: "break",
                  fontSize: 14,
                  color: "#000",
                }
              : {}),
            edgeDistance: "1%",
          },
          labelLine: {
            length: 10,
            length2: 10,
          },

          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          percentPrecision: 1,
          data: data,
          color: data?.length === chartColors?.length ? chartColors : [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    if (!filteredFieldResponses.length) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
    }

    const chart = echarts.init(el.current, null, dimension);

    if (setChartImage) {
      chart.on("finished", () => {
        var img = new Image();
        img.src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        setChartImage(img);
        chart.dispose();
      });
    }

    chart.setOption(option);

    function handleResize() {
      chart.resize(dimension);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, audit, isExportable, setChartImage]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
};

ReportAggregatedConformityLevel.propTypes = {
  audit: PropTypes.object,
  isExportable: PropTypes.bool,
  setChartImage: PropTypes.func,
};

export default ReportAggregatedConformityLevel;
