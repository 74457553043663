import { useState } from "react";
import { Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
const Citations = ({ citations }) => {
  const VERTICAL_ID = process.env.REACT_APP_VERTICAL_ID;
  const showGuidance = VERTICAL_ID === "653ffd70aed8a";

  const [show, setShow] = useState(showGuidance);
  if (!Array.isArray(citations)) return <></>;
  if (citations.length === 0) return <></>;

  return (
    <>
      <h5
        onClick={() => setShow((prevState) => !prevState)}
        role="button"
        className="mb-0"
      >
        {show ? (
          <span translate="no" className="material-symbols-outlined me-2">
            expand_more
          </span>
        ) : (
          <span translate="no" className="material-symbols-outlined me-2">
            expand_less
          </span>
        )}
        Assessment Guidance ·{" "}
        <span className="text-muted">{citations.length}</span>
      </h5>
      <Collapse in={show}>
        <div>
          {citations.map(({ id, body }) => (
            <div className="p-3 my-2 bg-white rounded-3 border" key={id}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(body),
                }}
              />
            </div>
          ))}
        </div>
      </Collapse>
      <hr />
    </>
  );
};
Citations.propTypes = {
  citations: PropTypes.array,
};

export default Citations;
