import React, { Fragment, useContext } from "react";

import { useRoles } from "hooks";
import { Button, Card, Col, Table } from "react-bootstrap";

import { useFetchAuditFiles } from "features/assessment/services";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import AuditBulkUpload from "./AuditBulkUpload";
import AuditFileListItem from "./AuditFileListItem";
import { exportCSV } from "utils/CSV";
import { stripHTML } from "utils/StringUtils";

const AuditFiles = () => {
  const { audit } = useContext(AuditContext);

  const { isCertifier } = useRoles();

  const { error, isLoading, files, setFiles } = useFetchAuditFiles({
    auditId: audit?.auditId,
    fetchByDefault: !!audit?.protocol?.hasFiles,
  });

  const onFileUploaded = (uploadedFiles) => {
    setFiles((prevFiles) => [...uploadedFiles, ...prevFiles]);
  };

  const handleCSVExport = () => {
    const data =
      files?.length &&
      files
        .filter((file) => !!file.observationId)
        .map((file) => {
          const question = audit?.questions.find(
            ({ observations }) =>
              !!observations.find(
                ({ observationId }) => observationId === file.observationId
              )
          );

          return [
            audit?.facility?.name || "",
            question.sectionTitle || "",
            stripHTML(question.prompt) || "",
            file.name || [],
          ];
        });

    const header = ["Entity Name", "Section Name", "Question", "File Name"];

    exportCSV(data, header, "Assessment Files");
  };

  if (!audit?.protocol?.hasFiles) return <></>;

  return (
    <Col xs={12} sm={12} md={12} lg={8}>
      <div className="d-flex flex-row mb-3">
        <div className="flex-grow-1">
          <h4 className="pt-1 mb-0">Files & Photos</h4>
        </div>
        <span className="float-end">
          <Button
            size="sm"
            className="me-2"
            variant="outline-primary"
            onClick={handleCSVExport}
          >
            <span translate="no" className="material-symbols-outlined md-18">
              download
            </span>{" "}
            Export Excel (CSV)
          </Button>
          <AuditBulkUpload onFileUploaded={onFileUploaded} />
        </span>
      </div>
      <Card>
        <Card.Body className="table-responsive">
          <Table hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Level</th>
                <th>Updated</th>
                {!isCertifier && <th>Action</th>}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              <AuditFileListItem
                error={error}
                isLoading={isLoading}
                files={files}
                setFiles={setFiles}
              />
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default AuditFiles;
