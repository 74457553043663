import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { sortBy } from "lodash";
import { get } from "utils/DeApi";
import { getURLFileExtension } from "utils/StringUtils";
import {
  Card,
  Col,
  Row,
  Container,
  Placeholder,
  Button,
} from "react-bootstrap";
import { compressImage } from "utils/UploadUtils";
import ErrorHandler from "components/ui/ErrorHandler";
import "./AssessmentLevelFiles.scss";
import { saveAs } from "file-saver";

const AssessmentLevelFiles = ({ showAttachments }) => {
  const subscribedPromises = useRef([]);
  const { auditId } = useParams();
  const [files, setFiles] = useState([]);
  const [compressedImages, setCompressedImages] = useState({});
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFilesAndAttachments = async () => {
      try {
        setFiles([]);
        setError(null);
        setIsLoading(true);

        const fileResponse = await get(`audits/${auditId}/files`, {
          params: { sort: "updated_at" },
        }).promise;

        setFiles(sortBy(fileResponse.data || [], "updatedAt").reverse());

        await Promise.all(
          fileResponse.data.map(async ({ url, fileId }) => {
            try {
              const imageResponse = await get(url, { responseType: "blob" })
                .promise;
              const { type } = imageResponse;

              if (type.startsWith("image/")) {
                const compressedBlob = await compressImage(
                  imageResponse,
                  800,
                  0.1
                );
                const base64Image = await new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.onload = () => resolve(reader.result);
                  reader.readAsDataURL(compressedBlob);
                });

                setCompressedImages((prevImages) => ({
                  ...prevImages,
                  [fileId]: base64Image,
                }));
              }
            } catch (error) {
              console.log(error);
            }
          })
        );
      } catch (error) {
        if (!error.isCanceled) {
          setError(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (showAttachments) {
      fetchFilesAndAttachments();
    }

    return () => {
      subscribedPromises.current.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [auditId, showAttachments]);

  const imageFiles = files
    ?.filter(
      ({ observationId, url }) =>
        observationId === null &&
        ["png", "jpg", "jpeg", "svg"].includes(getURLFileExtension(url || ""))
    )
    ?.map((file) => (
      <Col key={file.fileId} xs={4}>
        {isLoading && (
          <Placeholder
            as={Card}
            className="placeholder-image-loader m-2"
            animation="glow"
          >
            <Placeholder className="w-100 h-100" />
          </Placeholder>
        )}

        {!isLoading && (
          <>
            <div lassName="d-flex flex-row align-items-center my-1">
              <img
                className="img-fluid attachment-image"
                src={compressedImages[file.fileId]}
                alt={file.description}
              />
              <small>
                <i>
                  <Button
                    type="button"
                    variant="link"
                    size="sm"
                    className="text-start p-0"
                    onClick={() => {
                      saveAs(
                        file?.url,
                        file?.name ||
                          `${file?.fileId}.${getURLFileExtension(
                            file?.url || ""
                          )}}`
                      );
                    }}
                  >
                    {file?.name && <span>{file.name}</span>}
                    {!file?.name && <span>{file.fileId}</span>}
                  </Button>
                </i>
              </small>
            </div>

            <p>
              <span className="fw-bold">Description:</span>
              {file.description?.length > 25
                ? file.description?.slice(0, 25) + "..."
                : file?.description}
            </p>
          </>
        )}
      </Col>
    ));

  const nonImageFiles = files
    ?.filter(
      ({ observationId, url }) =>
        observationId === null &&
        !["png", "jpg", "jpeg", "svg"].includes(getURLFileExtension(url || ""))
    )
    ?.map((file) => (
      <>
        <Col key={file?.fileId} xs={4}>
          <div lassName="d-flex flex-row align-items-center my-2">
            <span
              className={`fiv-sqo fiv-size-md lh-base fiv-icon-${getURLFileExtension(
                file?.url || ""
              )}`}
            ></span>{" "}
            <small>
              <i>
                {/* <a href={file.url} target="_blank" rel="noreferrer"> */}
                <Button
                  type="button"
                  size="sm"
                  variant="link"
                  className="text-start p-0"
                  onClick={() => {
                    saveAs(
                      file?.url,
                      file?.name ||
                        `${file?.fileId}.${getURLFileExtension(
                          file?.url || ""
                        )}}`
                    );
                  }}
                >
                  {file?.name ? (
                    <span>{file.name}</span>
                  ) : (
                    <span>{file.fileId}</span>
                  )}
                </Button>
                {/* </a> */}
              </i>
            </small>
            <p>
              <span className="fw-bold">Description:</span>
              {file.description?.length > 25
                ? file?.description?.slice(0, 25) + "..."
                : file?.description}
            </p>
          </div>
        </Col>
      </>
    ));

  const combinedAttachments = [...imageFiles, ...nonImageFiles];

  if (error) return <ErrorHandler />;

  return (
    <>
      {combinedAttachments.length !== 0 && (
        <>
          <div className="page-break-before"></div>

          <div className="no-page-break">
            <h2 className="mb-4 text-uppercase text-primary border-bottom border-primary">
              Assessment Level Attachments
            </h2>
            <Container fluid>
              <Row className="my-1 py-1">{combinedAttachments}</Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default AssessmentLevelFiles;
