import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";
import { exportCSV } from "utils/CSV";

const GeoTagExport = ({ geolocations, question, audit }) => {
  const handleExport = () => {
    const { questionId, prompt } = question;

    const section = audit?.protocol.sections.find((section) =>
      section.questions.some((question) => question.questionId === questionId)
    );

    const questionNo = (
      section.questions.findIndex(
        (question) => question.questionId === questionId
      ) + 1
    ).toString();

    const geolocationWithQuestionData = geolocations.map((item) => ({
      ...item,
      questionId,
      questionNo,
      prompt,
    }));

    const headers = Object.keys(geolocationWithQuestionData[0])
      .filter((item) => item !== "images")
      .map((key) =>
        key === "prompt" ? "Question" : key[0].toUpperCase() + key.slice(1)
      );

    const ishtmlElement = (str) => {
      return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(str);
    };

    const data = geolocationWithQuestionData.map((d) =>
      Object.values(d)
        .filter((item) => !Array.isArray(item))
        .map((val) => {
          return ishtmlElement(val) ? val.replace(/(<([^>]+)>)/gi, "") : val;
        })
    );

    exportCSV(data, headers, audit?.name + "-" + section?.title);
  };
  return (
    <Button
      size="sm"
      variant="light"
      className="text-start bg-primary bg-opacity-25 rounded-3 text-dark me-2"
      disabled={!geolocations.length}
      onClick={handleExport}
    >
      <span translate="no" className="material-symbols-outlined md-18 me-2">
        download
      </span>
      Export
    </Button>
  );
};

GeoTagExport.propTypes = {
  question: PropTypes.object.isRequired,
  audit: PropTypes.object.isRequired,
  geolocations: PropTypes.array.isRequired,
};

export default GeoTagExport;
