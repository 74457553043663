import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import * as yup from "yup";
import moment from "moment";
import { Field, FieldArray, Formik } from "formik";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { Button, Form, Modal, Spinner, Row, Col } from "react-bootstrap";

import { get, post } from "utils/DeApi";
import { UserContext } from "contexts/UserProvider";
import SlateEditor from "components/ui/SlateEditor";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { OrganizationContext } from "contexts/OrganizationProvider";

import ErrorHandler from "components/ui/ErrorHandler";
import ActionItemQuestionResponse from "features/assessment/components/Audit/CorrectiveActions/ActionItemQuestionResponse";

export default function CorrectiveActionItemBulkCreate({
  auditId,
  facilityId,
  items,
  selectedItems,
  positionClasses,
  modalBtnVariant,
}) {
  if (selectedItems.length > 0) {
    return (
      <Row
        className={`position-absolute ${positionClasses} bg-gradient-gray rounded-2 px-1 py-2 bg-opacity`}
      >
        <Col>
          <div className="d-flex justify-content-center align-items-center">
            <span className="text-dark fw-bold me-2">
              {selectedItems.length} selected
            </span>
            <CorrectiveActionItemBulkCreateModal
              auditId={auditId}
              facilityId={facilityId}
              items={items}
              selectedItems={selectedItems}
              modalBtnVariant={modalBtnVariant}
            />
          </div>
        </Col>
      </Row>
    );
  }
  return null;
}

const CorrectiveActionItemBulkCreateModal = ({
  members,
  auditId,
  taskableId,
  facilityId,
  taskableType = "question",
  showCanvas = false,
  onActionItemCreated,
  onCanvasHidden,
  disabled,
  items,
  selectedItems,
  modalBtnVariant,
}) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(showCanvas);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const user = useContext(UserContext);
  const [subscribers, setSubscribers] = useState([]);
  const [tags, setTags] = useState([]);
  const [dueDate, setDueDate] = useState(new Date());
  const location = useLocation();
  const [node, setNode] = useState(false);

  const addYears = (date, years) => {
    date.setFullYear(date.getFullYear() + years);
    return date;
  };

  const handleClose = () => {
    setShow(false);
    if (typeof onCanvasHidden === "function") onCanvasHidden();
  };
  const handleShow = () => {
    setShow(true);
  };

  const autoFocusRef = useCallback(
    (node) => node !== null && setNode(node),
    []
  );

  const createActionItems = (values) => {
    const { items, description, assignedTo, tags, dueDate, type } = values;
    setError(null);
    setIsLoading(true);
    const createActionItemPromises = [];

    items.forEach((item) => {
      const { itemTitle } = item;
      const createActionItemPromise = post("action-items", {
        description,
        assignedTo: assignedTo?.length
          ? assignedTo.map(({ subscriberId }) => subscriberId)
          : [],
        tags: tags?.length
          ? tags.map(({ name = "" }) => name.toLowerCase())
          : [],
        dueDate: moment(dueDate).format("YYYY-MM-DD HH:mm:ss"),
        action_item_type: parseInt(type),
        item: itemTitle,
        status: 0,
        assignedBy: user?.de?.subscriberId,
        taskableType: taskableType ?? null,
        taskableId: facilityId || (taskableId ?? null),
        organizationId: organization?.id,
        referer: window.location.origin,
        path: location?.pathname,
        ...(auditId
          ? {
              audit_id: auditId,
            }
          : {}),
      });

      createActionItemPromises.push(createActionItemPromise.promise);
      subscribedPromises.current.push(createActionItemPromise);
    });

    Promise.all(createActionItemPromises)
      .then((responses) => {
        setIsLoading(false);
        responses.forEach((response) => {
          onActionItemCreated(response.data);
        });
        handleClose();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
  };

  const schema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        itemTitle: yup
          .string()
          .min(2, "Text is too Short!")
          .max(255, "Text is too Long!")
          .required("This field is required"),
        question: yup.object().required(),
      })
    ),
    description: yup.string().nullable(),
    assignedTo: yup.array().min(1).required(),
    dueDate: yup.date().required("Due date is a required field"),
  });

  const removeHtmlTags = (str) => {
    if (!str) return "";
    return str.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 245);
  };

  useEffect(() => {
    setShow(showCanvas);
  }, [showCanvas]);

  useEffect(() => {
    const fetchSubscribers = () => {
      setIsExpanding(true);
      const subscribersUrl = () => {
        if (taskableType === "facility")
          return `facilities/${taskableId}/subscribers`;
        if (taskableType === "question")
          return `facilities/${facilityId}/subscribers`;
        return "subscribers";
      };
      const subscribersPromise = get(subscribersUrl(), {
        params: {
          organizationId: organization?.id,
        },
      });
      subscribersPromise.promise
        .then((response) => {
          setIsExpanding(false);
          if (!members) return setSubscribers(response.data ?? []);
          const membersId = members?.map((m) => m.subscriberId);
          setSubscribers(
            response.data.filter((m) => !membersId.includes(m.subscriberId))
          );
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsExpanding(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    const fetchTags = () => {
      setIsSyncing(true);
      const subscribersPromise = get(`/organizations/${organization?.id}/tags`);
      subscribersPromise.promise
        .then((response) => {
          setTags(response.data ?? []);
          setIsSyncing(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsSyncing(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    if (show && node) {
      node.focus();
      fetchTags();
      fetchSubscribers();
    }
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [
    organization,
    user,
    show,
    node,
    taskableType,
    taskableId,
    facilityId,
    members,
  ]);

  if (organization?.invitation?.role === "Certifier") return <Fragment />;
  return (
    <>
      <Button
        size="sm"
        disabled={disabled}
        onClick={handleShow}
        variant={modalBtnVariant}
      >
        Bulk Create Action Items
      </Button>

      <Modal show={show} size="xl">
        <Modal.Header
          onHide={handleClose}
          className="border-bottom"
          closeButton
        >
          <Modal.Title>
            Bulk Create Action Items for {selectedItems.length} Selected
            Corrective Actions
          </Modal.Title>
        </Modal.Header>
        <div className="overflow-auto">
          <Formik
            validationSchema={schema}
            onSubmit={(values) => {
              createActionItems(values);
            }}
            initialValues={{
              items: items
                .filter((item) => selectedItems.includes(item.questionId))
                .map((item) => ({
                  itemTitle: removeHtmlTags(item.prompt),
                  question: item,
                })),
              assignedTo: members || [],
              description: "",
              dueDate: moment(dueDate).format("YYYY-MM-DD"),
              tags: [],
              type: 1,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <FieldArray
                    name="items"
                    render={(arrayHelpers) => (
                      <div>
                        <Row className="mb-2">
                          <Col className="fw-bold">Action Item Title</Col>
                          <Col className="fw-bold" lg={4}>
                            Question
                          </Col>
                          <Col className="fw-bold" lg={2}>
                            State
                          </Col>
                        </Row>
                        {values.items.map((item, index) => {
                          const question = item.question;
                          return (
                            <Row key={index}>
                              <Col>
                                <Field name={`items.${index}.itemTitle`}>
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                  }) => (
                                    <Form.Group
                                      controlId="items"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        as="textarea"
                                        ref={autoFocusRef}
                                        name={`items.${index}`}
                                        isValid={field.value && !field.error}
                                        isInvalid={
                                          !(field.value && !field.error) &&
                                          field.touched
                                        }
                                        placeholder="Enter action item title"
                                        {...field}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {field.error && field.touched ? (
                                          <small>{field.error}</small>
                                        ) : null}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                </Field>
                              </Col>
                              <Col lg={4}>
                                <ActionItemQuestionResponse
                                  question={question}
                                />
                              </Col>
                              <Col lg={2}>Editing</Col>
                            </Row>
                          );
                        })}
                      </div>
                    )}
                  />

                  <Form.Group controlId="description" className="mb-3">
                    <Form.Label className="mb-1">Description</Form.Label>
                    <div className="bg-light border rounded">
                      <SlateEditor
                        oldValue={""}
                        name="description"
                        setFieldValue={setFieldValue}
                        placeholder={"Enter Action Item Description"}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.description && touched.description ? (
                        <small>{errors.description}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="dueDate" className="mb-3">
                    <Form.Label className="mb-1">
                      Due Date <RequiredAsterisk />
                    </Form.Label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      type="date"
                      name="dueDate"
                      popperPlacement="top-start"
                      selected={dueDate}
                      onChange={(date) => {
                        setDueDate(date);
                        setFieldValue(
                          "dueDate",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }}
                      onBlur={handleBlur}
                      isValid={values.dueDate && !errors.dueDate}
                      isInvalid={
                        !(values.dueDate && !errors.dueDate) && touched.dueDate
                      }
                      maxDate={addYears(new Date(), 2)}
                      minDate={new Date()}
                      isClearable
                      placeholderText="Enter Due Date"
                      className="form-control"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dueDate && touched.dueDate ? (
                        <small>{errors.dueDate}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="assigned-to" className="mb-3">
                    <Form.Label className="mb-1">
                      Assigned To <RequiredAsterisk />
                    </Form.Label>
                    <Typeahead
                      id="subscribers-typeahead"
                      clearButton
                      placeholder="Select a user..."
                      isLoading={isExpanding}
                      labelKey={(option) =>
                        `${option.firstName} ${option.lastName}`
                      }
                      isValid={
                        !!values.assignedTo.length &&
                        !errors.assignedTo &&
                        touched.assignedTo
                      }
                      isInvalid={
                        !(values.assignedTo && !errors.assignedTo) &&
                        touched.assignedTo
                      }
                      onChange={(assignedTo) => {
                        setFieldValue("assignedTo", assignedTo);
                        if (!assignedTo.length)
                          setSubscribers((prev) =>
                            uniqBy([...prev, ...members], "subscriberId")
                          );
                      }}
                      options={subscribers}
                      selected={values.assignedTo}
                      multiple
                      renderToken={(option, props) => {
                        return (
                          <Token
                            onRemove={() => {
                              setFieldValue(
                                "assignedTo",
                                values.assignedTo.filter(
                                  (a) => a.subscriberId !== option.subscriberId
                                )
                              );
                              setSubscribers((prev) =>
                                uniqBy([...prev, option], "subscriberId")
                              );
                            }}
                          >
                            {option?.firstName} {option?.lastName}
                          </Token>
                        );
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="tags" className="mb-3">
                    <Form.Label className="mb-1">Tags</Form.Label>
                    <Typeahead
                      id="tags-typeahead"
                      clearButton
                      placeholder="Select tags ..."
                      newSelectionPrefix="Add: "
                      isLoading={isSyncing}
                      labelKey={`name`}
                      isValid={
                        !!values.tags.length && !errors.tags && touched.tags
                      }
                      isInvalid={!(values.tags && !errors.tags) && touched.tags}
                      onChange={(tags) => {
                        setFieldValue(
                          "tags",
                          uniqBy(
                            tags.map((tag) => ({
                              ...tag,
                              name: tag?.name.toLowerCase(),
                            })),
                            "name"
                          )
                        );
                      }}
                      options={tags}
                      selected={values.tags}
                      multiple
                    />
                  </Form.Group>
                  {show && error && <ErrorHandler error={error} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    className="me-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={isLoading || !isValid}
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Create Action Item{selectedItems?.length > 1 ? "s" : ""}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

CorrectiveActionItemBulkCreateModal.propTypes = {
  members: PropTypes.array,
  auditId: PropTypes.string,
  taskableId: PropTypes.string,
  taskableType: PropTypes.string,
  onActionItemCreated: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  showCanvas: PropTypes.bool,
  onCanvasHidden: PropTypes.func,
  disabled: PropTypes.bool,
  facilityId: PropTypes.string,
};
