import React, { Fragment, useContext, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

const FileDelete = ({ file, onFileDeleted }) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    description: yup.string().required(),
  });

  const deleteFile = (values) => {
    setError("");
    setIsLoading(true);

    const filePromise = destroy(`files/${file.fileId}`);

    filePromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        onFileDeleted(file);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(filePromise);
  };

  if (organization?.invitation?.role === "Certifier") return <Fragment />;

  return (
    <>
      <Button
        onClick={handleShow}
        variant="outline-danger"
        size="sm"
        className="ms-2 border-danger border-opacity-25"
      >
        <span className="material-symbols-outlined md-18 ">delete</span>
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Delete File <i>{file.description}</i>
          </Modal.Title>
        </Modal.Header>

        {file && (
          <Formik
            validationSchema={schema}
            onSubmit={(values) => {
              deleteFile(values);
            }}
            initialValues={{
              description: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isValid,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <p>Are you sure you want to delete this file?</p>

                  <Form.Group controlId="productName" className="mt-3 mb-3">
                    <Form.Label>
                      Please type the description of the file to confirm.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        values.description === file.description &&
                        !errors.description
                      }
                    />
                  </Form.Group>
                  {error && <ErrorHandler error={error} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button size="sm" variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    variant="danger"
                    disabled={
                      values.description.trim() !== file.description ||
                      !isValid ||
                      isLoading
                    }
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Delete
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

FileDelete.propTypes = {
  file: PropTypes.object.isRequired,
  onFileDeleted: PropTypes.func.isRequired,
};

export default FileDelete;
