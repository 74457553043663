import DOMPurify from "dompurify";
import { choiceColorMap } from "features/assessment/components/Audit/AuditDetails/Question/FlexibleQuestions/FieldCompound/helper";
import { parseFlexQuestion } from "features/assessment/components/Audit/AuditDetails/Question/helper";
import moment from "moment";
import React, { Fragment, useContext } from "react";
import NestedQuestion from "../../../NestedQuestion/NestedQuestion";
import ReportHeatmap from "../../../ReportHeatmap";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

function RecursiveNestedQuestions({
  responses = [],
  mergedQuestions = [],
  _showRisk,
  _showNotes,
  _showCompliance,
  _showCasualFactor,
  _showRecommendation,
  _showCorrectiveAction,
  _showConformityLevel,
  questionOptions,
  conformity,
  riskLevels,
  showAttachments,
  flexibleQuestions,
  showEmptyFlexibleQuestions,
  showRiskMatrices,
  index,
  setAnsweredArray,
  heatmapChoicesList = [],
  indent = false,
  sectionId = "",
}) {
  const { audit } = useContext(AuditContext);
  const { sectionFormFields = [] } = audit;

  const capitalizeFirstLetter = (word = "") => {
    if (word === null || word === undefined || !word.length) return "";
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  return (
    <>
      {mergedQuestions
        .sort((a, b) => a.order - b.order)
        .map((response, index) => {
          let {
            id,
            prompt,
            properties,
            nestedFields: nestedQuestions = [],
            children,
          } = response || {};
          let choices = [];
          let notes = "";
          let choice = {};
          let responseDatetime = "";
          const matchResponse = responses.filter(
            (elem) => elem.flexibleQuestionId === id
          );

          if (properties?.fieldType === "checkbox") {
            choices = matchResponse.map((elem) => elem?.choice);
          } else {
            notes = matchResponse[0]?.notes ?? "";
            responseDatetime = matchResponse[0]?.responseDatetime ?? "";
            choice = matchResponse[0]?.choice ?? {};
          }
          nestedQuestions = nestedQuestions?.filter(
            ({ triggerAnswerId = "" }) => triggerAnswerId === choice?.id
          );

          const showNestedQuestions = !!nestedQuestions.length;

          const doesNestedQuestionHaveResponses = !!responses
            ?.filter((elem, index, self) =>
              nestedQuestions?.some(
                ({ id: _id = "" }) => _id === elem.flexibleQuestionId
              )
            )
            .filter(
              ({ notes = "", responseDatetime = "", choice = "" }) =>
                !!notes || !!responseDatetime || !!choice
            ).length;

          const isQuestionToggledForThisSection =
            sectionFormFields
              ?.find(({ flexibleQuestionId: _id }) => _id === id)
              ?.sectionsAvailability?.find(
                ({ sectionId: _sectionId }) => _sectionId === sectionId
              )?.available ?? true;

          if (!isQuestionToggledForThisSection) {
            return <></>;
          }

          if (
            properties?.fieldType === "text" &&
            (notes || showEmptyFlexibleQuestions)
          ) {
            return (
              <p className="my-1">
                <strong className="me-2">{prompt}:</strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      capitalizeFirstLetter(notes)?.replace(/\n/g, "<br/>")
                    ),
                  }}
                ></span>
              </p>
            );
          }
          if (
            properties?.fieldType === "datetime" &&
            (responseDatetime || showEmptyFlexibleQuestions)
          ) {
            const datetime = responseDatetime
              ? moment.utc(responseDatetime).format("MMMM D, yyyy h:mm a")
              : "";
            return (
              <>
                <p className="my-1">
                  <strong className="me-2">{prompt}:</strong>
                  {datetime}
                </p>
              </>
            );
          }
          if (
            properties?.fieldType === "numeric" &&
            (notes || showEmptyFlexibleQuestions)
          ) {
            return (
              <p className="my-1" key={id}>
                <strong className="me-2">{prompt}:</strong>
                {notes}
              </p>
            );
          }
          if (
            properties?.fieldType === "radio" &&
            (choice?.label || showEmptyFlexibleQuestions)
          ) {
            return (
              <div className={`my-1`} key={id}>
                <strong className="me-2">{prompt}:</strong>
                <span className="d-inline flex-column position-relative">
                  {capitalizeFirstLetter(choice?.label)}
                </span>
                {doesNestedQuestionHaveResponses && showNestedQuestions && (
                  <>
                    <p>
                      <NestedQuestion
                        responses={responses}
                        nestedQuestion={nestedQuestions}
                        view="list"
                      />
                    </p>
                  </>
                )}
                {nestedQuestions.length > 0 && (
                  <div
                    className="ms-3 text-break nested-questions"
                    style={{ width: "98%" }}
                  >
                    <RecursiveNestedQuestions
                      key={id}
                      responses={responses}
                      mergedQuestions={nestedQuestions}
                      questionOptions={questionOptions}
                      _showRisk={_showRisk}
                      _showNotes={_showNotes}
                      _showCompliance={_showCompliance}
                      _showCasualFactor={_showCasualFactor}
                      _showRecommendation={_showRecommendation}
                      _showCorrectiveAction={_showCorrectiveAction}
                      _showConformityLevel={_showConformityLevel}
                      index={index}
                      setAnsweredArray={setAnsweredArray}
                      heatmapChoicesList={heatmapChoicesList}
                      showRiskMatrices={showRiskMatrices}
                      indent={true}
                    />
                  </div>
                )}
              </div>
            );
          }

          if (
            properties?.fieldType === "checkbox" &&
            (!!choices?.length || showEmptyFlexibleQuestions)
          ) {
            return (
              <div className="my-1" key={id}>
                <strong className="me-2">{prompt}:</strong>
                <>
                  {!!choices?.length &&
                    choices
                      ?.sort((a, b) => a?.order - b?.order)
                      ?.map(
                        ({ label, score }, index, arr) =>
                          `${capitalizeFirstLetter(label)} ${
                            arr.length > index + 1 ? "," : ""
                          } `
                      )}
                </>
              </div>
            );
          }
          if (
            showRiskMatrices &&
            properties?.fieldType === "compound" &&
            children?.length > 0 &&
            heatmapChoicesList[index]?.length === 2
          )
            return (
              <React.Fragment key={id}>
                <p>
                  <strong>
                    {prompt + " " + heatmapChoicesList[index][0].prompt}:{" "}
                  </strong>
                  {`${heatmapChoicesList[index][0].score} - ${heatmapChoicesList[index][0].label}`}
                </p>
                <p>
                  <strong>
                    {prompt + " " + heatmapChoicesList[index][1]?.prompt}:{" "}
                  </strong>
                  {`${heatmapChoicesList[index][1].score} - ${heatmapChoicesList[index][1].label}`}
                </p>
                {showRiskMatrices && (
                  <div className="print-component">
                    <strong>{prompt}:</strong>
                    <ReportHeatmap
                      children={choiceColorMap(response.children)}
                      values={parseFlexQuestion(responses)}
                    />
                  </div>
                )}
              </React.Fragment>
            );

          return <Fragment key={id} />;
        })}
    </>
  );
}

export default RecursiveNestedQuestions;
