const { Alert, Button } = require("react-bootstrap");

function ErrorBoundary() {
  return (
    <Alert variant="info" className={`my-3 d-flex flex-row`}>
      <div className="me-3">
        <span translate="no" className="material-symbols-outlined md-18">
          Error
        </span>
      </div>
      <div className="flex-fill">
        <h5 className="mb-1">
          <small>Error</small>
        </h5>
        <p>
          <small>An unexpected issue has occurred in ERM Assess.</small>
        </p>
        <p>
          <small>
            Please refresh the page and try again. If this doesn't work contact
            the admin.
          </small>
        </p>
        <Button
          variant="outline-dark"
          size="sm"
          onClick={() => window.location.reload()}
        >
          <span translate="no" className="material-symbols-outlined md-18 me-2">
            refresh
          </span>
          Refresh
        </Button>
      </div>
    </Alert>
  );
}

export default ErrorBoundary;
