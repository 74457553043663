import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import PropTypes from "prop-types";

const CHART_HEIGHT = 270;

function BestPracticesPieChart({ data, labels }) {
  const el = useRef(null);
  useEffect(() => {
    const chartData = Object.keys(data).map((key) => {
      if (key === "timeline") return null;
      return {
        name: labels[key] ? labels[key] : key,
        value: data[key]?.reduce((prev, current) => prev + current, 0),
      };
    });

    chartData.shift();

    const sum = chartData?.reduce((prev, current) => prev + current?.value, 0);
    const dimension = {
      width: el.current.offsetWidth,
      height: CHART_HEIGHT,
    };

    const option = {
      tooltip: {
        trigger: "item",
      },
      title: {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 15,
        },
        text: `Total: ${sum}`,
        left: "center",
        top: "bottom",
      },

      legend: {
        orient: "horizontal",
        show: true,
        itemStyle: {
          borderCap: "butt",
          borderJoin: "round",
        },
        icon: "circle",
        textStyle: {
          fontSize: 14,
        },
      },
      series: [
        {
          type: "pie",
          radius: ["30%", "50%"],
          label: {
            show: true,
            formatter: "{b}: {c}\n({d}%)",
          },
          percentPrecision: 1,
          data: chartData,

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    const chart = echarts.init(el.current, null, dimension);

    chart.setOption(option);

    function handleResize() {
      chart.resize(dimension);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
}

BestPracticesPieChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BestPracticesPieChart;
