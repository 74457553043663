import { useRoles } from "hooks";
import PropTypes from "prop-types";

import MemberDataExport from "./MemberDataExport";
import AdminOrOwnerDataExport from "./AdminOrOwnerDataExport";

const DataExport = ({ variant, level = "" }) => {
  const { isCertifier, isMember } = useRoles();

  if (isCertifier) return <></>;

  if (isMember) return <MemberDataExport variant={variant} level={level} />;

  return <AdminOrOwnerDataExport variant={variant} level={level} />;
};

DataExport.propTypes = {
  variant: PropTypes.string,
  level: PropTypes.string,
  auditList: PropTypes.array,
};

export default DataExport;
