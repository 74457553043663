import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";

const ManuscriptEditHistoryColumns = ({
  handleSort,
  sortField,
  sortDirection,
}) => {
  return (
    <thead className="bg-light">
      <tr>
        <th onClick={() => handleSort("timestamp")} className="cursor-pointer">
          Time{" "}
          {sortField === "timestamp" && (
            <span>{sortDirection === "desc" ? "↓" : "↑"}</span>
          )}
        </th>
        <th onClick={() => handleSort("userId")} className="cursor-pointer">
          User{" "}
          {sortField === "userId" && (
            <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
          )}
        </th>
        <th onClick={() => handleSort("field")} className="cursor-pointer">
          Question{" "}
          {sortField === "field" && (
            <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
          )}
        </th>
        <th>From</th>
        <th>To</th>
      </tr>
    </thead>
  );
};

export default ManuscriptEditHistoryColumns;
