import React, { useContext, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import {
  OrganizationContext,
  OrganizationsContext,
  OrganizationDispatchContext,
} from "contexts/OrganizationProvider";

import "./SwitchOrganization.scss";

const SwitchOrganization = () => {
  const activeOrganization = useContext(OrganizationContext);
  const organizations = useContext(OrganizationsContext);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const organizationId = urlParams.get("organizationId");
  const setOrganization = useContext(OrganizationDispatchContext);
  const navigate = useNavigate();

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    navigate(location?.pathname || "/");
  };

  const organization =
    organizations.find(({ id }) => id === organizationId) || {};

  const switchOrganization = () => {
    setOrganization({
      ...organization,
      documents: [],
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Switch Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The <strong>Action Item</strong> does not belong to current
          organization{" "}
          <strong className="text-warning">{activeOrganization?.name}</strong>,
          switch to{" "}
          <strong className="text-primary">{organization?.name}</strong> to see
          the Action Item.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          className="px-3"
          onClick={handleClose}
          variant="outline-primary"
        >
          Cancel
        </Button>
        <Button className="px-3" onClick={() => switchOrganization()} size="sm">
          Switch
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SwitchOrganization;
