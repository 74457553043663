import React, { useContext, useEffect, useRef } from "react";

import { groupBy } from "lodash";
import * as echarts from "echarts";
import PropTypes from "prop-types";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { complianceMap } from "features/assessment/components/Audit/helper";

import "./ReportCompliance.scss";

const CHART_HEIGHT = 270;

function ReportCompliance({ tooltip, setChartImage, isExportable = false }) {
  const el = useRef(null);
  const { audit } = useContext(AuditContext);

  useEffect(() => {
    const observations = audit?.questions.flatMap(
      (question) => question.observations || []
    );

    const compliance = groupBy(observations, (observation) => {
      if (observation.isInapplicable) return "";
      if (observation.compliance === null) return "";
      return complianceMap(observation.compliance);
    });

    delete compliance[""];

    const data = Object.keys(compliance).map((key) => {
      return { name: key, value: compliance[key].length };
    });

    const sum = data.reduce((prev, current) => prev + current.value, 0);
    const dimension = {
      width: isExportable ? 800 : el.current.offsetWidth,
      height: isExportable ? 500 : CHART_HEIGHT,
    };

    const option = {
      tooltip: {
        trigger: "item",
        confine: true,
      },
      title: {
        show: !isExportable,
        textStyle: {
          color: "grey",
          fontSize: 15,
          fontWeight: "lighter",
        },
        text: `Total: ${sum}`,
        left: "center",
        top: "bottom",
      },
      ...(!isExportable
        ? {
            legend: {
              type: "scroll",
              orient: "horizontal",
              icon: "circle",
              textStyle: {
                fontSize: 14,
              },
            },
          }
        : {}),
      series: [
        {
          name: tooltip,

          type: "pie",
          radius: "50%",
          label: {
            show: true,
            ...(isExportable
              ? {
                  alignTo: "none",
                  fontSize: 14,
                  color: "#000",
                  lineHeight: 2,
                  padding: 20,
                }
              : {}),
            formatter: "{b}: {c} ({d}%)",
          },
          percentPrecision: 1,
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    if (!sum) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
      option.series = null;
    }

    const chart = echarts.init(el.current, null, dimension);

    if (setChartImage) {
      chart.on("finished", () => {
        var img = new Image(800, 600);
        img.src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        setChartImage(img);
        chart.dispose();
      });
    }

    chart.setOption(option);

    function handleResize() {
      chart.resize(dimension);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, audit, isExportable, tooltip, setChartImage]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
}

ReportCompliance.propTypes = {
  tooltip: PropTypes.string.isRequired,
};

export default ReportCompliance;
