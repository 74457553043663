import { useEffect, useRef, useState } from "react";
import { post } from "utils/DeApi";
import { useNavigate } from "react-router-dom";

const useRestoreAudit = () => {
  const subscribedPromises = useRef([]);
  const navigate = useNavigate();

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const restoreAudit = (auditId, activityLogId) => {
    const orgPromise = post(`/activity-logs/${activityLogId}/restore`);
    orgPromise.promise
      .then(() => {
        navigate(`/audits/${auditId}`);
      })
      .catch((error) => {
        if (error.data.error.message) {
          error.data.error.message = "We could not restore the assessment";
        }
        setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(orgPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, restoreAudit };
};

export default useRestoreAudit;
