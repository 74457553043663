import * as yup from "yup";
import { MAX_FILE_SIZE } from "../constants";
const auditBulkUploadValidationSchema = yup.object().shape({
  description: yup
    .string()
    .required("The Description is required")
    .min(2, "Description is too Short!")
    .max(255, "Description is too Long!"),
  files: yup
    .array()
    .min(1, "The file is required")
    .required("The file is required")
    .test("fileSize", "File Size is too large", (value) => {
      if (value && value?.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].size > MAX_FILE_SIZE) {
            return false;
          }
        }
      }
      return true;
    }),
});

export default auditBulkUploadValidationSchema;
