import React, { useRef, useState } from "react";

import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import { destroyWithBody } from "utils/DeApi";
import OverlayTriggerButton from "components/ui/OverlayTriggerButton";

const ActionItemCommentDelete = ({
  actionItemId,
  handleActionItemCommentDelete,
  comment,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const deleteActionItem = () => {
    setError(null);
    setIsLoading(true);
    const actionItemCommentDeletePromise = destroyWithBody(
      `/action-items/${actionItemId}/comments`,
      {
        commentId: comment?.id,
      }
    );

    actionItemCommentDeletePromise.promise
      .then((response) => {
        setIsLoading(false);
        handleActionItemCommentDelete(comment);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(actionItemCommentDeletePromise);
  };

  error && console.log(error);
  return (
    <OverlayTriggerButton
      toolTipMessage="Delete"
      variant="outline-danger"
      className="ms-2"
      onClickHandler={() => deleteActionItem()}
      label={
        isLoading ? (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="light"
          />
        ) : (
          <span translate="no" className="material-symbols-outlined md-18">
            delete
          </span>
        )
      }
    />
  );
};

ActionItemCommentDelete.propTypes = {
  comment: PropTypes.object.isRequired,
  actionItemId: PropTypes.string.isRequired,
  handleActionItemCommentDelete: PropTypes.func.isRequired,
};

export default ActionItemCommentDelete;
