import { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ReportAggregation from "components/common/ReportAggregation";
import { OrganizationContext } from "contexts/OrganizationProvider";
import {
  aggregationOfComlianceTrends,
  aggregationOfCompliance,
} from "helper/reporting";

function FacilityReportCompliance({ protocol, filterPeriod, facility }) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [complianceStats, setComplianceStats] = useState();

  useEffect(() => {
    function fetchStats() {
      setError(null);
      setIsLoading(true);

      const compliancePromise = get(
        `facilities/${facility?.facilityId}/protocols/${protocol?.protocolId}/stats`,
        {
          params: {
            option: 1,
            period: filterPeriod?.period,
            organizationId: organization?.id,
          },
        }
      );
      compliancePromise.promise
        .then(({ data }) => {
          setComplianceStats(data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(compliancePromise);
    }

    if (protocol?.protocolId) {
      fetchStats();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organization?.id,
    protocol?.protocolId,
    filterPeriod,
    facility?.facilityId,
  ]);

  if (error) return <ErrorHandler error={error} />;

  return (
    <Row>
      {isLoading ? (
        <Loader />
      ) : complianceStats && complianceStats?.length !== 0 ? (
        <>
          <Col xs={12} sm={12} md={12} lg={4} className="mb-4 border-end">
            <p className="text-center w-100 text-truncate text-capitalize">
              Distribution of{" "}
              {protocol?.questionOptions[1]?.name ?? "Conformity Level"}
            </p>
            <hr />
            <ReportAggregation
              size="square-container"
              labels={{
                compliant: "Yes",
                nonCompliant: "No",
              }}
              data={aggregationOfCompliance(complianceStats)}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} className="mb-3">
            <p className="text-center w-100 text-truncate text-capitalize">
              {protocol?.questionOptions[1]?.name ?? "Conformity Level"} trends
              across · <small className="text-muted">{facility?.name}</small>
            </p>
            <hr />
            <ReportTrend
              size="square-container"
              labels={{
                compliant: "Yes",
                nonCompliant: "No",
              }}
              data={aggregationOfComlianceTrends(complianceStats, filterPeriod)}
            />
          </Col>{" "}
        </>
      ) : (
        complianceStats?.length === 0 && (
          <Col>
            <EmptyStateHandler
              title="No data to visualize"
              description="You have no data to visualize."
            />
          </Col>
        )
      )}
    </Row>
  );
}

FacilityReportCompliance.propTypes = {
  protocol: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  filterPeriod: PropTypes.object.isRequired,
};

export default FacilityReportCompliance;
