import { Badge } from "react-bootstrap";
import ActionItemStatus from "../components/ActionItems/ActionItemStatus";
import moment from "moment";
import SortColumn from "components/ui/SortColumn";
import { truncate } from "lodash";

const data = [
  {
    key: "facility",
    title: () => <th>Entity</th>,
    name: "Entity",
    dataIndex: "facility",
    hidden: false,
    component: (facility) => facility || "-",
  },
  {
    key: "protocol",
    title: () => <th>Protocol</th>,
    name: "Protocol",
    dataIndex: "protocol",
    hidden: false,
    component: (protocol) => protocol || "-",
  },
  {
    key: "typeLabel",
    title: (isLoading, tableSortState, sortActionItems) => (
      <th
        className="cursor-pointer"
        onClick={() => !isLoading && sortActionItems("action_item_type")}
      >
        Type
        <SortColumn
          tableSortState={tableSortState}
          column={"action_item_type"}
        />
      </th>
    ),
    name: "Type",
    dataIndex: "typeLabel",
    hidden: false,
    component: (typeLabel) => typeLabel || "-",
  },
  {
    key: "assignedBy",
    title: () => <th className="text-nowrap">Assigned By</th>,
    dataIndex: "assignedBy",
    name: "Assigned By",
    hidden: false,
    component: (assignedBy) => (
      <small className="text-nowrap">
        {assignedBy?.firstName} {assignedBy?.lastName} <br />
      </small>
    ),
  },
  {
    key: "assignedTo",
    title: () => <th>Assigned To</th>,
    dataIndex: "assignedTo",
    name: "Assigned To",
    hidden: false,
    component: (assignedTo) => (
      <ol className="ps-3">
        <small className="text-nowrap">
          {Array.isArray(assignedTo) && assignedTo?.length > 0
            ? assignedTo?.map((user, index) => (
                <li key={user.subscriberId}>
                  {user.firstName} {user.lastName} <br />
                </li>
              ))
            : "-"}
        </small>
      </ol>
    ),
  },
  {
    key: "tags",
    title: () => <th className="text-nowrap">Tags</th>,
    dataIndex: "tags",
    name: "Tags",
    hidden: false,
    component: (tags) => {
      return Array.isArray(tags) && !!tags?.length
        ? tags?.map(({ labelId, name }) => (
            <Badge
              key={labelId}
              className="bg-opacity-10 bg-info text-info me-2 border"
              title={name}
            >
              {truncate(name, {
                length: 25,
              })}
            </Badge>
          ))
        : "-";
    },
  },
  {
    key: "statusLabel",
    title: (isLoading, tableSortState, sortActionItems) => (
      <th
        className="text-nowrap cursor-pointer"
        onClick={() => !isLoading && sortActionItems("done")}
      >
        Status
        <SortColumn tableSortState={tableSortState} column={"done"} />
      </th>
    ),
    name: "Status",
    dataIndex: "statusLabel",
    hidden: false,
    component: (statusLabel) => <ActionItemStatus status={statusLabel} />,
  },
  {
    key: "dueDate",
    title: (isLoading, tableSortState, sortActionItems) => (
      <th
        className="cursor-pointer"
        onClick={() => !isLoading && sortActionItems("due_date")}
        role="button"
      >
        Due On
        <SortColumn tableSortState={tableSortState} column={"due_date"} />
      </th>
    ),
    name: "Due On",
    dataIndex: "dueDate",
    hidden: false,
    component: (dueDate, statusLabel) => (
      <small className="text-nowrap">
        {dueDate ? (
          <>
            {new Date(dueDate).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}{" "}
            {moment(dueDate).isBefore(
              moment(new Date()).utc().format("YYYY-MM-DD")
            ) &&
              statusLabel !== "Accepted" && (
                <>
                  <Badge className="bg-danger bg-opacity-10 text-danger">
                    Overdue
                  </Badge>
                </>
              )}
          </>
        ) : (
          "N/A"
        )}
      </small>
    ),
  },
  {
    key: "updatedAt",
    title: (isLoading, tableSortState, sortActionItems) => (
      <th
        onClick={() => {
          !isLoading && sortActionItems("updated_at");
        }}
        role="button"
        className="cursor-pointer text-nowrap"
      >
        Updated
        <SortColumn tableSortState={tableSortState} column={"updated_at"} />
      </th>
    ),
    name: "Updated",
    dataIndex: "updatedAt",
    hidden: false,
    component: (updatedAt) => (
      <small className="text-nowrap">
        {new Date(updatedAt).toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
        })}
      </small>
    ),
  },
];

export default data;
