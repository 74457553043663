import React from "react";

import { Alert, Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import { aggregationOfBestPracticesAndFindings } from "helper/reporting";

import BestPracticesPieChart from "./BestPracticesPieChart/BestPracticesPieChart";

const OrganizationBestPractices = ({ protocol, data, isLoading, error }) => {
  const graphLabels = {
    finding: "Findings",
    bestPractise: "Best Practices",
  };

  const allHaveZeroValues =
    data?.every(
      (item) => item.stats.bestPractise === 0 && item.stats.finding === 0
    ) || data?.length === 0;

  if (error) return <ErrorHandler error={error} />;
  return (
    <Row>
      {isLoading ? (
        <Loader />
      ) : data && !allHaveZeroValues ? (
        <>
          <Col xs={12} sm={12} md={12} lg={5} className="border-end">
            <p className="text-center w-100 text-truncate text-capitalize">
              Distribution of Findings and Best Practices
            </p>
            <hr />
            <BestPracticesPieChart
              size="square-container"
              labels={graphLabels}
              data={aggregationOfBestPracticesAndFindings(data)}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} className="mb-2">
            <p className="text-center w-100 text-truncate text-capitalize">
              Findings and Best Practices Across Principles
              <small className="text-muted">&nbsp;&nbsp;{protocol?.name}</small>
            </p>
            <hr />
            <ReportTrend
              size="square-container"
              labels={graphLabels}
              data={aggregationOfBestPracticesAndFindings(data)}
              chartHeight={280}
            />
          </Col>
        </>
      ) : (
        allHaveZeroValues && (
          <Col>
            <Alert variant="info" className={`my-3 d-flex flex-row`}>
              <div className="me-3">
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 text-primary text-opacity-75"
                >
                  info
                </span>
              </div>
              <div>
                <h5 className="mb-1">
                  <small>No data to visualize</small>
                </h5>
                <p className="mb-1">
                  <small>You have no data to visualize.</small>
                </p>
              </div>
            </Alert>
          </Col>
        )
      )}
    </Row>
  );
};

export default OrganizationBestPractices;
