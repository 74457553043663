import { post, put } from "utils/DeApi";
import { createUniqArray } from "utils/ArrayUtils";
import { formatDatetimeToMSQL } from "utils/DateUtils";
import { FlexibleFields } from "features/assessment/constants";

import { parseFlexQuestion } from "./helper";

const {
  CHECKBOX_FIELD,
  COMPOUND_RADIO,
  DATETIME_FIELD,
  NUMERIC_FIELD,
  RADIO_FIELD,
  TEXT_FIELD,
} = FlexibleFields;

const observationPromise = (formData = {}) => {
  const subscribedPromises = [];

  const observationPromise = formData.observationId
    ? put(`observations/${formData.observationId}`, formData)
    : post(`observations`, formData);

  subscribedPromises.push(observationPromise);
  return {
    promise: observationPromise.promise,
    subscribed: subscribedPromises,
  };
};

const responsePromises = (q = {}, options) => {
  const subscribedPromises = [];

  const responsePromise = Object.keys(q).flatMap((key) => {
    const { type, response = "" } = q[key] || {};

    const payload = {
      notes: type === TEXT_FIELD || type === NUMERIC_FIELD ? response : "",
      answer_ids: type === CHECKBOX_FIELD ? createUniqArray(response) : [],
      answer_id:
        type === COMPOUND_RADIO || type === RADIO_FIELD ? response : "",
      response_datetime:
        type === DATETIME_FIELD ? formatDatetimeToMSQL(response) : "",
      question_id: options?.questionId, //required
      flexible_question_id: key, //required
      is_inapplicable: options?.isInapplicable,
      observationId: options?.observationId,
      parent_id: null,
    };

    const observationPromise = post(
      `/audits/${options?.auditId}/observations/${options?.observationId}/responses`,
      payload
    );

    subscribedPromises.push(observationPromise);
    return observationPromise.promise;
  });

  return {
    promises: responsePromise,
    subscribed: subscribedPromises,
  };
};

const observationsChildrenMapping = (observations) => {
  if (!Array.isArray(observations)) return [];
  return observations
    .map((observation, index, self) => {
      //exclude children observations
      if (observation?.parentId) return null;
      return {
        ...observation,
        children: self
          .filter(({ parentId }) => parentId === observation.observationId)
          .sort(({ createdAt: a }, { createdAt: b }) => {
            if (a < b) return -1;
            if (a === b) return 0;
            return 1;
          }),
      };
    })
    .filter(Boolean);
};

const observationResponsesMapping = (observations, responses) => {
  if (!Array.isArray(observations) || !Array.isArray(responses)) return [];
  return observations.map((observation) => {
    return {
      ...observation,
      responses: responses.filter(
        ({ observationId }) => observation.observationId === observationId
      ),
    };
  });
};

const prepIntialObservationValues = (observation, questionId) => {
  const responses = Array.isArray(observation?.responses)
    ? observation?.responses.filter(({ questionId: id }) => id === questionId)
    : [];

  const flexibleResponses = parseFlexQuestion(responses);

  return {
    observationId: observation?.observationId || null,
    isInapplicable: observation?.isInapplicable || false,
    compliance: Number.isInteger(observation?.compliance)
      ? parseInt(observation?.compliance)
      : "",
    risk: Number.isInteger(observation?.risk)
      ? parseInt(observation?.risk)
      : "",
    conformityLevel: Number.isInteger(observation?.conformityLevel)
      ? parseInt(observation?.conformityLevel)
      : "",
    conformityInapplicableReasons:
      observation?.conformityInapplicableReasons || "",
    recommendation: observation?.recommendation || "",
    correctiveAction: observation?.correctiveAction || "",
    notes: observation?.notes || "",
    causalFactors: observation?.causalFactors || "",
    extras: observation?.extras || {
      auditedAt: "",
      location: "",
      coach: {
        fullName: "",
      },
      attendees: [],
      feedback: "",
    },
    attendee: {
      id: "",
      fullName: "",
      employeeNo: "",
    },
    dynamic: flexibleResponses || {},
  };
};

const prepSubmitObservationValues = (values, options = {}) => {
  const { auditId, questionId, parentId, observationId } = options;
  return {
    auditId: auditId,
    questionId: questionId,
    parentId: parentId || null,
    observationId:
      observationId === "default" || !observationId ? null : observationId,
    risk: !isNaN(parseInt(values.risk)) ? values.risk : "",
    conformityLevel: !isNaN(parseInt(values.conformityLevel))
      ? values.conformityLevel
      : "",
    compliance: !isNaN(parseInt(values.compliance)) ? values.compliance : "",
    isInapplicable: Boolean(values.isInapplicable),
    recommendation: values.recommendation || "",
    conformityInapplicableReasons: values.conformityInapplicableReasons || "",
    correctiveAction: values.correctiveAction || "",
    notes: values.notes || "",
    causalFactors: values.causalFactors || "",
    extras: values.extras || {
      auditedAt: "",
      location: "",
      coach: {
        fullName: "",
      },
      attendees: [],
      feedback: "",
    },

    dynamic: values.dynamic || {},
  };
};

export {
  observationPromise,
  responsePromises,
  prepIntialObservationValues,
  prepSubmitObservationValues,
  observationsChildrenMapping,
  observationResponsesMapping,
};
