import React, { useState } from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner, Dropdown } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { useDeleteAudit } from "features/assessment/services";

import "./auditDelete.scss";

const AuditDelete = ({ audit, onAuditDeleted, children }) => {
  const { actionItemsCount = 0, filesCount = 0 } = audit;

  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { isCertifier, isContributor } = useRoles();

  const handleClose = () => {
    setShow(false);
    setIsChecked(false);
  };
  const handleShow = () => setShow(true);

  const { isLoading, error, deleteAudit } = useDeleteAudit({
    audit,
    onAuditDeleted: (audit) => {
      handleClose();
      onAuditDeleted(audit);
    },
  });

  const hasRelationships = actionItemsCount > 0 || filesCount > 0;

  if (isCertifier || isContributor) return <></>;

  return (
    <>
      {React.cloneElement(
        children || (
          <Dropdown.Item className="text-danger" onClick={handleShow}>
            <span
              translate="no"
              className="material-symbols-outlined md-18 me-2"
            >
              delete
            </span>
            Delete
          </Dropdown.Item>
        ),
        { onClickHandler: handleShow }
      )}
      <Modal show={show} onHide={handleClose} size="md">
        {audit && (
          <>
            <Modal.Body className="mt-2">
              <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
                <span className="material-symbols-outlined md-24">warning</span>
              </div>
              <h2 className="text-center pt-2">
                Delete Assessment -{" "}
                <small translate="no" className="text-muted">
                  {audit.name}
                </small>
              </h2>
              {hasRelationships ? (
                <>
                  <p className="my-3 text-center">
                    The following data in this Assessment must be deleted before
                    proceeding:
                  </p>
                  <ol>
                    {actionItemsCount > 0 ? (
                      <li>
                        <small>
                          Action Items: <i>{actionItemsCount}</i>
                        </small>
                      </li>
                    ) : null}
                    {filesCount ? (
                      <li>
                        <small>
                          Files & Photos: <i>{filesCount}</i>
                        </small>
                      </li>
                    ) : null}
                  </ol>
                </>
              ) : (
                <p className="mx-4 mt-2">
                  <Form.Group>
                    <Form.Check
                      label={
                        <small>
                          By checking the box, I confirm that I want to delete
                          this Assessment. I understand that this cannot be
                          undone.
                        </small>
                      }
                      onClick={() => {
                        setIsChecked(!isChecked);
                      }}
                    />
                  </Form.Group>
                </p>
              )}
              {error && <ErrorHandler error={error} />}
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={handleClose}
                className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                variant="danger"
                onClick={deleteAudit}
                disabled={!isChecked || hasRelationships}
              >
                {isLoading && (
                  <Spinner
                    className="me-2"
                    animation="border"
                    size="sm"
                    variant="light"
                  />
                )}{" "}
                Delete
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

AuditDelete.propTypes = {
  audit: PropTypes.object.isRequired,
  onAuditDeleted: PropTypes.func.isRequired,
};

export default AuditDelete;
