import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import FilesPage from "pages/FilesPage";
import LoginPage from "pages/LoginPage";
import EntityPage from "pages/EntityPage";
import LogoutPage from "pages/LogoutPage";
import SearchPage from "pages/SearchPage";
import ContentPage from "pages/ContentPage";
import ReportsPage from "pages/ReportsPage";
import ProfilePage from "pages/ProfilePage";
import AssessmentPage from "pages/AssessmentPage";
import ActionItemsPage from "pages/ActionItemsPage";
import ActivityLogPage from "pages/ActivityLogPage";
import OrganizationPage from "pages/OrganizationPage";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import { OrganizationRedirect } from "features/account";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import AssessmentListPage from "pages/AssessmentListPage";
import { Overview, Portfolios } from "features/organization";
import WhatsNewArticlesPage from "pages/WhatsNewArticlesPage";
import AccountManagementPage from "pages/AccountManagementPage";

import Layout from "./Layout";
import NoMatch from "../ui/NoMatch";
import PrivateRoute from "./PrivateRoute";
import GTMMiddleware from "./GTMMiddleware";
import RolebaseMiddleware from "./RolebaseMiddleware";

import "./App.scss";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

const App = () => {
  const elements = createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route element={<Layout />}>
        <Route path="/" element={<GTMMiddleware />}>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="*"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <OrganizationPage />
                </RolebaseMiddleware>
              }
            >
              <Route index element={<Overview />} />
              <Route path="portfolios/:portfolioId" element={<Portfolios />} />
            </Route>
            <Route
              path="whats-new-articles"
              element={<WhatsNewArticlesPage />}
            />
            <Route
              path="facilities/:facilityId/*"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <EntityPage />
                </RolebaseMiddleware>
              }
            />
            <Route path="reports/*" element={<ReportsPage />} />
            <Route path="audits/:auditId/*" element={<AssessmentPage />} />
            <Route path="contents/:contentId" element={<ContentPage />} />
            <Route
              path="organizations/:organizationId"
              element={<OrganizationRedirect />}
            />
            <Route
              path="organizations/:organizationId/*"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <AccountManagementPage />
                </RolebaseMiddleware>
              }
            />
            <Route
              path="files"
              element={
                <RolebaseMiddleware allowedRoles={["Contributor"]}>
                  <FilesPage />
                </RolebaseMiddleware>
              }
            />
            <Route
              path="activity-log"
              element={
                <RolebaseMiddleware allowedRoles={[]}>
                  <ActivityLogPage />
                </RolebaseMiddleware>
              }
            />
            <Route
              path="action-items"
              element={
                <RolebaseMiddleware allowedRoles={["Contributor", "Member"]}>
                  <ActionItemsPage />
                </RolebaseMiddleware>
              }
            />
            <Route path="audits" element={<AssessmentListPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route
              path="profile/*"
              element={
                <RolebaseMiddleware allowedRoles={["Certifier", "Member"]}>
                  <ProfilePage />
                </RolebaseMiddleware>
              }
            />
            <Route path="search/*" element={<SearchPage />} />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="set-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Route>
    </Route>
  );

  const router = createBrowserRouter(elements);

  return <RouterProvider router={router} />;
};

export default App;
