import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormikContext } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import { DATETIME_FIELD } from "../constants";
const FieldDatetime = ({ value, question }) => {
  const { setFieldValue, handleBlur } = useFormikContext();
  const defaultDate = new Date();

  if (question.type !== DATETIME_FIELD) return <></>;

  if (!value?.response)
    setFieldValue(`metadata.${question.id}`, {
      type: DATETIME_FIELD,
      response: moment(defaultDate).format("YYYY-MM-DD HH:mm"),
    });

  return (
    <DatePicker
      isClearable
      onBlur={handleBlur}
      selected={
        value?.response
          ? moment(value?.response, "YYYY-MM-DD HH:mm").toDate()
          : defaultDate
      }
      dateFormat="MMMM d, yyyy h:mm aa"
      className="form-control"
      onChange={(date) => {
        if (date) {
          const formattedDate = moment(date).format("YYYY-MM-DD HH:mm");
          setFieldValue(`metadata.${question.id}`, {
            type: DATETIME_FIELD,
            response: formattedDate,
          });
        }
      }}
      showTimeSelect
    />
  );
};

FieldDatetime.propTypes = {
  question: PropTypes.object.isRequired,
};

export default FieldDatetime;
