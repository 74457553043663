import { useContext, useEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Button, Col, Table } from "react-bootstrap";

import Collapse from "react-bootstrap/Collapse";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import SeverityFields from "./SeverityFields";

const InherentRisk = ({ children, dynamic, module, fieldPrompt }) => {
  const { audit } = useContext(AuditContext);

  const [inherentRiskMap, setInherentRiskMap] = useState({});
  const [show, setShow] = useState(module ? true : false);
  const wrapperRef = useRef();

  const [resChoice, setResChoice] = useState([]);
  const [releventResLookup, setReleventResLookup] = useState({});

  const sections = audit?.protocol?.sections;

  const childrenMap = useMemo(
    () => children.map((item) => item.id),
    [children]
  );

  const questions = audit.questions;
  const isMultipleObservations = audit?.protocol?.hasObservationSets;

  const releventRes = useMemo(() => {
    return isMultipleObservations
      ? audit.observations
          .flatMap((observation) =>
            !!observation?.observationId
              ? [observation, ...(observation?.children || [])]
              : [{}]
          )
          .filter((observation) => !observation?.isInapplicable)
          .flatMap((observation) => observation.responses || [])
          .filter(
            (item) =>
              item.choice && childrenMap.includes(item.flexibleQuestionId)
          )
      : audit?.responses.filter(
          (item) => item.choice && childrenMap.includes(item.flexibleQuestionId)
        );
  }, [audit, childrenMap, isMultipleObservations]);

  useEffect(() => {
    const generateLookupObject = (dataArray) => {
      const lookupObject = {};

      dataArray.forEach((item) => {
        const { observationId, choiceId, flexibleQuestion } = item;
        if (!lookupObject[observationId]) {
          lookupObject[observationId] = {};
        }
        if (flexibleQuestion.order === 1) {
          lookupObject[item.observationId].severityChoiceId = choiceId;
          lookupObject[item.observationId].resId = item.id;
        }
      });

      return lookupObject;
    };

    setReleventResLookup(generateLookupObject(releventRes));
  }, [releventRes]);

  useEffect(() => {
    if (Array.isArray(children))
      children.forEach((child, index) => {
        const choices = child?.choices || [];

        const selectedIndex = choices.findIndex(({ id }) => {
          if (!dynamic) return false;
          if (!dynamic[child.id]) return false;
          return dynamic[child.id]?.response === id;
        });

        const findResChoice = audit?.responses.filter(
          (item) => item.choice && item.flexibleQuestionId === child.id
        );

        setResChoice((prevState) => {
          return {
            ...prevState,
            [index]: findResChoice?.map(
              ({ id, choice, flexibleQuestionId, questionId, choiceId }) => {
                return {
                  id,
                  choice,
                  flexibleQuestionId,
                  questionId,
                  choiceId,
                };
              }
            ),
          };
        });

        setInherentRiskMap((prevState) => {
          return {
            ...prevState,
            [index]: choices.map(({ id, color, label, score }, index) => {
              return {
                id,
                label,
                score,
                color: color,
                selected: selectedIndex === index,
              };
            }),
          };
        });
      });
  }, [children, dynamic, audit, questions]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  if (!Array.isArray(inherentRiskMap[0])) return <></>;
  if (!Array.isArray(inherentRiskMap[1])) return <></>;

  if (!Array.isArray(resChoice[0])) return <></>;
  if (!Array.isArray(resChoice[1])) return <></>;

  const yAxisItems = inherentRiskMap[0];
  const xAxisItems = inherentRiskMap[1].sort((a, b) => {
    if (a.score < b.score) return -1;
    if (a.score > b.score) return 1;
    return 0;
  });

  const responseYaxisItems = resChoice[0];
  const responseXaxisItems = resChoice[1];

  return (
    <div ref={wrapperRef} onMouseDown={handleClickOutside}>
      {!module && <p className="mt-2 mb-3">{fieldPrompt}</p>}

      <Table bordered className="bg-white" size="sm">
        <tbody>
          {yAxisItems.map((yAxis, index) => {
            return (
              <tr key={`${yAxis?.id}`}>
                <td>
                  {yAxis.label}
                  <span className="text-muted float-end">{yAxis.score}</span>
                </td>
                {xAxisItems.map((xAxis) => {
                  const colorMix = yAxis.color.mix(
                    xAxis.color.toString(),
                    0.5,
                    { space: "lch", outputSpace: "srgb" }
                  );

                  // Filter responses for this cell
                  const filteredResponses = responseYaxisItems.filter(
                    (item) => item.choiceId === yAxis.id
                  );
                  // Count the number of questions for this cell
                  const numberOfQuestions = filteredResponses.filter(
                    (response) =>
                      releventRes.some(
                        (item) =>
                          item.questionId === response.questionId &&
                          releventResLookup[item.observationId]
                            ?.severityChoiceId === response.choiceId &&
                          releventResLookup[item.observationId]?.resId ===
                            response.id &&
                          item.choiceId === xAxis.id
                      )
                  );

                  return (
                    <td
                      key={`${xAxis?.id}`}
                      style={{
                        backgroundColor: colorMix,
                        "--bs-border-color": "#000",
                      }}
                      className={"opacity-75 pointer text-center"}
                      onClick={() => {
                        setShow(
                          !!responseXaxisItems.length &&
                            !!responseYaxisItems.length
                        );
                      }}
                    >
                      <strong>{numberOfQuestions?.length || ""}</strong>
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            <td></td>
            {xAxisItems.map((xAxis, index) => {
              return (
                <td key={`${xAxis?.id}`} className="col-md-2">
                  <span className="text-muted">{xAxis.score}</span>
                  <br />
                  {xAxis.label}
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>

      <Col md={12} sm={12} xs={12} className="">
        {!module &&
          !!responseXaxisItems.length &&
          !!responseYaxisItems.length && (
            <Button
              onClick={() => setShow((prevState) => !prevState)}
              variant="link"
              className="border-none text-decoration-none mt-2 text-dark d-print-none"
              size="sm"
            >
              {show && (
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 me-2"
                >
                  expand_more
                </span>
              )}
              {!show && (
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 me-2"
                >
                  expand_less
                </span>
              )}
              Show IROs
            </Button>
          )}

        {show && !module && (
          <Collapse in={show}>
            <SeverityFields
              questions={questions}
              releventRes={releventRes}
              sections={sections}
              responseYaxisItems={responseYaxisItems}
              responseXaxisItems={responseXaxisItems}
              yAxisItems={yAxisItems}
              xAxisItems={xAxisItems}
              module={module}
            />
          </Collapse>
        )}

        {module &&
          show &&
          !!responseXaxisItems.length &&
          !!responseYaxisItems.length && (
            <SeverityFields
              questions={questions}
              releventRes={releventRes}
              sections={sections}
              responseYaxisItems={responseYaxisItems}
              responseXaxisItems={responseXaxisItems}
              yAxisItems={yAxisItems}
              xAxisItems={xAxisItems}
              module={module}
              isMultipleObservations={isMultipleObservations}
            />
          )}
      </Col>
    </div>
  );
};

InherentRisk.propTypes = {
  dynamic: PropTypes.any,
  children: PropTypes.array.isRequired,
};

export default InherentRisk;
