import React, { useContext } from "react";

import { uniqBy } from "lodash";
import { Card, Col, Table } from "react-bootstrap";

import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import AuditAddMember from "./AuditAddMember";
import AuditDeleteMember from "./AuditDeleteMember";
import { useRoles } from "hooks";

const AuditMembers = () => {
  const { isCertifier } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);
  const { subscribers } = audit || {};

  return (
    <Col xs={12} sm={12} md={12} lg={8}>
      <div className="d-flex flex-row justify-content-between align-items-center mb-2">
        <div className="flex-grow-1">
          <h4 className="pt-1 mb-0">Assessment Team</h4>
        </div>
        <AuditAddMember audit={audit} setAudit={setAudit} />
      </div>
      <Card>
        <Card.Body className="table-responsive">
          <Table hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Added</th>
                {!isCertifier && <th>Action</th>}
              </tr>
            </thead>

            <tbody className="table-group-divider">
              {!!subscribers.length ? (
                uniqBy(audit?.subscribers, "subscriberId")?.map(
                  (member, index) => {
                    const {
                      subscriberId,
                      firstName,
                      lastName,
                      email,
                      metadata: { addedAt } = {},
                    } = member;
                    return (
                      <tr key={subscriberId}>
                        <td className="text-wrap">
                          <small>
                            {firstName} {lastName}
                          </small>
                        </td>
                        <td className="text-wrap">
                          <small>{email}</small>
                        </td>
                        <td>
                          <small>
                            {new Date(addedAt).toLocaleString([], {
                              dateStyle: "short",
                              timeStyle: "short",
                            })}
                          </small>
                        </td>
                        <td>
                          <AuditDeleteMember
                            member={member}
                            audit={audit}
                            setAudit={setAudit}
                          />
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td colSpan={8}>
                    <EmptyStateHandler
                      title="No Members"
                      description="Members will appear here once added"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default AuditMembers;
