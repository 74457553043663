import { useContext, useEffect, useRef, useState } from "react";

import { useRoles } from "hooks";
import { dateFilter } from "constants";
import { head, truncate } from "lodash";
import { useFetchProtocols } from "services";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Nav,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import { FacilityList } from "features/entity";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { useShowObservationProperties } from "features/assessment";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { principleChartFilters } from "features/organization/constants";

import OrganizationReportRisk from "./OrganizationReportRisk";
import OrganizationActionItem from "./OrganizationActionItem";
import OrganizationBestPractices from "./OrganizationBestPractices";
import OrganizationStats from "./OrganizationStats/OrganizationStats";
import OrganizationReportCompliance from "./OrganizationReportCompliance";
import OrganizationReportConformity from "./OrganizationReportConformity";
import OrganizationPrincipleReportRisk from "./OrganizationPrincipleReportRisk";
import OrganizationRadioField from "./OrganizationRadioField/OrganizationRadioField";
import OrganizationPrincipleReportCompliance from "./OrganizationPrincipleReportCompliance";
import OrganizationPrincipleReportConformity from "./OrganizationPrincipleReportConformity";
import OrganizationCheckboxField from "./OrganizationCheckboxField/OrganizationCheckboxField";
import OrganizationNumericFields from "./OrganizationNumericFields/OrganizationNumericFields";
import Slider from "react-slick";
import { PrinciplesSpiderWeb } from "features/assessment";
import { PrinciplesScoresGuage } from "features/assessment";
import SlickArrowNext from "components/ui/SlickArrowNext";
import SlickArrowPrev from "components/ui/SlickArrowPrev";

const Overview = () => {
  const [key, setKey] = useState("risk-tab");
  const [weightScoresKey, setWeightScoresKey] = useState("gauges-tab");
  const [protocol, setProtocol] = useState();
  const [showPrinciple, setShowPrinciple] = useState(principleChartFilters[0]);
  const [filterPeriod, setFilterPeriod] = useState(dateFilter[3]);
  const organization = useContext(OrganizationContext);
  const [bestPracFindings, setBestPracFindings] = useState();
  const [dynamicFieldData, setDynamicFieldData] = useState();
  const [radioFields, setRadioFields] = useState();
  const [checkboxFields, setCheckboxFields] = useState();
  const [numericFields, setNumericFields] = useState();
  const [weightedScores, setWeightedScores] = useState([]);

  const navigate = useNavigate();
  const { isAdmin } = useRoles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const subscribedPromises = useRef([]);

  const { isLoading: isExpanding, protocols } = useFetchProtocols({
    onSuccess: (protocols) => {
      const first = head(protocols);
      setProtocol(first || null);
      if (first) setActiveTab(first?.questionOptions);
    },
  });

  const { isRisk, isConformity, isCompliance } = useShowObservationProperties({
    protocol,
  });

  const isBestPracticeAndFindings =
    showPrinciple === principleChartFilters[2] ? true : false;

  const setActiveTab = (questionOptions) => {
    if (!questionOptions || !questionOptions?.length) return false;
    questionOptions
      .filter(({ id }) => [0, 1, 2].includes(id))
      .every(({ id, value }) => {
        if (!value) return true;
        if (id === 0) setKey("risk-tab");
        if (id === 1) setKey("compliance-tab");
        if (id === 2) setKey("conformity-tab");
        return false;
      });
  };

  useEffect(() => {
    function fetchBestPracticesAndFindings() {
      setLoading(true);
      setError(null);
      setBestPracFindings();
      const findingsPromise = get(
        `/organizations/${organization?.id}/protocols/${protocol?.protocolId}/answer-type-stats`
      );

      findingsPromise.promise
        .then(({ data }) => {
          setBestPracFindings(data);
          const zeroValues = data?.every(
            (item) => item.stats.bestPractise === 0 && item.stats.finding === 0
          );
          if (
            !isRisk &&
            !isCompliance &&
            !isConformity &&
            !zeroValues &&
            !radioFields &&
            !checkboxFields
          ) {
            setShowPrinciple(principleChartFilters[2]);
            setDynamicFieldData();
          }
          setLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setLoading(false);
        });
      subscribedPromises.current.push(findingsPromise);
    }

    function fetchDynamicFields() {
      setLoading(true);
      setError(null);

      setRadioFields();
      setCheckboxFields();
      setNumericFields();
      setWeightedScores([]);

      const findingsPromise = get(
        `search/organizations/${organization?.id}/protocols/${protocol?.protocolId}/stats`
      );

      findingsPromise.promise
        .then(({ data }) => {
          setDynamicFieldData(data);

          const radioF = data?.filter(
            (item) => item.fieldType === "Radio Field"
          );

          const checkboxF = data?.filter(
            (item) => item.fieldType === "Checkbox Field"
          );
          const numericF = data?.filter(
            (item) => item.fieldType === "Numeric Field"
          );

          const filteredRadioCount = radioF.filter((item) => {
            return item.stats.some((stat) => stat.count > 0);
          });
          const filteredChechboxCount = checkboxF.filter((item) => {
            return item.stats.some((stat) => stat.count > 0);
          });
          const filteredNumericCount = numericF.filter((item) => {
            return item.stats.some((stat) => stat.count > 0);
          });

          const gauges = radioF
            ?.filter((item) => Array.isArray(item?.gauges))
            ?.flatMap((item) =>
              item.gauges.map((guage) => ({
                ...guage,
                questionTitle: item?.fieldName,
              }))
            );

          setWeightedScores(gauges);
          setRadioFields(filteredRadioCount);

          setCheckboxFields(filteredChechboxCount);
          setNumericFields(filteredNumericCount);

          if (!isRisk && !isCompliance && !isConformity) {
            if (filteredRadioCount?.length > 0) {
              setKey(filteredRadioCount[0]?.fieldName);
            } else if (filteredChechboxCount?.length > 0) {
              setKey(filteredChechboxCount[0]?.fieldName);
            }
          }

          setLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setLoading(false);
        });
      subscribedPromises.current.push(findingsPromise);
    }

    if (protocol?.protocolId && isBestPracticeAndFindings) {
      fetchBestPracticesAndFindings();
    }
    if (protocol?.protocolId && !isBestPracticeAndFindings)
      fetchDynamicFields();

    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    protocol?.protocolId,
    isRisk,
    isCompliance,
    isConformity,
    isBestPracticeAndFindings,
  ]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SlickArrowNext />,
    prevArrow: <SlickArrowPrev />,
    className: "card",
  };

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12} xl={8} className="mb-3">
        <Row>
          <Col xs={12} className="mb-3">
            <Card className="px-3">
              <Row className="pt-3">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2 className="m-0">Overview</h2>
                  {isAdmin && (
                    <div className="d-grid">
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => navigate(`reports`)}
                        className="px-4 py-1.5 flex-grow-1 rounded-3 fs-6"
                      >
                        Reports
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18 ms-1"
                        >
                          arrow_forward
                        </span>{" "}
                      </Button>
                    </div>
                  )}
                </div>
                <Col md={12} xs={12} sm={12} lg={12}>
                  <OrganizationStats orgId={organization?.id} />
                </Col>
                <hr />
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className="mb-3">
                  <Dropdown translate="no">
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="protocol-filter"
                      className="border btn-block w-100 text-start"
                      size="sm"
                    >
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18 me-2"
                      >
                        filter_list
                      </span>
                      {isExpanding
                        ? "Loading..."
                        : truncate(protocol?.name) ?? "Filter by protocol"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Array.isArray(protocols) &&
                        protocols.map((p) => (
                          <Dropdown.Item
                            active={p.protocolId === protocol?.protocolId}
                            key={p.protocolId}
                            onClick={() => {
                              setProtocol(p);
                              setActiveTab(p.questionOptions);
                            }}
                          >
                            {p.name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={12} sm={12} md={5} lg={4} xl={4} className="mb-3">
                  <Dropdown translate="no">
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="time-interval-filter"
                      className="border btn-block w-100 text-start dropdown-toggle"
                      size="sm"
                    >
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18 me-0.5 dropdown-icon-style"
                      >
                        bar_chart
                      </span>
                      <span className="w-100">
                        {truncate(showPrinciple.text, {
                          length: 27,
                          omission: "",
                        })}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {principleChartFilters.map((chart) => (
                        <Dropdown.Item
                          key={chart.index}
                          active={chart.index === showPrinciple?.index}
                          onClick={() => {
                            setShowPrinciple(chart);
                            setKey(
                              isRisk
                                ? "risk-tab"
                                : radioFields
                                ? radioFields[0]?.fieldName
                                : checkboxFields && checkboxFields[0]?.fieldName
                            );
                          }}
                        >
                          {chart.text}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                {showPrinciple.index === 1 && (
                  <Col xs={12} sm={12} md={3} lg={3} xl={3} className="mb-3">
                    <Dropdown translate="no">
                      <Dropdown.Toggle
                        variant="outline-secondary"
                        id="time-interval-filter"
                        className="border btn-block w-100 text-start"
                        size="sm"
                      >
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18 me-2"
                        >
                          date_range
                        </span>
                        {truncate(filterPeriod.text)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {dateFilter.map((interval) => (
                          <Dropdown.Item
                            key={interval.index}
                            active={interval.index === filterPeriod.index}
                            onClick={() => {
                              setFilterPeriod(interval);
                            }}
                          >
                            {interval.text}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                )}
              </Row>

              {!isExpanding && (
                <Tab.Container
                  id="left-tabs-example"
                  className="bg-primary"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Nav variant="tabs">
                    {isRisk && !isBestPracticeAndFindings && (
                      <Nav.Item>
                        <Nav.Link eventKey="risk-tab">
                          {protocol?.questionOptions[0]?.name ?? "Risk Rating"}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {isCompliance && !isBestPracticeAndFindings && (
                      <Nav.Item>
                        <Nav.Link eventKey="compliance-tab">
                          {protocol?.questionOptions[1]?.name ?? "Compliance"}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {isConformity && !isBestPracticeAndFindings && (
                      <Nav.Item>
                        <Nav.Link eventKey="conformity-tab">
                          {protocol?.questionOptions[2]?.name ??
                            "Conformity Level"}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {!!weightedScores?.length && !isBestPracticeAndFindings && (
                      <Nav.Item>
                        <Nav.Link eventKey="weighted-scores-tab">
                          Weighted Scores
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {showPrinciple.index === 1 && (
                      <>
                        {radioFields && (
                          <>
                            {radioFields?.map((item, index) => (
                              <Nav.Item key={index}>
                                <Nav.Link eventKey={item.fieldName}>
                                  {item.fieldName}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </>
                        )}

                        {checkboxFields && (
                          <>
                            {checkboxFields?.map((item, index) => (
                              <Nav.Item key={index}>
                                <Nav.Link eventKey={item.fieldName}>
                                  {item?.fieldName}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </>
                        )}
                        {numericFields && (
                          <>
                            {numericFields?.map((item, index) => (
                              <Nav.Item key={index}>
                                <Nav.Link eventKey={item.fieldName}>
                                  {item?.fieldName}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </Nav>
                  <Tab.Content className="pt-3">
                    {loading && !isBestPracticeAndFindings && <Loader />}

                    {showPrinciple.index === 1 ? (
                      <>
                        {isRisk && !isBestPracticeAndFindings && (
                          <Tab.Pane eventKey="risk-tab">
                            <OrganizationReportRisk
                              protocol={protocol}
                              isExpanding={isExpanding}
                              filterPeriod={filterPeriod}
                            />
                          </Tab.Pane>
                        )}
                        {isCompliance && !isBestPracticeAndFindings && (
                          <Tab.Pane eventKey="compliance-tab">
                            <OrganizationReportCompliance
                              protocol={protocol}
                              isExpanding={isExpanding}
                              filterPeriod={filterPeriod}
                            />
                          </Tab.Pane>
                        )}
                        {isConformity && !isBestPracticeAndFindings && (
                          <Tab.Pane eventKey="conformity-tab">
                            <OrganizationReportConformity
                              protocol={protocol}
                              isExpanding={isExpanding}
                              filterPeriod={filterPeriod}
                            />
                          </Tab.Pane>
                        )}
                        {!!weightedScores?.length &&
                          !isBestPracticeAndFindings && (
                            <Tab.Pane eventKey="weighted-scores-tab">
                              <Tabs
                                className="mb-3"
                                onSelect={(e) => setWeightScoresKey(e)}
                              >
                                <Tab
                                  eventKey={`gauges-tab`}
                                  title={"Scores By Principles (%) - Gauges"}
                                >
                                  <Tabs className="mb-3">
                                    {Object.entries(
                                      Object.groupBy(
                                        weightedScores || [],
                                        ({ questionTitle }) => questionTitle
                                      )
                                    )?.map(([key, value]) => {
                                      return (
                                        <Tab
                                          key={`${key}`}
                                          eventKey={`${key}`}
                                          title={key}
                                        >
                                          {value?.length &&
                                          value?.reduce(
                                            (acc, val) =>
                                              acc + Number(val?.maxScore),
                                            0
                                          ) ? (
                                            <Slider
                                              {...{
                                                ...settings,
                                                slidesToShow: 4,
                                                className: "pb-3",
                                              }}
                                            >
                                              {value?.map((data, index) => {
                                                const score =
                                                  (data?.totalScore /
                                                    data?.maxScore) *
                                                  100;
                                                if (!isNaN(score)) {
                                                  return (
                                                    <PrinciplesScoresGuage
                                                      title={`${data?.title}`}
                                                      score={
                                                        score?.toFixed(2) || 0
                                                      }
                                                      size="sm"
                                                      key={index}
                                                    />
                                                  );
                                                } else {
                                                  return false;
                                                }
                                              })}
                                            </Slider>
                                          ) : (
                                            <EmptyStateHandler
                                              title="No data to visualize"
                                              description="You have no data to visualize."
                                            />
                                          )}
                                        </Tab>
                                      );
                                    })}
                                  </Tabs>
                                </Tab>
                                <Tab
                                  eventKey={`spider-web-tab`}
                                  title={"Scores By Principles - Spiderweb"}
                                >
                                  <PrinciplesSpiderWeb
                                    rerender={weightScoresKey}
                                    overviewPage={true}
                                    data={weightedScores.map((val) => ({
                                      ...val,
                                      flexibleQuestion: {
                                        title: val?.questionTitle,
                                      },
                                      principle: {
                                        principleId: val?.principleId,
                                        name: val?.title,
                                      },
                                    }))}
                                    principles={[
                                      ...new Map(
                                        (weightedScores || []).map((item) => [
                                          item["title"],
                                          item,
                                        ])
                                      ).values(),
                                    ]}
                                  />
                                </Tab>
                              </Tabs>
                            </Tab.Pane>
                          )}
                        {radioFields &&
                          radioFields
                            .filter((item) => item.fieldName === key)
                            .map((filteredField, index) => (
                              <Tab.Pane
                                key={index}
                                eventKey={filteredField.fieldName}
                              >
                                <OrganizationRadioField data={filteredField} />
                              </Tab.Pane>
                            ))}
                        {checkboxFields &&
                          checkboxFields
                            ?.filter((item) => item.fieldName === key)
                            ?.map((filteredField, index) => (
                              <Tab.Pane
                                key={index}
                                eventKey={filteredField.fieldName}
                              >
                                <OrganizationCheckboxField
                                  data={filteredField}
                                />
                              </Tab.Pane>
                            ))}
                        {numericFields &&
                          numericFields
                            ?.filter((item) => item.fieldName === key)
                            ?.map((filteredField, index) => (
                              <Tab.Pane
                                key={index}
                                eventKey={filteredField.fieldName}
                              >
                                <OrganizationNumericFields
                                  numericFields={filteredField}
                                />
                              </Tab.Pane>
                            ))}

                        {!isRisk &&
                          !isCompliance &&
                          !isConformity &&
                          !radioFields?.length &&
                          !checkboxFields?.length &&
                          !loading && (
                            <EmptyStateHandler
                              title="No data to visualize"
                              description="You have no data to visualize."
                            />
                          )}
                      </>
                    ) : (
                      <>
                        {isRisk && !isBestPracticeAndFindings && (
                          <Tab.Pane eventKey="risk-tab">
                            <OrganizationPrincipleReportRisk
                              protocol={protocol}
                              filterPeriod={filterPeriod}
                            />
                          </Tab.Pane>
                        )}
                        {isCompliance && !isBestPracticeAndFindings && (
                          <Tab.Pane eventKey="compliance-tab">
                            <OrganizationPrincipleReportCompliance
                              protocol={protocol}
                              filterPeriod={filterPeriod}
                            />
                          </Tab.Pane>
                        )}
                        {isConformity && !isBestPracticeAndFindings && (
                          <Tab.Pane eventKey="conformity-tab">
                            <OrganizationPrincipleReportConformity
                              protocol={protocol}
                              filterPeriod={filterPeriod}
                            />
                          </Tab.Pane>
                        )}
                        {!isRisk &&
                          !isCompliance &&
                          !isConformity &&
                          !isBestPracticeAndFindings &&
                          !loading && (
                            <EmptyStateHandler
                              title="No data to visualize"
                              description="You have no data to visualize."
                            />
                          )}
                      </>
                    )}

                    {isBestPracticeAndFindings && (
                      <OrganizationBestPractices
                        data={bestPracFindings}
                        isLoading={loading}
                        protocol={protocol}
                        error={error}
                      />
                    )}
                  </Tab.Content>
                </Tab.Container>
              )}
            </Card>
          </Col>
          <Col xs={12} className="mb-3">
            <FacilityList />
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={4} className="mb-3">
        <OrganizationActionItem />
      </Col>
    </>
  );
};

export default Overview;
