import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import ActionItemTableHeader from "../../ActionItems/ActionItemTableHeader.js";
import ActionItemListItem from "../../ActionItems/ActionItemListItem";
import MetaPagination from "components/ui/MetaPagination";
import ActionItemPDF from "../../ActionItems/ActionItemPDF";
import {
  ActionItemsDispatchContext,
  ActionItemsContext,
} from "features/actionItems/context/ActionItemProvider";
import ActionItemFilters from "../../ActionItems/ActionItemFilters/ActionItemFilters.js";

import "./ActionItemDashBoard.scss";

const ActionItemDashBoard = ({ tableView }) => {
  const { actionItems, meta } = useContext(ActionItemsContext);

  const toPrintRef = useRef();

  const dispatch = useContext(ActionItemsDispatchContext);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    dispatch({ type: "SET_PAGE", payload: page });
  }, [dispatch, page]);

  useEffect(() => {
    dispatch({ type: "SET_PER_PAGE", payload: perPage });
    dispatch({ type: "SET_PAGE", payload: 1 });
  }, [dispatch, perPage]);

  if (!tableView) return <span />;

  return (
    <>
      <ActionItemFilters />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
          <Card>
            <Card.Body className="table-responsive">
              <Table className="cursor-default">
                <ActionItemTableHeader
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
                <ActionItemListItem
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
              </Table>
            </Card.Body>
            <Card.Footer>
              <MetaPagination
                meta={meta}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <ActionItemPDF reference={toPrintRef} actionItem={actionItems} />
    </>
  );
};

export default ActionItemDashBoard;
