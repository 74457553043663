import { useContext } from "react";

import { useRoles } from "hooks";
import { Card, Col, Image, Row, Table } from "react-bootstrap";

import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import SignatureCreate from "./SignatureCreate";
import SignatureDelete from "./SignatureDelete";

const AuditSignatures = () => {
  const { audit, setAudit } = useContext(AuditContext);

  const { isCertifier } = useRoles();

  const { signatures = [] } = audit || {};

  const handleSignatureDeleted = (deletedSignature) => {
    setAudit(() => ({
      ...audit,
      signatures: audit?.signatures.filter(({ id }) => {
        return deletedSignature?.id !== id;
      }),
    }));
  };

  const handleSignatureCreated = (signature) => {
    setAudit({ ...audit, signatures: [...audit?.signatures, signature] });
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={8}>
        <div className="d-flex flex-row mb-3">
          <div className="flex-grow-1">
            <h4 className="pt-1 mb-0">Signatures</h4>
          </div>
          <span className="float-end">
            {!isCertifier && (
              <SignatureCreate
                disclaimer={audit?.protocol?.disclaimer}
                onSignatureCreated={handleSignatureCreated}
              />
            )}
          </span>
        </div>
        <Card>
          <Card.Body className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Date</th>
                  <th>Signature</th>
                  {!isCertifier && <th>Action</th>}
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {Array.isArray(signatures) && !signatures.length ? (
                  <tr>
                    <td colSpan={8}>
                      <EmptyStateHandler
                        className="mb-3 d-flex flex-row"
                        title="No Signatures"
                        description="There are currently no signatures."
                      />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {Array.isArray(signatures) &&
                  signatures.map((signature, index) => {
                    return (
                      <tr key={signature?.id}>
                        <td className="text-wrap">{signature.name}</td>
                        <td className="text-wrap">{signature.title}</td>
                        <td>
                          <small>
                            {new Date(signature?.signatureDate).toLocaleString(
                              [],
                              {
                                dateStyle: "short",
                                timeStyle: "short",
                              }
                            )}
                          </small>
                        </td>
                        <td>
                          <Image
                            fluid
                            width={150}
                            src={signature.signatureImage[0]?.url}
                          />
                        </td>
                        {!isCertifier && (
                          <td className="text-center">
                            <SignatureDelete
                              signature={signature}
                              onSignatureDeleted={handleSignatureDeleted}
                              cypressId={`btn-signature-delete-${index}`}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AuditSignatures;
