import React, { useContext, useEffect, useRef, useState } from "react";

import { truncate } from "lodash";
import { Alert, Button, Card, Col } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { get } from "utils/DeApi";
import BreadCrumbs from "components/ui/BreadCrumbs";
import ExpandFullScreen from "components/ui/ExpandFullScreen";

import NoReportsAvailable from "../OrganizationReports/NoReportsAvailable";
import DisplayPowerBiReport from "../OrganizationReports/DisplayPowerBiReport";
import Loader from "components/ui/Loader";
import { OrganizationContext } from "contexts/OrganizationProvider";

const RenderReports = ({ fetchedEmbed, isPowerBi }) => {
  if (isPowerBi) {
    return <DisplayPowerBiReport config={{ ...fetchedEmbed.powerBi }} />;
  }
  return (
    <div
      className="ratio ratio-16x9"
      dangerouslySetInnerHTML={{
        __html: fetchedEmbed?.iframeCode,
      }}
    />
  );
};

function DisplayReport() {
  const { reportId } = useParams();
  const subscribedPromises = useRef([]);
  const [fetchedEmbed, setFetchedEmbed] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [dataNotAvailable, setDataNotAvailable] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const organization = useContext(OrganizationContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReportEmbed = () => {
      setIsLoading(true);
      const findingsPromise = get(`/embedded-reports/${reportId}`);
      findingsPromise.promise
        .then(async ({ data }) => {
          setIsLoading(false);
          if (data && data.length < 1) {
            setDataNotAvailable(true);
            return;
          }
          console.log(data);
          setFetchedEmbed(data);
        })
        .catch((error) => {
          setErrorMessage(error.data.error.message);
          setDataNotAvailable(true);
          setIsLoading(false);
        });
      subscribedPromises.current.push(findingsPromise);
    };
    fetchReportEmbed();
  }, [reportId]);

  if (isLoading) return <Loader />;

  if (organization?.id !== fetchedEmbed?.organizationId) {
    return (
      <div className="d-flex flex-column h-fixed-360 align-items-center justify-content-center">
        <Alert variant="info" className={`my-3 d-flex flex-row `}>
          <div className="me-3">
            <span translate="no" className="material-symbols-outlined md-18">
              lock
            </span>
          </div>
          <div className="flex-fill">
            <h5 className="mb-1">
              <small>Unauthorized</small>
            </h5>
            <p>
              <small>
                Sorry You Don't Have Permission To View This Report!
              </small>
            </p>

            <Button
              variant="info"
              size="sm"
              role="link"
              onClick={() => navigate(`/reports`)}
            >
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                arrow_back
              </span>
              {/* */}
              Go back to Reports
            </Button>
          </div>
        </Alert>
      </div>
    );
  }

  return (
    <>
      <BreadCrumbs
        breadcrumbs={[
          { name: "Dashboard", link: "/" },
          { name: "Reports", link: "/reports" },
          {
            name: truncate(fetchedEmbed?.title, {
              length: 40,
            }),
            active: false,
          },
        ]}
      />
      <div className="d-flex flex-row mb-3 align-items-center">
        <div className="flex-grow-1">
          <h1 className="mb-0">{fetchedEmbed?.title}</h1>
          <p className="mb-2 text-break">{fetchedEmbed?.description}</p>
        </div>
        {!dataNotAvailable && (
          <ExpandFullScreen title={fetchedEmbed?.title}>
            <RenderReports
              fetchedEmbed={fetchedEmbed}
              isPowerBi={fetchedEmbed?.reportType === "Assess PowerBi"}
            />
            {dataNotAvailable && (
              <NoReportsAvailable errorMessage={errorMessage} />
            )}
          </ExpandFullScreen>
        )}
      </div>
      <Col xs={12} className="mb-3">
        <Card className="p-3 ratio ratio-16x9">
          <Card.Body className="table-responsive">
            <RenderReports
              fetchedEmbed={fetchedEmbed}
              isPowerBi={fetchedEmbed?.reportType === "Assess PowerBi"}
            />
            {dataNotAvailable && (
              <NoReportsAvailable errorMessage={errorMessage} />
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default DisplayReport;
