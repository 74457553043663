import React, { useContext, useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";

import { put } from "utils/BeeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";
import MainFixedOffset from "layouts/MainFixedOffset";
import RedirectIfAuthenticated from "../common/RedirectIfAuthenticated";

import "./ResetPassword.scss";

const ResetPassword = () => {
  const user = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("verification");

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const subscribedPromises = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => promises.forEach((promise) => promise.cancel());
  }, []);

  function handleSubmit(email, password, confirmPassword) {
    setError("");
    setIsLoading(true);

    const createPromise = put("/user/anon/password", {
      userId: "anon",
      email,
      password,
      token,
      password_confirmation: confirmPassword,
    });
    createPromise.promise
      .then((response) => {
        setIsLoading(false);
        navigate("/login");
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPromise);
  }

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("This should be a valid email address")
      .required("Email is required"),
    password: yup
      .string()
      .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/, {
        message:
          "Password must contain at least 6 characters, including UPPER/lowercase and numbers.",
        excludeEmptyString: true,
      })
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Confirm password must match with password"
      )
      .required("Confirm Password is required"),
  });

  if (user) return <RedirectIfAuthenticated isAuthenticated={user} />;

  return (
    <MainFixedOffset>
      <Card className="border-0 shadow-sm">
        <Card.Body>
          <Formik
            validationSchema={schema}
            onSubmit={(values) =>
              handleSubmit(
                values.email,
                values.password,
                values.confirmPassword
              )
            }
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mt-sm mb-md text-center">
                  <h2 className="pre-title">Set Password</h2>
                  <p>Enter your email and a new password for your account.</p>
                </div>
                <hr />
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !(values.email && !errors.email) && touched.email
                    }
                    isValid={values.email && !errors.email}
                    placeholder="Enter email"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email && touched.email ? (
                      <small>{errors.email}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={values.password && !errors.password}
                      isInvalid={
                        !(values.password && !errors.password) &&
                        touched.password
                      }
                      placeholder="Password"
                      className="border-end-0"
                      autoComplete="new-password"
                    />
                    <Button
                      variant="light"
                      className="border border-secondary border-start-0 border-opacity-25"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18"
                        >
                          visibility
                        </span>
                      ) : (
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18"
                        >
                          visibility_off
                        </span>
                      )}
                    </Button>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {errors.password && touched.password ? (
                      <small>{errors.password}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3 text-input"
                  controlId="confirmPassword"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        values.confirmPassword && !errors.confirmPassword
                      }
                      isInvalid={
                        !(values.confirmPassword && !errors.confirmPassword) &&
                        touched.confirmPassword
                      }
                      placeholder="Confirm Password"
                      className="border-end-0"
                    />
                    <Button
                      variant="light"
                      className="border border-secondary border-start-0 border-opacity-25"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18"
                        >
                          visibility
                        </span>
                      ) : (
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18"
                        >
                          visibility_off
                        </span>
                      )}
                    </Button>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <small>{errors.confirmPassword}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                {error &&
                  ((error) => {
                    if (error.status === 403) {
                      return (
                        <Alert
                          variant="warning"
                          className="my-3 d-flex flex-row"
                        >
                          <div className="me-3">
                            <span
                              translate="no"
                              className="material-symbols-outlined md-18"
                            >
                              warning
                            </span>
                          </div>
                          <div>
                            <h5 className="mb-1">
                              <small>Invalid Token!</small>
                            </h5>
                            <p className="mb-1">
                              <small>
                                Sorry, your link to set a new password has
                                expired or is invalid. Please request for
                                another link{" "}
                                <Link to="/forgot-password">here</Link>.
                              </small>
                            </p>
                          </div>
                        </Alert>
                      );
                    }

                    return <ErrorHandler error={error} />;
                  })(error)}
                <div className="d-grid gap-2 mb-3">
                  <Button
                    className="mb-4"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}
                    <span>Continue</span>
                  </Button>
                </div>
                <p className="text-center">
                  <Link to="/login">Cancel</Link>
                </p>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </MainFixedOffset>
  );
};

export default ResetPassword;
