import { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ExpandFullScreen from "components/ui/ExpandFullScreen";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { aggregationOfPrincipleComlianceTrends } from "helper/reporting";
import ReportPrincipleChartLarge from "components/common/ReportPrincipleChartLarge";

function FacilityPrincipleReportCompliance({
  protocol,
  facility,
  filterPeriod,
}) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [error, setError] = useState();
  const [complianceStats, setComplianceStats] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const allHaveZeroValues =
    (complianceStats?.length === 0 ? true : false) ||
    complianceStats?.every((item) => item?.observationsCount === 0) ||
    (!complianceStats && true);

  useEffect(() => {
    function fetchStats() {
      setError(null);
      setIsLoading(true);

      const compliancePromise = get(
        `facilities/${facility?.facilityId}/protocols/${protocol?.protocolId}/principle-stats`,
        {
          params: {
            period: filterPeriod?.period,
            organizationId: organization?.id,
            option: 1,
          },
        }
      );
      compliancePromise.promise
        .then(({ data }) => {
          setComplianceStats(data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(compliancePromise);
    }

    if (protocol?.protocolId) {
      fetchStats();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organization?.id,
    protocol?.protocolId,
    filterPeriod,
    facility?.facilityId,
  ]);

  if (error) return <ErrorHandler error={error} />;

  return (
    <Row>
      {isLoading ? (
        <Loader />
      ) : !allHaveZeroValues && complianceStats ? (
        <>
          <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
            <div className="d-flex flex-row">
              <p className="text-center w-100 text-truncate text-capitalize mt-2">
                Distribution of Findings across Principles &{" "}
                {protocol?.questionOptions[1]?.name || "Compliance"}
              </p>
              <span>
                <ExpandFullScreen
                  title={`Distribution of Findings across Principles & ${
                    protocol?.questionOptions[1]?.name || "Compliance"
                  }`}
                >
                  <ReportPrincipleChartLarge
                    size="square-container"
                    labels={{
                      nonCompliant: "Non Compliant",
                      compliant: "Compliant",
                    }}
                    data={aggregationOfPrincipleComlianceTrends(
                      complianceStats
                    )}
                  />
                </ExpandFullScreen>
              </span>
            </div>
            <hr />
            <ReportTrend
              size="square-container"
              labels={{
                nonCompliant: "Non Compliant",
                compliant: "Compliant",
              }}
              data={aggregationOfPrincipleComlianceTrends(complianceStats)}
              chartHeight={400}
            />
          </Col>
        </>
      ) : (
        complianceStats &&
        allHaveZeroValues && (
          <Col>
            <EmptyStateHandler
              title="No data to visualize"
              description="You have no data to visualize."
            />
          </Col>
        )
      )}
    </Row>
  );
}

FacilityPrincipleReportCompliance.propTypes = {
  protocol: PropTypes.object.isRequired,
  facility: PropTypes.string.isRequired,
  filterPeriod: PropTypes.object.isRequired,
};

export default FacilityPrincipleReportCompliance;
