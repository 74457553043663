import DOMPurify from "dompurify";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import React, { useContext } from "react";

const LegalDisclaimer = () => {
  const { audit } = useContext(AuditContext);

  return (
    <>
      <h2 className="my-1 py-1 text-uppercase text-primary border-bottom border-primary">
        Legal Disclaimer
      </h2>

      {audit.protocol.disclaimer && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(audit.protocol.disclaimer),
          }}
        ></div>
      )}
    </>
  );
};

export default LegalDisclaimer;
