import React, { useContext, useEffect, useRef } from "react";

import * as echarts from "echarts";
import { flatten, groupBy, uniqBy } from "lodash";

import { colorMap } from "features/assessment/constants";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { conformityLevelMap } from "features/assessment/components/Audit/helper";

import "./ReportConformityPrinciple.scss";

const CHART_HEIGHT = 320;

const ReportConformityPrinciple = ({ setChartImage, isExportable = false }) => {
  const el = useRef(null);
  const { audit } = useContext(AuditContext);

  useEffect(() => {
    const observations = audit?.questions.flatMap(
      (question) => question.observations || []
    );
    const principles = uniqBy(
      flatten(audit.protocol.sections.map(({ principles }) => principles)),
      "principleId"
    );

    const conformityFiltered = audit.protocol.conformity.filter(
      ({ value = "" }) => value.trim().toLowerCase() !== "n/a"
    );

    const aggregation = principles.map((p) => {
      const principleSections = audit?.protocol?.sections.filter((section) => {
        return !!section.principles.find(
          (principle) => principle.principleId === p.principleId
        );
      });

      const questions = uniqBy(
        flatten(principleSections.map(({ questions }) => questions)),
        "questionId"
      );

      const conformityLevel = groupBy(observations, (observation) => {
        const { conformityLevel } = observation || {};

        if (observation.isInapplicable) return "";
        if (
          observation.conformityLevel === null ||
          !questions.find((q) => q.questionId === observation.questionId)
        )
          return "";
        return conformityLevelMap(conformityLevel, conformityFiltered);
      });

      delete conformityLevel[""];
      const mapped = Object.keys(conformityLevel).map((key) => {
        return { name: key, value: conformityLevel[key].length };
      });

      return [
        p.title,
        ...conformityFiltered
          .map(({ value: name }) => {
            const found = mapped.find((m) => m.name === name);
            return !!found ? found : { name: name, value: 0 };
          })
          .map(({ value }) => value),
      ];
    });

    const barTitles = conformityFiltered.map(({ value }) => value);

    const data = [["Principle", ...barTitles], ...aggregation];

    const maxTitleLength = Math.max(
      ...principles.map(({ title = "" }) => parseInt(title.length))
    );

    const expandRatio = (maxTitleLength / 2) * 10;

    const series = barTitles.map((key) => {
      const color = Object.values(colorMap).find(({ label }) => {
        return key.toLowerCase().includes(label?.toLowerCase());
      });

      return {
        type: "bar",
        barMaxWidth: 32,
        color: color?.color || "",
      };
    });

    const dimension = {
      width: isExportable ? 800 : el.current.offsetWidth,
      height: isExportable ? 500 + expandRatio : CHART_HEIGHT,
    };

    const option = {
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },

      legend: {
        type: "scroll",
        icon: "circle",
        textStyle: {
          fontSize: 14,
          ...(isExportable ? { color: "#000" } : {}),
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      dataset: {
        source: data,
      },
      xAxis: {
        type: "category",
        axisTick: {
          show: true,
          alignWithLabel: true,
          length: 8,
        },
        axisLabel: {
          rotate: 90,
          showMaxLabel: true,
          width: 96,
          ...(isExportable
            ? { color: "#000", fontSize: 14 }
            : { overflow: "truncate" }),
        },
      },
      yAxis: {},
      series: series.every(({ color }) => !!color)
        ? series
        : series.map(({ bar }) => ({ type: "bar", barMaxWidth: 32 })),
    };

    const chart = echarts.init(el.current, null, dimension);

    if (setChartImage) {
      chart.on("finished", () => {
        var img = new Image();
        img.src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        setChartImage(img);
        chart.dispose();
      });
    }

    chart.setOption(option);

    function handleResize() {
      chart.resize(dimension);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, isExportable, audit, setChartImage]);

  return (
    <div className="chart-container">
      <div className="mw-100" ref={el} />
    </div>
  );
};

ReportConformityPrinciple.propTypes = {};

export default ReportConformityPrinciple;
