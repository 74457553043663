import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import DisplayReport from "./DisplayReport";
import OrganizationReports from "./OrganizationReports";

const Reports = () => {
  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <Routes>
        <Route index element={<OrganizationReports />} />
        <Route path=":reportId/*" element={<DisplayReport />} />
      </Routes>
    </>
  );
};

export default Reports;
