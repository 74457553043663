import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";
import Color from "colorjs.io";

const InherentRiskRanking = ({ children, values }) => {
  const [inherentRiskMap, setInherentRiskMap] = useState({});

  useEffect(() => {
    if (Array.isArray(children))
      children.forEach((child, index) => {
        const choices = child?.choices || [];

        const selectedIndex = choices.findIndex(({ id }) => {
          if (!values?.dynamic) return false;
          if (!values?.dynamic[child.id]) return false;
          return values?.dynamic[child.id]?.response === id;
        });

        setInherentRiskMap((prevState) => {
          return {
            ...prevState,
            [index]: choices.map(({ id, color, label, score }, index) => {
              return {
                id,
                label,
                score,
                color: color,
                seleted: selectedIndex === index,
              };
            }),
          };
        });
      });
  }, [children, values]);

  if (!Array.isArray(inherentRiskMap[0])) return <></>;
  if (!Array.isArray(inherentRiskMap[1])) return <></>;

  const yAxisItems = inherentRiskMap[0];
  const xAxisItems = inherentRiskMap[1].sort((a, b) => {
    if (a.score < b.score) return -1;
    if (a.score > b.score) return 1;
    return 0;
  });

  return (
    <>
      <h6 className="mt-4">Inherent Risk Ranking</h6>
      <Table bordered className="bg-white" size="sm">
        <tbody>
          {yAxisItems.map((yAxis, index) => {
            return (
              <tr key={`${yAxis?.id}`}>
                <td>
                  {yAxis.label}
                  <span className="text-muted float-end">{yAxis.score}</span>
                </td>
                {xAxisItems.map((xAxis) => {
                  const colorMix = yAxis.color.mix(
                    xAxis.color.toString(),
                    0.5,
                    { space: "lch", outputSpace: "srgb" }
                  );

                  return (
                    <td
                      key={`${yAxis?.id}-${xAxis?.id}`}
                      style={{
                        backgroundColor: colorMix,
                        "--bs-border-color": "#000",
                      }}
                      className={
                        xAxis?.seleted && yAxis?.seleted
                          ? "border border-2"
                          : "opacity-75"
                      }
                    ></td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            <td></td>
            {xAxisItems.map((xAxis, index) => {
              return (
                <td key={`${xAxis?.id}`} className="col-md-2">
                  <span className="text-muted">{xAxis.score}</span>
                  <br />
                  {xAxis.label}
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    </>
  );
};

InherentRiskRanking.propTypes = {
  values: PropTypes.any,
  children: PropTypes.array.isRequired,
};

export default InherentRiskRanking;
