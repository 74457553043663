import React, { useState } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { memoValidationSchema } from "features/assessment/schemas";
import useUpdateMemo from "features/assessment/services/useUpdateMemo";

const AuditMemoUpdate = ({ memo, onMemoUpdate }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { error, isLoading, updateMemo } = useUpdateMemo({
    memoId: memo?.id,
    onMemoUpdated: (data) => {
      handleClose();
      onMemoUpdate(data);
    },
  });

  return (
    <div>
      <Button
        title="update"
        variant="outline-primary"
        size="sm"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          edit
        </span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-bottom" closeButton>
          <Modal.Title>Update Notepad</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={memoValidationSchema}
          onSubmit={updateMemo}
          initialValues={{
            key: memo?.key,
            value: memo?.value,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">
                    Title <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.key}
                    name="key"
                    type="text"
                    placeholder="Title"
                    isValid={values.key && !errors.key}
                    isInvalid={!(values.key && !errors.key) && touched.key}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.key && touched.key ? (
                      <small>{errors.key}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 h-25">
                  <Form.Label className="mb-1">
                    Notes <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.value}
                    name="value"
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="Take a note..."
                    isValid={values.value && !errors.value}
                    isInvalid={
                      !(values.value && !errors.value) && touched.value
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.value && touched.value ? (
                      <small>{errors.value}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="me-2 px-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  className="px-3"
                  disabled={isLoading || !isValid}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Update Note
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

AuditMemoUpdate.propTypes = {
  memo: PropTypes.object.isRequired,
  onMemoUpdate: PropTypes.func.isRequired,
};

export default AuditMemoUpdate;
