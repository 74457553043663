import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const PrinciplesSpiderWeb = ({
  data,
  principles,
  overviewPage,
  rerender = true,
}) => {
  const el = useRef(null);

  useEffect(() => {
    const questionsList = [
      ...new Map(
        (data || []).map((item) => [item["questionTitle"], item])
      ).values(),
    ];

    const _data = principles?.reduce(
      (acc, curr) => {
        let maxRadarScore = 0;
        const sdata = [...(acc?.seriesData || [])];
        let currPrinciple = data?.filter(
          (val) => val?.principle?.principleId === curr?.principleId
        );

        if (currPrinciple?.length !== questionsList?.length && overviewPage) {
          currPrinciple = questionsList.map((question) => {
            const index = currPrinciple.findIndex(
              (principle) =>
                principle?.questionTitle === question?.questionTitle
            );
            if (index > -1) {
              return {
                ...currPrinciple[index],
              };
            } else {
              return {
                principleId: curr?.principleId,
                title: curr?.title,
                totalScore: "0.00",
                maxScore: "0.00",
                questionTitle: question?.questionTitle,
                flexibleQuestion: {
                  title: question?.questionTitle,
                },
              };
            }
          });
        }
        currPrinciple?.forEach((val) => {
          if (Number(val?.maxScore) > maxRadarScore) {
            maxRadarScore = Number(val?.maxScore);
          }
          const doesValueExist = acc?.seriesData?.findIndex(
            (series) =>
              (val?.flexibleQuestion?.id !== undefined &&
                series?.id === val?.flexibleQuestion?.id) ||
              series?.name === val?.flexibleQuestion?.title
          );
          if (doesValueExist > -1) {
            sdata?.[doesValueExist]?.value?.push(Number(val?.totalScore));
          } else {
            sdata.push({
              id: val?.flexibleQuestion?.id,
              name:
                val?.flexibleQuestion?.prompt || val?.flexibleQuestion?.title,
              value: [Number(val?.totalScore)],
            });
          }
        });

        return {
          indicator: [
            ...acc?.indicator,
            {
              name: curr?.title,
              max: maxRadarScore,
            },
          ],
          seriesData: [...sdata],
        };
      },
      {
        indicator: [],
        seriesData: [],
      }
    );

    const option = {
      legend: {
        show: false,
      },
      toolbox: {
        showTitle: false,
        feature: {
          saveAsImage: {
            name: `Scores By Principles`,
          },
        },
      },
      grid: {
        top: 50,
        bottom: 50,
        left: 0,
        right: 0,
        containLabel: false,
      },
      tooltip: {
        extraCssText: "width:500px; white-space:pre-wrap;",
      },
      radar: {
        radius: "65%",
        indicator: [
          ...new Map(
            (_data?.indicator || []).map((item) => [item["name"], item])
          ).values(),
        ],
        name: {
          formatter: function (value) {
            const words = value.split(" ");
            let formattedText = "";
            let wordCount = 0;

            for (const element of words) {
              const word = element;
              if (wordCount + word.length <= 30) {
                formattedText += word + " ";
                wordCount += word.length;
              } else {
                if (word.length > 30) {
                  // If the word itself is longer than the max words per line, break it
                  let currentIndex = 0;
                  while (currentIndex < word.length) {
                    formattedText +=
                      word.substring(currentIndex, currentIndex + 30) + "\n";
                    currentIndex += 30;
                  }
                  wordCount = word.substring(currentIndex - 30).length;
                } else {
                  formattedText = formattedText.trim();
                  formattedText += "...";
                  break;
                }
              }
            }

            return formattedText;
          },
        },
      },
      series: [
        {
          name: "Budget vs spending",
          type: "radar",
          data:
            _data?.seriesData?.map((val) => ({
              name: val.name,
              value: val.value,
            })) || [],
        },
      ],
    };
    const dimension = {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 1.5,
    };

    const chart = echarts.init(el.current, null, dimension);

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 1.5,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data, principles, rerender]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
};

export default PrinciplesSpiderWeb;
