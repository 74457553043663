import * as echarts from "echarts";
import { conformityLevelMap } from "features/assessment/components/Audit/helper";
import { colorMap } from "features/assessment/constants";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { compact, groupBy } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef } from "react";
import "./ReportConformityLevel.scss";

const CHART_HEIGHT = 270;

const ReportConformityLevel = ({
  setChartImage,
  tooltip,
  isExportable = false,
}) => {
  const el = useRef(null);
  const { audit } = useContext(AuditContext);

  useEffect(() => {
    const observations = audit?.questions.flatMap(
      (question) => question.observations || []
    );
    const conformityLevel = groupBy(observations, (observation) => {
      if (observation.isInapplicable) return "";
      if (observation.conformityLevel === null) return "";
      return conformityLevelMap(
        observation.conformityLevel,
        audit.protocol.conformity
      );
    });

    delete conformityLevel[""];

    const mapped = Object.keys(conformityLevel).map((key) => {
      return { name: key, value: conformityLevel[key].length };
    });

    const data = audit.protocol.conformity
      .filter(({ value = "" }) => value.trim().toLowerCase() !== "n/a")
      .map(({ value: name }) => {
        const found = mapped.find((m) => m.name === name);
        const color = Object.values(colorMap).find(({ label }) => {
          const _name = !!found ? found?.name : name;
          return _name.toLowerCase().includes(label?.toLowerCase());
        });

        return !!found
          ? { ...found, color: color?.color || "" }
          : { name: name, value: 0, color: color?.color || "" };
      });

    const chartColors = compact(data.map(({ color }) => color));
    const sum = data.reduce((prev, current) => prev + current.value, 0);

    const dimension = {
      width: isExportable ? 800 : el.current.offsetWidth,
      height: isExportable ? 500 : CHART_HEIGHT,
    };

    const option = {
      tooltip: {
        trigger: "item",
        confine: true,
      },
      ...(!isExportable
        ? {
            legend: {
              type: "scroll",
              orient: "horizontal",
              icon: "circle",
              textStyle: {
                fontSize: 14,
              },
            },
          }
        : {}),
      title: {
        show: !isExportable,
        textStyle: {
          color: "grey",
          fontSize: 15,
          fontWeight: "lighter",
        },
        text: `Total: ${sum}`,
        left: "center",
        top: "bottom",
      },
      series: {
        name: tooltip,
        type: "pie",
        radius: "50%",
        label: {
          show: true,
          ...(isExportable
            ? {
                alignTo: "none",
                fontSize: 14,
                color: "#000",
                lineHeight: 2,
                padding: 20,
              }
            : {}),
          formatter: "{b}:{c} ({d}%)",
        },
        percentPrecision: 1,
        data: data,
        color: data?.length === chartColors?.length ? chartColors : [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    };

    if (!sum) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
      option.series = null;
    }

    const chart = echarts.init(el.current, null, dimension);

    if (setChartImage) {
      chart.on("finished", () => {
        var img = new Image(800, 600);
        img.src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        setChartImage(img);
        chart.dispose();
      });
    }

    chart.setOption(option);

    function handleResize() {
      chart.resize(dimension);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, isExportable, audit, tooltip, setChartImage]);

  return (
    <>
      <div className="chart-container">
        <div className="w-100" ref={el} />
      </div>
    </>
  );
};

ReportConformityLevel.propTypes = {
  audit: PropTypes.object.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default ReportConformityLevel;
