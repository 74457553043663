import React, { useContext, useRef, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { get } from "utils/DeApi";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";

import { OrganizationContext } from "contexts/OrganizationProvider";

const DownloadArcGisCsv = ({ audit }) => {
  const organization = useContext(OrganizationContext);
  const isAdminOrOwner =
    organization?.invitation?.role === "Admin" ||
    organization?.invitation?.role === "Owner";

  const subscribedPromises = useRef([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const downloadArcGisCsv = () => {
    setError(null);
    setIsLoading(true);
    const { auditId, protocolId } = audit;

    const downloadArcGisPromise = get(
      `/organizations/${organization?.id}/geotags-csv`,
      {
        params: {
          protocol_id: protocolId,
          assessments: [auditId],
        },
      }
    );

    downloadArcGisPromise.promise
      .then((resp) => {
        setIsLoading(false);
        const fileName = `${organization?.name}-ArcGIS.csv`;

        let type = "data:text/csv;charset=UTF-8,%EF%BB%BF;";
        let CSVBlob = new Blob([resp], { type: type });

        saveAs(CSVBlob, fileName);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(downloadArcGisPromise);
  };

  !isLoading && error && console.log(error);

  if (!isAdminOrOwner) return <></>;

  return (
    <Button
      size="sm"
      variant="outline-primary"
      className="me-2"
      onClick={() => downloadArcGisCsv()}
    >
      <span translate="no" className="material-symbols-outlined md-16 me-1">
        download
      </span>
      {isLoading && (
        <Spinner
          className="me-2"
          animation="border"
          size="sm"
          variant="light"
        />
      )}{" "}
      Export
    </Button>
  );
};

DownloadArcGisCsv.propTypes = {
  audit: PropTypes.object.isRequired,
};

export default DownloadArcGisCsv;
