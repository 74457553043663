import { OrganizationsContext } from "contexts/OrganizationProvider";
import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

const RedirectIfAuthenticated = ({ isAuthenticated = {} }) => {
  const location = useLocation();
  const { prevSelectedOrgId = "" } = isAuthenticated;
  const organizations = useContext(OrganizationsContext);
  const org = organizations.find((o) => o.id === prevSelectedOrgId);

  const redirectPath = prevSelectedOrgId
    ? `/organizations/${prevSelectedOrgId}`
    : "/";

  return (
    <Navigate
      to={{
        pathname: !org ? "/" : redirectPath,
        state: { from: location },
      }}
    />
  );
};
export default RedirectIfAuthenticated;
