import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InfoTooltip = ({ id, text }) => {
  if (!text) return <></>;
  return (
    <OverlayTrigger
      placement="right"
      overlay={(props) => (
        <Tooltip id={`tooltip-${id}`} {...props}>
          {text}
        </Tooltip>
      )}
    >
      <span
        translate="no"
        className="material-symbols-outlined text-muted md-18 ms-1"
      >
        info
      </span>
    </OverlayTrigger>
  );
};

export default InfoTooltip;
