import { useEffect, useRef, useState } from "react";
import { put, post } from "utils/DeApi";
import { FlexibleFields } from "../constants";
import { createUniqArray } from "utils/ArrayUtils";
import { formatDatetimeToMSQL } from "utils/DateUtils";
import { useToast } from "hooks";

const {
  TEXT_FIELD,
  RADIO_FIELD,
  NUMERIC_FIELD,
  CHECKBOX_FIELD,
  DATETIME_FIELD,
  COMPOUND_RADIO,
} = FlexibleFields;

const observationPromise = (formData = {}) => {
  if (Array.isArray(formData.notes) && formData.notes.length === 0) {
    formData.notes = "";
  }

  const subscribedPromises = [];

  const observationPromise = formData.observationId
    ? put(`observations/${formData.observationId}`, formData)
    : post(`observations`, formData);

  subscribedPromises.push(observationPromise);

  return {
    promise: observationPromise.promise,
    subscribed: subscribedPromises,
  };
};

const responsePromises = (q = {}, options) => {
  const subscribedPromises = [];

  const responsePromise = Object.keys(q).flatMap((key) => {
    const { type, response = "" } = q[key] || {};

    const payload = {
      notes: type === TEXT_FIELD || type === NUMERIC_FIELD ? response : "",
      answer_ids: type === CHECKBOX_FIELD ? createUniqArray(response) : [],
      answer_id:
        type === COMPOUND_RADIO || type === RADIO_FIELD ? response : "",
      response_datetime:
        type === DATETIME_FIELD ? formatDatetimeToMSQL(response) : "",
      question_id: options?.questionId, //required
      flexible_question_id: key, //required
      is_inapplicable: options?.isInapplicable,
      observationId: options?.observationId,
      parent_id: null,
    };

    const observationPromise = post(
      `/audits/${options?.auditId}/observations/${options?.observationId}/responses`,
      payload
    );

    subscribedPromises.push(observationPromise);
    return observationPromise.promise;
  });

  return {
    promises: responsePromise,
    subscribed: subscribedPromises,
  };
};

const useCreateOrUpdateObservation = ({
  onFinally,
  onResponsesSuccess,
  onObservationSuccess,
}) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = (formData = {}) => {
    setIsLoading(true);
    let closureObservation = {};
    const { promise, subscribed } = observationPromise(formData);
    subscribedPromises.current = [...subscribedPromises.current, ...subscribed];
    promise
      .then(({ data: observation }) => {
        closureObservation = observation;
        const { promises, subscribed } = responsePromises(
          formData?.dynamic,
          observation
        );

        if (typeof onObservationSuccess === "function") {
          onObservationSuccess(observation);
          toast.success("Success", "Observation saved successfully");
        }
        subscribedPromises.current = [
          ...subscribedPromises.current,
          ...subscribed,
        ];

        return Promise.allSettled(promises);
      })
      .then((reponses) => {
        const flexibleQuestionResponses = reponses
          .filter(({ status }) => status === "fulfilled")
          .flatMap(({ value }) => value.data || []);

        if (typeof onResponsesSuccess === "function") {
          onResponsesSuccess(flexibleQuestionResponses, closureObservation);
        }

        toast.success("Success", "Reponses saved successfully");
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
        if (typeof onFinally === "function") {
          onFinally();
        }
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, handleOnSubmit };
};

export default useCreateOrUpdateObservation;
