import React, { useRef, useState } from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Modal, Spinner } from "react-bootstrap";

import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";

const CommentDelete = ({ commentId, observationId, onCommentDeleted }) => {
  const { isCertifier } = useRoles();
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteComment = (values) => {
    setError(null);
    setIsLoading(true);

    const commentPromise = destroy(
      `/observations/${observationId}/comments/${commentId}`
    );

    commentPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        onCommentDeleted(commentId);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(commentPromise);
  };

  if (!isCertifier) return <></>;

  return (
    <>
      <Button variant="outline-danger" size="sm" onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18">
          delete
        </span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Comment</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to delete this comment?</p>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={deleteComment}
            size="sm"
            variant="danger"
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CommentDelete.propTypes = {
  commentId: PropTypes.string.isRequired,
  observationId: PropTypes.string.isRequired,
  onCommentDeleted: PropTypes.func.isRequired,
};

export default CommentDelete;
