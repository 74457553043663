import React from "react";
import { Table } from "react-bootstrap";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";

const COLORS = [
  "rgba(199,89,18,255)",
  "rgba(255,0,0,255)",
  "rgba(191,143,0,255)",
  "rgba(255,191,0,255)",
  "rgba(131,60,11,255)",
  "rgba(0,112,192,255)",
  "rgba(82,82,82,255)",
];

const dynamicTableHeaderColor = (index) =>
  COLORS[((index % COLORS.length) + COLORS.length) % COLORS.length];

const DynamicQuestionSummaryTable = ({ summaryTable }) => {
  const header = uniqBy(
    summaryTable?.dynamicQuestions?.summary
      ?.map((principle) => principle?.scores?.map(({ value }) => value))
      .flat()
  );

  return (
    summaryTable?.dynamicQuestions?.summary?.length > 0 && (
      <div>
        <h2 className="my-1 py-1 text-uppercase text-primary border-bottom border-primary">
          Summary Table
        </h2>
        <Table
          responsive
          bordered
          className="py-1 border-dark table-sm dynamic-summary-table"
        >
          <thead>
            <tr>
              <th className="pe-0 text-bg-dark">Principles</th>

              {Array.isArray(header) &&
                header.map((dynamic, index) => (
                  <th
                    className="p-0 text-white"
                    key={index}
                    style={{ backgroundColor: dynamicTableHeaderColor(index) }}
                  >
                    {dynamic}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-light">
              <td>
                <strong>Total</strong>
              </td>
              {summaryTable?.dynamicQuestions?.total?.map(({ total }) => (
                <td>{total}</td>
              ))}
            </tr>
            {summaryTable?.dynamicQuestions?.summary?.map((principle) => (
              <tr key={principle?.principleId}>
                <td className="py-0">{principle?.title}</td>
                {principle?.scores?.map(({ id, total }) => (
                  <td key={id}>{total}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  );
};
DynamicQuestionSummaryTable.propTypes = {
  summaryTable: PropTypes.object.isRequired,
};

export default DynamicQuestionSummaryTable;
