import React from "react";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";

const ActionItemActionType = ({ type, subjectType }) => {
  const logName = () => {
    if (subjectType === "comment") return "Comment";
    return "Status";
  };

  if (type === "created") {
    return (
      <Badge className="bg-primary bg-opacity-10 text-primary">
        {logName()} - Created
      </Badge>
    );
  }
  if (type === "updated") {
    return (
      <Badge className="bg-warning bg-opacity-10 text-warning">
        {logName()} - Updated
      </Badge>
    );
  }
  if (type === "deleted") {
    return (
      <Badge className="bg-danger bg-opacity-10 text-danger">
        {logName()} - Deleted
      </Badge>
    );
  }
  return "-";
};

ActionItemActionType.propTypes = {
  type: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
};

export default ActionItemActionType;
