import { useContext, useRef, useState } from "react";

import * as yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { groupBy } from "lodash";
import { useToast } from "hooks";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";

import { post } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import FieldRadio from "./FieldRadio";
import FieldNumeric from "./FieldNumeric";
import NestedFields from "./NestedFields";
import FieldCheckbox from "./FieldCheckbox";
import FieldDatetime from "./FieldDatetime";
import FieldTextarea from "./FieldTextarea";
import {
  CHECKBOX_FIELD,
  DATETIME_FIELD,
  NUMERIC_FIELD,
  RADIO_FIELD,
  TEXT_FIELD,
} from "./constants";
import { useRoles } from "hooks";

const AuditMetadata = () => {
  const { isCertifier } = useRoles();
  const toast = useToast();
  const { audit, setAudit } = useContext(AuditContext);
  const subscribedPromises = useRef([]);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isExpandingError, setIsExpandingError] = useState("");

  const onlineSync = (formData = {}) => {
    setIsExpanding(true);
    setIsExpandingError("");

    const q = formData?.metadata || {};

    const promiseOne =
      Object.keys(q)
        .map((key) => {
          const { type, response } = q[key] || {};
          const metadataPromise = post(
            `/audits/${audit?.auditId}/metadata-responses`,
            {
              audit_metadata_id: key,
              response_text: type === TEXT_FIELD ? response : "",
              response_numeric: type === NUMERIC_FIELD ? response : "",
              audit_metadata_choice_id: type === RADIO_FIELD ? response : "",
              audit_metadata_choice_ids:
                type === CHECKBOX_FIELD ? response : [],
              response_datetime:
                type === DATETIME_FIELD
                  ? moment(response).format("YYYY-MM-DD HH:mm:ss")
                  : "",
            }
          );
          subscribedPromises.current.push(metadataPromise);
          return metadataPromise.promise;
        })
        .flat() || [];

    Promise.all(promiseOne)
      .then((reponses) => {
        toast.success("Success", "Scope & Metadata saved successfully");
        setAudit((prevAudit) => {
          return {
            ...prevAudit,
            metadata: reponses.map(({ data }) => data || []).flat(),
          };
        });
      })
      .catch((error) => {
        !error.isCanceled && setIsExpandingError(error);
      })
      .finally(() => {
        setIsExpanding(false);
      });
  };

  const metadataMapping = () => {
    const metadata = audit?.metadata;
    if (!Array.isArray(metadata) || !metadata?.length) return {};

    const groupByMetaId = groupBy(metadata, "auditMetadata.id");

    return Object.keys(groupByMetaId).reduce((accumulator, key) => {
      if (!Array.isArray(groupByMetaId[key]) && !groupByMetaId[key].length)
        return accumulator;

      const response = groupByMetaId[key][0];
      const type = response?.auditMetadata?.type;

      if (type === TEXT_FIELD)
        return {
          ...accumulator,
          [key]: {
            type: TEXT_FIELD,
            response: response?.responseText,
          },
        };
      if (type === DATETIME_FIELD) {
        return {
          ...accumulator,
          [key]: {
            type: DATETIME_FIELD,
            response: response?.responseDatetime || "",
          },
        };
      }
      if (type === NUMERIC_FIELD)
        return {
          ...accumulator,
          [key]: {
            type: NUMERIC_FIELD,
            response: response?.responseNumeric,
          },
        };
      if (type === RADIO_FIELD)
        return {
          ...accumulator,
          [key]: {
            type: RADIO_FIELD,
            response: response?.auditMetadataChoice?.id,
          },
        };

      if (type === CHECKBOX_FIELD) {
        return {
          ...accumulator,
          [key]: {
            type: CHECKBOX_FIELD,
            response:
              groupByMetaId[key].map(
                ({ auditMetadataChoice }) => auditMetadataChoice?.id
              ) || [],
          },
        };
      }

      return accumulator;
    }, {});
  };
  const filteredQuestions = audit?.protocol?.assessmentLevelFields.filter(
    ({ type, choices }) => {
      if (
        (type === RADIO_FIELD && !choices.length) ||
        (type === CHECKBOX_FIELD && !choices.length)
      )
        return false;

      return true;
    }
  );
  const validationSchema = yup.object().shape({
    metadata: yup.object().shape(
      audit?.protocol?.assessmentLevelFields.reduce((key, acc) => {
        if (acc.type === TEXT_FIELD)
          return {
            ...key,
            [acc.id]: yup.object().shape({
              type: yup.string().required("This is a required field"),
              response: yup.string().required("This is a required field"),
            }),
          };
        if (acc.type === NUMERIC_FIELD)
          return {
            ...key,
            [acc.id]: yup.object().shape({
              type: yup.string().required("This is a required field"),
              response: yup.number().required("This is a required field"),
            }),
          };
        return {
          ...key,
        };
      }, {})
    ),
  });
  return (
    <Col xs={12} lg={8} md={12} className="mb-3">
      <Formik
        enableReinitialize
        initialValues={{
          metadata: metadataMapping() || {},
        }}
        onSubmit={(values) => {
          onlineSync(values);
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <fieldset disabled={isCertifier}>
              <Row>
                <Col xs={12} lg={12} md={12} className="mb-3 mt-2">
                  <Card>
                    <Card.Header>
                      <Card.Title className="my-2">Scope & Metadata</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {Array.isArray(audit?.protocol?.assessmentLevelFields) &&
                        filteredQuestions
                          .filter((q) => {
                            if (
                              !!q.triggerAuditMetadataChoiceId ||
                              !!q.triggerAuditMetadataId
                            )
                              return false;
                            return true;
                          })
                          .map((q) => {
                            const value = values.metadata[q.id];
                            const nestedQuestions =
                              value?.type === RADIO_FIELD
                                ? filteredQuestions?.filter(
                                    ({
                                      triggerAuditMetadataChoiceId,
                                      triggerAuditMetadataId,
                                    }) => {
                                      if (
                                        triggerAuditMetadataChoiceId !==
                                          value?.response ||
                                        triggerAuditMetadataId !== q.id
                                      )
                                        return false;

                                      return true;
                                    }
                                  )
                                : [];
                            return (
                              <Form.Group
                                key={q.id}
                                controlId={q.id}
                                className="my-3"
                              >
                                <Form.Label className="w-100">
                                  {q.prompt}
                                </Form.Label>
                                <FieldRadio question={q} value={value} />
                                {value?.type === RADIO_FIELD &&
                                nestedQuestions.length ? (
                                  <NestedFields
                                    values={values}
                                    questions={nestedQuestions}
                                  />
                                ) : null}
                                <FieldNumeric question={q} value={value} />
                                <FieldCheckbox question={q} value={value} />
                                <FieldTextarea question={q} value={value} />
                                <FieldDatetime question={q} value={value} />
                              </Form.Group>
                            );
                          })}

                      {!isExpanding && isExpandingError && (
                        <ErrorHandler error={isExpandingError} />
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        size="sm"
                        type="submit"
                        className="float-end"
                        disabled={isExpanding || !isValid}
                      >
                        {isExpanding && (
                          <Spinner
                            className="me-2"
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        )}{" "}
                        Save Metadata
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </fieldset>
          </Form>
        )}
      </Formik>
    </Col>
  );
};

export default AuditMetadata;
