import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { showCorrectiveAction } from "features/assessment/components/Audit/helper";

import FieldClearButton from "../FieldClearButton";
import { useContext } from "react";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

const FieldCorrectiveAction = ({ isDisabled }) => {
  const { audit } = useContext(AuditContext);
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();

  const { conformity, questionOptions } = audit?.protocol;
  const [, , , , , , correctiveActionLabel] = questionOptions || [];
  const _showCorrectiveAction = showCorrectiveAction(
    conformity,
    questionOptions
  );

  if (!_showCorrectiveAction) return <></>;
  if (parseInt(values.compliance) !== 0) return <></>;

  const clearSelection = () => {
    setFieldValue("correctiveAction", "");
  };

  return (
    <Form.Group controlId="corrective-action" className="my-3">
      <Form.Label className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <strong>
              {correctiveActionLabel?.name || "Corrective Action"}
            </strong>
            <RequiredAsterisk />
          </div>
          <FieldClearButton
            handleOnClick={clearSelection}
            isDisabled={isDisabled}
          />
        </div>
      </Form.Label>
      <Form.Control
        rows={3}
        as="textarea"
        name="correctiveAction"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.correctiveAction}
        disabled={values.isInapplicable || isDisabled}
        placeholder="Enter your answer here"
      />
    </Form.Group>
  );
};

FieldCorrectiveAction.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldCorrectiveAction;
