import moment from "moment";
import React from "react";

function GridView({
  id = "",
  prompt = "",
  properties = {},
  choices = [],
  choice = {},
  notes = "",
  responseDatetime = "",
  matchResponse = [],
}) {
  if (properties?.fieldType === "checkbox") {
    choices = matchResponse.map((elem) => elem?.choice);
  } else {
    notes = matchResponse[0]?.notes ?? "";
    responseDatetime = matchResponse[0]?.responseDatetime ?? "";
    choice = matchResponse[0]?.choice ?? {};
  }

  if (properties?.fieldType === "text" && notes) {
    return (
      <p key={id} className="mb-0">
        <span className="w-50 fw-bold">{prompt}</span>
        <p className="mb-0">
          <span className="text-capitalize-first">{notes}</span>
        </p>
      </p>
    );
  }
  if (properties?.fieldType === "datetime" && responseDatetime) {
    const datetime = responseDatetime
      ? moment.utc(responseDatetime).format("MMMM D, yyyy h:mm a")
      : "";
    return (
      <p className="mb-0" key={id}>
        <span className="w-50 fw-bold">{prompt}</span>
        <p className="mb-0">
          <span>{datetime}</span>
        </p>
      </p>
    );
  }
  if (properties?.fieldType === "numeric" && notes) {
    return (
      <p className="mb-0" key={id}>
        <span className="w-50 fw-bold">{prompt}</span>
        <p className="text-capitalize-first text-break mb-0">{notes}</p>
      </p>
    );
  }

  if (properties?.fieldType === "radio" && choice?.label) {
    return (
      <p className="mb-0" key={id}>
        <span className="w-50 fw-bold">{prompt}</span>
        <p className="">{choice?.label}</p>
      </p>
    );
  }

  if (properties?.fieldType === "checkbox" && !!choices?.length) {
    return (
      <p className="mb-0" key={id}>
        <span className="w-50 fw-bold">{prompt}:</span>
        <span>
          {!!choices?.length &&
            choices
              ?.sort((a, b) => a?.order - b?.order)
              ?.map(({ label = "" }, key) => (
                <span
                  className="d-flex flex-column mb-1 text-capitalize-first ms-2"
                  key={key}
                >{`- ${label} `}</span>
              ))}
        </span>
      </p>
    );
  }
}

export default GridView;
