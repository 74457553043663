import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { get } from "utils/DeApi";
import { Spinner, Table } from "react-bootstrap";
import ErrorHandler from "components/ui/ErrorHandler";
import "./Organization.css";
import { Link } from "react-router-dom";
import { OrganizationDispatchContext } from "contexts/OrganizationProvider";

const Organization = ({ organization = {} }) => {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [facilityTags, setFacilityTags] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const setOrganization = useContext(OrganizationDispatchContext);
  const organizationId = organization?.id;

  useEffect(() => {
    const fetchFacilities = () => {
      setIsLoading(true);
      const facilitiesPromise = get(
        `organizations/${organization?.id}/facilities`,
        {
          params: {
            perPage: 500,
            include: "labels",
          },
        }
      );

      facilitiesPromise.promise
        .then(({ data: facilities }) => {
          if (Array.isArray(facilities)) {
            // list the facilities
            const tmpFacilties = facilities.filter((tmpFacility) => {
              return tmpFacility.labels.length;
            });

            const tmpFacilityTags = tmpFacilties
              .map(({ labels }) => labels)
              .flat();
            const groupByTagId = Object.groupBy(
              tmpFacilityTags,
              ({ organizationLabelId }) => organizationLabelId
            );

            setFacilityTags(groupByTagId);
            setFacilities(tmpFacilties);
          }
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });

      subscribedPromises.current.push(facilitiesPromise);
    };

    fetchFacilities();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization]);

  const handleRowClick = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const facilityTagList = Object.values(facilityTags).map((tags) => {
    const { color = "#dddddd" } = tags[0] || {};
    return (
      <div
        key={crypto.randomUUID()}
        className="p-1 mx-2 my-1 text-center border-0 rounded"
        style={{
          backgroundColor: `${color}30`,
        }}
      >
        <small>
          {tags[0]?.name} ({tags?.length})
        </small>
      </div>
    );
  });

  return (
    <React.Fragment key={organizationId}>
      <tr
        className="align-middle"
        onClick={() => handleRowClick(organizationId)}
      >
        <td className="cursor-pointer">
          {selectedRow === organizationId ? (
            <span className="material-symbols-outlined md-20 text-muted">
              expand_less
            </span>
          ) : (
            <span className="material-symbols-outlined md-20 text-muted">
              expand_more
            </span>
          )}
        </td>
        <td className="table-first-child bg-white">
          <Link
            size="sm"
            className="text-decoration-none"
            to={`/organizations/${organizationId}`}
          >
            {organization?.name}
          </Link>
        </td>
        <td>{organization?.invitation?.role}</td>
        <td>
          {organization?.invitation?.subscriberJoinedAt &&
            new Date(
              organization?.invitation?.subscriberJoinedAt
            ).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
        </td>

        <td>
          <div className="d-flex flex-wrap">
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                variant="primary"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : error ? (
              <ErrorHandler
                error={error}
                header={error?.status === 404 && "Organization not found"}
                message={
                  error?.status === 404 && (
                    <span>
                      "Organization not found (possibly deleted). Please refresh
                      the page."
                    </span>
                  )
                }
              />
            ) : (
              <span>{facilityTagList}</span>
            )}
          </div>
        </td>
      </tr>
      {selectedRow === organizationId && facilities.length > 0 && (
        <tr>
          <td colSpan={5}>
            <Table className="table table-borderless">
              <thead>
                <tr>
                  <th>Entities</th>
                </tr>
              </thead>
              <tbody>
                {facilities.map((facility) => (
                  <tr key={facility?.facilityId}>
                    <td>
                      <p className="p-0 m-0 mb-2">
                        <Link
                          to={`/facilities/${facility?.facilityId}`}
                          size="sm"
                          className="text-decoration-none"
                          variant="secondary"
                          onClick={() => setOrganization(organization)}
                        >
                          {facility?.name}
                        </Link>
                      </p>
                    </td>
                    <td>
                      {Array.isArray(facility.labels) &&
                        facility.labels.map(
                          ({ organizationLabelId, name, color }) => (
                            <span
                              key={organizationLabelId}
                              className="p-2 mx-2 text-center border-0 rounded"
                              style={{
                                borderWidth: 1,
                                borderColor: color,
                                backgroundColor: `${color}30`,
                                borderStyle: "solid",
                              }}
                            >
                              {name}
                            </span>
                          )
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

Organization.propTypes = {
  organization: PropTypes.object,
};

export default Organization;
