import BreadCrumbs from "components/ui/BreadCrumbs";
import { createBreadcrumb } from "features/search/utils/breadcrumpUtil";
import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchResult from "../SearchResult";

const SearchDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("q") || "";

  const [isLoading, setIsLoading] = useState(false);
  const [inputTerm, setInputTerm] = useState("");
  const [page, setPage] = useState(1);

  const handleSearch = (event) => {
    event.preventDefault();
    if (inputTerm) {
      setIsLoading(true);
      navigate(`/search?q=${encodeURIComponent(inputTerm)}`);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      setInputTerm(decodeURIComponent(searchTerm));
      setPage(1);
    } else {
      setInputTerm("");
    }
  }, [searchTerm]);

  return (
    <Row>
      <Col className="my-3">
        <div className="mb-3">
          <BreadCrumbs breadcrumbs={createBreadcrumb()} />
        </div>
        <h1>Search In Assessments</h1>
        <div className="mt-3">
          <small>Please provide a keyword to search in assessments.</small>
        </div>
        <Form onSubmit={handleSearch}>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Search"
              aria-label="Search"
              value={inputTerm}
              onChange={(e) => setInputTerm(e.target.value)}
              disabled={isLoading}
            />
            <Button
              variant="outline-primary"
              id="searchContent"
              disabled={isLoading}
              onClick={handleSearch}
            >
              {isLoading && (
                <Spinner className="me-1" animation="border" size="sm" />
              )}
              <span>Search</span>
            </Button>
          </InputGroup>
        </Form>

        {searchTerm && (
          <SearchResult
            searchTerm={decodeURIComponent(searchTerm)}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setPage={setPage}
            page={page}
          />
        )}
      </Col>
    </Row>
  );
};

SearchDetails.propTypes = {};

export default SearchDetails;
