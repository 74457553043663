import React from "react";

import PropTypes from "prop-types";
import { Alert, Col } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";
import EvidenceFileUpload from "../EvidenceFileUpload";
import EvidenceFileDelete from "../EvidenceFileDelete";

const ActionItemUpdateEvidence = ({
  error,
  actionItem,
  onActionItemUpdated,
}) => {
  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex-fill">
          <h5 className="pt-2">
            Files{" "}
            <span className="ms-2 bg-light">
              {actionItem?.evidence?.length}
            </span>
          </h5>
        </div>
        <div>
          <EvidenceFileUpload
            actionItem={actionItem}
            onEvidenceFileUploaded={onActionItemUpdated}
          />
        </div>
      </div>
      <hr />
      {actionItem?.evidence?.length ? (
        <>
          {actionItem?.evidence.map((f) => {
            const fileURL = f.url || "";
            const fileEXT = getURLFileExtension(fileURL);
            return (
              <Col
                xs={12}
                key={f.id}
                className="mb-1 mt-1 d-flex flex-row align-items-center"
              >
                <div className="me-2">
                  <a href={fileURL} target="_blank" rel="noreferrer">
                    <span
                      className={`fiv-sqo fiv-size-md fiv-icon-${fileEXT}`}
                    ></span>
                  </a>
                </div>
                <div className="flex-fill">
                  <h6 className="mt-1">
                    <a
                      href={f.url}
                      target="_blank"
                      rel="noreferrer"
                      className=" text-trancate"
                    >
                      {f.fileName}
                    </a>
                  </h6>
                </div>
                <div className="me-2">
                  {new Date(f?.createdAt).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </div>
                <div>
                  <EvidenceFileDelete
                    file={f}
                    actionItemId={actionItem?.actionItemId}
                    onEvidenceFileDeleted={(file) => {
                      onActionItemUpdated({
                        ...actionItem,
                        evidence: actionItem?.evidence.filter(
                          ({ id }) => id !== file?.id
                        ),
                      });
                    }}
                  />
                </div>
              </Col>
            );
          })}
        </>
      ) : (
        <Alert variant="info" className={`mb-3 d-flex flex-row`}>
          <div className="me-3">
            <span className="material-symbols-outlined md-18 text-opacity-75">
              info
            </span>
          </div>
          <div>
            <h5 className="mb-1">
              <small>No Files Found</small>
            </h5>
            <p className="mb-1">
              <small> Files will appear here once added </small>
            </p>
          </div>
        </Alert>
      )}
      {error && error?.status === 403 && (
        <Alert variant="info" className={`my-3 d-flex flex-row `}>
          <div className="me-3">
            <span translate="no" className="material-symbols-outlined md-18">
              lock
            </span>
          </div>
          <div className="flex-fill">
            <h5 className="mb-1">
              <small>Request For Access</small>
            </h5>
            <p>Only the action item creator and assignees can upload files</p>
          </div>
        </Alert>
      )}
      {error && error?.status !== 403 && <ErrorHandler error={error} />}
    </>
  );
};

ActionItemUpdateEvidence.propTypes = {
  actionItem: PropTypes.object.isRequired,
  onActionItemUpdated: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default ActionItemUpdateEvidence;
