import React, { useState } from "react";

import PropTypes from "prop-types";
import { Button, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";
import useDeleteAuditFile from "features/assessment/services/useDeleteAuditFile";

const AuditFilesDelete = ({ cypressId, file, onFileDelete }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const fileURL = file?.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  const { error, isLoading, deleteAuditFile } = useDeleteAuditFile({
    file,
    onFileDelete: (file) => {
      handleClose();
      onFileDelete(file);
    },
  });

  return (
    <>
      <Button
        title="delete"
        variant="outline-danger"
        size="sm"
        className="ms-2"
        onClick={handleShow}
        data-cy={cypressId}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete this file?</h5>
          <p className="my-3 text-muted">
            <span
              className={`fiv-sqo fiv-size-sm fiv-icon-${fileEXT} me-2`}
            ></span>
            <small>{`${
              file?.name ? file?.name : `${file?.fileId}.${fileEXT}`
            }`}</small>
          </p>
          {error && show && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={deleteAuditFile}
            size="sm"
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
AuditFilesDelete.propTypes = {
  file: PropTypes.object.isRequired,
  onFileDelete: PropTypes.func.isRequired,
  cypressId: PropTypes.string.isRequired,
};

export default AuditFilesDelete;
