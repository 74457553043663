const breadcrumb = (audit) => {
  return [
    { name: "Dashboard", link: "/" },
    {
      name: audit?.facility?.name,
      link: `/facilities/${audit?.facility?.facilityId}`,
    },
    {
      name: audit.name,
      active: false,
    },
  ];
};

const breadcrumbFromParam = (p) => {
  if (p?.includes("files")) return "Files & Photos";
  if (p?.includes("signatures")) return "Signatures";
  if (p?.includes("members")) return "Assessment Team";
  if (p?.includes("metadata")) return "Scope & Metadata";
  if (p?.includes("pre-qualification")) return "Applicability Screening";
  if (p?.includes("corrective-actions")) return "Corrective Actions";
  if (p?.includes("reports")) return "Report";

  return "Assessment Question";
};

const filesBreadcrumb = (audit, param) => {
  return [
    { name: "Dashboard", link: "/" },
    {
      name: audit?.facility?.name,
      link: `/facilities/${audit?.facility?.facilityId}`,
    },
    {
      name: audit.name,
      link: `/audits/${audit?.auditId}`,
    },
    {
      name: breadcrumbFromParam(param),
      active: false,
    },
  ];
};

export { breadcrumb, filesBreadcrumb };
