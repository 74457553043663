import { useContext, useEffect, useRef, useState } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { get } from "utils/DeApi";

export function useFetchMembers() {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    const fetchMembers = () => {
      setError(null);
      setIsLoading(true);
      const membersPromise = get(`/subscribers`, {
        params: {
          organization_id: organization?.id,
        },
      });
      membersPromise.promise
        .then(({ data: members }) => setMembers(members))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(membersPromise);
    };

    fetchMembers();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization]);

  return { error, isLoading, members };
}
