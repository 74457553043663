import { useContext, useState } from "react";

import { useRoles } from "hooks";
import { Button, Dropdown, Form, Modal, Spinner } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { useAuditMapping } from "features/assessment/hooks";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import {
  useFetchCertifierComments,
  useFetchObservationMemos,
} from "features/assessment/services";
import { useDeleteObservation } from "features/assessment/services";

const DeleteObservation = ({ isDisabled, observation }) => {
  const { isCertifier, isContributor } = useRoles();

  const { audit } = useContext(AuditContext);

  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { observationDeleteMapping } = useAuditMapping();

  const {
    error: isExpandingError,
    isLoading: isExpanding,
    memos,
  } = useFetchObservationMemos({
    observationId: observation?.observationId,
  });

  const {
    error: isFetchError,
    isLoading: isFetching,
    comments: certifierComments,
  } = useFetchCertifierComments({
    observationId: observation?.observationId,
    fetchByDefault: audit?.protocol?.hasCertificationComments,
  });

  const { error, isLoading, deleteObservation } = useDeleteObservation({
    observationId: observation?.observationId,
    onObservationDeleted: () => {
      handleClose();
      observationDeleteMapping(observation);
    },
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setIsChecked(false);
  };

  const hasRelationships =
    memos?.length ||
    observation?.commentsCount ||
    certifierComments?.length ||
    observation?.geoTags?.length ||
    observation?.attachments?.length;

  if (isCertifier || isContributor) return <></>;

  return (
    <div
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onFocus={(e) => e.stopPropagation()}
      onMouseOver={(e) => e.stopPropagation()}
    >
      <Dropdown.Item
        onClick={handleShow}
        disabled={isDisabled}
        className="text-danger"
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>{" "}
        Delete
      </Dropdown.Item>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">Delete Observation</h2>
          {hasRelationships ? (
            <>
              {" "}
              <p className="my-3 text-center">
                The following data in this Observation must be deleted before
                proceeding:
              </p>
              <ol>
                {observation?.attachments?.length ? (
                  <li>
                    <small>
                      Files & Photos <i>{observation?.attachments?.length}</i>
                    </small>
                  </li>
                ) : null}
                {observation?.geoTags?.length ? (
                  <li>
                    <small>
                      Geotags <i>{observation?.geoTags.length}</i>
                    </small>
                  </li>
                ) : null}
                {observation?.commentsCount ? (
                  <li>
                    <small>
                      Comments <i>{observation?.commentsCount}</i>
                    </small>
                  </li>
                ) : null}
                {certifierComments?.length ? (
                  <li>
                    <small>
                      Certifier Comments <i>{certifierComments?.length}</i>
                    </small>
                  </li>
                ) : null}
                {memos?.length ? (
                  <li>
                    <small>
                      Notepad <i>{memos?.length}</i>
                    </small>
                  </li>
                ) : null}
              </ol>
            </>
          ) : (
            <div className="mx-4 mt-2">
              <Form.Group>
                <Form.Check
                  label={
                    <small>
                      By checking the box, I confirm that I want to delete this
                      Observation. I understand that this cannot be undone.
                    </small>
                  }
                  onClick={() => {
                    setIsChecked(!isChecked);
                  }}
                />
              </Form.Group>
            </div>
          )}
          {(isExpanding || isFetching) && <Loader />}
          {(error || isExpandingError || isFetchError) && (
            <ErrorHandler error={error || isExpandingError || isFetchError} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2 px-3"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="danger"
            className="px-3"
            onClick={() => deleteObservation(observation)}
            disabled={isLoading || hasRelationships || !isChecked}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteObservation;
