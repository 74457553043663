import { useContext } from "react";

import { ProgressBar } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";

import { questionIsAnswered } from "../../helper";

function ReportCompletion() {
  const { audit } = useContext(AuditContext);
  const answeredQuestions = audit?.questions
    .map(({ observations }) => {
      if (observations.length === 0) return false;
      return observations.every((observation) =>
        questionIsAnswered(audit, observation)
      );
    })
    .filter(Boolean);

  const percentage = () => {
    const round = Math.round(
      (answeredQuestions.length / audit?.questions.length) * 100
    );
    if (isNaN(round)) return 0;

    return round;
  };

  return (
    <div className="pb-3">
      <p className="mb-2">
        <small>
          {percentage()}% Complete - {answeredQuestions.length} of{" "}
          {audit?.questions.length} Questions
        </small>
      </p>
      <ProgressBar now={percentage()} />
    </div>
  );
}

ReportCompletion.propTypes = {};

export default ReportCompletion;
