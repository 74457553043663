const LabelScore = ({ score }) => {
  const _score = Number(score);
  if (isNaN(_score)) return null;
  if (_score === 0) return null;
  return (
    <small className="text-muted float-end ps-2 pt-1">{_score} score</small>
  );
};

export default LabelScore;
