import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import PropTypes from "prop-types";

const CHART_HEIGHT = 270;

const CheckboxFields = ({ data }) => {
  const el = useRef(null);

  useEffect(() => {
    const xAxisData = data?.stats.map((item) => item.option);
    const yAxisData = data?.stats.map((item) => item.count);

    const option = {
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />",
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        axisTick: {
          show: true,
          alignWithLabel: true,
          length: 8,
        },
        axisLabel: {
          rotate: 90,
          showMaxLabel: true,
          width: 72,
          overflow: "truncate",
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: yAxisData,
          type: "bar",
          barMaxWidth: 32,
        },
      ],
    };

    if (!data) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
    }

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: CHART_HEIGHT,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: CHART_HEIGHT,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
};

CheckboxFields.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CheckboxFields;
