import { useState, useEffect, useRef, useContext } from "react";
import { put, post } from "utils/DeApi";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useToast } from "hooks";

const useCreateOrUpdateSectionSummary = ({
  summary,
  sectionId,
  handleClose,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const { audit, setAudit } = useContext(AuditContext);

  function stripHtmlTags(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const createOrUpdateSectionSummary = (formData) => {
    if (!summary && !formData?.summary) {
      handleClose();
      return;
    }

    setError(null);
    setIsLoading(true);

    const body =
      stripHtmlTags(formData?.summary).trim() === ""
        ? "<p></p>"
        : formData?.summary;

    const summaryPromise = summary
      ? put(`/section-summaries/${summary.summaryId}`, {
          body: body,
        })
      : post(`/audits/${audit?.auditId}/section-summaries`, {
          body: body,
          section_id: sectionId,
        });

    summaryPromise.promise
      .then((response) => {
        setAudit((prevState) => {
          const { sections = [] } = prevState.protocol || {};
          const updatedSections = sections.map((section) => {
            if (section.sectionId === response.data.section.sectionId) {
              return {
                ...section,
                summary: response.data,
              };
            }
            return section;
          });
          return {
            ...prevState,
            protocol: {
              ...prevState.protocol,
              sections: updatedSections,
            },
          };
        });
        toast.success(
          "Success",
          "Section summary has been updated successfully"
        );
        setError(null);
        handleClose();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          toast.error("Error", "Failed to update section summary");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(summaryPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { isLoading, error, createOrUpdateSectionSummary };
};

export default useCreateOrUpdateSectionSummary;
