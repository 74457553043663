import React from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import OverlayTriggerButton from "components/ui/OverlayTriggerButton";

import AuditHistory from "./AuditHistory/AuditHistory";
import AuditUpdate from "features/assessment/components/common/AuditUpdate";
import AuditDelete from "features/assessment/components/common/AuditDelete";

const AuditListItem = ({ audit, onAuditUpdated, onAuditDeleted }) => {
  const { isCertifier } = useRoles();

  return (
    <tr>
      <td>
        <Link
          to={`/audits/${audit?.auditId}`}
          size="sm"
          className="text-decoration-none bold"
          variant="secondary"
        >
          {audit?.name}
        </Link>
        <br />
        {Array.isArray(audit?.labels) &&
          audit?.labels.map(({ auditLabelId, name, color }) => (
            <small
              key={auditLabelId}
              className="me-2 rounded-2 badge text-dark fw-normal opacity-75"
              style={{
                borderWidth: 1,
                borderColor: color,
                backgroundColor: `${color}30`,
                borderStyle: "solid",
              }}
            >
              {name}
            </small>
          ))}

        {audit?.archived && (
          <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
            <small className="text-warning">
              <span translate="no" className="material-symbols-outlined md-13">
                archive
              </span>{" "}
              Archived
            </small>
          </Badge>
        )}
        {audit?.confidential && (
          <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
            <small className="text-danger">
              <span translate="no" className="material-symbols-outlined md-13">
                block
              </span>{" "}
              Confidential
            </small>
          </Badge>
        )}
        {(audit.locked || isCertifier) && (
          <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
            <small className="text-dark">
              <span translate="no" className="material-symbols-outlined md-13">
                lock
              </span>{" "}
              View only
            </small>
          </Badge>
        )}
        {audit?.status === "Done" && (
          <Badge className="bg-primary me-2 mb-1">
            <small>
              <span translate="no" className="material-symbols-outlined md-13">
                check
              </span>{" "}
              {audit.status}
            </small>
          </Badge>
        )}
      </td>
      <td className="truncate">
        <small>{audit?.protocol?.name}</small>
      </td>
      <td className="truncate">
        <small>{audit?.group?.title || "-"}</small>
      </td>
      <td>
        <small>
          {new Date(audit?.updatedAt).toLocaleString([], {
            timeZone: "UTC",
            dateStyle: "short",
            timeStyle: "short",
          })}
        </small>
      </td>
      <td className="text-end">
        <div className="d-flex flex-row">
          <div className="me-2">
            <AuditHistory audit={audit} />
          </div>
          <div className="me-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="reports-tooltip">Reports</Tooltip>}
            >
              <Button
                as={Link}
                to={`/audits/${audit?.auditId}/reports`}
                size="sm"
                variant="outline-secondary"
                className="position-relative"
              >
                <span
                  translate="no"
                  className="material-symbols-outlined md-16"
                >
                  description
                </span>
                {!!audit.observationReportCommentsCount && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {audit?.observationReportCommentsCount}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                )}
              </Button>
            </OverlayTrigger>
          </div>
          <div className="me-2">
            <AuditUpdate
              audit={audit}
              onAuditUpdated={onAuditUpdated}
              children={
                <OverlayTriggerButton
                  placement="top"
                  toolTipMessage="Edit"
                  variant="outline-primary"
                  label={
                    <span
                      translate="no"
                      className="material-symbols-outlined md-16"
                    >
                      edit
                    </span>
                  }
                />
              }
            />
          </div>
          <div className="me-2">
            <AuditDelete
              audit={audit}
              onAuditDeleted={onAuditDeleted}
              children={
                <OverlayTriggerButton
                  placement="top"
                  toolTipMessage="Delete"
                  variant="outline-danger"
                  label={
                    <span
                      translate="no"
                      className="material-symbols-outlined md-16"
                    >
                      delete
                    </span>
                  }
                />
              }
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

AuditListItem.propTypes = {
  audit: PropTypes.object.isRequired,
  onAuditUpdated: PropTypes.func.isRequired,
  onAuditDeleted: PropTypes.func.isRequired,
};

export default AuditListItem;
