import { create, all } from "mathjs";
import Color from "colorjs.io";

const config = {};
const math = create(all, config);

export const color = new Color("green");

export const createEquation = (collection, operand = "score") => {
  if (!Array.isArray(collection)) return "";

  return collection.reduce((accummulator, current) => {
    if (current.label) return `${accummulator}${current[operand]}`;
    if (current.operator) return `${accummulator} ${current.operator} `;
    return accummulator;
  }, "");
};

export const isValidEquation = (equation) => {
  if (isNaN(parseFloat(equation))) return false;
  if (equation.includes("undefined")) return false;
  /** Test expression to see if it's a valid equation */
  const regEx = /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/;
  return regEx.test(equation);
};

export const evaluateExpression = (collection, invalidDefault = "Result") => {
  const equation = Array.isArray(collection)
    ? createEquation(collection)
    : collection;
  if (!isValidEquation(equation)) return invalidDefault;
  return math.evaluate(equation);
};

export const choiceColorMap = (children) => {
  if (!Array.isArray(children)) return [];
  return children.map((child) => {
    const choices = child.choices.sort((a, b) => {
      if (a.score < b.score) return 1;
      if (a.score > b.score) return -1;
      return 0;
    });
    const colorSteps = color
      .steps("red", {
        steps: 0,
        maxDeltaE: 3,
        space: "lch",
        outputSpace: "srgb",
        maxSteps: choices?.length,
      })
      .reverse();
    return {
      ...child,
      choices: choices.map((choice, index) => {
        return {
          ...choice,
          color: colorSteps[index],
        };
      }),
    };
  });
};

export const isResidualRisk = (expression) => {
  if (!Array.isArray(expression)) return false;
  if (expression.length < 3) return false;
  return !!expression.find(({ operator }) => operator === "/");
};

export const isInherentRisk = (expression) => {
  if (!Array.isArray(expression)) return false;
  if (expression.length < 3) return false;
  return !!expression.find(({ operator }) => operator === "*");
};
