import { useState, useRef } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { post } from "utils/DeApi";
import * as yup from "yup";
import ErrorHandler from "components/ui/ErrorHandler";
import { Formik } from "formik";
import { useRoles } from "hooks";

const ObservationLabelCreate = ({
  auditId,
  questionId,
  observation,
  updateObservations,
}) => {
  const { isCertifier } = useRoles();
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    label: yup
      .string()
      .min(2, "Observation name is too Short!")
      .max(100, "Observation name is too Long!")
      .required("Observation name is required"),
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    if (!observation?.observationId) createObservation();
  };

  const createObservation = (values) => {
    setError("");
    setIsLoading(true);

    const observationPromise = post(`observations`, {
      auditId: auditId,
      questionId: questionId,
      parentId: observation?.parentId
        ? observation?.parentId
        : observation?.observationId,
      label: values?.label || "observation 1",
      risk: "",
      conformityLevel: "",
      compliance: "",
      isInapplicable: false,
      recommendation: "",
      conformityInapplicableReasons: "",
      correctiveAction: "",
      notes: "",
      causalFactors: "",
      extras: {
        auditedAt: "",
        location: "",
        coach: {
          fullName: "",
        },
        attendees: [],
        feedback: "",
      },
    });
    observationPromise.promise
      .then(({ data }) => {
        updateObservations({ ...data, attachments: [], offline: false });
        if (observation?.observationId) handleClose();
      })

      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));
    subscribedPromises.current.push(observationPromise);
  };

  useState(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  if (isCertifier) return;

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        className="float-end"
        onClick={handleShow}
        disabled={isLoading}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          add
        </span>{" "}
        Add Observation
        {isLoading && (
          <Spinner animation="border" role="status" size="sm" className="ms-2">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Observation</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            createObservation(values);
          }}
          initialValues={{
            name: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="label" className="mb-3">
                  <Form.Label>Observation Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="label"
                    value={values.label}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isValid={values.label && !errors.label}
                    isInvalid={
                      !(values.label && !errors.label) && touched.label
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.label && touched.label ? (
                      <small>{errors.label}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  variant="primary"
                  className="px-3"
                  disabled={isLoading || !values?.label}
                  onClick={handleSubmit}
                >
                  {isLoading && (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      className="me-2"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}{" "}
                  Create Observation
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ObservationLabelCreate;
