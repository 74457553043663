import React from "react";
import * as Sentry from "@sentry/react";
import { Container } from "react-bootstrap";
import { ActivityLog } from "features/activityLogs";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const ActivityLogPage = () => {
  return (
    <Container className="my-2 MainContent">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "ActivityLogPage");
        }}
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      >
        <ActivityLog />
      </Sentry.ErrorBoundary>
    </Container>
  );
};

export default ActivityLogPage;
