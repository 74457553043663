import { useContext, useEffect, useRef, useState } from "react";

import { useRoles } from "hooks";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import ActionItemCreate from "features/actionItems/components/common/ActionItemCreate";
import ActionItemCard from "features/actionItems/components/common/ActionItemCard";

function OrganizationActionItem() {
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const { isCertifier } = useRoles();

  const [error, setError] = useState();
  const [actionItems, setActionItems] = useState();
  const [meta, setMeta] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchActionItems = () => {
      setError(null);
      setIsLoading(true);
      const subscribersPromise = get(
        `subscribers/${user?.de?.subscriberId}/action-items`,
        {
          params: {
            perPage: 5,
            organizationId: organization?.id,
            "filter[type]": "organization",
            "filter[status]": "0,1,2",
          },
        }
      );

      subscribersPromise.promise
        .then((response) => {
          setActionItems(response?.data);
          setMeta(response?.meta);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (!isCertifier) fetchActionItems();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [user, isCertifier, organization]);

  if (isCertifier) return <></>;

  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex-fill">
          <h2>
            Action Items ·{" "}
            <small className="text-muted" translate="no">
              {!isLoading && <span> {meta?.total ?? 0}</span>}
            </small>{" "}
          </h2>
          <p>Organization Level</p>
        </div>
        <div>
          <ActionItemCreate
            taskableId={null}
            taskableType={null}
            onActionItemCreated={(newActionItem) => {
              setMeta((prev) => ({ ...prev, total: ++prev.total }));
              setActionItems((prevActionItems) => [
                newActionItem,
                ...prevActionItems,
              ]);
            }}
          />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : actionItems?.length ? (
        <ActionItemCard
          className="w-75 mb-2"
          actionItems={actionItems?.filter(({ status }) => status !== 3)}
          setActionItems={setActionItems}
          to={`/action-items?taskableType=organization&taskableId=${organization?.id}`}
        />
      ) : (
        <EmptyStateHandler
          title="No action items found"
          description="There are currently no action items to show at the Organization level."
        />
      )}
    </>
  );
}

OrganizationActionItem.propTypes = {};

export default OrganizationActionItem;
