import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const ManuscriptEditHistoryFilters = ({
  userOptions,
  handleFilterChange,
  filters,
  questionOptions,
}) => {
  return (
    <Form className="mb-3 p-2 bg-light rounded">
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>User</Form.Label>
            <Typeahead
              id="user-filter"
              options={userOptions}
              placeholder="Filter by user"
              onChange={(selected) => handleFilterChange("userId", selected)}
              selected={filters.userId ? [{ label: filters.userId }] : []}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Question</Form.Label>
            <Typeahead
              id="question-filter"
              options={questionOptions}
              placeholder="Filter by question"
              onChange={(selected) => handleFilterChange("fieldType", selected)}
              selected={filters.fieldType ? [{ label: filters.fieldType }] : []}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={filters.startDate}
              onChange={(e) =>
                handleFilterChange("startDate", [{ label: e.target.value }])
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={filters.endDate}
              onChange={(e) =>
                handleFilterChange("endDate", [{ label: e.target.value }])
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default ManuscriptEditHistoryFilters;
