import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  color,
  createEquation,
  evaluateExpression,
  isInherentRisk,
  isResidualRisk,
} from "../helper";

const ResidualRiskColor = ({ expression, label }) => {
  const [residualRiskColor, setResidualRiskColor] = useState();

  useEffect(() => {
    if (isResidualRisk(expression)) {
      const inherentRisk = expression.find(({ expressions }) =>
        isInherentRisk(expressions)
      );

      if (inherentRisk) {
        const expressionMaxScore = inherentRisk?.expressions.map((operand) => {
          if (operand?.choices) return operand.choices[0];
          return operand;
        });

        const inherentRiskEquation = createEquation(expressionMaxScore);
        const inherentRiskAnswer = evaluateExpression(inherentRiskEquation);

        const residualRiskEquation = createEquation(expression);
        const residualRiskAnswer = evaluateExpression(residualRiskEquation);

        if (
          typeof inherentRiskAnswer === "number" &&
          isFinite(inherentRiskAnswer) &&
          typeof residualRiskAnswer === "number" &&
          isFinite(residualRiskAnswer)
        ) {
          const residualRisk = Math.ceil(residualRiskAnswer);
          const colorSteps = color.steps("red", {
            steps: 0,
            maxDeltaE: 3,
            space: "lch",
            outputSpace: "srgb",
            maxSteps: inherentRiskAnswer,
          });

          const index =
            residualRisk >= colorSteps?.length
              ? colorSteps?.length - 1
              : residualRisk;

          setResidualRiskColor(colorSteps[index]);
        }
      }
    }
  }, [expression]);

  if (!residualRiskColor) return <></>;

  return (
    <span
      className="float-end rounded-0 badge px-2 py-1 text-white"
      style={{
        backgroundColor: residualRiskColor.toString({ format: "hex" }),
      }}
    >
      <strong>{label}</strong>
    </span>
  );
};

ResidualRiskColor.propTypes = {
  expression: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default ResidualRiskColor;
