import React, { useContext, useEffect, useRef, useState } from "react";

import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useFormikContext } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

import { get } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import SlateEditor from "components/ui/SlateEditor";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { OrganizationContext } from "contexts/OrganizationProvider";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import { addYears } from "features/actionItems/utils";

const ActionItemUpdateGeneralInfo = ({
  show,
  taskableType,
  taskableId,
  facilityId,
}) => {
  const { handleChange, handleBlur, values, touched, errors, setFieldValue } =
    useFormikContext();

  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [error, setError] = useState();
  const [isSyncing, setIsSyncing] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [tags, setTags] = useState([]);
  const [subscribers, setSubscribers] = useState([]);

  const isAdminOrOwner =
    organization?.invitation?.role === "Admin" ||
    organization?.invitation?.role === "Owner";

  useEffect(() => {
    const fetchSubscribers = () => {
      setIsExpanding(true);
      const subscribersURL = () => {
        if (taskableType === "facility" && taskableId)
          return `facilities/${taskableId}/subscribers`;
        if (taskableType === "question" && facilityId)
          return `facilities/${facilityId}/subscribers`;
        return "subscribers";
      };
      const subscribersPromise = get(subscribersURL(), {
        params: {
          organizationId: organization?.id,
        },
      });
      subscribersPromise.promise
        .then((response) => {
          setSubscribers(response.data ?? []);
          setIsExpanding(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsExpanding(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    const fetchTags = () => {
      setIsSyncing(true);
      const subscribersPromise = get(`/organizations/${organization?.id}/tags`);
      subscribersPromise.promise
        .then((response) => {
          setTags(response.data ?? []);
          setIsSyncing(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsSyncing(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    if (show) {
      fetchSubscribers();
      fetchTags();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization?.id, taskableId, taskableType, facilityId, show]);

  return (
    <>
      <Form.Group controlId="status" className="mt-0 mb-3">
        <Form.Label className="mb-1">
          Status <RequiredAsterisk />
        </Form.Label>
        <Form.Select
          onChange={handleChange}
          onBlur={handleBlur}
          name="status"
          placeholder="Select Status"
          value={values.status}
          isValid={values.status && !errors.status}
          isInvalid={errors.status && touched.status}
        >
          <option value={0}>Assigned</option>
          <option value={1}>Submitted</option>
          <option hidden={!isAdminOrOwner} disabled={!isAdminOrOwner} value={2}>
            Incomplete
          </option>
          <option hidden={!isAdminOrOwner} disabled={!isAdminOrOwner} value={3}>
            Accepted
          </option>
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="item" className="mb-3">
        <Form.Label className="mb-1">
          Title <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="item"
          value={values.item}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={values.item && !errors.item}
          isInvalid={!(values.item && !errors.item) && touched.item}
          placeholder="Enter action item"
        />
        <Form.Control.Feedback type="invalid">
          {errors.item && touched.item ? <small>{errors.item}</small> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="type" className="mb-3">
        <Form.Label className="mb-1">
          Type <RequiredAsterisk />
        </Form.Label>
        <Form.Select
          onChange={handleChange}
          onBlur={handleBlur}
          name="type"
          placeholder="Select Type"
          value={values.type}
          isValid={values.type && !errors.type}
          isInvalid={errors.type && touched.type}
        >
          <option value={0}>Administrative</option>
          <option value={1}>Corrective</option>
          <option value={2}>Certification </option>
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="item" className="mb-3">
        <Form.Label className="mb-1">Description</Form.Label>
        <div className="bg-light border rounded">
          <SlateEditor
            name="description"
            setFieldValue={setFieldValue}
            oldValue={values.description}
            placeholder={"Enter Action Item Description"}
          />
        </div>
        <Form.Control.Feedback type="invalid">
          {errors.description && touched.description ? (
            <small>{errors.description}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Due Date <RequiredAsterisk />
        </Form.Label>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          type="date"
          name="dueDate"
          selected={values.dueDate}
          popperPlacement="top-start"
          className="action-item-datepicker form-control"
          onChange={(date) => setFieldValue("dueDate", date)}
          maxDate={addYears(new Date(), 2)}
          minDate={new Date()}
          onBlur={handleBlur}
          isValid={values.dueDate && !errors.dueDate}
          isInvalid={!(values.dueDate && !errors.dueDate) && touched.dueDate}
          isClearable
          placeholderText="Enter Due Date"
        />
        <Form.Control.Feedback type="invalid">
          {errors.dueDate && touched.dueDate ? (
            <small>{errors.dueDate}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="protocol" className="mb-3">
        <Form.Label className="mb-1">
          Assigned To <RequiredAsterisk />
        </Form.Label>
        <Typeahead
          id="assigned-to-typeahead"
          clearButton
          placeholder="Assigned to"
          isLoading={isExpanding}
          disabled={taskableType === "question" && !facilityId}
          labelKey={(option) => `${option.firstName} ${option.lastName}`}
          isValid={
            !!values.assignedTo.length &&
            !errors.assignedTo &&
            touched.assignedTo
          }
          isInvalid={
            !(values.assignedTo.length && !errors.assignedTo) &&
            touched.assignedTo
          }
          onChange={(assignedTo) => {
            setFieldValue("assignedTo", assignedTo);
          }}
          options={subscribers}
          selected={values.assignedTo}
          multiple
        />
      </Form.Group>

      <Form.Group controlId="tags" className="mb-3">
        <Form.Label className="mb-1">Tags</Form.Label>
        <Typeahead
          id="tags-typeahead"
          clearButton
          placeholder="Select tags ..."
          newSelectionPrefix="Add: "
          isLoading={isSyncing}
          labelKey={`name`}
          isValid={!!values.tags.length && !errors.tags && touched.tags}
          isInvalid={!(values.tags && !errors.tags) && touched.tags}
          onChange={(tags) => {
            setFieldValue(
              "tags",
              uniqBy(
                tags.map((tag) => ({
                  ...tag,
                  name: tag?.name.toLowerCase(),
                })),
                "name"
              )
            );
          }}
          options={tags}
          selected={values.tags}
          multiple
        />
      </Form.Group>
      {taskableType === "question" && !facilityId && (
        <EmptyStateHandler
          title="No Assessment"
          description="The action item does not have an associated assessment"
        />
      )}
      {error && show && <ErrorHandler error={error} />}
    </>
  );
};

ActionItemUpdateGeneralInfo.propTypes = {
  show: PropTypes.bool,
};

export default ActionItemUpdateGeneralInfo;
