import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import { stripHTML } from "utils/StringUtils";
import PropTypes from "prop-types";
import "./LatestManuscriptComments.scss";

function LatestManuscriptComments({ questions, comments, observations }) {
  return (
    <>
      <Card className="mt-2 background">
        <Card.Body>
          {comments?.slice(0, 5).map((comment, index, arr) => {
            const associatedObservation = observations?.find(
              (observation) =>
                observation.observationId === comment.observation?.observationId
            );

            const associatedQuestion = questions.find(
              (question) =>
                question.questionId === associatedObservation?.questionId
            );

            return (
              <Fragment key={comment.commentId}>
                <div className="d-flex flex-column m-0">
                  <a
                    href={`#question-${associatedQuestion?.questionId}`}
                    className="mb-2 w-100 text-reset text-decoration-none"
                  >
                    {associatedQuestion && (
                      <p className="mb-2">
                        <small className="text-muted commentTextWrap">
                          Question: {stripHTML(associatedQuestion.prompt)}
                        </small>
                      </p>
                    )}
                    <div className="commentTextWrap mb-2">{comment.body}</div>
                    <small className="text-muted mt">
                      {comment.subscriber?.firstName}{" "}
                      {comment.subscriber?.lastName} ·{" "}
                      {new Date(comment.updatedAt).toLocaleString([], {
                        dateStyle: "short",
                        timeStyle: "short",
                      })}
                    </small>
                  </a>
                </div>
                {arr.length > ++index && <hr className="mt-1" />}
              </Fragment>
            );
          })}
        </Card.Body>
      </Card>
    </>
  );
}

LatestManuscriptComments.propTypes = {
  questions: PropTypes.array.isRequired,
  comments: PropTypes.array.isRequired,
  observations: PropTypes.array.isRequired,
};

export default LatestManuscriptComments;
