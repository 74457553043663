import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Image, Table } from "react-bootstrap";
import { get } from "utils/DeApi";
import { getURLFileExtension } from "utils/StringUtils";
import { compressImage } from "utils/UploadUtils";
import { choiceColorMap } from "../../../AuditDetails/Question/FlexibleQuestions/FieldCompound/helper";
import { parseFlexQuestion } from "../../../AuditDetails/Question/helper";
import { complianceMap, conformityLevelMap, riskMap } from "../../../helper";
import "./ObservationExport.scss";
import { createTreeArray } from "../../../AuditDetails/Question/FlexibleQuestions/nestingUtils";
import RecursiveNestedQuestions from "./RecursiveNestedQuestions";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useVertical } from "features/assessment/hooks";

const ObservationExport = ({
  observation,
  responses,
  _showRisk,
  _showNotes,
  _showCompliance,
  _showCasualFactor,
  _showRecommendation,
  _showCorrectiveAction,
  _showConformityLevel,
  questionOptions,
  conformity,
  riskLevels,
  showAttachments,
  flexibleQuestions,
  showEmptyFlexibleQuestions,
  showRiskMatrices,
  index,
  setAnsweredArray,
  sectionId = "",
}) => {
  const [
    riskLabel,
    complianceLabel,
    conformityLabel,
    notesLabel,
    recommendationLabel,
    causalFactorsLabel,
    correctiveActionLabel,
  ] = questionOptions || [];
  const [compressedImages, setCompressedImages] = useState({});
  const [heatmapChoicesList, setHeatmapChoicesList] = useState([]);
  const subscribedPromises = useRef([]);
  const CONFORMITY_LEVEL_NOT_APPLICABLE = 4;
  const { hideAttachmentDescAndName } = useVertical();
  const isAttachmentsEmpty = (observation?.attachments || []).length;
  const showObservations = [
    observation.risk,
    observation.compliance,
    observation?.correctiveAction,
    observation.conformityLevel,
    observation?.causalFactors,
    observation?.recommendation,
    observation?.notes,
  ].filter((value) => value !== undefined && value !== null);

  const { audit } = useContext(AuditContext);
  const { sectionFormFields = [] } = audit;

  const filterEmptyResponses = responses.filter(
    ({ notes = "", responseDatetime = "", choice = "" }) =>
      !!notes || !!responseDatetime || !!choice
  );

  const sanitizeText = (text) => {
    if (typeof text !== "string") return "";
    return DOMPurify.sanitize(text.replace(/\n/g, "<br/>"));
  };

  let mergedQuestions = createTreeArray(flexibleQuestions);

  function getSelectedChoicesWithPrompt(children, values) {
    return children.reduce((acc, child) => {
      const choices = child?.choices || [];
      const prompt = child?.prompt;

      choices.forEach((choice) => {
        if (values?.[child.id]?.response === choice.id) {
          acc.push({ ...choice, prompt });
        }
      });

      return acc;
    }, []);
  }

  function areAllEmptyArrays(arr) {
    return arr.every(
      (element) => Array.isArray(element) && element.length === 0
    );
  }

  function countElementsInArrays(arrayOfArrays) {
    return arrayOfArrays.reduce((accumulator, currentArray) => {
      return accumulator + currentArray.length;
    }, 0);
  }

  const noQuestionsAnswered =
    !filterEmptyResponses.length ||
    (filterEmptyResponses.length ===
      countElementsInArrays(heatmapChoicesList) &&
      !heatmapChoicesList.some((selections) => selections.length === 2));

  useEffect(() => {
    const questionsToMap = mergedQuestions || flexibleQuestions;
    const newSelectedChoicesArray = questionsToMap
      .sort((a, b) => a.order - b.order)
      .map((question) => {
        if (
          question.properties?.fieldType === "compound" &&
          question.children?.length > 0
        ) {
          const heatmapChildren = choiceColorMap(question.children);
          const heatmapValues = parseFlexQuestion(responses);
          const selectedHeatmaps = getSelectedChoicesWithPrompt(
            heatmapChildren,
            heatmapValues
          );
          return selectedHeatmaps;
        }
        return [];
      });

    setHeatmapChoicesList(newSelectedChoicesArray);
  }, [flexibleQuestions, responses]);

  useEffect(() => {
    observation.attachments?.forEach(async (item) => {
      const imagePromise = get(item.url, { responseType: "blob" });

      imagePromise.promise
        .then((res) => {
          if (!res) {
            throw new Error(`Failed to fetch ${item.url}`);
          }
          return res;
        })
        .then(async (file) => {
          if (
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/svg"
          ) {
            const compressedBlob = await compressImage(file, 800, 0.1);
            const reader = new FileReader();

            reader.onload = () => {
              const base64Image = reader.result;
              setCompressedImages((prevImages) => ({
                ...prevImages,
                [item.fileId]: base64Image,
              }));
            };

            reader.readAsDataURL(compressedBlob);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      subscribedPromises.current.push(imagePromise);
    });
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [observation.attachments]);

  useEffect(() => {
    const unanswered =
      (!showObservations.length || areAllEmptyArrays(showObservations)) &&
      noQuestionsAnswered &&
      !showEmptyFlexibleQuestions &&
      (!isAttachmentsEmpty || !showAttachments);

    if (setAnsweredArray && typeof index === "number") {
      setAnsweredArray((prevState) => {
        const newArray = [...prevState];
        newArray[index] = unanswered;
        return newArray;
      });
    }
  }, [responses]);

  if (
    (!showObservations.length || areAllEmptyArrays(showObservations)) &&
    noQuestionsAnswered &&
    !showEmptyFlexibleQuestions &&
    (!isAttachmentsEmpty || !showAttachments)
  )
    return (
      <div className="py-2">
        <div className="bg-warning bg-light p-3 w-100  w-100 d-flex">
          UNANSWERED
        </div>
      </div>
    );

  if (
    !showObservations.length &&
    !filterEmptyResponses.length &&
    !showEmptyFlexibleQuestions &&
    isAttachmentsEmpty &&
    showAttachments
  )
    return (
      <Table size="sm" bordered>
        <thead className="border-0">
          <tr className="border-0 text-white">
            {/* <!-- add repeated tfoot for extra space --> */}
            <td className="border-0" colSpan={100}>
              &nbsp;
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-50 fw-bold">Attachments</td>
            <td className="w-50 fw-bold">
              {Array.isArray(observation.attachments) &&
                observation.attachments.map((item) => {
                  const fileEXT = getURLFileExtension(item.url || "");

                  return (
                    <div className={`my-2`} key={item.fileId}>
                      {compressedImages &&
                        ["png", "jpg", "jpeg", "svg"].includes(fileEXT) && (
                          <>
                            <Image
                              src={compressedImages[item.fileId]}
                              className="img-fluid attachment-image"
                              fluid
                            />
                            {!hideAttachmentDescAndName && (
                              <div>
                                <small>
                                  <i>
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {item?.name && <span>{item.name}</span>}{" "}
                                      {!item?.name && (
                                        <span>
                                          {item.fileId}.{fileEXT}
                                        </span>
                                      )}
                                    </a>
                                  </i>
                                </small>
                              </div>
                            )}
                          </>
                        )}

                      {compressedImages &&
                        !["png", "jpg", "jpeg", "svg"].includes(fileEXT) && (
                          <div className="d-flex flex-row align-items-center my-2">
                            {" "}
                            <span
                              className={`fiv-sqo fiv-size-md lh-base fiv-icon-${fileEXT}`}
                            ></span>
                            <small>
                              <i>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item?.name && (
                                    <span className="mx-1">{item.name}</span>
                                  )}
                                  {!item?.name && (
                                    <span className="mx-1">
                                      {item.fileId}.{fileEXT}
                                    </span>
                                  )}
                                </a>
                              </i>
                            </small>
                          </div>
                        )}

                      {!hideAttachmentDescAndName && (
                        <p>
                          <span className="fw-bold">Description:</span>
                          {item.description && item.description.length > 25 && (
                            <>{item.description.slice(0, 25) + "..."}</>
                          )}
                          {item.description &&
                            item.description.length <= 25 &&
                            item.description}
                        </p>
                      )}
                    </div>
                  );
                })}
            </td>
          </tr>
        </tbody>
        <tfoot>
          {/* <!-- add repeated tfoot for extra space --> */}
          <tr className="border-0">
            <td className="border-0" colSpan={100}>
              &nbsp;
            </td>
          </tr>
        </tfoot>
      </Table>
    );

  return (
    <>
      <Table size="sm" bordered>
        <thead className="border-0">
          <tr className="border-0 text-white">
            {/* <!-- add repeated tfoot for extra space --> */}
            {/* <td className="border-0" colSpan={100}>
              &nbsp;
            </td> */}
          </tr>
        </thead>
        <tbody>
          <RecursiveNestedQuestions
            observation={observation}
            index={index}
            showObservations={showObservations}
            showAttachments={showAttachments}
            hideAttachmentDescAndName={hideAttachmentDescAndName}
            showEmptyFlexibleQuestions={showEmptyFlexibleQuestions}
            setAnsweredArray={setAnsweredArray}
            responses={responses}
            riskLevels={riskLevels}
            complianceLabel={complianceLabel}
            riskLabel={riskLabel}
            correctiveActionLabel={correctiveActionLabel}
            _showCompliance={_showCompliance}
            _showRisk={_showRisk}
            _showCorrectiveAction={_showCorrectiveAction}
            compressedImages={compressedImages}
            setCompressedImages={setCompressedImages}
            _showNotes={_showNotes}
            _showCasualFactor={_showCasualFactor}
            _showRecommendation={_showRecommendation}
            _showConformityLevel={_showConformityLevel}
            questionOptions={questionOptions}
            mergedQuestions={mergedQuestions}
            sectionId={sectionId}
            heatmapChoicesList={heatmapChoicesList}
            showRiskMatrices={showRiskMatrices}
          />

          {_showRisk &&
            (riskMap(observation.risk, riskLevels) ||
              showEmptyFlexibleQuestions) && (
              <tr>
                <td className="w-50 fw-bold">
                  {riskLabel?.name || "Risk Rating"}
                </td>
                <td>{riskMap(observation.risk, riskLevels)}</td>
              </tr>
            )}

          {_showCompliance &&
            (complianceMap(observation.compliance) ||
              showEmptyFlexibleQuestions) && (
              <tr>
                <td className="fw-bold">
                  {complianceLabel?.name || "Compliance Observed"}
                </td>
                <td>{complianceMap(observation.compliance)}</td>
              </tr>
            )}
          {_showCorrectiveAction &&
            (observation?.correctiveAction || showEmptyFlexibleQuestions) && (
              <tr>
                <td className="w-50 fw-bold">
                  {correctiveActionLabel?.name || "Corrective Action"}
                </td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      observation?.correctiveAction?.replace(/\n/g, "<br/>")
                    ),
                  }}
                ></td>
              </tr>
            )}
          {_showConformityLevel &&
            (conformityLevelMap(observation.conformityLevel, conformity) ||
              showEmptyFlexibleQuestions) && (
              <>
                <tr>
                  <td className="w-50 fw-bold">
                    {conformityLabel?.name || "Conformity Level"}
                  </td>
                  <td>
                    {conformityLevelMap(
                      observation.conformityLevel,
                      conformity
                    )}
                  </td>
                </tr>
                {observation?.conformityInapplicableReasons &&
                  observation.conformityLevel ===
                    CONFORMITY_LEVEL_NOT_APPLICABLE && (
                    <tr>
                      <td className="w-50 fw-bold">
                        Why is the conformity level not applicable?
                      </td>
                      <td>{observation.conformityInapplicableReasons}</td>
                    </tr>
                  )}
              </>
            )}
          {_showNotes && (observation?.notes || showEmptyFlexibleQuestions) && (
            <tr>
              <td className="w-50 fw-bold">{notesLabel?.name || "Notes"}</td>
              <td
                dangerouslySetInnerHTML={{
                  __html: sanitizeText(observation?.notes)?.replace(
                    /\n/g,
                    "<br/>"
                  ),
                }}
              ></td>
            </tr>
          )}

          {_showCasualFactor &&
            (observation?.causalFactors || showEmptyFlexibleQuestions) && (
              <tr>
                <td className="w-50 fw-bold">
                  {causalFactorsLabel?.name || "Causal Factors"}
                </td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      observation?.causalFactors?.replace(/\n/g, "<br/>")
                    ),
                  }}
                ></td>
              </tr>
            )}
          {_showRecommendation &&
            (observation?.recommendation || showEmptyFlexibleQuestions) && (
              <tr>
                <td className="w-50 fw-bold">
                  {recommendationLabel?.name || "Best Practice"}
                </td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      observation?.recommendation?.replace(/\n/g, "<br/>")
                    ),
                  }}
                ></td>
              </tr>
            )}

          {showAttachments && (
            <>
              <tr>
                <td className="w-50 fw-bold">Attachments</td>
                <td className="w-50 fw-bold">
                  {Array.isArray(observation.attachments) &&
                    observation.attachments.map((item) => {
                      const fileEXT = getURLFileExtension(item.url || "");

                      return (
                        <div className={`my-2`} key={item.fileId}>
                          {compressedImages &&
                            ["png", "jpg", "jpeg", "svg"].includes(fileEXT) && (
                              <>
                                <Image
                                  src={compressedImages[item.fileId]}
                                  className="img-fluid attachment-image"
                                  fluid
                                />
                                {!hideAttachmentDescAndName && (
                                  <div>
                                    <small>
                                      <i>
                                        <a
                                          href={item.url}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item?.name && (
                                            <span>{item.name}</span>
                                          )}{" "}
                                          {!item?.name && (
                                            <span>
                                              {item.fileId}.{fileEXT}
                                            </span>
                                          )}
                                        </a>
                                      </i>
                                    </small>
                                  </div>
                                )}
                              </>
                            )}

                          {compressedImages &&
                            !["png", "jpg", "jpeg", "svg"].includes(
                              fileEXT
                            ) && (
                              <div className="d-flex flex-row align-items-center my-2">
                                {" "}
                                <span
                                  className={`fiv-sqo fiv-size-md lh-base fiv-icon-${fileEXT}`}
                                ></span>
                                <small>
                                  <i>
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {item?.name &&
                                        !hideAttachmentDescAndName && (
                                          <span className="mx-1">
                                            {item.name}
                                          </span>
                                        )}
                                      {!item?.name &&
                                        !hideAttachmentDescAndName && (
                                          <span className="mx-1">
                                            {item.fileId}.{fileEXT}
                                          </span>
                                        )}
                                    </a>
                                  </i>
                                </small>
                              </div>
                            )}

                          {!hideAttachmentDescAndName && (
                            <p>
                              <span className="fw-bold">Description:</span>
                              {item.description &&
                                item.description.length > 25 && (
                                  <>{item.description.slice(0, 25) + "..."}</>
                                )}
                              {item.description &&
                                item.description.length <= 25 &&
                                item.description}
                            </p>
                          )}
                        </div>
                      );
                    })}
                </td>
              </tr>
            </>
          )}
        </tbody>
        <tfoot>
          {/* <!-- add repeated tfoot for extra space --> */}
          <tr className="border-0">
            <td className="border-0" colSpan={100}>
              &nbsp;
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

ObservationExport.propTypes = {
  responses: PropTypes.array.isRequired,
  observation: PropTypes.object.isRequired,
};

export default ObservationExport;
