import VERTICALS from "config/VERTICALS";
import DOMPurify from "dompurify";
import moment from "moment";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Image } from "react-bootstrap";
import { get } from "utils/DeApi";
import { getURLFileExtension } from "utils/StringUtils";
import { compressImage } from "utils/UploadUtils";
import { choiceColorMap } from "../../../AuditDetails/Question/FlexibleQuestions/FieldCompound/helper";
import { createTreeArray } from "../../../AuditDetails/Question/FlexibleQuestions/nestingUtils";
import { parseFlexQuestion } from "../../../AuditDetails/Question/helper";
import { complianceMap, conformityLevelMap, riskMap } from "../../../helper";

import RecursiveNestedQuestions from "./RecursiveNestedQuestions";

import NestedQuestion from "../../NestedQuestion/NestedQuestion";
import ReportHeatmap from "../../ReportHeatmap";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useVertical } from "features/assessment/hooks";

const ListObservationExport = ({
  observation,
  responses,
  _showRisk,
  _showNotes,
  _showCompliance,
  _showCasualFactor,
  _showRecommendation,
  _showCorrectiveAction,
  _showConformityLevel,
  questionOptions,
  conformity,
  riskLevels,
  showAttachments,
  flexibleQuestions,
  showEmptyFlexibleQuestions,
  showRiskMatrices,
  index,
  setAnsweredArray,
  sectionId = "",
}) => {
  const [
    riskLabel,
    complianceLabel,
    conformityLabel,
    notesLabel,
    recommendationLabel,
    causalFactorsLabel,
    correctiveActionLabel,
  ] = questionOptions || [];
  const [compressedImages, setCompressedImages] = useState({});
  const [heatmapChoicesList, setHeatmapChoicesList] = useState([]);
  const subscribedPromises = useRef([]);
  const CONFORMITY_LEVEL_NOT_APPLICABLE = 4;

  const { hideAttachmentDescAndName } = useVertical();

  const isAttachmentsEmpty = (observation?.attachments || []).length;

  const capitalizeFirstLetter = (word = "") => {
    if (word === null || word === undefined || !word.length) return "";
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const showObservations = [
    observation.risk,
    observation.compliance,
    observation?.correctiveAction,
    observation.conformityLevel,
    observation?.causalFactors,
    observation?.recommendation,
    observation?.notes,
  ].filter((value) => value !== undefined && value !== null);

  const filterEmptyResponses = responses?.filter(
    ({ notes = "", responseDatetime = "", choice = "" }) =>
      !!notes || !!responseDatetime || !!choice
  );

  let mergedQuestions = createTreeArray(flexibleQuestions);

  function getSelectedChoicesWithPrompt(children, values) {
    return children.reduce((acc, child) => {
      const choices = child?.choices || [];
      const prompt = child?.prompt;

      choices.forEach((choice) => {
        if (values?.[child.id]?.response === choice.id) {
          acc.push({ ...choice, prompt });
        }
      });

      return acc;
    }, []);
  }

  function areAllEmptyArrays(arr) {
    return arr.every(
      (element) => Array.isArray(element) && element.length === 0
    );
  }

  function countElementsInArrays(arrayOfArrays) {
    return arrayOfArrays.reduce((accumulator, currentArray) => {
      return accumulator + currentArray.length;
    }, 0);
  }

  const noQuestionsAnswered =
    !filterEmptyResponses.length ||
    (filterEmptyResponses.length ===
      countElementsInArrays(heatmapChoicesList) &&
      !heatmapChoicesList.some((selections) => selections.length === 2));

  useEffect(() => {
    const questionsToMap = mergedQuestions || flexibleQuestions;
    const newSelectedChoicesArray = questionsToMap
      .sort((a, b) => a.order - b.order)
      .map((question) => {
        if (
          question.properties?.fieldType === "compound" &&
          question.children?.length > 0
        ) {
          const heatmapChildren = choiceColorMap(question.children);
          const heatmapValues = parseFlexQuestion(responses);
          const selectedHeatmaps = getSelectedChoicesWithPrompt(
            heatmapChildren,
            heatmapValues
          );
          return selectedHeatmaps;
        }
        return [];
      });

    setHeatmapChoicesList(newSelectedChoicesArray);
  }, [flexibleQuestions, responses]);

  useEffect(() => {
    const fetchAttachments = () => {
      observation?.attachments?.forEach(async (item) => {
        const imagePromise = get(item.url, { responseType: "blob" });

        imagePromise.promise
          .then((res) => {
            if (!res) {
              throw new Error(`Failed to fetch ${item.url}`);
            }
            return res;
          })
          .then(async (file) => {
            if (
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "image/jpg" ||
              file.type === "image/jpeg" ||
              file.type === "image/svg"
            ) {
              const compressedBlob = await compressImage(file, 800, 0.1);
              const reader = new FileReader();

              reader.onload = () => {
                const base64Image = reader.result;
                setCompressedImages((prevImages) => ({
                  ...prevImages,
                  [item.fileId]: base64Image,
                }));
              };

              reader.readAsDataURL(compressedBlob);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        subscribedPromises.current.push(imagePromise);
      });
    };
    fetchAttachments();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [observation.attachments]);

  useEffect(() => {
    const unanswered =
      (!showObservations.length || areAllEmptyArrays(showObservations)) &&
      noQuestionsAnswered &&
      !showEmptyFlexibleQuestions &&
      (!isAttachmentsEmpty || !showAttachments);

    if (setAnsweredArray && typeof index === "number") {
      setAnsweredArray((prevState) => {
        const newArray = [...prevState];
        newArray[index] = unanswered;
        return newArray;
      });
    }
  }, [responses, showAttachments]);

  if (
    (!showObservations.length || areAllEmptyArrays(showObservations)) &&
    noQuestionsAnswered &&
    !showEmptyFlexibleQuestions &&
    (!isAttachmentsEmpty || !showAttachments)
  )
    return (
      <div className="py-2">
        <div className="bg-warning bg-light p-3 w-100  w-100 d-flex">
          UNANSWERED
        </div>
      </div>
    );

  if (
    !showObservations.length &&
    noQuestionsAnswered &&
    !showEmptyFlexibleQuestions &&
    isAttachmentsEmpty &&
    showAttachments
  )
    return (
      <div className={"d-flex flex-column"}>
        <p className="fw-bold">Attachments:</p>
        {Array.isArray(observation.attachments) &&
          observation.attachments.map((item) => {
            const fileEXT = getURLFileExtension(item.url || "");

            return (
              <div className="d-flex flex-column" key={item.fileId}>
                <div>
                  {compressedImages &&
                    ["png", "jpg", "jpeg", "svg"].includes(fileEXT) && (
                      <>
                        <Image
                          className="img-fluid attachment-image"
                          src={compressedImages[item.fileId]}
                          fluid
                        />

                        {!hideAttachmentDescAndName && (
                          <div>
                            <small>
                              <i>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item?.name && <span>{item.name}</span>}
                                  {!item?.name && (
                                    <span>
                                      {item.fileId}.{fileEXT}
                                    </span>
                                  )}
                                </a>
                              </i>
                            </small>
                          </div>
                        )}
                      </>
                    )}

                  {compressedImages &&
                    !["png", "jpg", "jpeg", "svg"].includes(fileEXT) && (
                      <div className="d-flex flex-row align-items-center my-2">
                        {" "}
                        <span
                          className={`fiv-sqo fiv-size-md lh-base fiv-icon-${fileEXT}`}
                        ></span>
                        <small>
                          <i>
                            <a href={item.url} target="_blank" rel="noreferrer">
                              {item?.name && (
                                <span className="mx-1">{item.name}</span>
                              )}
                              {!item?.name && (
                                <span className="mx-1">
                                  {item.fileId}.{fileEXT}
                                </span>
                              )}
                            </a>
                          </i>
                        </small>
                      </div>
                    )}
                </div>

                {!hideAttachmentDescAndName && (
                  <p>
                    <span className="fw-bold">Description:</span>
                    {item.description && item.description.length > 25 && (
                      <>{item.description.slice(0, 25) + "..."}</>
                    )}
                    {item.description &&
                      item.description.length <= 25 &&
                      item.description}
                  </p>
                )}
              </div>
            );
          })}
      </div>
    );

  return (
    <>
      <RecursiveNestedQuestions
        observation={observation}
        index={index}
        showObservations={showObservations}
        showAttachments={showAttachments}
        hideAttachmentDescAndName={hideAttachmentDescAndName}
        showEmptyFlexibleQuestions={showEmptyFlexibleQuestions}
        setAnsweredArray={setAnsweredArray}
        responses={responses}
        riskLevels={riskLevels}
        complianceLabel={complianceLabel}
        riskLabel={riskLabel}
        correctiveActionLabel={correctiveActionLabel}
        _showCompliance={_showCompliance}
        _showRisk={_showRisk}
        _showCorrectiveAction={_showCorrectiveAction}
        compressedImages={compressedImages}
        setCompressedImages={setCompressedImages}
        _showNotes={_showNotes}
        _showCasualFactor={_showCasualFactor}
        _showRecommendation={_showRecommendation}
        _showConformityLevel={_showConformityLevel}
        questionOptions={questionOptions}
        mergedQuestions={mergedQuestions}
        sectionId={sectionId}
        heatmapChoicesList={heatmapChoicesList}
        showRiskMatrices={showRiskMatrices}
      />

      {_showRisk &&
        (riskMap(observation.risk, riskLevels) ||
          showEmptyFlexibleQuestions) && (
          <div className="my-1">
            <strong className="w-50 fw-bold me-2">
              {riskLabel?.name || "Risk Rating"}:
            </strong>
            {riskMap(observation.risk, riskLevels)}
          </div>
        )}
      {_showCompliance &&
        (complianceMap(observation.compliance) ||
          showEmptyFlexibleQuestions) && (
          <div className="my-1">
            <strong className="fw-bold me-2">
              {complianceLabel?.name || "Compliance Observed"}:
            </strong>
            {complianceMap(observation.compliance)}
          </div>
        )}
      {_showCorrectiveAction &&
        (observation?.correctiveAction || showEmptyFlexibleQuestions) && (
          <div className="my-1">
            <strong className="w-50 fw-bold me-2">
              {correctiveActionLabel?.name || "Corrective Action"}:
            </strong>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  capitalizeFirstLetter(observation?.correctiveAction)?.replace(
                    /\n/g,
                    "<br/>"
                  )
                ),
              }}
            ></span>
          </div>
        )}
      {_showConformityLevel &&
        (conformityLevelMap(observation.conformityLevel, conformity) ||
          showEmptyFlexibleQuestions) && (
          <>
            <div className="my-1">
              <strong className="w-50 fw-bold me-2">
                {conformityLabel?.name || "Conformity Level"}:{" "}
              </strong>

              {conformityLevelMap(observation.conformityLevel, conformity)}
            </div>
            {observation?.conformityInapplicableReasons &&
              observation.conformityLevel ===
                CONFORMITY_LEVEL_NOT_APPLICABLE && (
                <div className="my-1">
                  <strong className="w-50 fw-bold me-2">
                    Why is the conformity level not applicable?:
                  </strong>
                  {observation.conformityInapplicableReasons}
                </div>
              )}
          </>
        )}
      {_showNotes && (observation?.notes || showEmptyFlexibleQuestions) && (
        <div className="my-1">
          <strong className="w-50 fw-bold me-2">
            {notesLabel?.name || "Notes"}:
          </strong>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                capitalizeFirstLetter(observation?.notes)?.replace(
                  /\n/g,
                  "<br/>"
                )
              ),
            }}
          ></span>
        </div>
      )}
      {_showCasualFactor &&
        (observation?.causalFactors || showEmptyFlexibleQuestions) && (
          <div className="my-1">
            <strong className="w-50 fw-bold me-2">
              {causalFactorsLabel?.name || "Causal Factors"}:
            </strong>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  capitalizeFirstLetter(observation?.causalFactors)?.replace(
                    /\n/g,
                    "<br/>"
                  )
                ),
              }}
            ></span>
          </div>
        )}
      {_showRecommendation &&
        (observation?.recommendation || showEmptyFlexibleQuestions) && (
          <div className="my-1">
            <strong className="w-50 fw-bold me-2">
              {recommendationLabel?.name || "Best Practice"}:
            </strong>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  capitalizeFirstLetter(observation?.recommendation)?.replace(
                    /\n/g,
                    "<br/>"
                  )
                ),
              }}
            ></span>
          </div>
        )}
      {showAttachments && (
        <div className={"d-flex flex-column my-1 py-1"}>
          <span className="fw-bold">Attachments:</span>
          {Array.isArray(observation.attachments) &&
            observation.attachments.map((item) => {
              const fileEXT = getURLFileExtension(item.url || "");

              return (
                <div className="d-flex flex-column px-0" key={item.fileId}>
                  <div>
                    {compressedImages &&
                      ["png", "jpg", "jpeg", "svg"].includes(fileEXT) && (
                        <>
                          <Image
                            className="img-fluid attachment-image"
                            src={compressedImages[item.fileId]}
                            fluid
                          />
                          {!hideAttachmentDescAndName && (
                            <div>
                              <small>
                                <i>
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item?.name && <span>{item.name}</span>}
                                    {!item?.name && (
                                      <div>
                                        <span>
                                          {item.fileId}.{fileEXT}
                                        </span>
                                      </div>
                                    )}
                                  </a>
                                </i>
                              </small>
                            </div>
                          )}
                        </>
                      )}

                    {compressedImages &&
                      !["png", "jpg", "jpeg", "svg"].includes(fileEXT) && (
                        <div className="d-flex flex-row align-items-center">
                          {" "}
                          <span
                            className={`fiv-sqo fiv-size-md lh-base fiv-icon-${fileEXT}`}
                          ></span>
                          <small>
                            <i>
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item?.name && (
                                  <span className="mx-1">{item.name}</span>
                                )}
                                {!item?.name && (
                                  <span className="mx-1">
                                    {item.fileId}.{fileEXT}
                                  </span>
                                )}
                              </a>
                            </i>
                          </small>
                        </div>
                      )}
                  </div>
                  {!hideAttachmentDescAndName && (
                    <p>
                      <span className="fw-bold">Description:</span>
                      {item.description && item.description.length > 25 && (
                        <>{item.description.slice(0, 25) + "..."}</>
                      )}
                      {item.description &&
                        item.description.length <= 25 &&
                        item.description}
                    </p>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default ListObservationExport;
