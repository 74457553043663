import React from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import { validateImageFormat, compressImage } from "utils/UploadUtils";
import DragAndDrop from "components/common/DragAndDrop";

const GeoTagCreateImage = ({ setGeoLocation }) => {
  const { errors, touched, setFieldValue, setFieldTouched, setErrors } =
    useFormikContext();

  const validateImageSize = (value) => {
    if (value && value?.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].size > 2048000) {
          return false;
        }
      }
    }
    return true;
  };

  const onCompressImage = async (img) => {
    for (let i = 0; i < img.length; i++) {
      img[i] = await compressImage(img[i]);
    }
    setFieldValue("images", img);
    setGeoLocation((prev) => ({ ...prev, images: img }));
    const validSize = validateImageSize(img);
    if (!validSize) setErrors({ images: "File Size is greater than 2 MB" });
  };

  return (
    <Form.Group controlId="images" className="mb-3">
      <Form.Label>Upload Files</Form.Label>
      <DragAndDrop
        onDrop={(files) => {
          setFieldTouched("images", true, false);
          const validFormat = validateImageFormat(files);
          if (validFormat) onCompressImage(files);
          if (!validFormat)
            setErrors({
              images:
                "Wrong File Type. Only images (.jpeg, .jpg, .png) are accepted.",
            });
        }}
      />
      {errors.images && touched?.images && (
        <small className="text-danger">{errors.images}</small>
      )}
    </Form.Group>
  );
};
GeoTagCreateImage.propTypes = {
  setGeoLocation: PropTypes.func,
};
export default GeoTagCreateImage;
