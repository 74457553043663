import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";

import SearchDetails from "./SearchDetails";

const Search = () => {
  return (
    <>
      <Helmet>
        <title>Search Page</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<SearchDetails />} />
      </Routes>
    </>
  );
};

export default Search;
