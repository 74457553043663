import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import PropTypes from "prop-types";

import ErrorHandler from "components/ui/ErrorHandler";
import { destroy } from "utils/DeApi";
import { useRoles, useToast } from "hooks";
import { UserContext } from "contexts/UserProvider";

const ActionItemDelete = ({
  actionItem,
  onActionItemDeleted,
  handleClose: updateOffCanvanOnClose,
}) => {
  const {
    actionItemId,
    assignedBy: { subscriberId },
  } = actionItem;
  const { isCertifier } = useRoles();
  const toast = useToast();
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleClose = () => {
    setShow(false);
    updateOffCanvanOnClose();
  };
  const handleShow = () => setShow(true);

  const commentCreator = (user) => {
    return user?.subscriberId === subscriberId;
  };

  const deleteActionItem = () => {
    setError(null);
    setIsLoading(true);

    const actionItemPromise = destroy(`/action-items/${actionItemId}`);

    actionItemPromise.promise
      .then((resp) => {
        handleClose();
        onActionItemDeleted(actionItem);
        toast.success(
          "Success",
          "The action Item has been deleted successfully"
        );
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(actionItemPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  if (isCertifier) return <></>;
  if (!commentCreator(user, subscriberId)) return <></>;

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        className="border-0"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>{" "}
        Delete Action Item
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">
            Delete Action Item -{" "}
            <small translate="no" className="text-muted">
              {actionItem?.item}
            </small>
          </h2>
          <p className="mx-4 mt-2">
            <Form.Group>
              <Form.Check
                label={
                  <small>
                    By checking the box, I confirm that I want to delete this
                    Action Item. I understand that this cannot be undone.
                  </small>
                }
                onClick={() => {
                  setIsChecked(!isChecked);
                }}
              />
            </Form.Group>
          </p>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="danger"
            onClick={deleteActionItem}
            size="sm"
            disabled={!isChecked || isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            <span>Delete</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionItemDelete;

ActionItemDelete.propTypes = {
  actionItem: PropTypes.object.isRequired,
  onActionItemDeleted: PropTypes.func.isRequired,
};
