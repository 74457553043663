import React from "react";

import { Col, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { aggregationOfBestPracticesAndFindings } from "helper/reporting";
import BestPracticesPieChart from "features/organization/components/Organization/Overview/OrganizationBestPractices/BestPracticesPieChart/BestPracticesPieChart";

const FacilityBestPractices = ({ protocol, data, isLoading, error }) => {
  const graphLabels = {
    finding: "Findings",
    bestPractise: "Best Practices",
  };

  const allHaveZeroValues =
    data?.every(
      (item) => item.stats.bestPractise === 0 && item.stats.finding === 0
    ) || data?.length === 0;

  if (error) return <ErrorHandler error={error} />;
  return (
    <Row>
      {isLoading ? (
        <Loader />
      ) : data && !allHaveZeroValues ? (
        <>
          <Col xs={12} sm={12} md={12} lg={5} className="border-end">
            <p className="text-center w-100 text-truncate text-capitalize">
              Distribution of Findings and Best Practices
            </p>
            <hr />
            <BestPracticesPieChart
              size="square-container"
              labels={graphLabels}
              data={aggregationOfBestPracticesAndFindings(data)}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} className="mb-2">
            <p className="text-center w-100 text-truncate text-capitalize">
              Findings and Best Practices Across Principles
              <small className="text-muted">&nbsp;&nbsp;{protocol?.name}</small>
            </p>
            <hr />
            <ReportTrend
              size="square-container"
              labels={graphLabels}
              data={aggregationOfBestPracticesAndFindings(data)}
              chartHeight={280}
            />
          </Col>
        </>
      ) : (
        allHaveZeroValues && (
          <Col>
            <EmptyStateHandler
              title="No data to visualize"
              description="You have no data to visualize."
            />
          </Col>
        )
      )}
    </Row>
  );
};

export default FacilityBestPractices;
