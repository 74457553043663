import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import * as yup from "yup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import { getURLFileExtension } from "utils/StringUtils";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

const FileUpdate = ({ fileId, file, onFileUpdated }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fileURL = file.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateFile = (description) => {
    setError(null);
    setIsLoading(true);

    const auditPromise = put(`files/${fileId}`, {
      description,
    });
    auditPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onFileUpdated(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization]);

  const schema = yup.object().shape({
    description: yup.string().required("Description is required"),
  });

  if (organization?.invitation?.role === "Certifier") return <Fragment />;

  return (
    <>
      <Button
        onClick={handleShow}
        variant="outline-primary"
        size="sm"
        className="border-dark border-opacity-25"
      >
        <span className="md-18 material-symbols-outlined ">edit</span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update File</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { description } = values;
            updateFile(description);
          }}
          initialValues={{
            description: file.description,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="mb-3">
                  <a href={fileURL} target="_blank" rel="noreferrer">
                    <span
                      className={`fiv-sqo fiv-size-md lh-base fiv-icon-${fileEXT}`}
                    ></span>
                    <span className="mx-3">
                      {file?.name || file?.description}
                    </span>
                  </a>
                </div>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>
                    Description <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.description && touched.description}
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={!values.description || isLoading}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Update File
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default FileUpdate;

FileUpdate.propTypes = {
  fileId: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  onFileUpdated: PropTypes.func.isRequired,
};
