import { removePTags } from "../../../utils/removePTags";
import PropTypes from "prop-types";

const RenderDynamicMetadata = ({ subjectType = "", audit = {} }) => {
  if (subjectType === "subscriberFacility") {
    return (
      <small>
        <p>
          Member: {audit?.subscriber?.firstName} {audit?.subscriber?.lastName}
        </p>
        <p>Entity: {audit?.facility?.name}</p>
      </small>
    );
  }

  if (subjectType === "subscriberOrganization") {
    return (
      <small>
        <p>
          Member: {audit?.subscriber?.firstName} {audit?.subscriber?.lastName}
        </p>
      </small>
    );
  }

  if (subjectType === "observation") {
    return (
      <small>
        <p>Assessment: {audit?.observation?.audit?.name}</p>
        <p>
          Question:{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: removePTags(audit?.observation?.question?.prompt),
            }}
          />
        </p>
      </small>
    );
  }

  if (subjectType === "audit") {
    return (
      <small>
        <p>Assessment: {audit?.audit?.name}</p>
      </small>
    );
  }

  if (subjectType === "response") {
    return (
      <small>
        <p>Assessment: {audit?.response?.audit?.name}</p>
        <p>
          Question:{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: removePTags(audit?.response?.question?.prompt),
            }}
          />
        </p>
      </small>
    );
  }

  if (subjectType === "facility") {
    return (
      <small>
        <p>Entity Name: {audit?.facility?.name}</p>
      </small>
    );
  }

  if (subjectType === "subscriberPortfolio") {
    return (
      <small>
        <p>
          Member: {audit?.subscriber?.firstName} {audit?.subscriber?.lastName}
        </p>
        <p>Portfolio: {audit?.portfolio?.name}</p>
      </small>
    );
  }

  if (subjectType === "media") {
    return (
      <small>
        <p>File: {audit?.media?.name}</p>
        <p>Description: {audit?.media?.description}</p>
      </small>
    );
  }

  if (subjectType === "file") {
    return (
      <small>
        <p>File: {audit?.file?.name}</p>
        <p>Description: {audit?.file?.description}</p>
      </small>
    );
  }

  return <></>;
};

RenderDynamicMetadata.propTypes = {
  subjectType: PropTypes.string.isRequired,
  audit: PropTypes.object,
};

export default RenderDynamicMetadata;
