import { useContext, useEffect, useRef, useState } from "react";

import { useRoles } from "hooks";
import { groupBy, sortBy, uniqBy } from "lodash";
import { Form, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { put } from "utils/DeApi";

import "./styles.scss";

const AuditInActive = () => {
  const subscribedPromises = useRef([]);
  const { isAdmin, isCertifier, isContributor } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const isInapplicable = audit?.observations?.filter((observation) => {
    return Boolean(observation.isInapplicable);
  });

  const [toggle, setToggle] = useState(
    isInapplicable.length === audit?.questions?.length
  );

  const auditInapplicable = (newToggle) => {
    setIsLoading(true);
    const observationPromises = audit?.protocol?.sections
      .filter((section) => !!section?.questions?.length)
      .map(({ sectionId }) => {
        const observationPromise = put(
          `audits/${audit?.auditId}/sections/${sectionId}/is-inapplicable`,
          {
            isInapplicable: newToggle,
          }
        );
        subscribedPromises.current.push(observationPromise);
        return observationPromise.promise;
      });
    Promise.all(observationPromises)
      .then((response) => {
        let observations = [...audit?.observations];
        let questions = [...audit?.questions];
        response
          .flatMap(({ data }) => data)
          .forEach((updatedObservation) => {
            const found = observations.find(
              ({ observationId }) =>
                observationId === updatedObservation.observationId
            );

            if (found) {
              observations = observations.map((o) => {
                if (o.observationId === found?.observationId) {
                  return {
                    ...o,
                    ...updatedObservation,
                  };
                }
                return o;
              });
            } else {
              observations.push({
                ...updatedObservation,
                attachments: [],
                reponses: [],
                children: [],
                commentsCount: 0,
              });
            }
          });

        //remove duplicates
        const observationUnique = !audit?.protocol?.hasObservationSets
          ? uniqBy(sortBy(observations, "createdAt").reverse(), "questionId")
          : observations;

        const groupedObservations = groupBy(observationUnique, "questionId");

        setAudit({
          ...audit,
          observations: observationUnique,
          questions: questions.map((question) => ({
            ...question,
            observations: groupedObservations[question.questionId] || [],
          })),
        });
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (audit.locked && !isAdmin) return <></>;
  if (isCertifier || isContributor) return <></>;
  if (audit?.confidential && !isAdmin) return <></>;

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="flex-fill">
          <Form.Switch
            onChange={() => {
              setToggle((prevState) => {
                const newToggle = !prevState;
                auditInapplicable(newToggle);
                return newToggle;
              });
            }}
            type="switch"
            value={toggle}
            checked={toggle}
            disabled={isLoading || audit?.locked}
            name="isInapplicable"
            label={<h5>Mark Assessment as not applicable</h5>}
          />
        </div>
        {isLoading ? (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="primary"
          />
        ) : (
          <small>
            <strong>{isInapplicable?.length}</strong> of{" "}
            <strong>{audit?.questions?.length}</strong> questions
          </small>
        )}
      </div>
      {error && <ErrorHandler error={error} />}
      <hr />
    </>
  );
};

AuditInActive.propTypes = {};

export default AuditInActive;
