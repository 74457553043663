import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import Organization from "../Organization/Organization";
import "./Organizations.css";

const Organizations = ({ organizations = [] }) => {
  if (!organizations.length) return <span>No organizations to display</span>;

  return (
    <>
      <div className="d-flex flex-row py-3 pb-0">
        <h4 className="flex-fill">Organizations</h4>
      </div>
      <Table hover responsive className="table-wrapper">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Role</th>
            <th>Date Joined</th>
            <th>Tagged Entities</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {organizations.map((organization) => (
            <Organization key={organization?.id} organization={organization} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

Organizations.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object),
};

export default Organizations;
