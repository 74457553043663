import { Formik } from "formik";
import { useState, useEffect } from "react";
import { Alert, Button, Form, Offcanvas } from "react-bootstrap";
import { post, get } from "utils/DeApi";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import { reportValidationSchema } from "features/reports/schemas";

const AddReportModal = ({
  organizationId,
  fetchEmbedReport,
  subscribedPromises,
  showAddModal,
  setShowAddModal,
  reportTypes,
  handleUpdateData,
}) => {
  const [showError, setShowError] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function addReportsObject(requestBody) {
    try {
      setIsLoading(true);
      const findingsPromise = post(
        `/organizations/${organizationId}/embedded-reports`,
        { ...requestBody }
      );
      findingsPromise.promise
        .then(async ({ data }) => {
          if (data && data.length < 1) {
            return;
          }
          setShowAddModal(false);
          fetchEmbedReport();
          await handleUpdateData(data, "add");
        })
        .catch((error) => {
          setShowError(error.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      subscribedPromises.current.push(findingsPromise);
    } catch (err) {
      setShowError(err);
    }
  }

  const handleSave = (values, { setSubmitting }) => {
    setSubmitting(true);
    const requestBody = {
      title: values.title,
      description: values.description || null,
      iframe_code: values.iframeCode,
      report_type: Number(values.reportType),
      is_active: values.isActive,
      power_bi_workspace_id: values.workspace_id || null,
      assigned_to: values.assignedTo
        .filter((tmpAssigne) => tmpAssigne)
        .map((tmpAssigne) => tmpAssigne.subscriberId),
    };
    addReportsObject(requestBody);
    setSubmitting(false);
  };

  const onHide = () => {
    setShowAddModal(false);
    setShowError("");
  };

  useEffect(() => {
    const handleFetchSubscribers = () => {
      setIsExpanding(true);
      const subscribersPromise = get("subscribers", {
        params: {
          organizationId,
        },
      });

      subscribersPromise.promise
        .then((response) => {
          setSubscribers(response.data);
        })
        .catch((error) => {
          setShowError(error.data.message);
        })
        .finally(() => {
          setIsExpanding(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    handleFetchSubscribers();
  }, [subscribedPromises, organizationId]);

  return (
    <Offcanvas
      className="w-fixed-640 overflow-auto"
      show={showAddModal}
      onHide={onHide}
      placement="end"
    >
      <Offcanvas.Header className="border-bottom" closeButton>
        <Offcanvas.Title>Add Report</Offcanvas.Title>
      </Offcanvas.Header>
      <div className="overflow-auto">
        <Formik
          enableReinitialize
          initialValues={{
            title: "",
            description: "",
            iframeCode: "",
            reportType: "",
            isActive: false,
            workspace_id: "",
            assignedTo: [],
          }}
          validationSchema={reportValidationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Body className="vh-100">
                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.title && !!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="iframeCode">
                  <Form.Label>Iframe Code</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="iframeCode"
                    value={values.iframeCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.iframeCode && !!errors.iframeCode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.iframeCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="reportType">
                  <Form.Label>Report Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="reportType"
                    value={values.reportType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.reportType && !!errors.reportType}
                  >
                    {reportTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.reportType}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="isActive">
                  <Form.Check
                    type="checkbox"
                    label="Is Active"
                    name="isActive"
                    checked={values.isActive}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.isActive && !!errors.isActive}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.isActive}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="assigned-to" className="mb-5">
                  <Form.Label className="mb-1">Assigned To</Form.Label>
                  <Typeahead
                    id="subscribers-typeahead"
                    clearButton
                    placeholder="Select a user..."
                    isLoading={isExpanding}
                    labelKey={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    isValid={
                      !!values.assignedTo.length &&
                      !errors.assignedTo &&
                      touched.assignedTo
                    }
                    isInvalid={
                      !(values.assignedTo && !errors.assignedTo) &&
                      touched.assignedTo
                    }
                    onChange={(assignedTo) => {
                      setFieldValue("assignedTo", assignedTo);
                      if (!assignedTo.length)
                        setSubscribers((prev) =>
                          uniqBy([...prev, ...subscribers], "subscriberId")
                        );
                    }}
                    options={subscribers}
                    selected={values.assignedTo}
                    multiple
                    renderToken={(option, props) => {
                      return (
                        <Token
                          onRemove={() => {
                            setFieldValue(
                              "assignedTo",
                              values.assignedTo.filter(
                                (a) => a.subscriberId !== option.subscriberId
                              )
                            );
                            setSubscribers((prev) =>
                              uniqBy([...prev, option], "subscriberId")
                            );
                          }}
                        >
                          {option?.firstName} {option?.lastName}
                        </Token>
                      );
                    }}
                  />
                </Form.Group>
                {values.reportType === "1" && (
                  <Form.Group className="mb-3" controlId="workspace_id">
                    <Form.Label>Workspace ID</Form.Label>

                    <Form.Control
                      type="text"
                      name="workspace_id"
                      value={values.workspace_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.workspace_id && !!errors.workspace_id}
                    />
                    {values.reportType === "1" && (
                      <Form.Control.Feedback type="invalid">
                        {errors.workspace_id}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
              </Offcanvas.Body>
              <div className="sticky-bottom p-3 bg-light text-end mt-3">
                <Button size="sm" variant="outline-secondary" onClick={onHide}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  variant="primary"
                  disabled={isLoading}
                  className="ms-2"
                >
                  {isLoading ? "Saving..." : "Add"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {showError !== "" && (
          <Alert variant="info" className={`my-3 d-flex flex-row`}>
            <div className="me-3">
              <span
                translate="no"
                className="material-symbols-outlined md-18 text-primary text-opacity-75"
              >
                info
              </span>
            </div>
            <div>
              <h5 className="mb-1">
                <small>Error</small>
              </h5>
              <p className="mb-1">
                <small>{showError}</small>
              </p>
            </div>
          </Alert>
        )}
      </div>
    </Offcanvas>
  );
};

AddReportModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  fetchEmbedReport: PropTypes.func.isRequired,
  subscribedPromises: PropTypes.array,
  showAddModal: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired,
  reportTypes: PropTypes.array,
};

export default AddReportModal;
