import PropTypes from "prop-types";
import "./Attachments.scss";

const Attachments = ({ files, isActive }) => {
  if (!files || !files.length > 0) return <></>;

  return (
    <div className="position-relative">
      <span
        translate="no"
        className={`material-symbols-outlined my-1 md-18 my-1 text-opacity-75 ${
          isActive ? "text-light" : "text-secondary"
        }`}
      >
        description
      </span>
      <span
        className="position-absolute start-100 translate-middle attachment-badge rounded-pill bg-danger text-white"
        style={{ top: "30%" }}
      >
        <small>{files.length}</small>
      </span>
    </div>
  );
};

Attachments.propTypes = {
  files: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Attachments;
