const subjectTypes = [
  {
    key: "media",
    label: "Files",
  },
  {
    key: "facility",
    label: "Entity",
  },
  {
    key: "audit",
    label: "Assessment",
  },
  {
    key: "observation",
    label: "Observation",
  },
  {
    key: "file",
    label: "Assessment Files",
  },
  {
    key: "subscriberFacility",
    label: "Member Entity Access",
  },
  {
    key: "subscriberPortfolio",
    label: "Member Portfolio Access",
  },
  {
    key: "subscriberOrganization",
    label: "Member Organization Access",
  },
  {
    key: "response",
    label: "Observation Question Response",
  },
];

export default subjectTypes;
