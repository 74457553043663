import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

export function useFetchSearchContents(searchTerm, page, setIsLoading) {
  const { id: organizationId } = useContext(OrganizationContext) || "";
  const subscribedPromises = useRef([]);

  const [searchResult, setSearchResult] = useState([]);
  const [error, setError] = useState(undefined);
  const [totalPages, setTotalPages] = useState(2);

  useEffect(() => {
    const fetchSearchResults = async (pageNumber) => {
      setIsLoading(true);
      const searchContentsPromise = get(
        `organizations/${organizationId}/search`,
        {
          params: {
            keyword: searchTerm,
            page: pageNumber,
          },
        }
      );

      searchContentsPromise.promise
        .then((response) => {
          const contents = response.data;
          setSearchResult(contents);
          setTotalPages(response?.totalPages);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
          setError(undefined);
        });

      subscribedPromises.current.push(searchContentsPromise);
    };

    fetchSearchResults(page);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [searchTerm, organizationId, page, setIsLoading]);

  return { error, totalPages, searchResult };
}
