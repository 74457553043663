import { useContext } from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { showRecommendation } from "features/assessment/components/Audit/helper";

import FieldClearButton from "../FieldClearButton";
import ActionItemCreate from "features/actionItems/components/common/ActionItemCreate";

const FieldRecommendation = ({
  isDisabled,
  setActionItems,
  showActionItem,
}) => {
  const { audit } = useContext(AuditContext);
  const { auditId, questionId } = useParams();
  const { protocol, facilityId } = audit;
  const { conformity, questionOptions } = protocol;
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();
  const [, , , , recommendationLabel, , ,] = questionOptions || [];
  const _showRecommendation = showRecommendation(conformity, questionOptions);

  if (!_showRecommendation) return <></>;

  const clearSelection = () => {
    setFieldValue("recommendation", "");
  };

  return (
    <Form.Group controlId="recommendation" className="my-3">
      <Form.Label className="d-flex justify-content-between align-items-center">
        <div>
          <strong>{recommendationLabel?.name || "Best Practice"}</strong>
        </div>
        <div className="d-flex align-items-center">
          {showActionItem && (
            <ActionItemCreate
              variant="light"
              className="text-nowrap float-none me-2 bg-primary bg-opacity-10 text-dark"
              auditId={auditId}
              title="New Action Item"
              taskableId={questionId}
              taskableType={"question"}
              disabled={!values["recommendation"] || isDisabled}
              description={values["recommendation"] || ""}
              onActionItemCreated={(item) => {
                setActionItems((prevState) => [item, ...prevState]);
              }}
              facilityId={facilityId}
            />
          )}
          <FieldClearButton
            handleOnClick={clearSelection}
            isDisabled={isDisabled}
          />
        </div>
      </Form.Label>
      <Form.Control
        rows={3}
        as="textarea"
        name="recommendation"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.recommendation}
        disabled={values.isInapplicable || isDisabled}
        placeholder="Enter your answer here"
      />
    </Form.Group>
  );
};

FieldRecommendation.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  setActionItems: PropTypes.func.isRequired,
  showActionItem: PropTypes.bool.isRequired,
};

export default FieldRecommendation;
