import { useEffect, useRef, useState } from "react";
import { destroy } from "utils/DeApi";

import { useToast } from "hooks";
const useDeleteAuditFile = ({ file, onFileDelete }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const deleteAuditFile = () => {
    setError(null);
    setIsLoading(true);

    const auditFileDeletePromise = destroy(`/files/${file?.fileId}`);

    auditFileDeletePromise.promise
      .then((resp) => {
        onFileDelete(file);
        toast.success("Success", "File has been deleted successfully");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          toast.error("Error", "Failed to delete the file");
        }
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditFileDeletePromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);
  return {
    isLoading,
    error,
    deleteAuditFile,
  };
};

export default useDeleteAuditFile;
