import React, { useContext, useEffect, useState, useRef } from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Collapse, Form, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAuditMapping } from "features/assessment/hooks";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useUpdateSectionsInapplicable } from "features/assessment/services";

import Assignees from "./Assignees";
import QuestionTag from "./QuestionTag";
import Attachments from "./Attachments";
import QuestionIsDone from "./QuestionIsDone";
import { questionIsAnswered } from "../../helper";

const SectionNavigation = ({
  open,
  section,
  sectionIndex,
  toggleSectionHandler,
}) => {
  let location = useLocation();
  const navigate = useNavigate();
  const { isAdmin, isCertifier } = useRoles();
  const { audit } = useContext(AuditContext);
  const { observationsInapplicabilityMapping } = useAuditMapping();

  const questionRef = useRef({});
  const [toggle, setToggle] = useState(false);

  const questions = audit?.questions
    .filter(({ sectionId: id }) => id === section?.sectionId)
    .filter(({ assignedMembers = [] }) => {
      if (!audit?.selectedAssignee) return true;

      return assignedMembers.find(({ subscriberId }) => {
        return subscriberId === audit?.selectedAssignee[0]?.subscriberId;
      });
    });

  const { isLoading, updateSectionsInapplicable } =
    useUpdateSectionsInapplicable({
      auditId: audit?.auditId,
      onSuccess: (data = []) => {
        observationsInapplicabilityMapping(data);
      },
    });

  const allAnsweredQuestions = questions
    .map(({ observations }) => {
      if (!observations.length) return false;
      return observations.every((observation) =>
        questionIsAnswered(audit, observation)
      );
    })
    .filter(Boolean);

  useEffect(() => {
    const InapplicableQuestions = questions
      .map(({ observations }) => {
        if (!observations.length) return false;
        return observations.every((observation) => {
          return observation.isInapplicable;
        });
      })
      .filter(Boolean);

    setToggle(InapplicableQuestions.length === questions.length);
  }, [questions]);

  const viewFirstQuestionByDefault = () => {
    if (open && !location.pathname.includes(section.sectionId)) {
      const questionId = questions[0]?.questionId;
      if (questionId) {
        navigate(
          `/audits/${audit.auditId}/sections/${section.sectionId}/questions/${questionId}`
        );
        setTimeout(() => {
          if (questionRef.current[questionId]) {
            scrollToQuestion(questionRef.current[questionId]);
          }
        }, 100);
      }
    }
  };

  const scrollToQuestion = (element) => {
    const container = document.querySelector(".sidebar-container");
    if (container && element) {
      const containerHeight = container.clientHeight;
      const elementHeight = element.clientHeight;
      const elementOffsetTop = element.offsetTop;

      const scrollToFocus =
        elementHeight === 0
          ? parseFloat(sessionStorage.getItem("scrollPosition")) || 0
          : elementOffsetTop - containerHeight / 2 + elementHeight / 2;

      if (elementHeight !== 0) {
        sessionStorage.setItem("scrollPosition", scrollToFocus);
      }

      requestAnimationFrame(() => {
        container.scrollTo({
          top: scrollToFocus,
          behavior: "smooth",
        });
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const currentPath = location.pathname;
      const activeQuestionId = currentPath.split("/").pop();
      if (questionRef.current?.[activeQuestionId]) {
        scrollToQuestion(questionRef.current[activeQuestionId]);
      }
    };

    const currentPath = location.pathname;
    const activeQuestionId = currentPath.split("/").pop();

    setTimeout(() => {
      if (questionRef.current?.[activeQuestionId]) {
        scrollToQuestion(questionRef.current[activeQuestionId]);
      }
    }, 100);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [questions, location.pathname, open]);

  if (toggle && !audit?.showInapplicableSections && !isLoading) return null;

  return (
    <div className="py-1 my-0 w-100">
      <div
        className="w-100 pointer"
        data-cy={`section-${sectionIndex}`}
        onClick={() => toggleSectionHandler(section.sectionId)}
      >
        <div className="d-flex">
          <span
            className={`align-self-baseline ${
              toggle && "text-secondary text-opacity-75"
            }`}
          ></span>
        </div>
        <div className="d-flex">
          <span className="align-self-baseline text-muted">
            <span translate="no" className="material-symbols-outlined ">
              {open ? "expand_more" : "chevron_right"}
            </span>
          </span>
          <h3
            className={`align-self-baseline flex-grow-1 ${
              toggle && "text-secondary text-opacity-75"
            }`}
            role="button"
          >
            {section.title}{" "}
          </h3>
          <span className="align-content-end align-self-baseline text-nowrap text-muted">
            <span title="Final Weighted Score for Product Composition">
              {allAnsweredQuestions.length}
            </span>
            <span>/{questions.length}</span>
          </span>
        </div>
      </div>
      <Collapse in={open} onEnter={viewFirstQuestionByDefault}>
        <Nav defaultActiveKey={location?.pathname} variant="pills">
          {(!audit?.confidential || isAdmin) && (
            <div className="nav-link">
              <Form.Switch
                disabled={isCertifier || !questions?.length}
                onChange={() => {
                  setToggle((prevToggle) => !prevToggle);
                  updateSectionsInapplicable([
                    { sectionId: section.sectionId, isInapplicable: !toggle },
                  ]);
                }}
                type="switch"
                value={toggle}
                checked={toggle}
                name="isInapplicable"
                className="text-dark"
                data-cy={`section-toggle-${sectionIndex}`}
                label="Section is not applicable to the assessment"
              />
            </div>
          )}

          {questions.map(
            (
              {
                questionId,
                prompt,
                tags = [],
                sectionId,
                questionNo,
                observations = [],
                assignedMembers = [],
              },
              index
            ) => {
              let href = `/audits/${audit.auditId}/sections/${sectionId}/questions/${questionId}`;

              const isActive = location?.pathname === href;
              const key = `${section.sectionId}-${questionId}`;

              const attachments = observations.flatMap(
                ({ attachments }) => attachments || []
              );

              const isInApplicable =
                observations?.length &&
                observations.every(({ isInapplicable }) => isInapplicable);

              if (isLoading)
                return (
                  <Nav.Link className="placeholder-glow w-100" key={key}>
                    <span className="placeholder col-9 bg-primary placeholder-xs"></span>
                    <span className="placeholder col-12 bg-primary placeholder-xs"></span>
                    <span className="placeholder col-4 bg-primary placeholder-xs"></span>
                  </Nav.Link>
                );

              return (
                <Nav.Link
                  key={key}
                  as={Link}
                  to={href}
                  ref={(el) => (questionRef.current[questionId] = el)}
                  active={isActive}
                  data-cy={`section-${sectionIndex}-question-${index}`}
                  className={`${
                    isInApplicable &&
                    location?.pathname !== href &&
                    "text-secondary text-opacity-75"
                  } w-100`}
                >
                  <div className="w-100 d-flex flex-row"></div>
                  <div className="w-100 d-flex flex-row">
                    <div className="flex-fill">
                      <span className="me-2 float-start">
                        <strong> {++index}.</strong>{" "}
                        {questionNo && (
                          <small
                            className={`${
                              isActive
                                ? "bg-light bg-opacity-25 text-light"
                                : "bg-primary bg-opacity-25 text-dark"
                            }  border-0 px-2 rounded-1`}
                          >
                            {questionNo}
                          </small>
                        )}
                      </span>
                      <span dangerouslySetInnerHTML={{ __html: prompt }} />
                    </div>
                    <div className="d-flex flex-column ps-3">
                      <QuestionIsDone
                        observations={observations}
                        cypressId={`section-${sectionIndex}-question-${index}-check`}
                      />
                      <Attachments isActive={isActive} files={attachments} />
                      <Assignees
                        isActive={isActive}
                        assignedMembers={assignedMembers}
                      />
                    </div>
                  </div>
                  <div>
                    <QuestionTag isActive={isActive} tags={tags} />
                  </div>
                </Nav.Link>
              );
            }
          )}
        </Nav>
      </Collapse>
    </div>
  );
};
SectionNavigation.propTypes = {
  open: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  toggleSectionHandler: PropTypes.func.isRequired,
};
export default SectionNavigation;
