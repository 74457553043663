import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { useRoles } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import Loader from "components/ui/Loader";
import DataExport from "components/DataExport";
import ErrorHandler from "components/ui/ErrorHandler";
import BreadCrumbs from "components/ui/BreadCrumbs";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

import OrganizationAuditCreate from "./OrganizationAuditCreate";
import OrgAuditListFilters from "./OrgAuditListFilters/OrgAuditListFilters";
import AuditListExport from "../common/AuditList/AuditListExport";
import { formatDate } from "features/actionItems/utils";
import SortColumn from "components/ui/SortColumn";
import { useFetchOrgAudits } from "features/assessment/services";
import MetaPagination from "components/ui/MetaPagination";

const OrganizationAuditList = () => {
  const organization = useContext(OrganizationContext);
  const { searchIndexExists } = organization || false;
  const { isContributor, isCertifier } = useRoles();

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updated_at",
    updatedAt: true,
    sortAsc: false,
  });

  const [filters, setFilters] = useState({
    dueDate: [],
    updatedDate: [],
    entity: "",
    search: "",
    protocols: "",
    lockStatus: {
      index: 1,
      name: "All",
      locked: "",
      status: "",
    },
  });

  const updatingPage = (val) => setPage(val);

  const { protocols, lockStatus, search, entity, updatedDate, dueDate } =
    filters;
  const { sortAsc, sortColumn } = tableSortState;

  const assessmentsParams = useCallback(() => {
    return {
      sort: !!sortAsc ? sortColumn : `-${sortColumn}`,
      ...(entity?.facilityId
        ? {
            "filter[facility_id]": entity?.facilityId,
          }
        : {}),
      ...(updatedDate?.length
        ? {
            "filter[updated_at]": formatDate(updatedDate),
          }
        : {}),
      ...(dueDate?.length
        ? {
            "filter[due_date]": formatDate(dueDate),
          }
        : {}),
      ...(protocols?.protocolId
        ? {
            "filter[protocol_id]": protocols?.protocolId,
          }
        : {}),
      ...(lockStatus?.locked
        ? {
            "filter[locked]": lockStatus?.locked,
          }
        : {}),
      ...(lockStatus?.status
        ? {
            "filter[status]": lockStatus?.status,
          }
        : {}),
      ...(search
        ? {
            "filter[search]": search,
          }
        : {}),
    };
  }, [
    dueDate,
    entity?.facilityId,
    lockStatus?.locked,
    lockStatus?.status,
    protocols?.protocolId,
    search,
    sortAsc,
    sortColumn,
    updatedDate,
  ]);

  const { meta, error, assessments, isLoading, setError } = useFetchOrgAudits({
    page,
    perPage,
    params: assessmentsParams,
  });

  const sortAssessments = (sortingvalue) => {
    setTableSortState({
      sortAsc: !tableSortState.sortAsc,
      sortColumn: sortingvalue,
    });
  };

  const resettingFilters = () => {
    setFilters({
      dueDate: [],
      updatedDate: [],
      entity: "",
      protocols: "",
      lockStatus: {
        index: 1,
        name: "All",
        locked: "",
        status: "",
      },
      search: "",
    });
  };

  useEffect(() => {
    setPage(1);
  }, [filters]);

  return (
    <>
      <Helmet>
        <title>Assessments</title>
      </Helmet>
      <Container className="my-2 MainContent">
        <BreadCrumbs
          breadcrumbs={[
            { name: "Dashboard", link: "/" },
            { name: "Assessments", active: false },
          ]}
        />
        <div className="d-flex flex-row mb-3 align-items-center">
          <div className="flex-fill">
            <h1>Assessments</h1>
          </div>
          <div className="d-grid me-2">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => navigate(`/reports`)}
              className="px-4 py-1.5"
            >
              Reports{" "}
              <span translate="no" className="material-symbols-outlined md-18">
                arrow_forward
              </span>
            </Button>
          </div>
          {!isContributor && (
            <>
              {searchIndexExists && (
                <div className="d-grid">
                  <Button
                    variant="outline-primary"
                    as={Link}
                    to={"/search"}
                    size="sm"
                    className="me-2"
                  >
                    <span
                      translate="no"
                      className="material-symbols-outlined md-18"
                    >
                      search
                    </span>{" "}
                    Search in Assessments
                  </Button>
                </div>
              )}

              <div>
                <Dropdown translate="no" autoClose="outside" className="me-2 ">
                  <Dropdown.Toggle
                    size="sm"
                    variant="outline-primary"
                    className="w-100 text-start"
                  >
                    {isLoading ? (
                      <Spinner className="me-2" animation="border" size="sm" />
                    ) : (
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18 me-2"
                      >
                        download
                      </span>
                    )}{" "}
                    Export
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DataExport variant="" />
                    <Dropdown.Divider className="my-2" />
                    <AuditListExport
                      handleError={(err) => setError(err)}
                      params={assessmentsParams}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div>
                <OrganizationAuditCreate
                  organizationId={organization.id}
                  onAssessmentCreated={(assessment) => {
                    navigate(`/audits/${assessment?.auditId}`);
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div>
          <OrgAuditListFilters
            updatingPage={updatingPage}
            filters={filters}
            setFilters={setFilters}
            tableSortState={tableSortState}
            setTableSortState={setTableSortState}
            sortAssessments={sortAssessments}
            isLoading={isLoading}
            resettingFilters={resettingFilters}
          />
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
            <Card>
              <Card.Body className="table-responsive">
                <Table hover>
                  <thead>
                    <tr>
                      <th
                        className={`w-30 pointer ${
                          tableSortState.sortColumn === "name" ? "pb-1" : ""
                        }`}
                        onClick={() => !isLoading && sortAssessments("name")}
                      >
                        Name
                        <SortColumn
                          tableSortState={tableSortState}
                          column={"name"}
                        />
                      </th>
                      <th>Entity</th>
                      <th>Protocol</th>
                      <th
                        className={`pointer ${
                          tableSortState.sortColumn === "updated_at"
                            ? "pb-1"
                            : ""
                        }`}
                        onClick={() =>
                          !isLoading && sortAssessments("updated_at")
                        }
                      >
                        Updated
                        <SortColumn
                          tableSortState={tableSortState}
                          column={"updated_at"}
                        />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {isLoading ? (
                      <tr>
                        <td colSpan={8}>
                          <Loader />
                        </td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan={8}>
                          <ErrorHandler error={error} />
                        </td>
                      </tr>
                    ) : assessments && !assessments.length ? (
                      <tr>
                        <td colSpan={8}>
                          <EmptyStateHandler
                            title="No assessments found"
                            description="There are currently no assessments to show"
                          />
                        </td>
                      </tr>
                    ) : (
                      <Fragment />
                    )}
                    {assessments?.map(
                      ({
                        auditId,
                        facility,
                        name,
                        labels,
                        updatedAt,
                        protocol,
                        locked,
                        status,
                        archived,
                        confidential,
                        observationReportCommentsCount,
                      }) => (
                        <tr key={auditId}>
                          <td>
                            <h6>
                              <Link
                                to={`/audits/${auditId}/${
                                  isContributor ? "files" : ""
                                }`}
                                size="sm"
                                className="text-decoration-none bold"
                                variant="secondary"
                              >
                                {name}
                              </Link>
                            </h6>
                            {Array.isArray(labels) &&
                              labels.map(({ auditLabelId, name, color }) => (
                                <small
                                  key={auditLabelId}
                                  className="me-2 rounded-2 badge text-dark fw-normal opacity-75"
                                  style={{
                                    borderWidth: 1,
                                    borderColor: color,
                                    backgroundColor: `${color}30`,
                                    borderStyle: "solid",
                                  }}
                                >
                                  {name}
                                </small>
                              ))}
                            {archived && (
                              <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
                                <small className="text-warning">
                                  <span
                                    translate="no"
                                    className="material-symbols-outlined md-13"
                                  >
                                    archive
                                  </span>{" "}
                                  Archived
                                </small>
                              </Badge>
                            )}
                            {confidential && (
                              <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
                                <small className="text-danger">
                                  <span
                                    translate="no"
                                    className="material-symbols-outlined md-13"
                                  >
                                    block
                                  </span>{" "}
                                  Confidential
                                </small>
                              </Badge>
                            )}
                            {(locked || isCertifier) && (
                              <Badge className="bg-secondary bg-opacity-10 text-dark me-2 mb-1">
                                <small className="text-dark">
                                  <span
                                    translate="no"
                                    className="material-symbols-outlined md-13"
                                  >
                                    lock
                                  </span>{" "}
                                  View only
                                </small>
                              </Badge>
                            )}
                            {status === "Done" && (
                              <Badge className="bg-primary me-2 mb-1">
                                <small>
                                  <span
                                    translate="no"
                                    className="material-symbols-outlined md-13"
                                  >
                                    check
                                  </span>{" "}
                                  {status}
                                </small>
                              </Badge>
                            )}
                          </td>
                          <td>{facility?.name}</td>

                          <td>{protocol.name}</td>

                          <td>
                            <small>
                              {new Date(updatedAt).toLocaleString([], {
                                timeZone: "UTC",
                                dateStyle: "short",
                                timeStyle: "short",
                              })}
                            </small>
                          </td>
                          {!isContributor && (
                            <td>
                              <div className="d-flex justify-content-end">
                                {protocol?.hasCorrectiveAction && (
                                  <Button
                                    title="Corrective Actions"
                                    as={Link}
                                    to={`/audits/${auditId}/corrective-actions`}
                                    size="sm"
                                    variant="outline-secondary"
                                    className="position-relative mx-1"
                                  >
                                    <span
                                      translate="no"
                                      className="material-symbols-outlined md-18"
                                    >
                                      fact_check
                                    </span>
                                  </Button>
                                )}
                                <Button
                                  title="Assessment Report"
                                  as={Link}
                                  to={`/audits/${auditId}/reports`}
                                  size="sm"
                                  variant="outline-primary"
                                  className="position-relative mx-1"
                                >
                                  <>
                                    <span
                                      translate="no"
                                      className="material-symbols-outlined md-18"
                                    >
                                      description
                                    </span>
                                    {!!observationReportCommentsCount && (
                                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                        {observationReportCommentsCount}
                                        <span className="visually-hidden">
                                          unread messages
                                        </span>
                                      </span>
                                    )}
                                  </>
                                </Button>
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <MetaPagination
                  meta={meta}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

OrganizationAuditList.propTypes = {};

export default OrganizationAuditList;
