import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { RADIO_FIELD } from "../constants";
const FieldRadio = ({ value, question }) => {
  const { setFieldValue } = useFormikContext();

  if (question.type !== RADIO_FIELD) return <></>;

  const maxLabelLength = Math.max(
    ...question.choices.map(({ choice = "" }) => parseInt(choice.length))
  );

  const selected = question?.choices.find(({ id }) => {
    if (!value || !value?.response) return false;
    return value?.response === id;
  });

  return (
    <>
      {question.choices.length > 10 ? (
        <Typeahead
          clearButton
          labelKey="choice"
          id={question.id}
          options={question?.choices}
          placeholder={"Select an option..."}
          selected={selected ? [selected] : []}
          onChange={(data) => {
            const { id } = Array.isArray(data) && data.length ? data[0] : {};
            setFieldValue(`metadata.${question.id}`, {
              type: RADIO_FIELD,
              response: id,
            });
          }}
        />
      ) : (
        question.choices.map(({ id, choice }) => (
          <Form.Check
            key={id}
            id={id}
            value={id}
            type="radio"
            name={question.id}
            label={<>{choice}</>}
            inline={maxLabelLength < 30}
            className={maxLabelLength > 30 ? "mb-2" : ""}
            checked={value?.response ? value?.response === id : false}
            onChange={(ev) => {
              setFieldValue(`metadata.${question.id}`, {
                type: RADIO_FIELD,
                response: id,
              });
            }}
          />
        ))
      )}
      {value?.response === "" && (
        <div className="text-danger">This is a required field</div>
      )}
    </>
  );
};
Field.propTypes = {
  question: PropTypes.object.isRequired,
};
export default FieldRadio;
