import React, { useState } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { memoValidationSchema } from "features/assessment/schemas";
import useCreateMemo from "features/assessment/services/useCreateMemo";
const AuditMemoCreate = ({ onMemoCreate }) => {
  const { auditId } = useParams();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { error, isLoading, createMemo } = useCreateMemo({
    auditId,
    onMemoCreate: (data) => {
      handleClose();
      onMemoCreate(data);
    },
  });

  return (
    <>
      <Button
        onClick={handleShow}
        size="sm"
        className="float-end"
        variant="primary"
      >
        Add Notepad
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-bottom" closeButton>
          <Modal.Title>Add Notepad</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={memoValidationSchema}
          onSubmit={createMemo}
          initialValues={{
            key: "",
            value: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">
                    Title <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.key}
                    name="key"
                    type="text"
                    placeholder="Title"
                    isValid={values.key && !errors.key}
                    isInvalid={!(values.key && !errors.key) && touched.key}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.key && touched.key ? (
                      <small>{errors.key}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3 h-25">
                  <Form.Label className="mb-1">
                    Notes <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.value}
                    name="value"
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="Take a note..."
                    isValid={values.value && !errors.value}
                    isInvalid={
                      !(values.value && !errors.value) && touched.value
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.value && touched.value ? (
                      <small>{errors.value}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="me-2 px-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  className="px-3"
                  disabled={isLoading || !isValid}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Add Note
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

AuditMemoCreate.propTypes = {
  onMemoCreate: PropTypes.func.isRequired,
};

export default AuditMemoCreate;
