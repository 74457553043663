import React, { Fragment, useContext } from "react";

import SortColumn from "components/ui/SortColumn";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context/ActionItemProvider";
import PropTypes from "prop-types";

const ActionItemTableHeader = ({ selectedItems = [], setSelectedItems }) => {
  const { tableSortState, isLoading, tableData } =
    useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);

  const sortActionItems = (sortingvalue) => {
    dispatch({
      type: "SET_TABLE_SORT_STATE",
      payload: {
        sortAsc: !tableSortState.sortAsc,
        sortColumn: sortingvalue,
      },
    });
  };

  return (
    <thead>
      <tr className="align-baseline">
        <th></th>
        <th
          className="cursor-pointer"
          onClick={() => !isLoading && sortActionItems("item")}
          role="button"
        >
          Title
          <SortColumn tableSortState={tableSortState} column={"item"} />
        </th>
        {tableData
          .filter(({ hidden }) => !hidden)
          .map(({ title, key }) => (
            <Fragment key={key}>
              {typeof title === "function" &&
                title(isLoading, tableSortState, sortActionItems)}
            </Fragment>
          ))}
        <th>Action</th>
      </tr>
    </thead>
  );
};

ActionItemTableHeader.propTypes = {
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
};

export default ActionItemTableHeader;
