import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import AuditTrailResponse from "../AuditTrailResponse";
import AuditTrailSubscriberFacility from "../AuditTrailSubscriberFacility";
import AuditTrailFacility from "../AuditTrailFacility";
import AuditTrailAudit from "../AuditTrailAudit";
import AuditTrailSubscriberOrganization from "../AuditTrailSubscriberOrganization";
import AuditTrailSubscriberPortfolio from "../AuditTrailSubscriberPortfolio";
import { useFetchAuditTrailReview } from "features/activityLogs/services/useFetchAuditTrailReview";
import AuditTrailMedia from "../AuditTrailMedia";

const AuditTrailReview = ({ auditTrail }) => {
  const [show, setShow] = useState(false);
  const [renderedComponent, setRenderedComponent] = useState(<span />);
  const { isLoading, error, activityLog } = useFetchAuditTrailReview(
    auditTrail,
    show
  );

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const getRenderedComponent = () => {
      switch (activityLog?.subjectType) {
        case "response":
        case "observation":
          return (
            <AuditTrailResponse
              activityLog={activityLog}
              handleClose={handleClose}
              auditTrail={auditTrail}
            />
          );
        case "subscriberFacility":
          return (
            <AuditTrailSubscriberFacility
              activityLog={activityLog}
              handleClose={handleClose}
            />
          );
        case "facility":
          return (
            <AuditTrailFacility
              activityLog={activityLog}
              handleClose={handleClose}
            />
          );
        case "audit":
          return (
            <AuditTrailAudit
              activityLog={activityLog}
              auditTrail={auditTrail}
              handleClose={handleClose}
            />
          );
        case "subscriberOrganization":
          return (
            <AuditTrailSubscriberOrganization
              activityLog={activityLog}
              handleClose={handleClose}
            />
          );
        case "subscriberPortfolio":
          return (
            <AuditTrailSubscriberPortfolio
              activityLog={activityLog}
              handleClose={handleClose}
            />
          );
        case "media":
        case "file":
          return (
            <AuditTrailMedia
              activityLog={activityLog}
              handleClose={handleClose}
            />
          );
        case "action-item":
          return null;
        default:
          return <span />;
      }
    };

    setRenderedComponent(getRenderedComponent());
  }, [activityLog, handleClose, auditTrail]);

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} size="sm">
        Review
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Review</Modal.Title>
        </Modal.Header>
        {isLoading && <Loader />}
        {!isLoading && error && <ErrorHandler error={error} />}
        {!isLoading && !error && renderedComponent}
      </Modal>
    </>
  );
};

AuditTrailReview.propTypes = {
  auditTrail: PropTypes.object.isRequired,
};

export default AuditTrailReview;
