import { useContext } from "react";

import { AuditContext } from "features/assessment/contexts/AuditProvider";

import { flexibleFields } from "../helper";
import RecursiveFields from "./RecursiveFields";
import { createTreeArray } from "./nestingUtils";

const FlexibleQuestions = ({
  question,
  sectionId,
  isDisabled,
  isManuscript,
}) => {
  const { audit } = useContext(AuditContext);

  const questions = flexibleFields({
    sectionId: sectionId,
    sectionFormFields: audit?.sectionFormFields,
    flexibleQuestions: audit?.flexibleFormFields,
  });

  if (!Array.isArray(questions)) return <></>;

  const filteredQuestions = questions.filter((q) => !!q?.visible);

  return createTreeArray(filteredQuestions).map((q, index, self) => {
    return (
      <div
        key={q.id}
        className={q.properties.hasChildren ? `bg-light rounded p-2` : ""}
      >
        <RecursiveFields
          q={q}
          questions={self}
          isDisabled={isDisabled}
          auditQuestion={question}
          isManuscript={isManuscript}
        />
      </div>
    );
  });
};

export default FlexibleQuestions;
