import { Facility } from "features/entity";
import { Container } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const EntityPage = () => {
  return (
    <Container fluid className="my-2 MainContent">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "EntityPage");
        }}
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      >
        <Facility />
      </Sentry.ErrorBoundary>
    </Container>
  );
};

export default EntityPage;
