import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

import { getURLFileExtension } from "utils/StringUtils";
import { saveAs } from "file-saver";
import "./FileDownload.scss";
import { useRoles } from "hooks";

const FileDownload = ({ file }) => {
  const { isCertifier } = useRoles();

  const { id, name } = file;
  const fileURL = file.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  if (isCertifier) return <></>;

  return (
    <Button
      title="download"
      variant="outline-secondary"
      size="sm"
      onClick={() => {
        saveAs(fileURL, name || `${id}.${fileEXT}`);
      }}
    >
      <span translate="no" className="material-symbols-outlined md-16">
        download
      </span>
    </Button>
  );
};

FileDownload.propTypes = {
  file: PropTypes.object.isRequired,
};

export default FileDownload;
