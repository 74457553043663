import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import DownloadObservation from "./DownloadObservation";
import { useFormikContext } from "formik";
import { useRoles, useToast } from "hooks";

const DataLink = ({ setLinks }) => {
  const toast = useToast();
  const {
    values: { protocolId, facilityId, assessments },
    setFieldValue,
  } = useFormikContext();
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const { isMember } = useRoles();

  const [isLoading, setIsLoading] = useState(false);

  const memberDisable =
    isLoading || !protocolId || !assessments.length || !facilityId;
  const adminOrOwnerDisable = isLoading || !protocolId || !assessments.length;

  const onGenerateLink = () => {
    setIsLoading(true);
    setFieldValue("error", null);
    const length = Math.ceil(assessments?.length / 50);
    const promises = [];
    for (let index = 0; index < length; index++) {
      const data = assessments.slice(index * 50, (index + 1) * 50);
      const dataLinkPromise = get(
        `/organizations/${organization?.id}/data-link`,
        {
          params: {
            protocol_id: protocolId,
            assessments: data,
          },
        }
      );

      promises.push(dataLinkPromise.promise);
      subscribedPromises.current.push(dataLinkPromise);
    }

    Promise.all(promises)
      .then((responses) => {
        setLinks(responses.map((res) => res.data));
        toast.success("Success", "Your link has been generated successfully");
      })
      .catch((error) => !error.isCanceled && setFieldValue("error", error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <div className="sticky-bottom w-100 p-3 bg-light text-end">
      <DownloadObservation
        name={"Export To Excel (CSV)"}
        disable={isMember ? memberDisable : adminOrOwnerDisable}
      />

      <Button
        className="ms-2"
        onClick={() => onGenerateLink()}
        disabled={isMember ? memberDisable : adminOrOwnerDisable}
      >
        {isLoading && (
          <Spinner
            className="me-2"
            animation="border"
            size="sm"
            variant="light"
          />
        )}{" "}
        Generate Data Link
      </Button>
    </div>
  );
};

DataLink.propTypes = {
  setLinks: PropTypes.func,
};

export default DataLink;
