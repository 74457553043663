import { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ExpandFullScreen from "components/ui/ExpandFullScreen";
import { OrganizationContext } from "contexts/OrganizationProvider";
import ReportPrincipleChartLarge from "components/common/ReportPrincipleChartLarge";
import {
  aggregationOfPrincipleRiskTrends,
  defaultRisk,
  dynamicLabels,
} from "helper/reporting";

function FacilityPrincipleReportRisk({ protocol, facility, filterPeriod }) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const riskLevels =
    protocol?.riskLevels && protocol?.riskLevels.length
      ? protocol?.riskLevels
      : defaultRisk;

  const [error, setError] = useState();
  const [riskStats, setRiskStats] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const allHaveZeroValues =
    (riskStats?.length === 0 ? true : false) ||
    riskStats?.every((item) => item?.observationsCount === 0) ||
    (!riskStats && true);

  useEffect(() => {
    function fetchStats() {
      setError(null);
      setIsLoading(true);

      const riskPromise = get(
        `facilities/${facility?.facilityId}/protocols/${protocol?.protocolId}/principle-stats`,
        {
          params: {
            period: filterPeriod?.period,
            organizationId: organization?.id,
            option: 0,
          },
        }
      );
      riskPromise.promise
        .then(({ data }) => {
          setRiskStats(data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(riskPromise);
    }

    if (protocol?.protocolId) {
      fetchStats();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organization?.id,
    protocol?.protocolId,
    facility?.facilityId,
    filterPeriod,
  ]);

  if (error) return <ErrorHandler error={error} />;

  return (
    <Row>
      {isLoading ? (
        <Loader />
      ) : !allHaveZeroValues && riskStats ? (
        <>
          <Col xs={12} sm={12} md={12} lg={12} className="mb-4">
            <div className="d-flex flex-row">
              <p className="text-center w-100 text-truncate text-capitalize mt-2">
                Distribution of Findings across Principles &{" "}
                {protocol?.questionOptions[0]?.name || "Risk Rating"}
              </p>
              <span>
                <ExpandFullScreen
                  title={`Distribution of Findings across Principles & ${
                    protocol?.questionOptions[0]?.name || "Risk Rating"
                  }`}
                >
                  <ReportPrincipleChartLarge
                    size="square-container"
                    labels={dynamicLabels(riskLevels)}
                    data={aggregationOfPrincipleRiskTrends(
                      riskStats,
                      riskLevels,
                      filterPeriod
                    )}
                  />
                </ExpandFullScreen>
              </span>
            </div>
            <hr />
            <ReportTrend
              size="square-container"
              labels={dynamicLabels(riskLevels)}
              data={aggregationOfPrincipleRiskTrends(
                riskStats,
                riskLevels,
                filterPeriod
              )}
              chartHeight={400}
            />
          </Col>
        </>
      ) : (
        riskStats &&
        allHaveZeroValues && (
          <Col>
            <EmptyStateHandler
              title="No data to visualize"
              description="You have no data to visualize."
            />
          </Col>
        )
      )}
    </Row>
  );
}

FacilityPrincipleReportRisk.propTypes = {
  protocol: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  filterPeriod: PropTypes.object.isRequired,
};

export default FacilityPrincipleReportRisk;
