import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { NUMERIC_FIELD } from "../constants";
const FieldNumeric = ({ value, question }) => {
  const { setFieldValue, handleBlur, errors, touched } = useFormikContext();
  const fieldError = !value?.response;

  if (question.type !== NUMERIC_FIELD) return <></>;

  return (
    <>
      <Form.Control
        type="number"
        isValid={
          value?.response && !errors["metadata"]?.[question?.id]?.response
        }
        isInvalid={
          !(value?.response && !errors.name) && touched?.[question?.id]
        }
        name={question.id}
        onBlur={handleBlur}
        value={value?.response || ""}
        placeholder={`Enter numeric answer here.`}
        onChange={(ev) => {
          setFieldValue(`metadata.${question.id}`, {
            type: NUMERIC_FIELD,
            response: ev.target.value,
          });
        }}
      />
      <Form.Control.Feedback type="invalid">
        {errors["metadata"]?.[question?.id]?.response &&
        touched?.[question?.id] ? (
          <small>{errors["metadata"]?.[question?.id]?.response}</small>
        ) : null}
      </Form.Control.Feedback>
    </>
  );
};
FieldNumeric.propTypes = {
  question: PropTypes.object.isRequired,
};
export default FieldNumeric;
