import { useState } from "react";
import PropTypes from "prop-types";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";

import "./EntityTypeahead.scss";
import { useFetchEntities } from "services";

const EntityTypeahead = ({
  onChange,
  isValid,
  isInvalid,
  onBlur,
  selected,
  placeholder = "",
}) => {
  const { isLoading, error, entities } = useFetchEntities({ perPage: 1000 });
  const [entity, setEntity] = useState(Array.isArray(selected) ? selected : []);

  error && console.error(error);

  const handleOnChange = (data) => {
    onChange(data);
    setEntity(data);
  };

  return (
    <Typeahead
      clearButton
      labelKey="name"
      selected={entity}
      options={entities}
      id="entityTypeahead"
      isLoading={isLoading}
      onChange={handleOnChange}
      placeholder={placeholder}
      isValid={Boolean(isValid)}
      isInvalid={Boolean(isInvalid)}
      onBlur={onBlur}
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps}>
          {results.map((result, index) => (
            <MenuItem
              key={crypto.randomUUID()}
              option={result}
              position={index}
              className="d-flex flex-row"
            >
              {result.name}
              <br />
            </MenuItem>
          ))}
        </Menu>
      )}
    />
  );
};

EntityTypeahead.propTypes = {
  isValid: PropTypes.any,
  selected: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default EntityTypeahead;
