import { useContext } from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { showCasualFactor } from "features/assessment/components/Audit/helper";

import FieldClearButton from "../FieldClearButton";

const FieldCausalFactors = ({ isDisabled }) => {
  const { audit } = useContext(AuditContext);
  const { conformity, questionOptions } = audit?.protocol;
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();
  const [, , , , , causalFactorsLabel, ,] = questionOptions || [];
  const _showCasualFactor = showCasualFactor(conformity, questionOptions);

  if (!_showCasualFactor) return <></>;

  const clearSelection = () => {
    setFieldValue("causalFactors", "");
  };

  return (
    <Form.Group controlId="causalFactors" className="my-3">
      <Form.Label className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <strong>{causalFactorsLabel?.name || "Causal Factors"}</strong>
          <FieldClearButton
            handleOnClick={clearSelection}
            isDisabled={isDisabled}
          />
        </div>
      </Form.Label>
      <Form.Control
        rows={3}
        as="textarea"
        name="causalFactors"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.causalFactors}
        disabled={values.isInapplicable || isDisabled}
        placeholder="Enter your answer here"
      />
    </Form.Group>
  );
};

FieldCausalFactors.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldCausalFactors;
