import React, { useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { useRoles } from "hooks";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useFetchFacilitySubscribers } from "features/entity";
import { useUpdateAudit } from "features/assessment/services";

import "./AuditAddMember.scss";

const AuditAddMember = ({ audit, setAudit }) => {
  const { isCertifier } = useRoles();

  const [show, setShow] = useState(false);

  const subscriberIds = audit?.subscribers.map((item) => item.subscriberId);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const {
    error: isExpandError,
    isLoading: isExpanding,
    subscribers,
  } = useFetchFacilitySubscribers({
    facilityId: audit?.facilityId,
    fetchByDefault: show,
  });

  const { error, isLoading, updateAudit } = useUpdateAudit({
    auditId: audit?.auditId,
    onAuditUpdated: (data) => {
      handleClose();

      setAudit((prev) => ({
        ...prev,
        subscribers: data?.subscribers || [],
      }));
    },
  });

  const schema = yup.object().shape({
    name: yup.string().required("This field is required"),
    subscriberIds: yup.array().min(1).required(),
  });

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        onClick={handleShow}
        size="sm"
        className="float-end"
        variant="primary"
      >
        Add Member
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        scroll
        className="w-fixed-640"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Add Member</Offcanvas.Title>
        </Offcanvas.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { group } = audit;
            const { subscriberIds } = values;

            updateAudit({
              name: audit?.name,
              description: audit?.description,
              protocolId: audit?.protocolId,
              executiveSummary: audit?.executiveSummary,
              auditGroupId:
                Array.isArray(group) && group.length ? group.pop().id : "",
              subscriberIds: [
                ...audit?.subscribers.map((ele) => ele.subscriberId),
                ...subscriberIds?.map((ele) => ele.subscriberId),
              ],
            });
          }}
          initialValues={{ name: audit?.name, subscriberIds: [] }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Body className="vh-100">
                <Form.Group controlId="item" className="mb-3">
                  <Form.Label className="mb-1">
                    Assessment Name <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    name="name"
                    disabled
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name && !errors.name}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="subscriberIds" className="mb-3">
                  <Form.Label className="mb-1">
                    Members <RequiredAsterisk />
                  </Form.Label>
                  <Typeahead
                    id="subscribers-typeahead"
                    clearButton
                    placeholder="Select a user..."
                    isLoading={isExpanding}
                    labelKey={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    isValid={
                      !!values.subscriberIds.length &&
                      !errors.subscriberIds &&
                      touched.subscriberIds
                    }
                    isInvalid={
                      !(values.subscriberIds && !errors.subscriberIds) &&
                      touched.subscriberIds
                    }
                    onChange={(subscriberIds) => {
                      setFieldValue("subscriberIds", subscriberIds);
                    }}
                    options={subscribers?.filter(
                      ({ subscriberId }) =>
                        !subscriberIds.includes(subscriberId)
                    )}
                    selected={values.subscriberIds}
                    multiple
                  />
                </Form.Group>
                {show && (error || isExpandError) && (
                  <ErrorHandler error={error || isExpandError} />
                )}
              </Offcanvas.Body>
              <div className="sticky-bottom mt-5 p-3 bg-light text-end">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="me-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={isLoading || !isValid}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Add Members
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

export default AuditAddMember;
