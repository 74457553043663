import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

export function useFetchAuditTrailReview(auditTrail, show) {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activityLog, setActivityLog] = useState();

  useEffect(() => {
    const fetchActivityLog = () => {
      setError(null);
      setIsLoading(true);
      const activityLogPromise = get(`activity-logs/${auditTrail?.id}`);
      activityLogPromise.promise
        .then(({ data: activityLog }) => setActivityLog(activityLog))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(activityLogPromise);
    };

    if (show) fetchActivityLog();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [auditTrail, show]);

  return { error, isLoading, activityLog };
}
