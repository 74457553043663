import React, { useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import BreadCrumbs from "components/ui/BreadCrumbs";
import MetaPagination from "components/ui/MetaPagination";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { createBreadcrumb } from "features/activityLogs/utils/breadcrumpUtil";

import MembersTypeahead from "../MembersTypeahead";
import AuditTrailListItem from "./AuditTrailListItem";
import SubjectTypeahead from "../SubjectTypeahead/SubjectTypeahead";
import { useFetchAuditTrails } from "features/activityLogs/services/useFetchAuditTrails";

import "./AuditTrails.scss";

function AuditTrails() {
  const [member, setMember] = useState([]);
  const [subject, setSubject] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(undefined);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortState, setSortState] = useState("asc");
  const [selectedAction, setSelectedAction] = useState(undefined);

  const { isLoading, error, meta, auditTrails } = useFetchAuditTrails({
    startDate,
    endDate,
    selectedAction,
    sortState,
    page,
    perPage,
    member,
    subject,
  });

  const handleSelect = (eventKey) => {
    setSelectedAction(eventKey);
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const clearFilters = () => {
    setMember([]);
    setStartDate();
    setEndDate();
    setSubject([]);
    setSelectedAction("");
  };

  return (
    <>
      <Helmet>
        <title>Activity Log</title>
      </Helmet>
      <BreadCrumbs breadcrumbs={createBreadcrumb()} />
      <div className="d-flex flex-row mb-3">
        <div className="flex-fill">
          <h1>Activity Log</h1>
        </div>
      </div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={3} xl={3} className="mb-3">
          <Form.Group>
            <Form.Label>
              <small className="text-muted">Filter by Subject</small>
            </Form.Label>
            <SubjectTypeahead
              onChange={(subject) => setSubject(subject)}
              selected={subject}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} lg={3} xl={3} className="mb-3">
          <Form.Group>
            <Form.Label>
              <small className="text-muted">Filter by Actor</small>
            </Form.Label>
            <MembersTypeahead
              onChange={(member) => setMember(member)}
              selected={member}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} lg={3} xl={2} className="mb-3">
          <Form.Group>
            <Form.Label>
              <small className="text-muted">Filter by Action</small>
            </Form.Label>
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle id="dropdown-basic" variant="outline-primary">
                {selectedAction || "Select action"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="Created">Created</Dropdown.Item>
                <Dropdown.Item eventKey="Updated">Updated</Dropdown.Item>
                <Dropdown.Item eventKey="Deleted">Deleted</Dropdown.Item>
                <Dropdown.Item eventKey="Restored">Restored</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} lg={3} xl={2} className="mb-3">
          <Form.Group>
            <Form.Label>
              <small className="text-muted">Filter by Time</small>
            </Form.Label>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              placeholderText="Select date/week"
              monthsShown={2}
              className="form-control"
            >
              <Button
                size="sm"
                className="mb-1"
                onClick={() => {
                  setStartDate();
                  setEndDate();
                }}
              >
                Clear
              </Button>
            </DatePicker>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={12} lg={3} xl={1} className="my-2">
          <Button
            variant="outline-primary"
            size="sm"
            className="mt-lg-4"
            onClick={() => {
              clearFilters();
            }}
          >
            <span
              translate="no"
              className="material-symbols-outlined md-18 me-2"
            >
              filter_list
            </span>
            {/* */}
            Clear
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="mt-2 mb-5">
          <Card>
            <Card.Body className="table-responsive">
              <Table hover>
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Details</th>
                    <th>Action</th>
                    <th>Actor</th>
                    <th
                      className="table-sorter"
                      onClick={() =>
                        setSortState((prevSort) => {
                          if (prevSort === "asc") return "desc";
                          return "asc";
                        })
                      }
                    >
                      Time{" "}
                      <span
                        translate="no"
                        className="material-symbols-outlined mx-1 md-16"
                      >
                        {sortState === "desc"
                          ? "arrow_upward"
                          : "arrow_downward"}
                      </span>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  <AuditTrailListItem
                    error={error}
                    isLoading={isLoading}
                    auditTrails={auditTrails}
                  />
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <MetaPagination
                meta={meta}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AuditTrails;
