import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function SeverityFields({
  questions,
  releventRes,
  sections,
  responseYaxisItems,
  responseXaxisItems,
  yAxisItems,
  xAxisItems,
  module = "",
  isMultipleObservations,
}) {
  const COLOR_WIDTH = 25;

  function getHeightForString(str, isColor) {
    let length = str?.length || 0;
    let height;
    if (length <= 20) {
      height = 50;
    } else if (length <= 40) {
      height = 100;
    } else if (length <= 60) {
      height = 140;
    } else if (length <= 80) {
      height = 160;
    } else {
      height = 180;
    }

    return isColor ? `${(height - COLOR_WIDTH) / 2}px` : `${height}px`;
  }

  return (
    <div id="ques-text">
      <Table>
        <thead>
          <tr>
            <th>IRO</th>
            {isMultipleObservations && (
              <th className="text-center">Observer</th>
            )}
            <th>Severity</th>
            <th>Likelihood</th>
            <th>Inherent Risk</th>
          </tr>
        </thead>
        <tbody>
          {questions
            .filter((item) =>
              releventRes
                ?.map((queitem) => queitem.questionId)
                .includes(item.questionId)
            )
            .map((ques) => {
              const section = sections.find((sec) =>
                sec?.questions.find(
                  (que) => que?.questionId === ques?.questionId
                )
              );

              const severityFields = releventRes.filter(
                (item) =>
                  item.questionId === ques.questionId &&
                  item.flexibleQuestionId ===
                    responseYaxisItems[0].flexibleQuestionId
              );

              const likelihoodFields = releventRes.filter(
                (item) =>
                  item.questionId === ques.questionId &&
                  item.flexibleQuestionId ===
                    responseXaxisItems[0].flexibleQuestionId
              );

              const observers = likelihoodFields.map((field) => {
                const question = questions.find((q) =>
                  q.observations.some(
                    (obs) => obs.observationId === field.observationId
                  )
                );
                const observation = question?.observations.find(
                  (obs) => obs.observationId === field.observationId
                );
                return observation?.label ?? "Observation 1";
              });

              return (
                <tr key={ques.questionId}>
                  <td>
                    {!module && (
                      <div className="d-flex">
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              ques?.prompt.length > 35
                                ? `${ques?.prompt.slice(0, 40)}...`
                                : ques?.prompt,
                          }}
                        />
                        <Link
                          className="ms-1"
                          to={`sections/${section?.sectionId}/questions/${ques?.questionId}`}
                        >
                          Review
                          <span
                            translate="no"
                            className="material-symbols-outlined md-14"
                          >
                            arrow_forward
                          </span>
                        </Link>
                      </div>
                    )}

                    {module && (
                      <p className="text-decoration-none">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ques?.prompt,
                          }}
                        />
                      </p>
                    )}
                  </td>
                  {isMultipleObservations && (
                    <td className="text-center align-middle font-size-sm w-20">
                      {observers.map((observer, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-center align-items-center my-2"
                          style={{
                            height: getHeightForString(observers[index]),
                            wordBreak: "break-word",
                          }}
                        >
                          {observer ?? "N/A"}
                        </div>
                      ))}
                    </td>
                  )}
                  <td className="text-center align-middle">
                    {severityFields.map((field, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-center align-items-center my-2"
                        style={{
                          height: getHeightForString(observers[index]),
                        }}
                      >
                        {field?.choice?.score ?? 0}
                      </div>
                    ))}
                  </td>
                  <td className="text-center align-middle">
                    {likelihoodFields.map((field, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-center align-items-center my-2"
                        style={{
                          height: getHeightForString(observers[index]),
                        }}
                      >
                        {field?.choice?.score ?? 0}
                      </div>
                    ))}
                  </td>
                  <td className="align-middle">
                    {severityFields.map((severityField, index) => {
                      const getYAxixColor = yAxisItems?.find(
                        (item) => item.id === severityField?.choiceId
                      );
                      const likelihoodField = likelihoodFields[index];
                      const getXAxixColor = xAxisItems.find(
                        (item) => item.id === likelihoodField?.choiceId
                      );

                      let colorMix = "";
                      if (getXAxixColor && getYAxixColor) {
                        colorMix = getYAxixColor?.color?.mix(
                          getXAxixColor?.color?.toString(),
                          0.5,
                          {
                            space: "lch",
                            outputSpace: "srgb",
                          }
                        );
                      }

                      const verticalMargin = getHeightForString(
                        observers[index],
                        true
                      );

                      return (
                        <div className="mx-2 my-2" key={severityField?.id}>
                          <div
                            style={{
                              height: verticalMargin,
                            }}
                          />
                          <div
                            key={index}
                            style={{
                              width: `${COLOR_WIDTH}px`,
                              height: `${COLOR_WIDTH}px`,
                              backgroundColor: colorMix || "",
                            }}
                          />
                          <div
                            style={{
                              height: verticalMargin,
                            }}
                          />
                        </div>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default SeverityFields;
