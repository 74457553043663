import React from "react";
import { Modal, Button, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const AuditTrailFacility = ({ activityLog, handleClose }) => {
  const subscriberAction = activityLog?.description;

  return (
    <>
      <Modal.Body>
        <div className="d-flex flox-row">
          <div className="flex-fill">
            <h4>{activityLog.logLabel}</h4>
          </div>
          <div className="text-end">
            {subscriberAction === "created" && (
              <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
                CREATED
              </Badge>
            )}
            {subscriberAction === "updated" && (
              <Badge className="bg-warning rounded-0 py-2 px-3 bg-opacity-10 text-warning">
                UPDATED
              </Badge>
            )}
            {subscriberAction === "deleted" && (
              <Badge className="bg-danger rounded-0 py-2 px-3 bg-opacity-10 text-danger">
                DELETED
              </Badge>
            )}
            <div className="mt-1">
              <small>
                {new Date(activityLog?.createdAt).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </small>
            </div>
          </div>
        </div>
        <div>
          <span>
            {activityLog?.causer?.firstName ?? "-"}{" "}
            {activityLog?.causer?.lastName ?? "-"}
          </span>
          <br />
          <small className="text-muted">
            {activityLog?.causer?.email ?? "-"}
          </small>
        </div>
        {subscriberAction === "updated" && (
          <>
            <hr />
            <Table striped borderless>
              <thead>
                <tr>
                  <th>Attribute</th>
                  <th>From</th>
                  <th>To</th>
                </tr>
              </thead>
              <tbody>
                {activityLog && (
                  <tr>
                    <td>Title</td>
                    <td>{activityLog?.oldProperties?.name}</td>
                    <td>{activityLog?.newProperties?.name}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
        {activityLog?.description !== "deleted" && (
          <Button
            size="sm"
            className="px-3"
            as={Link}
            to={`/facilities/${activityLog?.facility?.id}`}
          >
            View Entity
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

AuditTrailFacility.propTypes = {
  activityLog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AuditTrailFacility;
