import React from "react";
import GridView from "./GridView";
import ListView from "./ListView";

function NestedQuestion({
  nestedQuestion = {},
  responses = [],
  view = "grid",
  label = "",
}) {
  let {
    id,
    prompt,
    properties,
    nestedFields: _nestedQuestion = [],
  } = nestedQuestion || {};
  let choices = [];
  let notes = "";
  let choice = {};
  let responseDatetime = "";
  const matchResponse = responses.filter(
    (elem) => elem.flexibleQuestionId === id
  );

  if (properties?.fieldType === "checkbox") {
    choices = matchResponse.map((elem) => elem?.choice);
  } else {
    choice = matchResponse[0]?.choice ?? {};
    notes = matchResponse[0]?.notes ?? "";
    responseDatetime = matchResponse[0]?.responseDatetime ?? "";
    choice = matchResponse[0]?.choice ?? {};
  }

  _nestedQuestion = _nestedQuestion?.filter(
    ({ triggerAnswerId = "" }) => triggerAnswerId === choice?.id
  );

  if (view === "list")
    return (
      <ListView
        id={id}
        prompt={prompt}
        properties={properties}
        choices={choices}
        choice={choice}
        notes={notes}
        responseDatetime={responseDatetime}
        matchResponse={matchResponse}
      />
    );

  return (
    <>
      <GridView
        id={id}
        prompt={prompt}
        properties={properties}
        choices={choices}
        choice={choice}
        notes={notes}
        responseDatetime={responseDatetime}
        matchResponse={matchResponse}
      />

      {_nestedQuestion?.length > 0 &&
        _nestedQuestion?.map((elem) => {
          return (
            <p className="ms-2">
              <NestedQuestion
                nestedQuestion={elem}
                responses={responses}
                view="grid"
              />
            </p>
          );
        })}
    </>
  );
}

export default NestedQuestion;
