import React, { useContext, useState } from "react";

import { useRoles } from "hooks";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Files } from "features/files";
import BreadCrumbs from "components/ui/BreadCrumbs";
import { UserContext } from "contexts/UserProvider";
import { OrganizationsContext } from "contexts/OrganizationProvider";
import MyActionItemPage from "pages/MyActionItemPage";
import Organizations from "../Organizations";

const ActivityLogs = () => <>Activity Logs</>;

const ProfileDetails = () => {
  const { isAdmin } = useRoles();
  const location = useLocation();
  const navigate = useNavigate();
  const userOrganizations = useContext(OrganizationsContext);
  const { firstName, lastName, email, userId } = useContext(UserContext);

  const tabName = location.pathname.split("/")[3];

  const [pathName, setPathName] = useState(tabName);

  const handleSelectTab = (tmpPathName) => {
    setPathName(tmpPathName);
    navigate(`${userId}/${tmpPathName}`);
  };

  const handlePathName = () => {
    if (pathName === "organizations") return "Organizations";
    if (pathName === "action-items") return "All Action Items";
    if (pathName === "photos") return "Files & Photos";
    return "Activity Log";
  };

  const breadCrumbsPath = [
    { name: "Dashboard", link: "/" },
    { name: "Profile", active: false },
    { name: handlePathName(), active: false },
  ];

  return (
    <>
      <BreadCrumbs breadcrumbs={[...breadCrumbsPath]} />
      <h1 className="mb-3">
        {firstName} {lastName}. <small className="text-muted">{email}</small>
      </h1>
      <Row>
        <Col className="col-12 mb-3">
          <Card>
            <Card.Body>
              <Tabs
                id="controlled-tab-example"
                activeKey={pathName}
                onSelect={(tmpPathName) => handleSelectTab(tmpPathName)}
                className="mb-3"
              >
                <Tab eventKey="organizations" title="Organizations" />

                {/* Hide this tab for future iteration
                <Tab eventKey="activityLog" title="Activity Log" /> */}
                <Tab eventKey="action-items" title="All Action Items" />
                {isAdmin && <Tab eventKey="photos" title="Files & Photos" />}
              </Tabs>

              <Routes>
                <Route
                  path=":profileId/action-items"
                  element={<MyActionItemPage />}
                />

                <Route
                  path=":profileId/organizations"
                  element={<Organizations organizations={userOrganizations} />}
                />

                <Route
                  path=":profileId/photos"
                  element={<Files noBreadCrumbs />}
                />

                <Route path="activityLog" element={<ActivityLogs />} />
              </Routes>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

ProfileDetails.propTypes = {};

export default ProfileDetails;
