import { useContext } from "react";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { OrganizationContext } from "contexts/OrganizationProvider";

import { Typeahead } from "react-bootstrap-typeahead";
import { get } from "utils/DeApi";
const ProtocolTypeahead = ({
  onChange,
  isValid,
  isInvalid,
  selected,
  placeholder,
}) => {
  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [protocol, setProtocol] = useState(
    Array.isArray(selected) ? selected : []
  );
  const [protocols, setProtocols] = useState([]);

  error && console.error(error);

  useEffect(() => {
    function fetchProtocols(organizationId) {
      setIsLoading(true);
      const protocolsPromise = get("protocols", {
        params: { organizationId: organizationId, excludeRetired: true },
      });
      protocolsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setProtocols(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(protocolsPromise);
    }

    fetchProtocols(organization?.id);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id]);
  return (
    <Typeahead
      id="protocol-typeahead"
      clearButton
      placeholder={placeholder || "Filter by protocol"}
      isLoading={isLoading}
      labelKey="name"
      isInvalid={Boolean(isInvalid)}
      onChange={(data) => {
        onChange(data);
        setProtocol(data);
      }}
      isValid={Boolean(isValid)}
      options={protocols}
      selected={protocol}
    />
  );
};

ProtocolTypeahead.propTypes = {
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.any,
  selected: PropTypes.array,
};

export default ProtocolTypeahead;
