import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import { FlexibleFields } from "features/assessment/constants";

const { TEXT_FIELD } = FlexibleFields;

const FieldTextarea = ({ value, question, isDisabled }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext();
  if (question?.properties?.fieldType !== "text") return <></>;
  return (
    <Form.Control
      as="textarea"
      name={question.id}
      placeholder={`Enter your answer here.`}
      value={value?.response || ""}
      onChange={(ev) => {
        setFieldValue(`dynamic.${question.id}`, {
          type: TEXT_FIELD,
          response: ev.target.value,
        });
      }}
      onBlur={handleBlur}
      disabled={values.isInapplicable || isDisabled}
      rows={3}
    />
  );
};
FieldTextarea.propTypes = {
  question: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  value: PropTypes.object,
};
export default FieldTextarea;
