import { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { useAuditMapping } from "features/assessment/hooks";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useCreateOrUpdateObservation } from "features/assessment/services";

import GeoTagMap from "./Map/Map";
import GeoTagCard from "./GeoTagCard";
import GeoTagCreate from "./GeoTagCreate";
import GeoTagExport from "./GeoTagExport";
import { prepSubmitObservationValues } from "../QuestionUtils";

import "./Geotag.scss";

const GeoTag = ({ disabled, question, observation }) => {
  const { audit } = useContext(AuditContext);
  const subscribedPromises = useRef([]);
  const { questionId, auditId } = useParams();
  const [geolocations, setGeolocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [mapView, setMapView] = useState(false);
  const { values } = useFormikContext();
  const handleError = (error) => setError(error);

  const { responseUpdateMapping, observationCreateOrUpdateMapping } =
    useAuditMapping();

  const {
    error: isExpandingError,
    isLoading: isExpanding,
    handleOnSubmit,
  } = useCreateOrUpdateObservation({
    onObservationSuccess: (observation) => {
      observationCreateOrUpdateMapping(observation);
    },
    onResponsesSuccess: (responses, observation) => {
      responseUpdateMapping(responses, observation);
    },
  });

  const createObservation = () => {
    const formData = prepSubmitObservationValues(values, {
      auditId,
      parentId: null,
      questionId,
      observationId: observation?.observationId,
    });
    handleOnSubmit(formData);
  };

  const onGeoTagCreate = (location) => {
    setGeolocations((prevLocation) => [...prevLocation, location]);
  };

  const getIconColor = (isActive) => {
    if (isActive) return "white";
  };

  const getGeoTagView = () => {
    if (geolocations?.length) {
      if (mapView) {
        return <GeoTagMap geolocations={geolocations} />;
      } else {
        return (
          <GeoTagCard
            geolocations={geolocations}
            setGeolocations={setGeolocations}
            handleError={handleError}
          />
        );
      }
    } else {
      return (
        <EmptyStateHandler
          title="No Geotag found"
          description="There are currently no Geotags"
        />
      );
    }
  };

  useEffect(() => {
    const fetchGeoTag = () => {
      setError("");
      setIsLoading(true);
      const geoTagPromise = get(
        `/observations/${observation?.observationId}/geotags`
      );
      geoTagPromise.promise
        .then(({ data }) => {
          setGeolocations(data);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(geoTagPromise);
    };

    if (observation?.observationId && audit?.protocol?.hasGeolocation)
      fetchGeoTag();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [observation?.observationId, audit?.protocol?.hasGeolocation]);

  error && console.error(error);

  if (!audit?.protocol?.hasGeolocation) return <></>;

  return (
    <>
      <div className="d-flex flex-row  align-items-center py-3 border-opacity-10">
        <h6 className="flex-fill mb-0">
          <strong>
            Geotags ·{" "}
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <span translate="no" className="text-muted">
                {geolocations?.length || 0}
              </span>
            )}
          </strong>
        </h6>
        <div className="text-end mb-2">
          <GeoTagCreate
            onGeoTagCreate={onGeoTagCreate}
            observationId={observation?.observationId}
            createObservation={createObservation}
            isExpanding={isExpanding}
            isExpandingError={isExpandingError}
            handleError={handleError}
            disabled={disabled}
          />
          {!!geolocations?.length && (
            <>
              <GeoTagExport
                audit={audit}
                question={question}
                geolocations={geolocations}
              />
              <ButtonGroup size="sm">
                <Button
                  title="List View"
                  className="geotag-btn-group text-start bg-opacity-25 bg-primary text-dark px-3"
                  size="sm"
                  variant="light"
                  active={!mapView}
                  onClick={() => setMapView(false)}
                >
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18"
                    style={{
                      color: getIconColor(!mapView),
                    }}
                  >
                    view_list
                  </span>
                </Button>
                <Button
                  title="Map View"
                  className=" geotag-btn-group text-start bg-primary bg-opacity-25 text-dark px-3 button-group"
                  size="sm"
                  variant="light"
                  active={mapView}
                  onClick={() => setMapView(true)}
                >
                  {isLoading ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    <span
                      translate="no"
                      className="material-symbols-outlined md-18"
                      style={{
                        color: getIconColor(mapView),
                      }}
                    >
                      map
                    </span>
                  )}
                </Button>
              </ButtonGroup>
            </>
          )}
        </div>
      </div>
      <hr className="py-0 my-0" />
      <div className="mt-2">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {error && <ErrorHandler error={error} />}
            {getGeoTagView()}
          </>
        )}
      </div>
    </>
  );
};

export default GeoTag;

GeoTag.propTypes = {
  observation: PropTypes.object,
  question: PropTypes.object.isRequired,
};
