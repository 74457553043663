import ExpandFullScreen from "components/ui/ExpandFullScreen";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import ContentDetails from "features/contents/components/ContentDetails";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";

const HelpInformation = ({ observations }) => {
  const { audit } = useContext(AuditContext);
  if (!audit?.protocol?.infoId || !observations?.length) return <></>;
  return (
    <>
      <div className="d-flex flex-row">
        <h5 className="mb-3 flex-fill pt-1">Helpful Information & Resources</h5>
        <span className>
          <ExpandFullScreen title={`Helpful Information & Resources`}>
            <Container>
              <Row className="justify-content-center py-5">
                <Col xs={12} sm={12} md={12} lg={9} xl={8} xxl={8}>
                  <ContentDetails contentId={audit?.protocol?.infoId} />
                </Col>
              </Row>
            </Container>
          </ExpandFullScreen>
        </span>
      </div>
      <hr />
      <ContentDetails
        showContentTitle={false}
        contentId={audit?.protocol?.infoId}
      />
    </>
  );
};

export default HelpInformation;
