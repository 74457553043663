import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
const Marker = ({ id, title, summary, visible, color }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    const tooltip = document.getElementById(id + "-marker-tooltip");
    if (!tooltip) return;
    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;

    if (fullscreenElement && show) {
      fullscreenElement.appendChild(tooltip);
    } else {
      document.body.appendChild(tooltip);
    }
  }, [show]);

  const markerPin = (
    <div className="marker">
      <span
        translate="no"
        className="material-symbols-outlined md-36 marker-pin"
        style={{
          color: color,
        }}
      >
        place
      </span>
    </div>
  );

  const summaryGeotag = visible ? (
    <OverlayTrigger
      show={show}
      onToggle={handleShow}
      overlay={
        <Tooltip id={id + "-marker-tooltip"} className="marker-tooltip">
          <div className="text-start">
            <div>{title}</div>
          </div>
        </Tooltip>
      }
    >
      {markerPin}
    </OverlayTrigger>
  ) : null;

  return summary ? summaryGeotag : markerPin;
};
Marker.propTypes = {
  title: PropTypes.string,
};
export default Marker;
