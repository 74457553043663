import React from "react";

import { Container } from "react-bootstrap";

import * as Sentry from "@sentry/react";
import { AccountManagement } from "features/account";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const AccountManagementPage = () => {
  return (
    <Container className="my-2 MainContent">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "AccountManagementPage");
        }}
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      >
        <AccountManagement />
      </Sentry.ErrorBoundary>
    </Container>
  );
};

export default AccountManagementPage;
