const TEXT_FIELD = 1;
const CHECKBOX_FIELD = 2;
const RADIO_FIELD = 3;
const DATETIME_FIELD = 4;
const NUMERIC_FIELD = 5;

export {
  TEXT_FIELD,
  CHECKBOX_FIELD,
  RADIO_FIELD,
  DATETIME_FIELD,
  NUMERIC_FIELD,
};
