import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { CHECKBOX_FIELD } from "../constants";
const FieldCheckbox = ({ question, value }) => {
  const { setFieldValue } = useFormikContext();

  if (question.type !== CHECKBOX_FIELD) return <></>;

  const maxLabelLength = Math.max(
    ...question.choices.map(({ choice = "" }) => parseInt(choice.length))
  );

  return (
    <>
      {question.choices.map(({ id, choice }) => {
        return (
          <Form.Check
            key={id}
            id={id}
            value={id}
            label={choice}
            type="checkbox"
            name={question.id}
            inline={maxLabelLength < 30}
            className={maxLabelLength > 30 ? "mb-2" : ""}
            checked={
              Array.isArray(value?.response)
                ? !!value?.response.find((choiceId) => choiceId === id)
                : false
            }
            onChange={(ev) => {
              const checked = ev.target.value;
              const checkList = Array.isArray(value?.response)
                ? value?.response
                : [];

              const found = checkList.find((id) => id === checked);

              setFieldValue(`metadata.${question.id}`, {
                type: CHECKBOX_FIELD,
                response: found
                  ? checkList.filter((id) => id !== checked)
                  : [...checkList, checked],
              });
            }}
          />
        );
      })}
    </>
  );
};
FieldCheckbox.propTypes = {
  question: PropTypes.object.isRequired,
};
export default FieldCheckbox;
