import PropTypes from "prop-types";
import "./FieldClearButton.scss";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useFormikContext } from "formik";
const FieldClearButton = ({ handleOnClick, isDisabled }) => {
  const { values } = useFormikContext();
  if (values.isInapplicable || isDisabled) return null;
  return (
    <OverlayTrigger
      placement="top"
      overlay={(props) => (
        <Tooltip id={`tooltip-risk`} {...props}>
          Clear
        </Tooltip>
      )}
    >
      <Button
        variant="light"
        size="sm"
        className="py-0 custom-clear-button border-secondary border-opacity-25 rounded-circle text-center float-end"
        onClick={handleOnClick}
      >
        <span translate="no" className="material-symbols-outlined md-14">
          close
        </span>
      </Button>
    </OverlayTrigger>
  );
};

FieldClearButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default FieldClearButton;
