import { Fragment, useState } from "react";

import { dateFilter } from "constants";
import { camelCase, head } from "lodash";
import { useFetchProtocols } from "services";
import { principleChartFilters } from "constants";
import { Card, Col, Nav, Row, Tab, Tabs } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { useActiveTab } from "features/entity/hooks";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import DateFilterDropdown from "components/ui/DateFilterDropdown";
import { useShowObservationProperties } from "features/assessment";
import { AuditList } from "features/assessment";
import ProtocolFilterDropdown from "components/ui/ProtocolFilterDropdown";
import PrincipleFilterDropdown from "components/ui/PrincipleFilterDropdown";
import { useFetchFacilityAnswerTypeAndDynamicFields } from "features/entity/services";

import FacilityStats from "./FacilityStats";
import FacilityActionItem from "./FacilityActionItem";
import FacilityReportRisk from "./FacilityReportRisk";
import FacilityReportCompliance from "./FacilityReportCompliance";
import FacilityReportConformity from "./FacilityReportConformity";
import FacilityReportRadioField from "./FacilityReportRadioField";
import FacilityPrincipleReportRisk from "./FacilityPrincipleReportRisk";
import FacilityReportCheckboxField from "./FacilityReportCheckboxField";
import FacilityBestPractices from "./FacilityBestPractices/FacilityBestPractices";
import FacilityPrincipleReportCompliance from "./FacilityPrincipleReportCompliance";
import FacilityPrincipleReportConformity from "./FacilityPrincipleReportConformity";
import FacilityReportNumericFields from "./FacilityReportNumericFields/FacilityReportNumericFields";
import Slider from "react-slick";
import { PrinciplesSpiderWeb } from "features/assessment";
import { PrinciplesScoresGuage } from "features/assessment";
import SlickArrowNext from "components/ui/SlickArrowNext";
import SlickArrowPrev from "components/ui/SlickArrowPrev";

const FacilityOverview = ({ facility }) => {
  const [protocol, setProtocol] = useState();
  const [radioFields, setRadioFields] = useState();
  const [checkboxFields, setCheckboxFields] = useState();
  const [numericFields, setNumericFields] = useState();
  const [filterPeriod, setFilterPeriod] = useState(dateFilter[3]);
  const [showPrinciple, setShowPrinciple] = useState(principleChartFilters[0]);
  const [weightedScores, setWeightedScores] = useState([]);
  const [weightScoresKey, setWeightScoresKey] = useState("gauges-tab");

  const { key, setKey, setActiveTab } = useActiveTab();
  const { isRisk, isConformity, isCompliance } = useShowObservationProperties({
    protocol,
  });

  const isBestPracticeAndFindings = showPrinciple === principleChartFilters[2];

  const {
    protocols,
    error: expandError,
    isLoading: isExpanding,
  } = useFetchProtocols({
    onSuccess: (protocols) => {
      const first = head(protocols);
      setProtocol(first || null);
      if (first) setActiveTab(first.questionOptions);
    },
  });

  const {
    error: bestPracError,
    isLoading: bestPracticeLoading,
    answerTypeStats: bestPracFindings,
  } = useFetchFacilityAnswerTypeAndDynamicFields({
    facilityId: facility?.facilityId,
    protocolId: protocol?.protocolId,
    isBestPracticeAndFindings,
    onSuccess: (data) => {
      const dynamicFields = data;

      const radioFieldCount = dynamicFields
        ?.filter(({ fieldType }) => fieldType === "Radio Field")
        ?.filter(({ stats }) => stats.some(({ count }) => count > 0))
        .map((field) => ({
          ...field,
          key: camelCase(field.fieldName),
          show: true,
          isRadio: true,
          name: field.fieldName,
        }));

      const checkboxCount = dynamicFields
        ?.filter(({ fieldType }) => fieldType === "Checkbox Field")
        ?.filter(({ stats }) => stats.some(({ count }) => count > 0))
        .map((field) => ({
          ...field,
          key: camelCase(field.fieldName),
          show: true,
          isRadio: false,
          name: field.fieldName,
        }));

      const numericCount = dynamicFields
        ?.filter(({ fieldType }) => fieldType === "Numeric Field")
        ?.filter(({ stats }) => stats.some(({ count }) => count > 0))
        .map((field) => ({
          ...field,
          key: camelCase(field.fieldName),
          show: true,
          name: field.fieldName,
        }));

      const gauges = dynamicFields
        ?.filter(({ fieldType }) => fieldType === "Radio Field")
        ?.filter((item) => Array.isArray(item?.gauges))
        ?.flatMap((item) =>
          item.gauges.map((guage) => ({
            ...guage,
            questionTitle: item?.fieldName,
          }))
        );

      setWeightedScores(gauges);

      setRadioFields(radioFieldCount);
      setCheckboxFields(checkboxCount);
      setNumericFields(numericCount);

      if (!isRisk && !isCompliance && !isConformity) {
        if (radioFieldCount?.length > 0) {
          return setKey(radioFieldCount[0]?.key);
        }

        if (checkboxCount?.length > 0) {
          return setKey(checkboxCount[0]?.key);
        }
      }
    },
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SlickArrowNext />,
    prevArrow: <SlickArrowPrev />,
    className: "card",
  };

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12} xl={8}>
        <Row>
          <Col xs={12} className="mb-3">
            <Card className="px-3 pt-3">
              <Row>
                <div className="mb-4">
                  <h2 className="m-0">Overview</h2>
                </div>
                <Col md={12} xs={12} sm={12} lg={12}>
                  <FacilityStats facilityId={facility?.facilityId} />
                </Col>
                <hr />
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className="mb-3">
                  <ProtocolFilterDropdown
                    selected={protocol}
                    isLoading={isExpanding}
                    options={protocols || []}
                    handleOnClick={(p) => {
                      setProtocol(p);
                      setActiveTab(p.questionOptions);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mb-3">
                  <PrincipleFilterDropdown
                    selected={showPrinciple}
                    handleOnClick={(chart) => {
                      setShowPrinciple(chart);

                      if (isRisk) return setKey("risk-tab");
                      if (radioFields) return setKey(radioFields[0]?.key);
                      if (checkboxFields) return setKey(checkboxFields[0]?.key);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className="mb-3">
                  {showPrinciple.index === 1 && (
                    <DateFilterDropdown
                      selected={filterPeriod}
                      handleOnClick={(interval) => setFilterPeriod(interval)}
                    />
                  )}
                </Col>
              </Row>

              {expandError && <ErrorHandler error={expandError} />}

              <Tab.Container
                id="left-tabs-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Nav variant="tabs" className="flex-row">
                  {[
                    {
                      key: "risk-tab",
                      show: isRisk && !isBestPracticeAndFindings,
                      name: protocol?.questionOptions[0]?.name || "Risk Rating",
                    },
                    {
                      key: "compliance-tab",
                      show: isCompliance && !isBestPracticeAndFindings,
                      name: protocol?.questionOptions[1]?.name || "Compliance",
                    },
                    {
                      key: "conformity-tab",
                      show: isConformity && !isBestPracticeAndFindings,
                      name: protocol?.questionOptions[2]?.name || "Conformity",
                    },
                    {
                      key: "weighted-scores-tab",
                      show:
                        !!weightedScores?.length && !isBestPracticeAndFindings,
                      name: "Weighted Scores",
                    },
                    ...(Array.isArray(radioFields) && showPrinciple.index === 1
                      ? radioFields
                      : []),
                    ...(Array.isArray(checkboxFields) &&
                    showPrinciple.index === 1
                      ? checkboxFields
                      : []),
                    ...(Array.isArray(numericFields) &&
                    showPrinciple.index === 1
                      ? numericFields
                      : []),
                  ].map((item) => {
                    if (!item.show) return <Fragment key={item?.key} />;
                    return (
                      <Nav.Item key={item?.key}>
                        <Nav.Link eventKey={item?.key}>{item?.name}</Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
                <Tab.Content className="pt-3">
                  {bestPracticeLoading && !isBestPracticeAndFindings && (
                    <Loader />
                  )}
                  <Tab.Pane eventKey="risk-tab">
                    {isRisk && showPrinciple.index === 1 && (
                      <FacilityReportRisk
                        facility={facility}
                        protocol={protocol}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isRisk && showPrinciple.index === 2 && (
                      <FacilityPrincipleReportRisk
                        facility={facility}
                        protocol={protocol}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="compliance-tab">
                    {isCompliance && showPrinciple.index === 1 && (
                      <FacilityReportCompliance
                        protocol={protocol}
                        facility={facility}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isCompliance && showPrinciple.index === 2 && (
                      <FacilityPrincipleReportCompliance
                        protocol={protocol}
                        facility={facility}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="conformity-tab">
                    {isConformity && showPrinciple.index === 1 && (
                      <FacilityReportConformity
                        protocol={protocol}
                        facility={facility}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isConformity && showPrinciple.index === 2 && (
                      <FacilityPrincipleReportConformity
                        protocol={protocol}
                        facility={facility}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  {!!weightedScores?.length && !isBestPracticeAndFindings && (
                    <Tab.Pane eventKey="weighted-scores-tab">
                      <Tabs
                        className="mb-3"
                        onSelect={(e) => setWeightScoresKey(e)}
                      >
                        <Tab
                          eventKey={`gauges-tab`}
                          title={"Scores By Principles (%) - Gauges"}
                        >
                          <Tabs className="mb-3">
                            {Object.entries(
                              Object.groupBy(
                                weightedScores || [],
                                ({ questionTitle }) => questionTitle
                              )
                            )?.map(([key, value]) => {
                              return (
                                <Tab
                                  key={`${key}`}
                                  eventKey={`${key}`}
                                  title={key}
                                >
                                  {value?.length &&
                                  value?.reduce(
                                    (acc, val) => acc + Number(val?.maxScore),
                                    0
                                  ) ? (
                                    <Slider
                                      {...{
                                        ...settings,
                                        slidesToShow: 4,
                                        className: "pb-3",
                                      }}
                                    >
                                      {value?.map((data, index) => {
                                        const score =
                                          (data?.totalScore / data?.maxScore) *
                                          100;
                                        if (!isNaN(score)) {
                                          return (
                                            <PrinciplesScoresGuage
                                              title={`${data?.title}`}
                                              score={score?.toFixed(2) || 0}
                                              size="sm"
                                              key={index}
                                            />
                                          );
                                        } else {
                                          return false;
                                        }
                                      })}
                                    </Slider>
                                  ) : (
                                    <EmptyStateHandler
                                      title="No data to visualize"
                                      description="You have no data to visualize."
                                    />
                                  )}
                                </Tab>
                              );
                            })}
                          </Tabs>
                        </Tab>
                        <Tab
                          eventKey={`spider-web-tab`}
                          title={"Scores By Principles - Spiderweb"}
                        >
                          <PrinciplesSpiderWeb
                            rerender={weightScoresKey}
                            overviewPage={true}
                            data={weightedScores.map((val) => ({
                              ...val,
                              flexibleQuestion: {
                                title: val?.questionTitle,
                              },
                              principle: {
                                principleId: val?.principleId,
                                name: val?.title,
                              },
                            }))}
                            principles={[
                              ...new Map(
                                (weightedScores || []).map((item) => [
                                  item["title"],
                                  item,
                                ])
                              ).values(),
                            ]}
                          />
                        </Tab>
                      </Tabs>
                    </Tab.Pane>
                  )}
                  {[
                    ...(Array.isArray(radioFields) ? radioFields : []),
                    ...(Array.isArray(checkboxFields) ? checkboxFields : []),
                  ].map((field) => (
                    <Tab.Pane key={field?.key} eventKey={field?.key}>
                      {field?.isRadio && showPrinciple.index === 1 && (
                        <FacilityReportRadioField data={field} />
                      )}
                      {!field?.isRadio && showPrinciple.index === 1 && (
                        <FacilityReportCheckboxField data={field} />
                      )}
                    </Tab.Pane>
                  ))}
                  {numericFields &&
                    numericFields
                      ?.filter((item) => item.key === key)
                      ?.map((filteredField, index) => (
                        <Tab.Pane key={index} eventKey={filteredField?.key}>
                          <FacilityReportNumericFields
                            numericFields={filteredField}
                          />
                        </Tab.Pane>
                      ))}

                  {showPrinciple.index === 3 && isBestPracticeAndFindings && (
                    <FacilityBestPractices
                      protocol={protocol}
                      data={bestPracFindings}
                      isLoading={bestPracticeLoading}
                      error={bestPracError}
                    />
                  )}

                  {showPrinciple.index === 1 &&
                    !isRisk &&
                    !isCompliance &&
                    !isConformity &&
                    !radioFields?.length &&
                    !checkboxFields?.length &&
                    !bestPracticeLoading && (
                      <EmptyStateHandler
                        title="No data to visualize"
                        description="You have no data to visualize."
                      />
                    )}

                  {showPrinciple.index !== 1 &&
                    !isRisk &&
                    !isCompliance &&
                    !isConformity &&
                    !isBestPracticeAndFindings &&
                    !bestPracticeLoading && (
                      <EmptyStateHandler
                        title="No data to visualize"
                        description="You have no data to visualize."
                      />
                    )}
                </Tab.Content>
              </Tab.Container>
            </Card>
          </Col>
          <Col xs={12} className="mb-3">
            <AuditList />
          </Col>
        </Row>
      </Col>
      <Col xs={12} xl={4} className="mb-3">
        <FacilityActionItem facilityId={facility?.facilityId} />
      </Col>
    </>
  );
};

export default FacilityOverview;
