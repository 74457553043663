import { OrganizationContext } from "contexts/OrganizationProvider";
import { useFormikContext } from "formik";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Col, Form, Spinner } from "react-bootstrap";
import { get } from "utils/DeApi";

const EntityField = ({ reset }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const {
    values: { isLoading, entities, protocolId, facilityId },
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const handleEntityChange = (facilityId) => {
    setFieldValue("facilityId", facilityId);
    setFieldValue("sortValue", {
      index: 0,
      name: "A - Z",
      value: "name",
    });
    setFieldValue("assessments", []);
    setFieldTouched("assessments", false, false);
    setFieldValue("audits", []);
    reset();
  };

  const fetchFacilities = useCallback(() => {
    setFieldValue("isLoading", true);
    const facilitiesPromise = get(
      `organizations/${organization?.id}/facilities`,
      {
        params: {
          ...(protocolId
            ? {
                "filter[jurisdiction]": protocolId,
              }
            : {}),
          perPage: 100,
        },
      }
    );
    facilitiesPromise.promise
      .then(({ data: facilities }) =>
        setFieldValue("entities", facilities ?? [])
      )
      .catch((error) => !error.isCanceled && setFieldValue("error", error))
      .finally(() => setFieldValue("isLoading", false));
  }, [organization?.id, protocolId, setFieldValue]);

  useEffect(() => {
    if (protocolId) fetchFacilities();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchFacilities, protocolId]);

  return (
    <Col>
      <Form.Group controlId="facilityId" className="mt-0 mb-3">
        <Form.Label className="mb-1 me-2 fw-semibold ">
          Select Entity
          {isLoading && (
            <Spinner
              className="ms-2"
              animation="border"
              size="sm"
              variant="dark"
            />
          )}
        </Form.Label>
        <Form.Select
          disabled={!protocolId}
          onChange={(e) => handleEntityChange(e.target.value)}
          onBlur={handleBlur}
          name="facilityId"
          value={facilityId}
          isValid={facilityId && !errors.facilityId}
          isInvalid={!(facilityId && !errors.facilityId) && touched.facilityId}
        >
          <option key="" hidden value>
            Select Entity
          </option>
          {entities?.map((f) => (
            <option key={f.facilityId} value={f.facilityId}>
              {f.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  );
};

export default EntityField;
