import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

import { FlexibleFields } from "features/assessment/constants";

import LabelScore from "../LabelScore";

const { RADIO_FIELD } = FlexibleFields;

const FieldRadio = ({ value, question, isDisabled }) => {
  const { values, setFieldValue } = useFormikContext();

  if (question?.properties?.fieldType !== "radio") return <></>;

  const choices = question.choices.sort((a, b) => {
    if (a.score < b.score) return 1;
    if (a.score > b.score) return -1;
    return 0;
  });

  const maxLabelLength = Math.max(
    ...question.choices.map(({ label = "" }) => parseInt(label.length))
  );

  const selected = choices.find(({ id }) => {
    if (!value || !value?.response) return false;
    return value?.response === id;
  });

  return (
    <>
      {choices.length > 10 ? (
        <Typeahead
          disabled={values.isInapplicable || isDisabled}
          clearButton
          labelKey="label"
          id={question.id}
          options={choices}
          placeholder={"Select an option..."}
          selected={selected ? [selected] : []}
          onChange={(data) => {
            const { id } = Array.isArray(data) && data.length ? data[0] : {};

            setFieldValue(`dynamic.${question.id}`, {
              type: RADIO_FIELD,
              response: id,
            });
          }}
        />
      ) : (
        choices.map(({ label, score, id, type }) => {
          return (
            <Form.Check
              id={id}
              key={id}
              value={id}
              type="radio"
              name={question.id}
              checked={value?.response === id}
              label={
                <>
                  {label} <LabelScore score={score} />
                </>
              }
              disabled={values.isInapplicable || isDisabled}
              inline={choices.length <= 5 && maxLabelLength < 20}
              className={choices.length >= 5 ? "mb-2" : ""}
              onChange={(ev) => {
                setFieldValue(`dynamic.${question.id}`, {
                  type: RADIO_FIELD,
                  response: id,
                });
              }}
            />
          );
        })
      )}
    </>
  );
};
FieldRadio.propTypes = {
  question: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldRadio;
