import React, { Fragment, useEffect, useState } from "react";

import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Collapse,
  Form,
} from "react-bootstrap";

import { colorfy, removeHTML } from "utils/MapUtils";
import ExpandFullScreen from "components/ui/ExpandFullScreen";

import ArcGis from "../ArcGis";
import Map from "../../Question/GeoTag/Map/Map";

import "../../Question/GeoTag/Map/Map.scss";

const AuditMap = ({ audit, questions }) => {
  const [show, setShow] = useState(false);
  const [geoLocations, setGeoLocations] = useState([]);
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [geoTaggedObservations, setGeoTaggedObservations] = useState([]);
  const [showAsPolygon, setShowAsPolygon] = useState({});
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const handleChange = (gTags = [], gQuestionId) => {
    const found = checkedQuestions.find(
      ({ questionId }) => questionId === gQuestionId
    );

    if (!!found) {
      setCheckedQuestions((prevState) =>
        prevState.filter(({ questionId }) => questionId !== gQuestionId)
      );
    } else {
      setCheckedQuestions((prevState) => [...prevState, ...gTags]);
    }
  };

  const handleShowAsPolygons = (gQuestionId, showAsPolygon) => {
    const updatedQuestions = checkedQuestions.map((question) => {
      if (question.questionId === gQuestionId) {
        question.showAsPolygon = showAsPolygon;
      }
      return question;
    });
    setCheckedQuestions(updatedQuestions);
    setShowAsPolygon((prev) => ({ ...prev, [gQuestionId]: showAsPolygon }));
  };

  useEffect(() => {
    const geoTaggedObservations = audit?.observations.filter(({ geoTags }) => {
      if (!Array.isArray(geoTags)) return false;
      return geoTags?.length > 0;
    });

    setGeoTaggedObservations(geoTaggedObservations);

    const geoLocations = geoTaggedObservations
      .map(({ questionId, geoTags }) => ({
        geoTags: geoTags?.map((location) => ({
          questionId,
          showAsPolygon: false,
          ...location,
        })),
      }))
      .map(({ geoTags }) => geoTags)
      .flat();

    let polygonView = {};
    geoTaggedObservations.forEach((element) => {
      polygonView[element.questionId] = false;
    });

    setGeoLocations(geoLocations || []);
    setCheckedQuestions(geoLocations || []);
    setShowAsPolygon(polygonView);
  }, [audit?.observations]);

  const isQuestionSelected = (item) => {
    return checkedQuestions.some(
      ({ questionId }) => questionId === item.questionId
    );
  };

  const toggleExpandedQuestions = (id) => {
    setExpandedQuestions((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const ReadMore = ({ question, truncLen }) => {
    if (question.prompt.length > truncLen) {
      return (
        <span
          className="text-muted"
          style={{ cursor: "pointer" }}
          onClick={() => toggleExpandedQuestions(question.questionId)}
        >
          {expandedQuestions.includes(question.questionId)
            ? " [Read less]"
            : " [Read more]"}
        </span>
      );
    }
  };

  return (
    <Col>
      <Card>
        <Card.Body>
          <div className="d-flex flex-row">
            <p className="w-25 flex-fill">Geotags</p>
            <span className="me-0">
              <ArcGis audit={audit} />
            </span>
            <span className="mb-2">
              <ExpandFullScreen title="Geotags">
                <Map geolocations={checkedQuestions} search={false} />
                <Col xs={12} className="mt-4">
                  <h5
                    onClick={() => setShow((prevState) => !prevState)}
                    role="button"
                    className="mb-0"
                  >
                    {show ? (
                      <span
                        translate="no"
                        className="material-symbols-outlined me-2"
                      >
                        expand_more
                      </span>
                    ) : (
                      <span
                        translate="no"
                        className="material-symbols-outlined me-2"
                      >
                        expand_less
                      </span>
                    )}
                    Filter Locations By Questions{" "}
                  </h5>
                  <Collapse in={show}>
                    <Form className="mt-4">
                      {uniqBy(questions, "questionId")
                        .filter((q) => {
                          return geoTaggedObservations.find(
                            ({ questionId }) => questionId === q.questionId
                          );
                        })
                        .map((item) => (
                          <Fragment key={item.questionId}>
                            <div className="d-flex flex-row">
                              <span>
                                <Form.Check
                                  type="checkbox"
                                  name="checkBox-1"
                                  checked={isQuestionSelected(item)}
                                  value={item?.questionId}
                                  onChange={() => {
                                    const array = geoLocations.filter(
                                      ({ questionId }) =>
                                        questionId === item?.questionId
                                    );

                                    handleChange(array, item.questionId);
                                  }}
                                />
                                <div
                                  className="question-color-box"
                                  style={{
                                    backgroundColor: colorfy(item.questionId),
                                  }}
                                ></div>
                              </span>
                              <div
                                className={
                                  isQuestionSelected(item)
                                    ? "ms-2"
                                    : "ms-2 opacity-50"
                                }
                              >
                                <span>
                                  {item.prompt.length > 300 &&
                                  !expandedQuestions.includes(item.questionId)
                                    ? `${removeHTML(item.prompt).slice(
                                        0,
                                        300
                                      )}...`
                                    : removeHTML(item.prompt)}
                                </span>
                                <ReadMore question={item} truncLen={300} />
                                <Link
                                  style={{
                                    display: "block",
                                    width: "fit-content",
                                  }}
                                  to={`/audits/${item.auditId}/sections/${item.sectionId}/questions/${item.questionId}`}
                                >
                                  Review
                                  <span
                                    translate="no"
                                    className="material-symbols-outlined md-14 ms-1"
                                  >
                                    east
                                  </span>
                                </Link>
                                <p>
                                  GeoTags ·{" "}
                                  {item.observations?.[0]?.geoTags?.length || 0}
                                </p>
                              </div>
                              <span className="ms-auto me-0 d-flex flex-column">
                                <ButtonGroup
                                  className={
                                    !isQuestionSelected(item)
                                      ? "opacity-75"
                                      : ""
                                  }
                                >
                                  <Button
                                    title="View As Pins"
                                    className="geotag-btn-group text-start bg-opacity-25 bg-primary text-dark px-3"
                                    size="xs"
                                    variant="light"
                                    disabled={!isQuestionSelected(item)}
                                    active={!showAsPolygon[item?.questionId]}
                                    onClick={() => {
                                      handleShowAsPolygons(
                                        item.questionId,
                                        false
                                      );
                                    }}
                                  >
                                    <span
                                      translate="no"
                                      className="material-symbols-outlined md-18 text-opacity-75"
                                      style={{
                                        color: !showAsPolygon[item?.questionId]
                                          ? "white"
                                          : "",
                                      }}
                                    >
                                      location_on
                                    </span>
                                  </Button>
                                  <Button
                                    title="View As Polygon"
                                    className="geotag-btn-group text-start bg-opacity-25 bg-primary text-dark px-3"
                                    size="xs"
                                    variant="light"
                                    disabled={!isQuestionSelected(item)}
                                    active={showAsPolygon[item?.questionId]}
                                    onClick={() => {
                                      handleShowAsPolygons(
                                        item.questionId,
                                        true
                                      );
                                    }}
                                  >
                                    <span
                                      translate="no"
                                      className="material-symbols-outlined md-18 text-opacity-75"
                                      style={{
                                        color: showAsPolygon[item?.questionId]
                                          ? "white"
                                          : "",
                                      }}
                                    >
                                      pentagon
                                    </span>
                                  </Button>
                                </ButtonGroup>
                                {item.showAsPolygon ? (
                                  <div
                                    style={{
                                      backgroundColor: colorfy(item.questionId),
                                      width: "14px",
                                      height: "14px",
                                    }}
                                  ></div>
                                ) : null}
                              </span>
                            </div>

                            <hr />
                          </Fragment>
                        ))}
                    </Form>
                  </Collapse>
                </Col>
              </ExpandFullScreen>
            </span>
          </div>

          <span className="d-flex mb-4">
            <Map geolocations={checkedQuestions} search={false} />
          </span>
          <Col xs={12}>
            <div>
              <h5
                onClick={() => setShow((prevState) => !prevState)}
                role="button"
                className="mb-0"
              >
                {show ? (
                  <span
                    translate="no"
                    className="material-symbols-outlined me-2"
                  >
                    expand_more
                  </span>
                ) : (
                  <span
                    translate="no"
                    className="material-symbols-outlined me-2"
                  >
                    expand_less
                  </span>
                )}
                Filter Locations By Questions{" "}
              </h5>
              <Collapse in={show}>
                <Form className="mt-4">
                  {uniqBy(questions, "questionId")
                    .filter((q) => {
                      return geoTaggedObservations.find(
                        ({ questionId }) => questionId === q.questionId
                      );
                    })
                    .map((item) => (
                      <Fragment key={item.questionId}>
                        <div className="d-flex flex-row">
                          <span>
                            <Form.Check
                              type="checkbox"
                              name="checkBox-1"
                              checked={isQuestionSelected(item)}
                              value={item?.questionId}
                              onChange={() => {
                                const array = geoLocations.filter(
                                  ({ questionId }) =>
                                    questionId === item?.questionId
                                );

                                handleChange(array, item.questionId);
                              }}
                            />
                            <div
                              className="question-color-box"
                              style={{
                                backgroundColor: colorfy(item.questionId),
                              }}
                            ></div>
                          </span>
                          <div
                            className={
                              isQuestionSelected(item)
                                ? "ms-2"
                                : "ms-2 opacity-50"
                            }
                          >
                            <span>
                              {item.prompt.length > 170 &&
                              !expandedQuestions.includes(item.questionId)
                                ? `${removeHTML(item.prompt).slice(0, 170)}...`
                                : removeHTML(item.prompt)}
                            </span>
                            <ReadMore question={item} truncLen={170} />
                            <Link
                              style={{ display: "block", width: "fit-content" }}
                              to={`/audits/${item.auditId}/sections/${item.sectionId}/questions/${item.questionId}`}
                            >
                              Review
                              <span
                                translate="no"
                                className="material-symbols-outlined md-14 ms-1"
                              >
                                east
                              </span>
                            </Link>
                            <p>
                              GeoTags ·{" "}
                              {item.observations?.[0]?.geoTags?.length || 0}
                            </p>
                          </div>
                          <span className="ms-auto me-0 d-flex flex-column">
                            <ButtonGroup
                              className={
                                !isQuestionSelected(item) ? "opacity-75" : ""
                              }
                            >
                              <Button
                                title="View As Pins"
                                className="geotag-btn-group text-start bg-opacity-25 bg-primary text-dark px-3"
                                size="xs"
                                variant="light"
                                disabled={!isQuestionSelected(item)}
                                active={!showAsPolygon[item?.questionId]}
                                onClick={() => {
                                  handleShowAsPolygons(item.questionId, false);
                                }}
                              >
                                <span
                                  translate="no"
                                  className="material-symbols-outlined md-18 text-opacity-75"
                                  style={{
                                    color: !showAsPolygon[item?.questionId]
                                      ? "white"
                                      : "",
                                  }}
                                >
                                  location_on
                                </span>
                              </Button>
                              <Button
                                title="View As Polygon"
                                className="geotag-btn-group text-start bg-opacity-25 bg-primary text-dark px-3"
                                size="xs"
                                variant="light"
                                disabled={!isQuestionSelected(item)}
                                active={showAsPolygon[item?.questionId]}
                                onClick={() => {
                                  handleShowAsPolygons(item.questionId, true);
                                }}
                              >
                                <span
                                  translate="no"
                                  className="material-symbols-outlined md-18 text-opacity-75"
                                  style={{
                                    color: showAsPolygon[item?.questionId]
                                      ? "white"
                                      : "",
                                  }}
                                >
                                  pentagon
                                </span>
                              </Button>
                            </ButtonGroup>
                            {item.showAsPolygon ? (
                              <div
                                style={{
                                  backgroundColor: colorfy(item.questionId),
                                  width: "14px",
                                  height: "14px",
                                }}
                              ></div>
                            ) : null}
                          </span>
                        </div>

                        <hr />
                      </Fragment>
                    ))}
                </Form>
              </Collapse>
            </div>
          </Col>
        </Card.Body>
      </Card>
    </Col>
  );
};
AuditMap.propTypes = {
  audit: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
};
export default AuditMap;
