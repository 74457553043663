import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useFormikContext } from "formik";

import { FlexibleFields } from "features/assessment/constants";

import "react-datepicker/dist/react-datepicker.css";

const { DATETIME_FIELD } = FlexibleFields;

const FieldDatetime = ({ value, question, isDisabled }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext();

  if (question?.properties?.fieldType !== "datetime") return <></>;

  return (
    <>
      <DatePicker
        selected={value?.response ? new Date(value?.response) : ""}
        onChange={(date) => {
          const _date = moment(date);
          const formattedDate = _date.isValid()
            ? _date.format("YYYY-MM-DD HH:mm")
            : "";
          setFieldValue(`dynamic.${question.id}`, {
            type: DATETIME_FIELD,
            response: formattedDate,
          });
        }}
        onBlur={handleBlur}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mm aa"
        disabled={values.isInapplicable || isDisabled}
        customInput={
          <Form.Control
            type="text"
            name={question.id}
            value={value?.response || ""}
            disabled={values.isInapplicable || isDisabled}
          />
        }
      />
    </>
  );
};
FieldDatetime.propTypes = {
  question: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldDatetime;
