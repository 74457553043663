import { useCallback, useContext, useEffect, useRef, useState } from "react";

import moment from "moment";
import { useRoles } from "hooks";
import { Field, Formik } from "formik";
import {
  Link,
  useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Button,
  Card,
  Col,
  DropdownButton,
  Form,
  Modal,
  Nav,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";

import Loader from "components/ui/Loader";
import { sortArrayByDate } from "utils/ArrayUtils";
import ErrorHandler from "components/ui/ErrorHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useCreateOrUpdateObservation } from "features/assessment/services";
import {
  useAuditMapping,
  useNextAndPrevious,
  useQuestion,
} from "features/assessment/hooks";

import Extras from "./Extras";
import NotePad from "./NotePad";
import Citations from "./Citations";
import GeoTag from "./GeoTag/GeoTag";
import HelpResource from "./HelpResource";
import QuestionTags from "./QuestionTags";
import { showActionItem } from "./helper";
import FieldFindings from "./FieldFindings";
import AssignMembers from "./AssignMembers";
import { questionIsAnswered } from "../helper";
import FieldRiskOption from "./FieldRiskOption";
import FileUpload from "./FileUpload/FileUpload";
import ClearObservation from "./ClearObservation";
import FlexibleQuestions from "./FlexibleQuestions";
import InspectorComments from "./InspectorComments";
import QuestionActionItem from "./QuestionActionItem";
import FieldRecommendation from "./FieldRecommendation";
import FieldConformityLevel from "./FieldConformityLevel";
import FieldComplianceOption from "./FieldComplianceOption";
import FieldCorrectiveAction from "./FieldCorrectiveAction";
import ObservationLabelCreate from "./ObservationLabelCreate";
import ObservationLabelUpdate from "./ObservationLabelUpdate";
import FieldConformityInapplicable from "./FieldConformityInapplicable";
import FieldCausalFactors from "./FieldCausalFactors/FieldCausalFactors";
import {
  prepIntialObservationValues,
  prepSubmitObservationValues,
} from "./QuestionUtils";

import "./Quesion.scss";
import DeleteObservation from "./DeleteObservation";
import { sanitizeHTML } from "utils/StringUtils";

const Question = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const { isCertifier, isContributor } = useRoles();
  const { audit } = useContext(AuditContext);
  const { questionId, auditId, sectionId } = useParams();
  const { question } = useQuestion(questionId, audit?.questions);
  const [observation, setObservation] = useState();
  const [actionItems, setActionItems] = useState();
  const [nextOrPrevious, setNextOrPrevious] = useState();
  const [key, setKey] = useState("default");
  const { next, previous } = useNextAndPrevious(sectionId, questionId);
  const { responseUpdateMapping, observationCreateOrUpdateMapping } =
    useAuditMapping();
  const [formIsTouched, setFormIsTouched] = useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      formIsTouched && currentLocation.pathname !== nextLocation.pathname
  );

  const { error, isLoading, handleOnSubmit } = useCreateOrUpdateObservation({
    onObservationSuccess: (observation) => {
      observationCreateOrUpdateMapping(observation);

      setTimeout(() => {
        setObservation(observation || {});
        setKey(observation?.observationId);
      }, 100);
    },
    onResponsesSuccess: (responses, observation) => {
      responseUpdateMapping(responses, observation);
      setTimeout(() => {
        setObservation(observation || {});
        setKey(observation?.observationId);
      }, 100);
    },
    onFinally: () => {
      setObservation(null);
      setFormIsTouched(false);
      setTimeout(handleNavigation, 0);
    },
  });
  const MAX_OBSERVATIONS = 10;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    blocker?.reset();
  };

  const _showActionItem = showActionItem(audit?.protocol);

  const isDisabled = audit.locked || isCertifier;

  const handleNavigation = () => {
    if (
      nextOrPrevious === "proceed" &&
      typeof blocker?.proceed === "function"
    ) {
      blocker?.proceed();
      return false;
    }

    if (typeof blocker?.reset === "function") blocker?.reset();

    if (nextOrPrevious === "next") navigate(next);
    if (nextOrPrevious === "previous") navigate(previous);
    if (nextOrPrevious === "final") navigate(`/audits/${auditId}`);
  };

  const fetchActiveObservation = useCallback(() => {
    if (!question?.observations?.length) return {};
    if (!key) return question?.observations[0];

    const found = question?.observations?.find(
      (observation) => observation?.observationId === key
    );

    return found || question?.observations[0];
  }, [question, key]);

  useEffect(() => {
    function fetchObservation() {
      const found = fetchActiveObservation();

      setObservation(found || {});
      setKey(found?.observationId || "default");
    }

    if (questionId !== question?.questionId) {
      setObservation(null);
      if (typeof blocker?.reset === "function") blocker?.reset();
    }

    fetchObservation();
    setFormIsTouched(false);
  }, [blocker, questionId, question?.questionId, fetchActiveObservation]);

  useEffect(() => {
    setShow(blocker?.state === "blocked");
  }, [blocker?.state]);

  if (!question || !observation) return <Loader />;

  const observationsMappped = !!question?.observations?.length
    ? sortArrayByDate(question?.observations, "createdAt")
    : [{}];

  return (
    <>
      <Col xs={12} lg={8} md={12} className="mb-3">
        <Row>
          <Col xs={12} className="mb-3 mt-2">
            <Card>
              <Card.Header>
                <Card.Title className="my-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(question?.prompt),
                    }}
                  />
                </Card.Title>
                <div className="d-flex flex-row justify-content-between">
                  <div className="flex-fill">
                    <QuestionTags tags={question?.tags || []} />
                  </div>
                  <div>
                    <AssignMembers question={question} />
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div
                  className="text-muted"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(question?.description),
                  }}
                />

                <Tab.Container activeKey={key}>
                  {audit?.protocol?.hasObservationSets && (
                    <Nav variant="tabs" className="pt-3 d-flex flex-row">
                      {observationsMappped.map((observation, index, self) => {
                        const { observationId = "default" } = observation || {};

                        return (
                          <Nav.Item
                            key={observationId}
                            onClick={() => {
                              setKey(observationId);
                            }}
                          >
                            <Nav.Link
                              eventKey={observationId}
                              className={`d-flex flex-row ${
                                observation?.isInapplicable &&
                                "text-muted opacity-75"
                              }`}
                            >
                              <span className="flex-fill">
                                {questionIsAnswered(audit, observation) && (
                                  <span
                                    className={`material-symbols-outlined md-16 me-2 text-primary ${
                                      observation?.isInapplicable
                                        ? "text-muted"
                                        : "text-primary"
                                    }`}
                                  >
                                    check_circle
                                  </span>
                                )}
                                {observation?.label
                                  ? observation?.label
                                  : `Observation ${++index}`}
                              </span>
                              {observation.observationId &&
                                key === observationId && (
                                  <DropdownButton
                                    title=""
                                    id="observation-set"
                                    variant="outline-primary"
                                    className="observation-set-dropdown"
                                  >
                                    <ObservationLabelUpdate
                                      observation={observation}
                                    />
                                    <DeleteObservation
                                      isDisabled={isDisabled}
                                      observation={observation}
                                    />
                                  </DropdownButton>
                                )}
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                      {observationsMappped.length <= MAX_OBSERVATIONS && (
                        <Nav.Item className="flex-fill">
                          <ObservationLabelCreate
                            auditId={auditId}
                            questionId={questionId}
                            observation={observation}
                            updateObservations={(observation) => {
                              observationCreateOrUpdateMapping(observation);
                            }}
                          />
                        </Nav.Item>
                      )}
                    </Nav>
                  )}
                  <Tab.Content className="mt-3">
                    {observationsMappped.map(
                      (observation = {}, index, self) => {
                        const { observationId = "default" } = observation;
                        const length = self.length;
                        const { observationId: parentId } =
                          length > 1 ? self[0] : {};

                        // determine next and previous index
                        const nextObservation =
                          index + 1 < length ? self[index + 1] : {};
                        const prevObservation =
                          index - 1 >= 0 ? self[index - 1] : {};

                        if (observationId !== key) {
                          return false;
                        }
                        return (
                          <Tab.Pane
                            key={observationId}
                            eventKey={observationId}
                          >
                            <Formik
                              innerRef={formRef}
                              onSubmit={(values, actions) => {
                                if (audit.locked || isCertifier) {
                                  setObservation(null);
                                  setFormIsTouched(false);
                                  setTimeout(handleNavigation, 0);
                                } else {
                                  const formData = prepSubmitObservationValues(
                                    values,
                                    {
                                      auditId,
                                      parentId,
                                      questionId,
                                      observationId,
                                    }
                                  );

                                  handleOnSubmit(formData);
                                }
                              }}
                              validate={() => setFormIsTouched(true)}
                              enableReinitialize
                              initialValues={prepIntialObservationValues(
                                observation,
                                questionId
                              )}
                            >
                              {({ handleSubmit, setFieldValue, values }) => (
                                <Form onSubmit={handleSubmit}>
                                  <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                      <Modal.Title>Leaving?</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <p>
                                        It looks like you have been editing
                                        something.
                                      </p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        size="sm"
                                        variant="outline-danger"
                                        onClick={() => {
                                          blocker?.proceed();
                                          setFormIsTouched(false);
                                        }}
                                      >
                                        Discard
                                      </Button>
                                      <div className="flex-fill d-flex justify-content-end">
                                        <Button
                                          size="sm"
                                          variant="outline-primary"
                                          className="me-2"
                                          disabled={audit.locked || isCertifier}
                                          onClick={() => blocker?.reset()}
                                        >
                                          Back to Edit
                                        </Button>
                                        <Button
                                          size="sm"
                                          type="submit"
                                          variant="primary"
                                          className="px-3"
                                          disabled={
                                            isLoading ||
                                            audit.locked ||
                                            isCertifier ||
                                            isContributor
                                          }
                                          onClick={() => {
                                            handleSubmit();
                                            setNextOrPrevious("proceed");
                                          }}
                                        >
                                          {isLoading && (
                                            <Spinner
                                              animation="border"
                                              role="status"
                                              size="sm"
                                              className="me-2"
                                            >
                                              <span className="visually-hidden">
                                                Loading...
                                              </span>
                                            </Spinner>
                                          )}{" "}
                                          Save
                                        </Button>
                                      </div>
                                    </Modal.Footer>
                                  </Modal>
                                  <div className="my-3 border-bottom py-3">
                                    <Field
                                      as={Form.Switch}
                                      disabled={isDisabled}
                                      checked={values.isInapplicable}
                                      value={values.isInapplicable}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "isInapplicable",
                                          !values.isInapplicable
                                        );
                                      }}
                                      type="switch"
                                      name="isInapplicable"
                                      label="Question does not apply to this assessment"
                                    />
                                  </div>

                                  <FlexibleQuestions
                                    question={question}
                                    sectionId={sectionId}
                                    isDisabled={isDisabled}
                                  />
                                  <FieldRiskOption isDisabled={isDisabled} />
                                  <FieldComplianceOption
                                    isDisabled={isDisabled}
                                  />
                                  <FieldCorrectiveAction
                                    isDisabled={isDisabled}
                                  />
                                  <FieldConformityLevel
                                    isDisabled={isDisabled}
                                  />
                                  <FieldConformityInapplicable
                                    isDisabled={isDisabled}
                                  />
                                  <FieldFindings
                                    isDisabled={isDisabled}
                                    setActionItems={setActionItems}
                                    showActionItem={_showActionItem?.isFinding}
                                  />
                                  <FieldCausalFactors isDisabled={isDisabled} />
                                  <FieldRecommendation
                                    isDisabled={
                                      values.isInapplicable || isDisabled
                                    }
                                    setActionItems={setActionItems}
                                    showActionItem={
                                      _showActionItem?.isRecommendation
                                    }
                                  />
                                  <Extras isDisabled={isDisabled} />

                                  <FileUpload
                                    observation={observation ?? {}}
                                    disabled={
                                      values.isInapplicable || isDisabled
                                    }
                                  />
                                  {key === observationId && (
                                    <GeoTag
                                      question={question}
                                      observation={observation}
                                      disabled={
                                        values.isInapplicable || isDisabled
                                      }
                                    />
                                  )}

                                  {error && <ErrorHandler error={error} />}
                                  <Card.Footer className="bg-white d-flex flex-row  py-3 px-0 mt-4">
                                    <div className="flex-fill align-items-center">
                                      <Button
                                        size="sm"
                                        variant="light"
                                        disabled={isLoading || !previous}
                                        as={Link}
                                        to={previous}
                                        onClick={(e) => {
                                          if (prevObservation?.observationId) {
                                            e.preventDefault();
                                            setKey(
                                              prevObservation?.observationId
                                            );
                                          }
                                        }}
                                        className="me-2 border"
                                      >
                                        <span
                                          translate="no"
                                          className="material-symbols-outlined md-16 me-1"
                                        >
                                          west
                                        </span>{" "}
                                        Previous{" "}
                                      </Button>
                                    </div>
                                    <div>
                                      <ClearObservation
                                        isDisabled={isDisabled}
                                        observation={observation}
                                      />

                                      <Button
                                        size="sm"
                                        type="submit"
                                        className="px-4"
                                        variant="primary"
                                        disabled={
                                          isLoading ||
                                          isCertifier ||
                                          isContributor
                                        }
                                      >
                                        {isLoading && (
                                          <Spinner
                                            size="sm"
                                            role="status"
                                            className="me-2"
                                            animation="border"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </Spinner>
                                        )}{" "}
                                        Save
                                      </Button>
                                      <Button
                                        size="sm"
                                        variant="light"
                                        className="border px-3 ms-3"
                                        disabled={isLoading || !next}
                                        as={Link}
                                        to={next}
                                        onClick={(e) => {
                                          if (nextObservation?.observationId) {
                                            e.preventDefault();
                                            setKey(
                                              nextObservation?.observationId
                                            );
                                          }
                                        }}
                                      >
                                        Next
                                        <span
                                          translate="no"
                                          className="material-symbols-outlined md-16 ms-1"
                                        >
                                          east
                                        </span>{" "}
                                      </Button>
                                    </div>
                                  </Card.Footer>
                                </Form>
                              )}
                            </Formik>
                          </Tab.Pane>
                        );
                      }
                    )}
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
            <div className="w-100">
              {!isLoading && (
                <small className="d-flex mt-1 text-muted">
                  Updated{" "}
                  {observation?.updatedAt
                    ? moment(observation?.updatedAt).fromNow()
                    : "_"}
                </small>
              )}
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} md={12} lg={4} className={`mb-5 scroller`}>
        <QuestionActionItem
          actionItems={actionItems}
          setActionItems={setActionItems}
        />
        <InspectorComments observation={observation} formRef={formRef} />
        <Citations citations={question?.citations} />
        <HelpResource infoId={question?.infoId} />
        <NotePad observation={observation} formRef={formRef} />
      </Col>
    </>
  );
};

export default Question;
