import { useContext, useState } from "react";

import { useRoles } from "hooks";
import { useFormikContext } from "formik";
import { Button, Modal, Spinner } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { useAuditMapping } from "features/assessment/hooks";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { CHECKBOX_FIELD } from "features/assessment/constants/flexibleQuestionsFields";
import {
  useDeleteObservation,
  useFetchCertifierComments,
  useFetchObservationMemos,
} from "features/assessment/services";

const ClearObservation = ({ isDisabled, observation }) => {
  const { audit } = useContext(AuditContext);
  const { isCertifier, isContributor } = useRoles();
  const { values, setFieldValue } = useFormikContext();
  const { observationDeleteMapping } = useAuditMapping();

  const [show, setShow] = useState(false);

  const {
    error: isExpandingError,
    isLoading: isExpanding,
    memos,
  } = useFetchObservationMemos({
    observationId: observation?.observationId,
  });

  const {
    error: isFetchError,
    isLoading: isFetching,
    comments: certifierComments,
  } = useFetchCertifierComments({
    observationId: observation?.observationId,
    fetchByDefault: audit?.protocol?.hasCertificationComments,
  });

  const { error, isLoading, deleteObservation } = useDeleteObservation({
    observationId: observation?.observationId,
    onObservationDeleted: () => {
      handleClose();
      observationDeleteMapping(observation);
    },
  });

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const hasRelationships =
    memos?.length ||
    observation?.commentCount ||
    certifierComments?.length ||
    observation?.geoTags?.length ||
    observation?.attachments?.length;

  const formReset = async () => {
    let dynamic = values.dynamic || {};
    Object.keys(dynamic).forEach(async (key) => {
      const type = dynamic[key]?.type;
      dynamic[key] = {
        type: type,
        response: type === CHECKBOX_FIELD ? [] : "",
      };
    });

    await setFieldValue("isInapplicable", false);
    await setFieldValue("risk", "");
    await setFieldValue("recommendation", "");
    await setFieldValue("compliance", "");
    await setFieldValue("conformityLevel", "");
    await setFieldValue("dynamic", dynamic);
    await setFieldValue("conformityInapplicableReasons", "");
    await setFieldValue("notes", "");
    await setFieldValue("causalFactors", "");
    handleClose();
  };

  const handleDeleteObservation = () => {
    if (
      hasRelationships ||
      !observation?.observationId ||
      audit?.protocol?.hasObservationSets
    ) {
      formReset();
      return false;
    }

    deleteObservation(observation);
  };

  if (isCertifier || isContributor) return <></>;

  return (
    <>
      <Button
        size="sm"
        className="me-3 px-3"
        onClick={handleShow}
        disabled={isDisabled}
        variant="outline-primary"
      >
        Clear
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Clear Observation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-2">
          <p>I confirm that I want to clear this Observation.</p>
          {(isExpanding || isFetching) && <Loader />}
          {(error || isExpandingError || isFetchError) && (
            <ErrorHandler error={error || isExpandingError || isFetchError} />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2 px-3"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="primary"
            className="px-3"
            onClick={handleDeleteObservation}
            disabled={isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Clear
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClearObservation;
