import { Badge } from "react-bootstrap";
import PropTypes from "prop-types";

const QuestionTag = ({ isActive, tags }) => {
  if (!tags || !tags.length > 0) return <></>;
  return tags.map(({ id, name }) => (
    <Badge
      key={id}
      className={`${
        isActive
          ? "bg-light bg-opacity-25 text-light"
          : "bg-primary bg-opacity-25 text-primary"
      }  me-2 my-2 bg-opacity-10 text-primary fw-normal`}
    >
      <p className="mb-0 pe-2">
        <span
          translate="no"
          className="material-symbols-outlined md-16 me-2 mt-n2"
        >
          tag
        </span>
        <span>{name}</span>
      </p>
    </Badge>
  ));
};

QuestionTag.propTypes = {
  isActive: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
};

export default QuestionTag;
