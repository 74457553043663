import { useContext, useEffect, useRef, useState } from "react";

import { Alert, Col, Row } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import ReportAggregation from "components/common/ReportAggregation";
import { OrganizationContext } from "contexts/OrganizationProvider";
import {
  aggregationOfRisk,
  aggregationOfRiskTrends,
  defaultRisk,
  dynamicLabels,
} from "helper/reporting";

const RISK_OPTION = 0;

function OrganizationReportRisk({ protocol, filterPeriod }) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const riskLevels =
    protocol?.riskLevels && protocol?.riskLevels.length
      ? protocol?.riskLevels
      : defaultRisk;

  const [error, setError] = useState();
  const [riskStats, setRiskStats] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function fetchStats() {
      setError(null);
      setIsLoading(true);
      const riskPromise = get(
        `organizations/${organization?.id}/protocols/${protocol?.protocolId}/stats`,
        {
          params: { period: filterPeriod?.period, option: RISK_OPTION },
        }
      );
      riskPromise.promise
        .then(({ data }) => {
          setRiskStats(data);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });
      subscribedPromises.current.push(riskPromise);
    }

    if (protocol?.protocolId) {
      fetchStats();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id, protocol?.protocolId, filterPeriod]);

  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : riskStats && riskStats?.length !== 0 ? (
        <Row>
          <Col xs={12} sm={12} md={12} lg={4} className="mb-3 border-end">
            <p className="text-center w-100 text-truncate text-capitalize">
              Distribution of{" "}
              {protocol?.questionOptions[0]?.name ?? "Risk Rating"}
            </p>
            <hr />
            <ReportAggregation
              size="square-container"
              labels={dynamicLabels(riskLevels)}
              data={aggregationOfRisk(riskStats, riskLevels)}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} className="mb-4">
            <p className="text-center w-100 text-truncate text-capitalize">
              {protocol?.questionOptions[0]?.name ?? "Risk Rating"} trends
              across ·{" "}
              <small className="text-muted">{organization?.name}</small>
            </p>
            <hr />
            <ReportTrend
              size="square-container"
              labels={dynamicLabels(riskLevels)}
              data={aggregationOfRiskTrends(
                riskStats,
                riskLevels,
                filterPeriod
              )}
            />
          </Col>
        </Row>
      ) : (
        riskStats?.length === 0 && (
          <Alert variant="info" className={`my-3 d-flex flex-row`}>
            <div className="me-3">
              <span
                translate="no"
                className="material-symbols-outlined md-18 text-primary text-opacity-75"
              >
                info
              </span>
            </div>
            <div>
              <h5 className="mb-1">
                <small>No data to visualize</small>
              </h5>
              <p className="mb-1">
                <small>You have no data to visualize.</small>
              </p>
            </div>
          </Alert>
        )
      )}{" "}
    </>
  );
}

export default OrganizationReportRisk;
