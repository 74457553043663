import React, { useEffect, useMemo, useRef } from "react";
import "./ReportCheckboxField.scss";
import * as echarts from "echarts";
import PropTypes from "prop-types";
import { groupBy } from "lodash";

const CHART_HEIGHT = 270;

const ReportCheckboxField = ({ audit, field, setChartImage }) => {
  const el = useRef(null);

  const fieldResponses = useMemo(() => {
    const applicableObservations = audit?.observations
      .filter(({ isInapplicable }) => !isInapplicable)
      .map(({ observationId }) => observationId);

    return audit.responses.filter(
      (item) =>
        item.choice &&
        item.flexibleQuestionId === field.id &&
        applicableObservations.includes(item.observationId)
    );
  }, [audit.responses, audit?.observations, field.id]);

  useEffect(() => {
    const fieldObservations = groupBy(fieldResponses, (item) => {
      return item.choice.label;
    });

    const xAxisData = Object.keys(fieldObservations).map((key) => key);
    const yAxisData = Object.keys(fieldObservations).map((key) => {
      return fieldObservations[key].length;
    });

    const option = {
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />",
        confine: true,
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        axisTick: {
          show: true,
          alignWithLabel: true,
          length: 8,
        },
        axisLabel: {
          rotate: 90,
          showMaxLabel: true,
          width: 72,
          overflow: "truncate",
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: yAxisData,
          type: "bar",
        },
      ],
    };

    if (!fieldResponses.length) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
    }

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: CHART_HEIGHT,
    });

    if (setChartImage) {
      chart.on("finished", () => {
        var img = new Image();
        img.src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        setChartImage(img);
        chart.dispose();
      });
    }

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: CHART_HEIGHT,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, audit, field]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
};

ReportCheckboxField.propTypes = {
  audit: PropTypes.object.isRequired,
};

export default ReportCheckboxField;
