import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import SearchFilter from "./SearchFilter";
import AssignByFilter from "./AssignByFilter";
import AssignToFilter from "./AssignToFilter";
import EntityAndAssessmentFilter from "./EntityAndAssessmentFilter";
import StatusFilter from "./StatusFilter";
import LevelFilter from "./LevelFilter";
import TagFilters from "./TagFilters";
import AdvanceFilters from "./AdvanceFilters";
import ClearFilters from "./ClearFilters";

import "./ActionItemFilters.scss";
import ErrorHandler from "components/ui/ErrorHandler";
import { ActionItemsContext } from "features/actionItems/context/ActionItemProvider";
import OrganizationFilter from "./OrganizationFilter/OrganizationFilter";
import ActionItemShowColumn from "../ActionItemShowColumn";

const ActionItemFilters = () => {
  const { error } = useContext(ActionItemsContext);
  return (
    <>
      <Row className="mb-3 filters-wrapper">
        <SearchFilter />
        <AssignByFilter />
        <AssignToFilter />
        <EntityAndAssessmentFilter />
      </Row>
      <Row className="filters-wrapper d-flex justify-content-between">
        <Col xl={8} lg={8} md={12}>
          <Row>
            <OrganizationFilter />
            <StatusFilter />
            <LevelFilter />
            <TagFilters />
            <AdvanceFilters />
          </Row>
        </Col>
        <Col xl={4} lg={4} md={12}>
          <div className="d-flex justify-content-end">
            <ClearFilters />
            <ActionItemShowColumn />
          </div>
        </Col>
      </Row>
      {error && <ErrorHandler error={error} />}
    </>
  );
};

export default ActionItemFilters;
