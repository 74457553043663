import * as yup from "yup";

const reportValidationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string(),
  iframeCode: yup
    .string()
    .required("Iframe Code is required")
    .test("iframe-tag-only", "Only <iframe> tags are allowed", (value) => {
      const iframeRegex = /<iframe.*?>.*?<\/iframe>/gi;
      return iframeRegex.test(value);
    }),
  reportType: yup.string().required("Report Type is required"),
  isActive: yup.boolean().required("Is Active is required"),
  workspace_id: yup.string().when("reportType", {
    is: "1",
    then: yup.string().required("Workspace ID is required for PowerBi reports"),
    otherwise: yup.string(),
  }),
});

export default reportValidationSchema;
