import React, { useContext } from "react";

import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import Loader from "components/ui/Loader";
import BreadCrumbs from "components/ui/BreadCrumbs";
import ErrorHandler from "components/ui/ErrorHandler";
import { useFetchFacilityById } from "features/entity/services";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { createBreadcrumb } from "features/entity/utils/breadcrumpUtil";

import FacilityFiles from "./FacilityFiles";
import AssessmentGroups from "./AssessmentGroups";
import FacilityOverview from "./FacilityOverview";
import FacilityLeftSidebar from "./FacilityLeftSidebar";

const Facility = () => {
  const { facilityId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const organization = useContext(OrganizationContext);

  const { error, isLoading, facility, setFacility } =
    useFetchFacilityById(facilityId);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!facility) return <></>;
  if (facility.organizationId !== organization?.id)
    navigate("/", { replace: true });

  return (
    <>
      <Helmet>{facility?.name || "Entity"}</Helmet>
      <BreadCrumbs breadcrumbs={createBreadcrumb(facility, location)} />
      <Row>
        <Col xs={12} sm={12} md={12} lg={3} xl={2} className="mb-5">
          <FacilityLeftSidebar facility={facility} setFacility={setFacility} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={9} xl={10}>
          <Row>
            <Routes>
              <Route path="files" element={<FacilityFiles />} />
              <Route path="groups" element={<AssessmentGroups />} />
              <Route index element={<FacilityOverview facility={facility} />} />
            </Routes>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Facility;
