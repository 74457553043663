import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";

import { OrganizationContext } from "contexts/OrganizationProvider";
import { download } from "utils/DeApi";
import { useRoles, useToast } from "hooks";

const AuditListExport = ({ handleError, params }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isCertifier } = useRoles();
  const toast = useToast();

  const handleAuditListExport = () => {
    setIsLoading(true);

    const entitiesCsvPromise = download("audits-list-export", {
      params: {
        organizationId: organization?.id,
        ...params(),
      },
    });

    let fileName = `${organization?.name} - Assessments.csv`;

    entitiesCsvPromise.promise
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Success", "The file has been downloaded successfully");
      })
      .catch((error) => {
        !error.isCanceled && handleError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(entitiesCsvPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isCertifier) return <></>;

  return (
    <>
      <Dropdown.Item onClick={() => handleAuditListExport()}>
        Assessment List{" "}
        {isLoading ? (
          <Spinner className="me-1" animation="border" size="sm" />
        ) : (
          <span translate="no" className="material-symbols-outlined md-18">
            file_download
          </span>
        )}{" "}
      </Dropdown.Item>
    </>
  );
};

export default AuditListExport;
