import React, { createContext, useContext, useState } from "react";

import { groupBy, uniqBy } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

import { useFetchAudit } from "../services";
import { compare } from "../components/Audit/AuditDetails/helper";
import { useRoles } from "hooks";
import { UserContext } from "contexts/UserProvider";

export const AuditContext = createContext({
  audit: {},
  setAudit: () => {},
});
const AuditProvider = ({ children }) => {
  const navigate = useNavigate();
  const { isAdmin } = useRoles();

  const { auditId } = useParams();
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);

  const [audit, setAudit] = useState(null);

  const configAudit = (audit) => {
    const questions = audit.protocol.sections
      .filter(({ principles }) => principles.length > 0)
      .flatMap(({ questions, sectionId, title }) => {
        return questions.map((question) => ({
          ...question,
          sectionId: sectionId,
          sectionTitle: title,
          auditId: auditId,
        }));
      })
      .filter(({ assignedMembers }) => {
        if (isAdmin || !audit?.confidential) return true;
        if (!assignedMembers.length) return false;
        return !!assignedMembers.find(({ subscriberId }) => {
          return subscriberId === user?.subscriberId;
        });
      });

    const flexibleFormFields = audit?.protocol?.flexibleQuestions.filter(
      (q) => {
        const { properties, choices, children } = q || {};
        const { fieldType } = properties || {};
        if (fieldType === "radio" && !choices.length) return false;
        if (fieldType === "checkbox" && !choices.length) return false;
        if (fieldType === "compound" && !children.length) return false;
        return true;
      }
    );

    const questionTags = uniqBy(
      questions.flatMap(({ tags }) => tags),
      "id"
    );

    const principles = uniqBy(
      audit?.protocol?.sections.flatMap(({ principles }) => principles),
      "principleId"
    ).sort(compare);

    const observationWithResponses = audit?.observations
      .map((observation) => {
        return {
          ...observation,
          responses: audit?.responses.filter(({ observationId }) => {
            return observation.observationId === observationId;
          }),
        };
      })
      .filter(({ subscriber = {} }) => {
        if (isAdmin) return true;
        if (!audit?.confidential) return true;
        if (!audit.protocol?.hasObservationSets) return true;
        return subscriber?.subscriberId === user?.subscriberId;
      });

    const groupedObservations = groupBy(observationWithResponses, "questionId");

    setAudit({
      ...audit,
      questions: questions.map((question) => ({
        ...question,
        observations: groupedObservations[question.questionId] || [],
      })),
      principles: principles,
      selectedAssignee: null,
      selectedPrinciple: null,
      questionTags: questionTags,
      observations: observationWithResponses,
      flexibleFormFields: flexibleFormFields,
    });
  };

  const { error, isLoading } = useFetchAudit({
    auditId: auditId,
    onSuccess: (data) => {
      configAudit(data);
    },
  });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  if (!audit) return <span />;
  if (audit.organizationId !== organization?.id) navigate("/");

  return (
    <AuditContext.Provider
      value={{
        audit,
        setAudit,
      }}
    >
      {children}
    </AuditContext.Provider>
  );
};

export default AuditProvider;
