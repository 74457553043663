import { useContext, useEffect, useState } from "react";

import Slider from "react-slick";
import { useNavigate } from "react-router";
import { flatten, max, min, sortBy, uniqBy } from "lodash";
import {
  Card,
  Col,
  Dropdown,
  Row,
  Tab,
  Tabs,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import ExpandFullScreen from "components/ui/ExpandFullScreen";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import { compare } from "../helper";
import AuditMap from "./AuditMap/AuditMap";
import HelpInformation from "./HelpInformation";
import ReportCompletion from "./ReportCompletion";
import ReportRisk from "../../../common/ReportRisk";
import ReportScore from "./ReportScore/ReportScore";
import AuditDelete from "../../../common/AuditDelete";
import AuditUpdate from "../../../common/AuditUpdate";
import ReportInapplicable from "./ReportInapplicable";
import { parseFlexQuestion } from "../Question/helper";
import InherentRisk from "./InherentRisk/InherentRisk";
import AuditReportSettings from "./AuditReportSettings";
import AuditToggler from "./AuditReportSettings/AuditToggler";
import ReportCompliance from "../../../common/ReportCompliance";
import ReportRiskPrinciple from "../../../common/ReportRiskPrinciple";
import ReportConformityLevel from "../../../common/ReportConformityLevel";
import PrinciplesSpiderWeb from "features/assessment/components/common/PrinciplesSpiderWeb";
import ReportBestPracticeAndFindings from "./ReportBestPracticeAndFindings";
import ReportCheckboxField from "./ReportCheckboxField/ReportCheckboxField";
import ReportRadioField from "../../../common/ReportRadioField/ReportRadioField";
import ReportConformityPrinciple from "../../../common/ReportConformityPrinciple";
import PrinciplesScoresGuage from "features/assessment/components/common/PrinciplesScoresGuage";
import { choiceColorMap } from "../Question/FlexibleQuestions/FieldCompound/helper";
import {
  conformityLevelMap,
  riskMap,
  showCompliance,
  showConformityLevel,
  showRisk,
} from "../../helper";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./AuditReport.scss";

import SlickArrowNext from "components/ui/SlickArrowNext";
import SlickArrowPrev from "components/ui/SlickArrowPrev";

const AuditReport = () => {
  const navigate = useNavigate();
  const { audit } = useContext(AuditContext);
  const [selectedTag, setSelectedTag] = useState();
  const [chartData, setChartData] = useState([]);
  const [principles, setPrinciples] = useState([]);
  const [numericFieldsData, setNumericFieldsData] = useState([]);

  const { conformity, questionOptions, flexibleQuestions } = audit.protocol;
  const [riskLabel, complianceLabel, conformityLabel] = questionOptions || [];

  const _showRisk = showRisk(conformity, questionOptions);
  const _showCompliance = showCompliance(conformity, questionOptions);
  const _showConformityLevel = showConformityLevel(conformity, questionOptions);
  const _showCustomResponses = flexibleQuestions;

  const questions = audit?.questions;
  const observations = questions.flatMap(
    (question) => question.observations || []
  );
  const responses = observations.flatMap(
    (observation) => observation.responses
  );

  const applicableObservations = observations
    .filter(({ isInapplicable }) => !isInapplicable)
    .map(({ observationId }) => observationId);

  const isInapplicableQue = observations?.every((item) => item?.isInapplicable);

  const _hasRiskResponses = !!observations.find(
    (item) => item.risk !== null && !item.isInapplicable
  );
  const _hasComplianceResponses = !!observations.find(
    (item) => item.compliance !== null && !item.isInapplicable
  );
  const _hasConformityResponses = !!observations.find(
    (item) => item.conformityLevel !== null && !item.isInapplicable
  );

  const handleAuditDeleted = () => navigate(`/facilities/${audit.facilityId}`);
  const handleAuditUpdated = () => navigate(0);

  const visualizedRadioFields = flexibleQuestions?.filter(
    (field) => field.questionType.includes("Radio Field") && field.visualize
  );
  const visualizedCheckboxFields = flexibleQuestions?.filter(
    (field) => field.questionType.includes("Checkbox Field") && field.visualize
  );

  const visualizedNumericFields = flexibleQuestions?.filter(
    (field) => field.questionType.includes("Numeric Field") && field.visualize
  );

  let _hasDynamicResponses = false;
  let _hasDynamicRadioResponses = false;
  let _hasNumericResponses = false;
  let _hasGuagesSpiderWebResponses = false;

  if (visualizedRadioFields)
    visualizedRadioFields.forEach((field) => {
      if (
        !!responses.find(
          (item) =>
            item.choice &&
            item.flexibleQuestionId === field.id &&
            applicableObservations.includes(item.observationId)
        )
      )
        _hasDynamicRadioResponses = true;
    });

  if (visualizedCheckboxFields)
    visualizedCheckboxFields.forEach((field) => {
      if (
        !!responses.find(
          (item) =>
            item.choice &&
            item.flexibleQuestionId === field.id &&
            applicableObservations.includes(item.observationId)
        )
      )
        _hasDynamicResponses = true;
    });

  if (numericFieldsData)
    numericFieldsData.forEach((field) => {
      if (
        responses.find(
          (item) =>
            item.flexibleQuestionId === field.id &&
            applicableObservations.includes(item.observationId)
        ) &&
        numericFieldsData?.find((item) => item?.sum > 0)
      )
        _hasNumericResponses = true;
    });
  if (chartData)
    chartData.forEach((field) => {
      if (
        !!responses.find(
          (item) =>
            item.flexibleQuestionId === field.id &&
            applicableObservations.includes(item.observationId)
        )
      )
        _hasGuagesSpiderWebResponses = true;
    });

  const dynamicFields = parseFlexQuestion(responses);

  const isInherentRisk = flexibleQuestions?.filter((field) =>
    field.questionType.includes("Scored Field")
  );

  const hasInherentRiskChild = !!isInherentRisk.find(
    (item) => item.children.length > 1
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SlickArrowNext />,
    prevArrow: <SlickArrowPrev />,
    className: "card",
  };
  useEffect(() => {
    const findSectionsAndPrinciples = () => {
      const sectionsPrinciples = [
        ...new Map(
          audit?.protocol?.sections
            .flatMap(({ principles }) => principles)
            .map((p) => [p?.principleId, p])
        ).values(),
      ];
      setPrinciples(sectionsPrinciples.sort(compare));
    };
    findSectionsAndPrinciples();

    const numericData = visualizedNumericFields?.map((item) => {
      const getNumericFieldsValues = responses.filter(
        (res) =>
          res.flexibleQuestionId === item?.id &&
          applicableObservations.includes(res.observationId)
      );

      const sum = getNumericFieldsValues.reduce(
        (prev, current) => prev + Number(current.notes),
        0
      );

      return {
        ...item,
        sum,
      };
    });
    setNumericFieldsData(numericData);
  }, [audit]);

  useEffect(() => {
    const data = audit?.protocol?.showScoredFields
      ? principles
          ?.map((principle) => {
            return audit?.protocol?.flexibleQuestions
              ?.filter((flexibleQuestion) => flexibleQuestion.scoreable)
              ?.map((flexibleQuestion) => {
                const maxFlexibleQuestionScore =
                  flexibleQuestion?.choices?.reduce((acc, curr) => {
                    return curr?.score > acc ? curr?.score : acc;
                  }, 0);

                const avalableSections = audit?.protocol?.sections?.filter(
                  (section) => {
                    const rtn = section?.principles?.filter(
                      (sectionPrinciple) => {
                        return (
                          sectionPrinciple?.principleId ===
                          principle?.principleId
                        );
                      }
                    );
                    return rtn?.length;
                  }
                );
                const maxScore = avalableSections
                  ?.map((section) => {
                    return section?.questions?.reduce(
                      (acc, curr) =>
                        maxFlexibleQuestionScore * (curr?.weight || 0) + acc,
                      0
                    );
                  })
                  ?.reduce((acc, curr) => curr + acc, 0);

                const totalScore = responses?.reduce((acc, response) => {
                  const questionIsInThisPrinciple = avalableSections?.reduce(
                    (acc, section) => {
                      const answeredQuestion = section?.questions?.find(
                        (question) =>
                          question.questionId === response.questionId &&
                          flexibleQuestion?.id === response?.flexibleQuestionId
                      );
                      if (answeredQuestion) {
                        return { ...answeredQuestion };
                      } else {
                        return acc;
                      }
                    },
                    {}
                  );
                  if (Object.keys(questionIsInThisPrinciple)?.length) {
                    return (
                      acc +
                      (response?.choice?.score || 0) *
                        (questionIsInThisPrinciple?.weight || 0)
                    );
                  } else {
                    return acc;
                  }
                }, 0);

                return {
                  id: flexibleQuestion.id,
                  maxScore: maxScore,
                  totalScore,
                  principle,
                  flexibleQuestion,
                };
              });
          })
          ?.reduce((acc, curr) => [...acc, ...curr], [])
      : [];
    setChartData(data);
  }, [audit, principles]);

  return (
    <>
      <Col xs={12} md={12} lg={8} className="mb-3">
        <div className="d-flex float-end gap-2">
          <AuditToggler label="Mark As Done" />
          <Dropdown className="float-end ms-2">
            <Dropdown.Toggle className="btn-sm bg-primary bg-opacity-10 text-dark border-0 ">
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                filter_list
              </span>
              {selectedTag?.name ? selectedTag?.name : "All tags"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                active={!selectedTag}
                onClick={() => {
                  setSelectedTag();
                }}
              >
                All
              </Dropdown.Item>
              {audit?.questionTags.map((tag) => (
                <Dropdown.Item
                  key={tag.id}
                  active={selectedTag?.id === tag.id}
                  as="button"
                  onClick={() => {
                    setSelectedTag(tag);
                  }}
                >
                  {tag.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className="btn-sm bg-primary bg-opacity-10 text-dark border-0">
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                settings
              </span>{" "}
              Settings{" "}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <AuditUpdate audit={audit} onAuditUpdated={handleAuditUpdated} />
              <AuditDelete audit={audit} onAuditDeleted={handleAuditDeleted} />
              <AuditReportSettings />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <h4 className="mb-3">Summary</h4>
        <Card>
          <Card.Body>
            <p className="text-start w-100 text-truncate">Completion</p>
            <hr />
            <Row>
              <Col xs={12} md={6}>
                <ReportCompletion />
              </Col>
              <Col xs={12} md={6}>
                <ReportInapplicable />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {audit?.protocol?.showScoredFields &&
        chartData?.length &&
        !isInapplicableQue &&
        _hasGuagesSpiderWebResponses &&
        !!chartData?.reduce((acc, curr) => curr?.totalScore + acc, 0) ? (
          <>
            <Card className="mb-2 mt-3">
              <Card.Body>
                <p className="text-start w-100 text-truncate">
                  Scores By Principles (%)
                </p>
                <hr />
                <Tabs className="mb-3">
                  {Object.entries(
                    Object.groupBy(
                      chartData,
                      ({ flexibleQuestion }) => flexibleQuestion?.prompt
                    )
                  )?.map(([key, value]) => {
                    return (
                      <Tab key={`${key}`} eventKey={`${key}`} title={key}>
                        <Slider
                          {...{
                            ...settings,
                            slidesToShow: 4,
                            className: "pb-3",
                          }}
                        >
                          {value?.map((data, index) => {
                            const score =
                              (data?.totalScore / data?.maxScore) * 100;
                            if (!isNaN(score)) {
                              return (
                                <PrinciplesScoresGuage
                                  title={`${data?.principle?.title}`}
                                  score={score?.toFixed(2)}
                                  size="sm"
                                  key={index}
                                />
                              );
                            } else {
                              return false;
                            }
                          })}
                        </Slider>
                      </Tab>
                    );
                  })}
                </Tabs>
              </Card.Body>
            </Card>
            <Card className="mb-2 mt-3">
              <Card.Body>
                <p className="text-start w-100 text-truncate">
                  Scores By Principles
                </p>
                <hr />
                <Row>
                  <Col md={12} lg={12}>
                    <PrinciplesSpiderWeb
                      data={chartData}
                      principles={principles}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        ) : null}
        {responses.length > 0 || observations.length > 0 ? (
          !isInapplicableQue && (
            <Row>
              {numericFieldsData &&
                numericFieldsData?.length > 0 &&
                _hasNumericResponses && (
                  <Col className="my-2" xs={12} sm={12}>
                    <Slider {...{ ...settings, slidesToShow: 1 }}>
                      {numericFieldsData
                        ?.filter((item) => item?.sum > 0)
                        .map((numericFields) => {
                          const getNumericFieldsValues = responses.filter(
                            (res) =>
                              res.flexibleQuestionId === numericFields?.id &&
                              applicableObservations.includes(res.observationId)
                          );

                          const fieldsAnsweredValues = getNumericFieldsValues
                            .filter(
                              (filteredItem) => filteredItem.notes !== null
                            )
                            .map((item) =>
                              Number(Number(item?.notes)?.toFixed(2))
                            );

                          const hasFields = !!fieldsAnsweredValues?.length;

                          function findMedian(arr) {
                            const sortedArr = sortBy(arr);

                            const mid = Math.floor(sortedArr.length / 2);

                            if (sortedArr.length % 2 === 0) {
                              return (sortedArr[mid - 1] + sortedArr[mid]) / 2;
                            } else {
                              return sortedArr[mid];
                            }
                          }

                          const minValue = min(fieldsAnsweredValues);
                          const medianValue = findMedian(fieldsAnsweredValues);
                          const highestValue = max(fieldsAnsweredValues);

                          const millionValue = 1000000;

                          let formatter = Intl.NumberFormat("en", {
                            maximumFractionDigits: 2,
                            notation: "compact",
                            compactDisplay: "short",
                          });

                          return (
                            <>
                              <Card.Body key={numericFields?.id}>
                                <p className="w-100 text-truncate text-capitalize">
                                  Summary of {numericFields?.prompt}
                                </p>
                                <hr />
                                <div className="d-flex align-items-center justify-content-center">
                                  <span className="w-25">
                                    <h3>
                                      {hasFields
                                        ? minValue >= millionValue
                                          ? formatter.format(minValue)
                                          : Number(
                                              minValue?.toFixed(2)
                                            )?.toLocaleString()
                                        : 0}
                                    </h3>
                                    <p>Lowest</p>
                                  </span>
                                  <span className="w-25">
                                    <h3>
                                      {hasFields
                                        ? medianValue >= millionValue
                                          ? formatter.format(medianValue)
                                          : Number(
                                              medianValue?.toFixed(2)
                                            )?.toLocaleString()
                                        : 0}
                                    </h3>
                                    <p>Median</p>
                                  </span>
                                  <span className="w-25">
                                    <h3>
                                      {hasFields
                                        ? highestValue >= millionValue
                                          ? formatter.format(highestValue)
                                          : highestValue?.toLocaleString()
                                        : 0}
                                    </h3>
                                    <p>Highest</p>
                                  </span>
                                  <span className="w-25">
                                    <h3>
                                      {numericFields?.sum >= millionValue
                                        ? formatter.format(numericFields?.sum)
                                        : Number(
                                            numericFields?.sum.toFixed(2)
                                          )?.toLocaleString() || 0}
                                    </h3>
                                    <p>Total</p>
                                  </span>
                                </div>
                              </Card.Body>
                            </>
                          );
                        })}
                    </Slider>
                  </Col>
                )}
              {((_showRisk && _hasRiskResponses) ||
                (_showConformityLevel && _hasConformityResponses) ||
                (_showCompliance && _hasComplianceResponses) ||
                (_showCustomResponses && _hasDynamicRadioResponses) ||
                (_showCustomResponses && _hasDynamicResponses)) && (
                <Col className="my-2" xs={12}>
                  <Slider {...settings}>
                    {_showRisk && _hasRiskResponses && (
                      <Card.Body className="border-end">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="chart-tooltip">
                              Distribution of Findings by{" "}
                              {riskLabel?.name || "Risk Rating"}
                            </Tooltip>
                          }
                        >
                          <p className="text-center text-truncate">
                            Distribution of Findings by{" "}
                            {riskLabel?.name || "Risk Rating"}
                          </p>
                        </OverlayTrigger>
                        <hr />
                        <ReportRisk
                          audit={audit}
                          size={"lg"}
                          tooltipTitle={riskLabel?.name || "Risk Rating"}
                        />
                      </Card.Body>
                    )}{" "}
                    {_showConformityLevel && _hasConformityResponses && (
                      <Card.Body className="border-end">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="chart-tooltip">
                              Distribution of Findings by{" "}
                              {conformityLabel?.name || "Conformity Level"}
                            </Tooltip>
                          }
                        >
                          <p className="text-center w-100 text-truncate">
                            Distribution of Findings by{" "}
                            {conformityLabel?.name || "Conformity Level"}
                          </p>
                        </OverlayTrigger>
                        <hr />
                        <ReportConformityLevel
                          audit={audit}
                          size={"lg"}
                          tooltip={conformityLabel?.name || "Conformity Level"}
                        />
                      </Card.Body>
                    )}
                    {_showCompliance && _hasComplianceResponses && (
                      <Card.Body className="border-end">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="chart-tooltip">
                              Distribution of Findings by{" "}
                              {complianceLabel?.name || "Compliance"}
                            </Tooltip>
                          }
                        >
                          <p className="text-center w-100 text-truncate text-capitalize">
                            Distribution of Findings by{" "}
                            {complianceLabel?.name || "Compliance"}
                          </p>
                        </OverlayTrigger>
                        <hr />
                        <ReportCompliance
                          size={"lg"}
                          labels={{
                            compliant: "Yes",
                            nonCompliant: "No",
                          }}
                          tooltip={complianceLabel?.name || "Compliance"}
                        />
                      </Card.Body>
                    )}
                    {_hasDynamicRadioResponses &&
                      visualizedRadioFields
                        ?.filter(
                          (filteredItem) =>
                            !!responses.find(
                              (item) =>
                                item.choice &&
                                item.flexibleQuestionId === filteredItem.id &&
                                applicableObservations.includes(
                                  item.observationId
                                )
                            )
                        )
                        .map((field) => (
                          <>
                            <Card.Body key={field?.id} className="border-end">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="chart-tooltip">
                                    Distribution of Findings by{" "}
                                    {field?.prompt || "Risk Rating"}
                                  </Tooltip>
                                }
                              >
                                <p className="text-center w-100 text-truncate text-capitalize">
                                  Distribution of Findings by{" "}
                                  {field?.prompt || "Risk Rating"}
                                </p>
                              </OverlayTrigger>
                              <hr />
                              <ReportRadioField
                                audit={audit}
                                field={field}
                                size={"lg"}
                                tooltipTitle={field?.prompt}
                              />
                            </Card.Body>
                          </>
                        ))}
                    {_hasDynamicResponses &&
                      visualizedCheckboxFields
                        ?.filter(
                          (filteredItem) =>
                            !!responses.find(
                              (item) =>
                                item.choice &&
                                item.flexibleQuestionId === filteredItem.id &&
                                applicableObservations.includes(
                                  item.observationId
                                )
                            )
                        )
                        ?.map((field) => (
                          <>
                            <Card.Body
                              key={field?.id}
                              className={
                                visualizedCheckboxFields.length === 1
                                  ? ""
                                  : "border-end"
                              }
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="chart-tooltip">
                                    Distribution of Findings by{" "}
                                    {field?.prompt || "Risk Rating"}
                                  </Tooltip>
                                }
                              >
                                <p className="text-center w-100 text-truncate">
                                  Distribution of Findings by{" "}
                                  {field?.prompt || "Risk Rating"}
                                </p>
                              </OverlayTrigger>
                              <hr />
                              <ReportCheckboxField
                                audit={audit}
                                field={field}
                                size={"lg"}
                                applicableObservations={applicableObservations}
                                tooltipTitle={field.prompt}
                              />
                            </Card.Body>
                          </>
                        ))}
                  </Slider>
                </Col>
              )}
              {((_showRisk && _hasRiskResponses) ||
                (_showConformityLevel && _hasConformityResponses)) && (
                <Col className="my-2" xs={12}>
                  <Slider {...{ ...settings, slidesToShow: 1 }}>
                    {_showRisk && _hasRiskResponses && (
                      <Card.Body>
                        <div className="d-flex flex-row">
                          <p className="text-center w-100 text-truncate mb-0 pt-1 flex-fill">
                            Distribution of Findings across Principles &{" "}
                            {riskLabel?.name || "Risk Rating"}
                          </p>
                          <span>
                            <ExpandFullScreen
                              title={`Distribution of Findings across Principles & ${
                                riskLabel?.name || "Risk Rating"
                              }`}
                            >
                              <ReportRiskPrinciple audit={audit} size="lg" />
                            </ExpandFullScreen>
                          </span>
                        </div>
                        <hr />
                        <ReportRiskPrinciple audit={audit} />
                      </Card.Body>
                    )}
                    {_showConformityLevel && _hasConformityResponses && (
                      <Card.Body>
                        <div className="d-flex flex-row">
                          <p className="text-center w-100 text-truncate mb-0 pt-1 flex-fill">
                            Distribution of Findings across Principles &{" "}
                            {conformityLabel?.name || "Conformity Level"}
                          </p>
                          <span>
                            <ExpandFullScreen
                              title={`Distribution of Findings across Principles & ${
                                conformityLabel?.name || "Conformity Level"
                              }`}
                            >
                              <ReportConformityPrinciple
                                audit={audit}
                                size="lg"
                              />
                            </ExpandFullScreen>
                          </span>
                        </div>
                        <hr />
                        <ReportConformityPrinciple audit={audit} />
                      </Card.Body>
                    )}
                  </Slider>
                </Col>
              )}

              <ReportBestPracticeAndFindings audit={audit} size="lg" />

              {/* Heatmap / Risk ranking section */}
              {isInherentRisk?.length > 0 && hasInherentRiskChild && (
                <Col md={12} xs={12} className="my-2 ">
                  <Slider
                    {...{
                      ...settings,
                      slidesToShow: 1,
                    }}
                  >
                    {isInherentRisk
                      .filter(
                        (filteredItem) => filteredItem.children.length > 1
                      )
                      .map((field) => (
                        <Card.Body key={field?.id}>
                          <p className="w-100 text-truncate">IRO Summary</p>
                          <hr />
                          <InherentRisk
                            children={choiceColorMap(field?.children)}
                            dynamic={dynamicFields}
                            fieldPrompt={field?.prompt}
                            questions={audit?.questions}
                            audit={audit}
                          />
                        </Card.Body>
                      ))}
                  </Slider>
                </Col>
              )}

              {((_showRisk && _hasRiskResponses) ||
                (_showCompliance && _hasComplianceResponses) ||
                (_showConformityLevel && _hasConformityResponses)) && (
                <Col className="my-2" xs={12}>
                  <Card>
                    <Card.Body>
                      <p>Principle Gauges</p>
                      {(() => {
                        const principles = uniqBy(
                          flatten(
                            audit.protocol.sections.map(
                              ({ principles }) => principles
                            )
                          ),
                          "principleId"
                        );

                        return (
                          <Tabs className="mb-3">
                            {_showRisk && _hasRiskResponses && (
                              <Tab
                                eventKey="risk"
                                title={riskLabel?.name || "Risk"}
                              >
                                <Slider
                                  {...{
                                    ...settings,
                                    slidesToShow: 4,
                                    className: "",
                                  }}
                                >
                                  {principles.map((principle) => {
                                    const principleSections =
                                      audit.protocol.sections.filter(
                                        (section) => {
                                          return !!section.principles.find(
                                            (p) =>
                                              p.principleId ===
                                              principle.principleId
                                          );
                                        }
                                      );

                                    const pQuestions = uniqBy(
                                      flatten(
                                        principleSections.map(
                                          ({ questions }) => questions
                                        )
                                      ),
                                      "questionId"
                                    );

                                    const pObservations =
                                      audit.observations.filter((obs) => {
                                        return (
                                          !obs.isInapplicable &&
                                          obs.risk !== null &&
                                          !!pQuestions.find(
                                            (q) =>
                                              q.questionId === obs.questionId
                                          )
                                        );
                                      });
                                    const pRiskScore = pObservations.length
                                      ? pObservations.reduce((sum, obs) => {
                                          return sum + obs.risk;
                                        }, 0) / pObservations.length
                                      : null;

                                    let scoreLabel = null;
                                    let scorePercentage = null;

                                    if (pRiskScore !== null) {
                                      let riskLevels =
                                        audit.protocol?.riskLevels;

                                      scoreLabel = riskMap(
                                        Math.round(pRiskScore),
                                        riskLevels
                                      );

                                      scorePercentage =
                                        100 -
                                        (pRiskScore /
                                          (riskLevels?.length || 3)) *
                                          100;
                                    }

                                    if (scorePercentage === null) return null;

                                    return (
                                      <Col
                                        xs={12}
                                        sm={4}
                                        md={3}
                                        key={principle.principleId}
                                      >
                                        <p className="text-center text-nowrap text-truncate">
                                          <small
                                            role="button"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title={principle?.title}
                                          >
                                            {principle?.title}
                                          </small>
                                        </p>
                                        <ReportScore
                                          score={scorePercentage}
                                          label={scoreLabel || "n/a"}
                                          size={"sm"}
                                          color={"#fd666d"}
                                        />
                                      </Col>
                                    );
                                  })}
                                </Slider>
                              </Tab>
                            )}
                            {_showCompliance && _hasComplianceResponses && (
                              <Tab
                                eventKey="compliance"
                                title={complianceLabel?.name || "Compliance"}
                              >
                                <Slider
                                  {...{
                                    ...settings,
                                    slidesToShow: 4,
                                    className: "",
                                  }}
                                >
                                  {principles.map((principle) => {
                                    const principleSections =
                                      audit.protocol.sections.filter(
                                        (section) => {
                                          return !!section.principles.find(
                                            (p) =>
                                              p.principleId ===
                                              principle.principleId
                                          );
                                        }
                                      );

                                    const pQuestions = uniqBy(
                                      flatten(
                                        principleSections.map(
                                          ({ questions }) => questions
                                        )
                                      ),
                                      "questionId"
                                    );

                                    const pObservations =
                                      audit.observations.filter((obs) => {
                                        return (
                                          !obs.isInapplicable &&
                                          obs.compliance !== null &&
                                          !!pQuestions.find(
                                            (q) =>
                                              q.questionId === obs.questionId
                                          )
                                        );
                                      });

                                    const complianceLevels = 2;

                                    const pComplianceScore =
                                      pObservations.length
                                        ? pObservations.reduce((sum, obs) => {
                                            let obsScore =
                                              (100 * (1 + obs.compliance)) /
                                              complianceLevels;
                                            return sum + obsScore;
                                          }, 0) / pObservations.length
                                        : null;

                                    if (pComplianceScore === null) return null;

                                    return (
                                      <Col
                                        xs={12}
                                        sm={4}
                                        md={3}
                                        key={principle.principleId}
                                      >
                                        <p className="text-center text-nowrap text-truncate">
                                          <small
                                            role="button"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title={principle?.title}
                                          >
                                            {principle?.title}
                                          </small>
                                        </p>
                                        <ReportScore
                                          score={pComplianceScore}
                                          size={"sm"}
                                          type={"compliance"}
                                        />
                                      </Col>
                                    );
                                  })}
                                </Slider>
                              </Tab>
                            )}
                            {_showConformityLevel &&
                              _hasConformityResponses && (
                                <Tab
                                  eventKey="conformity"
                                  title={conformityLabel?.name || "Conformity"}
                                >
                                  <Slider
                                    {...{
                                      ...settings,
                                      slidesToShow: 4,
                                      className: "",
                                    }}
                                  >
                                    {principles.map((principle) => {
                                      const principleSections =
                                        audit.protocol.sections.filter(
                                          (section) => {
                                            return !!section.principles.find(
                                              (p) =>
                                                p.principleId ===
                                                principle.principleId
                                            );
                                          }
                                        );

                                      const pQuestions = uniqBy(
                                        flatten(
                                          principleSections.map(
                                            ({ questions }) => questions
                                          )
                                        ),
                                        "questionId"
                                      );

                                      const pObservations =
                                        audit.observations.filter((obs) => {
                                          return (
                                            !obs.isInapplicable &&
                                            obs.conformityLevel !== null &&
                                            !!pQuestions.find(
                                              (q) =>
                                                q.questionId === obs.questionId
                                            )
                                          );
                                        });

                                      const conformity =
                                        audit.protocol?.conformity?.filter(
                                          ({ value }) =>
                                            value !== "n/a" && value !== ""
                                        );
                                      const conformityLevels =
                                        conformity.length || 5;

                                      const pConformityScore =
                                        pObservations.length
                                          ? pObservations.reduce((sum, obs) => {
                                              return sum + obs.conformityLevel;
                                            }, 0) / pObservations.length
                                          : null;

                                      let scoreLabel = null;
                                      let scorePercentage = null;

                                      if (pConformityScore !== null) {
                                        scoreLabel = conformityLevelMap(
                                          Math.round(pConformityScore),
                                          conformity
                                        );

                                        scorePercentage =
                                          ((conformityLevels -
                                            pConformityScore) /
                                            conformityLevels) *
                                          100;
                                      }
                                      if (scorePercentage === null) return null;

                                      return (
                                        <Col
                                          xs={12}
                                          sm={4}
                                          md={3}
                                          key={principle.principleId}
                                        >
                                          <p className="text-center text-nowrap text-truncate">
                                            <small
                                              role="button"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title={principle?.title}
                                            >
                                              {principle?.title}
                                            </small>
                                          </p>
                                          <ReportScore
                                            score={scorePercentage}
                                            label={scoreLabel}
                                            size={"sm"}
                                            type="conformity"
                                          />
                                        </Col>
                                      );
                                    })}
                                  </Slider>
                                </Tab>
                              )}
                          </Tabs>
                        );
                      })()}
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {!!audit.protocol?.hasGeolocation && (
                <Col className="my-2" xs={12}>
                  <AuditMap audit={audit} questions={audit?.questions} />
                </Col>
              )}
            </Row>
          )
        ) : (
          <></>
        )}
      </Col>
      <Col lg={4} md={12} xs={12} className="scroller mb-3">
        <HelpInformation observations={observations} />
      </Col>
    </>
  );
};

export default AuditReport;
