import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import NotePadUpdate from "../NotePadUpdate";
import NotePadDelete from "../NotePadDelete";
import PropTypes from "prop-types";
const NotePadCard = ({ memos, setMemos }) => {
  const [showUpdateForm, setShowUpdateForm] = useState("");

  const onMemoDelete = (deletedMemo) => {
    setMemos((prevMemo) => {
      return prevMemo.filter((memo) => {
        return deletedMemo?.id !== memo?.id;
      });
    });
  };

  const onMemoUpdate = (updatedMemo) => {
    setMemos((prevMemos) => {
      return prevMemos.map((memo) => {
        if (updatedMemo?.id === memo?.id) return updatedMemo;
        return memo;
      });
    });
  };

  if (!memos) return <></>;
  return (
    <>
      {Array.isArray(memos) &&
        memos.map((memo, index, arr) => {
          return (
            <Row key={memo?.id}>
              {showUpdateForm !== memo?.id ? (
                <Fragment>
                  <Col xs={10}>
                    <p>{memo.key}</p>
                  </Col>
                  <Col xs={2} className="d-flex justify-content-end">
                    {showUpdateForm !== memo?.id && (
                      <>
                        <span
                          translate="no"
                          className="material-symbols-outlined md-20 cursor-pointer me-1"
                          onClick={() => setShowUpdateForm(memo?.id)}
                        >
                          edit
                        </span>
                        <NotePadDelete
                          memo={memo}
                          onMemoDelete={onMemoDelete}
                        />
                      </>
                    )}
                  </Col>
                  <Col xs={12}>
                    <p>
                      <small>{memo.value}</small>
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p>
                      <small className="text-muted">
                        {new Date(memo.updatedAt).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </small>
                    </p>
                  </Col>
                </Fragment>
              ) : (
                <Col xs={12}>
                  <NotePadUpdate
                    memo={memo}
                    onMemoUpdate={onMemoUpdate}
                    setShowUpdateForm={setShowUpdateForm}
                  />
                </Col>
              )}
              <hr />
            </Row>
          );
        })}
    </>
  );
};
NotePadCard.propTypes = {
  memos: PropTypes.array,
  setMemos: PropTypes.func,
};
export default NotePadCard;
