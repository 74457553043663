import React, { useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";

const CommentUpdate = ({
  observationId,
  comment,
  commentId,
  onCommentUpdated,
}) => {
  const { isCertifier } = useRoles();
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateComment = ({ comment }) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = put(
      `/observations/${observationId}/comments/${commentId}`,
      {
        comment: comment ?? "",
      }
    );
    auditPromise.promise
      .then((response) => {
        setIsLoading(false);
        onCommentUpdated(response.data);
        handleClose();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(auditPromise);
  };

  const schema = yup.object().shape({
    comment: yup
      .string()
      .min(2, "Comment is too Short!")
      .required("Comment is required"),
  });

  if (!isCertifier) return <></>;

  return (
    <>
      <Button
        variant="outline-primary"
        className="me-2"
        size="sm"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-18">
          edit
        </span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Comment</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            updateComment({
              comment: values.comment,
            });
          }}
          initialValues={{
            comment: comment?.comments ?? "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="comment" className="mb-3">
                  <Form.Label>
                    Comment <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    comment="comment"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.comment && touched.comment}
                    isValid={values.comment && !errors.comment}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.comment && touched.comment ? (
                      <small>{errors.comment}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={!values.comment || isLoading}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Update Comment
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

CommentUpdate.propTypes = {
  observationId: PropTypes.string.isRequired,
  onCommentUpdated: PropTypes.func.isRequired,
};

export default CommentUpdate;
