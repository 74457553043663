import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useRoles } from "hooks";
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";

const SideNavigation = () => {
  const { audit } = useContext(AuditContext);
  const { isAdmin, isContributor } = useRoles();

  const sideNavigationItems = [
    {
      cy: "summary",
      name: "Summary",
      icon: "insights",
      link: `/audits/${audit.auditId}`,
      show: true,
    },
    {
      cy: "files-photos",
      name: "Files & Photos",
      icon: "drive_folder_upload",
      link: `/audits/${audit.auditId}/files`,
      show: audit?.protocol?.hasFiles,
    },
    {
      cy: "assessment-team",
      name: "Assessment Team",
      icon: "group_add",
      link: `/audits/${audit.auditId}/members`,
      show: true,
    },
    {
      cy: "signatures",
      name: "Signatures",
      icon: "verified",
      link: `/audits/${audit.auditId}/signatures`,
      show: audit?.protocol?.hasSignatories,
    },
    {
      cy: "scope-metadata",
      name: "Scope & Metadata",
      icon: "info",
      link: `/audits/${audit.auditId}/metadata`,
      show: !!audit?.protocol?.assessmentLevelFields?.length,
    },
    {
      cy: "applicability-screening",
      name: "Applicability Screening",
      icon: "manage_history",
      link: `/audits/${audit.auditId}/pre-qualification`,
      show:
        (!audit?.confidential || isAdmin) &&
        !!audit?.applicabilityScreening?.length,
    },
  ];
  return sideNavigationItems
    .filter((item) => (isContributor ? item.cy === "files-photos" : true))
    .map((item) => {
      if (!item.show) return <Fragment key={item.cy} />;
      return (
        <h5 className="mb-3" key={item.cy}>
          <Link
            to={item.link}
            data-cy={item.cy}
            className="text-decoration-none"
          >
            {item.name}
            <span
              translate="no"
              className="float-end material-symbols-outlined md-20"
            >
              {item.icon}
            </span>
          </Link>
        </h5>
      );
    });
};
export default SideNavigation;
