import { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ReportAggregation from "components/common/ReportAggregation";
import { OrganizationContext } from "contexts/OrganizationProvider";
import {
  aggregationOfRisk,
  aggregationOfRiskTrends,
  defaultRisk,
  dynamicLabels,
} from "helper/reporting";

function FacilityReportRisk({ protocol, filterPeriod, facility }) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const riskLevels =
    protocol?.riskLevels && protocol?.riskLevels.length
      ? protocol?.riskLevels
      : defaultRisk;

  const [error, setError] = useState();
  const [riskStats, setRiskStats] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function fetchStats() {
      setError(null);
      setIsLoading(true);

      const riskPromise = get(
        `facilities/${facility?.facilityId}/protocols/${protocol?.protocolId}/stats`,
        {
          params: {
            option: 0,
            period: filterPeriod?.period,
            organizationId: organization?.id,
          },
        }
      );
      riskPromise.promise
        .then(({ data }) => {
          setRiskStats(data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(riskPromise);
    }

    if (protocol?.protocolId) {
      fetchStats();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    filterPeriod,
    organization.id,
    protocol?.protocolId,
    facility?.facilityId,
  ]);

  if (error) return <ErrorHandler error={error} />;

  return (
    <Row>
      {isLoading ? (
        <Loader />
      ) : riskStats && riskStats?.length !== 0 ? (
        <>
          <Col xs={12} sm={12} md={12} lg={4} className="mb-3 border-end">
            <p className="text-center w-100 text-truncate text-capitalize">
              Distribution of{" "}
              {protocol?.questionOptions[0]?.name ?? "Risk Rating"}
            </p>
            <hr />
            <ReportAggregation
              size="square-container"
              labels={dynamicLabels(riskLevels)}
              data={aggregationOfRisk(riskStats, riskLevels)}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} className="mb-4">
            <p className="text-center w-100 text-truncate text-capitalize">
              {protocol?.questionOptions[0]?.name ?? "Risk Rating"} trends
              across ·{" "}
              <small className="text-muted">{facility?.name || "Entity"}</small>
            </p>
            <hr />
            <ReportTrend
              size="square-container"
              labels={dynamicLabels(riskLevels)}
              data={aggregationOfRiskTrends(
                riskStats,
                riskLevels,
                filterPeriod
              )}
            />
          </Col>
        </>
      ) : (
        riskStats?.length === 0 && (
          <Col>
            <EmptyStateHandler
              title="No data to visualize"
              description="You have no data to visualize."
            />
          </Col>
        )
      )}
    </Row>
  );
}

FacilityReportRisk.propTypes = {
  protocol: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired,
  filterPeriod: PropTypes.object.isRequired,
};

export default FacilityReportRisk;
