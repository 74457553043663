import React from "react";
import "./TypeAheadInput.scss";
import { Form } from "react-bootstrap";

const TypeAheadInput = ({ ...inputProps }) => {
  const { value, onClear, isLoading } = inputProps || {};
  return (
    <Form.Group
      className={`typeahead-input ${
        !!value ? "typeahead-active-input bg-primary bg-opacity-10" : ""
      }`}
    >
      <Form.Control {...inputProps} type="text" />
      {!!value && !isLoading && (
        <button
          aria-label="Clear"
          className="close btn-close rbt-close me-2"
          type="button"
          onClick={() => onClear()}
        >
          <span class="sr-only visually-hidden">Clear</span>
        </button>
      )}
    </Form.Group>
  );
};

export default TypeAheadInput;
