import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useLocation } from "react-router-dom";
import { useRoles } from "hooks";

const lockedStatus = [
  {
    index: 1,
    name: "All",
    locked: "",
    status: "",
  },
  {
    index: 2,
    name: "Locked",
    locked: "true",
    status: "",
  },
  {
    index: 3,
    name: "In Progress",
    locked: "false",
    status: "0",
  },
  {
    index: 4,
    name: "Done",
    locked: "",
    status: "1",
  },
];
const sortingColumns = [
  {
    index: 0,
    name: "Name",
    value: "name",
  },
  {
    index: 1,
    name: "Updated",
    value: "updated_at",
  },
];

const OrgAuditListFilters = ({
  filters,
  setFilters,
  tableSortState,
  sortAssessments,
  isLoading,
  resettingFilters,
}) => {
  const { updatedDate, entity, lockStatus, search, dueDate } = filters;

  const { isContributor, isCertifier } = useRoles();
  const [updatedStartDate, updatedEndDate] = updatedDate;
  const [dueStartDate, dueEndDate] = dueDate;
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const facilityId = urlParams.get("facilityId") || "";

  const [error, setError] = useState("");
  const [isExpanding, setIsExpanding] = useState();
  const [entities, setEntities] = useState();
  const [query, setQuery] = useState("");

  const [protocols, setProtocols] = useState("");

  const sendSearchQuery = useRef(
    debounce(({ q, key }) => {
      setFilters((prev) => ({ ...prev, [key]: q }));
    }, 1000)
  );

  const selectingFacility = (facilities, facilityId) => {
    const [entity] = facilities.filter((f) => f.facilityId === facilityId);
    setFilters((prev) => ({ ...prev, entity }));
  };

  const fetchProtocols = () => {
    setIsExpanding(true);
    const subscribersPromise = get(`protocols`, {
      params: {
        organizationId: organization?.id,
      },
    });
    subscribersPromise.promise
      .then((response) => {
        setProtocols(response.data);
        setIsExpanding(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsExpanding(false);
      });

    subscribedPromises.current.push(subscribersPromise);
  };

  useEffect(() => {
    fetchProtocols();
    const fetchFacilities = () => {
      setIsExpanding(true);
      const facilitiesPromise = get(
        `organizations/${organization?.id}/facilities`,
        {
          params: {
            perPage: 10000,
          },
        }
      );
      facilitiesPromise.promise
        .then(({ data: facilities }) => {
          selectingFacility(facilities, facilityId);
          setEntities(facilities ?? []);
          setIsExpanding(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsExpanding(false);
        });
    };

    fetchFacilities();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id]);

  error && console.error(error);

  useEffect(() => {
    if (!lockStatus) {
      setFilters((prev) => ({
        ...prev,
        lockStatus: lockedStatus[0],
      }));
    }

    if (!search) {
      setQuery("");
    }
  }, [lockStatus, search]);

  const ItemWithTooltip = ({ displayText }) => {
    return (
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip>{displayText}</Tooltip>}
      >
        <span>{displayText}</span>
      </OverlayTrigger>
    );
  };

  return (
    <Row>
      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Filter by Updated Date</small>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={updatedStartDate}
          onChange={(date) =>
            setFilters((prev) => ({ ...prev, updatedDate: date }))
          }
          startDate={updatedStartDate}
          endDate={updatedEndDate}
          selectsRange
          placeholderText="Filter by Updated Date"
          monthsShown={2}
          className="form-control"
        >
          <Button
            size="sm"
            className="mb-1"
            onClick={() => setFilters((prev) => ({ ...prev, updatedDate: [] }))}
          >
            Clear
          </Button>
        </DatePicker>
      </Col>

      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Filter by Due Date</small>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={dueStartDate}
          onChange={(date) =>
            setFilters((prev) => ({ ...prev, dueDate: date }))
          }
          startDate={dueStartDate}
          endDate={dueEndDate}
          selectsRange
          placeholderText="Filter by Due Date"
          monthsShown={2}
          className="form-control"
        >
          <Button
            size="sm"
            className="mb-1"
            onClick={() => setFilters((prev) => ({ ...prev, dueDate: [] }))}
          >
            Clear
          </Button>
        </DatePicker>
      </Col>
      {!isContributor && (
        <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
          <small className="text-muted">Filter By Entity</small>
          <Typeahead
            id="entity"
            name="entity"
            clearButton
            isLoading={isExpanding}
            labelKey={(option) => `${option?.name}`}
            placeholder="Select an entity..."
            onChange={(e) => {
              !e[0] &&
                setFilters((prev) => ({
                  ...prev,
                  entity: "",
                }));
              setFilters((prev) => ({ ...prev, entity: e[0] }));
            }}
            options={entities || []}
            selected={entity ? [entity] : []}
            renderMenu={(results, menuProps) => (
              <Menu className="" {...menuProps}>
                {results.map((result, index) => (
                  <MenuItem
                    key={result.facilityId}
                    option={result}
                    position={index}
                  >
                    <ItemWithTooltip
                      displayText={
                        <div>
                          {result.name}
                          <br />
                          <small className="text-muted">{result.address}</small>
                        </div>
                      }
                    />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
        </Col>
      )}
      {!isContributor && !isCertifier && (
        <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
          <small className="text-muted">Filter By Protocol</small>
          <Typeahead
            id="protocols"
            name="protocols"
            clearButton
            isLoading={isExpanding}
            labelKey={(option) => `${option?.name}`}
            placeholder="Select a protocol..."
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, protocols: e[0] }));
            }}
            options={protocols || []}
            selected={filters.protocols ? [filters.protocols] : []}
            renderMenu={(results, menuProps) => (
              <Menu className="" {...menuProps}>
                {results.map((result, index) => (
                  <MenuItem
                    key={result.protocolId}
                    option={result}
                    position={index}
                  >
                    <ItemWithTooltip displayText={result.name} />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
        </Col>
      )}

      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Filter By Status</small>
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-secondary"
            id="dropdown-done"
            className="w-100 text-start border border-gray-900 bg-white text-secondary"
          >
            {lockStatus?.name}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {lockedStatus.map((s) => (
              <Dropdown.Item
                key={s.index}
                active={s.index === lockStatus.index}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, lockStatus: s }))
                }
              >
                {s.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Search By Title</small>
        <Form.Control
          type="text"
          value={query || ""}
          onChange={(e) => {
            const q = e.target.value;
            setQuery(q);
            sendSearchQuery.current({ q, key: "search" });
          }}
          placeholder="Search"
        />
      </Col>
      <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
        <small className="text-muted">Sort By</small>
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-secondary"
            id="dropdown-done"
            className="w-100 text-start border border-gray-900 bg-white text-secondary"
          >
            {tableSortState.sortColumn.includes("name") ? "Name" : "Updated"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {sortingColumns.map((s) => (
              <Dropdown.Item
                key={s.index}
                active={s.value === tableSortState.sortColumn}
                onClick={() => !isLoading && sortAssessments(s.value)}
              >
                {s.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col
        xs={12}
        sm={isCertifier ? 12 : 4}
        md={isCertifier ? 6 : isContributor ? 9 : 3}
        lg={isCertifier ? 6 : isContributor ? 9 : 3}
        className="mb-3 d-flex flex-column"
      >
        <div className="flex-grow-1"></div>{" "}
        <div className="d-flex justify-content-end">
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2"
            onClick={() => resettingFilters()}
          >
            <span translate="no" className="material-symbols-outlined md-18">
              restart_alt
            </span>
            Reset Filters
          </Button>
        </div>
      </Col>
    </Row>
  );
};
OrgAuditListFilters.propTypes = {
  isLoading: PropTypes.bool,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  tableSortState: PropTypes.object,
  sortAssessments: PropTypes.func,
};

export default OrgAuditListFilters;
