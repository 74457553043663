import { OrganizationContext } from "contexts/OrganizationProvider";
import { useEffect, useState, useRef, useContext } from "react";
import { get } from "utils/DeApi";

const useFetchFacilityById = (facilityId) => {
  const [error, setError] = useState(null);
  const [facility, setFacility] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  useEffect(() => {
    function fetchFacility() {
      setError(null);
      setIsLoading(true);
      const facilitiesPromise = get(`facilities/${facilityId}`, {
        params: { organizationId: organization?.id },
      });
      facilitiesPromise.promise
        .then(({ data: facility }) => {
          setFacility(facility);
        })
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setTimeout(() => setIsLoading(false), 500));
      subscribedPromises.current.push(facilitiesPromise);
    }

    if (facilityId && organization?.id) fetchFacility();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [facilityId, organization?.id]);

  return {
    error,
    isLoading,
    facility,
    setFacility,
  };
};

export default useFetchFacilityById;
