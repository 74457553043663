import React, { useContext } from "react";

import PropTypes from "prop-types";
import { useRoles } from "hooks";
import { Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

import { WhatsNew, Help } from "features/contents";
import DataExport from "components/DataExport";
import { UserContext } from "contexts/UserProvider";
import { OrganizationToggler } from "features/organization";
import { OrganizationContext } from "contexts/OrganizationProvider";

import Feedback from "../../components/common/Feedback";

import "./Header.scss";

const Header = ({ isProfileComponent = false }) => {
  const user = useContext(UserContext);
  const { pathname = "" } = useLocation();

  const organization = useContext(OrganizationContext);
  const { isAdmin, isCertifier, isContributor } = useRoles();
  const isVertical = !!process.env.REACT_APP_VERTICAL;

  return (
    <Navbar expand="lg" fixed="top" className={`MainHeader`}>
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            src={`${
              isVertical ? "/erm-logo-green-empty.svg" : "/logo-online.svg"
            }`}
            alt="Logo"
          />
          {isVertical && (
            <span className="align-middle ms-2">
              {process.env.REACT_APP_VERTICAL}
            </span>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {!isProfileComponent && (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <OrganizationToggler />
            </Nav>
            <Nav className="ms-auto align-items-center">
              <WhatsNew />
              <Help />
              <Feedback />
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={"/audits"}
                  className="px-2 me-1"
                  active={pathname === "/audits"}
                >
                  <span>Assessments</span>
                </Nav.Link>
              </Nav.Item>
              {!isCertifier ? (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to={"/action-items"}
                    className="px-2 me-1"
                    active={pathname === "/action-items"}
                  >
                    <span>Action Items</span>
                  </Nav.Link>
                </Nav.Item>
              ) : null}
              <NavDropdown
                align="end"
                title={`Hi, ${user?.firstName}`}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item disabled>{user?.email}</NavDropdown.Item>

                {isAdmin && (
                  <NavDropdown.Item
                    as={Link}
                    to={`/organizations/${organization?.id}/members`}
                    active={pathname?.includes(`/organizations/`)}
                  >
                    Manage · <small>{organization?.name}</small>
                  </NavDropdown.Item>
                )}
                {isAdmin && (
                  <NavDropdown.Item
                    as={Link}
                    to="/activity-log"
                    className="my-2"
                    active={pathname === "/activity-log"}
                  >
                    Activity Log
                  </NavDropdown.Item>
                )}
                {!isContributor ? <DataExport level="navbar" /> : null}
                <NavDropdown.Divider />
                {!isContributor ? (
                  <NavDropdown.Item
                    as={Link}
                    to={`/profile/${user?.userId}/organizations`}
                  >
                    Profile
                  </NavDropdown.Item>
                ) : null}
                <NavDropdown.Item as={Link} to="/logout">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  isProfileComponent: PropTypes.bool,
};

export default Header;
