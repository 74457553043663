import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import subjects from "./SubjectTypes";

const SubjectTypeahead = ({ onChange, selected }) => {
  const [subject, setSubject] = useState([]);

  useEffect(() => {
    setSubject(selected);
  }, [selected]);

  return (
    <Typeahead
      id="subject-typeahead"
      name="subject"
      clearButton
      labelKey={(option) => `${option.label}`}
      placeholder="Select a subject..."
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps}>
          {results.map((result, index) => (
            <MenuItem key={result.key} option={result} position={index}>
              {result.label}
            </MenuItem>
          ))}
        </Menu>
      )}
      onChange={(data) => {
        onChange(data);
        setSubject(data);
      }}
      options={subjects}
      selected={subject}
    />
  );
};

SubjectTypeahead.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default SubjectTypeahead;
