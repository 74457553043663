import { useContext } from "react";

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import AuditInfo from "features/assessment/components/common/AuditInfo";

const LeftSidePanel = () => {
  const { audit } = useContext(AuditContext);

  return (
    <>
      <AuditInfo />
      <div className="d-flex flex-column gap-2">
        <Button
          variant="primary"
          size="sm"
          as={Link}
          to={`/audits/${audit.auditId}`}
        >
          <span translate="no" className="material-symbols-outlined md-18 me-2">
            west
          </span>
          Assessment{" "}
        </Button>
      </div>
    </>
  );
};

export default LeftSidePanel;
