import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
const AttendeeDelete = ({ attendee, onDeleted }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button size="sm" variant="light" onClick={handleShow}>
        <span
          translate="no"
          className="material-symbols-outlined md-16 text-danger"
        >
          delete
        </span>
      </Button>

      <Modal show={show} onHide={handleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Attendee{" "}
            <i className="text-capitalize">{attendee.fullName}</i>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to delete this attendee?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            size="sm"
            variant="danger"
            onClick={() => {
              onDeleted(attendee);
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
AttendeeDelete.propTypes = {
  attendee: PropTypes.object,
  onDeleted: PropTypes.func,
};
export default AttendeeDelete;
