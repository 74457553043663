import { capitalize } from "lodash";
import moment from "moment";
import React from "react";

function ListView({
  id = "",
  prompt = "",
  properties = {},
  choices = [],
  choice = {},
  notes = "",
  responseDatetime = "",
  matchResponse = [],
}) {
  if (properties?.fieldType === "text" && notes) {
    return (
      <span key={id} className="mb-1">
        <span className="w-50 fw-bold me-2">{prompt}: </span>
        <span className="mb-0">
          <span className="text-capitalize-first">{notes}</span>
        </span>
      </span>
    );
  }
  if (properties?.fieldType === "datetime" && responseDatetime) {
    const datetime = responseDatetime
      ? moment.utc(responseDatetime).format("MMMM D, yyyy h:mm a")
      : "";
    return (
      <span key={id} className="mb-1">
        <span className="w-50 fw-bold me-2">{prompt}: </span>
        <span className="mb-0">
          <span>{datetime}</span>
        </span>
      </span>
    );
  }
  if (properties?.fieldType === "numeric" && notes) {
    return (
      <span key={id} className="mb-1">
        <span className="w-50 fw-bold me-2">{prompt}: </span>
        <span className="text-capitalize-first text-break mb-0">{notes}</span>
      </span>
    );
  }

  if (properties?.fieldType === "radio" && choice?.label) {
    return (
      <span key={id} className="mb-1">
        <span className="w-50 fw-bold me-2">{prompt}: </span>
        <span className="">
          <span>
            <span className="">{choice?.label}</span>
          </span>
        </span>
      </span>
    );
  }

  if (properties?.fieldType === "checkbox" && !!choices?.length) {
    return (
      <span key={id} className="mb-1">
        <strong>{prompt}: </strong>
        <>
          {!!choices?.length &&
            choices
              ?.sort((a, b) => a?.order - b?.order)
              ?.map(
                ({ label, score }, index, arr) =>
                  `${capitalize(label)} ${arr.length > index + 1 ? "," : ""} `
              )}
        </>
      </span>
    );
  }
}

export default ListView;
