import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import * as yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import { get, post } from "utils/DeApi";
import { UserContext } from "contexts/UserProvider";
import SlateEditor from "components/ui/SlateEditor";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { OrganizationContext } from "contexts/OrganizationProvider";

import ActionItemType from "./ActionItemType";
import ErrorHandler from "components/ui/ErrorHandler";
import EntityTypeahead from "features/files/components/common/EntityTypeahead";

const ActionItemCreate = ({
  questionPrompt,
  members,
  title,
  auditId,
  variant,
  className,
  taskableId,
  facilityId,
  taskableType,
  showCanvas = false,
  onActionItemCreated,
  onCanvasHidden,
  description,
  disabled,
  dashboard = false,
  children,
}) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(showCanvas);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const user = useContext(UserContext);
  const [subscribers, setSubscribers] = useState([]);
  const [tags, setTags] = useState([]);
  const [dueDate, setDueDate] = useState(new Date());
  const location = useLocation();
  const [node, setNode] = useState(false);
  const [entity, setEntity] = useState([]);

  const addYears = (date, years) => {
    date.setFullYear(date.getFullYear() + years);
    return date;
  };

  const handleClose = () => {
    setShow(false);
    if (typeof onCanvasHidden === "function") onCanvasHidden();
  };
  const handleShow = () => {
    setShow(true);
  };

  const autoFocusRef = useCallback(
    (node) => node !== null && setNode(node),
    []
  );

  const createActionItem = (values) => {
    const { item, description, assignedTo, tags, dueDate, type, entity } =
      values;
    const [{ facilityId } = {}] = entity || [];
    setError(null);
    setIsLoading(true);
    const actionItemPromise = post("action-items", {
      description,
      assignedTo: assignedTo?.length
        ? assignedTo.map(({ subscriberId }) => subscriberId)
        : [],
      tags: tags?.length ? tags.map(({ name = "" }) => name.toLowerCase()) : [],
      dueDate: moment(dueDate).format("YYYY-MM-DD HH:mm:ss"),
      action_item_type: parseInt(type),
      item,
      status: 0,
      assignedBy: user?.de?.subscriberId,
      taskableType: facilityId ? "facility" : taskableType ?? null,
      taskableId: facilityId || (taskableId ?? null),
      organizationId: organization?.id,
      referer: window.location.origin,
      path: location?.pathname,
      ...(auditId
        ? {
            audit_id: auditId,
          }
        : {}),
    });

    actionItemPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onActionItemCreated(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(actionItemPromise);
  };

  const schema = yup.object().shape({
    item: yup
      .string()
      .min(2, "Text is too Short!")
      .max(255, "Text is too Long!")
      .required("This field is required"),
    description: yup.string().nullable(),
    assignedTo: yup.array().min(1).required(),
    dueDate: yup.date().required("Due date is a required field"),
    entity: dashboard ? yup.array().min(1).required() : yup.array().nullable(),
  });

  const removeHtmlTags = (str) => {
    if (!str) return "";
    return str.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 245);
  };

  useEffect(() => {
    setShow(showCanvas);
  }, [showCanvas]);

  useEffect(
    function fetchSubscribers() {
      if (!show) return () => {};

      if (dashboard && !entity?.length) return () => {};

      setIsExpanding(true);
      const subscribersUrl = () => {
        if (dashboard && entity?.length)
          return `facilities/${entity[0]?.facilityId}/subscribers`;
        if (taskableType === "facility")
          return `facilities/${taskableId}/subscribers`;
        if (taskableType === "question")
          return `facilities/${facilityId}/subscribers`;
        return "subscribers";
      };

      const subscribersPromise = get(subscribersUrl(), {
        params: {
          organizationId: organization?.id,
        },
      });
      subscribersPromise.promise
        .then((response) => {
          setIsExpanding(false);
          setSubscribers(response.data ?? []);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsExpanding(false);
        });

      return () => {
        subscribersPromise.cancel();
      };
    },
    [
      organization,
      user,
      show,
      members,
      taskableType,
      taskableId,
      facilityId,
      dashboard,
      entity,
    ]
  );

  useEffect(
    function fetchTags() {
      if (!show || !node) {
        return () => {};
      }

      node.focus();
      setIsSyncing(true);
      const orgTagsPromise = get(`/organizations/${organization?.id}/tags`);
      orgTagsPromise.promise
        .then((response) => {
          setTags(response.data ?? []);
          setIsSyncing(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsSyncing(false);
        });

      return () => {
        orgTagsPromise.cancel();
      };
    },
    [organization, user, show, node]
  );

  if (organization?.invitation?.role === "Certifier") return <Fragment />;
  return (
    <>
      <Button
        size="sm"
        disabled={disabled}
        onClick={handleShow}
        variant={variant || "primary"}
        className={`float-end ${className}`}
      >
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        New Action Item
      </Button>

      <Offcanvas show={show} placement="end" className="w-fixed-640">
        <Offcanvas.Header
          onHide={handleClose}
          className="border-bottom"
          closeButton
        >
          <Offcanvas.Title>
            {taskableType === "question"
              ? "New Corrective Action Item"
              : "New Action Item"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="overflow-auto">
          <Formik
            validationSchema={schema}
            onSubmit={(values) => {
              createActionItem(values);
            }}
            initialValues={{
              item: removeHtmlTags(questionPrompt),
              assignedTo: members || [],
              tags: [],
              dueDate: moment(dueDate).format("YYYY-MM-DD"),
              description,
              type: taskableType === "question" ? 1 : 0,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Offcanvas.Body className="h-100vh">
                  <Form.Group controlId="item" className="mb-3">
                    <Form.Label className="mb-1">
                      Title <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      ref={autoFocusRef}
                      name="item"
                      value={values.item}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={values.item && !errors.item}
                      isInvalid={!(values.item && !errors.item) && touched.item}
                      placeholder="Enter action item"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.item && touched.item ? (
                        <small>{errors.item}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {dashboard && (
                    <Form.Group controlId="entity" className="mb-3">
                      <Form.Label>
                        Entity <RequiredAsterisk />
                      </Form.Label>
                      <EntityTypeahead
                        isValid={
                          (values?.entity?.length && !errors.entity) ||
                          values?.entity?.length
                        }
                        isInvalid={
                          !values?.entity?.length &&
                          errors?.entity &&
                          touched?.entity &&
                          !touched?.entity?.length
                        }
                        onChange={(entity) => {
                          setEntity(entity);
                          setFieldValue("entity", entity);
                          setFieldTouched("entity", true);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.entity &&
                        touched?.entity &&
                        !touched?.entity?.length ? (
                          <small>{errors?.entity}</small>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <ActionItemType taskableType={taskableType} />
                  {children}
                  <Form.Group controlId="item" className="mb-3">
                    <Form.Label className="mb-1">Description</Form.Label>
                    <div className="bg-light border rounded">
                      <SlateEditor
                        oldValue={description}
                        name="description"
                        setFieldValue={setFieldValue}
                        placeholder={"Enter Action Item Description"}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.description && touched.description ? (
                        <small>{errors.description}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="dueDate" className="mb-3">
                    <Form.Label className="mb-1">
                      Due Date <RequiredAsterisk />
                    </Form.Label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      type="date"
                      name="dueDate"
                      popperPlacement="top-start"
                      selected={dueDate}
                      onChange={(date) => {
                        setDueDate(date);
                        setFieldValue(
                          "dueDate",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }}
                      onBlur={handleBlur}
                      isValid={values.dueDate && !errors.dueDate}
                      isInvalid={
                        !(values.dueDate && !errors.dueDate) && touched.dueDate
                      }
                      maxDate={addYears(new Date(), 2)}
                      minDate={new Date()}
                      isClearable
                      placeholderText="Enter Due Date"
                      className="form-control"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dueDate && touched.dueDate ? (
                        <small>{errors.dueDate}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="assigned-to" className="mb-3">
                    <Form.Label className="mb-1">
                      Assigned To <RequiredAsterisk />
                    </Form.Label>
                    <Typeahead
                      id="subscribers-typeahead"
                      clearButton
                      placeholder="Select a user..."
                      isLoading={isExpanding}
                      disabled={dashboard && !entity?.length}
                      labelKey={(option) =>
                        `${option.firstName} ${option.lastName}`
                      }
                      isValid={
                        !!values.assignedTo.length &&
                        !errors.assignedTo &&
                        touched.assignedTo
                      }
                      isInvalid={
                        !(values.assignedTo && !errors.assignedTo) &&
                        touched.assignedTo
                      }
                      onChange={(assignedTo) => {
                        setFieldValue("assignedTo", assignedTo);
                        if (!assignedTo.length)
                          setSubscribers((prev) =>
                            uniqBy([...prev, ...members], "subscriberId")
                          );
                      }}
                      options={subscribers}
                      selected={values.assignedTo}
                      multiple
                      renderToken={(option, props) => {
                        return (
                          <Token
                            onRemove={() => {
                              setFieldValue(
                                "assignedTo",
                                values.assignedTo.filter(
                                  (a) => a.subscriberId !== option.subscriberId
                                )
                              );
                              setSubscribers((prev) =>
                                uniqBy([...prev, option], "subscriberId")
                              );
                            }}
                          >
                            {option?.firstName} {option?.lastName}
                          </Token>
                        );
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="tags" className="mb-3">
                    <Form.Label className="mb-1">Tags</Form.Label>
                    <Typeahead
                      id="tags-typeahead"
                      clearButton
                      placeholder="Select tags ..."
                      newSelectionPrefix="Add: "
                      isLoading={isSyncing}
                      labelKey={`name`}
                      isValid={
                        !!values.tags.length && !errors.tags && touched.tags
                      }
                      isInvalid={!(values.tags && !errors.tags) && touched.tags}
                      onChange={(tags) => {
                        setFieldValue(
                          "tags",
                          uniqBy(
                            tags.map((tag) => ({
                              ...tag,
                              name: tag?.name.toLowerCase(),
                            })),
                            "name"
                          )
                        );
                      }}
                      options={tags}
                      selected={values.tags}
                      multiple
                    />
                  </Form.Group>
                  {show && error && <ErrorHandler error={error} />}
                </Offcanvas.Body>
                <div className="sticky-bottom mt-5 p-3 bg-light text-end">
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    className="me-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={isLoading || !isValid}
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    <span>Create Action Item</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Offcanvas>
    </>
  );
};

ActionItemCreate.propTypes = {
  members: PropTypes.array,
  auditId: PropTypes.string,
  taskableId: PropTypes.string,
  taskableType: PropTypes.string,
  onActionItemCreated: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  showCanvas: PropTypes.bool,
  onCanvasHidden: PropTypes.func,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  facilityId: PropTypes.string,
};

export default ActionItemCreate;
