import React from "react";
import * as Sentry from "@sentry/react";
import { Container } from "react-bootstrap";
import { Logout } from "features/authentication";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const LogoutPage = () => {
  return (
    <Container className="my-2 MainContent">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "LogoutPage");
        }}
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      >
        <Logout />
      </Sentry.ErrorBoundary>
    </Container>
  );
};

export default LogoutPage;
