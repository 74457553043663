import * as Sentry from "@sentry/react";
import { Search } from "features/search";
import { Container } from "react-bootstrap";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const SearchPage = () => {
  return (
    <Container className="my-2 MainContent">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "SearchPage");
        }}
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      >
        <Search />
      </Sentry.ErrorBoundary>
    </Container>
  );
};

export default SearchPage;
