import moment from "moment";
import { groupBy, uniqBy } from "lodash";

import { FlexibleFields } from "features/assessment/constants";

const {
  CHECKBOX_FIELD,
  COMPOUND_RADIO,
  DATETIME_FIELD,
  NUMERIC_FIELD,
  RADIO_FIELD,
  TEXT_FIELD,
} = FlexibleFields;

/**
 *
 * @param {Array} responses
 * @param {*} flexibleQuestions
 * @returns {
 *  [flexibleQuestionId] : {
 *    "type" TEXT_FIELD | CHECKBOX_FIELD | RADIO_FIELD | ...
 *    "response" : "" | []
 *  }
 * }
 */

export function parseFlexQuestion(responses = []) {
  let q = {};
  const responsesGroupedById = groupBy(responses, "flexibleQuestionId");

  Object.keys(responsesGroupedById).forEach((flexibleQuestionId) => {
    const response = responsesGroupedById[flexibleQuestionId][0] || {};
    const questionTypeId = response?.flexibleQuestion?.questionTypeId;

    if (questionTypeId === TEXT_FIELD) {
      q[flexibleQuestionId] = {
        type: TEXT_FIELD,
        response: response?.notes,
      };
    }

    if (questionTypeId === NUMERIC_FIELD) {
      q[flexibleQuestionId] = {
        type: NUMERIC_FIELD,
        response: response?.notes,
      };
    }

    if (questionTypeId === DATETIME_FIELD) {
      const date = moment.utc(response?.responseDatetime);

      q[flexibleQuestionId] = {
        type: DATETIME_FIELD,
        response: date.isValid() ? date.format("YYYY-MM-DDTHH:mm") : "",
      };
    }

    if (questionTypeId === CHECKBOX_FIELD) {
      q[flexibleQuestionId] = {
        type: CHECKBOX_FIELD,
        response: uniqBy(
          responsesGroupedById[flexibleQuestionId],
          "choiceId"
        ).map(({ choiceId }) => choiceId),
      };
    }

    if (questionTypeId === RADIO_FIELD) {
      q[flexibleQuestionId] = {
        type: RADIO_FIELD,
        response: response?.choice?.id,
      };
    }

    if (questionTypeId === COMPOUND_RADIO) {
      q[flexibleQuestionId] = {
        type: COMPOUND_RADIO,
        response: response?.choice?.id,
      };
    }
  });

  return q;
}

export const responseMap = (formData = {}) => {
  const q = formData?.dynamic || {};
  return Object.keys(q)
    .map((key) => {
      const answer = q[key];
      const payload = {
        id: Date.now(),
        auditId: formData?.auditId,
        questionId: formData?.questionId, //required
        organizationId: formData?.organizationId,
        flexibleQuestionId: "", //required
        isInapplicable: false,
        notes: null,
        choiceId: null,
        choice: null,
        offline: true,
        syncedAt: moment().format(),
      };

      payload["flexibleQuestionId"] = key;
      payload["isInapplicable"] = Boolean(formData?.isInapplicable);

      // checkbox;
      if (Array.isArray(answer)) {
        return answer.map((choiceId) => {
          payload["choiceId"] = choiceId ?? null;
          payload["choice"] = null;
          return payload;
        });
      }
      //radio
      if (
        typeof answer === "object" &&
        !Array.isArray(answer) &&
        answer !== null
      ) {
        return Object.keys(answer).map((key) => {
          payload["choiceId"] = answer[key] ?? null;
          payload["choice"] = null;
          return payload;
        });
      }
      //text
      payload["notes"] = answer ?? null;
      return payload;
    })
    .flat();
};

export function parseCustomResponses(response) {
  if (!Array.isArray(response)) return null;

  if (
    response.filter(({ customAnswer }) => customAnswer.typeLabel === "number")
      .length
  )
    return {
      [response[0]?.customAnswerId]: response[0]?.response || "",
    };

  if (
    response.filter(({ customAnswer }) => customAnswer.typeLabel === "text")
      .length
  )
    return {
      [response[0]?.customAnswerId]: response[0]?.response || "",
    };

  if (
    response.filter(({ customAnswer }) => customAnswer.typeLabel === "datetime")
      .length
  )
    return {
      [response[0]?.customAnswerId]: response[0]?.responseDatetime
        ? moment.utc(response[0]?.responseDatetime).format("YYYY-MM-DDTHH:mm")
        : moment().format("YYYY-MM-DDTHH:mm"),
    };

  if (
    response.filter(({ customAnswer }) => customAnswer.typeLabel === "radio")
      .length
  )
    return {
      [response[0]?.customAnswerId]: response[0]?.customAnswer || {},
    };

  //checkbox
  if (
    response.filter(({ customAnswer }) => customAnswer.typeLabel === "checkbox")
      .length
  ) {
    return {
      [response[0].questionId]: response.map(
        ({ customAnswerId }) => customAnswerId
      ),
    };
  }

  return null;
}

export function flexibleFields({
  sectionId = "",
  sectionFormFields = [],
  flexibleQuestions = [],
}) {
  const sectionFields = sectionFormFields.map((fields) => {
    const found = fields.sectionsAvailability.find(
      ({ sectionId: id, available }) => {
        return id === sectionId && available;
      }
    );

    return {
      ...fields,
      visible: !!found,
    };
  });

  return flexibleQuestions.map((field) => {
    const { visible } =
      sectionFields.find(
        ({ flexibleQuestionId }) => flexibleQuestionId === field?.id
      ) || {};

    return {
      ...field,
      visible: !!visible,
    };
  });
}

export function showActionItem(protocol) {
  const defaults = {
    isFinding: false,
    isRecommendation: false,
    isFlexbleQuestion: false,
  };
  if (
    protocol.flexibleQuestions.find(
      ({ prompt }) =>
        prompt === "Implementation - List observations about this topic"
    )
  )
    return {
      ...defaults,
      isFlexbleQuestion: true,
    };

  if (protocol.questionOptions.find(({ id, value }) => id === 4 && value))
    return {
      ...defaults,
      isRecommendation: true,
    };

  if (protocol.questionOptions.find(({ id, value }) => id === 3 && value))
    return {
      ...defaults,
      isFinding: true,
    };
  return defaults;
}

export function createOrUpdate({
  audit = {},
  observation = {},
  updatedResponses = [],
}) {
  const { responses = [], observations = [] } = audit || {};

  const found = observations.find(({ questionId, auditId }) => {
    return (
      observation?.questionId === questionId && observation?.auditId === auditId
    );
  });

  const current = responses.filter(
    ({ auditId, questionId, flexibleQuestionId }) => {
      return !updatedResponses.find(
        (u) =>
          u?.auditId === auditId &&
          u?.questionId === questionId &&
          u?.flexibleQuestionId === flexibleQuestionId
      );
    }
  );

  if (found)
    return {
      ...audit,
      observations: observations.map((data) => {
        if (
          data.questionId === observation.questionId &&
          data.auditId === observation.auditId
        )
          return observation;
        return data;
      }),
      responses: [...current, ...updatedResponses],
    };

  return {
    ...audit,
    observations: [...audit?.observations, observation],
    responses: [...current, ...updatedResponses],
  };
}
