import { useContext, useRef, useState } from "react";

import * as yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import SignatureCanvas from "react-signature-canvas";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import { post } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";
import RequiredAsterisk from "components/ui/RequiredAsterisk";

import SignatureDisclaimer from "../SignatureDisclaimer";

const SignatureCreate = ({ disclaimer, onSignatureCreated }) => {
  const user = useContext(UserContext);
  const signatureRef = useRef();
  const { auditId } = useParams();

  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createSignature = (signature = {}) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = post(`audits/${auditId}/signatures`, signature);
    auditPromise.promise
      .then(({ data }) => {
        handleClose();
        onSignatureCreated(data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Name is too Short!")
      .max(100, "Name is too Long!")
      .required("Name is required"),
    title: yup
      .string()
      .min(2, "Title is too Short!")
      .max(100, "Title is too Long!")
      .required("Title is required"),

    base64DataString: yup
      .string()
      .min(10, "Signature is too Short!")
      .required("Signature is required"),

    signatureDate: yup.date().required("Date is required"),
  });

  return (
    <>
      <Button size="sm" variant="primary" onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        New Signature
      </Button>
      {show && disclaimer && (
        <SignatureDisclaimer open={show} disclaimer={disclaimer} />
      )}
      <Offcanvas
        scroll
        show={show}
        placement="end"
        onHide={handleClose}
        className="w-fixed-640"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>New Signature</Offcanvas.Title>
        </Offcanvas.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const formattedDatetime = moment
              .utc(values?.signatureDate)
              .format("YYYY-MM-DD HH:mm:ss");
            createSignature({
              name: values.name,
              title: values.title,
              date: formattedDatetime,
              base64DataString: values.base64DataString,
            });
          }}
          initialValues={{
            name: `${user?.de?.firstName} ${user?.de?.lastName}`,
            title: "",
            signatureDate: new Date(),
            base64DataString: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Body className="h-100vh">
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label>
                    Name <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isValid={values.name && !errors.name}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>
                    Title <small>(Job Position)</small> <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    isValid={values.title && !errors.title}
                    isInvalid={!!errors.title && touched.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title && touched.title ? (
                      <small>{errors.title}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="signatureDate" className="mb-3">
                  <Form.Label className="mb-1">
                    Date <RequiredAsterisk />
                  </Form.Label>
                  <ReactDatePicker
                    type="date"
                    name="signatureDate"
                    showTimeSelect
                    popperPlacement="top-start"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    selected={values.signatureDate}
                    onChange={(value) => {
                      setFieldValue("signatureDate", value);
                    }}
                    onBlur={handleBlur}
                    isValid={values.signatureDate && !errors.signatureDate}
                    isInvalid={
                      !(values.signatureDate && !errors.signatureDate) &&
                      touched.signatureDate
                    }
                    placeholderText="Enter Date"
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.signatureDate && touched.signatureDate ? (
                      <small>{errors.signatureDate}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="base64DataString" className="my-3 ">
                  <Form.Label className="mb-2 w-100">
                    Signature <RequiredAsterisk />
                    <Button
                      size="sm"
                      className="float-end py-0"
                      onClick={() => {
                        signatureRef.current.clear();
                        setFieldValue("base64DataString", "");
                      }}
                      variant="outline-primary"
                    >
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18"
                      >
                        close
                      </span>{" "}
                      Clear
                    </Button>
                  </Form.Label>
                  <div
                    style={{
                      height: 500,
                      "--bs-border-style": "dashed",
                      className: "border border-2 w-100 rounded",
                    }}
                  >
                    <SignatureCanvas
                      canvasProps={{
                        height: 500,
                        className: "border w-100 rounded",
                      }}
                      onEnd={() => {
                        setFieldValue(
                          "base64DataString",
                          signatureRef.current.toDataURL()
                        );

                        setFieldTouched("base64DataString", true, true);
                      }}
                      ref={signatureRef}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.base64DataString && touched.base64DataString ? (
                      <small>{errors.base64DataString}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
              </Offcanvas.Body>
              <div className="d-flex flex-row px-3 bg-light pb-5 pt-3 position-absolute bottom-0  w-100">
                <div className="flex-fill"></div>
                <div>
                  <Button
                    size="sm"
                    className="me-2"
                    onClick={handleClose}
                    variant="outline-primary"
                  >
                    Cancel
                  </Button>

                  <Button
                    size="sm"
                    type="submit"
                    disabled={
                      !values.name ||
                      !values?.title ||
                      !values?.signatureDate ||
                      isLoading
                    }
                  >
                    {isLoading && (
                      <Spinner
                        size="sm"
                        variant="light"
                        className="me-2"
                        animation="border"
                      />
                    )}{" "}
                    Save Signature
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

SignatureCreate.propTypes = {
  onSignatureCreated: PropTypes.func.isRequired,
};

export default SignatureCreate;
