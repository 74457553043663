import { useContext, useEffect, useRef, useState } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { Dropdown } from "react-bootstrap";
import { get, put } from "utils/DeApi";
import "./ActionItemNotificationDropdown.scss";
import { useToast } from "hooks";
import { ActionItemsDispatchContext } from "features/actionItems/context/ActionItemProvider";
import EmailNotificationSettings from "features/actionItems/notifications/emails/Settings";

const ActionItemNotificationDropdown = () => {
  const toast = useToast();
  const organization = useContext(OrganizationContext);
  const dispatch = useContext(ActionItemsDispatchContext);
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFieldToggled, setIsFieldToggled] = useState(false);
  const [notification, setNotification] = useState({});

  const updateNotification = (val, notificationType) => {
    setIsFieldToggled(true);
    if (notificationType === "all") {
      setNotification(() => ({
        actionItemCommentAlerts: val,
        actionItemStatusAlerts: val,
        all: val,
        actionItemCreateAlerts: val,
        actionItemOverDueAlerts: val,
      }));
    }
    dispatch({ type: "SET_ERROR", payload: "" });
    setIsLoading(true);

    if (notificationType === "actionItemCommentAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
          all:
            prevNotification.actionItemStatusAlerts &&
            prevNotification.actionItemCreateAlerts &&
            prevNotification.actionItemOverDueAlerts &&
            val,
        };
      });
    }
    if (notificationType === "actionItemCreateAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
          all:
            prevNotification.actionItemStatusAlerts &&
            prevNotification.actionItemCommentAlerts &&
            prevNotification.actionItemOverDueAlerts &&
            val,
        };
      });
    }

    if (notificationType === "actionItemStatusAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
          all:
            prevNotification.actionItemCommentAlerts &&
            prevNotification.actionItemCreateAlerts &&
            prevNotification.actionItemOverDueAlerts &&
            val,
        };
      });
    }

    if (notificationType === "actionItemOverDueAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
          all:
            prevNotification.actionItemCommentAlerts &&
            prevNotification.actionItemCreateAlerts &&
            prevNotification.actionItemStatusAlerts &&
            val,
        };
      });
    }

    if (notificationType === "actionItemEmailsAlert") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
        };
      });
    }

    if (notificationType === "actionItemDigestAlerts") {
      setNotification((prevNotification) => {
        return {
          ...prevNotification,
          [notificationType]: val,
        };
      });
    }
  };

  useEffect(() => {
    const fetchNotification = () => {
      dispatch({ type: "SET_ERROR", payload: "" });
      setIsLoading(true);
      const notificationPromise = get(
        `organizations/${organization?.id}/profile`
      );
      notificationPromise.promise
        .then(({ data }) => {
          const {
            actionItemCommentAlerts = true,
            actionItemEmailsAlert = true,
            actionItemStatusAlerts = true,
            actionItemCreateAlerts = true,
            actionItemOverDueAlerts = true,
            actionItemDigestAlerts = true,
          } = data || {};
          setNotification({
            actionItemCreateAlerts,
            actionItemCommentAlerts,
            actionItemEmailsAlert,
            actionItemStatusAlerts,
            actionItemOverDueAlerts,
            actionItemDigestAlerts,
            all:
              actionItemCreateAlerts &&
              actionItemCommentAlerts &&
              actionItemStatusAlerts &&
              actionItemOverDueAlerts,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && dispatch({ type: "SET_ERROR", payload: error });
          setIsLoading(false);
        });

      subscribedPromises.current.push(notificationPromise);
    };

    fetchNotification();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [dispatch, organization?.id]);

  useEffect(() => {
    if (isFieldToggled) {
      let notificationBody = {
        action_item_comment_alerts: notification.actionItemCommentAlerts,
        action_item_emails: notification.actionItemEmailsAlert,
        action_item_status_alerts: notification.actionItemStatusAlerts,
        action_item_create: notification.actionItemCreateAlerts,
        action_item_overdue_alerts: notification.actionItemOverDueAlerts,
        actionItemDigestAlerts: notification.actionItemDigestAlerts,
      };

      const notificationPromise = put(
        `organizations/${organization?.id}/profile`,
        notificationBody
      );
      notificationPromise.promise
        .then(({ data }) => {
          setIsLoading(false);
          toast.success(
            "Success",
            "Your notification preference has been updated"
          );
          const {
            actionItemCommentAlerts = true,
            actionItemEmailsAlert = true,
            actionItemStatusAlerts = true,
            actionItemCreateAlerts = true,
            actionItemOverDueAlerts = true,
            actionItemDigestAlerts = true,
          } = data || {};
          setNotification({
            actionItemCreateAlerts,
            actionItemCommentAlerts,
            actionItemEmailsAlert,
            actionItemStatusAlerts,
            actionItemOverDueAlerts,
            actionItemDigestAlerts,
            all:
              actionItemCreateAlerts &&
              actionItemCommentAlerts &&
              actionItemStatusAlerts &&
              actionItemOverDueAlerts,
          });
        })
        .catch((error) => {
          !error.isCanceled && dispatch({ type: "SET_ERROR", payload: error });
          setIsLoading(false);
        })
        .finally(() => {
          setIsFieldToggled(false);
        });

      subscribedPromises.current.push(notificationPromise);
      const promises = subscribedPromises.current;
      return () => {
        promises.forEach(function (promise) {
          promise.cancel();
        });
      };
    }
  }, [notification, isFieldToggled, organization?.id, toast, dispatch]);

  return (
    <>
      <Dropdown autoClose="outside" className="mx-2">
        <Dropdown.Toggle
          variant="outline-secondary"
          id="protocol-filter"
          className="w-100 text-start"
          size="sm"
        >
          <span translate="no" className="material-symbols-outlined md-18 me-2">
            notifications
          </span>
          Notification Settings
        </Dropdown.Toggle>
        <Dropdown.Menu className="notification-menu p-2">
          <EmailNotificationSettings
            settings={notification}
            handleSettingsChange={updateNotification}
            isLoading={isLoading}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ActionItemNotificationDropdown;
