import * as echarts from "echarts";
import {
  getRiskLevel,
  riskMap,
} from "features/assessment/components/Audit/helper";
import { colorMap } from "features/assessment/constants";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { compact, groupBy } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef } from "react";
import "./ReportRisk.scss";

const CHART_HEIGHT = 270;

const ReportRisk = ({
  tooltipTitle = "",
  setChartImage,
  isExportable = false,
  setReportRiskEmptyData = () => {},
}) => {
  const { audit } = useContext(AuditContext);
  const el = useRef(null);

  useEffect(() => {
    const observations = audit?.questions.flatMap(
      (question) => question.observations || []
    );

    const riskLevelMap = getRiskLevel(audit.protocol.riskLevels);

    const risk = groupBy(observations, (observation) => {
      if (observation.isInapplicable) return "";
      if (observation.risk === null) return "";
      return riskMap(observation.risk, riskLevelMap);
    });

    const isV2Protocol =
      audit.protocol.questionOptions.filter(({ id, name, value }) => {
        if (!value) return false;
        if (id === 3 && name === "Program") return true;
        if (
          id === 4 &&
          [
            "Implementation - List observations about this topic.",
            "Implementation - List observations about this topic",
          ].includes(name)
        )
          return true;
        if (
          id === 5 &&
          name ===
            "Training - Has training been completed in accordance with regulations and requirements?"
        )
          return true;
        return false;
      }).length === 3;

    const generateRiskFactor = (array) => {
      if (!array?.length) return 3;
      const arrayOfNumbers = array.map(
        ({ recommendation = "", notes = "", causalFactors = "" }) => {
          const _notes = notes?.length > 0 ? 1 : 0;
          const _causalFactors = causalFactors?.length > 0 ? 1 : 0;
          const _recommendation = recommendation?.length > 0 ? 1 : 0;
          return _notes + _causalFactors + _recommendation;
        }
      );

      return arrayOfNumbers.reduce((a, b) => a + b, 0);
    };

    delete risk[""];
    const mapped = Object.keys(risk).map((key) => {
      return {
        name: key,
        value: isV2Protocol ? generateRiskFactor(risk[key]) : risk[key].length,
      };
    });

    const data = riskLevelMap.map(({ value: name }) => {
      const found = mapped.find((m) => m.name === name);

      const color = Object.values(colorMap).find(({ label }) => {
        const _name = !!found ? found?.name : name;
        return _name.toLowerCase().includes(label?.toLowerCase());
      });

      return !!found
        ? { ...found, color: color?.color || "" }
        : { name: name, value: 0, color: color?.color || "" };
    });

    const chartColors = compact(data.map(({ color }) => color));
    const sum = data.reduce((prev, current) => prev + current.value, 0);

    setReportRiskEmptyData(sum);

    const dimension = {
      width: isExportable ? 800 : el.current.offsetWidth,
      height: isExportable ? 500 : CHART_HEIGHT,
    };

    const option = {
      title: {
        show: !isExportable,
        textStyle: {
          color: "grey",
          fontSize: 15,
          fontWeight: "lighter",
        },
        text: `Total: ${sum}`,
        left: "center",
        top: "bottom",
      },
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />{d0}%",
        confine: true,
      },
      ...(!isExportable
        ? {
            legend: {
              type: "scroll",
              orient: "horizontal",
              icon: "circle",
              textStyle: {
                fontSize: 14,
              },
            },
          }
        : {}),
      series: {
        name: tooltipTitle ?? "Risk Aggregation",
        type: "pie",
        radius: "50%",
        label: {
          show: true,
          ...(isExportable
            ? {
                alignTo: "none",
                fontSize: 14,
                color: "#000",
                lineHeight: 2,
                padding: 20,
              }
            : {}),
          formatter: "{b}:{c} ({d}%)",
        },
        percentPrecision: 1,
        data: data,
        color: data?.length === chartColors?.length ? chartColors : [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    };

    if (!sum) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
      option.series = null;
    }

    const chart = echarts.init(el.current, null, dimension);

    if (setChartImage) {
      chart.on("finished", () => {
        var img = new Image(800, 600);
        img.src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        setChartImage(img);
        chart.dispose();
      });
    }

    chart.setOption(option);

    function handleResize() {
      chart.resize(dimension);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, isExportable, audit, setChartImage, tooltipTitle]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
};

ReportRisk.propTypes = {
  audit: PropTypes.object.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
};

export default ReportRisk;
