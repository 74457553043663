import React, { useState, useEffect, useContext } from "react";

import "./Logout.scss";

import { logout as logoutBE } from "utils/BeeApi";
import { logout as logoutDE } from "utils/DeApi";

import Loader from "components/ui/Loader";
import { UserDispatchContext } from "contexts/UserProvider";
import { useAuth0 } from "@auth0/auth0-react";

const key = process.env.REACT_APP_LOCAL_STORAGE_KEY;

const Logout = () => {
  const setUser = useContext(UserDispatchContext);
  const { isAuthenticated, logout } = useAuth0();

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    setIsLoggingOut(true);
    logoutBE()
      .then(() => {
        return logoutDE();
      })
      .then(() => {})
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        if (isAuthenticated) {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }
        setUser(null);
        setIsLoggingOut(false);
        localStorage.removeItem(key);
      });
  }, [setUser]);

  if (isLoggingOut) return <Loader />;
  return <span />;
};

export default Logout;
