const { Modal, Button } = require("react-bootstrap");
const { destroy } = require("utils/DeApi");

const DeleteReportModal = ({
  selectedReport,
  fetchEmbedReport,
  showDeleteModal,
  setShowDeleteModal,
  subscribedPromises,
  handleUpdateData,
}) => {
  function deleteReportsObject() {
    const findingsPromise = destroy(`/embedded-reports/${selectedReport.id}`);
    findingsPromise.promise
      .then(async (data) => {
        await handleUpdateData(data, "delete");
        fetchEmbedReport();
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error(error);
      });
    subscribedPromises.current.push(findingsPromise);
  }
  return (
    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this report?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Cancel
        </Button>
        <Button size="sm" variant="danger" onClick={deleteReportsObject}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteReportModal;
