import React from "react";
import { Modal, Button, Table, Badge } from "react-bootstrap";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "features/activityLogs/utils/capitalizeFirstLetter";

const AuditTrailSubscriberPortfolio = ({ activityLog, handleClose }) => {
  const subscriberObject = activityLog?.subscriberPortfolio;
  const subscriberAction = activityLog?.description;

  return (
    <>
      <Modal.Body>
        <div className="d-flex flox-row">
          <div className="flex-fill">
            <h4>{activityLog.logLabel}</h4>
          </div>
          <div className="text-end">
            {subscriberAction === "created" && (
              <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
                CREATED
              </Badge>
            )}
            {subscriberAction === "updated" && (
              <Badge className="bg-warning rounded-0 py-2 px-3 bg-opacity-10 text-warning">
                UPDATED
              </Badge>
            )}
            {subscriberAction === "deleted" && (
              <Badge className="bg-danger rounded-0 py-2 px-3 bg-opacity-10 text-danger">
                DELETED
              </Badge>
            )}
            <div className="mt-1">
              <small>
                {new Date(activityLog?.createdAt).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </small>
            </div>
          </div>
        </div>
        <div>
          <span>
            {activityLog?.causer?.firstName ?? "-"}{" "}
            {activityLog?.causer?.lastName ?? "-"}
          </span>
          <br />
          <small className="text-muted">
            {activityLog?.causer?.email ?? "-"}
          </small>
        </div>
        <>
          <hr />
          <Table striped borderless>
            <thead>
              <tr>
                <th>Name</th>
                <th>Portfolio</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {activityLog && (
                <tr>
                  <td>
                    {subscriberObject.subscriber.firstName}{" "}
                    {subscriberObject.subscriber.lastName}
                  </td>
                  <td>{subscriberObject.portfolio.name}</td>
                  <td>{capitalizeFirstLetter(subscriberAction)}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
};

AuditTrailSubscriberPortfolio.propTypes = {
  activityLog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AuditTrailSubscriberPortfolio;
