import { Fragment, useContext, useEffect, useRef, useState } from "react";

import moment from "moment";
import { groupBy } from "lodash";
import DOMPurify from "dompurify";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Image,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";

import { fileConvert } from "utils/BeeApi";
import { useVertical } from "features/assessment/hooks";
import { isNotUndefined, stripHTML } from "utils/StringUtils";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import AuditCSVExport from "./AuditCSVExport";
import AuditWordExport from "./AuditWordExport";
import ReportRisk from "../../common/ReportRisk";
import ManuscriptEdit from "./Manuscript/ManuscriptEdit";
import ReportCompliance from "../../common/ReportCompliance";
import SummaryTables from "./AuditReportExport/Summary Tables";
import LegalDisclaimer from "./LegalDisclaimer/LegalDisclaimer";
import ManuscriptComments from "./Manuscript/ManuscriptComments";
import ManuscriptEditHistory from "./Manuscript/ManuscriptEditHistory/ManuscriptEditHistory";
import ReportHideResponseFields from "./ReportHideResponseFields";
import ReportRiskPrinciple from "../../common/ReportRiskPrinciple";
import { parseFlexQuestion } from "../AuditDetails/Question/helper";
import ReportConformityLevel from "../../common/ReportConformityLevel";
import AuditWordExportRaw from "./AuditWordExportRaw/AuditWordExportRaw";
import RedirectToVertical from "./RedirectToVertical/RedirectToVertical";
import ExportWrapper from "./AuditReportExport/ExportWrapper/ExportWrapper";
import ReportRadioField from "../../common/ReportRadioField/ReportRadioField";
import ReportConformityPrinciple from "../../common/ReportConformityPrinciple";
import InherentRisk from "../AuditDetails/AuditReport/InherentRisk/InherentRisk";
import AuditExecutiveSummary from "./AuditExecutiveSummary/AuditExecutiveSummary";
import ReportAggregatedConformityLevel from "../../common/ReportAggregatedConformityLevel";
import LatestManuscriptComments from "./Manuscript/ManuscriptComments/LatestManuscriptComments";
import { choiceColorMap } from "../AuditDetails/Question/FlexibleQuestions/FieldCompound/helper";
import AssessmentLevelFiles from "./AuditReportExport/AssessmentLevelFiles/AssessmentLevelFiles";
import {
  CHECKBOX_FIELD,
  DATETIME_FIELD,
  NUMERIC_FIELD,
  RADIO_FIELD,
  TEXT_FIELD,
} from "../AuditDetails/AuditMetadata/constants";
import {
  showCasualFactor,
  showCompliance,
  showConformityLevel,
  showCorrectiveAction,
  showNotes,
  showRecommendation,
  showRisk,
} from "../helper";

import "./Report.scss";

import AuditInfo from "../../common/AuditInfo";
import { useRoles } from "hooks";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import ManuscriptSectionSummary from "./Manuscript/ManuscriptSectionSummary";
import ErrorHandler from "components/ui/ErrorHandler";

const TYPE_FINDING = 0;
const TYPE_BEST_PRACTICES = 1;

const hideForClientWorks = (protocolId) => {
  // Contractor Safety Audit (USA) NEW - 64ef9dd38695e
  // Contractor Safety Audit (UK) NEW - 64f78de719879
  // Contractor Safety Audit (Germany) NEW - 64f795d8407c0
  // Contractor Safety Audit (USA) (Retired) - 6494b8fed7a9f
  // Contractor Safety Audit (UK) (Retired) - 64c1319579b81
  // Contractor Safety Audit (Germany) (Retired) - 64c911a935e8b
  return [
    "64ef9dd38695e",
    "64f78de719879",
    "64f795d8407c0",
    "6494b8fed7a9f",
    "64c1319579b81",
    "64c911a935e8b",
  ].includes(protocolId);
};

const hideConformityChart = (protocolId) => {
  // Contractor Safety Audit (USA) NEW - 64ef9dd38695e
  // Contractor Safety Audit (UK) NEW - 64f78de719879
  // Contractor Safety Audit (Germany) NEW - 64f795d8407c0
  return ["64ef9dd38695e", "64f78de719879", "64f795d8407c0"].includes(
    protocolId
  );
};

const hideBarCharts = (protocolId) => {
  // Contractor Safety Audit (USA) (Retired) - 6494b8fed7a9f
  // Contractor Safety Audit (UK) (Retired) - 64c1319579b81
  // Contractor Safety Audit (Germany) (Retired) - 64c911a935e8b
  return ["6494b8fed7a9f", "64c1319579b81", "64c911a935e8b"].includes(
    protocolId
  );
};

const Report = () => {
  const { isAdmin } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);
  const {
    defaultViewInReport,
    hideAssessmentLevelAttachment,
    hideAttachmentDescAndName: hideERMTitles,
    generatedFileNameShouldOnlyHaveAssessmentName,
  } = useVertical();
  const [riskPieChart, setRiskPieChart] = useState();
  const [conformityPieChart, setConformityPieChart] = useState();
  const [compliancePieChart, setCompliancePieChart] = useState();
  const [dynamicPieChart, setDynamicPieChart] = useState([]);
  const [chartsToBeToggledForExports, setChartsToBeToggledForExports] =
    useState([]);
  const [combinedConformityPieChart, setCombinedConformityPieChart] =
    useState();

  const [riskPrincipleBarChart, setRiskPrincipleBarChart] = useState();
  const [conformityPrincipleBarChart, setConformityPrincipleBarChart] =
    useState();

  const [showCharts, setShowCharts] = useState(false);
  const [executiveSummary, setExecutiveSummary] = useState(false);
  const [showMetadata, setShowMetaData] = useState(false);
  const [summaryTable, setSummaryTable] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showCitations, setShowCitations] = useState(false);
  const [showInapplicable, setShowInapplicable] = useState(false);
  const [showRiskMatrices, setShowRiskMatrices] = useState(false);
  const [view, setView] = useState(defaultViewInReport);
  const [showSignatories, setShowSignatories] = useState(false);
  const [showLegalDisclaimer, setShowLegalDisclaimer] = useState(false);
  const [showEmptyFlexibleQuestions, setShowEmptyFlexibleQuestions] =
    useState(false);

  const [visibleQuestions, setVisibleQuestions] = useState({});
  const [visibleObservationQuestions, setVisibleObservationQuestions] =
    useState({
      Risk: true,
      Notes: true,
      Compliance: true,
      "Causal Factors": true,
      Recommendations: true,
      Conformity: true,
      "Corrective Action": true,
    });

  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [error, setError] = useState();
  const organization = useContext(OrganizationContext);

  const questions = audit?.questions;
  const observations = questions.flatMap(
    (question) => question.observations || []
  );
  const responses = observations.flatMap(
    (observation) => observation.responses
  );

  const handleToggleQuestionVisibility = (questionId) => {
    setVisibleQuestions((prev) => ({
      ...prev,
      [questionId]: !prev[questionId],
    }));
  };

  const handleToggleObservationQuestionVisibility = (questionType) => {
    setVisibleObservationQuestions((prev) => ({
      ...prev,
      [questionType]: !prev[questionType],
    }));
  };

  useEffect(() => {
    const initialVisibleQuestions = {};
    audit.protocol.flexibleQuestions.forEach((question) => {
      initialVisibleQuestions[question.id] = true;
    });
    setVisibleQuestions(initialVisibleQuestions);
  }, []);

  const metadataMapping = () => {
    const metadata = audit?.metadata;
    if (!Array.isArray(metadata) || !metadata?.length) return {};

    const groupByMetaId = groupBy(metadata, "auditMetadata.prompt");

    return Object.keys(groupByMetaId).reduce((accumulator, key) => {
      if (!Array.isArray(groupByMetaId[key]) && !groupByMetaId[key].length)
        return accumulator;

      const response = groupByMetaId[key][0];
      const type = response?.auditMetadata?.type;

      if (type === TEXT_FIELD)
        return {
          ...accumulator,
          [key]: {
            type: TEXT_FIELD,
            response: response?.responseText,
          },
        };
      if (type === DATETIME_FIELD)
        return {
          ...accumulator,
          [key]: {
            type: DATETIME_FIELD,
            response: response?.responseDatetime
              ? moment(response?.responseDatetime).format("ll")
              : "",
            ...response,
          },
        };
      if (type === NUMERIC_FIELD)
        return {
          ...accumulator,
          [key]: {
            type: NUMERIC_FIELD,
            response: response?.responseNumeric,
          },
        };
      if (type === RADIO_FIELD)
        return {
          ...accumulator,
          [key]: {
            type: RADIO_FIELD,
            response: response?.auditMetadataChoice?.choice,
          },
        };

      if (type === CHECKBOX_FIELD) {
        return {
          ...accumulator,
          [key]: {
            type: CHECKBOX_FIELD,
            response:
              groupByMetaId[key].map(
                ({ auditMetadataChoice }) => auditMetadataChoice?.choice
              ) || [],
          },
        };
      }
      return accumulator;
    }, {});
  };
  var formattedDate =
    new Intl.DateTimeFormat("en-US", { month: "short" }).format(new Date()) +
    " " +
    new Date().getDate() +
    " " +
    new Date().getFullYear();
  var formattedTime = new Intl.DateTimeFormat("en-US", {
    timeStyle: "short",
  }).format(new Date());

  const handleAuditUpdated = (data) => {
    setAudit((prevAudit) => ({
      ...prevAudit,
      ...data,
    }));
  };

  const toPrintRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => toPrintRef.current,
  });

  const handlePDFDownload = useReactToPrint({
    content: () => toPrintRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      setDownloadingPDF(true);
      if (document) {
        var base = document.createElement("base");
        base.href = window.location.origin;
        document.getElementsByTagName("head")[0].appendChild(base);

        var head = document.getElementsByTagName("head")[0];
        var links = head.getElementsByTagName("link");
        for (var link in links) {
          if (links.hasOwnProperty(link)) {
            var l = links[link];
            if (l.rel === "stylesheet") {
              l.href = "" + l.href;
            }
          }
        }

        const html = document.getElementsByTagName("html")[0];
        const htmlFile = new Blob([html.outerHTML], {
          type: "text/html;charset=UTF-8",
        });
        downloadAudit(htmlFile);
      } else {
        console.warn("Printing Error");
        setDownloadingPDF(false);
      }
    },
  });

  const downloadAudit = (htmlFile) => {
    const formData = new FormData();

    formData.append("html_file", htmlFile);
    formData.append("file_name", "audit");
    formData.append(
      "file_name",
      `${organization.name} ${audit.name} Assessment Report`
    );
    formData.append(
      "header_html",
      `<div style="width: 100%; visibility:hidden; margin-bottom: 2px; border-top: 1px solid seagreen; display:flex;flex-direction:row;justify-content:space-between"><p style="text-align:left; font-size: 7px; color: green;margin-left:2px">${audit.name}</p><p style="text-align:right; font-size: 7px; color: green;margin-right:2px">${formattedTime}</p></div>`
    );
    formData.append(
      "footer_html",
      `<div style="width: 100%;margin-top: 8px; visibility:hidden; border-bottom: 1px solid seagreen; display:flex;flex-direction:row;justify-content:space-between"><p style="text-align:left; font-size: 7px; color: green;margin-left:2px">${organization.name}</p><p style="text-align:right; font-size: 7px; color: green;margin-right:2px">${formattedDate}</p></div>`
    );
    formData.append("header_and_footer", 1);
    const auditPromise = fileConvert(`/services/html-to-pdf`, formData);
    auditPromise.promise
      .then((response) => {
        const pdfFile = new Blob([response.data], {
          type: "text/html;charset=UTF-8",
        });
        const filename = generatedFileNameShouldOnlyHaveAssessmentName
          ? `${audit.name}.pdf`
          : `${organization.name} ${audit.name} Assessment Report.pdf`;
        saveAs(pdfFile, filename);
        setDownloadingPDF(false);
      })
      .catch((error) => {
        setDownloadingPDF(false);
        setError(error);
        console.warn(error);
      });
  };

  function formatAndCleanExecutiveSummaryContent() {
    return audit?.executiveSummary
      ?.replace(/<p>(\s|&nbsp;)*?<\/p>/g, "<p>&#xFEFF;</p>") // Replace empty <p> with <p>&#xFEFF;</p>
      ?.replace(/\n/g, "<br />") // Replace newlines with <br />
      ?.replace(/<p>(.*?)<\/p>/g, (match, p1) => {
        // Replace empty tags inside <p></p> with &#xFEFF;
        return p1.trim() === ""
          ? "<p>&#xFEFF;</p>"
          : match.replace(/<(\w+)><\/\1>/g, "<$1>&#xFEFF;</$1>");
      });
  }

  let {
    protocolId,
    conformity,
    riskLevels,
    questionOptions,
    flexibleQuestions,
  } = audit?.protocol || {};
  const [riskLabel, complianceLabel, conformityLabel] = questionOptions || [];

  const _showRisk = showRisk(conformity, questionOptions);
  const _showNotes = showNotes(conformity, questionOptions);
  const _showCompliance = showCompliance(conformity, questionOptions);

  const _showCasualFactor = showCasualFactor(conformity, questionOptions);
  const _showRecommendation = showRecommendation(conformity, questionOptions);
  const _showConformityLevel = showConformityLevel(conformity, questionOptions);
  const _showCorrectiveAction = showCorrectiveAction(
    conformity,
    questionOptions
  );
  const isInherentRisk = flexibleQuestions?.filter((field) =>
    field.questionType.includes("Scored Field")
  );
  const dynamicFields = parseFlexQuestion(responses);

  const doesSummaryTableHaveEitherFindingOrBestPractices = !!flexibleQuestions
    ?.flatMap(({ choices = [] }) => choices)
    .filter(({ type }) => type === TYPE_FINDING || type === TYPE_BEST_PRACTICES)
    .length;

  const _showCustomResponses = !!flexibleQuestions
    .map(({ id }) =>
      responses?.find(
        ({ flexibleQuestionId, choice }) => flexibleQuestionId === id && choice
      )
    )
    .filter(Boolean).length;

  const handleChartsToBeToggledForExports = (field = {}) => {
    setDynamicPieChart((prevDynamicPieChart) =>
      prevDynamicPieChart.filter(({ id = "" }) => id !== field?.id)
    );

    setChartsToBeToggledForExports((prevChartsToBeToggledForExports) => [
      ...prevChartsToBeToggledForExports,
      field,
    ]);
  };

  function shouldShowHeatmapToggle(audit) {
    if (!audit?.protocol?.flexibleQuestions) {
      return false;
    }
    return audit.protocol.flexibleQuestions.some((question) => {
      return (
        question?.properties?.fieldType === "compound" &&
        question?.children?.length > 0
      );
    });
  }

  const handleResetChartsToBeToggledForExports = () => {
    setDynamicPieChart((prevDynamicPieChart) => {
      const updateDynamicPieChart = [
        ...prevDynamicPieChart,
        ...chartsToBeToggledForExports,
      ]
        .filter(
          (elem, index, self) =>
            self.findIndex((_elem) => _elem === elem) === index
        )
        .sort((a, b) => a?.order - b?.order);

      return [...updateDynamicPieChart];
    });

    setChartsToBeToggledForExports((prev) => []);
  };
  const _hideBarCharts = hideBarCharts(protocolId);
  const _hideForClientWorks = hideForClientWorks(protocolId);
  const _hideConformityChart = hideConformityChart(protocolId);
  const data = {
    charts: [
      riskPieChart?.currentSrc && {
        title: `Distribution of Findings by ${
          riskLabel?.name || `Risk Rating`
        }`,
        image_string: riskPieChart?.currentSrc,
        type: "pie",
      },
      compliancePieChart?.currentSrc && {
        title: `Distribution of Findings by ${
          complianceLabel?.name || "Compliance"
        }`,
        image_string: compliancePieChart?.currentSrc,
        type: "pie",
      },
      conformityPieChart?.currentSrc && {
        title: `Distribution of Findings by
            ${conformityLabel?.name || "Conformity Level"}`,
        image_string: conformityPieChart?.currentSrc,
        type: "pie",
      },
      riskPrincipleBarChart?.src && {
        title: `Distribution of Findings Across Principles and
            ${riskLabel?.name || "Risk Rating"}`,
        image_string: riskPrincipleBarChart?.src,
        type: "bar",
      },
      conformityPrincipleBarChart?.currentSrc && {
        title: `Distribution of Findings across Principles and ${
          conformityLabel?.name || "Conformity Level"
        }`,
        image_string: conformityPrincipleBarChart?.currentSrc,
        type: "bar",
      },
      ...(Array.isArray(dynamicPieChart)
        ? dynamicPieChart.map(({ title, chart }) => {
            return {
              title: title,
              image_string: chart?.currentSrc,
              type: "pie",
            };
          })
        : []),
      combinedConformityPieChart?.currentSrc && {
        title: `Distribution of Findings by Conformity Level`,
        image_string: combinedConformityPieChart?.currentSrc,
        type: "pie",
      },
    ].filter(Boolean),
  };

  if (audit?.confidential && !isAdmin)
    return (
      <EmptyStateHandler
        title="Confidential Assessment"
        description="The report page is only accessible to Owners and Admins."
      />
    );

  return (
    <Row>
      <Col xs={12} sm={12} md={4} lg={3} className="scroller mb-3">
        <AuditInfo />
        <div className="d-flex flex-column gap-2">
          <Button
            variant="primary"
            size="sm"
            as={Link}
            to={`/audits/${audit.auditId}`}
          >
            <span
              translate="no"
              className="material-symbols-outlined md-18 me-2"
            >
              west
            </span>
            Assessment{" "}
          </Button>
          <AuditExecutiveSummary onAuditUpdated={handleAuditUpdated} />
        </div>
        <hr />
        <div className="d-flex flex-column gap-2">
          <Button
            className="btn-sm bg-primary bg-opacity-10 text-dark border-0 px-3"
            onClick={handlePrint}
          >
            Print
          </Button>
          <Button
            className="btn-sm bg-primary bg-opacity-10 text-dark border-0 px-3"
            onClick={handlePDFDownload}
            disabled={downloadingPDF}
          >
            {downloadingPDF && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}{" "}
            PDF
          </Button>
          {error && <ErrorHandler error={error} />}

          <AuditWordExport
            charts={data}
            title={audit?.name}
            auditId={audit.auditId}
            viewCharts={showCharts}
            summaryTable={summaryTable}
            showMetadata={showMetadata}
            showCitation={showCitations}
            showAttachments={showAttachments}
            showSignatories={showSignatories}
            showInapplicable={showInapplicable}
            showExecutiveSummary={executiveSummary}
            toPrintRef={toPrintRef}
            organization={organization}
          />
        </div>
        <hr />

        <Form.Switch
          className="mt-3 mx-3"
          id="show-executive-summary"
          checked={executiveSummary}
          value={true}
          onChange={() => setExecutiveSummary(!executiveSummary)}
          type="switch"
          name="show-executive-summary"
          label={<span className="text-muted">Show Executive Summary</span>}
        />

        {audit?.protocol?.assessmentLevelFields?.length ? (
          <Form.Switch
            className={"mt-3 mx-3"}
            id="show-scope-screening-meta-data"
            checked={showMetadata}
            value={true}
            onChange={() => setShowMetaData(!showMetadata)}
            type="switch"
            name="show-scope-screening-meta-data"
            label={
              <span className="text-muted">
                Show Scope, Screening, Metadata
              </span>
            }
          />
        ) : null}

        {doesSummaryTableHaveEitherFindingOrBestPractices && (
          <Form.Switch
            className={"mt-3 mx-3"}
            id="show-summary-table"
            checked={summaryTable}
            value={true}
            onChange={() => setSummaryTable(!summaryTable)}
            type="switch"
            name="show-summary-table"
            label={<span className="text-muted">Show Summary Table</span>}
          />
        )}

        <div className="d-flex justify-content-between align-items-baseline">
          <Form.Switch
            className={showCharts ? " mx-3" : "mt-3 mx-3"}
            id="show-charts"
            checked={showCharts}
            value={true}
            onChange={() => setShowCharts(!showCharts)}
            type="switch"
            name="show-charts"
            label={<span className="text-muted">Show Charts</span>}
          />

          {showCharts && (
            <Button
              variant="outline-secondary"
              className={
                "d-flex justify-content-center align-items-center  border-secondary border-opacity-50  px-1 py-0 mt-3 me-2 "
              }
              size="sm"
              onClick={handleResetChartsToBeToggledForExports}
            >
              <span className="">Reset</span>
            </Button>
          )}
        </div>

        <Form.Switch
          className="my-3 mx-3"
          id="show-citations"
          checked={showCitations}
          value={true}
          onChange={() => setShowCitations(!showCitations)}
          type="switch"
          name="show-citations"
          label={<span className="text-muted">Show Citations</span>}
        />
        <Form.Switch
          className="my-3 mx-3"
          id="show-inapplicable"
          checked={showInapplicable}
          value={true}
          onChange={() => setShowInapplicable(!showInapplicable)}
          type="switch"
          name="show-inapplicable"
          label={
            <span className="text-muted">Show Inapplicable Questions</span>
          }
        />
        {!!observations.length && (
          <Form.Switch
            className="my-3 mx-3"
            id="show-empty-flexible-questions"
            checked={showEmptyFlexibleQuestions}
            value={true}
            onChange={() =>
              setShowEmptyFlexibleQuestions(!showEmptyFlexibleQuestions)
            }
            type="switch"
            name="show-empty-flexible-questions"
            label={<span className="text-muted">Show Uncompleted Fields</span>}
          />
        )}
        <Form.Switch
          className="my-3 mx-3"
          id="show-attachments"
          checked={showAttachments}
          value={true}
          onChange={() => setShowAttachments(!showAttachments)}
          type="switch"
          name="show-attachments"
          label={<span className="text-muted">Show Attachments</span>}
        />
        {audit?.protocol?.hasSignatories ? (
          <Form.Switch
            className="my-3 mx-3"
            id="show-signatures"
            checked={showSignatories}
            value={true}
            onChange={() => setShowSignatories(!showSignatories)}
            type="switch"
            name="show-signatures"
            label={<span className="text-muted">Show Signatures</span>}
          />
        ) : null}
        <Form.Switch
          className="my-3 mx-3"
          id="show-legal-disclaimer"
          checked={showLegalDisclaimer}
          value={true}
          onChange={() => setShowLegalDisclaimer(!showLegalDisclaimer)}
          type="switch"
          name="show-legal-disclaimer"
          label={<span className="text-muted">Show Legal Disclaimer</span>}
        />
        {shouldShowHeatmapToggle(audit) && (
          <Form.Switch
            className="my-3 mx-3"
            id="show-risk-matrices"
            checked={showRiskMatrices}
            value={true}
            onChange={() => setShowRiskMatrices(!showRiskMatrices)}
            type="switch"
            name="show-risk-matrices"
            label={<span className="text-muted">Show Risk Matrices</span>}
          />
        )}
        <hr />
        <div className="d-flex flex-column mb-2">
          <AuditCSVExport audit={audit} />
        </div>
        <div className="d-flex flex-column mb-2">
          <AuditWordExportRaw
            auditId={audit.auditId}
            name={audit.name}
            organization={organization.name}
            charts={data}
            showAttachments={showAttachments}
            showCitation={showCitations}
            showExecutiveSummary={executiveSummary}
            showInapplicable={showInapplicable}
            showSummary={summaryTable}
            showCharts={showCharts}
          />
        </div>

        <p className="text-muted">
          <small>*Note : Toggles do not apply to the Raw CSV.</small>
        </p>
      </Col>

      <Col xs={12} sm={12} md={8} lg={9}>
        <Row>
          <Col xs={12} md={12} lg={8}>
            <Row>
              <Col xs={12} sm={12} md={10} lg={10}>
                <h4>Report</h4>
              </Col>
              <Col xs={12} sm={12} md={2} lg={2}>
                <div className="d-flex justify-content-md-end mb-3">
                  <ReportHideResponseFields
                    visibleQuestions={visibleQuestions}
                    visibleObservationQuestions={visibleObservationQuestions}
                    handleToggleQuestionVisibility={
                      handleToggleQuestionVisibility
                    }
                    handleToggleObservationQuestionVisibility={
                      handleToggleObservationQuestionVisibility
                    }
                  />
                  <ButtonGroup size="sm">
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="list-tooltip">
                          Responses in List Layout
                        </Tooltip>
                      }
                    >
                      <Button
                        className="px-2"
                        variant="outline-secondary"
                        active={view === "list"}
                        onClick={() => setView("list")}
                        style={{ boxShadow: "none" }}
                      >
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18"
                        >
                          list
                        </span>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="list-tooltip">
                          Responses in Grid Layout
                        </Tooltip>
                      }
                    >
                      <Button
                        className="px-2"
                        variant="outline-secondary"
                        active={view === "grid"}
                        onClick={() => setView("grid")}
                        style={{ boxShadow: "none" }}
                      >
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18"
                        >
                          grid_view
                        </span>
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </div>
              </Col>
            </Row>

            <Card>
              <Card.Body>
                <div ref={toPrintRef} className="p-3 report-module">
                  <RedirectToVertical />
                  <div className="py-5 text-center">
                    <div className="my-5 py-3 d-flex align-items-center justify-content-center">
                      <div>
                        <img
                          className="img-responsive my-5"
                          src="https://www.ermassess.com/Assess_Logo_Green.png"
                          width="350px"
                          alt="Logo"
                        />{" "}
                      </div>
                    </div>

                    <h3>{organization.name}</h3>
                    <h5>{audit?.facility?.name}</h5>
                    <address className="text-muted">
                      {audit?.facility?.address}
                    </address>
                    <hr className="my-5" />
                    <h5>{audit?.protocol?.name}</h5>
                    <h1>
                      <big>{audit?.name}</big>
                    </h1>
                  </div>

                  <div className="page-break"></div>
                  <div className="d-print-none mt-5"></div>
                  {executiveSummary && (
                    <>
                      <h2 className="py-1 text-uppercase text-primary border-bottom border-primary">
                        Executive Summary
                      </h2>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            formatAndCleanExecutiveSummaryContent()
                          ),
                        }}
                        className="executive-summary"
                      />

                      <div className="page-break-before"></div>
                      <div className="d-print-none mt-5"></div>
                    </>
                  )}
                  {showMetadata &&
                  audit?.protocol?.assessmentLevelFields?.length ? (
                    <>
                      <h2 className="mb-0 text-uppercase text-primary border-bottom border-primary">
                        Scope, Screening & Metadata{" "}
                      </h2>
                      <Table size="sm" bordered>
                        <thead className="border-0">
                          <tr className="border-0 text-white">
                            {/* <!-- add repeated tfoot for extra space --> */}
                            {/* <td className="border-0" colSpan={100}>
                              &nbsp;
                            </td> */}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(metadataMapping()).map((key) => (
                            <tr>
                              <td className="fw-bold">{key}</td>
                              <td>
                                {metadataMapping()[key].type === CHECKBOX_FIELD
                                  ? metadataMapping()[key]?.response?.map(
                                      (choice, index, array) => (
                                        <span className="me-2">
                                          {`${choice} ${
                                            array.length !== index + 1
                                              ? ","
                                              : ""
                                          }`}
                                        </span>
                                      )
                                    )
                                  : metadataMapping()[key].type === RADIO_FIELD
                                  ? metadataMapping()[key].response
                                  : metadataMapping()[key].response}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="page-break-before "></div>
                      <div className="d-print-none mt-5"></div>
                    </>
                  ) : null}
                  {summaryTable && (
                    <>
                      <SummaryTables
                        riskLabel={riskLabel}
                        showInapplicable={showInapplicable}
                        complianceLabel={complianceLabel}
                      />
                    </>
                  )}
                  {summaryTable && (
                    <>
                      <div className="page-break-before "></div>
                      <div className="d-print-none mt-5"></div>
                    </>
                  )}

                  {showCharts && (
                    <>
                      <h2 className="my-1 py-1 text-uppercase text-primary border-bottom border-primary">
                        Charts
                      </h2>
                      {/* Risk charts */}
                      {riskPieChart?.src && (
                        <div className="no-page-break py-1">
                          <div className="p-3 my-3 border text-center">
                            <p>
                              Distribution of Findings by{" "}
                              {riskLabel?.name || "Risk Rating"}
                            </p>
                            <div className="pie-chart-container">
                              <img
                                src={riskPieChart.src}
                                className="img-fluid pie-chart-image"
                                alt={`Distribution of Findings by ${
                                  riskLabel?.name || "Risk Rating"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {riskPrincipleBarChart?.src && (
                        <div className="no-page-break">
                          <div className="p-3 my-3 border text-center">
                            <p>
                              Distribution of Findings Across Principles and{" "}
                              {riskLabel?.name || "Risk Rating"}
                            </p>
                            <div className="bar-chart-container">
                              <img
                                src={riskPrincipleBarChart?.src}
                                className="img-fluid bar-chart-image"
                                alt={`Distribution of Findings Across Principles and ${
                                  riskLabel?.name || "Risk Rating"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Conformity Level */}
                      {conformityPieChart?.src && (
                        <div className="no-page-break">
                          <div className="p-3 my-3 border text-center">
                            <p>
                              Distribution of Findings by{" "}
                              {conformityLabel?.name || "Conformity Level"}
                            </p>
                            <div className="pie-chart-container">
                              <img
                                src={conformityPieChart?.src}
                                className="pie-chart-image img-fluid"
                                alt={`Distribution of Findings by ${
                                  conformityLabel?.name || "Conformity Level"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {combinedConformityPieChart?.src && (
                        <div className="no-page-break">
                          <div className="p-3 my-3 border text-center">
                            <p>Distribution of Findings </p>
                            <div className="pie-chart-container">
                              <img
                                src={combinedConformityPieChart?.src}
                                className="pie-chart-image img-fluid"
                                alt={`Distribution of Findings by`}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {Array.isArray(dynamicPieChart) &&
                        dynamicPieChart.map((_dynamicPieChart) => {
                          const { id, title, chart } = _dynamicPieChart;
                          if (
                            title
                              .toLowerCase()
                              .includes(
                                "distribution of findings by applicable to other client work"
                              ) &&
                            _hideForClientWorks
                          )
                            return <Fragment key={id} />;

                          if (
                            title.toLowerCase().includes("conformity level") &&
                            _hideConformityChart
                          )
                            return <Fragment key={id} />;
                          //console.log(dynamicPieChart);
                          return (
                            <div className="no-page-break" key={id}>
                              <div className="p-3 my-3 border text-center position-relative">
                                <p className="w-75 mx-auto">{title}</p>
                                <Button
                                  variant="outline-secondary"
                                  className=" d-print-none d-flex justify-content-center align-items-center position-absolute top-0 end-0 border-secondary border-opacity-50  px-1 py-0 mt-3 me-2 "
                                  size="sm"
                                  onClick={() =>
                                    handleChartsToBeToggledForExports(
                                      _dynamicPieChart
                                    )
                                  }
                                >
                                  <span className="">Hide</span>
                                </Button>
                                <div className="pie-chart-container ">
                                  <img
                                    src={chart.src}
                                    className="img-fluid pie-chart-image"
                                    alt={title}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {conformityPrincipleBarChart?.src && (
                        <div className="no-page-break">
                          <div className="p-3 my-3 border text-center">
                            <p>
                              Distribution of Findings across Principles and{" "}
                              {conformityLabel?.name || "Conformity Level"}
                            </p>
                            <div className="bar-chart-container">
                              <img
                                src={conformityPrincipleBarChart?.src}
                                className="img-fluid bar-chart-image"
                                alt={`Distribution of Findings across Principles and ${
                                  conformityLabel?.name || "Conformity Level"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Compliance */}
                      {compliancePieChart?.src && (
                        <div className="no-page-break">
                          <div className="p-3 my-3 border text-center">
                            <p>
                              Distribution of Findings by{" "}
                              {complianceLabel?.name || "Compliance"}
                            </p>
                            <div className="pie-chart-container">
                              <img
                                src={compliancePieChart?.src}
                                className="img-fluid pie-chart-image"
                                alt={`Distribution of Findings by ${
                                  complianceLabel?.name || "Compliance"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {showCharts && (
                    <>
                      <div className="page-break-before"></div>
                      <div className="d-print-none mt-5"></div>
                    </>
                  )}
                  {showRiskMatrices &&
                    dynamicFields &&
                    isInherentRisk?.length > 0 &&
                    shouldShowHeatmapToggle(audit) && (
                      <h2 className="mb-0 text-uppercase text-primary border-bottom border-primary">
                        IRO Summary
                      </h2>
                    )}
                  {showRiskMatrices &&
                    dynamicFields &&
                    isInherentRisk?.length > 0 && (
                      <Col md={12} xs={12} className="">
                        {isInherentRisk
                          .filter(
                            (filteredItem) => filteredItem.children.length > 1
                          )
                          .map((field) => (
                            <Card.Body>
                              <p className="w-100 text-truncate">
                                {field?.prompt || "Risk Rating"}
                              </p>
                              <InherentRisk
                                children={choiceColorMap(field?.children)}
                                dynamic={dynamicFields}
                                audit={audit}
                                module="report"
                              />
                            </Card.Body>
                          ))}
                      </Col>
                    )}

                  {showRiskMatrices &&
                    dynamicFields &&
                    isInherentRisk?.length > 0 && (
                      <>
                        <div className="page-break-before"></div>
                        <div className="d-print-none mt-5"></div>
                      </>
                    )}

                  <h2 className="mb-0 text-uppercase text-primary border-bottom border-primary">
                    Observations And Findings
                  </h2>
                  <Table borderless className="my-1 py-1 observation-table">
                    <tbody>
                      {audit?.protocol.sections
                        .filter(
                          ({ principles, sectionId }) => principles.length > 0
                        )
                        .map(
                          (
                            { title, principles = [], sectionId, summary },
                            ii
                          ) => {
                            const principle = principles[0]?.title || "";

                            const sectionQuestions = questions.filter(
                              (question) => question.sectionId === sectionId
                            );

                            const firstApplicableObservationIndex =
                              sectionQuestions?.findIndex(({ questionId }) => {
                                const observation = observations.find(
                                  ({ questionId: id }) => id === questionId
                                );
                                return !observation?.isInapplicable;
                              });

                            return sectionQuestions
                              .map(
                                (
                                  { questionId, prompt, citation, citations },
                                  index
                                ) => {
                                  const question = sectionQuestions.find(
                                    (q) => q.questionId === questionId
                                  );

                                  const hasInapplicableObservation =
                                    question.observations.some(
                                      (observation) =>
                                        observation.isInapplicable
                                    );

                                  if (
                                    !showInapplicable &&
                                    hasInapplicableObservation
                                  )
                                    return <></>;

                                  return (
                                    <Fragment key={index}>
                                      {index ===
                                        firstApplicableObservationIndex && (
                                        <tr>
                                          <td className="px-0">
                                            <div className="my-1 py-1">
                                              <h4 className="fw-bold principle">
                                                {title}
                                              </h4>
                                            </div>
                                            {!hideERMTitles && (
                                              <>
                                                <div className="d-flex mb-2">
                                                  <h5 className="fw-bold title me-2">
                                                    Principle:
                                                  </h5>{" "}
                                                  <p>{principle}</p>
                                                </div>
                                                <ManuscriptSectionSummary
                                                  summary={summary}
                                                  sectionId={sectionId}
                                                />
                                                <hr className="m-0 p-0" />
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                      <tr id={`question-${questionId}`}>
                                        <td className="d-flex justify-content-between align-items-start px-0 py-1">
                                          <div>
                                            <span>
                                              {!hideERMTitles && (
                                                <strong className="me-2">
                                                  Question {index + 1}:
                                                </strong>
                                              )}
                                            </span>
                                            <span>{stripHTML(prompt)}</span>
                                          </div>
                                          <div className="d-flex d-print-none">
                                            <ManuscriptEdit
                                              question={question}
                                              sectionId={sectionId}
                                            />
                                            <ManuscriptEditHistory
                                              question={question}
                                              labels={{
                                                risk:
                                                  riskLabel?.name ||
                                                  "Risk Rating",
                                                conformity:
                                                  conformityLabel?.name ||
                                                  "Conformity Level",
                                                compliance:
                                                  complianceLabel?.name ||
                                                  "Compliance",
                                              }}
                                            />
                                            <ManuscriptComments
                                              payload={{
                                                auditId: audit.auditId,
                                                questionId: questionId,
                                              }}
                                              question={question}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                      {view === "grid" && (
                                        <div className="my-1"></div>
                                      )}
                                      {showCitations &&
                                        !!citations.length &&
                                        citations.map(
                                          ({ id = "", body = "" }) => (
                                            <tr>
                                              <td className="px-0 my-1 py-1">
                                                <h5 className="fw-bold">
                                                  Citation:
                                                </h5>
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      DOMPurify.sanitize(body),
                                                  }}
                                                  className="mb-1"
                                                />
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      <tr>
                                        {question.observations.length > 0 ? (
                                          <>
                                            <td
                                              className={`px-0 py-0 ${
                                                view === "list"
                                                  ? "observation-view"
                                                  : null
                                              }`}
                                            >
                                              {hasInapplicableObservation ? (
                                                <div className="border p-3 text-uppercase  mb-1">
                                                  NOT Applicable
                                                </div>
                                              ) : (
                                                <ExportWrapper
                                                  sectionId={sectionId}
                                                  isMultipleObservations={
                                                    audit?.protocol
                                                      ?.hasObservationSets
                                                  }
                                                  isGrid={view === "grid"}
                                                  observations={
                                                    question.observations
                                                  }
                                                  _showRisk={
                                                    _showRisk &&
                                                    visibleObservationQuestions[
                                                      "Risk"
                                                    ]
                                                  }
                                                  _showNotes={
                                                    _showNotes &&
                                                    visibleObservationQuestions[
                                                      "Notes"
                                                    ]
                                                  }
                                                  _showCompliance={
                                                    _showCompliance &&
                                                    visibleObservationQuestions[
                                                      "Compliance"
                                                    ]
                                                  }
                                                  _showCasualFactor={
                                                    _showCasualFactor &&
                                                    visibleObservationQuestions[
                                                      "Causal Factors"
                                                    ]
                                                  }
                                                  _showRecommendation={
                                                    _showRecommendation &&
                                                    visibleObservationQuestions[
                                                      "Recommendations"
                                                    ]
                                                  }
                                                  _showConformityLevel={
                                                    _showConformityLevel &&
                                                    visibleObservationQuestions[
                                                      "Conformity"
                                                    ]
                                                  }
                                                  _showCorrectiveAction={
                                                    _showCorrectiveAction &&
                                                    visibleObservationQuestions[
                                                      "Corrective Action"
                                                    ]
                                                  }
                                                  questionOptions={
                                                    questionOptions
                                                  }
                                                  conformity={conformity}
                                                  riskLevels={riskLevels}
                                                  showAttachments={
                                                    showAttachments
                                                  }
                                                  showCitations={showCitations}
                                                  flexibleQuestions={
                                                    audit?.protocol?.flexibleQuestions.filter(
                                                      (question) => {
                                                        return visibleQuestions[
                                                          question.id
                                                        ];
                                                      }
                                                    ) || []
                                                  }
                                                  showEmptyFlexibleQuestions={
                                                    showEmptyFlexibleQuestions
                                                  }
                                                  showRiskMatrices={
                                                    showRiskMatrices
                                                  }
                                                />
                                              )}
                                            </td>
                                          </>
                                        ) : (
                                          <td className="px-0">
                                            <div className="bg-warning bg-light p-3">
                                              UNANSWERED
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                    </Fragment>
                                  );
                                }
                              )
                              .filter(isNotUndefined);
                          }
                        )}
                    </tbody>
                  </Table>

                  {showAttachments && !hideAssessmentLevelAttachment && (
                    <>
                      <div className="d-print-none mt-5"></div>
                      <div className="page-break-before"></div>
                      <AssessmentLevelFiles showAttachments={showAttachments} />
                    </>
                  )}
                  {audit?.protocol?.hasSignatories && showSignatories ? (
                    <>
                      <div className="d-print-none mt-5"></div>

                      <div className="page-break-before"></div>
                      <h2 className=" text-uppercase text-primary border-bottom border-primary ">
                        Signatures{" "}
                      </h2>
                      <Row className="d-flex my-1 py-1">
                        {Array.isArray(audit?.signatures) &&
                          audit?.signatures?.map(
                            (
                              {
                                name = "",
                                title = "",
                                signatureImage = [],
                                signatureDate = "",
                              },
                              index
                            ) => (
                              <Col
                                xs={6}
                                className={`mb-3 border-end  border-bottom border-light`}
                              >
                                {!!signatureImage.length &&
                                  signatureImage.map(({ url = "" }) => (
                                    <Image
                                      fluid
                                      src={url}
                                      style={{
                                        height: "100px",
                                      }}
                                    />
                                  ))}
                                <div className="mt-3">
                                  <h5 className="d-flex">
                                    <span className="text-primary me-2">
                                      Name:
                                    </span>
                                    <strong className=" ms-2 text-start ">
                                      {name}
                                    </strong>
                                  </h5>
                                  <p className="d-flex mb-2">
                                    <span className="text-primary ">
                                      Job Title:
                                    </span>
                                    <strong className="ms-2  text-start ">
                                      {title}
                                    </strong>
                                  </p>
                                  <p className="d-flex">
                                    <span className="text-primary me-2">
                                      Date:
                                    </span>
                                    <strong className="  text-start ms-4">
                                      {new Date(signatureDate).toLocaleString(
                                        [],
                                        {
                                          dateStyle: "short",
                                          timeStyle: "short",
                                        }
                                      )}
                                    </strong>
                                  </p>
                                </div>
                              </Col>
                            )
                          )}
                      </Row>
                    </>
                  ) : null}
                  {showLegalDisclaimer && (
                    <>
                      <div className="d-print-none mt-5"></div>

                      <div className="page-break-before"></div>
                      <LegalDisclaimer />
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          {audit?.latestComments.length > 0 && (
            <Col xs={12} md={12} lg={4} className={`mb-5 d-print-none`}>
              <h5 className="mb-3 flex-fill">Latest Comments</h5>
              <hr />
              <LatestManuscriptComments
                comments={audit?.latestComments}
                questions={questions}
                observations={observations}
              />
            </Col>
          )}
        </Row>
        <div className={!_showCustomResponses ? "invisible" : null}>
          {_showRisk && (
            <>
              <ReportRisk
                size="lg"
                isExportable={true}
                setChartImage={setRiskPieChart}
                tooltipTitle={riskLabel?.name || "Risk Rating"}
              />
              {!_hideBarCharts && (
                <ReportRiskPrinciple
                  size="lg"
                  isExportable={true}
                  setChartImage={setRiskPrincipleBarChart}
                />
              )}
            </>
          )}
          {_showConformityLevel && (
            <>
              <ReportConformityLevel
                size="lg"
                isExportable={true}
                setChartImage={setConformityPieChart}
                tooltip={conformityLabel?.name || "Conformity Level"}
              />

              {!_hideBarCharts && (
                <ReportConformityPrinciple
                  size="lg"
                  isExportable={true}
                  setChartImage={setConformityPrincipleBarChart}
                  tooltip={conformityLabel?.name || "Conformity Level"}
                />
              )}
            </>
          )}
          {_showCompliance && _hideConformityChart && (
            <>
              <ReportCompliance
                isExportable={true}
                setChartImage={setCompliancePieChart}
                tooltip={complianceLabel?.name || "Compliance"}
              />
            </>
          )}
          {_showCustomResponses && (
            <>
              {flexibleQuestions
                ?.filter(({ visualize }) => visualize)
                .map((field, index) => {
                  if (
                    field?.prompt
                      .toLowerCase()
                      .includes("applicable to other client work") &&
                    _hideForClientWorks
                  )
                    return <Fragment key={index} />;

                  if (
                    field?.prompt.toLowerCase().includes("conformity level") &&
                    _hideConformityChart
                  )
                    return <Fragment key={index} />;
                  if (field.questionType === "Radio Field")
                    return (
                      <ReportRadioField
                        size={"lg"}
                        key={index}
                        field={field}
                        isExportable={true}
                        tooltipTitle={field.prompt}
                        setChartImage={setDynamicPieChart}
                        module="Reports"
                        hasAnyOfTheChartsBeenHidden={
                          !!chartsToBeToggledForExports.length
                        }
                      />
                    );

                  return <Fragment key={index} />;
                })}
              {_hideConformityChart && (
                <ReportAggregatedConformityLevel
                  size={"lg"}
                  audit={audit}
                  isExportable={true}
                  setChartImage={setCombinedConformityPieChart}
                />
              )}
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};
export default Report;
