const { Alert } = require("react-bootstrap");

export default function NoReportsAvailable({ errorMessage }) {
  return (
    <Alert variant="info" className="my-3 d-flex flex-row mx-5">
      <div className="me-3">
        <span
          translate="no"
          className="material-symbols-outlined md-18 text-primary text-opacity-75"
        >
          info
        </span>
      </div>
      <div>
        <h5 className="mb-1">
          <small>No data to visualize</small>
        </h5>
        <p className="mb-1">
          <small>
            {errorMessage?.errorMessage ||
              errorMessage ||
              "You have no data to visualize for this Organization."}
          </small>
        </p>
      </div>
    </Alert>
  );
}
