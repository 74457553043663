import { OrganizationContext } from "contexts/OrganizationProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchFacilities = () => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);

  const [entities, setEntities] = useState();
  // const [isExpanding, setIsExpanding] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchFacilities = () => {
      setIsLoading(true);
      setError(null);
      const facilitiesPromise = get(
        `organizations/${organization?.id}/facilities`,
        {
          params: {
            perPage: 10000000000,
          },
        }
      );
      facilitiesPromise.promise
        .then(({ data: facilities }) => setEntities(facilities ?? []))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));
    };

    fetchFacilities();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id]);

  return {
    entities,
    isLoading,
    error,
  };
};

export default useFetchFacilities;
