import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";

const ManuscriptCommentUpdate = ({ onManuscriptCommentUpdated, comment }) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const { body } = comment;
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const updateManuscriptComment = (body) => {
    setError("");
    setIsLoading(true);
    const url = `/observations-report-comments/${comment.commentId}`;
    const payload = { body: body };
    const commentPromise = put(url, payload);

    commentPromise.promise
      .then((response) => {
        setIsLoading(false);
        onManuscriptCommentUpdated(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      })
      .finally(() => {
        setFieldValue("updateComment", "");
        setFieldTouched("updateComment", false);
      });

    subscribedPromises.current.push(commentPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    setFieldValue("updateComment", body || "");
  }, [body, setFieldValue]);

  return (
    <>
      <Form.Group className="mb-3 mt-1" controlId="updateComment">
        <Form.Control
          onChange={handleChange}
          onBlur={handleBlur}
          name="updateComment"
          type="text"
          placeholder="Write a comment"
          as="textarea"
          rows={3}
          value={values?.updateComment}
          isValid={values?.updateComment && !error?.updateComment}
          isInvalid={
            !(values?.updateComment && !errors?.updateComment) &&
            touched?.updateComment
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors?.updateComment && touched?.updateComment ? (
            <small>{errors?.updateComment}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      {error && <ErrorHandler error={error} />}
      <Col xs={12} className="text-end">
        <Button
          onClick={() => updateManuscriptComment(values?.updateComment)}
          size="sm"
          disabled={isLoading}
        >
          {isLoading && (
            <Spinner
              className="me-2"
              animation="border"
              size="sm"
              variant="light"
            />
          )}
          <span>Update</span>
          <span translate="no" className="material-symbols-outlined md-18 ms-2">
            send
          </span>
        </Button>
      </Col>
    </>
  );
};

ManuscriptCommentUpdate.propTypes = {
  onManuscriptCommentUpdated: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
};

export default ManuscriptCommentUpdate;
