import React from "react";
import { Modal, Button, Table, Badge } from "react-bootstrap";
import PropTypes from "prop-types";

const AuditTrailMedia = ({ activityLog = {}, handleClose }) => {
  const mediaObject = activityLog?.media || activityLog?.file;
  const subscriberAction = activityLog?.description;

  const handleDynamicMediaLevel = () => {
    const modelType = mediaObject?.modelType;
    if (modelType === "organization") {
      return {
        header: "Organization",
        value: activityLog?.organization?.name,
      };
    }
    if (modelType === "facility") {
      return {
        header: "Entity",
        value: activityLog?.facility?.name,
      };
    } else
      return {
        header: "Assessment",
        value: mediaObject?.audit?.name,
      };
  };

  const handleFileExtension = () => {
    const fileName = mediaObject?.name;
    return fileName.split(".").pop();
  };

  const data = [
    { header: "File Name", value: mediaObject?.name },
    { header: "File Description", value: mediaObject?.description },
    {
      header: "File Extension",
      value: mediaObject?.mimeType || handleFileExtension(),
    },
    { header: "File Url", value: mediaObject?.url },
    handleDynamicMediaLevel(),
  ];

  return (
    <>
      <Modal.Body>
        <div className="d-flex flox-row">
          <div className="flex-fill">
            <h4>{activityLog.logLabel}</h4>
          </div>
          <div className="text-end">
            {subscriberAction === "created" && (
              <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
                CREATED
              </Badge>
            )}
            {subscriberAction === "updated" && (
              <Badge className="bg-warning rounded-0 py-2 px-3 bg-opacity-10 text-warning">
                UPDATED
              </Badge>
            )}
            {subscriberAction === "deleted" && (
              <Badge className="bg-danger rounded-0 py-2 px-3 bg-opacity-10 text-danger">
                DELETED
              </Badge>
            )}
            <div className="mt-1">
              <small>
                {new Date(activityLog?.createdAt).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </small>
            </div>
          </div>
        </div>
        <div>
          <span>
            {activityLog?.causer?.firstName ?? "-"}{" "}
            {activityLog?.causer?.lastName ?? "-"}
          </span>
          <br />
          <small className="text-muted">
            {activityLog?.causer?.email ?? "-"}
          </small>
        </div>
        <>
          <hr />
          <Table striped borderless className="small">
            <thead>
              <tr>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="font-weight-bolder">{row.header}</td>
                  <td>
                    {row.header === "File Url" ? (
                      <a
                        href={row.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row.value}
                      </a>
                    ) : (
                      row.value
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
};

AuditTrailMedia.propTypes = {
  activityLog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AuditTrailMedia;
