export const colorfy = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 8) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 12)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

export const drawPolygons = (map, maps, geolocations, search, polygons) => {
  const newPolygons = {};
  if (!search) {
    const groupedCoordinates = geolocations.reduce((acc, geolocation) => {
      const questionId = geolocation.questionId;
      if (geolocation.showAsPolygon) {
        (acc[questionId] = acc[questionId] || []).push({
          lat: parseFloat(geolocation.latitude),
          lng: parseFloat(geolocation.longitude),
        });
      }
      return acc;
    }, {});

    for (let p in polygons) {
      polygons[p].setMap(null);
    }

    const sortPathCoords = (coords, centerPt) => {
      const sorter = (a, b) => {
        let bearA = maps.geometry.spherical.computeHeading(centerPt, a);
        let bearB = maps.geometry.spherical.computeHeading(centerPt, b);
        return bearA - bearB;
      };
      return coords.sort(sorter);
    };

    if (Object.keys(groupedCoordinates).length > 0) {
      for (let questionId in groupedCoordinates) {
        let questionBound = new maps.LatLngBounds();
        groupedCoordinates[questionId].forEach((place) => {
          questionBound.extend(new maps.LatLng(place.lat, place.lng));
        });
        let centerPt = questionBound.getCenter();
        let path = sortPathCoords(groupedCoordinates[questionId], centerPt);
        newPolygons[questionId] = new maps.Polygon({
          paths: path,
          strokeColor: colorfy(questionId),
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: colorfy(questionId),
          fillOpacity: 0.4,
          map: map,
          geodesic: true,
        });
      }
    }
  }
  return newPolygons;
};

export const removeHTML = (input) => {
  return input.replace(/<[^>]+>/g, "");
};
