import { useContext } from "react";

import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";

import { useDownloadAuditWord } from "features/assessment/services";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import ErrorHandler from "components/ui/ErrorHandler";

function AuditWordExport({ toPrintRef }) {
  const { audit } = useContext(AuditContext);
  const { error, isLoading, downloadAudit } = useDownloadAuditWord({
    toPrintRef: toPrintRef,
    auditName: audit?.name,
  });

  error && console.error("error in downloading Word", error);

  return (
    <>
      <Button
        disabled={isLoading}
        onClick={downloadAudit}
        className="btn-sm bg-primary bg-opacity-10 text-dark border-0 px-3"
      >
        {isLoading && <Spinner animation="border" size="sm" className="ms-2" />}{" "}
        Word Document
      </Button>
      {error && <ErrorHandler error={error} />}
    </>
  );
}
AuditWordExport.propTypes = {
  toPrintRef: PropTypes.any,
};
export default AuditWordExport;
