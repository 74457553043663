import { sortBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchAuditFiles = ({ auditId, fetchByDefault = true }) => {
  const subscribedPromises = useRef([]);

  const [files, setFiles] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFiles = () => {
      setError(null);
      setIsLoading(true);

      const filePromise = get(`audits/${auditId}/files`, {
        params: {
          sort: "updated_at",
        },
      });
      filePromise.promise
        .then(({ data }) => setFiles(sortBy(data || [], "updatedAt").reverse()))
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(filePromise);
    };

    if (auditId && fetchByDefault) fetchFiles();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [auditId, fetchByDefault]);

  return { error, isLoading, files, setFiles };
};

export default useFetchAuditFiles;
