import { useContext, useState } from "react";

import { useRoles } from "hooks";
import { Button, ButtonGroup } from "react-bootstrap";
import { Helmet } from "react-helmet";

import BreadCrumbs from "components/ui/BreadCrumbs";

import ActionItemFileExport from "../common/ActionItemFileExport/ActionItemFileExport";
import ActionItemNotificationDropdown from "./ActionItemNotificationDropdown";

import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context/ActionItemProvider";
import ActionItemCreate from "../common/ActionItemCreate";
import ActionItemDashBoard from "../ui/ActionItemDashBoard";
import ActionItemStats from "../ui/ActionItemStats";
import "./ActionItems.scss";

const ActionItems = () => {
  const [tableView, setTableView] = useState(true);
  const dispatch = useContext(ActionItemsDispatchContext);
  const { filters } = useContext(ActionItemsContext);
  const { isAdmin } = useRoles();

  const handleError = (err) => {
    dispatch({ type: "SET_ERROR", payload: err });
  };

  return (
    <>
      <Helmet>
        <title>Action Items</title>
      </Helmet>
      <BreadCrumbs
        breadcrumbs={[
          { name: "Dashboard", link: "/" },
          { name: "Action Items", active: false },
        ]}
      />
      <div className="d-flex flex-row mb-3 flex-wrap">
        <div className="flex-fill">
          <h1>Action Items</h1>
        </div>
        <div className="d-flex flex-wrap align-items-center">
          {isAdmin && (
            <ActionItemCreate
              dashboard={true}
              onActionItemCreated={(newActionItem) => {
                dispatch({
                  type: "CREATE_ACTION_ITEM",
                  payload: newActionItem,
                });
              }}
            />
          )}
          <ActionItemNotificationDropdown />
          {tableView && (
            <>
              <ActionItemFileExport
                handleError={handleError}
                filters={filters}
              />
            </>
          )}
          <ButtonGroup size="sm">
            <Button
              variant="outline-secondary"
              active={!tableView}
              onClick={() => setTableView(false)}
              className="px-3"
            >
              <span translate="no" className="material-symbols-outlined md-18">
                bar_chart
              </span>
            </Button>
            <Button
              variant="outline-secondary"
              active={tableView}
              onClick={() => setTableView(true)}
              className="px-3"
            >
              <span translate="no" className="material-symbols-outlined md-18">
                table_view
              </span>
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <ActionItemStats tableView={tableView} />
      <ActionItemDashBoard tableView={tableView} />
    </>
  );
};

ActionItems.propTypes = {};

export default ActionItems;
