import { useContext, useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import { useRoles, useToast } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";
import * as yup from "yup";

import DragAndDrop from "components/common/DragAndDrop";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { MAX_FILE_SIZE } from "features/assessment/constants";
import { upload } from "utils/DeApi";
import {
  compressImage,
  convertToMB,
  SUPPORTED_FORMATS,
} from "utils/UploadUtils";
import AssessmentTypeahead from "../../common/AssessmentTypeahead";
import EntityTypeahead from "../../common/EntityTypeahead";
import "./FileUpload.scss";

const FileUpload = ({ onFileUploaded }) => {
  const subscribedPromises = useRef([]);
  const { isCertifier, isMember } = useRoles();
  const organization = useContext(OrganizationContext);
  const toast = useToast();

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const uploadOrganizationFiles = (files, description) => {
    const filesPromises = files.map((file) => {
      const uploadData = new FormData();

      uploadData.append("document", file);
      uploadData.append("description", description);

      const auditFilePromise = upload(
        `organizations/${organization?.id}/documents`,
        uploadData
      );
      subscribedPromises.current.push(auditFilePromise);

      return auditFilePromise.promise;
    });

    Promise.all(filesPromises)
      .then((response) => {
        handleClose();
        onFileUploaded(response.map(({ data }) => data));
        toast.success("Success", "The file has been uploaded successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  };

  const uploadFacilityFiles = (files, description, facilityId) => {
    const filesPromises = files.map((file) => {
      const uploadData = new FormData();

      uploadData.append("document", file);
      uploadData.append("description", description);

      const auditFilePromise = upload(
        `facilities/${facilityId}/documents`,
        uploadData
      );
      subscribedPromises.current.push(auditFilePromise);

      return auditFilePromise.promise;
    });

    Promise.all(filesPromises)
      .then((response) => {
        handleClose();
        toast.success("Success", "The file has been uploaded successfully");
        onFileUploaded(response.map(({ data }) => data));
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  };

  const uploadAuditFiles = (files, description, auditId) => {
    const filesPromises = files.map((file) => {
      const uploadData = new FormData();

      uploadData.append("file", file);
      uploadData.append("description", description);

      const auditFilePromise = upload(`audits/${auditId}/files`, uploadData);
      subscribedPromises.current.push(auditFilePromise);

      return auditFilePromise.promise;
    });

    Promise.all(filesPromises)
      .then((response) => {
        handleClose();
        onFileUploaded(
          response.map(({ data }) => ({
            id: data.fileId,
            fileName: data.name,
            name: data.name,
            mimeType: "",
            modelType: "assessment",
            humanReadableSize: "",
            url: data.url,
            description: data.description,
            auditId: data.auditId,
            observationId: data.observationId,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
          }))
        );
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  };

  const uploadMultipleFiles = (files, description, facilityId, auditId) => {
    setError(null);
    setIsLoading(true);

    if (!facilityId && !auditId) {
      uploadOrganizationFiles(files, description);
      return false;
    }

    if (facilityId && !auditId) {
      uploadFacilityFiles(files, description, facilityId);
      return false;
    }

    if (facilityId && auditId) {
      uploadAuditFiles(files, description, auditId);
      return false;
    }
  };

  const uploadOrDragFile = async (files, setFieldValue) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.startsWith("image")) {
        files[i] = await compressImage(files[i]);
      }
    }
    setFieldValue("files", files);
    return [...files];
  };

  const schema = yup.object().shape({
    description: yup
      .string()
      .required("The Description is required")
      .min(2, "Description is too Short!")
      .max(255, "Description is too Long!"),
    files: yup
      .array()
      .min(1, "The file is required")
      .required("The file is required")
      .test("max-fileSize", "File Size is too large", (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > MAX_FILE_SIZE) {
              return false;
            }
          }
        }
        return true;
      }),
    entity: yup
      .array()
      .test("required", "The Entity is required", (value, context) => {
        if (isMember) {
          return value?.length;
        } else {
          return true;
        }
      }),
  });

  useEffect(() => {
    if (!show) setError(false);
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [show]);

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        size="sm"
        className="float-end"
        onClick={handleShow}
        variant="primary"
        data-cy={`btn-bulk-upload`}
      >
        <span translate="no" className="material-symbols-outlined md-16 me-2">
          upload_file
        </span>
        Bulk Upload
      </Button>
      <Offcanvas
        scroll
        show={show}
        onHide={handleClose}
        placement="end"
        className="w-fixed-640"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Upload Multiple Files</Offcanvas.Title>
        </Offcanvas.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { files, description, entity, assessment } = values;
            const facilityId =
              Array.isArray(entity) && entity.length
                ? values.entity[0]?.facilityId
                : null;
            const auditId =
              Array.isArray(assessment) && assessment.length
                ? values.assessment[0]?.auditId
                : null;
            uploadMultipleFiles(files, description, facilityId, auditId);
          }}
          initialValues={{
            files: [],
            entity: [],
            assessment: "",
            description: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldTouched,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="overflow-auto">
              <Offcanvas.Body>
                <Form.Group controlId="file" className="mb-3">
                  <Form.Label>
                    Upload Files <RequiredAsterisk />
                  </Form.Label>
                  <DragAndDrop
                    onDrop={(files) => uploadOrDragFile(files, setFieldValue)}
                    maxSize={convertToMB(MAX_FILE_SIZE)}
                    formats={SUPPORTED_FORMATS}
                  />
                  {values?.files.length && errors.files ? (
                    <small className="text-danger">{errors.files}</small>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>
                    Description
                    <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !(values.description && !errors.description) &&
                      touched.description
                    }
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="entity" className="mb-3">
                  <Form.Label>
                    Entity {isMember ? <RequiredAsterisk /> : "(Optional)"}
                  </Form.Label>
                  <EntityTypeahead
                    isValid={
                      (values?.entity?.length && !errors.entity) ||
                      values?.entity?.length
                    }
                    isInvalid={
                      !values?.entity?.length &&
                      errors?.entity &&
                      touched?.entity &&
                      !touched?.entity?.length
                    }
                    onChange={(entity) => {
                      setFieldValue("entity", entity);
                      setFieldValue("assessment", []);
                      setFieldTouched("entity", true);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.entity &&
                    touched?.entity &&
                    !touched?.entity?.length ? (
                      <small>{errors?.entity}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="assessment" className="mb-3">
                  <Form.Label>Assessment (Optional)</Form.Label>
                  <AssessmentTypeahead
                    facilityId={
                      Array.isArray(values.entity) && values.entity.length
                        ? values.entity[0]?.facilityId
                        : null
                    }
                    isValid={values.assessment && !errors.assessment}
                    onChange={(assessment) =>
                      setFieldValue("assessment", assessment)
                    }
                  />
                </Form.Group>
                {error && <ErrorHandler error={error} />}

                <div className="d-flex flex-row py-3 mt-5 w-100">
                  <div className="flex-fill"></div>
                  <div>
                    <Button
                      size="sm"
                      className="me-2"
                      variant="outline-primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" size="sm" disabled={isLoading}>
                      {isLoading && (
                        <Spinner
                          className="me-2"
                          animation="border"
                          size="sm"
                          variant="light"
                        />
                      )}{" "}
                      Upload File(s)
                    </Button>
                  </div>
                </div>
              </Offcanvas.Body>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

FileUpload.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
};

export default FileUpload;
