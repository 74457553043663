import { useContext } from "react";

import { Typeahead } from "react-bootstrap-typeahead";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useRoles } from "hooks";

const AssigneesFilter = () => {
  const { isAdmin } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);

  if (audit?.confidential && !isAdmin) return <></>;
  if (!audit?.protocol?.hasAssignableQuestions) return <></>;

  return (
    <Typeahead
      id="assignee-typeahead"
      className="mb-2"
      clearButton
      placeholder="Filter by assignee"
      labelKey={(option) => `${option.firstName} ${option.lastName}`}
      onChange={(data) => {
        setAudit((prevAudit) => ({
          ...prevAudit,
          selectedAssignee: Array.isArray(data) && data.length ? data : null,
        }));
      }}
      options={audit?.subscribers || []}
      selected={
        Array.isArray(audit?.selectedAssignee) ? audit?.selectedAssignee : []
      }
    />
  );
};

export default AssigneesFilter;
