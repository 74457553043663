import { OrganizationContext } from "contexts/OrganizationProvider";
import { uniqBy } from "lodash";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchFacilities = ({
  page = 1,
  perPage = 10,
  query,
  startDate,
  endDate,
  sortState = {},
}) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [facilities, setFacilities] = useState();
  const [meta, setMeta] = useState();

  const fetchFacilities = useCallback(() => {
    setError(null);
    setIsLoading(true);
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const facilitiesPromise = get(
      `organizations/${organization?.id}/facilities`,
      {
        params: {
          "filter[created_at]": startDate && endDate ? `${start},${end}` : "",
          "filter[search]": query ? query : "",
          include: "labels",
          page: page || 1,
          perPage: perPage || 10,
          sort: !sortState?.sortAsc
            ? sortState?.sortColumn
            : `-${sortState?.sortColumn}`,
        },
      }
    );
    facilitiesPromise.promise
      .then(({ data: facilities, meta }) => {
        setFacilities(uniqBy(facilities, "facilityId"));
        setMeta(meta);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    subscribedPromises.current.push(facilitiesPromise);
  }, [
    startDate,
    endDate,
    organization?.id,
    query,
    page,
    perPage,
    sortState?.sortAsc,
    sortState?.sortColumn,
  ]);

  useEffect(() => {
    fetchFacilities();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchFacilities]);

  return {
    error,
    isLoading,
    meta,
    setMeta,
    facilities,
    setFacilities,
  };
};

export default useFetchFacilities;
