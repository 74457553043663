import { useContext, useEffect, useRef, useState } from "react";

import { useReactToPrint } from "react-to-print";

import { post } from "utils/MlWord";
import { OrganizationContext } from "contexts/OrganizationProvider";

import { useVertical } from "../hooks";
import { formattedDate, formattedTime } from "../utils";

const useDownloadAuditWord = ({ toPrintRef, auditName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const { generatedFileNameShouldOnlyHaveAssessmentName } = useVertical();

  const downloadAudit = useReactToPrint({
    content: () => toPrintRef.current,
    print: async (printIframe) => {
      setIsLoading(true);

      const document = printIframe.contentDocument;
      if (document) {
        var base = document.createElement("base");
        base.href = window.location.origin;
        document.getElementsByTagName("head")[0].appendChild(base);

        var head = document.getElementsByTagName("head")[0];
        var links = head.getElementsByTagName("link");
        for (var link in links) {
          if (links.hasOwnProperty(link)) {
            var l = links[link];
            if (l.rel === "stylesheet") {
              l.href = "" + l.href;
            }
          }
        }

        const html = document.getElementsByTagName("html")[0];

        const htmlFile = new Blob([html.outerHTML]);
        const formData = new FormData();
        let filename = generatedFileNameShouldOnlyHaveAssessmentName
          ? `${auditName}`
          : `${organization.name} ${auditName} Assessment Report`;

        formData.append("name", filename);
        formData.append("html_file", htmlFile);
        formData.append(
          "header_html",
          `<div 
            style="width: 100%; 
            margin-top: 2px; 
            visibility:hidden; 
            border-top: 1px solid seagreen; 
            display:flex;
            flex-direction:row;
            justify-content:space-between">
              <p style="text-align:left; font-size: 7px; color: green;margin-left:2px">${auditName}</p>
              <p style="text-align:right; font-size: 7px; color: green;margin-right:2px">${formattedTime}</p>
          </div>`
        );
        formData.append(
          "footer_html",
          `<div 
            style="width: 100%;
            margin-bottom: 2px; 
            visibility:hidden; 
            border-bottom: 1px solid seagreen; 
            display:flex;
            flex-direction:row;
            justify-content:space-between">
              <p style="text-align:left; font-size: 7px; color: green;margin-left:2px">${organization.name}</p>
              <p style="text-align:right; font-size: 7px; color: green;margin-right:2px">${formattedDate}</p>
            </div>`
        );
        formData.append("header_and_footer", 1);
        const responsePromise = post(
          `${process.env.REACT_APP_HTML_TO_PDF_TO_WORD}`,
          formData
        );
        responsePromise.promise
          .then((response) => {
            const docLink = response?.presigned_output_url;

            const link1 = document.createElement("a");
            link1.href = docLink;
            filename = generatedFileNameShouldOnlyHaveAssessmentName
              ? `${auditName}.docx`
              : `${organization.name}_Assesment_${auditName}.docx}`;

            link1.download = filename;
            document.body.appendChild(link1);
            link1.click();
            document.body.removeChild(link1);
          })
          .catch((err) => setError(err))
          .finally(() => {
            setIsLoading(false);
          });
        subscribedPromises.current.push(responsePromise);
      }
    },
  });

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, downloadAudit };
};

export default useDownloadAuditWord;
