import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

export const Autocomplete = ({ onPlacesChanged, maps, map }) => {
  const input = useRef(null);
  const autocomplete = useRef(null);

  const handleOnPlacesChanged = () => {
    if (onPlacesChanged) {
      onPlacesChanged(autocomplete?.current.getPlace());
    }
  };

  const options = {
    fields: ["formatted_address", "geometry", "name"],
    strictBounds: false,
  };

  useEffect(() => {
    if (!autocomplete.current && maps) {
      autocomplete.current = new maps.places.Autocomplete(
        input.current,
        options
      );
      autocomplete.current.bindTo("bounds", map);
      map.controls[maps.ControlPosition.TOP_LEFT].push(input.current);
      autocomplete.current.addListener("place_changed", handleOnPlacesChanged);
    }
    return () => {
      if (maps) {
        maps.event.clearInstanceListeners(input);
      }
    };
  }, [maps, handleOnPlacesChanged]);

  return (
    <Form.Control
      className="autocomplete"
      ref={input}
      placeholder="Search"
      aria-label="Search"
      type="search"
    />
  );
};

Autocomplete.propTypes = {
  maps: PropTypes.any.isRequired,
  map: PropTypes.any.isRequired,
  onPlacesChanged: PropTypes.func,
};
