import { useContext } from "react";

import { Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { STORMWATER_VERTICAL_ID } from "config/ORGS";
import { useVertical } from "features/assessment/hooks";
import { OrganizationContext } from "contexts/OrganizationProvider";

const RedirectToVertical = () => {
  const location = useLocation();
  const { domain } = useVertical();
  const { pathname } = location;
  const organization = useContext(OrganizationContext);
  const { id: verticalId = null } = organization.vertical || {};
  const isStormwaterVertical =
    verticalId === STORMWATER_VERTICAL_ID && domain !== window.location.origin;

  return (
    <>
      {isStormwaterVertical && (
        <Alert variant="info" className="d-print-none">
          <span className="material-symbols-outlined md-16 me-2">info</span>
          This Organization contains specific reporting settings please view on
          <a href={domain + pathname} className="text-underline">
            {" "}
            {domain}
          </a>{" "}
          to ensure you are viewing the report correctly.
        </Alert>
      )}
    </>
  );
};

export default RedirectToVertical;
