import { Alert, Button } from "react-bootstrap";

const ErrorBoundaryFallback = ({ resetError }) => (
  <Alert variant="info" className={`my-3 d-flex flex-row`}>
    <div className="me-3">
      <span translate="no" className="material-symbols-outlined md-18">
        Error
      </span>
    </div>
    <div className="flex-fill">
      <h4 className="mb-1">
        <small>Error</small>
      </h4>
      <p>
        <small>An unexpected issue has occurred in ERM Assess.</small>
      </p>
      <p>
        <small>
          Please refresh the page and try again. If this doesn't work contact
          the admin.
        </small>
      </p>
      <Button
        size="sm"
        variant="info"
        className="me-2"
        onClick={() => {
          resetError();
        }}
      >
        <span translate="no" className="material-symbols-outlined md-15 me-2">
          refresh
        </span>
        <span>Refresh</span>
      </Button>
    </div>
  </Alert>
);

export default ErrorBoundaryFallback;
