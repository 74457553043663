import moment from "moment";
import { Fragment, useContext } from "react";
import { Table } from "react-bootstrap";
import {
  showRisk,
  showNotes,
  showCompliance,
  showRecommendation,
  showCorrectiveAction,
  showConformityLevel,
  showCasualFactor,
  riskMap,
  complianceMap,
  conformityLevelMap,
} from "features/assessment/components/Audit/helper";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

const QuestionAnswersRow = ({ observations }) => {
  const { audit } = useContext(AuditContext);
  const { conformity, riskLevels, questionOptions, hasObservationSets } =
    audit?.protocol || {};
  const [
    riskLabel,
    complianceLabel,
    conformityLabel,
    notesLabel,
    recommendationLabel,
    causalFactorsLabel,
    correctiveActionLabel,
  ] = questionOptions || [];

  const flexibleQuestions = audit?.protocol?.flexibleQuestions;

  const _showRisk = showRisk(conformity, questionOptions);
  const _showNotes = showNotes(conformity, questionOptions);
  const _showCompliance = showCompliance(conformity, questionOptions);
  const _showCasualFactor = showCasualFactor(conformity, questionOptions);
  const _showRecommendation = showRecommendation(conformity, questionOptions);
  const _showConformityLevel = showConformityLevel(conformity, questionOptions);
  const _showCorrectiveAction = showCorrectiveAction(
    conformity,
    questionOptions
  );

  return (
    <div
      className={hasObservationSets ? "border-primary border-start ps-2" : ""}
      style={{
        "--bs-border-style": "dashed",
      }}
    >
      {Array.isArray(observations) &&
        observations.map((observation, index) => {
          const responses = observation?.responses || [];
          return (
            <Fragment key={observation?.observationId}>
              {hasObservationSets && (
                <p className="text-primary mb-2">
                  <span
                    translate="no"
                    className={`material-symbols-outlined md-18 text-primary`}
                  >
                    subdirectory_arrow_right
                  </span>
                  <strong>
                    {observation?.label || `Observation ${++index}`}
                  </strong>
                </p>
              )}
              <Table
                size="sm"
                bordered
                className={`mb-4 ${hasObservationSets ? "ms-2" : ""}`}
              >
                <tbody>
                  {flexibleQuestions
                    .sort((a, b) => a.order - b.order)
                    .map((response) => {
                      let { id, prompt, properties } = response || {};
                      let choices = [];
                      let notes = "";
                      let choice = {};
                      let responseDatetime = "";
                      const matchResponse = responses.filter(
                        (elem) => elem.flexibleQuestionId === id
                      );
                      if (properties?.fieldType === "checkbox") {
                        choices = matchResponse.map((elem) => elem?.choice);
                      } else {
                        notes = matchResponse[0]?.notes ?? "";
                        responseDatetime =
                          matchResponse[0]?.responseDatetime ?? "";
                        choice = matchResponse[0]?.choice ?? {};
                      }

                      if (properties?.fieldType === "text") {
                        return (
                          <tr key={id}>
                            <td className="w-50 fw-bold">{prompt}</td>
                            <td className="text-capitalize-first text-break">
                              {notes || "-"}
                            </td>
                          </tr>
                        );
                      }
                      if (properties?.fieldType === "datetime") {
                        const datetime = responseDatetime
                          ? moment
                              .utc(responseDatetime)
                              .format("MMMM D, yyyy h:mm a")
                          : "";
                        return (
                          <tr key={id}>
                            <td className="w-50 fw-bold">{prompt}</td>
                            <td className="text-break">{datetime || "-"}</td>
                          </tr>
                        );
                      }
                      if (properties?.fieldType === "numeric") {
                        return (
                          <tr key={id}>
                            <td className="w-50 fw-bold">{prompt}</td>
                            <td className="text-capitalize-first text-break">
                              Test {notes || "-"}
                            </td>
                          </tr>
                        );
                      }
                      if (properties?.fieldType === "radio" && choice?.label) {
                        return (
                          <tr key={id}>
                            <td className="w-50 fw-bold">{prompt}</td>
                            <td className="text-capitalize-first  text-break">
                              {choice?.label}
                            </td>
                          </tr>
                        );
                      }
                      if (
                        properties?.fieldType === "checkbox" &&
                        !!choices?.length
                      ) {
                        return (
                          <tr key={id}>
                            <td className="w-50 fw-bold">{prompt}</td>
                            <td className="text-break">
                              {!!choices?.length &&
                                choices?.map(({ label = "" }, key) => (
                                  <span
                                    className="d-flex flex-column mb-1 text-capitalize-first"
                                    key={key}
                                  >{`- ${label} `}</span>
                                ))}
                            </td>
                          </tr>
                        );
                      }

                      return <Fragment key={id} />;
                    })}

                  {_showRisk && riskMap(observation?.risk, riskLevels) && (
                    <tr>
                      <td className="w-50 fw-bold">
                        {riskLabel?.name || "Risk Rating"}
                      </td>
                      <td className="text-break">
                        {riskMap(observation?.risk, riskLevels)}
                      </td>
                    </tr>
                  )}

                  {_showCompliance &&
                    complianceMap(observation?.compliance) && (
                      <tr>
                        <td className="fw-bold">
                          {complianceLabel?.name || "Compliance Observed"}
                        </td>
                        <td className="text-break">
                          {complianceMap(observation?.compliance)}
                        </td>
                      </tr>
                    )}
                  {_showCorrectiveAction && observation?.correctiveAction && (
                    <tr>
                      <td className="w-50 fw-bold">
                        {correctiveActionLabel?.name || "Corrective Action"}
                      </td>
                      <td className="text-break">
                        {observation?.correctiveAction}
                      </td>
                    </tr>
                  )}
                  {_showConformityLevel &&
                    conformityLevelMap(
                      observation?.conformityLevel,
                      conformity
                    ) && (
                      <tr>
                        <td className="w-50 fw-bold">
                          {conformityLabel?.name || "Conformity Level"}
                        </td>
                        <td className="text-break">
                          {conformityLevelMap(
                            observation?.conformityLevel,
                            conformity
                          )}
                        </td>
                      </tr>
                    )}

                  {_showCasualFactor && observation?.causalFactors && (
                    <tr>
                      <td className="w-50 fw-bold">
                        {causalFactorsLabel?.name || "Causal Factors"}
                      </td>
                      <td className="text-break">
                        {observation?.causalFactors}
                      </td>
                    </tr>
                  )}
                  {_showRecommendation && observation?.recommendation && (
                    <tr>
                      <td className="w-50 fw-bold">
                        {recommendationLabel?.name || "Best Practice"}
                      </td>
                      <td className="text-break">
                        {observation?.recommendation}
                      </td>
                    </tr>
                  )}
                  {_showNotes && observation?.notes && (
                    <tr>
                      <td className="w-50 fw-bold">
                        {notesLabel?.name || "Notes"}
                      </td>
                      <td className="text-break">{observation?.notes}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Fragment>
          );
        })}
    </div>
  );
};

export default QuestionAnswersRow;
