import React, { useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { destroy } from "utils/DeApi";

const ManuscriptCommentDelete = ({ onCommentDeleted, commentId }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const deletedComment = () => {
    setError(null);
    setIsLoading(true);
    const url = `/observations-report-comments/${commentId}`;
    const commentDeletePromise = destroy(url);

    commentDeletePromise.promise
      .then(() => {
        setIsLoading(false);
        onCommentDeleted(commentId);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(commentDeletePromise);
  };

  error && console.log(error);
  return (
    <Button
      variant="outline-danger"
      className="ms-2"
      onClick={() => deletedComment()}
      size="sm"
    >
      {isLoading ? (
        <Spinner
          className="me-2"
          animation="border"
          size="sm"
          variant="light"
        />
      ) : (
        <span translate="no" className="material-symbols-outlined md-18">
          delete
        </span>
      )}
    </Button>
  );
};

ManuscriptCommentDelete.propTypes = {
  onCommentDeleted: PropTypes.func.isRequired,
  commentId: PropTypes.string.isRequired,
};

export default ManuscriptCommentDelete;
