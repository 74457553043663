import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context/ActionItemProvider";
import { useFetchTags } from "features/actionItems/services";
import isFilterActive from "features/actionItems/utils/isFilterActive";
import React, { useContext } from "react";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

const TagFilters = () => {
  const {
    filters: { tag },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);
  const { tags, isLoading } = useFetchTags({ show: true });

  return (
    <div
      className={`mb-3 ${
        !!tag.length
          ? "tags-wrapper typehead-multi-select-active min-width-19 w-auto"
          : "col-lg-2"
      }`}
    >
      <Typeahead
        multiple
        className={`${isFilterActive(tag[0]?.name, true)}`}
        clearButton
        isLoading={isLoading}
        id="tags"
        labelKey="name"
        name="tags"
        onChange={(tag) => {
          dispatch({ type: "SET_FILTERS", payload: { tag } });
        }}
        options={tags || []}
        selected={tag}
        placeholder="Select Tags"
        renderMenu={(results, menuProps) => (
          <Menu className="" {...menuProps}>
            {results.map((result, index) => (
              <MenuItem key={result.labelId} option={result} position={index}>
                {result.name}
              </MenuItem>
            ))}
          </Menu>
        )}
      />
    </div>
  );
};

export default TagFilters;
