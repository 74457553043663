import EntityTypeahead from "features/entity/components/ui/EntityTypeahead";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

const AdvancedInfomationFields = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  return (
    <>
      <Form.Group controlId="entityType" className="my-3">
        <Form.Label>Entity Type</Form.Label>
        <br />
        <Form.Check
          inline
          label={"Unmanned Entity"}
          name="entityType"
          value={0}
          checked={values.entityType === 0}
          onChange={(event) => setFieldValue("entityType", 0)}
          type="radio"
          id={`checkbox-unmmaned`}
        />
        <Form.Check
          inline
          label={"Manned Entity"}
          name="entityType"
          value={1}
          checked={values.entityType === 1}
          onChange={(event) => setFieldValue("entityType", 1)}
          type="radio"
          id={`checkbox-manned`}
        />
      </Form.Group>
      <Form.Group controlId="maxOccupancy" className="mb-3">
        <Form.Label className="mb-1">Max Occupancy</Form.Label>
        <Form.Control
          type="number"
          name="maxOccupancy"
          value={values.maxOccupancy}
          onBlur={handleBlur}
          onChange={handleChange}
          isValid={values.maxOccupancy && !errors.maxOccupancy}
          isInvalid={!!errors.maxOccupancy && touched.maxOccupancy}
        />
        <Form.Control.Feedback type="invalid">
          {errors.maxOccupancy && touched.maxOccupancy ? (
            <small>{errors.maxOccupancy}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="numberOfEmployees" className="mb-3">
        <Form.Label className="mb-1">Number of Employees</Form.Label>
        <Form.Control
          type="number"
          name="numberOfEmployees"
          onBlur={handleBlur}
          value={values.numberOfEmployees}
          onChange={handleChange}
          isValid={values.numberOfEmployees && !errors.numberOfEmployees}
          isInvalid={!!errors.numberOfEmployees && touched.numberOfEmployees}
        />
        <Form.Control.Feedback type="invalid">
          {errors.numberOfEmployees && touched.numberOfEmployees ? (
            <small>{errors.numberOfEmployees}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="fireDepartment" className="mb-3">
        <Form.Label className="mb-1">Fire Department</Form.Label>
        <Form.Control
          type="text"
          name="fireDepartment"
          onBlur={handleBlur}
          value={values.fireDepartment}
          onChange={handleChange}
          isValid={values.fireDepartment && !errors.fireDepartment}
          isInvalid={!!errors.fireDepartment && touched.fireDepartment}
        />
        <Form.Control.Feedback type="invalid">
          {errors.fireDepartment && touched.fireDepartment ? (
            <small>{errors.fireDepartment}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="lepc" className="mb-3">
        <Form.Label className="mb-1">
          LEPC (Local Emergency Planning Committee){" "}
        </Form.Label>
        <Form.Control
          type="text"
          name="lepc"
          onBlur={handleBlur}
          value={values.lepc}
          onChange={handleChange}
          isValid={values.lepc && !errors.lepc}
          isInvalid={!!errors.lepc && touched.lepc}
        />
        <Form.Control.Feedback type="invalid">
          {errors.lepc && touched.lepc ? <small>{errors.lepc}</small> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="dunAndBradstreetNumber" className="mb-3">
        <Form.Label className="mb-1">Dun & Bradstreet Number</Form.Label>
        <Form.Control
          type="text"
          name="dunAndBradstreetNumber"
          onBlur={handleBlur}
          value={values.dunAndBradstreetNumber}
          onChange={handleChange}
          isValid={
            values.dunAndBradstreetNumber && !errors.dunAndBradstreetNumber
          }
          isInvalid={
            !!errors.dunAndBradstreetNumber && touched.dunAndBradstreetNumber
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors.dunAndBradstreetNumber && touched.dunAndBradstreetNumber ? (
            <small>{errors.dunAndBradstreetNumber}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="naicsCode" className="mb-3">
        <Form.Label className="mb-1">NAICS code</Form.Label>
        <Form.Control
          type="text"
          name="naicsCode"
          onBlur={handleBlur}
          value={values.naicsCode}
          onChange={handleChange}
          isValid={values.naicsCode && !errors.naicsCode}
          isInvalid={!!errors.naicsCode && touched.naicsCode}
        />
        <Form.Control.Feedback type="invalid">
          {errors.naicsCode && touched.naicsCode ? (
            <small>{errors.naicsCode}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="facility_supplies" className="mb-3">
        <Form.Label className="mb-1">Supply To</Form.Label>
        <EntityTypeahead
          selected={values.facilitySuppliesTo}
          onChange={(entity) => setFieldValue("facilitySuppliesTo", entity)}
          isValid={
            values.facilitySuppliesTo.length && !errors.facilitySuppliesTo
          }
          isInvalid={!!errors.facilitySuppliesTo && touched.facilitySuppliesTo}
          onBlur={() => setFieldTouched("facilitySuppliesTo", true, true)}
        />
        <Form.Control.Feedback type="invalid">
          {errors.facilitySuppliesTo && touched.facilitySuppliesTo ? (
            <small>{errors.facilitySuppliesTo}</small>
          ) : null}
        </Form.Control.Feedback>

        <Form.Label className="mb-1 mt-3">Supplied By</Form.Label>
        <EntityTypeahead
          selected={values.facilitySuppliesFrom}
          onChange={(entity) => setFieldValue("facilitySuppliesFrom", entity)}
          isValid={
            values.facilitySuppliesFrom.length && !errors.facilitySuppliesFrom
          }
          isInvalid={
            !!errors.facilitySuppliesFrom && touched.facilitySuppliesFrom
          }
          onBlur={() => setFieldTouched("facilitySuppliesFrom", true, true)}
        />
        <Form.Control.Feedback type="invalid">
          {errors.facilitySuppliesFrom && touched.facilitySuppliesFrom ? (
            <small>{errors.facilitySuppliesFrom}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default AdvancedInfomationFields;
