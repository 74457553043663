import { useRoles } from "hooks";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import ManuscriptQuestion from "../ManuscriptQuestion/ManuscriptQuestion";
import "./ManuscriptEdit.scss";

const ManuscriptEdit = ({
  showCanvas = false,
  onCanvasHidden,
  disabled,
  question,
  sectionId,
}) => {
  const [show, setShow] = useState(showCanvas);
  const { isCertifier } = useRoles();

  const handleClose = () => {
    setShow(false);
    if (typeof onCanvasHidden === "function") onCanvasHidden();
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(showCanvas);
  }, [showCanvas]);

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        size="sm"
        disabled={disabled}
        onClick={handleShow}
        variant={"outline-primary"}
        className="ms-3  d-print-none py-0"
      >
        <span translate="no" className="material-symbols-outlined md-16">
          edit
        </span>
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        scroll
        className="w-fixed-640"
      >
        <div className="h-100 overflow-auto">
          <div className="d-flex justify-content-between p-3 manuscript-header">
            <div
              className="question-title"
              dangerouslySetInnerHTML={{
                __html: question?.prompt,
              }}
            />
            <Button
              type="button"
              className="btn-close bg-white p-2 ms-2"
              onClick={handleClose}
            />
          </div>
          <hr className="m-1" />
          <ManuscriptQuestion
            questionId={question.questionId}
            handleClose={handleClose}
            sectionId={sectionId}
          />
        </div>
      </Offcanvas>
    </>
  );
};

ManuscriptEdit.propTypes = {
  showCanvas: PropTypes.bool,
  onCanvasHidden: PropTypes.func,
  disabled: PropTypes.bool,
  question: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
};

export default ManuscriptEdit;
