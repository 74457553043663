import { useState, Fragment, useCallback, useContext, useEffect } from "react";
import DOMPurify from "dompurify";
import { Col, Container, Row } from "react-bootstrap";

import { UserContext } from "contexts/UserProvider";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { get } from "utils/DeApi";

const ActionItemPDF = ({ reference }) => {
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const [reportActionItem, setReportActionItem] = useState();
  const [total, setTotal] = useState();

  const fetchActionItems = useCallback(() => {
    const subscribersPromise = get(
      `subscribers/${user?.de?.subscriberId}/action-items`,
      {
        params: {
          organizationId: organization?.id,
          current_page: 1,
          from: 1,
          last_page: total,
          per_page: total,
          total: total,
          sort: "-updated_at",
        },
      }
    );

    subscribersPromise.promise
      .then(({ data, meta }) => {
        setTotal(meta?.total);
        setReportActionItem(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [total, organization?.id, user?.de?.subscriberId]);

  useEffect(() => {
    fetchActionItems();
  }, [fetchActionItems]);

  return (
    <div style={{ display: "none" }}>
      <div ref={reference}>
        {reportActionItem?.map((Actionitem, key) => {
          const {
            actionItemId,
            item,
            assignedBy: assignor,
            assignedTo,
            dueDate,
            updatedAt,
            taskableId,
            taskableType,
            statusLabel,
            description,
            doneBy,
            createdAt,
            typeLabel,
            tags,
          } = Actionitem;

          return (
            <Fragment key={actionItemId}>
              <div>
                <Container className="container">
                  <Row className="row">
                    <div className="d-flex flex-column">
                      <h2 className="action-item">
                        {key + 1}.{item}
                      </h2>
                      {description && (
                        <div className="d-flex flex-row">
                          <h2>Description:</h2>
                          <h2
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(description),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Row>

                  <Row className="mt-2">
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around fs-3">
                        <p> Due Date: </p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {" "}
                          {new Date(dueDate).toISOString().slice(0, 10)}
                        </p>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> Taskable Type:</p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {" "}
                          {taskableType ? taskableType : "N/A"}
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> Assigned By:</p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {" "}
                          {assignor?.firstName} {assignor?.lastName}
                        </p>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> Done By:</p>

                        <p className="text-secondary mx-2 fs-3 text-center">
                          {doneBy ? doneBy.firstName + doneBy.lastName : "N/A"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> Status:</p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {statusLabel}
                        </p>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> TaskableId:</p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {" "}
                          {taskableId ? taskableId : "N/A"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> Assigned To:</p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {assignedTo && assignedTo.length > 0
                            ? Array.prototype.map
                                .call(
                                  assignedTo,
                                  (item) => item.firstName + item.lastName
                                )
                                .join(",")
                            : "N/A"}
                        </p>
                      </div>
                    </Col>

                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3">Updated At:</p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {" "}
                          {new Date(updatedAt).toISOString().slice(0, 10)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> Created On:</p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {" "}
                          {new Date(createdAt).toISOString().slice(0, 10)}
                        </p>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> Tags:</p>{" "}
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {tags.length > 0
                            ? Array.prototype.map
                                .call(tags, (item) => item.name)
                                .join(",")
                            : "N/A"}{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-between">
                      <div className="d-flex justify-content-around">
                        <p className="fs-3"> Type:</p>
                        <p className="text-secondary mx-2 fs-3 text-center">
                          {typeLabel ? typeLabel : "N/A"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ActionItemPDF;
