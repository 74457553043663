import React, { useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { destroy } from "utils/DeApi";

const GeoTagFileDelete = ({ id, setGeoLocation, onGeoTagUpdate }) => {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();

  const [isExpanding, setIsExpanding] = useState(false);

  const handleDeleteLocation = () => {
    setError(null);
    setIsExpanding(true);
    const geoTagPromise = destroy(`/media/${id}`);
    geoTagPromise.promise
      .then(() => {
        setGeoLocation((prev) => {
          onGeoTagUpdate({
            ...prev,
            images: prev.images.filter((image) => image.id !== id),
          });
          return {
            ...prev,
            images: prev.images.filter((image) => image.id !== id),
          };
        });
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsExpanding(false));
    subscribedPromises.current.push(geoTagPromise);
  };

  error && console.log(error);
  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        className={isExpanding ? "ms-2 border-0" : "ms-2"}
        onClick={() => handleDeleteLocation()}
        disabled={isExpanding}
      >
        {isExpanding ? (
          <Spinner animation="border" size="sm" variant="dark" />
        ) : (
          <span translate="no" className="material-symbols-outlined md-18 ">
            delete
          </span>
        )}
      </Button>
    </>
  );
};

GeoTagFileDelete.propTypes = {
  id: PropTypes.number.isRequired,
  setGeoLocation: PropTypes.func.isRequired,
  onGeoTagUpdate: PropTypes.func,
};

export default GeoTagFileDelete;
