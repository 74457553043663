import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { Button, Col, Modal, Navbar, Row } from "react-bootstrap";

import { get } from "utils/BeeApi";
import ContentDetails from "features/contents/components/ContentDetails";

import DefaultHelpView from "./DefaultHelpView/DefaultHelpView";

import "./Help.css";

const Help = () => {
  const category = process.env.REACT_APP_HELP_CATEGORY_ID;

  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchContents, setSearchContents] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const [contentId, setContentId] = useState(undefined);

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const handleHelpContents = () => {
    setError("");
    setIsLoading(true);

    const helpContentsPromise = get(`contents`, {
      params: {
        category,
        limit: 100,
      },
    });

    helpContentsPromise.promise
      .then((response) => {
        const contents = response.data;
        setSearchContents(contents);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(helpContentsPromise);
  };

  const handleSearchContents = () => {
    if (!searchTerm) return;

    setError("");
    setIsLoading(true);
    setIsSearching(true);

    const searchContentsPromise = get(
      `/search/contents?q=${category}+AND+${searchTerm}&type=bool&offset=0&limit=10`
    );

    searchContentsPromise.promise
      .then((response) => {
        const contents = response.data;
        setSearchContents(contents);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(searchContentsPromise);
  };

  const handleShowModal = () => {
    setShow(true);
    handleHelpContents();
  };

  const handleCloseModal = () => {
    setShow(false);
    setError("");
    setSearchContents(undefined);
    setContentId(undefined);
  };

  const handleSetSearchTearm = ({ target: { value } }) => {
    setSearchTerm(value);

    if (!value) {
      handleHelpContents();
      setIsSearching(false);
    }
  };

  const handleSetContentId = (tmpContentId) => {
    setContentId(tmpContentId);
  };

  return (
    <>
      <Navbar.Text className="px-2 me-2 pointer mt-1" onClick={handleShowModal}>
        <span translate="no" className="material-symbols-outlined md-22 me-2">
          help
        </span>
        {/* */}
        Help
      </Navbar.Text>
      <Modal show={show} onHide={handleCloseModal} className="right" size="xl">
        <Modal.Header closeButton className="bg-light">
          <Modal.Title as={"div"}>
            <h4>Help Center</h4>
            <small className="mb-4">
              Articles for getting the most out of ERM Assess.
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {contentId ? (
            <>
              <Row className="mb-5 mt-0">
                <Col>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => handleSetContentId(undefined)}
                  >
                    <span
                      role="button"
                      translate="no"
                      className="material-symbols-outlined "
                    >
                      arrow_back
                    </span>{" "}
                    Back to Articles
                  </Button>
                </Col>
              </Row>
              <ContentDetails contentId={contentId} />
            </>
          ) : (
            <DefaultHelpView
              handleSetSearchTearm={handleSetSearchTearm}
              handleSearchContents={handleSearchContents}
              handleSetContentId={handleSetContentId}
              isLoading={isLoading}
              error={error}
              isSearching={isSearching}
              searchTerm={searchTerm}
              searchContents={searchContents}
            />
          )}
        </Modal.Body>
        {contentId && (
          <Modal.Footer>
            <div className="col text-center">
              <Link
                to={`/contents/${contentId}`}
                target="_blank"
                rel="noreferrer"
              >
                View content on full screen
              </Link>{" "}
              <a
                href={`/contents/${contentId}`}
                target="_blank"
                rel="noreferrer"
                aria-label="open in new window"
              >
                <span className="material-symbols-outlined ">open_in_new</span>
              </a>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default Help;
