import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const CHART_HEIGHT = 270;

const RadioFields = ({ data }) => {
  const el = useRef(null);

  useEffect(() => {
    if (!data.length) {
      return;
    }
    const chartData = data?.map((item) => {
      return {
        name: item.option,
        value: item.count,
      };
    });

    const sum = chartData?.reduce((prev, current) => prev + current.value, 0);

    const dimension = {
      width: el.current.offsetWidth,
      height: CHART_HEIGHT,
    };

    const option = {
      title: {
        textStyle: {
          color: "grey",
          fontSize: 15,
          fontWeight: "lighter",
        },

        text: `Total: ${sum} `,
        left: "center",
        top: "bottom",
      },
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}<br />{d0}%",
      },

      series: [
        {
          name: "",
          type: "pie",
          radius: "50%",
          label: {
            show: true,
            formatter: "{b},{c} ({d}%)",

            edgeDistance: "1%",
          },
          labelLine: {
            length: 10,
            length2: 10,
          },

          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          percentPrecision: 1,
          data: chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    if (!data) {
      option.title = {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      };
    }

    const chart = echarts.init(el.current, null, dimension);

    chart.setOption(option);

    function handleResize() {
      chart.resize(dimension);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data]);

  return (
    <div className="ReportRadioField">
      <div className="chart-container">
        <div className="w-100" ref={el} />
      </div>
    </div>
  );
};

export default RadioFields;
