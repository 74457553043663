import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import moment from "moment";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useToast } from "hooks";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import DataExport from "components/DataExport";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import AuditListItem from "./AuditListItem";
import GroupTypeahead from "../GroupTypeahead";
import AuditListExport from "./AuditListExport";
import AuditCreate from "../AuditCreate/AuditCreate";
import ProtocolTypeahead from "../ProtocolTypeahead";
import MetaPagination from "components/ui/MetaPagination";

const AuditList = () => {
  const toast = useToast();
  const { facilityId } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState([]);
  const [protocol, setProtocol] = useState([]);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [audits, setAudits] = useState([]);

  const [date, setDate] = useState({
    startCreateDate: null,
    endCreateDate: null,
    startDueDate: null,
    endDueDate: null,
  });

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState("");

  const { startCreateDate, endCreateDate, startDueDate, endDueDate } = date;

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updated_at",
    updatedAt: true,
    sortAsc: false,
  });

  const handleError = (err) => setError(err);

  const sortingColumns = [
    {
      index: 0,
      name: "Sort By",
      value: "",
    },
    {
      index: 1,
      name: "Name",
      value: "name",
    },

    {
      index: 2,
      name: "Updated",
      value: "updated_at",
    },
  ];

  const onDateChange = (dates, startDate, endDate) => {
    const [start, end] = dates;

    setDate((prev) => ({
      ...prev,
      [startDate]: start,
      [endDate]: end,
    }));
    setPage(1);
  };

  const sendSearchQuery = useRef(
    debounce((q) => {
      setDebouncedQuery(q);
      setPage(1);
    }, 1000)
  );

  const handleAuditUpdated = (updatedAudit) => {
    toast.success("Success", "Assessment updated successfully");
    setAudits((prevAudits) =>
      prevAudits.map((audit) => {
        if (audit?.auditId === updatedAudit?.auditId)
          return {
            ...audit,
            ...updatedAudit,
          };
        return audit;
      })
    );
  };

  const handleAuditDeleted = (deletedAudit) => {
    toast.success("Success", "Assessment deleted successfully");
    setAudits((prevAudits) =>
      prevAudits.filter((audit) => {
        return audit?.auditId !== deletedAudit?.auditId;
      })
    );
  };

  const handleDateFormatting = (startDate, endDate) => {
    if (!startDate && !endDate) return [];
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    return [start, end];
  };

  const fetchAudits = useCallback(() => {
    const [start, end] = handleDateFormatting(startCreateDate, endCreateDate);
    const [startDue, endDue] = handleDateFormatting(startDueDate, endDueDate);
    setError(null);
    setIsLoading(true);
    const auditsPromise = get(`facilities/${facilityId}/audits`, {
      params: {
        "filter[created_at]":
          startCreateDate && endCreateDate ? `${start},${end}` : "",
        "filter[due_date]":
          startDueDate && endDueDate ? `${startDue},${endDue}` : "",
        "filter[protocol]":
          Array.isArray(protocol) && protocol[0]
            ? `${protocol[0]?.protocolId}`
            : "",
        "filter[audit_group]":
          Array.isArray(group) && group[0] ? `${group[0]?.id}` : "",
        "filter[name]": debouncedQuery ? debouncedQuery : "",
        page: page ? page : 1,
        perPage: perPage || 10,
        include: "protocol,facility,labels",
        sort: "-updated_at",
      },
    });

    auditsPromise.promise
      .then(({ data: audits, meta }) => {
        setMeta(meta);
        setAudits(audits);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setTimeout(() => setIsLoading(false), 500));

    subscribedPromises.current.push(auditsPromise);
  }, [
    startCreateDate,
    endCreateDate,
    startDueDate,
    endDueDate,
    facilityId,
    protocol,
    group,
    debouncedQuery,
    page,
    perPage,
  ]);

  const sortAudits = useCallback(
    (sortingvalue) => {
      const [start, end] = handleDateFormatting(startCreateDate, endCreateDate);
      setError(null);
      setIsLoading(true);
      setTableSortState({
        sortAsc: !tableSortState.sortAsc,
        sortColumn: sortingvalue,
      });

      const auditsPromise = get(`facilities/${facilityId}/audits`, {
        params: {
          "filter[created_at]":
            startCreateDate && endCreateDate ? `${start},${end}` : "",
          "filter[protocol]":
            Array.isArray(protocol) && protocol[0]
              ? `${protocol[0]?.protocolId}`
              : "",
          "filter[name]": debouncedQuery ? debouncedQuery : "",
          page: page ? page : 1,
          perPage: perPage || 10,
          include: "protocol,facility",
          sort: !tableSortState.sortAsc ? sortingvalue : `-${sortingvalue}`,
        },
      });

      auditsPromise.promise
        .then(({ data: audits, meta }) => {
          setMeta(meta);
          setAudits(audits);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(auditsPromise);
    },
    [
      startCreateDate,
      endCreateDate,
      tableSortState.sortAsc,
      facilityId,
      protocol,
      debouncedQuery,
      page,
      perPage,
    ]
  );

  useEffect(() => {
    fetchAudits();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchAudits]);

  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-row mb-4 flex-wrap">
          <div className="flex-grow-1">
            <h2 className="pt-1 mb-0">Assessments</h2>
          </div>
          <Button
            size="sm"
            as={Link}
            to={`groups`}
            className="me-2"
            variant="outline-primary"
          >
            Assessment Groups{" "}
            <span translate="no" className="material-symbols-outlined md-18">
              arrow_forward
            </span>
          </Button>
          <Dropdown translate="no" autoClose="outside" className="me-2 ">
            <Dropdown.Toggle
              size="sm"
              variant="outline-primary"
              className="w-100 text-start"
            >
              {isLoading ? (
                <Spinner className="me-2" animation="border" size="sm" />
              ) : (
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 me-2"
                >
                  download
                </span>
              )}{" "}
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DataExport variant="" level={"entity"} />
              <Dropdown.Divider className="my-2" />
              <AuditListExport
                handleError={handleError}
                params={() => ({ "filter[facility_id]": facilityId })}
              />
            </Dropdown.Menu>
          </Dropdown>
          <AuditCreate
            facilityId={facilityId}
            onAuditCreated={(audit) => {
              navigate(`/audits/${audit?.auditId}`);
            }}
          />
        </div>
        <Form as={Row}>
          <Form.Group as={Col} lg={4} md={12} sm={12} xs={12} className="mb-3">
            <Form.Control
              type="text"
              value={query}
              onChange={(e) => {
                const q = e.target.value;
                setQuery(q);
                sendSearchQuery.current(q);
              }}
              placeholder="Search"
            />
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <ProtocolTypeahead
              onChange={(protocol) => {
                setProtocol(protocol);
                setPage(1);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <GroupTypeahead
              facilityId={facilityId}
              onChange={(group) => {
                setGroup(group);
                setPage(1);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={startCreateDate}
              onChange={(date) => {
                onDateChange(date, "startCreateDate", "endCreateDate");
              }}
              startDate={startCreateDate}
              endDate={endCreateDate}
              selectsRange
              placeholderText="Filter by date range"
              monthsShown={2}
              className="form-control"
              isClearable
            >
              <Button
                size="sm"
                className="mb-1"
                onClick={() => {
                  setDate((prev) => ({
                    ...prev,
                    startCreateDate: "",
                    endCreateDate: "",
                  }));
                }}
              >
                Clear
              </Button>
            </DatePicker>
          </Form.Group>
          <Form.Group as={Col} lg={4} md={6} sm={12} xs={12} className="mb-3">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={startDueDate}
              onChange={(date) => {
                onDateChange(date, "startDueDate", "endDueDate");
              }}
              startDate={startDueDate}
              endDate={endDueDate}
              selectsRange
              placeholderText="Filter by due date range"
              monthsShown={2}
              className="form-control"
              isClearable
            >
              <Button
                size="sm"
                className="mb-1"
                onClick={() => {
                  setDate((prev) => ({
                    ...prev,
                    startDueDate: "",
                    endDueDate: "",
                  }));
                }}
              >
                Clear
              </Button>
            </DatePicker>
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={4} md={3} lg={3} className="mb-3">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-sorting"
                className="w-100 text-start border border-gray-900 bg-white text-secondary"
              >
                {tableSortState.sortColumn.includes("name")
                  ? "Name"
                  : "Updated"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortingColumns.map((s) => (
                  <Dropdown.Item
                    key={s.index}
                    disabled={s.index === 0}
                    active={s.value === tableSortState.sortColumn}
                    onClick={() => !isLoading && sortAudits(s.value)}
                  >
                    {s.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
        <Table hover>
          <thead>
            <tr>
              <th
                className={`w-30 pointer ${
                  tableSortState.sortColumn === "name" ? "pb-1" : ""
                }`}
                onClick={() => !isLoading && sortAudits("name")}
              >
                Name
                {tableSortState.sortColumn === "name" && (
                  <span
                    translate="no"
                    className="material-symbols-outlined mx-1 md-18 text-muted"
                  >
                    {tableSortState.sortAsc && "arrow_upward"}
                    {!tableSortState.sortAsc && "arrow_downward"}
                  </span>
                )}
              </th>
              <th>Protocol</th>
              <th>Group</th>
              <th
                className={`pointer ${
                  tableSortState.sortColumn === "updated_at" ? "pb-1" : ""
                }`}
                onClick={() => !isLoading && sortAudits("updated_at")}
              >
                Updated
                {tableSortState.sortColumn === "updated_at" && (
                  <span
                    translate="no"
                    className="material-symbols-outlined mx-1 md-18 text-muted"
                  >
                    {tableSortState.sortAsc && "arrow_upward"}
                    {!tableSortState.sortAsc && "arrow_downward"}
                  </span>
                )}
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {isLoading ? (
              <tr>
                <td colSpan={8}>
                  <Loader />
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={8}>
                  <ErrorHandler error={error} />
                </td>
              </tr>
            ) : Array.isArray(audits) && !audits.length ? (
              <tr>
                <td colSpan={8}>
                  <EmptyStateHandler
                    title="No assessments"
                    description="Assessments will appear here once added"
                  />
                </td>
              </tr>
            ) : (
              <Fragment />
            )}
            {audits &&
              audits.map((audit) => (
                <AuditListItem
                  key={audit?.auditId}
                  audit={audit}
                  onAuditUpdated={handleAuditUpdated}
                  onAuditDeleted={handleAuditDeleted}
                />
              ))}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <MetaPagination
          meta={meta}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      </Card.Footer>
    </Card>
  );
};

AuditList.propTypes = {};

export default AuditList;
