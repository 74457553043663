import React from "react";
import * as Sentry from "@sentry/react";
import { ForgotPassword } from "features/authentication";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const ForgotPasswordPage = () => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("location", "ForgotPasswordPage");
      }}
      fallback={({ error, resetError }) => (
        <ErrorBoundaryFallback error={error} resetError={resetError} />
      )}
    >
      {" "}
      <ForgotPassword />
    </Sentry.ErrorBoundary>
  );
};

export default ForgotPasswordPage;
