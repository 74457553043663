import React, { useContext, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

import { OrganizationContext } from "contexts/OrganizationProvider";
import { useToast } from "hooks";
import { saveAs } from "file-saver";
import { post } from "utils/DeApi";
import { useFormikContext } from "formik";

const DownloadObservation = ({
  disable,
  name,
  size,
  className,
  icon,
  variant,
}) => {
  const organization = useContext(OrganizationContext);
  const {
    values: { protocolId, assessments },
    setFieldValue,
  } = useFormikContext();

  const toast = useToast();

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);

  const downloadThroughEmail = () => {
    setFieldValue("error", null);
    setIsLoading(true);

    const downloadObservationPromise = post(
      `/organizations/${organization?.id}/stream-csv`,
      {
        protocol_id: protocolId,
        assessments: assessments,
      }
    );

    downloadObservationPromise.promise
      .then((resp) => {
        toast.success("Success", "Your File has been downloaded successfully");
        const fileName = `${organization?.name}.csv`;

        let type = "data:text/csv;charset=UTF-8,%EF%BB%BF;";
        let CSVBlob = new Blob([resp], { type: type });

        saveAs(CSVBlob, fileName);
      })
      .catch((error) => !error.isCanceled && setFieldValue("error", error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(downloadObservationPromise);
  };

  return (
    <Button
      variant={variant || "primary"}
      size={size || ""}
      className={className || ""}
      onClick={downloadThroughEmail}
      disabled={disable}
    >
      {isLoading ? (
        <Spinner
          className="me-2"
          animation="border"
          size="sm"
          variant="light"
        />
      ) : (
        !!icon && (
          <span className="material-symbols-outlined md-18">download</span>
        )
      )}{" "}
      {name}
    </Button>
  );
};

export default DownloadObservation;
