import { Container } from "react-bootstrap";

import * as Sentry from "@sentry/react";
import { Reports } from "features/reports";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const ReportsPage = () => {
  return (
    <Container className="my-2 MainContent">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "ReportsPage");
        }}
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      >
        <Reports />
      </Sentry.ErrorBoundary>
    </Container>
  );
};

export default ReportsPage;
