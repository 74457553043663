import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ImagePreviewModal = ({ description, image }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        className="border-dark border-opacity-25 mx-2"
        onClick={handleShow}
      >
        <span className="md-18 material-symbols-outlined ">visibility</span>
      </Button>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Image Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">{description}</p>
          <img src={image} alt={"preview"} fluid className="w-100" />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImagePreviewModal;
