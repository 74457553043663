import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import * as yup from "yup";
import { useParams } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";

import { upload } from "utils/DeApi";
import { compressImage } from "utils/UploadUtils";
import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";
import { useAuditMapping } from "features/assessment/hooks";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useCreateOrUpdateObservation } from "features/assessment/services";
import {
  convertToMB,
  SUPPORTED_FORMATS,
  SupportedTypesMaxSize,
} from "utils/UploadUtils";

import FileUpdate from "../FileUpdate/FileUpdate";
import FileDelete from "../FileDelete/FileDelete";
import { prepSubmitObservationValues } from "../QuestionUtils";
import ImagePreviewModal from "./ImagePreviewModal/ImagePreviewModal";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

const MAX_FILE_SIZE = 15728640;

const FileUpload = ({ disabled, observation, manuscriptQuestionId }) => {
  const { audit } = useContext(AuditContext);
  const { questionId, auditId } = useParams();
  const organization = useContext(OrganizationContext);
  const { values } = useFormikContext();
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const { responseUpdateMapping, observationCreateOrUpdateMapping } =
    useAuditMapping();

  const {
    error: isExpandingError,
    isLoading: isExpanding,
    handleOnSubmit,
  } = useCreateOrUpdateObservation({
    onObservationSuccess: (observation) => {
      observationCreateOrUpdateMapping(observation);
    },
    onResponsesSuccess: (responses, observation) => {
      responseUpdateMapping(responses, observation);
    },
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseWarningModal = () => setShowWarningModal(false);
  const handleShowWarningModal = () => setShowWarningModal(true);

  const createFile = (file, description) => {
    setError(null);
    setIsLoading(true);

    const uploadData = new FormData();

    uploadData.append("file", file);
    uploadData.append("description", description);
    uploadData.append("audit_id", auditId);
    uploadData.append("observation_id", observation.observationId);

    const auditPromise = upload("files", uploadData);
    auditPromise.promise
      .then(({ data }) => {
        handleClose();
        observationCreateOrUpdateMapping({
          ...observation,
          attachments: observation?.attachments
            ? [...observation?.attachments, data]
            : [data],
        });
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  const createObservation = () => {
    const formData = prepSubmitObservationValues(values, {
      auditId,
      parentId: null,
      questionId: manuscriptQuestionId || questionId,
      observationId: observation.observationId,
    });
    handleOnSubmit(formData);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [organization]);

  const schema = yup.object().shape({
    description: yup
      .string()
      .min(2, "Description is too Short!")
      .max(255, "Description is too Long!")
      .required("Description is required"),
    file: yup
      .mixed()
      .required("A file is required")
      .test(
        "is-valid-size",
        "Max allowed size is 15MB",
        (value) => value && value.size < MAX_FILE_SIZE
      ),
  });

  const onFileUpload = async (e, setFieldValue) => {
    let _file = e.currentTarget.files[0] || {};
    let type = _file.type || "";
    if (type.startsWith("image")) _file = await compressImage(_file);
    setFieldValue("file", _file);
    if (typeof _file === "object") setFieldValue("name", _file.name);
  };

  const onFileUpdated = (file) =>
    observationCreateOrUpdateMapping({
      ...observation,
      attachments: observation?.attachments.map((f) =>
        f.fileId === file.fileId
          ? {
              ...f,
              description: file.description,
            }
          : f
      ),
    });

  const onFileDeleted = (file) =>
    observationCreateOrUpdateMapping({
      ...observation,
      attachments: observation?.attachments.filter(
        (f) => f.fileId !== file.fileId
      ),
    });

  isExpandingError && console.error(isExpandingError);

  if (!audit?.protocol.hasFiles) return <></>;

  return (
    <>
      <div className="d-flex flex-row  align-items-center py-3 border-opacity-10">
        <h6 className="flex-fill mb-0">
          <strong>
            Files & Photo ·{" "}
            <span className="text-muted">
              {observation?.attachments?.length ?? 0}
              <span className="visually-hidden">Action Items count</span>
            </span>
          </strong>
        </h6>

        <Button
          className="text-start bg-primary bg-opacity-25 rounded-3 text-dark"
          variant="light"
          onClick={
            observation?.observationId !== undefined
              ? handleShow
              : handleShowWarningModal
          }
          disabled={disabled || isExpanding}
          size="sm"
        >
          <span translate="no" className="material-symbols-outlined me-2 md-18">
            file_upload
          </span>
          Attach file
          {isExpanding && (
            <Spinner
              className="ms-3 mt-2 float-end"
              animation="border"
              size="sm"
              variant="primary"
            />
          )}
        </Button>
      </div>

      <hr className="py-0 my-0" />

      {observation?.attachments?.length ? (
        <>
          {observation?.attachments.map((f, index, arr) => {
            const fileURL = f.url || "";
            const fileEXT = getURLFileExtension(fileURL);
            return (
              <Fragment key={f.fileId}>
                <div className="d-flex flex-row py-2">
                  <div className="me-3">
                    <a href={fileURL} target="_blank" rel="noreferrer">
                      <span
                        className={`fiv-sqo fiv-size-md lh-base fiv-icon-${fileEXT}`}
                      ></span>
                    </a>
                  </div>
                  <div className="flex-fill pt-1">
                    <a
                      href={f.url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-trancate"
                    >
                      {f.name || f.description}
                    </a>
                  </div>
                  <div className="d-flex">
                    <div>
                      {["png", "jpg", "jpeg", "svg"].includes(fileEXT) ? (
                        <ImagePreviewModal
                          image={f.url}
                          description={f.description}
                        />
                      ) : null}
                    </div>
                    {!disabled && (
                      <>
                        <div>
                          <FileUpdate
                            fileId={f.fileId}
                            file={f}
                            onFileUpdated={onFileUpdated}
                          />
                        </div>
                        <div>
                          <FileDelete file={f} onFileDeleted={onFileDeleted} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {arr.length !== ++index && (
                  <Col>
                    <hr className="my-0 py-1" />
                  </Col>
                )}
              </Fragment>
            );
          })}
        </>
      ) : (
        <EmptyStateHandler
          className={` d-flex flex-row mx-1 my-3`}
          title="No Files Found"
          description="Files will appear here once added"
        />
      )}

      <Modal
        show={showWarningModal && !observation?.observationId}
        onHide={handleCloseWarningModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmptyStateHandler
            className={` d-flex flex-row mx-1 my-3`}
            title="Observation not Saved"
            description="You can attach files once you have saved the observation"
          />
          {isExpandingError && <ErrorHandler error={isExpandingError} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            className="px-3"
            variant="outline-primary"
            onClick={handleCloseWarningModal}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            type="submit"
            className="px-3"
            disabled={isExpanding}
            onClick={createObservation}
          >
            {isExpanding && (
              <Spinner
                size="sm"
                variant="light"
                className="me-2"
                animation="border"
              />
            )}{" "}
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show && observation?.observationId !== undefined}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>New File</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { file, description } = values;
            createFile(file, description);
          }}
          initialValues={{
            file: "",
            description: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="file" className="mb-3">
                  <Form.Label>
                    Upload File <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={(e) => onFileUpload(e, setFieldValue)}
                    onBlur={(e) => onFileUpload(e, setFieldValue)}
                    isInvalid={
                      (!(values.file && !errors.file) && touched.file) ||
                      (values?.file && values?.file?.size > MAX_FILE_SIZE)
                    }
                    isValid={
                      values.file &&
                      !errors.file &&
                      values?.file?.size < MAX_FILE_SIZE
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.file && touched.file && (
                      <small>{errors.file}</small>
                    )}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Text muted>
                  <SupportedTypesMaxSize
                    formats={SUPPORTED_FORMATS}
                    maxSize={convertToMB(MAX_FILE_SIZE)}
                  />
                </Form.Text>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>
                    Description
                    <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !(values.description && !errors.description) &&
                      touched.description
                    }
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={
                    !values.name ||
                    isLoading ||
                    !isValid ||
                    values?.file?.size > MAX_FILE_SIZE
                  }
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Upload File
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default FileUpload;
