import ErrorHandler from "components/ui/ErrorHandler";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import useDeleteMemo from "features/assessment/services/useDeleteMemo";

const AuditMemoDelete = ({ memo, onMemoDelete }) => {
  const [show, setShow] = useState(false);
  const [isChecked, setIsConfirmationChecked] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { error, isLoading, deleteMemo } = useDeleteMemo({
    memoId: memo?.id,
    onMemoDeleted: () => {
      handleClose();
      onMemoDelete(memo);
    },
  });

  useEffect(() => {
    if (!show) setIsConfirmationChecked(false);
  }, [show]);

  return (
    <div>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={handleShow}
        className="ms-2"
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>{" "}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">
            Delete Notepad -{" "}
            <small translate="no" className="text-muted">
              {memo?.key}
            </small>
          </h2>
          <p className="mx-4 mt-2">
            <Form.Group>
              <Form.Check
                label={
                  <small>
                    By checking the box, I confirm that I want to delete this
                    Notepad. I understand that this cannot be undone.
                  </small>
                }
                onClick={() => {
                  setIsConfirmationChecked(!isChecked);
                }}
              />
            </Form.Group>
          </p>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="sm"
            variant="danger"
            onClick={deleteMemo}
            disabled={!isChecked || isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AuditMemoDelete;
