import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Badge, Form, Spinner, Table } from "react-bootstrap";
import { get, put } from "utils/DeApi";

function AuditLock() {
  const { isAdmin } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [error, setError] = useState();
  const [locked, setLocked] = useState(audit?.locked);
  const [activities, setActivities] = useState(audit?.activities || []);

  const updateAudit = (lock) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = put(`audits/${audit?.auditId}/lock`, {
      locked: lock,
    });
    auditPromise.promise
      .then(({ data: audit }) => {
        setAudit((prevState) => {
          return {
            ...prevState,
            locked: audit?.locked,
          };
        });
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const fetchAudit = () => {
      setIsExpanding(true);
      const auditsPromise = get(`audits/${audit?.auditId}`);
      auditsPromise.promise
        .then(({ data: audit }) => {
          setActivities(audit?.activities);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsExpanding(false));
      subscribedPromises.current.push(auditsPromise);
    };

    if (isAdmin) fetchAudit();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [audit?.auditId, isAdmin]);

  if (!isAdmin) return <></>;

  return (
    <>
      <div className="d-flex flex-row align-items-center w-100 mb-3">
        <div className="flex-fill">
          <Form.Switch
            disabled={isLoading}
            value={Boolean(locked)}
            checked={Boolean(locked)}
            label={<h5>Make assessment view only </h5>}
            onChange={() => {
              setLocked((prevState) => {
                updateAudit(!prevState);
                return !prevState;
              });
            }}
          />
        </div>
        {isLoading && (
          <Spinner
            className="ms-2 float-end"
            animation="border"
            size="sm"
            variant="primary"
          />
        )}{" "}
      </div>
      {error && <ErrorHandler error={error} />}
      {isExpanding && <Loader />}
      {Array.isArray(activities) ? (
        <div className="bg-light rounded">
          {activities.length ? (
            <Table>
              <thead>
                <tr>
                  <th>Causer</th>
                  <th>Action</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {activities.map((trail, index) => (
                  <tr key={index}>
                    <td>
                      <small>
                        {trail?.causer?.firstName ?? "-"}{" "}
                        {trail?.causer?.lastName ?? "-"}
                      </small>
                      <br />
                      <small className="text-muted">
                        {trail?.causer?.email ?? "-"}
                      </small>
                    </td>
                    <td>
                      <small>
                        {trail.type === "created" && (
                          <Badge className="bg-primary rounded-0 bg-opacity-10 text-primary">
                            CREATED
                          </Badge>
                        )}
                        {trail.type === "updated" && (
                          <Badge className="bg-warning rounded-0 bg-opacity-10 text-warning">
                            UPDATED
                          </Badge>
                        )}
                        {trail.type === "deleted" && (
                          <Badge className="bg-danger rounded-0 bg-opacity-10 text-danger">
                            DELETED
                          </Badge>
                        )}
                      </small>
                    </td>
                    <td>
                      <small>
                        {trail.oldProperties.locked ? (
                          <Badge className="bg-secondary rounded-0 bg-opacity-10 text-secondary">
                            <span
                              translate="no"
                              className="material-symbols-outlined md-16 me-2"
                            >
                              lock
                            </span>
                            VIEW ONLY
                          </Badge>
                        ) : (
                          <Badge className="bg-primary rounded-0 bg-opacity-10 text-primary">
                            <span
                              translate="no"
                              className="material-symbols-outlined md-16 me-2"
                            >
                              no_encryption
                            </span>
                            OPEN
                          </Badge>
                        )}
                      </small>
                    </td>
                    <td>
                      <small>
                        {trail.newProperties.locked ? (
                          <Badge className="bg-secondary rounded-0 bg-opacity-10 text-secondary">
                            <span
                              translate="no"
                              className="material-symbols-outlined md-16 me-2"
                            >
                              lock
                            </span>
                            VIEW ONLY
                          </Badge>
                        ) : (
                          <Badge className="bg-primary rounded-0 bg-opacity-10 text-primary">
                            <span
                              translate="no"
                              className="material-symbols-outlined md-16 me-2"
                            >
                              no_encryption
                            </span>
                            OPEN
                          </Badge>
                        )}
                      </small>
                    </td>
                    <td>
                      <small>
                        {new Date(trail.createdAt).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert variant="info" className={`my-3 d-flex flex-row`}>
              <div className="me-3">
                <span
                  translate="no"
                  className="material-symbols-outlined md-18 text-primary text-opacity-75"
                >
                  info
                </span>
              </div>
              <div>
                <h5 className="mb-1">
                  <small>No lock activities</small>
                </h5>
                <p className="mb-1">
                  <small>
                    There are currently no assessment lock activities to show.
                  </small>
                </p>
              </div>
            </Alert>
          )}
        </div>
      ) : null}
    </>
  );
}

AuditLock.propTypes = {
  audit: PropTypes.object.isRequired,
  onAuditLocked: PropTypes.func.isRequired,
};

export default AuditLock;
