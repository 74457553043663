import { useContext, useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { useRoles } from "hooks";
import { Collapse, Spinner } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import { UserContext } from "contexts/UserProvider";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import ActionItemCreate from "features/actionItems/components/common/ActionItemCreate";
import ActionItemCard from "features/actionItems/components/common/ActionItemCard";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

function QuestionActionItem({ actionItems, setActionItems }) {
  const user = useContext(UserContext);
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const { auditId, questionId } = useParams();
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [meta, setMeta] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { audit } = useContext(AuditContext);

  const { isCertifier } = useRoles();

  useEffect(() => {
    const fetchActionItems = () => {
      setIsLoading(true);
      setError(null);
      const subscribersPromise = get(
        `subscribers/${user?.de?.subscriberId}/action-items`,
        {
          params: {
            perPage: 5,
            organizationId: organization?.id,
            "filter[type]": "question",
            "filter[question_id]": questionId,
            "filter[audit_id]": auditId,
          },
        }
      );

      subscribersPromise.promise
        .then((response) => {
          setActionItems(response.data);
          setMeta(response?.meta);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(subscribersPromise);
    };

    if (!isCertifier && audit?.protocol?.hasActionItems) fetchActionItems();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [
    organization,
    user,
    isCertifier,
    auditId,
    questionId,
    setActionItems,
    audit?.protocol?.hasActionItems,
  ]);

  if (isCertifier) return <></>;
  if (!audit?.protocol?.hasActionItems) return <></>;

  return (
    <>
      <div className="d-flex flex-row">
        <h5
          onClick={() => setOpen((prevState) => !prevState)}
          role="button"
          className="mb-0 flex-fill"
        >
          {open ? (
            <span translate="no" className="material-symbols-outlined me-2">
              expand_more
            </span>
          ) : (
            <span translate="no" className="material-symbols-outlined me-2">
              expand_less
            </span>
          )}
          Action Items ·{" "}
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <span className="text-muted">
              {meta?.total ?? 0}
              <span className="visually-hidden">Action Items count</span>
            </span>
          )}
        </h5>
        <div>
          <ActionItemCreate
            description={""}
            auditId={auditId}
            facilityId={audit?.facilityId}
            taskableId={questionId}
            taskableType={"question"}
            onActionItemCreated={(newActionItem) => {
              setMeta((prev) => ({ ...prev, total: ++prev.total }));
              setActionItems((prevActionItems) => [
                newActionItem,
                ...prevActionItems,
              ]);
            }}
          />
        </div>
      </div>
      <Collapse in={open}>
        <div className="ps-2 mt-2">
          {isLoading ? (
            <Loader />
          ) : error ? (
            <ErrorHandler error={error} />
          ) : actionItems?.length ? (
            <ActionItemCard
              facilityId={audit?.facilityId}
              taskableId={questionId}
              taskableType={"question"}
              className="w-50 mb-2"
              actionItems={actionItems}
              setActionItems={setActionItems}
              to={`/action-items?taskableType=question&taskableId=${questionId}&facilityId=${audit?.facilityId}&auditId=${auditId}`}
            />
          ) : (
            <EmptyStateHandler
              title="No action items found"
              description="There are currently no action items to show at the Question level."
            />
          )}
        </div>
      </Collapse>
      <hr />
    </>
  );
}

QuestionActionItem.propTypes = {};

export default QuestionActionItem;
