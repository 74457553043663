import { useContext } from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { showConformityLevel } from "features/assessment/components/Audit/helper";

import FieldClearButton from "../FieldClearButton";

const FieldConformityInapplicable = ({ isDisabled }) => {
  const { audit } = useContext(AuditContext);
  const { conformity, questionOptions } = audit?.protocol;
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();
  const _showConformityLevel = showConformityLevel(conformity, questionOptions);

  if (!_showConformityLevel) return <></>;
  if (parseInt(values.conformityLevel) !== 4) return <></>;

  const clearSelection = () => {
    setFieldValue("conformityInapplicableReasons", "");
  };

  return (
    <Form.Group controlId="conformity-inapplicable" className="my-3">
      <Form.Label className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <strong>Why is the conformity level not applicable?</strong>
            <RequiredAsterisk />
          </div>
          <FieldClearButton
            handleOnClick={clearSelection}
            isDisabled={isDisabled}
          />
        </div>
      </Form.Label>
      <Form.Control
        rows={3}
        as="textarea"
        onBlur={handleBlur}
        onChange={handleChange}
        name="conformityInapplicableReasons"
        value={values.conformityInapplicableReasons}
        disabled={values.isInapplicable || isDisabled}
        placeholder="Enter your reasons here."
      />
    </Form.Group>
  );
};

FieldConformityInapplicable.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default FieldConformityInapplicable;
