import { useContext } from "react";

import PropTypes from "prop-types";

import { AuditContext } from "features/assessment/contexts/AuditProvider";

import { questionIsAnswered } from "../../../helper";

const QuestionIsDone = ({ cypressId, observations = [] }) => {
  const { audit } = useContext(AuditContext);
  if (observations.length === 0) return <></>;

  const allDone = observations.every((observation) =>
    questionIsAnswered(audit, observation)
  );

  if (audit?.protocol?.hasObservationSets) {
    const partiallyDone = observations.some((observation) =>
      questionIsAnswered(audit, observation)
    );

    if (allDone) {
      return (
        <span
          translate="no"
          className="material-symbols-outlined float-end mb-1"
          data-cy={cypressId}
        >
          done_all
        </span>
      );
    }

    if (partiallyDone) {
      return (
        <span
          translate="no"
          className="material-symbols-outlined float-end mb-1"
          data-cy={cypressId}
        >
          done
        </span>
      );
    }
  }

  if (allDone)
    return (
      <span
        translate="no"
        data-cy={cypressId}
        className="material-symbols-outlined float-end mb-1"
      >
        done
      </span>
    );

  return <></>;
};

QuestionIsDone.propTypes = {
  observations: PropTypes.array.isRequired,
  cypressId: PropTypes.string.isRequired,
};

export default QuestionIsDone;
