import { useState, useCallback, useEffect, useRef } from "react";
import moment from "moment";
import { get } from "utils/DeApi";

export function useFetchEditHistory(observationId, show) {
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const subscribedPromises = useRef([]);

  const fetchEditHistory = useCallback(async () => {
    if (!observationId) {
      setHistoryData([]);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await get(
        `/observations/${observationId}/activity-logs`
      );
      if (
        response &&
        response.promise &&
        typeof response.promise.then === "function"
      ) {
        const result = await response.promise;

        if (result && result.data && Array.isArray(result.data)) {
          const { data } = result;

          const transformLog = (log, index, array) => {
            try {
              const {
                id,
                type,
                subjectType,
                createdAt,
                causer,
                newProperties,
                oldProperties,
                response,
                observation,
              } = log;

              const formatValue = (value) => {
                if (value === null || value === undefined || value === "null") {
                  return "Unanswered";
                }
                return typeof value === "object"
                  ? JSON.stringify(value)
                  : String(value);
              };

              const excludedFields = [
                "confidential",
                "parentId",
                "extras",
                "label",
              ];

              let baseEntry = {
                id,
                type: subjectType,
                changeType: type,
                timestamp: moment(createdAt).format("M/D/YY, h:mm A"),
                userId: causer.id,
                userFirstName: causer.firstName,
                userLastName: causer.lastName,
              };

              if (subjectType === "response") {
                baseEntry = {
                  ...baseEntry,
                  field: response?.flexibleQuestion?.prompt || "Unknown Field",
                  observationId:
                    response?.observationId || newProperties?.observationId,
                  questionId: response?.questionId,
                  responseId: response?.id,
                };

                const changes = [];

                const oldValue = formatValue(
                  oldProperties?.notes || oldProperties?.answerIdValue
                );
                let newValue = formatValue(
                  newProperties?.notes || newProperties?.answerIdValue
                );

                if (oldValue !== newValue) {
                  changes.push({ ...baseEntry, oldValue, newValue });
                }

                const oldDateTime = oldProperties?.responseDatetime
                  ? moment(oldProperties.responseDatetime).format(
                      "M/D/YY, h:mm A"
                    )
                  : "Unanswered";
                let newDateTime = newProperties?.responseDatetime
                  ? moment(newProperties.responseDatetime).format(
                      "M/D/YY, h:mm A"
                    )
                  : "Unanswered";

                if (oldDateTime !== newDateTime) {
                  changes.push({
                    ...baseEntry,
                    field:
                      response?.flexibleQuestion?.prompt || "Date and Time",
                    oldValue: oldDateTime,
                    newValue: newDateTime,
                  });
                }

                return changes;
              } else if (subjectType === "observation") {
                return Object.keys(newProperties)
                  .filter((field) => !excludedFields.includes(field))
                  .map((field) => {
                    const oldValue = formatValue(oldProperties?.[field]);
                    let newValue = formatValue(newProperties[field]);

                    if (field === "isInapplicable") {
                      const isInitialSetting = index === array.length - 1;

                      if (!isInitialSetting && oldValue !== newValue) {
                        return {
                          ...baseEntry,
                          field: "Is Inapplicable",
                          oldValue,
                          newValue,
                          observationId:
                            observation?.observationId ||
                            newProperties?.observationId,
                          questionId: observation?.question?.questionId,
                        };
                      }
                      return null;
                    }

                    return oldValue !== newValue
                      ? {
                          ...baseEntry,
                          field,
                          oldValue,
                          newValue,
                          observationId:
                            observation?.observationId ||
                            newProperties?.observationId,
                          questionId: observation?.question?.questionId,
                        }
                      : null;
                  })
                  .filter(Boolean);
              }

              return [];
            } catch (error) {
              console.error("Error transforming log:", log, error);
              return [
                {
                  id: log.id,
                  type: "error",
                  field: "Error",
                  oldValue: "Error getting old value",
                  newValue: error.message,
                  timestamp: moment(log.createdAt).format("M/D/YY, h:mm A"),
                },
              ];
            }
          };

          const transformedLogs = data
            .flatMap(transformLog)
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

          setHistoryData(transformedLogs);
        } else {
          throw new Error("Unexpected data format from API");
        }
      } else {
        throw new Error("Unexpected response format from get function");
      }
    } catch (error) {
      console.error("Error fetching activity logs:", error);
      setError(error.message || "Error fetching activity logs");
    } finally {
      setIsLoading(false);
    }
  }, [observationId]);

  useEffect(() => {
    fetchEditHistory();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        if (promise.cancel) promise.cancel();
      });
    };
  }, [show]);

  return { historyData, isLoading, error, refetch: fetchEditHistory };
}
