import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import * as yup from "yup";
import PropTypes from "prop-types";
import { put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { useToast } from "hooks";

const NotePadUpdate = ({ memo, onMemoUpdate, setShowUpdateForm }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup
      .string()
      .required("The Description is required")
      .min(2, "Description is too Short!"),
  });

  const updateMemo = (values) => {
    const { name, description } = values;
    setIsLoading(true);
    const memoPromise = put(`/memos/${memo?.id}`, {
      key: name,
      value: description,
    });
    memoPromise.promise
      .then(({ data }) => {
        onMemoUpdate(data);
        setShowUpdateForm("");
        setIsLoading(false);
        toast.success("Success", "Notepad has been updated successfully");
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
    subscribedPromises.current.push(memoPromise);
  };

  return (
    <>
      <Formik
        onSubmit={updateMemo}
        validationSchema={schema}
        initialValues={{
          name: memo?.key || "",
          description: memo?.value || "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                name="name"
                type="text"
                placeholder="Title"
                isInvalid={!(values.name && !errors.name) && touched.name}
                isValid={values.name && !errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name && touched.name ? (
                  <small>{errors.name}</small>
                ) : null}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                name="description"
                as="textarea"
                row={2}
                placeholder="Take a note..."
                isInvalid={
                  !(values.description && !errors.description) &&
                  touched.description
                }
                isValid={values.description && !errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description && touched.description ? (
                  <small>{errors.description}</small>
                ) : null}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="text-end my-3">
              <Button
                size="sm"
                variant="outline-primary"
                className="me-2"
                onClick={() => setShowUpdateForm("")}
              >
                Cancel
              </Button>
              {isLoading ? (
                <Spinner
                  className="mt-2"
                  animation="border"
                  size="sm"
                  variant="dark"
                />
              ) : (
                <Button
                  type="submit"
                  size="sm"
                  variant="outline-primary"
                  className="px-3"
                >
                  Update
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {error && <ErrorHandler error={error} />}
    </>
  );
};
NotePadUpdate.propTypes = {
  memo: PropTypes.object,
  onMemoUpdate: PropTypes.func,
  setShowUpdateForm: PropTypes.func,
};
export default NotePadUpdate;
