import ProtocolTypeahead from "features/assessment/components/common/ProtocolTypeahead";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Form, Spinner } from "react-bootstrap";

const ProtocolField = ({ reset }) => {
  const {
    values: { protocolId, protocol, isLoading },
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const resetFields = () => {
    setFieldValue("sortValue", {
      index: 0,
      name: "A - Z",
      value: "name",
    });
    setFieldValue("assessments", []);
    setFieldTouched("assessments", false, false);
    setFieldValue("audits", []);
    reset();
  };

  const handleProtocolChanges = (protocolId) => {
    resetFields();
    setFieldValue("protocolId", protocolId);
  };

  return (
    <Col>
      <Form.Group controlId="protocol" className="mt-0 mb-3">
        <Form.Label className="mb-1 me-2 fw-semibold ">
          Select Protocol
          {isLoading && (
            <Spinner
              className="ms-2"
              animation="border"
              size="sm"
              variant="dark"
            />
          )}
        </Form.Label>
        <ProtocolTypeahead
          placeholder="Select Protocol"
          onChange={(protocols = []) => {
            const [{ protocolId } = {}] = protocols;
            setFieldValue("protocol", protocols);
            handleProtocolChanges(protocolId);
          }}
          isValid={protocolId && !errors.protocolId}
          isInvalid={!(protocolId && !errors.protocolId) && touched.protocolId}
          selected={protocol}
        />
      </Form.Group>
    </Col>
  );
};

export default ProtocolField;
