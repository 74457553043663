import { Col, Row } from "react-bootstrap";

import CheckboxFields from "./CheckboxFields/CheckboxFields";

function OrganizationCheckboxField({ data }) {
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="mb-4">
          <p className="text-center w-100 text-truncate">
            Distribution of Findings by {data?.fieldName || "Risk Rating"}
          </p>
          <hr />
          <CheckboxFields data={data} />
        </Col>
      </Row>
    </>
  );
}

export default OrganizationCheckboxField;
