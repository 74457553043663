import { useContext } from "react";

import { useRoles } from "hooks";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { AuditContext } from "features/assessment/contexts/AuditProvider";

const AuditReportLink = () => {
  const { audit } = useContext(AuditContext);
  const { isAdmin, isContributor } = useRoles();

  if (isContributor) return <></>;
  if (audit?.confidential && !isAdmin) return <></>;

  return (
    <Button
      variant="primary"
      size="sm"
      as={Link}
      to={`/audits/${audit.auditId}/reports`}
      className="d-flex justify-content-center align-items-center position-relative"
    >
      <span>Assessment Report</span>
      <span translate="no" className="material-symbols-outlined md-18 ms-1">
        east
      </span>
      {!!audit?.observationReportCommentsCount > 0 && (
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {audit?.observationReportCommentsCount}
          <span className="visually-hidden">unread messages</span>
        </span>
      )}
    </Button>
  );
};

export default AuditReportLink;
