import { useRef, useState, useEffect } from "react";
import { upload } from "utils/DeApi";
import { useParams } from "react-router-dom";
import { useToast } from "hooks";
const useUploadAuditFiles = ({ onFileUploaded }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const subscribedPromises = useRef([]);
  const { auditId } = useParams();
  const toast = useToast();
  const uploadAuditFiles = (files, description) => {
    setError(null);
    setIsLoading(true);

    const filesPromises = files.map((file) => {
      const uploadData = new FormData();

      uploadData.append("file", file);
      uploadData.append("description", description);

      const auditFilePromise = upload(`audits/${auditId}/files`, uploadData);
      subscribedPromises.current.push(auditFilePromise);

      return auditFilePromise.promise;
    });

    Promise.all(filesPromises)
      .then((response) => {
        onFileUploaded(response.map(({ data }) => data));
        toast.success("Success", "Files has been uploaded successfully");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          toast.error("Error", "Something went wrong");
        }
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);
  return { isLoading, error, uploadAuditFiles };
};

export default useUploadAuditFiles;
