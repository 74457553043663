import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Col, Dropdown, Form, Row } from "react-bootstrap";

import { get } from "utils/DeApi";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useRoles } from "hooks";

const sortBy = [
  {
    index: 0,
    name: "A - Z",
    value: "name",
  },
  {
    index: 1,
    name: "Z - A",
    value: "-name",
  },
];

const AssessmentList = ({ reset }) => {
  const {
    values: {
      protocolId,
      assessments,
      search,
      sortValue,
      updatedAt,
      audits,
      isLoading,
      level,
      facilityId,
    },
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();

  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const { isMember, isAdmin } = useRoles();

  const [query, setQuery] = useState("");

  const sendSearchQuery = useRef(
    debounce(({ q, key }) => {
      setFieldValue(key, q);
    }, 1000)
  );

  const facilityIdExists = isAdmin
    ? facilityId && level === "entity"
    : facilityId;

  const fetchAudits = useCallback(
    (selectAll = false) => {
      setFieldValue("error", null);
      setFieldValue("isLoading", true);
      const auditsPromise = get(`/audits`, {
        params: {
          organizationId: organization?.id,
          ...(protocolId
            ? {
                "filter[protocol_id]": protocolId,
              }
            : {}),
          ...(facilityIdExists
            ? {
                "filter[facility_id]": facilityId,
              }
            : {}),
          ...(search ? { "filter[search]": search } : {}),
          ...(updatedAt ? { "filter[updated_at]": updatedAt } : {}),
          sort: sortValue?.value ?? "name",
          perPage: 1000000000000000,
        },
      });
      auditsPromise.promise
        .then(({ data: audits }) => {
          if (selectAll) {
            return setFieldValue(
              "assessments",
              Array.isArray(audits)
                ? audits
                    .filter(
                      ({ organizationId }) =>
                        organizationId === organization?.id
                    )
                    .map(({ auditId }) => auditId)
                : []
            );
          }
          setFieldValue(
            "audits",
            Array.isArray(audits)
              ? audits.filter(
                  ({ organizationId }) => organizationId === organization?.id
                )
              : []
          );
        })
        .catch((error) => !error.isCanceled && setFieldValue("error", error))
        .finally(() => setFieldValue("isLoading", false));

      subscribedPromises.current.push(auditsPromise);
    },
    [
      facilityId,
      facilityIdExists,
      organization?.id,
      protocolId,
      search,
      setFieldValue,
      sortValue?.value,
      updatedAt,
    ]
  );

  const handleChange = (checked, auditId) => {
    reset();
    if (checked) return setFieldValue("assessments", [...assessments, auditId]);
    setFieldValue(
      "assessments",
      assessments.filter((id) => id !== auditId)
    );
  };

  useEffect(() => {
    if (protocolId && isAdmin) fetchAudits();
    if (protocolId && isMember && facilityId) fetchAudits();
  }, [facilityId, fetchAudits, isAdmin, isMember, protocolId]);

  if (!protocolId) return <></>;

  return (
    <Row>
      <Col xs={12}>
        <Form.Group controlId="assessments" className="mt-0 mb-1">
          {Array.isArray(audits) && !!audits?.length ? (
            <>
              <Row className="d-flex align-items-center">
                <Col xs={4}>
                  <Form.Label className="mt-1 me-2 fw-semibold ">
                    Select Assessments
                  </Form.Label>
                </Col>
                <Col
                  xs={8}
                  className="d-flex align-items-center justify-content-end"
                >
                  Sort By:
                  <Dropdown className="">
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="dropdown-done"
                      className="ms-2 text-start border border-gray-900 bg-white text-secondary"
                    >
                      {sortValue?.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="min-width-auto w-fixed-84 ms-2 text-center">
                      {sortBy.map((s) => (
                        <Dropdown.Item
                          key={s.index}
                          active={s.index === sortValue.index}
                          onClick={() => setFieldValue("sortValue", s)}
                          className="w-100"
                        >
                          {s.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="assessments" className="mt-2 mb-3">
                    <Form.Control
                      type="text"
                      value={query}
                      onChange={(e) => {
                        const q = e.target.value;
                        setQuery(q);
                        sendSearchQuery.current({ q, key: "search" });
                      }}
                      placeholder="Search By Assessment"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Check
                    className="mb-2"
                    disabled={isLoading}
                    onChange={(e) => {
                      reset();
                      const { checked } = e.target;
                      if (!checked) return setFieldValue("assessments", []);
                      fetchAudits(true);
                    }}
                    inline
                    label="Select All"
                    name="select-all"
                    type="checkbox"
                    id="select-all"
                  />
                </Col>
                {audits?.map(({ auditId, name }) => (
                  <Col xs={12} className={"mb-2"} key={auditId}>
                    <Form.Check
                      disabled={isLoading}
                      checked={assessments.includes(auditId)}
                      onChange={(e) => handleChange(e.target.checked, auditId)}
                      inline
                      label={name}
                      name="assessments"
                      type="checkbox"
                      id={auditId}
                    />
                  </Col>
                ))}
              </Row>
            </>
          ) : isAdmin ? (
            <EmptyStateHandler
              title="No Assessments Found"
              description="Try using a different Entity or Protocol."
            />
          ) : (
            isMember &&
            facilityId && (
              <EmptyStateHandler
                title="No Assessments Found"
                description="Try using a different protocol."
              />
            )
          )}

          {errors.assessments && touched.assessments ? (
            <small className="text-danger">{errors.assessments}</small>
          ) : null}
        </Form.Group>
      </Col>
    </Row>
  );
};

AssessmentList.propTypes = {
  reset: PropTypes.func,
};

export default AssessmentList;
