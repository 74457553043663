import { Pagination } from "react-bootstrap";

const SearchPagination = ({ totalPages, page, setPage }) => {
  const items = [];

  items.push(
    <Pagination.Item key={1} active={1 === page} onClick={() => setPage(1)}>
      1
    </Pagination.Item>
  );

  if (page > 3) {
    items.push(<Pagination.Ellipsis key="start-ellipsis" disabled />);
  }

  const startPage = Math.max(2, page - 1);
  const endPage = Math.min(totalPages - 1, page + 1);

  for (let i = startPage; i <= endPage; i++) {
    items.push(
      <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
        {i}
      </Pagination.Item>
    );
  }

  if (page < totalPages - 1) {
    items.push(<Pagination.Ellipsis key="end-ellipsis" disabled />);
  }

  if (totalPages > 1) {
    items.push(
      <Pagination.Item
        key={totalPages}
        active={totalPages === page}
        onClick={() => setPage(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  return items;
};

export default SearchPagination;
