import { useContext } from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { showNotes } from "features/assessment/components/Audit/helper";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import FieldClearButton from "../FieldClearButton";
import ActionItemCreate from "features/actionItems/components/common/ActionItemCreate";

const FieldFindings = ({ isDisabled, setActionItems, showActionItem }) => {
  const { audit } = useContext(AuditContext);
  const { auditId, questionId } = useParams();
  const { protocol, facilityId } = audit;
  const { conformity, questionOptions } = protocol;
  const [, , , findingLabel, , , ,] = questionOptions || [];
  const _showNotes = showNotes(conformity, questionOptions);
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();

  if (!_showNotes) return <></>;

  const clearSelection = () => {
    setFieldValue("notes", "");
  };

  return (
    <Form.Group controlId="findings" className="my-3">
      <Form.Label className="d-flex justify-content-between align-items-center">
        <div>
          <strong>{findingLabel?.name || "Findings"}</strong>
          <RequiredAsterisk />
        </div>
        <div className="d-flex align-items-center">
          {showActionItem && (
            <ActionItemCreate
              variant="light"
              className="text-nowrap float-none me-2 bg-primary bg-opacity-10 text-dark"
              auditId={auditId}
              title="New Action Item"
              taskableId={questionId}
              taskableType={"question"}
              disabled={!values["notes"]}
              description={values["notes"] || ""}
              onActionItemCreated={(item) => {
                setActionItems((prevState) => [item, ...prevState]);
              }}
              facilityId={facilityId}
            />
          )}
          <FieldClearButton
            handleOnClick={clearSelection}
            isDisabled={isDisabled}
          />
        </div>
      </Form.Label>
      <Form.Control
        rows={3}
        as="textarea"
        name="notes"
        onBlur={handleBlur}
        value={values.notes}
        onChange={handleChange}
        disabled={values.isInapplicable || isDisabled}
        placeholder="Enter your answer here"
      />
    </Form.Group>
  );
};

FieldFindings.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  setActionItems: PropTypes.func.isRequired,
  showActionItem: PropTypes.bool.isRequired,
};

export default FieldFindings;
