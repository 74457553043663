import React from "react";
import { Alert } from "react-bootstrap";
import PropTypes from "prop-types";
const GeoTagError = ({ className }) => {
  return (
    <Alert variant="warning" className={`my-3 d-flex flex-row ${className}`}>
      <div className="me-3">
        <span translate="no" className="material-symbols-outlined md-18">
          warning
        </span>
      </div>
      <div>
        <h5 className="mb-1">
          <small>Location is not accessible</small>
        </h5>
        <p className="mb-1">
          <small>
            Please provide access to location to get current longitude and
            latitude.
          </small>
        </p>
      </div>
    </Alert>
  );
};
GeoTagError.propTypes = { className: PropTypes.string };
export default GeoTagError;
