import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import TextLogo from "components/ui/TextLogo";
import Copyright from "components/ui/Copyright";

import "./MainFixedOffset.scss";

const MainFixedOffset = ({ children }) => {
  return (
    <Container className="MainFixedOffset my-5">
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          sm={{ span: 8, offset: 2 }}
        >
          <TextLogo />
          {children}
          <Row>
            <Col xs={12} className="my-3">
              <p className="text-center mb-1">
                <small>
                  <Copyright />
                </small>
              </p>
              <p className="text-center  text-muted">
                <small>v {process.env.REACT_APP_RELEASE_VERSION}</small>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MainFixedOffset;
