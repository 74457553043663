import { useState } from "react";

import PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";

import VERTICALS from "config/VERTICALS";
import ExpandFullScreen from "components/ui/ExpandFullScreen";
import ContentDetails from "features/contents/components/ContentDetails";

const CSRD_VERTICAL_ID = process.env.REACT_APP_VERTICAL_ID;

const HelpResource = ({ infoId }) => {
  const [openHelpResource, setOpenHelpResource] = useState(false);
  const headerVerbiage =
    VERTICALS[CSRD_VERTICAL_ID]?.["labels"]?.[
      "Helpful Information & Resources"
    ] ?? "Helpful Information & Resources";

  if (!infoId) return <></>;

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5
          onClick={() => setOpenHelpResource((prevState) => !prevState)}
          role="button"
        >
          {openHelpResource ? (
            <span translate="no" className="material-symbols-outlined me-2">
              expand_more
            </span>
          ) : (
            <span translate="no" className="material-symbols-outlined me-2">
              expand_less
            </span>
          )}
          {headerVerbiage}
        </h5>
        <span>
          <ExpandFullScreen title={headerVerbiage}>
            <ContentDetails contentId={infoId} />
          </ExpandFullScreen>
        </span>
      </div>

      <Collapse in={openHelpResource}>
        <div className="ps-3 mt-2">
          <ContentDetails contentId={infoId} showContentTitle={false} />
        </div>
      </Collapse>

      <hr />
    </>
  );
};

HelpResource.propTypes = {
  infoId: PropTypes.string,
};

export default HelpResource;
