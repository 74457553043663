import React from "react";

import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";

import ProfileDetails from "./ProfileDetails";

const Profile = () => {
  return (
    <>
      <Helmet>
        <title>Profile Page</title>
      </Helmet>
      <Routes>
        <Route path="*" element={<ProfileDetails />} />
      </Routes>
    </>
  );
};

export default Profile;
