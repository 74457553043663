import React from "react";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ActionItemCard from "features/actionItems/components/common/ActionItemCard";
import ActionItemCreate from "features/actionItems/components/common/ActionItemCreate";
import useFetchFacilityActionItems from "features/entity/services/useFetchFacilityActionItems";

function FacilityActionItem({ facilityId }) {
  const { isCertifier } = useRoles();
  const { error, isLoading, meta, actionItems, setMeta, setActionItems } =
    useFetchFacilityActionItems({
      facilityId,
    });

  if (isCertifier) return <></>;

  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex-fill">
          <h2>
            Action Items ·{" "}
            <small className="text-muted">
              {!isLoading && <> {meta?.total ?? 0}</>}
            </small>{" "}
          </h2>
          <p>Entity Level</p>
        </div>
        <div>
          <ActionItemCreate
            taskableId={facilityId}
            taskableType={"facility"}
            onActionItemCreated={(newActionItem) => {
              setMeta((prev) => ({ ...prev, total: ++prev.total }));
              setActionItems((prevActionItems) => [
                newActionItem,
                ...prevActionItems,
              ]);
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : actionItems?.length ? (
        <ActionItemCard
          taskableId={facilityId}
          taskableType={"facility"}
          className="w-75 mb-2"
          actionItems={actionItems?.filter(({ status }) => status !== 3)}
          setActionItems={setActionItems}
          to={`/action-items?taskableType=facility&taskableId=${facilityId}`}
        />
      ) : (
        <EmptyStateHandler
          title="No action items found"
          description="There are currently no action items to show at the Entity level."
        />
      )}
    </>
  );
}

FacilityActionItem.propTypes = {
  facilityId: PropTypes.string.isRequired,
};

export default FacilityActionItem;
