import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import { FlexibleFields } from "features/assessment/constants";

import LabelScore from "../LabelScore";

const { CHECKBOX_FIELD } = FlexibleFields;

const FieldCheckbox = ({ question, value, isDisabled }) => {
  const { values, setFieldValue } = useFormikContext();
  if (question?.properties?.fieldType !== "checkbox") return <></>;

  const maxLabelLength = Math.max(
    ...question.choices.map(({ label = "" }) => parseInt(label.length))
  );

  return (
    <>
      {question.choices.length > 2 && (
        <Form.Check
          id={"all"}
          value={"All"}
          type="switch"
          name={question.id}
          label={"Select All"}
          inline={maxLabelLength < 30}
          className={maxLabelLength > 30 ? "mb-2" : ""}
          disabled={values.isInapplicable || isDisabled}
          checked={question.choices
            .map(
              ({ id }) =>
                Array.isArray(value?.response) && value?.response.includes(id)
            )
            .every(Boolean)}
          onChange={(ev) => {
            const selectAll = question.choices.map(({ id }) => id) ?? [];
            setFieldValue(`dynamic.${question.id}`, {
              type: CHECKBOX_FIELD,
              response: selectAll
                .map(
                  (id) =>
                    Array.isArray(value?.response) &&
                    value?.response.includes(id)
                )
                .every(Boolean)
                ? []
                : selectAll,
            });
          }}
        />
      )}
      {question.choices.map(({ label, score, id }) => {
        return (
          <Form.Check
            label={
              <>
                {label} <LabelScore score={score} />
              </>
            }
            id={id}
            key={id}
            value={id}
            type="checkbox"
            name={question.id}
            inline={maxLabelLength < 30}
            className={maxLabelLength > 30 ? "mb-2" : ""}
            disabled={values.isInapplicable || isDisabled}
            checked={
              Array.isArray(value?.response) && value?.response.includes(id)
            }
            onChange={(ev) => {
              const selected = ev.target.value;
              const updatedValues = Array.isArray(value?.response)
                ? ev.target.checked
                  ? [...value?.response, selected]
                  : value?.response.filter((id) => id !== selected)
                : [selected];

              setFieldValue(`dynamic.${question.id}`, {
                type: 2,
                response: updatedValues,
              });
            }}
          />
        );
      })}
    </>
  );
};
FieldCheckbox.propTypes = {
  question: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
export default FieldCheckbox;
