import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import * as yup from "yup";
import PropTypes from "prop-types";

import DataLink from "../DataLink";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import AssessmentList from "../AssessmentsList";
import { useCopyToClipboard, useRoles } from "hooks";
import ProtocolField from "../ProtocolField";
import DateField from "../DateField";
import { useParams } from "react-router-dom";
import DataExportButton from "../DataExportButton";

const AdminOrOwnerDataExport = ({ variant, level = "" }) => {
  const { facilityId } = useParams();
  const organization = useContext(OrganizationContext);
  const [show, setShow] = useState(false);
  const [links, setLinks] = useState([]);
  const [copiedLink, setCopiedLink] = useState("");

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    reset();
  };

  const { isCertifier } = useRoles();
  const { handleCopy, setIsCopied } = useCopyToClipboard();

  const reset = () => {
    setLinks([]);
    setCopiedLink("");
  };
  const onCopyLink = (link) => {
    handleCopy(link);
    setCopiedLink(link);
  };

  const schema = yup.object().shape({
    protocolId: yup.string().required("Protocol is required"),
    assessments: yup.array().min(1).required("Assessment is required"),
  });

  useEffect(() => {
    if (!links?.length) setIsCopied(false);
  }, [links, setIsCopied]);

  if (isCertifier) return <></>;

  return (
    <>
      <DataExportButton
        variant={variant}
        level={level}
        handleShow={handleShow}
      />
      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Formik
          validationSchema={schema}
          initialValues={{
            protocol: [],
            protocolId: "",
            assessments: [],
            search: "",
            sortValue: {
              index: 0,
              name: "A - Z",
              value: "name",
            },
            level,
            isLoading: false,
            error: null,
            audits: [],
            facilityId,
          }}
        >
          {({ handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  Data Export - {organization?.name}{" "}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="vh-100">
                <p className="">
                  Data Export allows you to export observations level data
                  either as CSV or a Data Link for integration with Business
                  Intelligence Tools. The Data links provides data in a JSON
                  format.
                </p>
                <>
                  <ProtocolField reset={reset} />
                  <DateField reset={reset} />
                  <AssessmentList reset={reset} />
                </>
                {Array.isArray(links) && !!links.length && (
                  <div className="mb-2">
                    <Form.Group>
                      <Form.Label className="mb-1 me-2 fw-semibold ">
                        Data Link{" "}
                        <p className="text-muted mb-0 fw-normal">
                          <small>
                            <span
                              translate="no"
                              className="material-symbols-outlined md-18"
                            >
                              info
                            </span>{" "}
                            Assessment are divided into multiple links. Single
                            link contains maximum 50 assessments.
                          </small>
                        </p>
                      </Form.Label>
                      {links?.map((link, ind) => (
                        <div key={ind} className="d-flex flex-row mb-2">
                          <div className="flex-fill">
                            <Form.Control
                              type="text"
                              readOnly
                              disabled
                              value={link}
                            />
                          </div>
                          <div>
                            <Button
                              onClick={() => onCopyLink(link)}
                              size="sm"
                              className="ms-2"
                              variant="outline-secondary"
                            >
                              <span
                                translate="no"
                                className="material-symbols-outlined md-18 me-2"
                              >
                                content_copy
                              </span>
                              {copiedLink === link ? "Copied!" : "Copy"}
                            </Button>
                          </div>
                        </div>
                      ))}
                    </Form.Group>
                    {values?.error && <ErrorHandler error={values?.error} />}
                  </div>
                )}
              </Offcanvas.Body>
              <DataLink setLinks={setLinks} />
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </>
  );
};

AdminOrOwnerDataExport.propTypes = {
  variant: PropTypes.string,
  level: PropTypes.string,
  auditList: PropTypes.array,
};

export default AdminOrOwnerDataExport;
