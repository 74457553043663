import { useContext } from "react";
import React, { Fragment, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { useToast } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { destroy } from "utils/DeApi";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";

const SignatureDelete = ({ signature, onSignatureDeleted }) => {
  const subscribedPromises = useRef([]);
  const toast = useToast();
  const organization = useContext(OrganizationContext);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup.string().required().matches(signature?.name),
  });

  const deleteSignature = () => {
    setError("");
    setIsLoading(true);

    const signaturePromise = destroy(`signatures/${signature?.id}`);

    signaturePromise.promise
      .then(() => {
        handleClose();
        onSignatureDeleted(signature);
        toast.show("Success", "The signature has been deleted successfully");
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(signaturePromise);
  };

  if (organization?.invitation?.role === "Certifier") return <Fragment />;

  return (
    <>
      <Button variant="outline-danger" size="sm" onClick={handleShow}>
        <span translate="no" className="material-symbols-outlined md-18">
          delete
        </span>
      </Button>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            Delete signature ·{" "}
            <small translate="no" className="text-muted">
              {signature.name}
            </small>
          </Modal.Title>
        </Modal.Header>

        {signature && (
          <Formik
            validationSchema={schema}
            onSubmit={(values) => {
              deleteSignature(values);
            }}
            initialValues={{
              name: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isValid,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <p>Are you sure you want to delete this signature?</p>

                  <Form.Group controlId="productName" className="mt-3 mb-3">
                    <Form.Label>
                      Please type the name of the signatory to confirm.{" "}
                      <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={values.name && !errors.name}
                      isInvalid={!(values.name && !errors.name) && touched.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name && touched.name ? (
                        <small>{errors.name}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {error && <ErrorHandler error={error} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    variant="danger"
                    disabled={
                      values.name.trim() !== signature.name ||
                      !isValid ||
                      isLoading
                    }
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Delete
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

SignatureDelete.propTypes = {
  signature: PropTypes.object.isRequired,
  onSignatureDeleted: PropTypes.func.isRequired,
};

export default SignatureDelete;
