import { useContext, useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { post } from "utils/DeApi";
import VERTICALS from "config/VERTICALS";
const useDownloadAuditCSV = (audit) => {
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const VERTICAL_ID = process.env.REACT_APP_VERTICAL_ID;
  const generatedFileNameShouldOnlyHaveAssessmentName =
    VERTICALS[VERTICAL_ID]?.generatedFileNameShouldOnlyHaveAssessmentName ??
    false;
  const {
    protocolId = "",
    auditId = "",
    protocol: { sections = [] } = {},
  } = audit;
  const questionIds = sections
    .flatMap(({ questions = {} }) => questions)
    .map(({ questionId = "" }) => questionId);
  const downloadObservation = () => {
    setError(null);
    setIsLoading(true);
    const downloadObservationPromise = post(
      `/organizations/${organization?.id}/csv`,
      {
        protocol_id: protocolId,
        assessments: [auditId],
        question_ids: questionIds,
      }
    );

    downloadObservationPromise.promise
      .then((resp) => {
        setIsLoading(false);
        const fileName = generatedFileNameShouldOnlyHaveAssessmentName
          ? `${audit.name}.csv`
          : `${organization.name} ${audit.name} Assessment Report.csv`;
        let type = "data:text/csv;charset=UTF-8,%EF%BB%BF;";
        let CSVBlob = new Blob([resp], { type: type });

        saveAs(CSVBlob, fileName);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(downloadObservationPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, downloadObservation };
};

export default useDownloadAuditCSV;
