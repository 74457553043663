import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Typeahead } from "react-bootstrap-typeahead";
import { get } from "utils/DeApi";

import "./AssessmentTypeahead.scss";

const AssessmentTypeahead = ({
  onChange,
  isValid,
  selected,
  facilityId,
  placeholder = "",
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [assessment, setAssessment] = useState(
    Array.isArray(selected) ? selected : []
  );
  const [assessments, setAssessments] = useState([]);

  error && console.error(error);

  useEffect(() => {
    function fetchAssessments() {
      setIsLoading(true);
      const assessmentsPromise = get(`facilities/${facilityId}/audits`, {
        params: { perPage: 1000 },
      });
      assessmentsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setAssessments(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(assessmentsPromise);
    }

    if (facilityId) fetchAssessments();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [facilityId]);

  return (
    <Typeahead
      id="assessment-typeahead"
      clearButton
      placeholder={placeholder || "Filter by Assessment"}
      isLoading={isLoading}
      labelKey="name"
      disabled={!facilityId}
      onChange={(data) => {
        onChange(data);
        setAssessment(data);
      }}
      isValid={Boolean(isValid)}
      options={assessments}
      selected={assessment}
    />
  );
};

AssessmentTypeahead.propTypes = {
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.any,
  selected: PropTypes.array,
  facilityId: PropTypes.string,
  placeholder: PropTypes.string,
};

export default AssessmentTypeahead;
