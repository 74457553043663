import React, { useContext, useState } from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Offcanvas, Table } from "react-bootstrap";

import useFetchMemos from "features/assessment/services/useFetchMemos";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import AuditMemoCreate from "./AuditMemoCreate";
import AuditMemoListItem from "./AuditMemoListItem";

import "./AuditMemos.scss";

const AuditMemos = () => {
  const { isCertifier } = useRoles();
  const { audit } = useContext(AuditContext);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { error, isLoading, memos, setMemos } = useFetchMemos({
    auditId: audit?.auditId,
    fetchByDefault: show && audit?.protocol?.hasNotepad && !isCertifier,
  });

  const onMemoCreate = (data) => {
    setMemos((prev) => [...prev, data]);
  };

  if (isCertifier) return <></>;
  if (!audit?.protocol?.hasNotepad) return <></>;

  return (
    <>
      <Button
        variant="secondary"
        className="sticky-bottom float-end fab shadow"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-18">
          message
        </span>{" "}
        Notepad
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="w-fixed-640"
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Notepad</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="table-responsive">
          <div className="d-flex flex-row mb-3">
            <div className="flex-grow-1"></div>
            <span className="float-end">
              <AuditMemoCreate onMemoCreate={onMemoCreate} />
            </span>
          </div>
          <Table hover>
            <thead>
              <tr>
                <th>Title</th>
                <th>Note</th>
                <th>Created</th>
                <th>Updated</th>
                {!isCertifier && <th>Action</th>}
              </tr>
            </thead>
            <tbody className="table-group-divider">
              <AuditMemoListItem
                error={error}
                memos={memos}
                setMemos={setMemos}
                isLoading={isLoading}
              />
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
AuditMemos.prototypes = {
  show: PropTypes.bool.isRequired,
};
export default AuditMemos;
