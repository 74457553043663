import React, { useContext, useEffect, useState } from "react";

import { Card, Table } from "react-bootstrap";

import MetaPagination from "components/ui/MetaPagination";
import ActionItemFilters from "features/actionItems/components/ActionItems/ActionItemFilters";
import ActionItemFileExport from "features/actionItems/components/common/ActionItemFileExport";
import ActionItemListItem from "features/actionItems/components/ActionItems/ActionItemListItem";
import ActionItemTableHeader from "features/actionItems/components/ActionItems/ActionItemTableHeader.js";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context/ActionItemProvider";

import "./MyActionItem.scss";

const MyActionItem = () => {
  const dispatch = useContext(ActionItemsDispatchContext);
  const { filters, meta } = useContext(ActionItemsContext);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handleError = (err) => {
    dispatch({ type: "SET_ERROR", payload: err });
  };

  useEffect(() => {
    dispatch({ type: "SET_PAGE", payload: page });
  }, [dispatch, page]);

  useEffect(() => {
    dispatch({ type: "SET_PER_PAGE", payload: perPage });
    dispatch({ type: "SET_PAGE", payload: 1 });
  }, [dispatch, perPage]);

  return (
    <>
      <div className="d-flex flex-row mb-3 flex-wrap align-items-center justify-content-end">
        <div>
          <ActionItemFileExport
            endpoint="my-action-items-export"
            handleError={handleError}
            filters={filters}
          />
        </div>
      </div>
      <ActionItemFilters />
      <div className="table-responsive mt-2">
        <Table className="cursor-default">
          <ActionItemTableHeader />
          <ActionItemListItem />
        </Table>
      </div>
      <Card.Footer>
        <MetaPagination
          meta={meta}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card.Footer>
    </>
  );
};

MyActionItem.propTypes = {};

export default MyActionItem;
