import { useContext, useRef, useState, useEffect } from "react";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { get } from "utils/DeApi";

const useFetchEntities = ({ perPage = 1000 }) => {
  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    function fetchEntities() {
      setIsLoading(true);
      const entitiesPromise = get(
        `organizations/${organization?.id}/facilities`,
        {
          params: { perPage: perPage },
        }
      );
      entitiesPromise.promise
        .then((response) => {
          setEntities(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
          }
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(entitiesPromise);
    }

    fetchEntities();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id, perPage]);

  return {
    error,
    entities,
    isLoading,
  };
};

export default useFetchEntities;
