import React, { useContext, useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Alert, Button, Card, Form, Spinner } from "react-bootstrap";

import { put } from "utils/BeeApi";
import { UserContext } from "contexts/UserProvider";
import MainFixedOffset from "layouts/MainFixedOffset";
import ErrorHandler from "components/ui/ErrorHandler";
import RedirectIfAuthenticated from "../common/RedirectIfAuthenticated";

import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const subscribedPromises = useRef([]);

  const handleSubmit = (email) => {
    setError("");
    setIsLoading(true);

    const createPromise = put("/login", {
      action: "forgot",
      email,
    });
    createPromise.promise
      .then((response) => {
        setError("");
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPromise);
  };

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
  });

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => promises.forEach((promise) => promise.cancel());
  }, []);

  if (user) return <RedirectIfAuthenticated isAuthenticated={user} />;

  return (
    <MainFixedOffset>
      <Card className="border-0 shadow-sm">
        <Card.Body>
          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleSubmit(values.email)}
            initialValues={{
              email: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mt-sm mb-md text-center">
                  <h2 className="pre-title">
                    {location.pathname === "/set-password"
                      ? `Set Up Password`
                      : `Request New Password`}
                  </h2>
                  <p>
                    If a matching account has been found, an email will be sent
                    to help you reset your password. <br />
                    Otherwise, contact your admin.
                  </p>
                </div>
                <hr />
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    ype="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.email && !errors.email}
                    placeholder="Enter email"
                    autoFocus
                  />
                  <Form.Control.Feedback type="invalid">
                    This should be a valid email address
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {success && (
                  <Alert variant="success" className="my-3 d-flex flex-row">
                    <div className="me-3">
                      <span
                        translate="no"
                        className="material-symbols-outlined md-18"
                      >
                        check_circle
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-1">
                        <small>Success!</small>
                      </h5>
                      <p className="mb-1">
                        <small>
                          Please follow the instructions sent to the email you
                          provided to reset your password.
                        </small>
                      </p>
                    </div>
                  </Alert>
                )}
                <div className="d-grid gap-2 mb-3">
                  <Button
                    className="mb-4"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}
                    <span>Send Instructions</span>
                  </Button>
                </div>
                <p className="text-center">
                  <Link to="/login">Cancel</Link>
                </p>
              </form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </MainFixedOffset>
  );
};

export default ForgotPassword;
