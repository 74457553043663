import { useFormikContext } from "formik";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
const GeoTagFooter = ({
  step,
  setStep,
  isLoading,
  handleClose,
  submitButton,
}) => {
  const { isValid, handleSubmit } = useFormikContext();
  return (
    <div className="sticky-bottom p-3 bg-light text-end">
      {step === 0 && (
        <>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2 px-3"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => setStep(1)}
            className="px-3"
            variant="primary"
            size="sm"
          >
            Next
          </Button>
        </>
      )}
      {step === 1 && (
        <>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2 px-3"
            onClick={() => setStep(0)}
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            variant="primary"
            size="sm"
            disabled={isLoading || !isValid}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            {submitButton}
          </Button>
        </>
      )}
    </div>
  );
};
GeoTagFooter.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  isLoading: PropTypes.bool,
  handleClose: PropTypes.func,
  submitButton: PropTypes.string,
};
export default GeoTagFooter;
