import * as Sentry from "@sentry/react";

import { ActionItems } from "features/actionItems";
import { ActionItemsContextProvider } from "features/actionItems/context/ActionItemProvider";

import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const ActionItemsPage = () => {
  return (
    <div className="px-3 py-2 MainContent">
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "ActionItemsPage");
        }}
        fallback={({ error, resetError }) => (
          <ErrorBoundaryFallback error={error} resetError={resetError} />
        )}
      >
        <ActionItemsContextProvider>
          <ActionItems />
        </ActionItemsContextProvider>
      </Sentry.ErrorBoundary>
    </div>
  );
};

export default ActionItemsPage;
