import { useEffect, useState } from "react";
import { Formik } from "formik";
import { put, get } from "utils/DeApi";
import { Alert, Button, Form, Offcanvas } from "react-bootstrap";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import { reportValidationSchema } from "features/reports/schemas";

const EditReportModal = ({
  organizationId,
  selectedReport,
  showEditModal,
  setShowEditModal,
  fetchEmbedReport,
  subscribedPromises,
  reportTypes,
  generateReportTypeMapping,
  handleUpdateData,
}) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    iframeCode: "",
    reportType: "",
    isActive: false,
    workspace_id: "",
    assignedTo: [],
  });
  const [showError, setShowError] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedReport) {
      const reportTypeMapping = generateReportTypeMapping(reportTypes);

      setInitialValues({
        title: selectedReport.title || "",
        description: selectedReport.description || "",
        iframeCode: selectedReport.iframeCode || "",
        reportType: reportTypeMapping[selectedReport.reportType] || "",
        isActive: selectedReport.isActive || false,
        workspace_id: selectedReport.power_bi_workspace_id || "",
        assignedTo: selectedReport.members || [],
      });
    }
  }, [generateReportTypeMapping, reportTypes, selectedReport]);

  useEffect(() => {
    const handleFetchSubscribers = () => {
      setIsExpanding(true);
      const subscribersPromise = get("subscribers", {
        params: {
          organizationId,
        },
      });

      subscribersPromise.promise
        .then((response) => {
          setSubscribers(response.data);
        })
        .catch((error) => {
          setShowError(error.data.message);
        })
        .finally(() => {
          setIsExpanding(false);
        });

      subscribedPromises.current.push(subscribersPromise);
    };

    handleFetchSubscribers();
  }, [subscribedPromises, organizationId]);

  function updateReportsObject(requestBody) {
    setIsLoading(true);
    const findingsPromise = put(`/embedded-reports/${selectedReport.id}`, {
      ...requestBody,
    });
    findingsPromise.promise
      .then(async ({ data }) => {
        if (data && data.length < 1) {
          return;
        }
        setShowEditModal(false);
        await handleUpdateData(data, "edit");
        fetchEmbedReport();
      })
      .catch((error) => {
        setShowError(error.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    subscribedPromises.current.push(findingsPromise);
  }

  const handleSave = (values, { setSubmitting }) => {
    setSubmitting(true);
    const requestBody = {
      title: values.title,
      description: values.description || null,
      iframe_code: values.iframeCode,
      report_type: Number(values.reportType),
      is_active: values.isActive,
      power_bi_workspace_id:
        Number(values.reportType) === 1 ? values.workspace_id : "",
      assigned_to: values.assignedTo
        .filter((tmpAssigne) => tmpAssigne)
        .map((tmpAssigne) => tmpAssigne.subscriberId),
    };
    updateReportsObject(requestBody);
    setSubmitting(false);
  };

  const onHide = () => {
    setShowEditModal(false);
    setShowError("");
  };

  return (
    <Offcanvas
      className="w-fixed-640 overflow-auto"
      show={showEditModal}
      onHide={onHide}
      placement="end"
    >
      <Offcanvas.Header className="border-bottom" closeButton>
        <Offcanvas.Title>Edit Report</Offcanvas.Title>
      </Offcanvas.Header>
      <div className="overflow-auto">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={reportValidationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Body className="vh-100">
                <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.title && !!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="iframeCode">
                  <Form.Label>Iframe Code</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="iframeCode"
                    value={values.iframeCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.iframeCode && !!errors.iframeCode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.iframeCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="reportType">
                  <Form.Label>Report Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="reportType"
                    value={values.reportType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.reportType && !!errors.reportType}
                  >
                    {reportTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.reportType}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="isActive">
                  <Form.Check
                    type="checkbox"
                    label="Is Active"
                    name="isActive"
                    checked={values.isActive}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.isActive && !!errors.isActive}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.isActive}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="assigned-to" className="mb-5">
                  <Form.Label className="mb-1">Assigned To</Form.Label>
                  <Typeahead
                    id="subscribers-typeahead"
                    clearButton
                    placeholder="Select a user..."
                    isLoading={isExpanding}
                    labelKey={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    isValid={
                      !!values.assignedTo.length &&
                      !errors.assignedTo &&
                      touched.assignedTo
                    }
                    isInvalid={
                      !(values.assignedTo && !errors.assignedTo) &&
                      touched.assignedTo
                    }
                    onChange={(assignedTo) => {
                      setFieldValue("assignedTo", assignedTo);
                      if (!assignedTo.length)
                        setSubscribers((prev) =>
                          uniqBy([...prev, ...subscribers], "subscriberId")
                        );
                    }}
                    options={subscribers}
                    selected={values.assignedTo}
                    multiple
                    renderToken={(option, props) => {
                      return (
                        <Token
                          onRemove={() => {
                            setFieldValue(
                              "assignedTo",
                              values.assignedTo.filter(
                                (a) => a.subscriberId !== option.subscriberId
                              )
                            );
                            setSubscribers((prev) =>
                              uniqBy([...prev, option], "subscriberId")
                            );
                          }}
                        >
                          {option?.firstName} {option?.lastName}
                        </Token>
                      );
                    }}
                  />
                </Form.Group>
                {values.reportType === "1" && (
                  <Form.Group className="mb-3" controlId="workspace_id">
                    <Form.Label>Workspace ID</Form.Label>

                    <Form.Control
                      type="text"
                      name="workspace_id"
                      value={values.workspace_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.workspace_id && !!errors.workspace_id}
                    />
                    {values.reportType === "1" && (
                      <Form.Control.Feedback type="invalid">
                        {errors.workspace_id}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
              </Offcanvas.Body>
              <div className="sticky-bottom p-3 bg-light text-end">
                <Button size="sm" variant="outline-secondary" onClick={onHide}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  variant="primary"
                  disabled={isLoading}
                  className="ms-2"
                >
                  {isLoading ? "Saving..." : "Update"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {showError !== "" && (
          <Alert variant="danger" className={`my-3 d-flex flex-row`}>
            <div className="me-3">
              <span
                translate="no"
                className="material-symbols-outlined md-18 text-primary text-opacity-75"
              >
                info
              </span>
            </div>
            <div>
              <h5 className="mb-1">
                <small>Error</small>
              </h5>
              <p className="mb-1">
                <small>{showError}</small>
              </p>
            </div>
          </Alert>
        )}
      </div>
    </Offcanvas>
  );
};

EditReportModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  selectedReport: PropTypes.object.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  setShowEditModal: PropTypes.func.isRequired,
  generateReportTypeMapping: PropTypes.func.isRequired,
  fetchEmbedReport: PropTypes.func.isRequired,
  subscribedPromises: PropTypes.array,
  reportTypes: PropTypes.array,
};

export default EditReportModal;
