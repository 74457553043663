import React, { useContext, useRef, useState } from "react";

import * as yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { Form, Offcanvas, Tab, Tabs } from "react-bootstrap";

import { put } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { UserContext } from "contexts/UserProvider";
import { OrganizationContext } from "contexts/OrganizationProvider";
import OverlayTriggerButton from "components/ui/OverlayTriggerButton";

import ActionItemUpdateFooter from "./ActionItemUpdateFooter";
import ActionItemUpdateComment from "./ActionItemUpdateComment";
import ActionItemUpdateEvidence from "./ActionItemUpdateEvidence";
import ActionItemUpdateGeneralInfo from "./ActionItemUpdateGeneralInfo";

import "./ActionItemUpdate.scss";

const ActionItemUpdate = ({
  actionItem,
  facilityId = "",
  onActionItemDeleted,
  onActionItemUpdated,
  collapseView = false,
  count,
  step = 0,
}) => {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [actionItemComment, setActionItemComment] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateActionItem = (...args) => {
    const [item, description, assignedTo, tags, dueDate, done, status, type] =
      args;
    setError("");
    setIsLoading(true);
    const actionItemPromise = put(`action-items/${actionItem.actionItemId}`, {
      ...actionItem,
      item: item,
      status: status ?? 0,
      description: description,
      assignedTo: assignedTo.length ? assignedTo : null,
      assignedBy: actionItem?.assignedBy?.subscriberId,
      tags: tags ?? [],
      done: done,
      doneBy: done
        ? actionItem?.doneBy?.subscriberId ?? user?.de?.subscriberId
        : actionItem?.doneBy?.subscriberId
        ? actionItem?.doneBy?.subscriberId
        : user?.de?.subscriberId,
      dueDate: dueDate,
      taskableType: actionItem?.taskableType ?? null,
      taskableId: actionItem?.taskableId ?? null,
      ...(actionItem?.audit
        ? {
            audit_id: actionItem?.audit?.auditId,
          }
        : {}),
      path: actionItem?.path,
      referer: window.location.origin,
      action_item_type: parseInt(type),
    });
    actionItemPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onActionItemUpdated(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
    subscribedPromises.current.push(actionItemPromise);
  };

  const schema = yup.object().shape({
    item: yup
      .string()
      .min(2, "Text is too Short!")
      .max(255, "Text is too Long!")
      .required("This field is required"),
    description: yup.string().nullable(),
    assignedTo: yup.array().min(1).required(),
    dueDate: yup.date().required("Due date is a required field"),
    comment: yup.string().min(2, "Comment is too Short!"),
  });

  if (organization?.invitation?.role === "Certifier") return <></>;

  return (
    <>
      {collapseView ? (
        <>
          <span
            onClick={handleShow}
            className="fw-semibold mb-2 text-primary text-decoration-underline cursor-pointer"
          >
            Comments · {count ?? 0}
          </span>
        </>
      ) : (
        <OverlayTriggerButton
          toolTipMessage="Edit"
          className="ms-2"
          variant="outline-primary"
          onClickHandler={handleShow}
          label={
            <span translate="no" className="material-symbols-outlined md-16">
              edit
            </span>
          }
        />
      )}
      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        placement="end"
      >
        <Offcanvas.Header
          onHide={handleClose}
          className="border-bottom"
          closeButton
        >
          <Offcanvas.Title>Action Item</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="overflow-auto">
          <Formik
            validationSchema={schema}
            onSubmit={(values) => {
              const {
                item,
                description,
                assignedTo,
                tags,
                dueDate,
                done,
                status,
                type,
              } = values;
              updateActionItem(
                item,
                description,
                assignedTo?.length
                  ? assignedTo.map(({ subscriberId }) => subscriberId)
                  : [],
                tags?.length
                  ? tags.map(({ name = "" }) => name.toLowerCase())
                  : [],
                moment(dueDate).format("YYYY-MM-DD HH:mm:ss"),
                done,
                +status,
                type
              );
            }}
            initialValues={{
              comment: "",
              updateComment: "",
              status: actionItem?.status ?? 0,
              item: actionItem.item,
              description: actionItem.description || "",
              assignedTo: actionItem.assignedTo ? actionItem.assignedTo : [],
              done: !!actionItem?.done,
              tags: actionItem?.tags ?? [],
              dueDate: actionItem.dueDate
                ? new Date(actionItem.dueDate)
                : new Date(),
              type: actionItem?.type,
              step,
              actionItem,
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Offcanvas.Body className="h-100">
                  <Tabs
                    id="action-item-update-tabs"
                    name="step"
                    activeKey={values.step}
                    onSelect={(k) => setFieldValue("step", +k)}
                    className="mb-2"
                  >
                    <Tab eventKey={0} title={"General Information"}>
                      <ActionItemUpdateGeneralInfo
                        show={show}
                        taskableId={actionItem?.taskableId}
                        taskableType={actionItem?.taskableType}
                        facilityId={actionItem?.audit?.facilityId}
                      />
                    </Tab>
                    <Tab eventKey={1} title={"Files"}>
                      <ActionItemUpdateEvidence
                        error={error}
                        actionItem={actionItem}
                        onActionItemUpdated={onActionItemUpdated}
                      />
                    </Tab>
                    <Tab eventKey={2} title={"Comments"}>
                      <ActionItemUpdateComment
                        actionItemComment={actionItemComment}
                        setActionItemComment={setActionItemComment}
                      />
                    </Tab>
                  </Tabs>
                  {error && <ErrorHandler error={error} />}
                </Offcanvas.Body>
                <ActionItemUpdateFooter
                  onActionItemDeleted={onActionItemDeleted}
                  setActionItemComment={setActionItemComment}
                  actionItemId={actionItem?.actionItemId}
                  handleClose={handleClose}
                  isLoading={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Offcanvas>
    </>
  );
};

ActionItemUpdate.propTypes = {
  onActionItemUpdated: PropTypes.func.isRequired,
  actionItem: PropTypes.object.isRequired,
  collapseView: PropTypes.bool,
  count: PropTypes.number,
  onActionItemDeleted: PropTypes.func,
  step: PropTypes.number,
};

export default ActionItemUpdate;
