import PropTypes from "prop-types";
import "./Assignees.scss";
import { useRoles } from "hooks";
import { useContext } from "react";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

const Assignees = ({ assignedMembers, isActive }) => {
  const { isAdmin } = useRoles();
  const { audit } = useContext(AuditContext);

  if (!isAdmin) return <></>;
  if (!audit?.protocol?.hasAssignableQuestions) return <></>;
  if (!assignedMembers || !assignedMembers.length > 0) return <></>;

  return (
    <div className="position-relative">
      <span
        translate="no"
        className={`material-symbols-outlined md-18 my-1 text-opacity-75 ${
          isActive ? "text-light" : "text-secondary"
        }`}
      >
        group
      </span>
      <span
        className={`position-absolute start-100 translate-middle assignees-badge rounded-pill ${
          isActive
            ? "text-dark bg-white bg-opacity-75"
            : "bg-secondary bg-opacity-75 text-white"
        }`}
        style={{ top: "30%" }}
      >
        <small>{assignedMembers.length}</small>
      </span>
    </div>
  );
};

Assignees.propTypes = {
  assignedMembers: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Assignees;
