import { useContext } from "react";

import { useRoles } from "hooks";
import { truncate } from "lodash";
import { saveAs } from "file-saver";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import AuditFilesUpdate from "../AuditFilesUpdate";
import AuditFilesDelete from "../AuditFilesDelete";
import AuditFileDownload from "../AuditFileDownload";

import "./AuditFileListItem.scss";

const AuditFileListItem = ({ error, isLoading, files, setFiles }) => {
  const { isCertifier } = useRoles();
  const { setAudit } = useContext(AuditContext);

  const onFileUpdate = (updatedFile) => {
    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (updatedFile.fileId === file.fileId) return updatedFile;
        return file;
      });
    });
  };

  const onFileDelete = (deletedFile) => {
    const { observationId = "", fileId = "" } = deletedFile;

    if (observationId) {
      setAudit((prevAudit) => {
        const { observations = [] } = prevAudit;

        const indexOfToBeDeletedAttachment = observations.findIndex(
          ({ observationId: _observationId }) =>
            _observationId === observationId
        );

        observations[indexOfToBeDeletedAttachment] = {
          ...observations[indexOfToBeDeletedAttachment],
          attachments:
            observations[indexOfToBeDeletedAttachment].attachments.filter(
              ({ fileId: _fileId }) => _fileId !== fileId
            ) || [],
        };

        return {
          ...prevAudit,
          observations,
        };
      });
    }

    setFiles((prevFiles) => {
      return prevFiles.filter((file) => {
        return deletedFile?.fileId !== file?.fileId;
      });
    });
  };

  if (isLoading)
    return (
      <tr>
        <td colSpan={8}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={8}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (!Array.isArray(files)) return <></>;

  if (!files.length)
    return (
      <tr>
        <td colSpan={8}>
          <EmptyStateHandler
            className="mb-3 d-flex flex-row"
            title="No Files Found"
            description="Files will appear here once added"
          />
        </td>
      </tr>
    );

  return files.map((file, index) => {
    const { fileId, name, observationId, description, updatedAt } = file;
    const fileURL = file.url || "";
    const fileEXT = getURLFileExtension(fileURL);
    return (
      <tr key={fileId}>
        <td className="text-wrap">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2">
                {name || `${fileId}.${fileEXT}`}
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <Button
                type="button"
                variant="link"
                size="sm"
                ref={ref}
                className="text-start p-0"
                {...triggerHandler}
                onClick={() => {
                  saveAs(fileURL, name || `${fileId}.${fileEXT}`);
                }}
              >
                <div className="d-flex flex-row">
                  <div>
                    <span
                      className={`fiv-sqo fiv-size-md fiv-icon-${fileEXT} me-2`}
                    ></span>
                  </div>

                  <span className="text-wrap">
                    {truncate(name || `${fileId}.${fileEXT}`, {
                      length: 25,
                    })}
                  </span>
                </div>
              </Button>
            )}
          </OverlayTrigger>
        </td>
        <td className="text-wrap">{description}</td>
        <td>{!!observationId ? "Question" : "Assessment"}</td>
        <td>
          <small>
            {new Date(updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        {!isCertifier && (
          <td className="text-center">
            <div className="d-flex flex-row">
              <div>
                <AuditFileDownload file={file} />
              </div>
              <div>
                <AuditFilesUpdate
                  file={file}
                  onFileUpdate={onFileUpdate}
                  cypressId={`btn-file-update-${index}`}
                />
              </div>
              <div>
                <AuditFilesDelete
                  file={file}
                  onFileDelete={onFileDelete}
                  cypressId={`btn-file-delete-${index}`}
                />
              </div>
            </div>
          </td>
        )}
      </tr>
    );
  });
};

export default AuditFileListItem;
