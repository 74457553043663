import { useContext, useEffect, useState } from "react";

import { Field, Formik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  DropdownButton,
  Form,
  Nav,
  Spinner,
  Tab,
} from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import Loader from "components/ui/Loader";

import ClearObservation from "../../../AuditDetails/Question/ClearObservation";
import FieldCausalFactors from "../../../AuditDetails/Question/FieldCausalFactors";
import FieldComplianceOption from "../../../AuditDetails/Question/FieldComplianceOption";
import FieldConformityInapplicable from "../../../AuditDetails/Question/FieldConformityInapplicable";
import FieldConformityLevel from "../../../AuditDetails/Question/FieldConformityLevel";
import FieldCorrectiveAction from "../../../AuditDetails/Question/FieldCorrectiveAction";
import FieldFindings from "../../../AuditDetails/Question/FieldFindings";
import FieldRecommendation from "../../../AuditDetails/Question/FieldRecommendation";
import FieldRiskOption from "../../../AuditDetails/Question/FieldRiskOption";
import FileUpload from "../../../AuditDetails/Question/FileUpload/FileUpload";
import FlexibleQuestions from "../../../AuditDetails/Question/FlexibleQuestions";
import GeoTag from "../../../AuditDetails/Question/GeoTag/GeoTag";
import ObservationLabelCreate from "../../../AuditDetails/Question/ObservationLabelCreate";
import ObservationLabelUpdate from "../../../AuditDetails/Question/ObservationLabelUpdate";
import {
  prepIntialObservationValues,
  prepSubmitObservationValues,
} from "../../../AuditDetails/Question/QuestionUtils";

import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useAuditMapping, useQuestion } from "features/assessment/hooks";
import { useCreateOrUpdateObservation } from "features/assessment/services";
import "../../../AuditDetails/Question/Quesion.scss";

import { useRoles } from "hooks";
import { sortArrayByDate } from "utils/ArrayUtils";
import { questionIsAnswered } from "../../../AuditDetails/helper";
import DeleteObservation from "../../../AuditDetails/Question/DeleteObservation";

const ManuscriptQuestion = ({ questionId, handleClose, sectionId }) => {
  const [observation, setObservation] = useState();
  const [actionItems, setActionItems] = useState();
  const [key, setKey] = useState("default");
  const { audit } = useContext(AuditContext);

  const { question } = useQuestion(questionId, audit?.questions);
  const { isCertifier } = useRoles();
  const isDisabled = audit.locked || isCertifier;

  const { responseUpdateMapping, observationCreateOrUpdateMapping } =
    useAuditMapping();

  const { error, isLoading, handleOnSubmit } = useCreateOrUpdateObservation({
    onObservationSuccess: (observation) => {
      observationCreateOrUpdateMapping(observation);

      setTimeout(() => {
        setObservation(observation || {});
        setKey(observation?.observationId);
      }, 100);
    },
    onResponsesSuccess: (responses, observation) => {
      responseUpdateMapping(responses, observation);
      setTimeout(() => {
        setObservation(observation || {});
        setKey(observation?.observationId);
      }, 100);
    },
    onFinally: () => {
      setObservation(null);
      handleClose();
    },
  });

  useEffect(() => {
    function fetchObservation() {
      const found = question?.observations?.length
        ? question?.observations[0]
        : {};

      setObservation(found || {});
      setKey(found?.observationId || "default");
    }

    setObservation(null);
    fetchObservation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audit.auditId, questionId, question?.observations]);

  if (!question || !observation) return <Loader />;

  const observationsMappped = !!question?.observations?.length
    ? sortArrayByDate(question?.observations, "createdAt")
    : [{}];

  return (
    <>
      <div className="mx-3">
        {question?.tags &&
          question?.tags.map(({ id, name }) => (
            <Badge
              key={id}
              className="me-2 mb-2 bg-opacity-10 text-primary fw-normal"
            >
              <p className="mb-0 pe-2">
                <span
                  translate="no"
                  className="material-symbols-outlined md-16 me-2 mt-n2"
                >
                  tag
                </span>
                <strong>{name}</strong>
              </p>
            </Badge>
          ))}
      </div>
      <p className="mx-3">{question?.description}</p>
      <Tab.Container activeKey={key}>
        {audit?.protocol?.hasObservationSets && (
          <Nav variant="tabs" className="pt-3 d-flex flex-row mx-3">
            {observationsMappped.map((observation, index) => {
              const { observationId = "default" } = observation || {};

              return (
                <Nav.Item
                  key={observationId}
                  onClick={() => {
                    setKey(observationId);
                  }}
                >
                  <Nav.Link
                    eventKey={observationId}
                    className={`d-flex flex-row ${
                      observation?.isInapplicable && "text-muted opacity-75"
                    }`}
                  >
                    {questionIsAnswered(audit, observation) && (
                      <span
                        className={`material-symbols-outlined md-16 me-2 text-primary ${
                          observation?.isInapplicable
                            ? "text-muted"
                            : "text-primary"
                        }`}
                      >
                        check_circle
                      </span>
                    )}
                    <span className="flex-fill">
                      {observation?.label
                        ? observation?.label
                        : `Observation ${++index}`}
                    </span>
                    {observation.observationId && key === observationId && (
                      <DropdownButton
                        title=""
                        id="observation-set"
                        variant="outline-primary"
                        className="observation-set-dropdown"
                      >
                        <ObservationLabelUpdate observation={observation} />
                        <DeleteObservation
                          isDisabled={isDisabled}
                          observation={observation}
                        />
                      </DropdownButton>
                    )}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
            <Nav.Item className="flex-fill">
              <ObservationLabelCreate
                auditId={audit.auditId}
                questionId={questionId}
                observation={observation}
                updateObservations={(observation) => {
                  observationCreateOrUpdateMapping(observation);
                }}
              />
            </Nav.Item>
          </Nav>
        )}
        <Tab.Content>
          {observationsMappped.map((observation = {}, index, self) => {
            const { observationId = "default" } = observation;
            const length = self.length;
            const { observationId: parentId } = length > 1 ? self[0] : {};

            return (
              <Tab.Pane key={observationId} eventKey={observationId}>
                <Formik
                  onSubmit={(values, actions) => {
                    if (audit.locked || isCertifier) {
                      setObservation(null);
                    } else {
                      const auditId = audit.auditId;
                      const formData = prepSubmitObservationValues(values, {
                        auditId,
                        parentId,
                        questionId,
                        observationId,
                      });
                      handleOnSubmit(formData);
                    }
                  }}
                  enableReinitialize
                  initialValues={prepIntialObservationValues(
                    observation,
                    questionId
                  )}
                >
                  {({ handleSubmit, setFieldValue, values }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="mx-3">
                        <div className="my-3 border-bottom py-3">
                          <Field
                            as={Form.Switch}
                            disabled={isDisabled}
                            checked={values.isInapplicable}
                            value={values.isInapplicable}
                            onChange={(event) => {
                              setFieldValue(
                                "isInapplicable",
                                !values.isInapplicable
                              );
                            }}
                            type="switch"
                            name="isInapplicable"
                            label="Question does not apply to this assessment"
                          />
                        </div>
                        <FlexibleQuestions
                          question={question}
                          sectionId={sectionId}
                          isDisabled={isDisabled}
                          isManuscript={true}
                        />
                        <FieldRiskOption isDisabled={isDisabled} />
                        <FieldComplianceOption isDisabled={isDisabled} />
                        <FieldCorrectiveAction isDisabled={isDisabled} />
                        <FieldConformityLevel isDisabled={isDisabled} />
                        <FieldConformityInapplicable isDisabled={isDisabled} />
                        <FieldFindings
                          isDisabled={isDisabled}
                          setActionItems={setActionItems}
                          showActionItem={false}
                        />
                        <FieldCausalFactors isDisabled={isDisabled} />
                        <FieldRecommendation
                          isDisabled={isDisabled}
                          setActionItems={setActionItems}
                          showActionItem={false}
                        />
                        <FileUpload
                          observation={observation ?? {}}
                          disabled={values.isInapplicable || isDisabled}
                          manuscriptQuestionId={questionId}
                        />
                        {key === observationId && (
                          <GeoTag
                            question={question}
                            observation={observation}
                          />
                        )}
                      </div>

                      {error && <ErrorHandler error={error} />}

                      <div className="sticky-bottom p-3 bg-light d-flex justify-content-between flex-row">
                        <div>
                          {observation?.updatedAt && (
                            <small>
                              Last updated{" "}
                              {moment(observation?.updatedAt).fromNow()}
                            </small>
                          )}
                        </div>
                        <div>
                          {observation?.observationId && (
                            <ClearObservation
                              isDropdown={false}
                              isDisabled={isDisabled}
                              observation={observation}
                            />
                          )}
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            className="me-3 ms-2"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            size="sm"
                            disabled={isLoading || isDisabled}
                          >
                            <span translate="no">
                              {isLoading && (
                                <Spinner
                                  className="me-2"
                                  animation="border"
                                  size="sm"
                                  variant="light"
                                />
                              )}
                            </span>
                            <span>Save Changes</span>
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Tab.Pane>
            );
          })}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
ManuscriptQuestion.propTypes = {
  questionId: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  sectionId: PropTypes.string.isRequired,
};

export default ManuscriptQuestion;
