import React, { useState } from "react";
import Dropzone from "react-dropzone";
import "./DragAndDrop.scss";
import { Button } from "react-bootstrap";
import { truncate } from "lodash";
import { getURLFileExtension } from "utils/StringUtils";
import PropTypes from "prop-types";
import { SupportedTypesMaxSize } from "utils/UploadUtils";

const DragAndDrop = ({
  onDrop,
  preview = true,
  replaceFile = false,
  formats,
  maxSize,
}) => {
  const [fileList, setFileList] = useState([]);

  const handleDeleteFile = (deletedFile) => {
    setFileList((prevFiles) => {
      const files = prevFiles.filter((file) => {
        return deletedFile?.path !== file?.path;
      });
      onDrop(files);
      return files;
    });
  };

  return (
    <>
      <Dropzone
        onDrop={(files) => {
          !!replaceFile ? onDrop([...files]) : onDrop([...fileList, ...files]);
          setFileList((prevFiles) => [...prevFiles, ...files]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="container p-0">
            <div
              {...getRootProps({
                className:
                  "text-center p-5 bg-light rounded mb-3 text-muted border-dashed",
                onDrop: (event) => {
                  event.preventDefault();
                },
              })}
            >
              <input {...getInputProps()} />
              <p className="mb-0">
                Drag 'n' drop some files here, or click to select files
              </p>
            </div>
          </div>
        )}
      </Dropzone>
      <SupportedTypesMaxSize formats={formats} maxSize={maxSize} />
      {!!preview &&
        fileList?.map((file, index) => {
          const fileURL = file.path || "";
          const fileEXT = getURLFileExtension(fileURL);
          return (
            <div className="d-flex flex-row border-bottom" key={index}>
              <div className="py-2">
                <span
                  className={`fiv-sqo fiv-size-md lh-base
             fiv-icon-${fileEXT} 
             me-2 rounded`}
                ></span>
              </div>
              <div className="d-flex flex-column flex-fill">
                <p className=" mt-2 pt-1">
                  {truncate(file?.name, {
                    length: 100,
                  })}
                  <small className="ms-2">{`(${(
                    file?.size /
                    (1024 * 1024)
                  ).toFixed(2)}MB)`}</small>
                </p>
              </div>
              <div>
                <Button
                  variant="outline-danger"
                  size="sm"
                  className="mt-2"
                  onClick={() => handleDeleteFile(file)}
                >
                  <span
                    translate="no"
                    className="material-symbols-outlined md-16"
                  >
                    delete
                  </span>
                </Button>
              </div>
            </div>
          );
        })}
    </>
  );
};
DragAndDrop.propTypes = {
  onDrop: PropTypes.func.isRequired,
  preview: PropTypes.bool,
  replaceFile: PropTypes.bool,
  formats: PropTypes.array,
  maxSize: PropTypes.number,
};
export default DragAndDrop;
