import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import PropTypes from "prop-types";
import { Offcanvas, Table } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import ErrorHandler from "components/ui/ErrorHandler";
import { OrganizationContext } from "contexts/OrganizationProvider";
import OverlayTriggerButton from "components/ui/OverlayTriggerButton";

import ActionItemHistoryCard from "./ActionItemHistoryCard";

import "./ActionItemHistory.scss";

const ActionItemHistory = ({ actionItemId }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [actionItemHistory, setActionItemHistory] = useState([]);

  const isAdminOrOwner =
    organization?.invitation?.role === "Owner" ||
    organization?.invitation?.role === "Admin";

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchActionItemHistory = () => {
      setIsLoading(true);
      const actionItemHistoryPromise = get(
        `action-items/${actionItemId}/audit-logs`
      );
      actionItemHistoryPromise.promise
        .then((response) => {
          setActionItemHistory(response.data ?? []);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(actionItemHistoryPromise);
    };

    if (show && isAdminOrOwner) fetchActionItemHistory();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [show, actionItemId, isAdminOrOwner]);

  if (!isAdminOrOwner) return <></>;

  return (
    <>
      <OverlayTriggerButton
        toolTipMessage="History"
        variant="outline-secondary"
        onClickHandler={handleShow}
        label={
          <span translate="no" className="material-symbols-outlined md-16">
            history
          </span>
        }
      />

      <Offcanvas
        className="history-offcanvas-style"
        show={show}
        onHide={handleClose}
        placement="end"
        scroll
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Action Item History</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {Array.isArray(actionItemHistory) &&
            actionItemHistory
              .filter(
                ({ subjectType }) =>
                  !subjectType.includes("action_item_assignee")
              )
              .map((history) => {
                return (
                  <Fragment key={history.id}>
                    <Table>
                      <ActionItemHistoryCard {...history} />
                    </Table>
                    <hr />
                  </Fragment>
                );
              })}
          {!isLoading &&
            !error &&
            Array.isArray(actionItemHistory) &&
            !actionItemHistory.length && (
              <EmptyStateHandler
                title="No action item history"
                description="Currently, there is no history of past action item activity to display."
              />
            )}
          {isLoading && <Loader />}
          {error && <ErrorHandler error={error} />}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ActionItemHistory.propTypes = {
  actionItemId: PropTypes.string.isRequired,
};

export default ActionItemHistory;
