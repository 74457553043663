import React, { useState } from "react";

import { Formik } from "formik";
import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { uploadFile } from "features/assessment/utils";
import DragAndDrop from "components/common/DragAndDrop";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { MAX_FILE_SIZE } from "features/assessment/constants";
import { convertToMB, SUPPORTED_FORMATS } from "utils/UploadUtils";
import useUploadAuditFiles from "features/assessment/services/useUploadAuditFiles";
import auditBulkUploadValidationSchema from "features/assessment/schemas/auditBulkUploadValidationSchema";

const AuditBulkUpload = ({ onFileUploaded }) => {
  const { isCertifier } = useRoles();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { isLoading, error, uploadAuditFiles } = useUploadAuditFiles({
    onFileUploaded: (files, description) => {
      handleClose();
      onFileUploaded(files, description);
    },
  });

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        size="sm"
        variant="primary"
        onClick={handleShow}
        className="float-end"
        data-cy={`btn-bulk-upload`}
      >
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          upload_file
        </span>
        Bulk Upload
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upload Multiple Files</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={auditBulkUploadValidationSchema}
          onSubmit={(values) => {
            const { files, description } = values;
            uploadAuditFiles(files, description);
          }}
          initialValues={{
            files: [],
            description: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="file" className="mb-3">
                  <Form.Label>
                    Upload Files <RequiredAsterisk />
                  </Form.Label>
                  <DragAndDrop
                    onDrop={async (files) => {
                      const uploadFiles = await uploadFile(files);
                      setFieldValue("files", uploadFiles);
                    }}
                    maxSize={convertToMB(MAX_FILE_SIZE)}
                    formats={SUPPORTED_FORMATS}
                  />
                  {values?.files.length && errors.files ? (
                    <small className="text-danger">{errors.files}</small>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>
                    Description
                    <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !(values.description && !errors.description) &&
                      touched.description
                    }
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={isLoading}>
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Upload File(s)
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
AuditBulkUpload.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
};

export default AuditBulkUpload;
