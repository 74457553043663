import { useContext, useEffect, useRef, useState } from "react";

import { Alert, Col, Row } from "react-bootstrap";

import { get } from "utils/DeApi";
import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import ReportTrend from "components/common/ReportTrend";
import ReportAggregation from "components/common/ReportAggregation";
import { OrganizationContext } from "contexts/OrganizationProvider";
import {
  aggregationOfComlianceTrends,
  aggregationOfCompliance,
} from "helper/reporting";

const COMPLIANCE_OPTION = 1;

function OrganizationReportCompliance({ protocol, filterPeriod, isExpanding }) {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [complianceStats, setComplianceStats] = useState();

  useEffect(() => {
    function fetchStats() {
      setError(null);
      setIsLoading(true);

      const compliancePromise = get(
        `organizations/${organization?.id}/protocols/${protocol?.protocolId}/stats`,
        {
          params: { period: filterPeriod?.period, option: COMPLIANCE_OPTION },
        }
      );
      compliancePromise.promise
        .then(({ data }) => {
          setComplianceStats(data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(compliancePromise);
    }

    if (protocol?.protocolId) {
      fetchStats();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id, protocol?.protocolId, filterPeriod]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : complianceStats && complianceStats?.length > 0 ? (
        <Row>
          {" "}
          <Col xs={12} sm={12} md={12} lg={4} className="mb-4 border-end">
            <p className="text-center w-100 text-truncate text-capitalize">
              Distribution of{" "}
              {protocol?.questionOptions[1]?.name ?? "Compliance"}
            </p>
            <hr />
            <ReportAggregation
              size="square-container"
              labels={{
                compliant: "Yes",
                nonCompliant: "No",
              }}
              data={aggregationOfCompliance(complianceStats)}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={8} className="mb-3">
            <p className="text-center w-100 text-truncate text-capitalize">
              {protocol?.questionOptions[1]?.name ?? "Compliance"} trends across
              · <small className="text-muted">{organization?.name}</small>
            </p>
            <hr />
            <ReportTrend
              size="square-container"
              labels={{
                compliant: "Yes",
                nonCompliant: "No",
              }}
              data={aggregationOfComlianceTrends(complianceStats, filterPeriod)}
            />
          </Col>
        </Row>
      ) : (
        complianceStats?.length === 0 && (
          <Alert variant="info" className={`my-3 d-flex flex-row`}>
            <div className="me-3">
              <span
                translate="no"
                className="material-symbols-outlined md-18 text-primary text-opacity-75"
              >
                info
              </span>
            </div>
            <div>
              <h5 className="mb-1">
                <small>No data to visualize</small>
              </h5>
              <p className="mb-1">
                <small>You have no data to visualize.</small>
              </p>
            </div>
          </Alert>
        )
      )}
    </>
  );
}

export default OrganizationReportCompliance;
