import React, { useContext, useState } from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { useUpdateAudit } from "features/assessment/services";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import "./AuditDeleteMember.scss";

const AuditDeleteMember = ({ member }) => {
  const { isCertifier } = useRoles();
  const { subscriberId, firstName, lastName } = member;
  const { audit, setAudit } = useContext(AuditContext);

  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { error, isLoading, updateAudit } = useUpdateAudit({
    auditId: audit?.auditId,
    onAuditUpdated: (data) => {
      handleClose();
      setAudit((prev) => ({
        ...prev,
        subscribers: data?.subscribers || [],
      }));
    },
  });

  if (isCertifier) return;

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        className="ms-2"
        onClick={handleShow}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          delete
        </span>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">
            Delete Member -{" "}
            <small translate="no" className="text-muted">
              {firstName} {lastName}
            </small>
          </h2>
          <p className="mx-4 mt-2">
            <Form.Group>
              <Form.Check
                label={
                  <small>
                    By checking the box, I confirm that I want to delete this
                    Member. I understand that this cannot be undone.
                  </small>
                }
                onClick={() => {
                  setIsChecked(!isChecked);
                }}
              />
            </Form.Group>
          </p>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              const { group } = audit;

              updateAudit({
                name: audit?.name,
                description: audit?.description,
                protocolId: audit?.protocolId,
                executiveSummary: audit?.executiveSummary,
                auditGroupId:
                  Array.isArray(group) && group.length ? group.pop().id : "",
                subscriberIds: [
                  ...audit?.subscribers
                    .filter((ele) => ele.subscriberId !== subscriberId)
                    .map(({ subscriberId }) => subscriberId),
                ],
              });
            }}
            size="sm"
            disabled={!isChecked || isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AuditDeleteMember.propTypes = {
  member: PropTypes.object.isRequired,
};

export default AuditDeleteMember;
