import { useContext, useState } from "react";

import { Formik } from "formik";
import { isEmpty } from "lodash";
import { useRoles } from "hooks";
import { Link } from "react-router-dom";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { assigneesValidationSchema } from "features/assessment/schemas";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import { useUpdateQuestionAssignees } from "features/assessment/services";

import "./AssignMembers.scss";

const AssignMembers = ({ question }) => {
  const { isAdmin } = useRoles();
  const { audit, setAudit } = useContext(AuditContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { isLoading, error, updateQuestionAssignees } =
    useUpdateQuestionAssignees({
      auditId: audit?.auditId,
      questionId: question?.questionId,
      onSuccess: (data = []) => {
        setAudit((prevState) => {
          const assignees = data.map(({ member }) => ({ ...member }));
          return {
            ...prevState,
            questions: prevState.questions.map((q) => {
              if (q.questionId !== question?.questionId) return q;
              return {
                ...q,
                assignedMembers: assignees,
              };
            }),
          };
        });
        handleClose();
      },
    });

  if (!isAdmin) return <></>;
  if (!audit?.protocol?.hasAssignableQuestions) return <></>;

  return (
    <>
      <Button size="sm" variant="outline-primary" onClick={handleShow}>
        <span translate="no" className={`material-symbols-outlined md-16 me-2`}>
          group_add
        </span>
        Assign Members
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Formik
          validationSchema={assigneesValidationSchema}
          onSubmit={(values) => {
            updateQuestionAssignees({
              subscriber_ids:
                Array.isArray(values?.assignees) && values?.assignees?.length
                  ? values.assignees
                  : [""],
            });
          }}
          initialValues={{
            assignees:
              question?.assignedMembers?.map(
                ({ subscriberId }) => subscriberId
              ) || [],
          }}
        >
          {({ values, setFieldValue, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Assign Members</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {isEmpty(audit?.subscribers) && (
                  <EmptyStateHandler
                    title="No members found"
                    description="Add members to the Assessment Team."
                  >
                    <Button
                      size="sm"
                      as={Link}
                      variant="outline-info"
                      to={`/audits/${audit?.auditId}/members`}
                    >
                      Go to Assessment Team{" "}
                      <span className="material-symbols-outlined md-16">
                        east
                      </span>
                    </Button>
                  </EmptyStateHandler>
                )}

                <Table hover borderless striped className="mb-0">
                  <tbody>
                    {Array.isArray(audit?.subscribers) &&
                      audit?.subscribers.map(
                        ({ subscriberId, email, firstName, lastName }) => {
                          const checked =
                            Array.isArray(values?.assignees) &&
                            values?.assignees.includes(subscriberId);
                          return (
                            <tr key={subscriberId}>
                              <td>
                                <Form.Check
                                  inline={false}
                                  type="checkbox"
                                  name="assignees"
                                  value={subscriberId}
                                  checked={checked}
                                  onChange={(ev) => {
                                    const selected = ev.target.value;
                                    const updatedValues = Array.isArray(
                                      values?.assignees
                                    )
                                      ? ev.target.checked
                                        ? [...values?.assignees, selected]
                                        : values?.assignees.filter(
                                            (id) => id !== selected
                                          )
                                      : [selected];

                                    setFieldValue(`assignees`, updatedValues);
                                  }}
                                />
                              </td>
                              <td>
                                <small>
                                  {firstName} {lastName}
                                </small>
                              </td>
                              <td>
                                <small>{email}</small>
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </Table>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="me-2 px-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {!isEmpty(audit?.subscribers) && (
                  <Button
                    type="submit"
                    size="sm"
                    className="px-3"
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Save Changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AssignMembers;
