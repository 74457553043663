import { AuditContext } from "features/assessment/contexts/AuditProvider";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const ReportHideResponseFields = ({
  visibleQuestions,
  visibleObservationQuestions,
  handleToggleQuestionVisibility,
  handleToggleObservationQuestionVisibility,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { audit } = useContext(AuditContext);
  const questions = audit.protocol.flexibleQuestions;
  const observationQuestions = audit.protocol.questionOptions;

  const topLevelQuestions = questions
    .filter(({ triggerFlexibleQuestionId = "" }) => !triggerFlexibleQuestionId)
    .sort((a, b) => a.order - b.order);

  const nestedQuestions = questions.filter(
    ({ triggerFlexibleQuestionId = "" }) => triggerFlexibleQuestionId
  );

  const mergedQuestions = topLevelQuestions.map((topLevelQuestion) => {
    const correspondingNestedQuestion = nestedQuestions.filter(
      ({ triggerFlexibleQuestionId = "" }) =>
        topLevelQuestion?.id === triggerFlexibleQuestionId
    );

    return {
      ...topLevelQuestion,
      nestedQuestions: correspondingNestedQuestion,
    };
  });

  return (
    <>
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip id="list-tooltip">Manage Response Visibility</Tooltip>
        }
      >
        <Button
          size="sm"
          variant="outline-primary me-2"
          style={{
            lineHeight: "normal",
          }}
          onClick={handleShow}
        >
          <span translate="no" className="material-symbols-outlined md-18">
            visibility
          </span>
        </Button>
      </OverlayTrigger>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Manage Response Visibility</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <ul className="mb-0 ps-0 mx-3 border-top pt-3">
            {mergedQuestions?.map((question, index) => (
              <div className="mb-2">
                <div
                  key={question.id}
                  className="d-flex justify-content-between"
                >
                  {question.prompt}
                  <Form.Check
                    id={question.id}
                    key={question.id}
                    value={question.id}
                    type="checkbox"
                    name={question.id}
                    checked={visibleQuestions[question.id]}
                    onChange={() => handleToggleQuestionVisibility(question.id)}
                  />
                </div>
                {question.nestedQuestions?.map((nestedQuestion) => (
                  <div
                    key={nestedQuestion.id}
                    className="d-flex justify-content-between ms-5 mt-2"
                  >
                    {nestedQuestion.prompt}
                    <Form.Check
                      id={nestedQuestion.id}
                      key={nestedQuestion.id}
                      value={nestedQuestion.id}
                      type="checkbox"
                      name={nestedQuestion.id}
                      checked={visibleQuestions[nestedQuestion.id]}
                      onChange={() =>
                        handleToggleQuestionVisibility(nestedQuestion.id)
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
          </ul>
          <ul className="ps-0 mx-3">
            {observationQuestions
              ?.filter((question) => question.value === true)
              .map((question) => (
                <div
                  key={question.originalName}
                  className="d-flex justify-content-between mb-2"
                >
                  {question.name}
                  <Form.Check
                    id={question.originalName}
                    key={question.originalName}
                    value={question.originalName}
                    type="checkbox"
                    name={question.originalName}
                    checked={
                      visibleObservationQuestions[question.originalName] ??
                      false
                    }
                    onChange={() =>
                      handleToggleObservationQuestionVisibility(
                        question.originalName
                      )
                    }
                  />
                </div>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

ReportHideResponseFields.propTypes = {
  visibleQuestions: PropTypes.object.isRequired,
  visibleObservationQuestions: PropTypes.object.isRequired,
  handleToggleQuestionVisibility: PropTypes.func.isRequired,
  handleToggleObservationQuestionVisibility: PropTypes.func.isRequired,
};

export default ReportHideResponseFields;
