import React, { useState } from "react";

import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import RequiredAsterisk from "components/ui/RequiredAsterisk";

import GeoTagMap from "../../Map/Map";

const GeoTagUpdateFields = ({ geoLocation, setGeoLocation }) => {
  const { values, errors, touched, setFieldValue, handleBlur } =
    useFormikContext();

  const [draggable, setDraggable] = useState(false);

  const onFormChange = (key, value) => {
    setFieldValue(key, value);
    setGeoLocation((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onMarkerInteraction = (mouse) => {
    setGeoLocation((prev) => ({
      ...prev,
      latitude: mouse.lat?.toFixed(6) || prev.latitude,
      longitude: mouse.lng?.toFixed(6) || prev.longitude,
    }));
    setDraggable(false);
  };

  const onMarkerInteractionUp = (childKey, childProps, mouse) => {
    setGeoLocation((prev) => ({
      ...prev,
      latitude: mouse.lat?.toFixed(6) || prev.latitude,
      longitude: mouse.lng?.toFixed(6) || prev.longitude,
    }));
    setDraggable(true);
  };

  const handlePinDrop = () => {
    setFieldValue("latitude", geoLocation?.latitude);
    setFieldValue("longitude", geoLocation?.longitude);
  };

  const onPlacesChanged = (place) => {
    if (place?.geometry) {
      setGeoLocation((prev) => ({
        ...prev,
        latitude: place?.geometry?.location.lat(),
        longitude: place?.geometry?.location.lng(),
      }));
      values.latitude = place?.geometry?.location.lat();
      values.longitude = place?.geometry?.location.lng();
    }
  };
  return (
    <>
      <Form.Group controlId="title" className="mt-0 mb-3">
        <Form.Label className="mb-1">
          Title <RequiredAsterisk />
        </Form.Label>
        <Form.Control
          name="title"
          value={values.title}
          onChange={(e) => onFormChange("title", e.target.value)}
          onBlur={handleBlur}
          isValid={values.title && !errors.title}
          isInvalid={!(values.title && !errors.title) && touched.title}
        />
        <Form.Control.Feedback type="invalid">
          {errors.title && touched.title ? <small>{errors.title}</small> : null}
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col xs={6}>
          <Form.Group controlId="latitude" className="mt-0 mb-3">
            <Form.Label className="mb-1">
              Latitude <RequiredAsterisk />
            </Form.Label>
            <Form.Control
              type="number"
              name="latitude"
              value={values.latitude}
              onChange={(e) => onFormChange("latitude", e.target.value)}
              onBlur={handleBlur}
              isValid={values.latitude && !errors.latitude}
              isInvalid={
                !(values.latitude && !errors.latitude) && touched.latitude
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.latitude && touched.latitude ? (
                <small>{errors.latitude}</small>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group controlId="longitude" className="mt-0 mb-3">
            <Form.Label className="mb-1">
              Longitude <RequiredAsterisk />
            </Form.Label>
            <Form.Control
              type="number"
              name="longitude"
              value={values.longitude}
              onChange={(e) => onFormChange("longitude", e.target.value)}
              onBlur={handleBlur}
              isValid={values.longitude && !errors.longitude}
              isInvalid={
                !(values.longitude && !errors.longitude) && touched.longitude
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.longitude && touched.longitude ? (
                <small>{errors.longitude}</small>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex justify-content-center mt-1">
          {!errors.longitude && !errors.latitude ? (
            <>
              <div className="w-100">
                <p className="text-secondary mb-1">
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18"
                  >
                    info
                  </span>{" "}
                  Drag the pin to change the location.
                </p>
                <GeoTagMap
                  values={values}
                  draggable={draggable}
                  geolocations={[geoLocation]}
                  handlePinDrop={handlePinDrop}
                  onMarkerInteraction={onMarkerInteraction}
                  onMarkerInteractionUp={onMarkerInteractionUp}
                  search={true}
                  onPlacesChanged={onPlacesChanged}
                />
              </div>
            </>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </>
  );
};

GeoTagUpdateFields.propTypes = {
  geoLocation: PropTypes.object.isRequired,
  setGeoLocation: PropTypes.func.isRequired,
};

export default GeoTagUpdateFields;
