import React, { useState } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { auditFilesUpdateValidationSchema } from "features/assessment/schemas";
import useUpdateAuditFile from "features/assessment/services/useUpdateAuditFile";
const AuditFilesUpdate = ({ cypressId, file, onFileUpdate }) => {
  const [show, setShow] = useState(false);
  const fileURL = file.url || "";
  const fileEXT = getURLFileExtension(fileURL);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { isLoading, error, updateAuditFile } = useUpdateAuditFile({
    file,
    onFileUpdate: (formData) => {
      handleClose();
      onFileUpdate(formData);
    },
  });

  return (
    <>
      <Button
        title="update"
        variant="outline-primary"
        size="sm"
        className="ms-2"
        onClick={handleShow}
        data-cy={cypressId}
      >
        <span translate="no" className="material-symbols-outlined md-16">
          edit
        </span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Update file · <small className="text-muted">{fileEXT}</small>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={auditFilesUpdateValidationSchema}
          onSubmit={(values) => updateAuditFile(values)}
          initialValues={{
            name: file?.name || file?.fileId,
            description: file?.description,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="name" className="mb-3">
                  <Form.Label>
                    name
                    <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    rows={3}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!(values.name && !errors.name) && touched.name}
                    isValid={values.name && !errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && touched.name ? (
                      <small>{errors.name}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>
                    Description
                    <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !(values.description && !errors.description) &&
                      touched.description
                    }
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={isLoading || !isValid}
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Update File
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
AuditFilesUpdate.prototype = {
  cypressId: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  onFileUpdate: PropTypes.func.isRequired,
};
export default AuditFilesUpdate;
