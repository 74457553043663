import React, { useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useRoles, useToast } from "hooks";
import { useParams } from "react-router-dom";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import { upload } from "utils/DeApi";
import { compressImage } from "utils/UploadUtils";
import ErrorHandler from "components/ui/ErrorHandler";
import DragAndDrop from "components/common/DragAndDrop";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { convertToMB, SUPPORTED_FORMATS } from "utils/UploadUtils";
import { MAX_FILE_SIZE } from "features/assessment/constants";

const FacilityFileUpload = ({ onFileUploaded }) => {
  const { facilityId } = useParams();
  const toast = useToast();
  const { isCertifier } = useRoles();

  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const uploadMultipleFiles = (files, description) => {
    setError(null);
    setIsLoading(true);

    const filesPromises = files.map((file) => {
      const uploadData = new FormData();

      uploadData.append("document", file);
      uploadData.append("description", description);

      const auditFilePromise = upload(
        `facilities/${facilityId}/documents`,
        uploadData
      );
      subscribedPromises.current.push(auditFilePromise);

      return auditFilePromise.promise;
    });

    Promise.all(filesPromises)
      .then((response) => {
        handleClose();
        onFileUploaded(response.map(({ data }) => data));
        toast.success("Sucess", "Files uploaded successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
  };

  const uploadOrDragFile = async (files, setFieldValue) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.startsWith("image")) {
        files[i] = await compressImage(files[i]);
      }
    }
    setFieldValue("files", files);
    return [...files];
  };

  const schema = yup.object().shape({
    description: yup
      .string()
      .required("The Description is required")
      .min(2, "Description is too Short!")
      .max(255, "Description is too Long!"),
    files: yup
      .array()
      .min(1, "The file is required")
      .required("The file is required")
      .test("max-fileSize", "File Size is too large", (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > MAX_FILE_SIZE) {
              return false;
            }
          }
        }
        return true;
      }),
  });

  const resetForm = () => {
    setError(false);
  };

  useEffect(() => {
    if (!show) resetForm();
  }, [show]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  if (isCertifier) return <></>;

  return (
    <>
      <Button
        size="sm"
        className="float-end"
        onClick={handleShow}
        variant="primary"
        data-cy={`btn-bulk-upload`}
      >
        <span translate="no" className="material-symbols-outlined md-16 me-2">
          upload_file
        </span>
        Bulk Upload
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Upload Multiple Files</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            const { files, description } = values;
            uploadMultipleFiles(files, description);
          }}
          initialValues={{
            files: [],
            description: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="file" className="mb-3">
                  <Form.Label>
                    Upload Files <RequiredAsterisk />
                  </Form.Label>
                  <DragAndDrop
                    onDrop={(files) => uploadOrDragFile(files, setFieldValue)}
                    maxSize={convertToMB(MAX_FILE_SIZE)}
                    formats={SUPPORTED_FORMATS}
                  />
                  {values?.files.length && errors.files ? (
                    <small className="text-danger">{errors.files}</small>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>
                    Description
                    <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      !(values.description && !errors.description) &&
                      touched.description
                    }
                    isValid={values.description && !errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description && touched.description ? (
                      <small>{errors.description}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={isLoading}>
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Upload File(s)
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

FacilityFileUpload.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
};

export default FacilityFileUpload;
