import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { download } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { formatDateRange } from "utils/StringUtils";
import { get } from "utils/DeApi";
import { useParams } from "react-router-dom";
import { useToast } from "hooks";

const ActionItemFileExport = ({ filters, endpoint = "", handleError }) => {
  const { createdAt, updatedDate } = filters || {};
  const organization = useContext(OrganizationContext);
  const { auditId } = useParams();
  const toast = useToast();

  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = (link) => {
    copyTextToClipboard(link)
      .then(() =>
        toast.success("Success", "Your link has been copied to the clipboard")
      )
      .catch((err) => handleError(err));
  };
  const paramsForApi = () => {
    return {
      ...(filters?.organization?.organizationId !== "all"
        ? {
            "filter[organization_id]": filters?.organization?.organizationId,
          }
        : {}),
      ...(filters?.level?.filter !== "all"
        ? { "filter[type]": filters?.level?.filter }
        : {}),

      ...(filters?.assignedBy?.subscriberId
        ? {
            "filter[assigned_by]": filters?.assignedBy?.subscriberId,
          }
        : {}),
      ...(filters?.assignedTo?.subscriberId
        ? {
            "filter[assigned_to]": filters?.assignedTo?.subscriberId,
          }
        : {}),
      ...(!!filters?.updatedDate?.length
        ? {
            "filter[updated_at]": formatDateRange(updatedDate),
          }
        : {}),
      ...(!!filters?.createdAt?.length
        ? {
            "filter[created_at]": formatDateRange(createdAt),
          }
        : {}),
      ...(!!filters?.entity?.facilityId && !filters?.audit?.auditId
        ? {
            "filter[facility_id]": filters?.entity?.facilityId,
          }
        : {}),
      ...(!!filters?.questionId && !!filters?.auditIdFromParam
        ? {
            "filter[question_id]": filters?.questionId,
          }
        : {}),
      ...(!!filters?.audit?.auditId
        ? {
            "filter[audit_id]": filters?.audit?.auditId,
          }
        : {}),
      ...(!!filters?.search
        ? {
            "filter[search]": filters?.search,
          }
        : {}),
      ...(!!filters?.id
        ? {
            "filter[id]": filters?.id,
          }
        : {}),
      ...(filters?.type?.filter !== "all"
        ? { "filter[action_item_type]": filters?.type?.filter }
        : {}),
    };
  };

  const handleActionItemExport = () => {
    handleError(null);
    setIsLoading(true);

    const actionItemCsvPromise = download(
      `${endpoint || `organizations/${organization?.id}/export-action-items`}`,
      {
        params: {
          ...paramsForApi(),
        },
      }
    );

    let fileName = `${organization?.name} - Action Items.xlsx`;

    if (!filters?.organization?.name && endpoint)
      fileName = `All  Action Items.xlsx`;

    if (filters?.organization?.name !== "all" && filters?.organization?.name)
      fileName = `${filters?.organization?.name} - Action Items.xlsx`;

    actionItemCsvPromise.promise
      .then((resp) => {
        toast.success("Success", "Your file has been downloaded");
        const url = window.URL.createObjectURL(new Blob([resp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        !error.isCanceled && handleError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(actionItemCsvPromise);
  };

  const handleActionItemGetLink = () => {
    handleError(null);
    setIsLoading(true);

    const actionItemGetLinkPromise = get(
      `organizations/${organization?.id}/action-items-data-link`,
      {
        params: {
          ...paramsForApi(),
        },
      }
    );

    actionItemGetLinkPromise.promise
      .then(({ data }) => handleCopyClick(data))
      .catch((error) => {
        !error.isCanceled && handleError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(actionItemGetLinkPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);
  return (
    <>
      {auditId ? (
        <Button
          size="sm"
          variant="outline-secondary"
          className="me-2"
          disabled={isLoading}
          onClick={() => handleActionItemExport()}
        >
          {isLoading ? (
            <Spinner className="me-1" animation="border" size="sm" />
          ) : (
            <span translate="no" className="material-symbols-outlined md-18">
              file_download
            </span>
          )}{" "}
          <span>Export Excel</span>
        </Button>
      ) : (
        <Dropdown autoClose="outside" className="me-2 ">
          <Dropdown.Toggle
            variant="outline-secondary"
            id="protocol-filter"
            className=" w-100 text-start "
            size="sm"
          >
            {isLoading ? (
              <Spinner className="me-2" animation="border" size="sm" />
            ) : (
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                download
              </span>
            )}{" "}
            <span>Export</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-2 w-100">
            <Dropdown.Item onClick={() => handleActionItemExport()}>
              Export Excel{" "}
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                download
              </span>
            </Dropdown.Item>{" "}
            <Dropdown.Divider className="my-2" />
            <Dropdown.Item onClick={() => handleActionItemGetLink()}>
              Get Link{" "}
              <span
                translate="no"
                className="material-symbols-outlined md-16 cursor-pointer ms-1"
              >
                content_copy
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

ActionItemFileExport.propTypes = {
  handleError: PropTypes.func,
};

export default ActionItemFileExport;
