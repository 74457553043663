import { useContext } from "react";

import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { FlexibleFields } from "features/assessment/constants";
import { OrganizationContext } from "contexts/OrganizationProvider";

import FieldRadio from "../FieldRadio";
import InfoTooltip from "../InfoTooltip";
import LabelWeight from "../LabelWeight";
import FieldNumeric from "../FieldNumeric";
import FieldCheckbox from "../FieldCheckbox";
import FieldTextarea from "../FieldTextarea";
import FieldDatetime from "../FieldDatetime";
import FieldClearButton from "../../FieldClearButton";
import FieldCompound from "../FieldCompound/FieldCompound";
import FormatResponseStatic from "../FormatResponseStatic";
import FormatResponseCompute from "../FormatResponseCompute";

const { CHECKBOX_FIELD } = FlexibleFields;

function RecursiveFields({
  q,
  question,
  questions,
  isDisabled,
  isManuscript,
  auditQuestion,
}) {
  const { values, setFieldValue } = useFormikContext();
  const organization = useContext(OrganizationContext);
  const organizationName = organization?.name || "";
  const value = values.dynamic[q.id];
  const isNested = q?.triggerAnswerId && q?.triggerFlexibleQuestionId;
  const hasChildren = !q?.nestedFields?.length > 0 ? "my-3" : "";

  const clearSelection = () => {
    setFieldValue(`dynamic.${q.id}`, {
      type: q.questionTypeId,
      response: q.questionTypeId === CHECKBOX_FIELD ? [] : "",
    });
  };

  if (q.nestedFields.length === 0) {
    return (
      <Form.Group
        controlId={q.id}
        className={`d-flex flex-row ${
          q.properties.hasChildren ? `mt-3 mb-1` : hasChildren
        }`}
      >
        {isNested && (
          <div className="px-1">
            <span
              translate="no"
              className={`material-symbols-outlined md-18 text-primary`}
            >
              subdirectory_arrow_right
            </span>
          </div>
        )}

        <div className="flex-fill">
          <Form.Label className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <strong>{q.prompt}</strong>
              {q.properties.required && <RequiredAsterisk />}
              <InfoTooltip id={q.id} text={q.description} />
            </div>

            <div className="d-flex align-items-center">
              {organizationName.toLowerCase().includes("amazon") ? (
                <FormatResponseStatic question={q} value={value} />
              ) : (
                <FormatResponseCompute question={q} value={value} />
              )}

              <LabelWeight
                question={q}
                value={value}
                auditQuestion={auditQuestion}
              />
              {!isManuscript && q.questionTypeId !== 5 && (
                <FieldClearButton
                  handleOnClick={clearSelection}
                  isDisabled={isDisabled}
                />
              )}
            </div>
          </Form.Label>
          <FieldRadio question={q} value={value} isDisabled={isDisabled} />
          <FieldTextarea question={q} value={value} isDisabled={isDisabled} />
          <FieldNumeric question={q} value={value} isDisabled={isDisabled} />
          <FieldDatetime question={q} value={value} isDisabled={isDisabled} />
          <FieldCheckbox question={q} value={value} isDisabled={isDisabled} />
          <FieldCompound
            question={q}
            questions={questions}
            isDisabled={isDisabled}
          />
        </div>
      </Form.Group>
    );
  }

  return (
    <div className={`d-flex flex-row`}>
      {isNested && (
        <div className="px-1">
          <span
            translate="no"
            className={`material-symbols-outlined md-18 text-primary`}
          >
            subdirectory_arrow_right
          </span>
        </div>
      )}
      <div className="flex-fill">
        <Form.Group
          controlId={q.id}
          className={` ${q.properties.hasChildren ? `mt-3 mb-1` : hasChildren}`}
        >
          <Form.Label className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <strong>{q.prompt}</strong>
              {q.properties.required && <RequiredAsterisk />}
              <InfoTooltip id={q.id} text={q.description} />
            </div>

            <div className="d-flex align-items-center">
              {organizationName.toLowerCase().includes("amazon") ? (
                <FormatResponseStatic question={q} value={value} />
              ) : (
                <FormatResponseCompute question={q} value={value} />
              )}

              <LabelWeight
                question={q}
                value={value}
                auditQuestion={auditQuestion}
              />
              {!isManuscript && q.questionTypeId !== 5 && (
                <FieldClearButton
                  handleOnClick={clearSelection}
                  isDisabled={isDisabled}
                />
              )}
            </div>
          </Form.Label>
          <FieldRadio question={q} value={value} isDisabled={isDisabled} />
        </Form.Group>
        <div
          className="border-start border-end border-primary py-0 py-1 pe-0 border-end-0"
          style={{
            "--bs-border-style": "dashed",
          }}
        >
          {value?.response &&
            q.nestedFields
              .filter(
                ({ triggerAnswerId }) => triggerAnswerId === value?.response
              )
              .map((child) => (
                <RecursiveFields
                  q={child}
                  key={child.id}
                  question={question}
                  questions={questions}
                  isDisabled={isDisabled}
                  isManuscript={isManuscript}
                />
              ))}
        </div>
      </div>
    </div>
  );
}

export default RecursiveFields;
