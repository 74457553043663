import { slugify } from "./StringUtils";
import { saveAs } from "file-saver";

/**
 * @function exportCSV
 * @param {array} data [ [data1,data2,data3,...], [data1,data2,data3,...] , ... ]
 * @param {array} header [ header1, header2, header3, ...]
 * @param {string} fileName
 */
function exportCSV(data = [], header = [], fileName = "download") {
  let csvContent = "";
  if (Array.isArray(header) && header.length > 0) data.splice(0, 0, header); //start, deleteCount, value
  if (!Array.isArray(data)) return;

  data.forEach((infoArray, index) => {
    let dataString = stripCommas(infoArray).join(",");
    csvContent += index < data.length ? dataString + "\n" : dataString;
  });

  renameFile(slugify(fileName), csvContent);
}

function renameFile(title, csvContent) {
  title = title + ".csv";

  /** adding UTF-8 BOM at start of mimetype %EF%BB%BF;  */
  let type = "data:text/csv;charset=UTF-8,%EF%BB%BF;";
  let CSVBlob = new Blob([csvContent], { type: type });

  saveAs(CSVBlob, title);
}

function stripCommas(array = []) {
  return array?.map((data) => '"' + ignoreNonUTF8Chars(data) + '"');
}

function ignoreNonUTF8Chars(str) {
  var utf8 = [];
  var string = "";

  for (var i = 0; i < str?.length; i++) {
    var charCode = str.charCodeAt(i);
    if (charCode <= 127) utf8.push(charCode);
    if (charCode === 8217) utf8.push(39);
  }

  // utf8 = new TextEncoder().encode(str); //might not work in IE

  utf8.forEach((item) => {
    string += String.fromCharCode(item);
  });

  return string;
}

export { exportCSV };
