import React from "react";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";
import Loader from "components/ui/Loader";
import AuditTrailReview from "../AuditTrailReview";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import RenderDynamicMetadata from "../RenderDynamicMetadata/RenderDynamicMetadata";

const AuditTrailListItem = ({ isLoading, error, auditTrails }) => {
  if (isLoading)
    return (
      <tr>
        <td colSpan={7}>
          <Loader />
        </td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td colSpan={7}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (auditTrails && !auditTrails.length)
    return (
      <tr>
        <td colSpan={7}>
          <EmptyStateHandler
            title="No assessment trails"
            description="There are currently no assessment trails to show."
          />
        </td>
      </tr>
    );

  return auditTrails.map((trail) => {
    return (
      <tr key={trail.id}>
        <td>{trail?.logLabel}</td>
        <td>
          <RenderDynamicMetadata
            subjectType={trail?.subjectType}
            audit={trail}
          />
        </td>
        <td>
          {trail.type === "created" && (
            <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
              CREATED
            </Badge>
          )}
          {trail.type === "updated" && (
            <Badge className="bg-warning rounded-0 py-2 px-3 bg-opacity-10 text-warning">
              UPDATED
            </Badge>
          )}
          {trail.type === "deleted" && (
            <Badge className="bg-danger rounded-0 py-2 px-3 bg-opacity-10 text-danger">
              DELETED
            </Badge>
          )}
          {trail.type === "restored" && (
            <Badge className="bg-primary rounded-0 py-2 px-3 bg-opacity-10 text-primary">
              RESTORED
            </Badge>
          )}
        </td>
        <td>
          <small>
            {trail?.causer?.firstName ?? "-"} {trail?.causer?.lastName ?? "-"}
          </small>
          <br />
          <small className="text-muted">{trail?.causer?.email ?? "-"}</small>
        </td>
        <td>
          <small>
            {new Date(trail.createdAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        <td className="text-end">
          {(trail.type !== "deleted" || trail.subjectType === "audit") && (
            <AuditTrailReview
              data-testid={`audit-trail-review-${trail.id}`}
              auditTrail={trail}
            />
          )}
        </td>
      </tr>
    );
  });
};

AuditTrailListItem.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  auditTrails: PropTypes.array.isRequired,
};

export default AuditTrailListItem;
