import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { TEXT_FIELD } from "../constants";
const FieldTextarea = ({ value, question }) => {
  const { setFieldValue, handleBlur, errors, touched } = useFormikContext();
  const fieldError = !value?.response;
  if (question.type !== TEXT_FIELD) return <></>;

  return (
    <>
      <Form.Control
        as="textarea"
        name={question.id}
        isValid={
          value?.response && !errors["metadata"]?.[question?.id]?.response
        }
        isInvalid={
          !(value?.response && !errors.name) && touched?.[question?.id]
        }
        placeholder={`Enter your answer here.`}
        value={value?.response}
        onChange={(ev) => {
          setFieldValue(`metadata.${question.id}`, {
            type: TEXT_FIELD,
            response: ev.target.value,
          });
        }}
        onBlur={handleBlur}
        rows={3}
      />
      <Form.Control.Feedback type="invalid">
        {errors["metadata"]?.[question?.id]?.response &&
        touched?.[question?.id] ? (
          <small>{errors["metadata"]?.[question?.id]?.response}</small>
        ) : null}
      </Form.Control.Feedback>
    </>
  );
};
FieldTextarea.propTypes = {
  value: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
};
export default FieldTextarea;
