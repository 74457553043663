import React, { useContext } from "react";

import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";

import BreadCrumbs from "components/ui/BreadCrumbs";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import Report from "./Report/Report";
import AuditDetails from "./AuditDetails";
import CorrectiveActions from "./CorrectiveActions";
import { breadcrumb, filesBreadcrumb } from "../../utils";

import "./Audit.scss";

const Audit = () => {
  const location = useLocation();
  const { audit } = useContext(AuditContext);

  return (
    <>
      <Helmet>
        <title>{audit?.name || "Assessment"}</title>
      </Helmet>
      <BreadCrumbs
        breadcrumbs={
          location.pathname === `/audits/${audit?.auditId}`
            ? breadcrumb(audit, location.pathname)
            : filesBreadcrumb(audit, location.pathname)
        }
      />
      <Routes>
        <Route path="*" element={<AuditDetails />} />
        <Route path="reports/" element={<Report />} />
        <Route path="corrective-actions/" element={<CorrectiveActions />} />
      </Routes>
    </>
  );
};

export default Audit;
