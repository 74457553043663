import { UserContext } from "contexts/UserProvider";
import { levels } from "features/actionItems/constants";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context/ActionItemProvider";
import { useFetchSubscribers } from "features/actionItems/services";
import isFilterActive from "features/actionItems/utils/isFilterActive";
import React, { useCallback, useContext, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useLocation } from "react-router-dom";
import TypeAheadInput from "../TypeAheadInput";

const AssignToFilter = () => {
  const user = useContext(UserContext);
  const {
    filters: { assignedTo },
    query: { assignedTo: query },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const assignedToFromUrl = urlParams.get("assignedTo");
  const taskableType = urlParams.get("taskableType");

  const { subscribers, isExpanding } = useFetchSubscribers({
    path: "subscribers",
  });

  const onClear = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: { assignedTo: "" },
    });
    dispatch({
      type: "SET_QUERY",
      payload: { assignedTo: "" },
    });
  };

  const updateFilter = useCallback(
    (level = {}, entity = "", audit = "") => {
      dispatch({ type: "SET_FILTERS", payload: { level, entity, audit } });
    },
    [dispatch]
  );

  const prepopulatingLevel = useCallback(() => {
    if (taskableType === "organization") return updateFilter(levels[0]);
    if (taskableType === "facility") return updateFilter(levels[1]);
    if (taskableType === "question") return updateFilter(levels[2]);
    return updateFilter({});
  }, [taskableType, updateFilter]);

  useEffect(() => {
    prepopulatingLevel();
  }, [assignedToFromUrl, prepopulatingLevel, user]);

  return (
    <Col lg={2} className="mb-3 mt-2">
      <small className="text-muted">Assigned To</small>
      <Typeahead
        className={`mt-2 ${isFilterActive(assignedTo?.subscriberId, true)}`}
        id="assigned-to"
        name="assignedTo"
        selected={assignedTo ? [assignedTo] : []}
        isLoading={isExpanding}
        labelKey={(option) => `${option.firstName} ${option.lastName}`}
        placeholder="Select an assignee..."
        onInputChange={(val) =>
          dispatch({ type: "SET_QUERY", payload: { assignedTo: val } })
        }
        onChange={(a) => {
          dispatch({ type: "SET_FILTERS", payload: { assignedTo: a[0] } });
        }}
        options={subscribers || []}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
          const { firstName, lastName } = assignedTo;
          const name = firstName && lastName ? `${firstName} ${lastName}` : "";
          return (
            <TypeAheadInput
              {...inputProps}
              value={name || query || ""}
              onClear={onClear}
            />
          );
        }}
        renderMenu={(results, menuProps) => (
          <Menu className="typehead-menu-alignment" {...menuProps}>
            {results.map((result, index) => (
              <MenuItem
                key={result.subscriberId}
                option={result}
                position={index}
              >
                {result.firstName
                  ? `${result.firstName} ${result.lastName}`
                  : "Load more..."}
                <br />
                <small className="text-muted">{result.email}</small>
              </MenuItem>
            ))}
          </Menu>
        )}
      />
    </Col>
  );
};

export default AssignToFilter;
