import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Dropdown,
  Table,
  Badge,
  Button,
  Form,
} from "react-bootstrap";
import DOMPurify from "dompurify";
import { uniqBy } from "lodash";

import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import QuestionAnswersRow from "../QuestionAnswersRow";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import ActionItemCreate from "features/actionItems/components/common/ActionItemCreate";
import ActionItemFileExport from "features/actionItems/components/common/ActionItemFileExport";

import ActionItemQuestionResponse from "../ActionItemQuestionResponse";
import CorrectiveActionItemBulkCreate from "features/actionItems/components/ActionItems/ActionItemsBulkOperation/CorrectiveActionItemsBulkCreate";

const filterTypes = [
  {
    value: "all",
    label: "All Questions",
  },
  {
    value: 0,
    label: "Questions with Findings",
  },
  {
    value: 1,
    label: "Questions with Best Practices",
  },
];

const CorrectiveActionsQuestions = ({
  open,
  setOpen,
  setCurrentPrompt,
  updateActionItemCount,
  setActionItems,
}) => {
  const [error, setError] = useState("");
  const { audit } = useContext(AuditContext);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState({
    label: "All Questions",
    value: "all",
  });
  const [showApplicable, setShowApplicable] = useState(
    JSON.parse(localStorage.getItem("showApplicable")) || false
  );

  const [filters] = useState({
    type: {
      index: 2,
      name: "Corrective",
      filter: 1,
    },
    audit,
    updatedDate: [],
    createdAt: [],
  });

  const handleSelect = (eventKey) => {
    if (eventKey === "all") {
      setSelectedFilter({
        value: "all",
        label: "All Questions",
      });
      return;
    }
    const selectedValue = filterTypes.find(
      (item) => item.value === Number(eventKey)
    );
    setSelectedFilter(selectedValue);
  };

  const handleShowInapplicable = () => {
    setShowApplicable(!showApplicable);
    localStorage.setItem("showApplicable", !showApplicable);
  };

  const filteredFindings = useMemo(() => {
    return audit?.questions
      .filter(({ observations = [] }) => {
        if (selectedFilter.value === "all") return true;
        return observations?.some(({ responses = [] }) => {
          return responses.find(
            (response) => response?.choice?.type === selectedFilter.value
          );
        });
      })
      .filter(({ observations = [] }) => {
        if (!showApplicable && !!observations.length) {
          const inapplicableObservations = observations.some(
            ({ isInapplicable }) => isInapplicable
          );
          return !inapplicableObservations;
        }
        return observations;
      });
  }, [audit?.questions, selectedFilter.value, showApplicable]);

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="p-1">
        <Card className="border-0">
          <Card.Body>
            <div className="d-flex flex-row mb-2 justify-content-between align-items-center">
              <div></div>
              <div className="d-flex align-items-center flex-wrap">
                <Form className="me-2 mt-1">
                  <Form.Check
                    checked={showApplicable}
                    type="switch"
                    label="Show Inapplicable"
                    onChange={() => handleShowInapplicable()}
                  />
                </Form>
                <ActionItemFileExport
                  filters={filters}
                  label={"Export Excel"}
                  handleError={(err) => setError(err)}
                />
                <Dropdown onSelect={handleSelect}>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    className="border btn-block w-100 text-start text-muted dropdown-toggle bg-white"
                  >
                    {selectedFilter.label}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {filterTypes.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.value}>
                        {item.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="table-responsive position-relative">
              <CorrectiveActionItemBulkCreate
                auditId={audit?.auditId}
                facilityId={audit?.facilityId}
                selectedItems={selectedItems}
                items={filteredFindings}
                modalBtnVariant={"primary"}
                positionClasses={"start-25 top-0 w-50"}
              />
              <Table hover>
                <CorrectiveActionsQuestionsHeader
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  questions={filteredFindings}
                />
                <CorrectiveActionsQuestionsBody
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  questions={filteredFindings}
                  error={error}
                  updateActionItemCount={updateActionItemCount}
                  setActionItems={setActionItems}
                  selectedFilter={selectedFilter}
                  open={open}
                  setOpen={setOpen}
                  setCurrentPrompt={setCurrentPrompt}
                />
              </Table>
              <CorrectiveActionItemBulkCreate
                auditId={audit?.auditId}
                facilityId={audit?.facilityId}
                selectedItems={selectedItems}
                items={filteredFindings}
                modalBtnVariant={"primary"}
                positionClasses={"start-25 bottom-0 w-50"}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CorrectiveActionsQuestions;

const CorrectiveActionsQuestionsHeader = ({
  selectedItems,
  questions,
  setSelectedItems,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(
    function updateIsCheckedStatus() {
      setIsChecked(
        selectedItems.length > 0 &&
          selectedItems.length === questions.length &&
          selectedItems.every((selectedItem) => {
            // exists in questions
            return questions.find(
              (question) => question.questionId === selectedItem
            );
          })
      );
    },
    [selectedItems, questions]
  );

  const handleSelectAllChange = (e) => {
    setSelectedItems(
      e.target.checked ? questions.map((question) => question.questionId) : []
    );
    setIsChecked(e.target.checked);
  };

  return (
    <thead>
      <tr>
        <th>
          {/* <Form.Check
              type="checkbox"
              title="Select all items in this page"
              checked={isChecked}
              onChange={handleSelectAllChange}
            /> */}
        </th>
        <th width="30"></th>
        <th>Question</th>
        <th></th>
      </tr>
    </thead>
  );
};

const CorrectiveActionsQuestionsBody = ({
  questions,
  selectedItems,
  setSelectedItems,
  updateActionItemCount,
  error,
  setActionItems,
  selectedFilter,
  open,
  setOpen,
  setCurrentPrompt,
}) => {
  const { audit } = useContext(AuditContext);

  return (
    <tbody className="table-group-divider">
      {error ? (
        <tr>
          <td colSpan={3}>
            <ErrorHandler error={error} />
          </td>
        </tr>
      ) : Array.isArray(questions) && !questions.length ? (
        <tr>
          <td colSpan={3}>
            <EmptyStateHandler
              className="mb-3 d-flex flex-row"
              title={`No Questions`}
              description={`There are currently no ${selectedFilter.label} for this assessment.`}
            />
          </td>
        </tr>
      ) : (
        <></>
      )}

      {questions.map((question = {}) => {
        const {
          questionId,
          sectionId,
          prompt,
          observations,
          actionItemsCount = 0,
        } = question;
        const handleRowCollapse = () =>
          setOpen((prevStatus) => ({
            ...Object.keys(prevStatus).reduce(
              (attrs, key) => ({
                ...attrs,
                [key]: false,
              }),
              {}
            ),
            [questionId]: !prevStatus[questionId],
          }));

        const handleCurrentPrompt = () =>
          setCurrentPrompt(open[questionId] ? "Action Items" : prompt);

        return (
          <Fragment key={question?.questionId}>
            <tr className={open[questionId] ? "table-primary" : ""}>
              <td>
                {/* <Form.Check
                    type="checkbox"
                    title="Select for bulk update"
                    checked={
                      selectedItems?.find((it) => it === questionId) || false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedItems([...selectedItems, questionId]);
                      } else {
                        setSelectedItems(
                          selectedItems.filter((it) => it !== questionId)
                        );
                      }
                    }}
                  /> */}
              </td>
              <td
                className="cursor-pointer"
                onClick={() => {
                  handleRowCollapse();
                  handleCurrentPrompt();
                }}
              >
                {open[questionId] ? (
                  <span className="material-symbols-outlined md-20">
                    expand_less
                  </span>
                ) : (
                  <span className="material-symbols-outlined md-20">
                    expand_more
                  </span>
                )}
              </td>
              <td
                className="text-wrap cursor-pointer"
                onClick={() => {
                  handleRowCollapse();
                  handleCurrentPrompt();
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(prompt),
                }}
              ></td>
              <td>
                <div className="d-flex justify-content-end gap-2 align-items-center">
                  <Badge className="py-2 px-3 bg-primary text-white">
                    <strong>{actionItemsCount}</strong>
                  </Badge>
                  <ActionItemCreate
                    questionPrompt={prompt}
                    members={uniqBy(audit?.subscribers, "subscriberId")}
                    description={""}
                    className="text-nowrap py-0"
                    variant="outline-primary"
                    auditId={audit?.auditId}
                    facilityId={audit?.facilityId}
                    taskableId={questionId}
                    taskableType={"question"}
                    onActionItemCreated={(newActionItem) => {
                      updateActionItemCount(questionId, actionItemsCount + 1);
                      setActionItems((prevState) => [
                        newActionItem,
                        ...prevState,
                      ]);
                    }}
                  >
                    <ActionItemQuestionResponse
                      setOpen={setOpen}
                      question={question}
                      observations={observations}
                      handleRowCollapse={handleRowCollapse}
                      handleCurrentPrompt={handleCurrentPrompt}
                    />
                  </ActionItemCreate>
                </div>
              </td>
            </tr>
            {!!open[questionId] && (
              <tr className="border-bottom table-light border-1">
                <td colSpan={12} className="border-0">
                  <Button
                    size="sm"
                    as={Link}
                    variant="link"
                    className="mb-2"
                    to={`/audits/${audit.auditId}/sections/${sectionId}/questions/${questionId}`}
                  >
                    Go To Question
                  </Button>
                  <QuestionAnswersRow observations={observations} />
                </td>
              </tr>
            )}
          </Fragment>
        );
      })}
    </tbody>
  );
};
