import React, { useRef, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";

import { post } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import RequiredAsterisk from "components/ui/RequiredAsterisk";
import { useRoles } from "hooks";

import GroupTypeahead from "../../common/GroupTypeahead";
import ProtocolTypeahead from "../../common/ProtocolTypeahead";
import PropTypes from "prop-types";
import EntityTypeahead from "features/entity/components/ui/EntityTypeahead";

export default function OrganizationAuditCreate({
  organizationId,
  onAssessmentCreated,
}) {
  const { isCertifier, isContributor } = useRoles();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const subscribedPromises = useRef([]);

  const validationSchema = yup.object().shape({
    assessmentName: yup
      .string()
      .min(2, "Assessment name is too Short!")
      .max(100, "Assessment name is too Long!")
      .required("Assessment name is required"),
    assessmentDescription: yup
      .string()
      .min(2, "Description is too Short!")
      .max(255, "Description is too Long!")
      .notRequired(),
    protocol: yup.array().length(1).required(),
    group: yup.array(),
    entityName: yup.object({
      name: yup.string(),
      facilityId: yup.string(),
    }),
    name: yup.string().required("Entity name is required"),
  });

  const onHide = () => {
    setShow(false);
    setError(undefined);
  };

  const createAudit = (audit = {}) => {
    setError("");
    setIsLoading(true);
    const auditPromise = post("audits", {
      ...audit,
      organizationId: organizationId,
    });
    auditPromise.promise
      .then(({ data }) => {
        if (data) {
          onAssessmentCreated(data);
          onHide();
        }
      })
      .catch((error) => {
        !error.isCanceled && setError(error.data.message);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(auditPromise);
  };

  if (isCertifier || isContributor) return <></>;

  return (
    <>
      <Button size="sm" variant="primary" onClick={() => setShow(!show)}>
        <span translate="no" className="material-symbols-outlined md-18">
          add
        </span>{" "}
        New Assessment
      </Button>
      <Offcanvas
        className="w-fixed-640 overflow-auto"
        show={show}
        onHide={onHide}
        placement="end"
        scroll
      >
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>New Assessment</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="overflow-auto">
          <Formik
            validateOnMount={true}
            initialValues={{
              entityName: { name: "", facilityId: "" },
              assessmentName: "",
              assessmentDescription: "",
              protocol: [],
              group: [],
              name: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              createAudit({
                name: values.assessmentName,
                description: values.assessmentDescription,
                auditGroupId:
                  Array.isArray(values.group) && values.group.length
                    ? values.group.pop().id
                    : "",
                protocolId: values.protocol.pop().protocolId,
                facilityId: values?.entityName?.facilityId || "",
              });
            }}
          >
            {({
              isValid,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Offcanvas.Body className="h-100vh">
                  <Form.Group controlId="entityName" className="mb-3">
                    <Form.Label>
                      Entity Name <RequiredAsterisk />
                    </Form.Label>
                    <EntityTypeahead
                      onChange={(selected) => {
                        const entity = selected[0] || "";
                        setFieldValue("entityName", entity);
                        setFieldTouched("entityName", true);
                        setFieldValue("name", entity?.name);
                      }}
                      selected={values.entityName ? [values.entityName] : []}
                      placeholder="Search or select an entity"
                      isInvalid={!!errors.entityName && touched.entityName}
                      isValid={
                        !errors.entityName &&
                        touched.entityName &&
                        values.entityName
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.entityName?.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="protocol" className="mb-3">
                    <Form.Label>
                      Assessment Protocol <RequiredAsterisk />
                    </Form.Label>
                    <ProtocolTypeahead
                      name="protocol"
                      isValid={touched.protocol && !errors.protocol}
                      onChange={(protocol) =>
                        setFieldValue("protocol", protocol)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.protocol}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="assessmentName">
                    <Form.Label>
                      Assessment Name <RequiredAsterisk />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="assessmentName"
                      value={values.assessmentName}
                      onChange={handleChange}
                      placeholder="Assessment Name"
                      onBlur={handleBlur}
                      isInvalid={
                        touched.assessmentName && !!errors.assessmentName
                      }
                      isValid={touched.assessmentName && !errors.assessmentName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.assessmentName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="assessmentDescription"
                  >
                    <Form.Label>Assessment Description (optional)</Form.Label>

                    <Form.Control
                      as="textarea"
                      name="assessmentDescription"
                      value={values.assessmentDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Assessment Description"
                      isValid={
                        touched.assessmentDescription &&
                        !errors.assessmentDescription &&
                        values.assessmentDescription
                      }
                      isInvalid={
                        touched.assessmentDescription &&
                        !!errors.assessmentDescription
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.assessmentDescription}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {values?.entityName?.facilityId && (
                    <Form.Group className="mb-3" controlId="group">
                      <Form.Label>
                        Group Assignment (optional) <br />
                        <small>
                          An assessment can only be assigned to{" "}
                          <strong>1</strong> group
                        </small>
                      </Form.Label>

                      <GroupTypeahead
                        name="group"
                        allowNew={true}
                        selected={values?.group}
                        placeholder="Select or create a group"
                        isValid={
                          values?.group && !errors?.group && touched.group
                        }
                        facilityId={values.entityName.facilityId}
                        onChange={(group) => setFieldValue("group", group)}
                      />
                    </Form.Group>
                  )}
                  {error && <ErrorHandler error={error} />}
                </Offcanvas.Body>
                <div className="sticky-bottom p-3 bg-light text-end">
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    variant="primary"
                    disabled={isLoading || !isValid}
                    className="ms-2"
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Create Assessment
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Offcanvas>
    </>
  );
}

OrganizationAuditCreate.propTypes = {
  entityList: PropTypes.array,
  organizationId: PropTypes.string.isRequired,
  onAssessmentCreated: PropTypes.func.isRequired,
};
