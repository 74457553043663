import React from "react";
import * as Sentry from "@sentry/react";
import { ResetPassword } from "features/authentication";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";

const ResetPasswordPage = () => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("location", "ResetPasswordPage");
      }}
      fallback={({ error, resetError }) => (
        <ErrorBoundaryFallback error={error} resetError={resetError} />
      )}
    >
      <ResetPassword />
    </Sentry.ErrorBoundary>
  );
};

export default ResetPasswordPage;
