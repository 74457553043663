import React, { useContext } from "react";
import { Col, Dropdown } from "react-bootstrap";

import { doneStatus } from "features/actionItems/constants";
import {
  ActionItemsContext,
  ActionItemsDispatchContext,
} from "features/actionItems/context/ActionItemProvider";
import isFilterActive from "features/actionItems/utils/isFilterActive";

const StatusFilter = () => {
  const {
    filters: { status },
  } = useContext(ActionItemsContext);
  const dispatch = useContext(ActionItemsDispatchContext);

  return (
    <Col lg={2} className="mb-3">
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-done"
          className={`w-100 text-truncate border border-gray-900 ${isFilterActive(
            status?.name
          )}`}
        >
          {status?.name || "Status"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {doneStatus.map((s) => (
            <Dropdown.Item
              key={s.index}
              active={s.index === status?.index}
              onClick={() => {
                dispatch({ type: "SET_FILTERS", payload: { status: s } });
              }}
            >
              {s.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  );
};

export default StatusFilter;
