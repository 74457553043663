import { useContext } from "react";
import PropTypes from "prop-types";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { colorMap } from "features/assessment/constants";

const FormatResponse = ({ options, index, label }) => {
  const organization = useContext(OrganizationContext);

  if (index < 0) return <></>;
  if (!label.toLowerCase().includes("conformity")) return <></>;
  if (!organization?.name?.toLowerCase().includes("amazon")) return <></>;

  return (
    <span
      className="float-end rounded-0 badge px-2 py-1"
      style={{ backgroundColor: colorMap[index]?.color }}
    >
      <strong
        className={index === 2 || index === 3 || index === 6 ? "text-dark" : ""}
      >
        {options[index]?.value}
      </strong>
    </span>
  );
};

FormatResponse.propTypes = {
  value: PropTypes.any,
  index: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
};

export default FormatResponse;
