import React from "react";

import { Form, Pagination } from "react-bootstrap";

import { sanitizeHTML } from "utils/StringUtils";

const MetaPagination = ({ meta, setPage, perPage, setPerPage }) => {
  if (meta?.total <= 0) return <span />;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex gap-2 align-items-end justify-content-between">
        <Pagination className="mb-1">
          {Array.isArray(meta?.links) &&
            meta?.links.map((link) => {
              return (
                <Pagination.Item
                  key={crypto.randomUUID()}
                  active={link?.active}
                  disabled={!link?.url}
                  onClick={() => {
                    if (link?.url) {
                      const searchableURL = new URL(link?.url);
                      const URLParams = new URLSearchParams(
                        searchableURL.search
                      );
                      setPage(URLParams.get("page"));
                    }
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(link?.label),
                    }}
                  />
                </Pagination.Item>
              );
            })}
        </Pagination>
        <div className="d-flex flex-row mb-1">
          <div className="me-3 pt-1">
            <small>
              Showing<span className="mx-1">{meta?.from}</span>-
              <span className="mx-1">{meta?.to}</span>of
              <span className="ms-1">{meta?.total}</span>
            </small>
          </div>
          <Form.Group className="mb-1">
            <Form.Select
              size="sm"
              onChange={(e) => {
                setPage(1);
                setPerPage(e.target.value);
              }}
              value={perPage}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
            </Form.Select>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default MetaPagination;
