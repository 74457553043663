import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Card, Col } from "react-bootstrap";

import { get } from "utils/DeApi";
import ReportTrend from "components/common/ReportTrend";
import ExpandFullScreen from "components/ui/ExpandFullScreen";
import { aggregationOfBestPracticesAndFindings } from "helper/reporting";

import "./ReportBestPractices.scss";

const ReportBestPracticeAndFindings = ({ audit }) => {
  const { auditId } = audit;

  const [auditBestPracticesData, setAuditBestPracticesData] = useState();
  const subscribedPromises = useRef([]);

  useEffect(() => {
    function fetchBestPracticesAndFindings() {
      const findingsPromise = get(`/audits/${auditId}/answer-type-stats`, {
        params: { groupBy: "principle" },
      });
      findingsPromise.promise
        .then(({ data }) => {
          const aggregatedData = aggregationOfBestPracticesAndFindings(data);
          setAuditBestPracticesData(aggregatedData);
        })
        .catch((error) => {
          console.error(error);
          setAuditBestPracticesData({});
        });
      subscribedPromises.current.push(findingsPromise);
    }
    if (auditId) {
      fetchBestPracticesAndFindings();
    }
  }, [auditId]);

  const bestPracticeZeroValues =
    (auditBestPracticesData &&
      auditBestPracticesData?.bestPractise?.every((item) => item === 0)) ||
    auditBestPracticesData?.length === 0;

  const findingZeroValues =
    (auditBestPracticesData &&
      auditBestPracticesData?.finding?.every((item) => item === 0)) ||
    auditBestPracticesData?.length === 0;

  if (!auditBestPracticesData) return <></>;
  if (bestPracticeZeroValues && findingZeroValues) return <></>;

  return (
    <Col className="my-2" xs={12}>
      <Card>
        <Card.Body>
          <div className="d-flex flex-row">
            <p className="text-center w-100 text-truncate mb-0 pt-1 flex-fill">
              Findings & Best Practices Across Principles
              <small className="text-muted">&nbsp;&nbsp;{audit?.name}</small>
            </p>
            <span>
              <ExpandFullScreen
                title={"Findings & Best practices across principles"}
              >
                <ReportTrend
                  size="square-container"
                  labels={{
                    finding: "Findings",
                    bestPractise: "Best Practices",
                  }}
                  data={auditBestPracticesData}
                  chartHeight={320}
                />
              </ExpandFullScreen>
            </span>
          </div>
          <hr />
          <ReportTrend
            size="square-container"
            labels={{
              finding: "Findings",
              bestPractise: "Best Practices",
            }}
            data={auditBestPracticesData}
            chartHeight={320}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};

ReportBestPracticeAndFindings.propTypes = {
  isBestPracticeData: PropTypes.func,
};

export default ReportBestPracticeAndFindings;
