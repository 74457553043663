import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ManagePortfolio from "./ManagePortfolio";
import PortfolioDelete from "./PortfolioDelete";

import { useFetchPortfolioById } from "features/organization/services";
import PortfolioFacilities from "./PortfolioFacilities";

const Portfolios = ({ setPortfolios }) => {
  const { portfolioId } = useParams();
  const navigate = useNavigate();

  const { portfolio = {}, setPortfolio } = useFetchPortfolioById({
    portfolioId,
  });

  const onPortfolioUpdated = (p) => {
    setPortfolios((prevState) => {
      return prevState.map((portfolio) => {
        if (portfolio.id === p.id) return p;
        return portfolio;
      });
    });
  };
  const onPortfolioDeleted = (p) => {
    setPortfolios((prevState) => {
      return prevState.filter((portfolio) => {
        return portfolio.id !== p.id;
      });
    });
    navigate("/");
  };

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12} xl={8} className="mb-3">
        <div className="d-flex flex-row mb-3">
          <div className="flex-fill">
            <h4 translate="no" className="pt-1">
              {portfolio?.name || "portfolio"}
            </h4>
          </div>
          <div>
            <ManagePortfolio
              portfolio={portfolio}
              onManagePortfolio={(p) => {
                setPortfolio(p);
                onPortfolioUpdated(p);
              }}
            />
            <PortfolioDelete
              portfolio={portfolio}
              onPortfolioDeleted={onPortfolioDeleted}
            />
          </div>
        </div>
        <PortfolioFacilities portfolioId={portfolioId} />
      </Col>
    </>
  );
};

Portfolios.propTypes = {
  portfolios: PropTypes.array,
  onPortfolioUpdated: PropTypes.func,
  onPortfolioDeleted: PropTypes.func,
};

export default Portfolios;
