import { useRoles } from "hooks";
import { truncate } from "lodash";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import AuditMemoUpdate from "../AuditMemoUpdate";
import AuditMemoDelete from "../AuditMemoDelete";

const AuditMemoListItem = ({ error, isLoading, memos, setMemos }) => {
  const { isCertifier } = useRoles();

  const onMemoDelete = (deletedMemo) => {
    setMemos((prevMemo) => {
      return prevMemo.filter((memo) => {
        return deletedMemo?.id !== memo?.id;
      });
    });
  };

  const onMemoUpdate = (updatedMemo) => {
    setMemos((prevMemos) => {
      return prevMemos.map((memo) => {
        if (updatedMemo?.id === memo?.id) return updatedMemo;
        return memo;
      });
    });
  };

  if (isLoading)
    return (
      <tr className="text-center">
        <td colSpan={8}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={8}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (!memos) return <></>;

  if (!memos.length)
    return (
      <tr>
        <td colSpan={8}>
          <EmptyStateHandler
            className="mb-3 d-flex flex-row"
            title="No Notepad Found"
            description="Notepad will appear here once added"
          />
        </td>
      </tr>
    );

  return memos.map((memo) => {
    const { id, key, value, createdAt, updatedAt } = memo;
    return (
      <tr key={id}>
        <td className="text-wrap">
          <span className="text-wrap">
            {truncate(key, {
              length: 25,
            })}
          </span>
        </td>
        <td className="text-wrap">{value}</td>
        <td>
          <small>
            {new Date(createdAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        <td>
          <small>
            {new Date(updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        {!isCertifier && (
          <td className="text-center">
            <div className="d-flex flex-row">
              <AuditMemoUpdate memo={memo} onMemoUpdate={onMemoUpdate} />
              <AuditMemoDelete memo={memo} onMemoDelete={onMemoDelete} />
            </div>
          </td>
        )}
      </tr>
    );
  });
};

export default AuditMemoListItem;
