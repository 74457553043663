import { useCallback } from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function useShowEditHistory(filters, sortField, sortDirection, labels) {
  const getFieldLabel = (field) => {
    switch (field.toLowerCase()) {
      case "risk":
        return labels.risk;
      case "conformity":
      case "conformitylevel":
        return labels.conformity;
      case "compliance":
        return labels.compliance;
      default:
        return field.charAt(0).toUpperCase() + field.slice(1);
    }
  };

  const filterAndSortHistory = useCallback(
    (history) => {
      const filtered = history.filter((entry) => {
        const matchesUser =
          !filters.userId ||
          `${entry.userFirstName} ${entry.userLastName}`
            .toLowerCase()
            .includes(filters.userId.toLowerCase());

        const matchesType =
          !filters.fieldType ||
          entry.field.toLowerCase().includes(filters.fieldType.toLowerCase());

        const entryDate = moment(entry.timestamp, "M/D/YY, h:mm A");
        const startDate = filters.startDate
          ? moment(filters.startDate).startOf("day")
          : null;
        const endDate = filters.endDate
          ? moment(filters.endDate).endOf("day")
          : null;

        const matchesDateRange =
          (!startDate || entryDate.isSameOrAfter(startDate)) &&
          (!endDate || entryDate.isSameOrBefore(endDate));

        return matchesUser && matchesType && matchesDateRange;
      });

      if (sortField === "timestamp") {
        return filtered.sort((a, b) => {
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
          return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
        });
      } else {
        return filtered.sort((a, b) => {
          const aValue = a[sortField];
          const bValue = b[sortField];

          if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
          if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
          return 0;
        });
      }
    },
    [filters, sortField, sortDirection]
  );

  const renderHistoryEntry = useCallback(
    (entry, index) => {
      const userInitials = `${entry.userFirstName?.[0] || ""}${
        entry.userLastName?.[0] || ""
      }`.toUpperCase();
      const userName = `${entry.userFirstName || ""} ${
        entry.userLastName || ""
      }`;

      const renderValue = (value) => {
        if (value === "Unanswered") {
          return <span className="text-muted">Unanswered</span>;
        }
        return value;
      };

      return (
        <tr key={`${entry.type}-${entry.id}-${index}`}>
          <td>{entry.timestamp}</td>
          <td>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${entry.id}-${index}`}>
                  {userName}
                </Tooltip>
              }
            >
              <div
                className="rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  width: "32px",
                  height: "32px",
                  fontWeight: "bold",
                  backgroundColor: "#e9f0ea",
                  color: "#018219",
                }}
              >
                {userInitials}
              </div>
            </OverlayTrigger>
          </td>
          <td className="text-break" style={{ minWidth: "150px" }}>
            {getFieldLabel(entry.field)}
          </td>
          <td className="text-break">{renderValue(entry.oldValue)}</td>
          <td className="text-break">{renderValue(entry.newValue)}</td>
        </tr>
      );
    },
    [labels]
  );

  return {
    filterAndSortHistory,
    renderHistoryEntry,
  };
}
