import React, { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";
import PropTypes from "prop-types";
import { Button, Modal, Spinner, Form } from "react-bootstrap";

import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
const NotePadDelete = ({ memo, onMemoDelete }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const toast = useToast();

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setIsChecked(false);
  };

  const deleteMemo = () => {
    setIsLoading(true);
    const memoPromise = destroy(`/memos/${memo?.id}`);
    memoPromise.promise
      .then((response) => {
        onMemoDelete(memo);
        handleClose();
        setIsLoading(false);
        toast.success("Success", `Notepad has been deleted successfully`);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
    subscribedPromises.current.push(memoPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <>
      <div onClick={handleShow}>
        <span
          translate="no"
          className="material-symbols-outlined md-20 cursor-pointer text-danger"
        >
          delete
        </span>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="mt-2">
          <div className="align-items-center bg-danger bg-opacity-10 d-flex justify-content-center mb-3 mx-auto rounded-circle text-center text-danger icon-circle">
            <span className="material-symbols-outlined md-24">warning</span>
          </div>
          <h2 className="text-center pt-2">
            Delete Notepad -{" "}
            <small translate="no" className="text-muted">
              {memo?.key}
            </small>
          </h2>
          <p className="mx-4 mt-2">
            <Form.Group>
              <Form.Check
                label={
                  <small>
                    By checking the box, I confirm that I want to delete this
                    Notepad. I understand that this cannot be undone.
                  </small>
                }
                onClick={() => {
                  setIsChecked(!isChecked);
                }}
              />
            </Form.Group>
          </p>
          {error && show && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            className="me-2"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={deleteMemo}
            size="sm"
            disabled={!isChecked || isLoading}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}{" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
NotePadDelete.propTypes = {
  memo: PropTypes.object,
  onMemoDelete: PropTypes.func,
};

export default NotePadDelete;
