import React, { useContext, useState } from "react";

import moment from "moment";
import { useParams } from "react-router-dom";
import { Card, Col, Collapse, Row } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { useAuditMapping } from "features/assessment/hooks";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";
import {
  useCreateOrUpdateObservation,
  useFetchCertifierComments,
} from "features/assessment/services";

import CommentCreate from "./CommentCreate";
import CommentUpdate from "./CommentUpdate";
import CommentDelete from "./CommentDelete";
import { prepSubmitObservationValues } from "../QuestionUtils";

import "./InspectorComment.scss";

function InspectorComments({ observation, formRef }) {
  const VERTICAL_ID = process.env.REACT_APP_VERTICAL_ID;
  const { audit } = useContext(AuditContext);
  const [open, setOpen] = useState(false);
  const { questionId, auditId } = useParams();
  const { responseUpdateMapping, observationCreateOrUpdateMapping } =
    useAuditMapping();

  const {
    error: isExpandError,
    isLoading: isExpanding,
    handleOnSubmit,
  } = useCreateOrUpdateObservation({
    onObservationSuccess: (observation) => {
      observationCreateOrUpdateMapping(observation);
    },
    onResponsesSuccess: (responses, observation) => {
      responseUpdateMapping(responses, observation);
    },
  });

  const { error, isLoading, comments, setComments } = useFetchCertifierComments(
    {
      observationId: observation?.observationId,
      fetchByDefault:
        VERTICAL_ID !== "653ffd70aed8a" &&
        audit?.protocol?.hasCertificationComments,
    }
  );

  const handleShow = () => setOpen((prev) => !prev);

  const createObservation = () => {
    const formData = prepSubmitObservationValues(formRef.current.values, {
      auditId,
      parentId: null,
      questionId,
      observationId: observation?.observationId,
    });
    handleOnSubmit(formData);
  };

  if (!audit?.protocol?.hasCertificationComments) return <></>;
  if (VERTICAL_ID === "653ffd70aed8a") return <></>;

  return (
    <>
      <div className="d-flex flex-row">
        <h5
          onClick={
            !!observation?.observationId ? handleShow : createObservation
          }
          role="button"
          className="mb-0 d-flex lh-base"
        >
          {open ? (
            <span translate="no" className="material-symbols-outlined me-2">
              expand_more
            </span>
          ) : (
            <span translate="no" className="material-symbols-outlined me-2">
              expand_less
            </span>
          )}
          Certification & Verification Comments
        </h5>
      </div>

      <Collapse in={open}>
        <div className="ps-3 mt-2">
          {isLoading ? (
            <Loader />
          ) : error ? (
            <ErrorHandler error={error} />
          ) : comments?.length ? (
            <Card className="mb-3">
              <Card.Body>
                {comments.map((comment, index, arr) => {
                  const { commentId, comments } = comment;
                  return (
                    <div key={commentId}>
                      <div xs={12} className="mb-1 mt-1 d-flex flex-row">
                        <div className="flex-fill">
                          <p className="mb-1 pb-0">{comments ?? ""}</p>
                          <p className="text-muted mt-0 pt-0 pb-0 mb-0">
                            <small>
                              {comment?.inspector?.firstName ?? "-"} · updated{" "}
                              {moment(comment.updatedAt).fromNow()}{" "}
                            </small>
                          </p>
                        </div>
                        <div className="text-end ps-2">
                          <div className="d-flex flex-row">
                            <CommentUpdate
                              comment={comment}
                              commentId={commentId}
                              observationId={observation?.observationId}
                              onCommentUpdated={(updated) => {
                                setComments((prevComments) =>
                                  prevComments.map((comment) => {
                                    if (comment.commentId === updated.commentId)
                                      return updated;
                                    return comment;
                                  })
                                );
                              }}
                            />
                            <CommentDelete
                              commentId={commentId}
                              observationId={observation?.observationId}
                              onCommentDeleted={(id) =>
                                setComments((prevComments) =>
                                  prevComments.filter(
                                    ({ commentId }) => commentId !== id
                                  )
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {arr.length !== ++index && <hr />}
                    </div>
                  );
                })}
              </Card.Body>
            </Card>
          ) : (
            <EmptyStateHandler
              className={`mb-0 d-flex flex-row`}
              title="No Certification & Verification Comments found"
              description="There are currently no certification & verification comments for this observation."
            />
          )}

          <Row>
            <Col>
              <CommentCreate
                className="float-end"
                count={comments?.length ?? 0}
                observationId={observation?.observationId}
                onCommentCreated={(comment) => {
                  setComments((prevComments) => [comment, ...prevComments]);
                }}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
      <hr />
    </>
  );
}

export default InspectorComments;
