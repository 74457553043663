import React from "react";

import * as Sentry from "@sentry/react";
import ErrorBoundaryFallback from "components/common/ErrorBoundaryFallback";
import MyActionItem from "features/profile/components/Profile/MyActionItem";
import { ActionItemsContextProvider } from "features/actionItems/context/ActionItemProvider";

const MyActionItemPage = () => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("location", "MyActionItemPage");
      }}
      fallback={({ error, resetError }) => (
        <ErrorBoundaryFallback error={error} resetError={resetError} />
      )}
    >
      <ActionItemsContextProvider url="action-items">
        <MyActionItem />
      </ActionItemsContextProvider>
    </Sentry.ErrorBoundary>
  );
};

export default MyActionItemPage;
