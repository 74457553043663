import DOMPurify from "dompurify";
import { choiceColorMap } from "features/assessment/components/Audit/AuditDetails/Question/FlexibleQuestions/FieldCompound/helper";
import { parseFlexQuestion } from "features/assessment/components/Audit/AuditDetails/Question/helper";
import moment from "moment";
import React, { Fragment, useContext } from "react";
import NestedQuestion from "../../../NestedQuestion/NestedQuestion";
import ReportHeatmap from "../../../ReportHeatmap";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

function RecursiveNestedQuestions({
  responses = [],
  mergedQuestions = [],
  _showRisk,
  _showNotes,
  _showCompliance,
  _showCasualFactor,
  _showRecommendation,
  _showCorrectiveAction,
  _showConformityLevel,
  questionOptions,
  conformity,
  riskLevels,
  showAttachments,
  flexibleQuestions,
  showEmptyFlexibleQuestions,
  showRiskMatrices,
  index,
  setAnsweredArray,
  heatmapChoicesList = [],
  indent = false,
  sectionId = "",
}) {
  const { audit } = useContext(AuditContext);
  const { sectionFormFields = [] } = audit;
  const capitalizeFirstLetter = (word = "") => {
    if (word === null || word === undefined || !word.length) return "";
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  return (
    <>
      {mergedQuestions
        .sort((a, b) => a.order - b.order)
        .map((response, index) => {
          let {
            id,
            prompt,
            properties,
            nestedFields: nestedQuestions = [],
            children,
          } = response || {};
          let choices = [];
          let notes = "";
          let choice = {};
          let responseDatetime = "";
          const matchResponse = responses.filter(
            (elem) => elem.flexibleQuestionId === id
          );

          if (properties?.fieldType === "checkbox") {
            choices = matchResponse.map((elem) => elem?.choice);
          } else {
            notes = matchResponse[0]?.notes ?? "";
            responseDatetime = matchResponse[0]?.responseDatetime ?? "";
            choice = matchResponse[0]?.choice ?? {};
          }
          nestedQuestions = nestedQuestions?.filter(
            ({ triggerAnswerId = "" }) => triggerAnswerId === choice?.id
          );

          const showNestedQuestions = !!nestedQuestions.length;

          const doesNestedQuestionHaveResponses = !!responses
            ?.filter((elem, index, self) =>
              nestedQuestions?.some(
                ({ id: _id = "" }) => _id === elem.flexibleQuestionId
              )
            )
            .filter(
              ({ notes = "", responseDatetime = "", choice = "" }) =>
                !!notes || !!responseDatetime || !!choice
            ).length;

          const isQuestionToggledForThisSection =
            sectionFormFields
              ?.find(({ flexibleQuestionId: _id }) => _id === id)
              ?.sectionsAvailability?.find(
                ({ sectionId: _sectionId }) => _sectionId === sectionId
              )?.available ?? true;

          if (!isQuestionToggledForThisSection) {
            return <></>;
          }

          if (
            properties?.fieldType === "text" &&
            (notes || showEmptyFlexibleQuestions)
          ) {
            return (
              <tr key={id}>
                <td className="w-50 fw-bold">{prompt}</td>
                <td
                  className="text-capitalize-first"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(notes.replace(/\n/g, "<br/>")),
                  }}
                ></td>
              </tr>
            );
          }
          if (
            properties?.fieldType === "datetime" &&
            (responseDatetime || showEmptyFlexibleQuestions)
          ) {
            const datetime = responseDatetime
              ? moment.utc(responseDatetime).format("MMMM D, yyyy h:mm a")
              : "";
            return (
              <tr key={id}>
                <td className="w-50 fw-bold">{prompt}</td>
                <td>{datetime}</td>
              </tr>
            );
          }
          if (
            properties?.fieldType === "numeric" &&
            (notes || showEmptyFlexibleQuestions)
          ) {
            return (
              <tr key={id}>
                <td className="w-50 fw-bold">{prompt}</td>
                <td className="text-capitalize-first text-break">{notes}</td>
              </tr>
            );
          }
          if (
            properties?.fieldType === "radio" &&
            (choice?.label || showEmptyFlexibleQuestions)
          ) {
            return (
              <tr key={id} className={id}>
                <td className="w-50 fw-bold text-break">{prompt}</td>
                <td className="text-capitalize-first text-break">
                  {choice?.label}

                  {doesNestedQuestionHaveResponses && showNestedQuestions && (
                    <>
                      <p className="ms-3">
                        {nestedQuestions.map((nestedQuestion) => (
                          <NestedQuestion
                            responses={responses}
                            nestedQuestion={nestedQuestion}
                          />
                        ))}
                      </p>
                    </>
                  )}
                </td>
              </tr>
            );
          }
          if (
            properties?.fieldType === "checkbox" &&
            (!!choices?.length || showEmptyFlexibleQuestions)
          ) {
            return (
              <tr key={id}>
                <td className="w-50 fw-bold">{prompt}</td>
                <td>
                  {!!choices?.length &&
                    choices
                      ?.sort((a, b) => a?.order - b?.order)
                      ?.map(({ label = "" }, key) => (
                        <span
                          className="d-flex flex-column mb-1 text-capitalize-first"
                          key={key}
                        >{`- ${label} `}</span>
                      ))}
                </td>
              </tr>
            );
          }
          if (
            properties?.fieldType === "compound" &&
            children?.length > 0 &&
            heatmapChoicesList[index]?.length === 2
          )
            return (
              <React.Fragment key={id}>
                <tr>
                  <td className="w-50 fw-bold">
                    {" "}
                    {prompt + " " + heatmapChoicesList[index][0].prompt}:{" "}
                  </td>
                  <td className="text-capitalize-first">
                    {" "}
                    {`${heatmapChoicesList[index][0].score} - ${heatmapChoicesList[index][0].label}`}
                  </td>
                </tr>
                <tr>
                  <td className="w-50 fw-bold">
                    {" "}
                    {prompt + " " + heatmapChoicesList[index][1].prompt}:{" "}
                  </td>
                  <td className="text-capitalize-first">
                    {" "}
                    {`${heatmapChoicesList[index][1].score} - ${heatmapChoicesList[index][1].label}`}
                  </td>
                </tr>
                {showRiskMatrices && (
                  <tr className="print-component">
                    <td className="w-50 fw-bold">{prompt}</td>
                    <td>
                      {" "}
                      <ReportHeatmap
                        children={choiceColorMap(response.children)}
                        values={parseFlexQuestion(responses)}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );

          return <Fragment key={id} />;
        })}
    </>
  );
}

export default RecursiveNestedQuestions;
