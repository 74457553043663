import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";

import ReportAggregation from "components/common/ReportAggregation";
import ErrorHandler from "components/ui/ErrorHandler";
import moment from "moment";
import ReportTrend from "components/common/ReportTrend";
import { get } from "utils/DeApi";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { dateFilter } from "constants";
import { camelCase } from "lodash";
import { truncate } from "lodash";
import Loader from "components/ui/Loader";
import useFetchOrganizationStats from "features/actionItems/services/useFetchOrganizationStats";

const actionItemStatus = [
  {
    id: 0,
    value: "assigned",
  },
  {
    id: 1,
    value: "submitted",
  },
  {
    id: 2,
    value: "incomplete",
  },
  {
    id: 3,
    value: "accepted",
  },
];

const doneStatus = [
  {
    index: 0,
    name: "All",
    filter: "",
  },
  {
    index: 1,
    name: "Assigned",
    filter: "assigned",
  },
  {
    index: 2,
    name: "Submitted",
    filter: "submitted",
  },
  {
    index: 3,
    name: "Incomplete",
    filter: "incomplete",
  },
  {
    index: 4,
    name: "Accepted",
    filter: "accepted",
  },
];

const labelsData = [
  {
    assigned: "Assigned",
    submitted: "Submitted",
    incomplete: "Incomplete",
    accepted: "Accepted",
  },
  {
    assigned: "Assigned",
  },
  {
    submitted: "Submitted",
  },
  {
    incomplete: "Incomplete",
  },
  {
    accepted: "Accepted",
  },
];

function ActionItemReport() {
  const subscribedPromises = useRef([]);
  const organization = useContext(OrganizationContext);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [filterPeriod, setFilterPeriod] = useState(dateFilter[3]);
  const [actionItemStats, setActionItemStats] = useState();
  const [status, setStatus] = useState(doneStatus[0]);
  const [reportAgg, setReportAgg] = useState([]);
  const [reportTrend, setReportTrend] = useState({});
  const [labels, setLabels] = useState(labelsData[0]);

  const onUpdateStatus = useCallback(
    (s, ind) => {
      setStatus(s);
      setLabels(labelsData[ind]);
      setReportAgg(
        aggregationOfActionItems(actionItemStats).filter((d) =>
          s.filter ? d.name === s.filter : d
        )
      );
      const trendObj = aggregationOfActionItemsTrends(
        actionItemStats,
        actionItemStatus,
        filterPeriod
      );

      Object.keys(trendObj).forEach((key) => {
        if (key !== "timeline" && key !== s.filter && s.filter) {
          delete trendObj[key];
        }
      });

      setReportTrend(trendObj);
    },
    [actionItemStats, filterPeriod]
  );

  const aggregationOfActionItemsTrends = (
    actionItemStats,
    actionItemStatus,
    filterPeriod
  ) => {
    if (!actionItemStatus) return {};
    const trends = actionItemStatus
      .map(({ value }) => value)
      .reduce(
        (accumulator, value) => {
          return {
            ...accumulator,
            [`${camelCase(value)}`]: Array(
              filterPeriod?.timeline?.length ?? 0
            ).fill(0),
          };
        },
        { timeline: filterPeriod?.timeline ?? [] }
      );

    trends.timeline.forEach((month, index) => {
      const aggregationMonth =
        Array.isArray(actionItemStats) &&
        actionItemStats.find(({ period }) => {
          return moment(period, filterPeriod?.remoteDateFormat).isSame(
            moment(month),
            filterPeriod?.aggregationPeriod
          );
        });

      if (aggregationMonth) {
        const { actionItems } = aggregationMonth;
        Object.keys(actionItems)
          .map((key) => {
            return key;
          })
          .forEach((key) => {
            if (trends[key]) trends[key][index] = actionItems[key];
          });
      }
    });

    return {
      ...trends,
      timeline: trends.timeline.map((date) =>
        moment(date, "YYYY-MM-DD").format(filterPeriod?.localDateFormat)
      ),
    };
  };

  const aggregationOfActionItems = (actionItemStats) => {
    const aggregatedData = {};

    actionItemStats.forEach((item) => {
      for (const key in item.actionItems) {
        if (aggregatedData[key]) {
          aggregatedData[key] += item.actionItems[key];
        } else {
          aggregatedData[key] = item.actionItems[key];
        }
      }
    });

    const result = Object.keys(aggregatedData).map((key) => ({
      name: key,
      value: aggregatedData[key],
    }));

    return result;
  };

  const fetchActionItemStats = useCallback(
    (params) => {
      setError("");
      setIsLoading(true);
      const actionItemStatsPromise = get(
        `/organizations/${organization?.id}/action-items-stats`,
        {
          params: {
            period: params,
          },
        }
      );
      actionItemStatsPromise.promise
        .then(({ data }) => {
          const formattedData = data.map((item) => ({
            period: item.period,
            month: item.month,
            year: item.year,
            actionItems: {
              assigned: +item.assigned,
              submitted: +item.submitted,
              incomplete: +item.incomplete,
              accepted: +item.accepted,
            },
          }));
          setActionItemStats(formattedData);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(actionItemStatsPromise);
    },
    [organization?.id]
  );

  useEffect(() => {
    fetchActionItemStats(filterPeriod?.period);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchActionItemStats, filterPeriod?.period]);

  useEffect(() => {
    if (actionItemStats) onUpdateStatus(status, status.index);
  }, [actionItemStats, onUpdateStatus, status]);

  const { stats: orgStats } = useFetchOrganizationStats({
    orgId: organization?.id,
  });

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  const totalActionItems = orgStats?.actionItems ?? 0;
  const acceptedActionItems = orgStats?.actionItemsByStatus.accepted ?? 0;
  const overdueActionItems = orgStats?.actionItemsByStatus.overdue ?? 0;
  const unacceptedActionItems = totalActionItems - acceptedActionItems;
  const dueUnacceptedItems = unacceptedActionItems - overdueActionItems;

  const reportData = [
    { name: "Overdue", value: overdueActionItems },
    { name: "Due", value: dueUnacceptedItems },
  ];

  return (
    <>
      <Row>
        <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
          <small className="text-muted">Status</small>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-done"
              className="w-100 text-start border border-gray-900 bg-white text-secondary"
            >
              {status?.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {doneStatus.map((s) => (
                <Dropdown.Item
                  key={s.index}
                  active={s.index === status.index}
                  onClick={() => onUpdateStatus(s, s.index)}
                >
                  {s.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={12} sm={4} md={3} lg={3} xl={3} className="mb-3">
          <small className="text-muted">Period</small>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-done"
              className="w-100 text-start border border-gray-900 bg-white text-secondary"
            >
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                date_range
              </span>
              {truncate(filterPeriod.text)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dateFilter.map((interval) => (
                <Dropdown.Item
                  key={interval.index}
                  active={interval.index === filterPeriod.index}
                  onClick={() => {
                    setFilterPeriod(interval);
                  }}
                >
                  {interval.text}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} className="border-end">
              <p className="text-center w-100 text-truncate text-capitalize">
                Distribution of Action Items
              </p>
              <hr />
              <ReportAggregation
                size="square-container"
                labels={labels}
                data={reportAgg}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <p className="text-center w-100 text-truncate text-capitalize">
                Comparision between Overdue and Due Action Items
              </p>
              <hr />
              <ReportAggregation
                size="square-container"
                data={reportData}
                labels={{
                  Overdue: "Overdue Action Items",
                  Due: "Due Action Items",
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <p className="text-center w-100 text-truncate text-capitalize">
                Action items trends across
              </p>
              <hr />
              <ReportTrend
                size="square-container"
                labels={labels}
                data={reportTrend}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default ActionItemReport;
