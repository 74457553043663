import { useContext, useEffect, useState } from "react";

import { useRoles } from "hooks";
import { useLocation } from "react-router-dom";

import AuditInfo from "features/assessment/components/common/AuditInfo";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import SideNavigation from "./SideNavigation";
import GroupNavigation from "./GroupNavigation";
import AssigneesFilter from "./AssigneesFilter";
import AuditReportLink from "./AuditReportLink";
import PrinciplesFilter from "./PrinciplesFilter";
import SectionNavigation from "./SectionNavigation";
import CorrectiveActionLink from "./CorrectiveActionLink";

const LeftSidePanel = () => {
  const location = useLocation();
  const { isContributor } = useRoles();
  const { audit } = useContext(AuditContext);

  const [openSection, setOpenSection] = useState(null);

  const filterByPrinciple = (section) => {
    if (!audit?.selectedPrinciple) return true;

    return section.principles.find(({ principleId }) => {
      return principleId === audit?.selectedPrinciple[0]?.principleId;
    });
  };

  const filterByAssignee = (section) => {
    if (!audit?.selectedAssignee) return true;

    const questions = audit.questions.filter(
      ({ sectionId }) => sectionId === section.sectionId
    );

    const assignedMembers = questions.flatMap(
      ({ assignedMembers = [] }) => assignedMembers
    );

    return assignedMembers.find(({ subscriberId }) => {
      return subscriberId === audit?.selectedAssignee[0]?.subscriberId;
    });
  };

  const toggleSectionHandler = (sectionId) => {
    setOpenSection((openSection) =>
      openSection === sectionId ? null : sectionId
    );
  };

  useEffect(() => {
    const pathname = location.pathname;
    if (!pathname.includes("section")) {
      setOpenSection(null);
    } else {
      const path = pathname?.split("/");
      if (path.length > 4) {
        setOpenSection(path[4]);
      }
    }
  }, [location.pathname]);

  return (
    <>
      <AuditInfo />
      <GroupNavigation />
      <SideNavigation />
      <CorrectiveActionLink />
      <AuditReportLink />
      <hr />
      {!isContributor && (
        <>
          <PrinciplesFilter />
          <AssigneesFilter />

          {audit?.protocol.sections
            .filter(filterByAssignee)
            .filter(filterByPrinciple)
            .map((section, index) => (
              <SectionNavigation
                section={section}
                sectionIndex={index}
                key={section.sectionId}
                open={openSection === section.sectionId}
                toggleSectionHandler={toggleSectionHandler}
              />
            ))}
        </>
      )}
    </>
  );
};

export default LeftSidePanel;
