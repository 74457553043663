import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import { FlexibleFields } from "features/assessment/constants";

const { NUMERIC_FIELD } = FlexibleFields;

const FieldNumeric = ({ value, question, isDisabled }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext();
  if (question?.properties?.fieldType !== "numeric") return <></>;
  return (
    <Form.Control
      type="number"
      name={question.id}
      placeholder={`Enter numeric answer here.`}
      value={value?.response || ""}
      onChange={(ev) => {
        setFieldValue(`dynamic.${question.id}`, {
          type: NUMERIC_FIELD,
          response: ev.target.value,
        });
      }}
      onBlur={handleBlur}
      disabled={values.isInapplicable || isDisabled}
      rows={3}
    />
  );
};
FieldNumeric.propTypes = {
  question: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
export default FieldNumeric;
