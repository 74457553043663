import React from "react";
import "./SortColumn.scss";

const SortColumn = ({ tableSortState, column }) => {
  if (tableSortState.sortColumn === column)
    return (
      <span
        translate="no"
        className="material-symbols-outlined mx-1 md-18 text-muted"
      >
        {tableSortState.sortAsc && "arrow_upward"}
        {!tableSortState.sortAsc && "arrow_downward"}
      </span>
    );

  return (
    <span
      translate="no"
      className="material-symbols-outlined mx-1 md-18 text-muted"
    >
      swap_vert
    </span>
  );
};

export default SortColumn;
