import { OrganizationContext } from "contexts/OrganizationProvider";
import { useState, useEffect, useRef, useContext } from "react";
import { get } from "utils/DeApi";

const useFetchProtocols = (args) => {
  const { onSuccess, onError } = args || {};
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [protocols, setProtocols] = useState([]);

  const organization = useContext(OrganizationContext);

  const subscribedPromises = useRef([]);
  const onErrorRef = useRef(onError);
  const onSuccessRef = useRef(onSuccess);

  useEffect(() => {
    function fetchProtocols() {
      setError(null);
      setIsLoading(true);
      const protocolsPromise = get("protocols", {
        params: { organizationId: organization?.id },
      });
      protocolsPromise.promise
        .then(({ data: protocols }) => {
          setProtocols(protocols);
          if (typeof onSuccessRef.current === "function")
            onSuccessRef.current(protocols);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            if (typeof onErrorRef.current === "function")
              onErrorRef.current(error);
          }
        })
        .finally(() => setIsLoading(false));
      subscribedPromises.current.push(protocolsPromise);
    }
    if (organization?.id) fetchProtocols();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization?.id]);

  return { error, isLoading, protocols };
};

export default useFetchProtocols;
