import { useContext } from "react";

import { Typeahead } from "react-bootstrap-typeahead";

import { AuditContext } from "features/assessment/contexts/AuditProvider";

const PrinciplesFilter = () => {
  const { audit, setAudit } = useContext(AuditContext);
  return (
    <Typeahead
      id="principle-typeahead"
      className="mb-2"
      clearButton
      placeholder="Filter by principle"
      labelKey="title"
      onChange={(data) => {
        setAudit((prevAudit) => ({
          ...prevAudit,
          selectedPrinciple: Array.isArray(data) && data.length ? data : null,
        }));
      }}
      options={audit?.principles || []}
      selected={
        Array.isArray(audit?.selectedPrinciple) ? audit?.selectedPrinciple : []
      }
    />
  );
};

export default PrinciplesFilter;
