import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";

import AttendeeDelete from "./AttendeeDelete";
import { useContext } from "react";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

export function isSuncorProtocol(protocolId) {
  return [
    /** Suncor protocol Ids */
    "63f36318badc5",
    "63f363714370a",
    "6543c7189d15d",
    "6543c693751eb",
    "6543c6564aaed",
    "6543c61cbcb1e",
    "6543c60bf1486",
    /** Amazon Tier 11 protocol Ids */
    "65205a3135100",
    "651b30514beb9",
  ].includes(protocolId);
}

const Extras = ({ isDisabled }) => {
  const { values, setFieldValue, handleChange, handleBlur } =
    useFormikContext();

  const { audit } = useContext(AuditContext);

  if (!isSuncorProtocol(audit?.protocolId)) return <></>;

  return (
    <div className="w-100">
      <Row>
        <Form.Group
          controlId="anwer-recommendation"
          className="mb-3"
          as={Col}
          xs={12}
        >
          <Form.Label>
            <strong>ERM coach full name</strong>
          </Form.Label>
          <Form.Control
            name="extras.coach.fullName"
            placeholder="John Doe"
            value={values.extras.coach.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.isInapplicable || isDisabled}
            rows={3}
          />
        </Form.Group>
        <Form.Group controlId="location" className="mb-3" as={Col} xs={6}>
          <Form.Label>
            <strong>Location</strong>
          </Form.Label>
          <Form.Control
            name="extras.location"
            placeholder="Location"
            value={values.extras.location}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.isInapplicable || isDisabled}
            rows={3}
          />
        </Form.Group>
        <Form.Group controlId="audit-date" className="mb-3" as={Col} xs={6}>
          <Form.Label>
            <strong>Date</strong>
          </Form.Label>
          <Form.Control
            name="extras.auditedAt"
            type="date"
            placeholder="Assessment date"
            value={values.extras.auditedAt}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.isInapplicable || isDisabled}
            rows={3}
          />
        </Form.Group>
      </Row>
      <h6 className="mt-3">
        <strong>Attendees at this location</strong>
      </h6>
      {values?.extras?.attendees.map(({ id, fullName, employeeNo }, index) => (
        <div className="d-flex mb-2" key={id}>
          <div className="flex-fill text-capitalize pt-1">
            {++index}. {fullName}
          </div>
          <div className="pt-1">
            <span className="text-muted">{employeeNo}</span>
          </div>
          <div className="ms-2">
            <Button
              size="sm"
              variant="light"
              onClick={() => {
                setFieldValue("attendee.id", id);
                setFieldValue("attendee.fullName", fullName);
                setFieldValue("attendee.employeeNo", employeeNo);
              }}
            >
              <span
                translate="no"
                className="material-symbols-outlined md-16 text-primary"
              >
                edit
              </span>
            </Button>
          </div>
          <div className="ms-2">
            <AttendeeDelete
              attendee={{ id, fullName, employeeNo }}
              onDeleted={({ id }) => {
                if (id) {
                  setFieldValue(
                    "extras.attendees",
                    values?.extras?.attendees.filter((a) => a.id !== id)
                  );
                }
              }}
            />
          </div>
        </div>
      ))}
      <div className="d-flex my-3">
        <Form.Group controlId="location" className="mb-3 flex-fill">
          <Form.Control
            name="attendee.fullName"
            placeholder="Full name"
            value={values.attendee.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.isInapplicable || isDisabled}
            rows={3}
          />
        </Form.Group>
        <Form.Group controlId="employeeNo" className="mb-3 ms-2" xs={6}>
          <Form.Control
            name="attendee.employeeNo"
            placeholder="Employee No"
            value={values.attendee.employeeNo}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.isInapplicable || isDisabled}
            rows={3}
          />
        </Form.Group>
        <div className="ms-2">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => {
              const attendee = {
                ...values.attendee,
                id: values.attendee?.id
                  ? values.attendee.id
                  : Number(new Date()),
              };
              const found = values?.extras?.attendees.find(
                ({ id }) => id === attendee.id
              );
              if (found) {
                const mapped = values?.extras?.attendees.map((item) => {
                  if (item.id === attendee.id) return attendee;
                  return item;
                });
                setFieldValue("extras.attendees", mapped);
              } else {
                const added = values?.extras?.attendees.concat(attendee);
                setFieldValue("extras.attendees", added);
              }

              setFieldValue("attendee.id", "");
              setFieldValue("attendee.fullName", "");
              setFieldValue("attendee.employeeNo", "");
            }}
            className="py-0"
          >
            <span translate="no" className="material-symbols-outlined md-17">
              add
            </span>
          </Button>
        </div>
      </div>
      <Form.Group controlId="corrective-action" className="mt-3 mb-3">
        <Form.Label>
          <strong>Feedback</strong>
        </Form.Label>
        <Form.Control
          as="textarea"
          name="extras.feedback"
          placeholder="Enter your feedback here."
          value={values.extras.feedback}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={values.isInapplicable || isDisabled}
          rows={3}
        />
      </Form.Group>
    </div>
  );
};
Extras.propTypes = {
  isDisabled: PropTypes.bool,
};

export default Extras;
