import React, { useContext, useState } from "react";

import DOMPurify from "dompurify";
import { findKey, isEmpty, pickBy } from "lodash";
import { Col, Row, Tab, Tabs } from "react-bootstrap";

import EmptyStateHandler from "components/ui/EmptyStateHandler";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import LeftSidePanel from "./LeftSidePanel";
import {
  CorrectiveQuestionActionItems,
  CorrectiveActionItems,
} from "./CorrectiveActionItems";
import CorrectiveActionsQuestions from "./CorrectiveActionsQuestions/CorrectiveActionsQuestions";

import "./CorrectiveActions.scss";

const CorrectiveActions = () => {
  const [tabKey, setTabKey] = useState("questions");

  const { audit, setAudit } = useContext(AuditContext);

  const [actionItems, setActionItems] = useState([]);
  const [open, setOpen] = useState({});
  const [currentPrompt, setCurrentPrompt] = useState("");

  const updateActionItemCount = (questionId, num) => {
    setAudit((prevState) => {
      return {
        ...prevState,
        questions: prevState?.questions?.map((q) => {
          if (q.questionId === questionId)
            return {
              ...q,
              actionItemsCount: num,
            };
          return q;
        }),
      };
    });
  };

  const ondeletedActionItem = (questionId) => {
    setAudit((prevState) => {
      return {
        ...prevState,
        questions: prevState?.questions?.map((q) => {
          if (q.questionId === questionId)
            return {
              ...q,
              actionItemsCount: q?.actionItemsCount - 1,
            };
          return q;
        }),
      };
    });
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={4} lg={3} className="scroller mb-3">
        <LeftSidePanel />
      </Col>
      <Col xs={12} sm={12} md={8} lg={9} className="mb-3">
        <div className="ps-0">
          <h4 className="mb-3">Corrective Actions</h4>
        </div>
        <Row className="ps-3">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={8}
            className="bg-white p-3 rounded-2 border"
          >
            <Tabs
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
              variant="tabs"
              className=""
            >
              <Tab eventKey={"questions"} title="Questions" className="px-2">
                <CorrectiveActionsQuestions
                  open={open}
                  setOpen={setOpen}
                  setCurrentPrompt={setCurrentPrompt}
                  updateActionItemCount={updateActionItemCount}
                  setActionItems={setActionItems}
                />
              </Tab>
              <Tab
                eventKey={"actionItems"}
                title="Action Items"
                className="px-2"
              >
                <CorrectiveActionItems tabKey={tabKey} />
              </Tab>
            </Tabs>
          </Col>
          {tabKey === "questions" ? (
            <Col xs={12} md={12} lg={4} className={`scroller`}>
              <h5
                className="pt-2 mb-3 flex-fill"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currentPrompt),
                }}
              ></h5>

              <hr />
              {isEmpty(pickBy(open, Boolean)) ? (
                <EmptyStateHandler
                  title="No Question Selected"
                  description="Select a question to show it's related action items."
                />
              ) : (
                <CorrectiveQuestionActionItems
                  questionId={findKey(pickBy(open, Boolean), (prop) => !!prop)}
                  actionItems={actionItems}
                  auditId={audit?.auditId}
                  facilityId={audit?.facilityId}
                  setActionItems={setActionItems}
                  updateActionItemCount={updateActionItemCount}
                  ondeletedActionItem={ondeletedActionItem}
                  disableViewAll={false}
                />
              )}
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default CorrectiveActions;
