import { uniq } from "lodash";

export const first = (array) => {
  if (!Array.isArray(array)) return null;
  if (array.length === 0) return null;
  return array[0];
};

export function createUniqArray(data) {
  if (!data) return [];
  if (!Array.isArray(data)) return [];
  return uniq(data);
}

export function sortArrayByDate(data) {
  if (!data) return [];
  if (!Array.isArray(data)) return [];
  return data
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .reverse();
}
