import * as yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import ErrorHandler from "components/ui/ErrorHandler";
import { useContext, useState } from "react";

import { login as loginDE } from "utils/DeApi";
import { login } from "utils/BeeApi";
import { UserDispatchContext } from "contexts/UserProvider";
import { useAuth0 } from "@auth0/auth0-react";

const LoginForm = ({
  setIsLoginLoading,
  setRequireTwoFactor,
  setEmail,
  isLoginLoading,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const fetchUser = useContext(UserDispatchContext);

  const AUTH0_CONNECTION = process.env.REACT_APP_AUTH0_CONNECTION;

  const { loginWithRedirect } = useAuth0();

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    password: yup.string().required("Required"),
  });

  const handleSubmit = (email, password) => {
    setError("");
    setEmail(email);
    setIsLoginLoading(true);
    login({
      email,
      password,
    })
      .then((response) => {
        if (response.data.requireTwoFactorCode) {
          setRequireTwoFactor(true);
          setError(null);
          setIsLoginLoading(false);
          return Promise.reject("two-step");
        } else {
          return loginDE(response.data);
        }
      })
      .then(
        (response) =>
          new Promise((resolve) => setTimeout(() => resolve(response), 1000))
      )
      .then((response) => {
        fetchUser();
        setError(null);
        setIsLoginLoading(false);
      })
      .catch((error) => {
        if (error !== "two-step") {
          setError(error);
          setIsLoginLoading(false);
        }
      });
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values) => handleSubmit(values.email, values.password)}
      initialValues={{
        email: "",
        password: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="text-center">
            <h2 className="pre-title">Login to continue</h2>
          </div>
          <hr />
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={values.email && !errors.email}
              placeholder="Enter email"
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              This should be a valid email address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  !(values.password && !errors.password) && touched.password
                }
                placeholder="Password"
                className="border-end-0"
                autoComplete="new-password"
              />
              <Button
                variant="light"
                className="border border-secondary border-start-0 border-opacity-25"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18"
                  >
                    visibility
                  </span>
                ) : (
                  <span
                    translate="no"
                    className="material-symbols-outlined md-18"
                  >
                    visibility_off
                  </span>
                )}
              </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              This is required
            </Form.Control.Feedback>
          </Form.Group>

          {error && <ErrorHandler error={error} />}
          <div className="d-grid gap-2 mb-3">
            <Button type="submit" color="primary" disabled={isLoginLoading}>
              {isLoginLoading && (
                <Spinner
                  className="me-2"
                  animation="border"
                  size="sm"
                  variant="light"
                />
              )}
              <span>Sign in</span>
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                loginWithRedirect({
                  authorizationParams: {
                    connection: AUTH0_CONNECTION,
                  },
                });
              }}
            >
              Sign in with ERM
            </Button>
          </div>
          <p className="text-muted text-center mt-1 mb-1">
            <small>
              By continuing, you agree to our Terms of Service and have read and
              acknowledge our{" "}
              <a
                href="https://www.erm.com/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </small>
          </p>
          <hr />
          <p className="text-center">
            <Link to="/forgot-password">Forgot Password?</Link>
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
