import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import "./ActionItemCommentCreate.scss";
import { post } from "utils/DeApi";
import ErrorHandler from "components/ui/ErrorHandler";
import { useFormikContext } from "formik";

const ActionItemCommentCreate = ({
  actionItemId,
  onActionItemCommentCreated,
}) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    isValid,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext() || {};
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const postActionItemCommentCreate = (body) => {
    setError("");
    setIsLoading(true);
    const actionItemPromise = post(`/action-items/${actionItemId}/comments`, {
      body,
    });

    actionItemPromise.promise
      .then(({ data: comment }) => {
        onActionItemCommentCreated(comment);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setFieldValue("comment", "");
        setFieldTouched("comment", false);
      });

    subscribedPromises.current.push(actionItemPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return (
    <>
      <Form.Group className="mb-3" controlId="comment">
        <Form.Label>Add Comment</Form.Label>
        <Form.Control
          onChange={handleChange}
          onBlur={handleBlur}
          name="comment"
          type="text"
          placeholder="Write a comment"
          as="textarea"
          rows={3}
          value={values?.comment}
          isValid={values?.comment && !error?.comment}
          isInvalid={!(values?.comment && !errors.comment) && touched?.comment}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.comment && touched?.comment ? (
            <small>{errors?.comment}</small>
          ) : null}
        </Form.Control.Feedback>
      </Form.Group>

      {error && <ErrorHandler error={error} />}
      <Col xs={12}>
        <Button
          onClick={() => postActionItemCommentCreate(values?.comment)}
          className="float-end"
          size="sm"
          disabled={isLoading || !isValid}
        >
          {isLoading && (
            <Spinner
              className="me-2"
              animation="border"
              size="sm"
              variant="light"
            />
          )}
          <span>Post Comment</span>
          <span translate="no" className="material-symbols-outlined md-18 ms-2">
            send
          </span>
        </Button>
      </Col>
    </>
  );
};

ActionItemCommentCreate.propTypes = {
  actionItemId: PropTypes.string.isRequired,
  onActionItemCommentCreated: PropTypes.func.isRequired,
};

export default ActionItemCommentCreate;
